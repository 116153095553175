import Vue from "vue";
import Vuex from "vuex";

import login from "./login.module";
import signup from "./signup.module";
import token from "./token.module";
import config from "./config.module";
import user from "./user.module";
import system from "./system.module";
import edit_profile from "./settings/edit.profile.module";
import post from "./post/post.module";
import posts from "./Profile/posts.module";
import profile from "./Profile/profile.module";
import pages from "./Pages/page.module";
import options from "./System/systemOption.module";
import currentPage from "./Pages/currentPage.module";
import friends from "./People/friends.module";
import song from "./Market/Product/song.module";
import sample from "./Market/Product/sample.module.js";
import album from "./Market/Product/album.module";
import PageMusic from "./Pages/music.module";
import MusicFooter from "./Pages/musicFooter.module";
import current_song from "./Song/current_song.module"
import VuexPersistence from "vuex-persist";
import events from "./Events/events.module";
import singleEventDetail from "./Events/singleEventDetail.module";
import event_favprite_invite_myTicket from "./Events/event_favprite_invite_myTicket.module.js";
import MusicPlayer from "./MusicPlayer.module";
import getStream from "./getStream.module";
import launchRoom from "./launchRoom.module";
import Articl from "./Articles/articles.module"
import Forums from "./Forum/forums.module"
import twilio from "./twilio.module"

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["token","MusicFooter","PageMusic","system"]
});

export default new Vuex.Store({
  modules: {
    token,
    login,
    config,
    signup,
    user,
    system,
    edit_profile,
    post,
    posts,
    profile,
    friends,
    pages,
    currentPage,
    options,
    song,
    sample,
    album,
    PageMusic,
    MusicFooter,
    current_song,
    events,
    MusicPlayer,
    getStream,
    launchRoom,
    Articl,
    Forums,
    singleEventDetail,
    event_favprite_invite_myTicket,
    twilio
  },
  plugins: [vuexLocal.plugin]
});
