<template>
  <div class="w-100">
<!--    <MusicSubHeader/>-->
    <div class="container_xxl">
      <div class="artist_body mx-auto">
        <div class="panel_wrapper engagement-container">
          <div class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">
                <!-- <img src="img/svg/crosshair.svg" alt="icon"> -->
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.9987 14.6668C11.6806 14.6668 14.6654 11.6821 14.6654 8.00016C14.6654 4.31826 11.6806 1.3335 7.9987 1.3335C4.3168 1.3335 1.33203 4.31826 1.33203 8.00016C1.33203 11.6821 4.3168 14.6668 7.9987 14.6668Z"
                      stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path
                      d="M8 11C9.65685 11 11 9.65685 11 8C11 6.34315 9.65685 5 8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11Z"
                      fill="#47505B"/>
                </svg>
                <span class="engage-text">Engage button</span>
              </div>
            </div>
            <div class="panel_body">
              <div class="engage_inside_header">
                <div class="engage-settings-header">
                  <h3>Engage Buttons Settings</h3>
                  <h6>To set up each button, start by turning them on with the switcher</h6>
                </div>
                <button class="preview_engage ml-auto" @click="openEngageWithArtistModal">
                  <svg class="preview-icon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                      <path d="M0.5 6C0.5 6 2.5 2 6 2C9.5 2 11.5 6 11.5 6C11.5 6 9.5 10 6 10C2.5 10 0.5 6 0.5 6Z"
                            stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path
                          d="M6 7.5C6.82843 7.5 7.5 6.82843 7.5 6C7.5 5.17157 6.82843 4.5 6 4.5C5.17157 4.5 4.5 5.17157 4.5 6C4.5 6.82843 5.17157 7.5 6 7.5Z"
                          stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="12" height="12" fill="white"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                  <span class="preview-text">Preview</span>
                </button>
              </div>
              <div class="engage_inside_body">
                <ul>
                  <li class="engage_switch_wrapper engage_container" v-on:click="changeEngageSetting('Learn More')">
                    <input type="checkbox" v-model="engageFormData.learn_more" id="learn_more">
                    <label class="engage_switch_main engage_label" for="learn_more">
                      <div class="engage_icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M7.9987 14.6668C11.6806 14.6668 14.6654 11.6821 14.6654 8.00016C14.6654 4.31826 11.6806 1.3335 7.9987 1.3335C4.3168 1.3335 1.33203 4.31826 1.33203 8.00016C1.33203 11.6821 4.3168 14.6668 7.9987 14.6668Z"
                              class="svg_link_icons" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                          <path class="svg_link_icons" d="M8 10.6667V8" stroke="#47505B" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                          <path class="svg_link_icons" d="M8 5.3335H8.00667" stroke="#47505B" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                      </div>
                      <div class="engage_text">
                        Learn More
                      </div>
                      <div class="engage_switch">
                        <div class="engage_switch_toggler"></div>
                      </div>
                    </label>
                  </li>
                  <li class="engage_switch_wrapper" v-on:click="changeEngageSetting('Book Now')">
                    <input type="checkbox" v-model="engageFormData.book_now" id="book_now">
                    <label class="engage_switch_main" for="book_now">
                      <div class="engage_icon">
                        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path class="svg_link_icons"
                                d="M13.6 4H2.4C1.6268 4 1 4.63959 1 5.42857V12.5714C1 13.3604 1.6268 14 2.4 14H13.6C14.3732 14 15 13.3604 15 12.5714V5.42857C15 4.63959 14.3732 4 13.6 4Z"
                                stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                          <path class="svg_link_icons"
                                d="M11 14V2.44444C11 2.06135 10.842 1.69395 10.5607 1.42307C10.2794 1.15218 9.89782 1 9.5 1H6.5C6.10218 1 5.72064 1.15218 5.43934 1.42307C5.15804 1.69395 5 2.06135 5 2.44444V14"
                                stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                        </svg>
                      </div>
                      <div class="engage_text">
                        Book Now
                      </div>
                      <div class="engage_switch">
                        <div class="engage_switch_toggler"></div>
                      </div>
                    </label>
                  </li>
                  <li class="engage_switch_wrapper" v-on:click="changeEngageSetting('Membership')">
                    <input type="checkbox" v-model="engageFormData.subscribe" id="sponsor">
                    <label class="engage_switch_main" for="sponsor">
                      <div class="engage_icon">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path class="svg_link_icons_fill"
                                d="M15.2602 11.0859L13.7103 12.7498C13.5099 12.1057 12.9084 11.6367 12.1992 11.6367H9.72791C9.38686 11.6367 9.05797 11.5126 8.8019 11.2873C7.42475 10.0754 5.35472 10.0648 3.96457 11.2538C3.67791 10.8479 3.20551 10.582 2.67188 10.582H0.527344C0.236109 10.582 0 10.8181 0 11.1094V17.4726C0 17.7639 0.236109 18 0.527344 18H2.67188C3.14546 18 3.57082 17.7905 3.861 17.4597C4.9757 17.773 6.01847 18 7.45534 18H12.1992C13.3549 18 14.4589 17.448 15.1539 16.5213L17.6839 13.1129C17.684 13.1128 17.6841 13.1128 17.6841 13.1127C17.6844 13.1124 17.6846 13.112 17.6848 13.1117L17.6857 13.1106C17.6858 13.1104 17.6859 13.1102 17.686 13.1101C17.8914 12.8351 18 12.5081 18 12.1641C18 10.7277 16.233 10.0417 15.2602 11.0859ZM3.19922 16.418C3.19922 16.7087 2.96265 16.9453 2.67188 16.9453H1.05469V11.6367H2.67188C2.96265 11.6367 3.19922 11.8733 3.19922 12.1641V16.418ZM16.8404 12.4798C16.8401 12.4802 16.8399 12.4806 16.8396 12.4809C16.8394 12.4812 16.839 12.4816 16.8388 12.4819L14.3086 15.8906C13.8133 16.551 13.0247 16.9453 12.1992 16.9453H7.45534C6.21875 16.9453 5.24704 16.7557 4.24543 16.4742C4.24754 16.4137 4.24688 16.7383 4.24688 12.4025L4.62139 12.0791C5.61442 11.2052 7.11211 11.2052 8.1052 12.0791C8.55394 12.4739 9.13018 12.6914 9.72791 12.6914H12.1992C12.49 12.6914 12.7266 12.928 12.7266 13.2187C12.7266 13.5095 12.49 13.7461 12.1992 13.7461H8.41619C8.12496 13.7461 7.88885 13.9822 7.88885 14.2734C7.88885 14.5647 8.12496 14.8008 8.41619 14.8008H12.5527C12.9902 14.8008 13.4121 14.6172 13.7103 14.2971L16.032 11.8048C16.1329 11.6964 16.27 11.6367 16.418 11.6367C16.8524 11.6367 17.0991 12.1349 16.8404 12.4798Z"
                                fill="#47505B"></path>
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M6.39844 5.80078C6.39844 2.60223 9.00067 0 12.1992 0C15.3978 0 18 2.60223 18 5.80078C18 8.99933 15.3978 11.6016 12.1992 11.6016C9.00067 11.6016 6.39844 8.99933 6.39844 5.80078ZM11.6748 4.74609C11.6748 5.03687 11.9114 5.27344 12.2022 5.27344C13.0745 5.27344 13.7842 5.98314 13.7842 6.85547C13.7842 7.51806 13.366 8.11557 12.7436 8.34226L12.7295 8.34704V8.96484C12.7295 9.25608 12.4934 9.49219 12.2022 9.49219C11.911 9.49219 11.6748 9.25608 11.6748 8.96484V8.35179C11.3269 8.23461 10.9951 8.00184 10.71 7.66245C10.5227 7.43941 10.5516 7.10677 10.7746 6.91945C10.9977 6.73214 11.3303 6.76111 11.5176 6.98411C11.7965 7.31616 12.112 7.44993 12.3827 7.35128C12.5901 7.27569 12.7295 7.07646 12.7295 6.85547C12.7295 6.56469 12.493 6.32812 12.2022 6.32812C11.3299 6.32812 10.6202 5.61843 10.6202 4.74609C10.6202 4.08593 11.0289 3.48427 11.6748 3.25445V2.63672C11.6748 2.34548 11.911 2.10938 12.2022 2.10938C12.4934 2.10938 12.7295 2.34548 12.7295 2.63672V3.25104C13.0035 3.34304 13.2682 3.50606 13.5062 3.73672C13.7154 3.93936 13.7207 4.27321 13.5181 4.48239C13.3153 4.69153 12.9816 4.6968 12.7724 4.49416C12.5156 4.2454 12.233 4.16007 11.9968 4.26006C11.8013 4.34292 11.6748 4.53368 11.6748 4.74609Z"
                                class="svg_link_icons_fill" fill="#47505B"></path>
                        </svg>
                      </div>
                      <div class="engage_text">
                        Membership
                      </div>
                      <div class="engage_switch">
                        <div class="engage_switch_toggler"></div>
                      </div>
                    </label>
                  </li>
                  <li class="engage_switch_wrapper" v-on:click="changeEngageSetting('Tickets')">
                    <input type="checkbox" v-model="engageFormData.tickets" id="tickets">
                    <label class="engage_switch_main" for="tickets">
                      <div class="engage_icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path class="svg_link_icons"
                                d="M12.6667 2.66699H3.33333C2.59695 2.66699 2 3.26395 2 4.00033V13.3337C2 14.07 2.59695 14.667 3.33333 14.667H12.6667C13.403 14.667 14 14.07 14 13.3337V4.00033C14 3.26395 13.403 2.66699 12.6667 2.66699Z"
                                stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                          <path class="svg_link_icons" d="M10.668 1.33301V3.99967" stroke="#47505B" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                          <path class="svg_link_icons" d="M5.33203 1.33301V3.99967" stroke="#47505B" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                          <path class="svg_link_icons" d="M2 6.66699H14" stroke="#47505B" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>

                      </div>
                      <div class="engage_text">
                        Tickets
                      </div>
                      <div class="engage_switch">
                        <div class="engage_switch_toggler"></div>
                      </div>
                    </label>
                  </li>
                  <li class="engage_switch_wrapper" v-on:click="changeEngageSetting('Make Offer')">
                    <input type="checkbox" v-model="engageFormData.make_offer" id="make_offer">
                    <label class="engage_switch_main" for="make_offer">
                      <div class="engage_icon">
                        <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path class="svg_link_icons" d="M5 1V15.6667" stroke="#47505B" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                          <path class="svg_link_icons"
                                d="M8.33333 3H3.33333C2.71449 3 2.121 3.24583 1.68342 3.68342C1.24583 4.121 1 4.71449 1 5.33333C1 5.95217 1.24583 6.54566 1.68342 6.98325C2.121 7.42083 2.71449 7.66667 3.33333 7.66667H6.66667C7.28551 7.66667 7.879 7.9125 8.31658 8.35008C8.75417 8.78767 9 9.38116 9 10C9 10.6188 8.75417 11.2123 8.31658 11.6499C7.879 12.0875 7.28551 12.3333 6.66667 12.3333H1"
                                stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                        </svg>

                      </div>
                      <div class="engage_text">
                        Make Offer
                      </div>
                      <div class="engage_switch">
                        <div class="engage_switch_toggler"></div>
                      </div>
                    </label>
                  </li>
                  <li class="engage_switch_wrapper" v-on:click="changeEngageSetting('Get Quote')">
                    <input type="checkbox" v-model="engageFormData.get_quote" id="get_qoute">
                    <label class="engage_switch_main" for="get_qoute">
                      <div class="engage_icon">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0)">
                            <path class="svg_link_icons"
                                  d="M8.78516 1.71777H4.11849C3.76487 1.71777 3.42573 1.85825 3.17568 2.1083C2.92563 2.35835 2.78516 2.69748 2.78516 3.05111V13.7178C2.78516 14.0714 2.92563 14.4105 3.17568 14.6606C3.42573 14.9106 3.76487 15.0511 4.11849 15.0511H12.1185C12.4721 15.0511 12.8112 14.9106 13.0613 14.6606C13.3113 14.4105 13.4518 14.0714 13.4518 13.7178V6.38444L8.78516 1.71777Z"
                                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path class="svg_link_icons" d="M8.78516 1.71777V6.38444H13.4518" stroke="#47505B"
                                  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect width="16" height="16" fill="white" transform="translate(0.117188 0.384766)"></rect>
                            </clipPath>
                          </defs>
                        </svg>


                      </div>
                      <div class="engage_text">
                        Get Quote
                      </div>
                      <div class="engage_switch">
                        <div class="engage_switch_toggler"></div>
                      </div>
                    </label>
                  </li>
                  <li class="engage_switch_wrapper" v-on:click="changeEngageSetting('Listen Now')">
                    <input type="checkbox" v-model="engageFormData.listen_now" id="listen_now">
                    <label class="engage_switch_main" for="listen_now">
                      <div class="engage_icon">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path class="svg_link_icons" d="M6.15625 12.3623V3.69564L14.1563 2.3623V11.029"
                                stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                          <path class="svg_link_icons"
                                d="M4.15625 14.3623C5.26082 14.3623 6.15625 13.4669 6.15625 12.3623C6.15625 11.2577 5.26082 10.3623 4.15625 10.3623C3.05168 10.3623 2.15625 11.2577 2.15625 12.3623C2.15625 13.4669 3.05168 14.3623 4.15625 14.3623Z"
                                stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                          <path class="svg_link_icons"
                                d="M12.1562 13.0293C13.2608 13.0293 14.1562 12.1339 14.1562 11.0293C14.1562 9.92473 13.2608 9.0293 12.1562 9.0293C11.0517 9.0293 10.1562 9.92473 10.1562 11.0293C10.1562 12.1339 11.0517 13.0293 12.1562 13.0293Z"
                                stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                        </svg>
                      </div>
                      <div class="engage_text">
                        Listen Now
                      </div>
                      <div class="engage_switch">
                        <div class="engage_switch_toggler"></div>
                      </div>
                    </label>
                  </li>
                  <li class="engage_switch_wrapper" v-on:click="changeEngageSetting('Watch Now')">
                    <input type="checkbox" v-model="engageFormData.watch_now" id="watch_now">
                    <label class="engage_switch_main" for="watch_now">
                      <div class="engage_icon">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0)">
                            <path class="svg_link_icons"
                                  d="M8.54948 14.9144C12.2314 14.9144 15.2161 11.9296 15.2161 8.24772C15.2161 4.56582 12.2314 1.58105 8.54948 1.58105C4.86758 1.58105 1.88281 4.56582 1.88281 8.24772C1.88281 11.9296 4.86758 14.9144 8.54948 14.9144Z"
                                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path class="svg_link_icons" d="M7.21875 5.58105L11.2188 8.24772L7.21875 10.9144V5.58105Z"
                                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect width="16" height="16" fill="white" transform="translate(0.550781 0.248047)"></rect>
                            </clipPath>
                          </defs>
                        </svg>

                      </div>
                      <div class="engage_text">
                        Watch Now
                      </div>
                      <div class="engage_switch">
                        <div class="engage_switch_toggler"></div>
                      </div>
                    </label>
                  </li>
                  <li class="engage_switch_wrapper " v-on:click="changeEngageSetting('Show Now')">
                    <input type="checkbox" v-model="engageFormData.shop_now" id="shop_now" >
                    <label class="engage_switch_main" for="shop_now">
                      <div class="engage_icon">
                        <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path class="svg_link_icons" d="M1 12.144L3.05859 1H12.9414L15.001 12.144H1Z" stroke="#47505B"
                                stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path class="svg_link_icons"
                                d="M9.97656 3.76074V5.03974C9.90421 5.50602 9.65398 5.92611 9.27843 6.21178C8.90288 6.49745 8.43123 6.62647 7.96256 6.57174C7.49389 6.62647 7.02225 6.49745 6.6467 6.21178C6.27115 5.92611 6.02092 5.50602 5.94856 5.03974V3.76074"
                                stroke="#47505B" stroke-width="1.3" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                        </svg>
                      </div>
                      <div class="engage_text">
                        Shop Now
                      </div>
                      <div class="engage_switch">
                        <div class="engage_switch_toggler"></div>
                      </div>
                    </label>
                  </li>
                  <li class="engage_switch_wrapper " v-on:click="changeEngageSetting('Apply Now')">
                    <input type="checkbox" v-model="engageFormData.apply_now" id="apply_now">
                    <label class="engage_switch_main" for="apply_now">
                      <div class="engage_icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path class="svg_link_icons"
                                d="M14.6654 7.38625V7.99958C14.6645 9.4372 14.199 10.836 13.3383 11.9875C12.4775 13.1389 11.2676 13.9812 9.88894 14.3889C8.51032 14.7965 7.03687 14.7475 5.68834 14.2493C4.33982 13.7511 3.18847 12.8303 2.406 11.6243C1.62354 10.4183 1.25189 8.99163 1.34648 7.55713C1.44107 6.12263 1.99684 4.75714 2.93088 3.6643C3.86493 2.57146 5.12722 1.80984 6.52949 1.49301C7.93176 1.17619 9.39888 1.32114 10.712 1.90625"
                                stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                          <path class="svg_link_icons" d="M14.6667 2.66699L8 9.34033L6 7.34033" stroke="#47505B"
                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>

                      </div>
                      <div class="engage_text">
                        Apply Now
                      </div>
                      <div class="engage_switch">
                        <div class="engage_switch_toggler"></div>
                      </div>
                    </label>
                  </li>
                  <li class="engage_switch_wrapper" v-on:click="changeEngageSetting('Contact Us')">
                    <input type="checkbox" v-model="engageFormData.contact_us" id="contact_us">
                    <label class="engage_switch_main" for="contact_us">
                      <div class="engage_icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path class="svg_link_icons"
                                d="M14 7.66669C14.0023 8.5466 13.7967 9.41461 13.4 10.2C12.9296 11.1412 12.2065 11.9328 11.3116 12.4862C10.4168 13.0396 9.3855 13.3329 8.33333 13.3334C7.45342 13.3356 6.58541 13.1301 5.8 12.7334L2 14L3.26667 10.2C2.86995 9.41461 2.66437 8.5466 2.66667 7.66669C2.66707 6.61452 2.96041 5.58325 3.51381 4.68839C4.06722 3.79352 4.85884 3.0704 5.8 2.60002C6.58541 2.20331 7.45342 1.99772 8.33333 2.00002H8.66667C10.0562 2.07668 11.3687 2.66319 12.3528 3.64726C13.3368 4.63132 13.9233 5.94379 14 7.33335V7.66669Z"
                                stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                        </svg>

                      </div>
                      <div class="engage_text">
                        Contact Us
                      </div>
                      <div class="engage_switch">
                        <div class="engage_switch_toggler"></div>
                      </div>
                    </label>
                  </li>
                  <li class="engage_switch_wrapper" v-on:click="changeEngageSetting('Other')">
                    <input type="checkbox" v-model="engageFormData.other" id="other">
                    <label class="engage_switch_main" for="other">
                      <div class="engage_icon">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0)">
                            <path class="svg_link_icons"
                                  d="M6.83203 8.73142C7.11833 9.11417 7.4836 9.43088 7.90306 9.66005C8.32253 9.88922 8.78637 10.0255 9.26313 10.0596C9.73989 10.0938 10.2184 10.025 10.6663 9.85794C11.1141 9.69089 11.5208 9.42947 11.8587 9.09142L13.8587 7.09142C14.4659 6.46275 14.8019 5.62074 14.7943 4.74675C14.7867 3.87276 14.4361 3.03672 13.8181 2.41869C13.2001 1.80067 12.364 1.4501 11.49 1.44251C10.616 1.43491 9.77404 1.7709 9.14536 2.37809L7.9987 3.51809"
                                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path class="svg_link_icons"
                                  d="M9.49753 7.39729C9.21123 7.01453 8.84596 6.69783 8.42649 6.46866C8.00703 6.23949 7.54319 6.10321 7.06643 6.06906C6.58967 6.03492 6.11114 6.10371 5.6633 6.27076C5.21546 6.43782 4.80879 6.69924 4.47086 7.03729L2.47086 9.03729C1.86367 9.66596 1.52769 10.508 1.53528 11.382C1.54288 12.2559 1.89344 13.092 2.51147 13.71C3.12949 14.328 3.96554 14.6786 4.83952 14.6862C5.71351 14.6938 6.55552 14.3578 7.18419 13.7506L8.32419 12.6106"
                                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect width="16" height="16" fill="white"
                                    transform="translate(0.164062 0.0644531)"></rect>
                            </clipPath>
                          </defs>
                        </svg>


                      </div>
                      <div class="engage_text">
                        Other
                      </div>
                      <div class="engage_switch">
                        <div class="engage_switch_toggler"></div>
                      </div>
                    </label>
                  </li>
                </ul>
              </div>
              <div class="engage_inside_header engage_btn_settings">
                <h3 v-if="selectedOption != ''">{{ '«' + selectedOption + '» Button Settings' }}</h3>
                <h3 v-else>Button Settings</h3>
              </div>
              <div class="engage_settings" v-if="selectedOption == ''">
                <div class="choose_setup">
                  Choose Button to Set Up First
                </div>
              </div>
              <LearnMore :active-red-border="redBorder" v-if="selectedOption === 'Learn More'" :learn-more-settings="(engageFormData && engageFormData.learn_more_settings) ? engageFormData.learn_more_settings : {}" @getLearnMoreData="getLearnMoreData"/>
              <BookNow v-if="selectedOption === 'Book Now'" :book-now-settings="(engageFormData && engageFormData.book_now_settings) ? engageFormData.book_now_settings : {}" @getBookNowData="getBookNowData"/>
              <Sponsor v-if="selectedOption === 'Membership'" :subscribe-settings="(engageFormData && engageFormData.subscribe_settings) ? engageFormData.subscribe_settings : {}" @getSubscribeData="getSubscribeData"/>
              <Tickets :active-red-border="redBorder" v-if="selectedOption === 'Tickets'" :tickets-settings="(engageFormData && engageFormData.tickets_settings) ? engageFormData.tickets_settings : {}" @getTicketsData="getTicketsData"/>
              <MakeOffer v-if="selectedOption === 'Make Offer'" :make-offer-settings="(engageFormData && engageFormData.make_offer_settings) ? engageFormData.make_offer_settings : {}" @getMakeOfferData="getMakeOfferData"/>
              <GetQuote v-if="selectedOption === 'Get Quote'" :get-quote-settings="(engageFormData && engageFormData.get_quote_settings) ? engageFormData.get_quote_settings : {}" @getQuoteData="getQuoteData"/>
              <ListenNow :active-red-border="redBorder" v-if="selectedOption === 'Listen Now'" :listen-now-settings="(engageFormData && engageFormData.listen_now_settings) ? engageFormData.listen_now_settings : {}" @getListenNowData="getListenNowData"/>
              <WatchNow :active-red-border="redBorder" v-if="selectedOption === 'Watch Now'" :watch-now-settings="(engageFormData && engageFormData.watch_now_settings) ? engageFormData.watch_now_settings : {}" @getWatchNowData="getWatchNowData"/>
              <ShopNow v-if="selectedOption === 'Shop Now'" @getShopNowData="getShopNowData"/>
              <ApplyNow v-if="selectedOption === 'Apply Now'" :apply-now-settings="(engageFormData && engageFormData.apply_now_settings) ? engageFormData.apply_now_settings : {}" @getApplyNowData="getApplyNowData"/>
              <ContactUs :active-red-border="redBorder" v-if="selectedOption === 'Contact Us'" :contact-us-settings="(engageFormData && engageFormData.contact_us_settings) ? engageFormData.contact_us_settings : {}" @getContactUsData="getContactUsData"/>
              <Other :active-red-border="redBorder" v-if="selectedOption === 'Other'" :other-settings="(engageFormData && engageFormData.other_settings) ? engageFormData.other_settings : {}" @getOtherData="getOtherData"/>
              <div class="d-flex justify-content-end">
                <button class="prime_button" type="button" @click="saveEngageSettings">Save Changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EngageWithArtistModal :page_id="page_id" :engageFormData="engageFormData" v-if="engageWithArtistModal"  @close-modal="closeEngageWithArtistModal"/>
  </div>
</template>
<script type="application/javascript">
//    import MusicSubHeader from '../MusicSubHeader'
import MusicSubHeader from '@/components/PageManager/PMSubHeader.vue'
import LearnMore from './EngagementContents/LearnMore'
import BookNow from './EngagementContents/BookNow'
import Sponsor from './EngagementContents/Sponsor'
import Tickets from './EngagementContents/Tickets'
import MakeOffer from './EngagementContents/MakeOffer'
import GetQuote from './EngagementContents/GetQuote'
import ListenNow from './EngagementContents/ListenNow'
import WatchNow from './EngagementContents/WatchNow'
import ShopNow from './EngagementContents/ShopNow'
import ApplyNow from './EngagementContents/ApplyNow'
import ContactUs from './EngagementContents/ContactUs'
import Other from './EngagementContents/Other'
import { getCurrentPageEngageSettings, saveCurrentPageEngageSettings } from "../../../apis/APIs";
import { createRawData } from "../../../mixins/common";
import EngageWithArtistModal from "@/components/PageManager/Pages/Components/EngageWithArtistModal.vue";

export default {
  data() {
    return {
      selectedOption: '',
      engageFormData: {
        page_id: null,
        learn_more: null,
        learn_more_settings:{
          visibility: false,
          action_button_link: null
        },
        book_now: null,
        book_now_settings:{
          visibility: false,
          action_button_link: null
        },
        subscribe: null,
        subscribe_settings:{},
        tickets:null,
        tickets_settings:{},
        make_offer: null,
        make_offer_settings:{},
        get_quote: null,
        get_quote_settings:{},
        listen_now: null,
        listen_now_settings: {},
        watch_now: null,
        watch_now_settings: {},
        shop_now: null,
        shop_now_settings: {},
        apply_now: null,
        apply_now_settings: {},
        contact_us: null,
        contact_us_settings: {},
        other: null,
        other_settings: {}
      },
      engageWithArtistModal: false,
      redBorder: false
    }
  },
  components: {
    // MusicSubHeader,
    EngageWithArtistModal,
    LearnMore,
    BookNow,
    Sponsor,
    Tickets,
    MakeOffer,
    GetQuote,
    ListenNow,
    WatchNow,
    ShopNow,
    ApplyNow,
    ContactUs,
    Other
  },
  computed: {
    page_id() {
      return this.$route.params.id;
    },
    validateEngageData() {
      let isInvalid = false;

      if (this.engageFormData.learn_more_settings.visibility) {
        const condition = !this.engageFormData.learn_more_settings.action_button_link;
        isInvalid = isInvalid || condition;
      }

      if (this.engageFormData.tickets_settings.visibility) {
        const condition = !this.engageFormData.tickets_settings.event;
        isInvalid = isInvalid || condition;
      }

      if (this.engageFormData.listen_now_settings.visibility) {
        const condition = !(this.engageFormData.listen_now_settings.what_to_listen && this.engageFormData.listen_now_settings.album);
        isInvalid = isInvalid || condition;
      }

      if (this.engageFormData.watch_now_settings.visibility) {
        const condition = !this.engageFormData.watch_now_settings.video_link_url;
        isInvalid = isInvalid || condition;
      }

      if (this.engageFormData.contact_us_settings.visibility) {
        const condition = !(this.engageFormData.contact_us_settings.button && this.engageFormData.contact_us_settings.email);
        isInvalid = isInvalid || condition;
      }

      if (this.engageFormData.other_settings.visibility) {
        const condition = !(this.engageFormData.other_settings.button && this.engageFormData.other_settings.action_link_url);
        isInvalid = isInvalid || condition;
      }

      return isInvalid;
    }
  },
  mounted() {
    this.getEngageButtonSettings()
  },
  methods: {
    openEngageWithArtistModal() {
      this.engageWithArtistModal = true;
    },
    closeEngageWithArtistModal() {
      this.engageWithArtistModal = false;
    },
    changeEngageSetting: function (currentOption) {
      this.selectedOption = currentOption;
    },
    getLearnMoreData (obj) {
      this.engageFormData.learn_more_settings = obj
    },
    getBookNowData (obj) {
      this.engageFormData.book_now_settings = obj
    },
    getSubscribeData (obj) {
      this.engageFormData.subscribe_settings = obj
    },
    getTicketsData (obj) {
      this.engageFormData.tickets_settings = obj
    },
    getMakeOfferData (obj) {
      this.engageFormData.make_offer_settings = obj
    },
    getQuoteData (obj) {
      this.engageFormData.get_quote_settings = obj
    },
    getListenNowData (obj) {
      this.engageFormData.listen_now_settings = obj
    },
    getWatchNowData (obj) {
      this.engageFormData.watch_now_settings = obj
    },
    getShopNowData (obj) {
      this.engageFormData.shop_now_settings = obj
    },
    getApplyNowData (obj) {
      this.engageFormData.apply_now_settings = obj
    },
    getContactUsData (obj) {
      this.engageFormData.contact_us_settings = obj
    },
    getOtherData (obj) {
      this.engageFormData.other_settings = obj
    },
    async saveEngageSettings() {
      try {
        await this.$store.dispatch('loading', true)
        console.log("engage", this.engageFormData)
        if(this.validateEngageData) {
          console.log("return", this.validateEngageData)
          this.redBorder = true;
          await this.$store.dispatch('loading', false)
          return;
        }
        this.redBorder = false;
        this.engageFormData.page_id = this.$route.params.id;
        const formData = createRawData(this.engageFormData)
        await saveCurrentPageEngageSettings(formData)
        await this.$store.dispatch('loading', false)
      } catch (e) {
        await this.$store.dispatch('loading', false)
        console.error(e);
      }
    },
    async getEngageButtonSettings() {
      try {
        await this.$store.dispatch('loading', true)
        const {data} = await getCurrentPageEngageSettings(this.$route.params.id)
        const {success, information} = data || {}
        if (success === 1) {
          if (information) {
            this.engageFormData = information
          }
        }
        await this.$store.dispatch('loading', false)
      } catch (e) {
        await this.$store.dispatch('loading', false)
        console.error(e);
      }
    }
  }
}
</script>
<style scoped lang="scss">
.panel_wrapper {
  margin-top: 130px;
  margin-bottom: 70px;
}
.engage_switch_wrapper > input:checked + label .engage_switch {
  background-color:  rgba(209, 67, 58, 0.10);
}
</style>
