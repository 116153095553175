<template>
  <div class="Signup_pop_overlay">
    <div class="wr_report_modal">
      <ReportModalHeader 
        @closeModal="closeModal"
        :reportableType="reportableType"
        report-partner
      />
      <div class="text-container">
        <div style="margin-top: 34px;">
          <span>
            <svg
              width="60"
              height="60"
              viewBox="0 0 60 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M29.0125 14.456L29.5875 8.53347C29.815 6.15347 32.0375 4.53847 34.2825 5.11847C37.66 5.99347 40.0325 9.13097 40.0325 12.7285V20.316C40.0325 22.0035 40.0325 22.8485 40.3975 23.466C40.605 23.8185 40.89 24.116 41.23 24.3285C41.83 24.706 42.6475 24.706 44.28 24.706H45.27C49.5275 24.706 51.655 24.706 52.965 25.681C53.9475 26.4135 54.64 27.486 54.915 28.701C55.28 30.326 54.4625 32.3585 52.825 36.4185L52.01 38.4435C51.5372 39.6163 51.3438 40.883 51.445 42.1435C52.025 49.3285 46.3275 55.381 39.3625 54.9835L13.3025 53.486C10.455 53.3235 9.03246 53.241 7.74746 52.1035C6.45996 50.966 6.21746 49.7935 5.73496 47.451C4.70804 42.4675 4.75491 37.3224 5.87246 32.3585C6.57996 29.2385 9.55996 27.5585 12.6375 27.9585C20.7975 29.0085 28.195 22.911 29.0125 14.4585V14.456Z"
                stroke="#D1433A"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.5002 28.75L17.1577 29.8925C14.9404 37.2837 15.0601 45.1793 17.5002 52.5"
                stroke="#D1433A"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <h1>Thanks</h1>
        </div>
      </div>
      <div class="wr_btn">
        <p>Your report has been submitted</p>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import ReportModalHeader from "./ReportModalHeader.vue";
export default {
  name: "ReportSuccess",
  components: {
    ReportModalHeader,
  },
  props: {
    reportableType: {
      type: String,
      default: 'post'
    },
    post: {
      type: Object
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
<style lang="scss" scoped>
.Signup_pop_overlay {
  font-family: HelveticaNeueNormal;
  .wr_report_modal {
    background: #ffffff;
    width: 570px;
    height: 315px;
    padding: 22px 20px 29px;
    border-radius: 4px;
    .text-container {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #e6eaf0;
      margin-bottom: 20px;
      h1 {
        margin-top: 17px;
        margin-bottom: 16px;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #071526;
      }
    }
    .wr_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #e6eaf0;
      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #47505b;
        margin-bottom: 24px;
      }
    }
  }
}
</style>
