import Vue from "vue";
import $axios from "../plugins/axios";
import store from "../store"
import router from "../router";
import AppConfig from "../configs/AppConfig";

Vue.config.productionTip = false;
Vue.prototype.$http = $axios;
$axios.defaults.timeout = 60000;

$axios.interceptors.request.use(
    async (config) => {
        const access_token = await store.getters.getAccessToken
        if (access_token) {
            config.headers = {
                Authorization: 'Bearer ' + access_token,
                ...config.headers
            }
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
$axios.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    async (error) => {
        console.log('intercept error ' + error)
        if (error.response && error.response.status) {
            switch (error.response.status) {
                case 400:

                    //do something
                    break;

                case 401:
                    // alert("session expired");
                    await store.dispatch('logout')
                    router.push("/")
                    break;
                case 403:
                    router.push("/")
                    break;
                case 404:
                    console.error(error.message);
                    break;
                case 502:
                    setTimeout(() => {
                        router.push("/")
                    }, 1000);
                default:
                    console.log(error);
                    break;
            }
            return Promise.reject(error.response);
        }
    }
);
export default $axios;