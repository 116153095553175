<template>
  <div class="item-title">
    <div class="header-text">
      <h1 style="font-family: HelveticaNeueBold, sans-serif;" >Report {{ reportableType ? reportableType : 'Post' }}</h1>
    </div>
    <div @click="closeModal()">
      <span>
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 10.1914L20 19.8068"
            stroke="#47505B"
            stroke-width="1.5"
          />
          <path
            d="M19.8066 10L10.1913 20"
            stroke="#47505B"
            stroke-width="1.5"
          />
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportModalHeader",
  props: {
    reportableType: {
      type: String,
      default: 'post'
    },
    reportPartner: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    }
  }
};
</script>

<style lang="scss" scoped>
.item-title {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e6eaf0;
  padding-bottom: 19px;
  .header-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 520px;
    h1 {
      color: #071526;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
    }
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
</style>
