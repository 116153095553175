<template>
  <div class="error-container">
    <img class="error-img" :src="require(`@/assets/img/svg/error-icon.svg`)" />
    <p class="error-text">
      <span v-if="addRequiredFieldLabel && showLabel">{{ errorLabel }}: </span>
      {{ text }}
    </p>
  </div>
</template>

<script>


export default {
  props:{
    text:{
      type: String,
    },
    label: {
      type: String,
    },
    addRequiredFieldLabel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    errorLabel(){
      return this.label? this.label.replace(/_/g, " "): ''
    },
    showLabel() {
      return  this.text === 'Required'? true: false
    }
  }
}
</script>

<style lang="scss" scoped>
.error-container {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  min-height: 40px;
  background: rgba(209, 67, 58, 0.10);
  height: 40px;
}

.error-text {
  color: #D1433A;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 17px;
  padding-right: 27px;
  margin-bottom: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  white-space: normal; 
  word-wrap: break-word; 
  overflow-wrap: break-word
}

.error-img {
  height: 24px;
  width: 24px;
  margin-left: 20px;
  align-self: center;
  vertical-align: middle;
}

</style>