<template>
  <div class="Signup_pop_overlay">
    <div class="modal fade show pr-0" id="login" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      style="display: block; padding-right: 15px;" aria-modal="true">
      <div class="modal-dialog d-flex justify-content-center">
        <div class="modal-content" style="width: 572px;">
          <div class="">
            <div class="post-option-container fixPostOptionContainer">
              <div class="news-feed-heading">
                <h1>Engage with Artist</h1>
                <div class="create-video-close fixCreateVidoClose close-modal-btn" v-on:click="closeModalEvent">
                  <a href="javascript:;">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.5 4.5L4.5 13.5" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path d="M4.5 4.5L13.5 13.5" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                    </svg>
                  </a>
                </div>
              </div>
              <div class="feeds-prefrances-list ">
                <ul>
                  <li @click="openLearnMoreButtonLink" v-if="engageFormData && engageFormData.learn_more">
                    <div class="feed-labels" >
                      <input id="genres_1" type="radio" name="Main" checked="">
                      <label class="artist-check_box-main" for="genres_1">
                        <div class="artist-check-svg-icon">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_20348_140767)">
                              <path
                                d="M7.99967 14.6654C11.6816 14.6654 14.6663 11.6806 14.6663 7.9987C14.6663 4.3168 11.6816 1.33203 7.99967 1.33203C4.31778 1.33203 1.33301 4.3168 1.33301 7.9987C1.33301 11.6806 4.31778 14.6654 7.99967 14.6654Z"
                                stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M8 10.6667V8" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                              <path d="M8 5.33203H8.00667" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_20348_140767">
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div class="artist-check-svg-text">Learn More</div>
                      </label>
                    </div>
                  </li>
                  <li v-if="engageFormData && engageFormData.book_now" @click="redirectToBookArtistPage">
                    <div class="feed-labels">
                      <input id="genres_2" type="radio" name="Main">
                      <label class="artist-check_box-main" for="genres_2">
                        <div class="artist-check-svg-icon">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12.6667 2.66797H3.33333C2.59695 2.66797 2 3.26492 2 4.0013V13.3346C2 14.071 2.59695 14.668 3.33333 14.668H12.6667C13.403 14.668 14 14.071 14 13.3346V4.0013C14 3.26492 13.403 2.66797 12.6667 2.66797Z"
                              stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.667 1.33203V3.9987" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round" />
                            <path d="M5.33301 1.33203V3.9987" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round" />
                            <path d="M2 6.66797H14" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                        </div>
                        <div class="artist-check-svg-text">Book Now</div>
                      </label>
                    </div>
                  </li>
                  <li @click="openMembershipModal" v-if="engageFormData && engageFormData.subscribe">
                    <div class="feed-labels" 
                    v-on:click="showMemberShipModalFunc" 
                    
                    >
                      <input id="genres_3" type="radio" name="Main">
                      <label class="artist-check_box-main" for="genres_3">
                        <div class="artist-check-svg-icon member-ship">
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_20348_140883)">
                              <path
                                d="M15.2602 11.0869L13.7103 12.7507C13.5099 12.1067 12.9084 11.6377 12.1992 11.6377H9.72791C9.38686 11.6377 9.05797 11.5136 8.80189 11.2883C7.42475 10.0764 5.35472 10.0657 3.96457 11.2547C3.67791 10.8489 3.20551 10.583 2.67188 10.583H0.527344C0.236109 10.583 0 10.8191 0 11.1104V17.4736C0 17.7648 0.236109 18.001 0.527344 18.001H2.67188C3.14546 18.001 3.57082 17.7915 3.861 17.4607C4.9757 17.774 6.01847 18.001 7.45534 18.001H12.1992C13.3549 18.001 14.4589 17.449 15.1539 16.5223L17.6839 13.1139C17.684 13.1138 17.6841 13.1137 17.6841 13.1137C17.6844 13.1133 17.6846 13.113 17.6848 13.1127L17.6857 13.1115C17.6858 13.1114 17.6859 13.1112 17.686 13.1111C17.8914 12.8361 18 12.509 18 12.165C18 10.7287 16.233 10.0426 15.2602 11.0869ZM3.19922 16.4189C3.19922 16.7097 2.96265 16.9463 2.67188 16.9463H1.05469V11.6377H2.67188C2.96265 11.6377 3.19922 11.8743 3.19922 12.165V16.4189ZM16.8404 12.4808C16.8401 12.4812 16.8399 12.4816 16.8396 12.4819C16.8394 12.4822 16.839 12.4826 16.8388 12.4829L14.3086 15.8916C13.8133 16.552 13.0247 16.9463 12.1992 16.9463H7.45534C6.21875 16.9463 5.24704 16.7567 4.24543 16.4752C4.24754 16.4147 4.24688 16.7393 4.24688 12.4034L4.62139 12.0801C5.61442 11.2062 7.11211 11.2062 8.1052 12.0801C8.55394 12.4749 9.13018 12.6924 9.72791 12.6924H12.1992C12.49 12.6924 12.7266 12.9289 12.7266 13.2197C12.7266 13.5105 12.49 13.7471 12.1992 13.7471H8.4162C8.12496 13.7471 7.88885 13.9832 7.88885 14.2744C7.88885 14.5656 8.12496 14.8017 8.4162 14.8017H12.5527C12.9902 14.8017 13.4121 14.6182 13.7103 14.298L16.032 11.8057C16.1329 11.6974 16.27 11.6377 16.418 11.6377C16.8524 11.6377 17.0991 12.1359 16.8404 12.4808Z"
                                fill="#47505B" />
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M6.39844 5.80078C6.39844 2.60223 9.00067 0 12.1992 0C15.3978 0 18 2.60223 18 5.80078C18 8.99933 15.3978 11.6016 12.1992 11.6016C9.00067 11.6016 6.39844 8.99933 6.39844 5.80078ZM11.6748 4.74609C11.6748 5.03687 11.9114 5.27344 12.2022 5.27344C13.0745 5.27344 13.7842 5.98314 13.7842 6.85547C13.7842 7.51806 13.366 8.11557 12.7436 8.34226L12.7295 8.34704V8.96484C12.7295 9.25608 12.4934 9.49219 12.2022 9.49219C11.911 9.49219 11.6748 9.25608 11.6748 8.96484V8.35179C11.3269 8.23461 10.9951 8.00184 10.71 7.66245C10.5227 7.43941 10.5516 7.10677 10.7746 6.91945C10.9977 6.73214 11.3303 6.76111 11.5176 6.98411C11.7965 7.31616 12.112 7.44993 12.3827 7.35128C12.5901 7.27569 12.7295 7.07646 12.7295 6.85547C12.7295 6.56469 12.493 6.32812 12.2022 6.32812C11.3299 6.32812 10.6202 5.61843 10.6202 4.74609C10.6202 4.08593 11.0289 3.48427 11.6748 3.25445V2.63672C11.6748 2.34548 11.911 2.10938 12.2022 2.10938C12.4934 2.10938 12.7295 2.34548 12.7295 2.63672V3.25104C13.0035 3.34304 13.2682 3.50606 13.5062 3.73672C13.7154 3.93936 13.7207 4.27321 13.5181 4.48239C13.3153 4.69153 12.9816 4.69681 12.7724 4.49416C12.5156 4.2454 12.233 4.16007 11.9968 4.26006C11.8013 4.34292 11.6748 4.53368 11.6748 4.74609Z"
                                fill="#47505B" />
                            </g>
                            <defs>
                              <clipPath id="clip0_20348_140883">
                                <rect width="18" height="18" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div class="artist-check-svg-text">Membership</div>
                      </label>
                    </div>
                  </li>
                  <li @click="redirectToButTicketsPage" v-if="engageFormData && engageFormData.tickets">
                    <div class="feed-labels">
                      <input id="genres_4" type="radio" name="Main">
                      <label class="artist-check_box-main" for="genres_4">
                        <div class="artist-check-svg-icon">
                          <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M13.6 4H2.4C1.6268 4 1 4.63959 1 5.42857V12.5714C1 13.3604 1.6268 14 2.4 14H13.6C14.3732 14 15 13.3604 15 12.5714V5.42857C15 4.63959 14.3732 4 13.6 4Z"
                              stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                              d="M11 14V2.44444C11 2.06135 10.842 1.69395 10.5607 1.42307C10.2794 1.15218 9.89782 1 9.5 1H6.5C6.10218 1 5.72064 1.15218 5.43934 1.42307C5.15804 1.69395 5 2.06135 5 2.44444V14"
                              stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                        <div class="artist-check-svg-text">Tickets</div>
                      </label>
                    </div>
                  </li>
                  <li @click="openMakeOfferModal" v-if="engageFormData && engageFormData.make_offer">
                    <div class="feed-labels">
                      <input id="genres_6" type="radio" name="Main">
                      <label class="artist-check_box-main" for="genres_6">
                        <div class="artist-check-svg-icon">
                          <svg width="10" height="17" viewBox="0 0 10 17" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 1V15.6667" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round" />
                            <path
                              d="M8.33333 3H3.33333C2.71449 3 2.121 3.24583 1.68342 3.68342C1.24583 4.121 1 4.71449 1 5.33333C1 5.95217 1.24583 6.54566 1.68342 6.98325C2.121 7.42083 2.71449 7.66667 3.33333 7.66667H6.66667C7.28551 7.66667 7.879 7.9125 8.31658 8.35008C8.75417 8.78767 9 9.38116 9 10C9 10.6188 8.75417 11.2123 8.31658 11.6499C7.879 12.0875 7.28551 12.3333 6.66667 12.3333H1"
                              stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                        <div class="artist-check-svg-text">Make Offer</div>
                      </label>
                    </div>
                  </li>
                  <li @click="openGetQuoteModal" v-if="engageFormData && engageFormData.get_quote">
                    <div class="feed-labels">
                      <input id="genres_8" type="radio" name="Main">
                      <label class="artist-check_box-main" for="genres_8">
                        <div class="artist-check-svg-icon">
                          <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_20348_140776)">
                              <path
                                d="M8.78223 1.71484H4.11556C3.76194 1.71484 3.4228 1.85532 3.17275 2.10537C2.9227 2.35542 2.78223 2.69456 2.78223 3.04818V13.7148C2.78223 14.0685 2.9227 14.4076 3.17275 14.6577C3.4228 14.9077 3.76194 15.0482 4.11556 15.0482H12.1156C12.4692 15.0482 12.8083 14.9077 13.0584 14.6577C13.3084 14.4076 13.4489 14.0685 13.4489 13.7148V6.38151L8.78223 1.71484Z"
                                stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M8.78223 1.71484V6.38151H13.4489" stroke="#47505B" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_20348_140776">
                                <rect width="16" height="16" fill="white" transform="translate(0.115234 0.382812)" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div class="artist-check-svg-text">Get Quote</div>
                      </label>
                    </div>
                  </li>
                  <li @click="openListenNowButtonLink" v-if="engageFormData && engageFormData.listen_now">
                    <div class="feed-labels">
                      <input id="genres_9" type="radio" name="Main">
                      <label class="artist-check_box-main" for="genres_9">
                        <div class="artist-check-svg-icon">
                          <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.1543 12.3633V3.69661L14.1543 2.36328V11.0299" stroke="#47505B"
                              stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                              d="M4.1543 14.3633C5.25887 14.3633 6.1543 13.4679 6.1543 12.3633C6.1543 11.2587 5.25887 10.3633 4.1543 10.3633C3.04973 10.3633 2.1543 11.2587 2.1543 12.3633C2.1543 13.4679 3.04973 14.3633 4.1543 14.3633Z"
                              stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                              d="M12.1543 13.0312C13.2589 13.0312 14.1543 12.1358 14.1543 11.0312C14.1543 9.92668 13.2589 9.03125 12.1543 9.03125C11.0497 9.03125 10.1543 9.92668 10.1543 11.0312C10.1543 12.1358 11.0497 13.0312 12.1543 13.0312Z"
                              stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                        <div class="artist-check-svg-text">Listen Now</div>
                      </label>
                    </div>
                  </li>
                  <li @click="openVedioLink" v-if="engageFormData && engageFormData.watch_now">
                    <div class="feed-labels">
                      <input id="genres_10" type="radio" name="Main">
                      <label class="artist-check_box-main" for="genres_10">
                        <div class="artist-check-svg-icon">
                          <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_20348_140835)">
                              <path
                                d="M8.54948 14.9115C12.2314 14.9115 15.2161 11.9267 15.2161 8.24479C15.2161 4.56289 12.2314 1.57812 8.54948 1.57812C4.86758 1.57812 1.88281 4.56289 1.88281 8.24479C1.88281 11.9267 4.86758 14.9115 8.54948 14.9115Z"
                                stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M7.2168 5.57812L11.2168 8.24479L7.2168 10.9115V5.57812Z" stroke="#47505B"
                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_20348_140835">
                                <rect width="16" height="16" fill="white" transform="translate(0.549805 0.246094)" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div class="artist-check-svg-text">Watch Now</div>
                      </label>
                    </div>
                  </li>
                  <li v-if="false" class="disabled_engage_btn">
                    <div class="feed-labels">
                      <input id="genres_11" type="radio" name="Main">
                      <label class="artist-check_box-main" for="genres_11">
                        <div class="artist-check-svg-icon">
                          <svg width="16" height="13" viewBox="0 0 16 13" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 12.144L3.05859 1H12.9414L15.001 12.144H1Z" stroke="#47505B" stroke-width="1.3"
                              stroke-linecap="round" stroke-linejoin="round" />
                            <path
                              d="M9.97559 3.76172V5.04072C9.90323 5.50699 9.653 5.92709 9.27745 6.21276C8.9019 6.49843 8.43026 6.62745 7.96159 6.57272C7.49292 6.62745 7.02127 6.49843 6.64572 6.21276C6.27017 5.92709 6.01994 5.50699 5.94759 5.04072V3.76172"
                              stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                        <div class="artist-check-svg-text">Shop Now</div>
                      </label>
                    </div>
                  </li>
                  <li v-if="false" class="disabled_engage_btn">
                    <div class="feed-labels">
                      <input id="genres_12" type="radio" name="Main">
                      <label class="artist-check_box-main" for="genres_12">
                        <div class="artist-check-svg-icon">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_20348_140841)">
                              <path
                                d="M14.6663 7.38527V7.99861C14.6655 9.43622 14.2 10.8351 13.3392 11.9865C12.4785 13.1379 11.2685 13.9803 9.88991 14.3879C8.51129 14.7955 7.03785 14.7465 5.68932 14.2483C4.3408 13.7501 3.18944 12.8293 2.40698 11.6233C1.62452 10.4173 1.25287 8.99065 1.34746 7.55615C1.44205 6.12165 1.99781 4.75616 2.93186 3.66332C3.86591 2.57049 5.1282 1.80886 6.53047 1.49204C7.93274 1.17521 9.39985 1.32017 10.713 1.90527"
                                stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M14.6667 2.66797L8 9.3413L6 7.3413" stroke="#47505B" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_20348_140841">
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div class="artist-check-svg-text">Apply Now</div>
                      </label>
                    </div>
                  </li>
                  <li @click="openContactUsModal" v-if="engageFormData && engageFormData.contact_us">
                    <div class="feed-labels">
                      <input id="genres_13" type="radio" name="Main">
                      <label class="artist-check_box-main" for="genres_13">
                        <div class="artist-check-svg-icon">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M14 7.66669C14.0023 8.5466 13.7967 9.41461 13.4 10.2C12.9296 11.1412 12.2065 11.9328 11.3116 12.4862C10.4168 13.0396 9.3855 13.3329 8.33333 13.3334C7.45342 13.3356 6.58541 13.1301 5.8 12.7334L2 14L3.26667 10.2C2.86995 9.41461 2.66437 8.5466 2.66667 7.66669C2.66707 6.61452 2.96041 5.58325 3.51381 4.68839C4.06722 3.79352 4.85884 3.0704 5.8 2.60002C6.58541 2.20331 7.45342 1.99772 8.33333 2.00002H8.66667C10.0562 2.07668 11.3687 2.66319 12.3528 3.64726C13.3368 4.63132 13.9233 5.94379 14 7.33335V7.66669Z"
                              stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                        <div class="artist-check-svg-text">Contact Us</div>
                      </label>
                    </div>
                  </li>
                  <li @click="openOtherButtonLink" v-if="engageFormData && engageFormData.other">
                    <div class="feed-labels">
                      <input id="genres_14" type="radio" name="Main">
                      <label class="artist-check_box-main" for="genres_14">
                        <div class="artist-check-svg-icon">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_20348_140854)">
                              <path
                                d="M7.99967 14.6654C11.6816 14.6654 14.6663 11.6806 14.6663 7.9987C14.6663 4.3168 11.6816 1.33203 7.99967 1.33203C4.31778 1.33203 1.33301 4.3168 1.33301 7.9987C1.33301 11.6806 4.31778 14.6654 7.99967 14.6654Z"
                                stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M8 5.33203V7.9987" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                              <path d="M8 10.668H8.00667" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_20348_140854">
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div class="artist-check-svg-text">{{ engageFormData.other_settings.button }}</div>
                      </label>
                    </div>
                  </li>
                  <li v-if="emptyEngageData">No Settings Enabled yet!</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EngageWithArtistModal",
  props: {
    engageFormData: {
      type: Object,
      required: true
    },
    performActions: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    emptyEngageData() {
      return !this.engageFormData || 
        (!this.engageFormData.learn_more && 
          !this.engageFormData.book_now && 
          !this.engageFormData.subscribe && 
          !this.engageFormData.tickets &&
          !this.engageFormData.make_offer &&
          !this.engageFormData.get_quote &&
          !this.engageFormData.listen_now &&
          !this.engageFormData.watch_now &&
          !this.engageFormData.contact_us &&
          !this.engageFormData.other
        )
    }
  },
  methods: {
    redirectToBookArtistPage() {
      if(this.performActions) {
        this.$router.push(`/page-manager/page/book-artist/${this.$route.params.id}`)
      }
    },
    openMakeOfferModal() {
      if(this.performActions) {
        this.$emit("open-make-offer-model");
      }
    },
    openMembershipModal() {
      if(this.performActions) {
        this.$emit('open-membership-modal')
      }
    },
    openGetQuoteModal() {
      if(this.performActions) {
        this.$emit("open-get-quote-model");
      }
    },
    openContactUsModal() {
      if(this.performActions) {
        this.$emit("open-contact-us-model");
      }
    },
    openListenNowButtonLink() {
      if(this.performActions) {
        switch (this.engageFormData.listen_now_settings.what_to_listen) {
          case 'Songs':
            this.$router.push(`/music/page/song/${this.engageFormData.listen_now_settings.album.value}`)
            break;

          case 'Albums':
            this.$router.push(`/music/page/album/${this.engageFormData.listen_now_settings.album.value}`)
            break;
        }
      }
    },
    openVedioLink() {
      if(this.performActions) {
        window.open(this.engageFormData.watch_now_settings.video_link_url);
      }
    },
    openOtherButtonLink() {
      if(this.performActions) {
        window.open(this.engageFormData.other_settings.action_link_url);
      }
    },
    openLearnMoreButtonLink() {
      if(this.performActions) {
        window.open(this.engageFormData.learn_more_settings.action_button_link);
      }
    },
    redirectToButTicketsPage() {
      if(this.performActions) {
        this.$router.push(`/events/page/buy/ticket/${this.engageFormData.tickets_settings.event}`)
      }
    },
    showMemberShipModalFunc() {
      if(this.performActions) {
        this.$emit('open-membership-modal');
      }
    },
    closeModalEvent() {
      this.$emit('close-modal')
    },
  }
};
</script>

<style scoped lang="scss">
.fixCreateVidoClose {
  background-color: transparent;
}
.Signup_pop_overlay {
  .modal {
    .modal-dialog {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;

      .modal-content {
        .post-option-container {
          padding: 1.5rem;
        }
      }
    }
  }
}

.feed-labels {
  &:hover {
    .artist-check_box-main {
      .artist-check-svg-icon {
        &.member-ship {
          svg {
            g {
              path {
                fill: #D1433A;
                stroke: none;
              }
            }
          }
        }

        svg {
          path {
            stroke: #D1433A;
          }
        }
      }
    }
  }
}
</style>

<style scoped>
.disabled_engage_btn {
  pointer-events: none;
  opacity: 0.5;
}

.artist-check_box-main:hover .artist-check-svg-text {
  color: #071526
}

.artist-check-svg-text {
  color: #071526 !important;

  &:hover {
    color: #D1433A;
  }
}

.feed-labels>input:checked+.artist-check_box-main {
  background-color: #fff;
  border: 1px solid #e6eaf0;

  &:hover {
    border: 1px solid #D1433A;
  }
}
</style>
