<template>
  <div class="about-work about-section-page pb20">
    <div class="row">
      <div class="col">
        <div class="about-work-text ">
          <h1 class="about-title fixAboutTitle">Contact</h1>
        </div>
      </div>
    </div>
    <div v-if="contact.website || contact.phone || contact.email || contact.address" class="row mt-4">
      <div class="col-md-6">
        <div class="about_listing">
          <div v-if="contact.website" class="about-more-details">
               <span>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path
                         d="M4.8 8.79999H0C0.198991 10.7802 1.12892 12.6151 2.60819 13.9464C4.08747 15.2778 6.00987 16.01 8 16C5.952 16 4.944 12.408 4.8 8.79999Z"
                         fill="#8B949F"></path>
                     <path
                         d="M0 7.20022H4.8C4.928 3.59222 5.936 0.000222633 8 0.000222633C6.00987 -0.00975066 4.08747 0.722422 2.60819 2.05377C1.12892 3.38512 0.198991 5.22004 0 7.20022Z"
                         fill="#8B949F"></path>
                     <path
                         d="M8.00039 14.4C8.38439 14.4 9.44839 12.48 9.60039 8.79999H6.40039C6.55239 12.48 7.61639 14.4 8.00039 14.4Z"
                         fill="#8B949F"></path>
                     <path
                         d="M8.00039 1.60001C7.61639 1.60001 6.55239 3.52001 6.40039 7.20001H9.60039C9.44839 3.52001 8.38439 1.60001 8.00039 1.60001Z"
                         fill="#8B949F"></path>
                     <path
                         d="M8 16C9.99013 16.01 11.9125 15.2778 13.3918 13.9464C14.8711 12.6151 15.801 10.7802 16 8.79999H11.2C11.056 12.408 10.048 16 8 16Z"
                         fill="#8B949F"></path>
                     <path
                         d="M8 9.00975e-06C10.048 9.00975e-06 11.056 3.59201 11.2 7.20001H16C15.801 5.21982 14.8711 3.3849 13.3918 2.05356C11.9125 0.722209 9.99013 -0.00996428 8 9.00975e-06Z"
                         fill="#8B949F"></path>
                  </svg>
               </span>
            <p class="fixFntColr">{{contact.website}}</p>
          </div>
          <div v-if="contact.phone" class="about-more-details">
                <span>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.2544 2.52816L13.1864 0.462136C13.0405 0.315619 12.8671 0.199367 12.6762 0.120059C12.4853 0.0407514 12.2805 -4.95526e-05 12.0738 4.51641e-08C11.6524 4.51641e-08 11.2563 0.165049 10.9592 0.462136L8.73398 2.68738C8.58746 2.83325 8.47121 3.00664 8.39191 3.19758C8.3126 3.38852 8.2718 3.59325 8.27185 3.8C8.27185 4.22136 8.43689 4.61748 8.73398 4.91456L10.3612 6.54175C9.98028 7.38128 9.45072 8.14503 8.79806 8.79612C8.14703 9.45037 7.38336 9.98185 6.54369 10.365L4.91651 8.73786C4.77063 8.59135 4.59724 8.4751 4.4063 8.39579C4.21537 8.31648 4.01064 8.27568 3.80388 8.27573C3.38253 8.27573 2.98641 8.44078 2.68932 8.73786L0.462137 10.9612C0.31544 11.1073 0.199088 11.281 0.119777 11.4723C0.0404652 11.6636 -0.000240619 11.8687 1.06997e-06 12.0757C1.06997e-06 12.4971 0.16505 12.8932 0.462137 13.1903L2.52621 15.2544C3 15.7301 3.65437 16 4.32621 16C4.46796 16 4.60388 15.9883 4.73787 15.965C7.35534 15.534 9.95146 14.1417 12.0466 12.0485C14.1398 9.95728 15.5301 7.36311 15.967 4.73786C16.099 3.93592 15.833 3.11068 15.2544 2.52816V2.52816Z"
                        fill="#7E858C"/>
                   </svg>
               </span>
            <p class="fixFntColr">{{contact.phone}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="about_listing">
          <div v-if="contact.email" class="about-more-details">
               <span>
              <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path
                         d="M4 15H12V1.60714C12 0.719866 11.3281 0 10.5 0H5.5C4.67188 0 4 0.719866 4 1.60714V15ZM6 2.14286H10V3.21429H6V2.14286ZM16 4.82143V13.3929C16 14.2801 15.3281 15 14.5 15H13V3.21429H14.5C15.3281 3.21429 16 3.93415 16 4.82143ZM3 15H1.5C0.671875 15 0 14.2801 0 13.3929V4.82143C0 3.93415 0.671875 3.21429 1.5 3.21429H3V15Z"
                         fill="#8B949F"></path>
                  </svg>
               </span>
            <p class="fixFntColr">{{contact.email}}</p>
          </div>
          <div v-if="contact.address" class="about-more-details">
               <span>
                   <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path
                         d="M1.00173 6.42198C0.983736 5.71866 1.10635 5.01884 1.3623 4.36349C1.61824 3.70815 2.00241 3.11048 2.4923 2.60551C2.98219 2.10054 3.56792 1.69844 4.21521 1.42275C4.8625 1.14707 5.5583 1.00333 6.26185 1H6.3428C7.04438 0.999364 7.73917 1.13708 8.38746 1.40527C9.03575 1.67346 9.62481 2.06684 10.1209 2.56293C10.617 3.05902 11.0104 3.64807 11.2786 4.29637C11.5467 4.94466 11.6845 5.6395 11.6838 6.34108V6.42198C11.6567 7.91492 11.0468 9.33809 9.98442 10.3873L6.3428 15L2.62023 10.3064C1.5682 8.76879 1.00173 7.87863 1.00173 6.42198Z"
                         fill="#8B949F"></path>
                     <path
                         d="M1.00173 6.42198C0.983736 5.71866 1.10635 5.01884 1.3623 4.36349C1.61824 3.70815 2.00241 3.11048 2.4923 2.60551C2.98219 2.10054 3.56792 1.69844 4.21521 1.42275C4.8625 1.14707 5.5583 1.00333 6.26185 1H6.3428C7.04438 0.999364 7.73917 1.13708 8.38746 1.40527C9.03575 1.67346 9.62481 2.06684 10.1209 2.56293C10.617 3.05902 11.0104 3.64807 11.2786 4.29637C11.5467 4.94466 11.6845 5.6395 11.6838 6.34108V6.42198C11.6567 7.91492 11.0468 9.33809 9.98442 10.3873L6.3428 15L2.62023 10.3064C1.5682 8.76879 1.00173 7.87863 1.00173 6.42198"
                         stroke="#8B949F" stroke-width="1.5"></path>
                     <path
                         d="M6.34174 8.12136C7.36969 8.12136 8.20301 7.32428 8.20301 6.34102C8.20301 5.35776 7.36969 4.56067 6.34174 4.56067C5.31379 4.56067 4.48047 5.35776 4.48047 6.34102C4.48047 7.32428 5.31379 8.12136 6.34174 8.12136Z"
                         stroke="white" stroke-width="1.5" stroke-miterlimit="10"></path>
                  </svg>
               </span>
            <p class="fixFntColr">{{contact.address}}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else style="margin-top: 18px; margin-left: 25px;"> No Contacts added</div>
    <!-- <div class="row">
      <div class="col-12 ">
        <div class="px-3">
          <img style="height: 100px" class="w-100" :src="require(`@/assets/img/map.png`)">
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>

export default {
  name: "contact.vue",
  data() {
    return {
      deletePage: false,
      contact: {
        website: null,
        email: null,
        phone: null,
        address: null,
        latitude: null,
        longitude: null,
      }
    }
  },
  computed: {
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
  },
  methods: {
    setPageData(){
      if(this.current_page){
        let {email,address,phone,website} = this.current_page
        this.contact.email= email
        this.contact.phone= phone
        this.contact.website= website
        this.contact.address= address
      }
    }
  },
  mounted() {
    this.setPageData()
  },
  watch:{
    current_page(val){
      if(val)
        this.setPageData()

    }
  }
}
</script>

<style scoped>

</style>
