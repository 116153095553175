<template>
  <div class="singleNewsPostComment">
    
    <div class="row">
      <DeleteCommentDropDown :item="item" :post="post" @removeCommentFromList="removeCommentFromList" />

      <div class="post-inner-comment-card px-0 postInnerMain">
        <div class="post-inner-comment-card-body">
          <div data-v-e43c18bc="" class="">

            <div data-v-e43c18bc="" class="post-comment-reply-width px-0">
              <img class="photo-radius" v-if="user_picture" :src="user_picture">
              <img class="photo-radius" v-if="!user_picture" :src="require(`@/assets/img/user-placeholder.png`)">
            </div>

            <div data-v-e43c18bc="" class="pl-0 pt-2 post-comment-reply-width-1">
              <div data-v-e43c18bc="" class="col-sm-12 pl-0 pr-2">
                <h3 data-v-e43c18bc=""
                    class="comment-heading mb-0"
                    style="display: inline-block;">{{ name }}</h3>
                    <span data-v-e43c18bc="" class=" john-comment"> {{ item.content }}</span>
                    <img v-if="item.artwork_url" :src="item.artwork_url.original_url" alt="" id="cmnt-img" class="comment-image" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


    <div class="row reply-post-margin pt-2 mb-3">
      <div class="post-like-comment post-reply-comment-bottom">
        <span class="hover">Like</span>
      </div>
      <div class="post-like-comment post-reply-comment-bottom">
        <span class="hover">Reply</span>
      </div>
      <div class="post-like-commentt post-reply-comment-bottom">
        <span class="hover">{{ time }}</span>
      </div>

    </div>
  </div>
</template>
<script>
import {post_comment_api, post_comment_form_data_api, editComment} from '../../../../apis/APIs';
import DeleteCommentDropDown from './DeleteCommentDropDown.vue';
import {mapGetters} from 'vuex';
import moment from 'moment';

export default {
  data() {
    return ({
      is_edit: false,
      comment: "",
      comment_loading: false,
      write_reply: false,

      is_drop_down_open: false,

      message: "",
      success_message: ""
    })
  },
  components:{
    DeleteCommentDropDown
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    }),
    id() {
      const {node_id = ""} = this.item || {};

      return node_id;
    },
    name() {
      const {user} = this.item || {};
      const {name, last_name} = user
      return `${name} ${last_name}`
    },
    user_picture() {
      const {user} = this.item || {};
      return (user.artwork_url) ? user.artwork_url : null
    },
    time() {
      const {updated_at} = this.item || {};
      return moment(updated_at).fromNow();
    }
  },
  props: ["item", "reply_count", "post_id", "post"],
  methods: {
    toggleReplySection() {
      this.$parent.openReplySection();
    },
    toggle_drop_down() {
      this.is_drop_down_open = !this.is_drop_down_open;
    },
    init() {
      const {text} = this.item;

      this.comment = text;
    },
    toggle_edit() {
      this.is_edit = !this.is_edit
    },
    refresh_reply_list() {
      this.$parent.init_reply_list()
    },
    removeCommentFromList(){
      this.$emit('removeComment',this.item.id);
    },
    async submit() {

      const {comment_loading, comment, item} = this;
      const {comment_id} = item || {};

      const {user_data} = this.user || {};
      const {user_id} = user_data || {};

      if (comment_loading || (!comment)) return;

      try {

        var formdata = new FormData();
        formdata.append("page_action", "2");
        formdata.append("comment_id", comment_id);
        formdata.append("text", comment);
        formdata.append("time", moment(new Date()).format("YYYY-MM-dd hh:mm:ss"))

        this.comment_loading = true;
        this.message = "";

        const {success, message} = await editComment(formdata);
        this.comment_loading = false;

        if (success === 1) {
          this.success_message = message;
          this.reset_data();
          this.refresh_reply_list();
          setTimeout(() => this.success_message = "", 5000);
        } else {
          this.message = message;
        }

      } catch (error) {
        this.comment_loading = false;
      }
    },
    checkEnter(event) {

      if (event.keyCode === 13) {
        this.submit();
      }

    },
    reset_data() {
      this.is_edit = false;
      this.comment_loading = false;
    },
    async deleteSubmit() {

      const {comment_loading, comment, item} = this;
      const {comment_id} = item || {};

      const {user_data} = this.user || {};
      const {user_id} = user_data || {};

      try {

        var formdata = new FormData();
        formdata.append("page_action", "3");
        formdata.append("post_id", this.post_id);
        formdata.append("comment_id", comment_id);
        formdata.append("node_id", this.id);
        formdata.append("node_type", "comment");
        formdata.append("user_id", user_id);
        formdata.append("time", moment(new Date()).format("YYYY-MM-dd hh:mm:ss"))

        const {success, message} = await editComment(formdata);

        if (success === 1) {
          this.reset_data();
          this.refresh_reply_list();
          setTimeout(() => this.success_message = "", 5000);
        } else {
        }

      } catch (error) {
      }
    },
    reactOrUnReact(value){
      this.$emit('reactOrUnReact')
    },
  }
}
</script>
<style scoped lang="scss">
.comment-image {
  width: 100px;
  height: 100px;
  display: block;
  padding-bottom: 1rem;
}
.comment-card {
  background: #FFFFFF;
  border: 1px solid #E6EAF0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 0.6rem;
}

.comment-card-body {
  padding: 10px 20px 10px 20px;
}

.radius {
  border-radius: 100px;
  width: 51px;
}

.comment-heading {
  color: #071526;
  font-family: HelveticaNeueMedium, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.date-custom-card {
  font-family: 'HelveticaNeueMedium';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #8B949F;
}

.date-custom-card::before {
  width: 5px;
  height: 5px;
  background: red;
}

.comment-p {
  font-family: 'HelveticaNeueMedium';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #071526;
}

.comment-icon {
  display: inline-block !important;
  width: 20% !important;
  position: absolute;
  right: 30px;

}

.comment-icon svg {
  float: right;

}


.post-comment-bottom-icons-width {
  padding: 7px 0px 7px 12px;
  width: 70px;
}

.post-comment-bottom-icons-width:hover {
  padding: 7px 0px 7px 12px;
  width: 70px;
  border-radius: 4px;
  background: #ECF0FF;
  stroke: #D1433A;
  fill: #D1433A;
  cursor: pointer;
}

.post-comment-bottom-icons-width:nth-child(2):hover span svg g path {
  stroke: #D1433A;
}

.post-comment-bottom-icons-width:hover span svg path {
  stroke: #D1433A;
}

.post-comment-bottom-icons-width:nth-child(4):hover span svg g g g path {
  fill: #D1433A;
  stroke: none;
}


.post-comment-width {
  width: 75px !important;
  display: inline-block !important;
}

.post-comment-width-1 {
  width: 70% !important;
  display: inline-block !important;
}

.post-comment-p {
  width: 100%;
}

.border-width {
  width: 100%;
  color: #E6EAF0;
}

.post-comment-icon-color {
  display: inline-block !important;
  float: left;
  padding-top: 1px;
}

.post-comment-icon-text {
  display: inline-block !important;
  float: left;
  font-size: 11px;
  padding-left: 5px;
}

.post-comment-icon-color-1 {
  display: inline-block !important;
  float: left;
  padding-top: 2px;
}

.post-comment-icon-color-2 {
  display: inline-block !important;
  float: left;

}

.post-inner-comment-card {
  // width: auto;
  max-width: 374px;
  background: #F5F6F8;
  box-sizing: border-box;
  border-radius: 34.5px;
  margin-left: 28px;
}

.post-inner-comment-card-body {
  padding: 10px 20px 10px 20px;
  position: relative;
  width: 308px !important;
}

.post-comment-reply-width {
  width: 8% !important;
  position: relative;
  left: -18px;
}

.post-comment-reply-width-1 {
  width: 92% !important;
  display: inline-block !important;
}

.photo-radius {
  border-radius: 100px;
  width: 40px;
  margin-bottom: 20px;
  margin-top: -10px;
}

.reply-post-margin {
  margin-left: 46px;
}

.post-like-comment {
  width: auto;
  border-right: 1px solid #8B949F;
}

.post-like-commentt {
  width: auto;
}

.post-like-comment-1 {
  width: 16%;
}

.post-reply-comment-bottom {
  color: #47505B;
  font-family: HelveticaNeueMedium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.john-comment {
  color: #47505B;
  font-family: HelveticaNeueNormal, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.post-inner-comment-card-body-1 {
  padding: 5px 20px 5px 20px;
}

.post-inner-comment-heading {
  width: 460px;

  box-sizing: border-box;
  border-radius: 34.5px;
  margin-left: 80px;
}

.post-photo-width {
  width: 257px;
  border-radius: 10px;
}

.post-photo-main-width {
  width: 100%;

}

.photo-margin {
  margin-left: 97px;
}

.pg-container {
  width: 100%;
}


@media screen and (max-width: 1440px) {
  .post-inner-comment-card {
    width: 90%;
    margin-left: 40px;
  }
  .reply-post-margin {
    margin-left: 50px;
  }
  .photo-margin {
    margin-left: 50px;
  }
  .post-inner-comment-heading {
    margin-left: 40px;
  }
}

.hover:hover {
  color: #D1433A;
  cursor: pointer;
}

.icon-padding {
  padding-left: 6px;
}


</style>
