import { render, staticRenderFns } from "./SentFriendRequests.vue?vue&type=template&id=716e0120&scoped=true"
import script from "./SentFriendRequests.vue?vue&type=script&lang=js"
export * from "./SentFriendRequests.vue?vue&type=script&lang=js"
import style0 from "./SentFriendRequests.vue?vue&type=style&index=0&id=716e0120&prod&lang=scss&scoped=true"
import style1 from "./SentFriendRequests.vue?vue&type=style&index=1&id=716e0120&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "716e0120",
  null
  
)

export default component.exports