import { render, staticRenderFns } from "./AboutTab.vue?vue&type=template&id=040c3255&scoped=true"
import script from "./AboutTab.vue?vue&type=script&lang=js"
export * from "./AboutTab.vue?vue&type=script&lang=js"
import style0 from "./AboutTab.vue?vue&type=style&index=0&id=040c3255&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "040c3255",
  null
  
)

export default component.exports