<template>
  <v-select class="select-dropdown" v-model="internalValue" :placeholder="placeholder" :disabled="disabled" :class="{ 'vs__actions-hidden': hideCrossIcon }" :options="options" :value="value" :reduce="option => option.value" label="label"
    @input="handleInput" :style="{ 
      '--dropdown-border-radius': borderRadius, 
      '--dropdown-background-color': backgroundColor,
      '--dropdown-min-width': minWidth,
      '--search-input-color': inputColor,
        ...style 
      }"  />
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-select/dist/vue-select.css";

export default {
  name: "SelectDropdown",
  components: { vSelect },
  props: {
    hideCrossIcon: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Object],
      required: false,
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
    style: {
      type: String,
      required: false,
    },
    backgroundColor: {
      type: String,
      default: "white"
    },
    borderRadius: {
      type: String,
      default: "4px"
    },
    placeholder:{
      type: String,
      default: ""
    },
    minWidth: {
      type: String,
    },
    inputColor: {
      type: String
    }
  },
  data() {
    return {
      internalValue: this.value,
    };
  },
  watch: {
    value(newVal) {
      this.internalValue = newVal;
    },
  },
  methods: {
    handleInput(value) {
      this.name ? this.$emit("change", this.name, value) : this.$emit("change", this.internalValue);
    },
  },
};
</script>

<style scoped lang="scss">
.select-dropdown {
  font-family: HelveticaNeueNormal, sans-serif !important;
  color: #8b949f !important;
  font-weight: normal !important;
  letter-spacing: 0.5px !important;
  border: none;
  outline: none !important;
}
</style>
<style>
.v-select {
  border-radius: 4px;
}

.v-select input::placeholder {
  color: #8B949F;
  font-family: HelveticaNeueMedium, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.vs__dropdown-toggle {
  background: var(--dropdown-background-color, white);
  border: none;
  border-radius: var(--dropdown-border-radius, 4px);
  caret-color: #D1433A;
}

.v-select ul {
  padding: 0px !important;
  margin: 0px !important;
  color: #47505b;
}

.v-select ul li {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 12px;
  font-family: HelveticaNeueNormal, sans-serif;
}

.v-select li {
  display: block !important;
  justify-content: initial !important;
  border-bottom: none !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  align-items: initial !important;
}

.v-select li:last-child {
  margin-bottom: 0px;
}

.v-select .vs__dropdown-menu {
  margin-top: 0.1rem !important;
}

.vs__dropdown-menu .vs__dropdown-option:hover,
.vs__dropdown-menu .vs__dropdown-option--highlight {
  background: rgba(209, 67, 58, 0.10) !important;
  color: inherit !important;
}

.vs__actions-hidden .vs__actions {
  display: none;
}

.setting-detail {
  margin-bottom: auto !important;
}

.vs__dropdown-menu {
  min-width: var(--dropdown-min-width, 160px);
}

.vs__selected {
  color: var(--search-input-color, '#47505b');
}

</style>