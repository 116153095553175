<template>
  <div class="Signup_pop_overlay">
    <div class="modal fade  Choose-sponsorship-model centerChooseSponsorshipModel show" id="login" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" style="display: block; padding-right: 15px;" aria-modal="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="sponsorship_modal">
            <div class="sponsarship_modal_left">
              <div class="spons_avatar">
                <img :src="require(`@/assets/img/svg/artist-img.jpg`)" />
              </div>
              <div class="spons_name">{{ current_page.title }}</div>
              <div class="spons_desg">{{ current_page.type.charAt(0).toUpperCase() + current_page.type.slice(1) }}</div>
              <div class="sponsor_description" v-if="!hideDescription">
                <h4>Sponsorship</h4>
                <p>Sponsor this Artist</p>
              </div>
            </div>
            <div class="sponsorship_modal_right">
              <div class="sponsor_header">
                <div class="back_popup" @click="goBack" v-if="!hideBackButton">
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="30" height="30" rx="4" fill="#E6EAF0" />
                    <path d="M16 19L12 15L16 11" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </div>

                <h3>{{ header }}</h3>
                <div class="ml-auto">
                  <div class="create-video-close fixCreateVideoClose" v-on:click="closeModal">
                    <a href="javascript:;">
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5 4.5L4.5 13.5" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                        <path d="M4.5 4.5L13.5 13.5" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div class="sponsor_body">
                <div class="make_offer">
                  <div class="make_offer_top">
                    <div class="row">

                      <div class="fixMakeOfferContent">
                      <div :class="[fullTitleWidth? 'quoteFirstDiv': 'offerFirstDiv']">
                        <div class="widget_field">
                          <label class="engage_fields_label">{{ title  }}</label>
                          <div class="input-wrapper">
                            <svg 
                              v-if="showEmailIcon" 
                              class="email-svg-icon" 
                              xmlns="http://www.w3.org/2000/svg" 
                              width="18" 
                              height="14" 
                              viewBox="0 0 18 14" 
                              fill="none"
                            >
                              <g opacity="0.7">
                                <path 
                                  d="M3 1H15C15.5299 1.00158 16.0377 1.2128 16.4125 1.58753C16.7872 1.96227 16.9984 2.47005 17 3V11.4C16.9984 11.9299 16.7872 12.4377 16.4125 12.8125C16.0377 13.1872 15.5299 13.3984 15 13.4H3C2.47005 13.3984 1.96227 13.1872 1.58753 12.8125C1.2128 12.4377 1.00158 11.9299 1 11.4V3C1.00158 2.47005 1.2128 1.96227 1.58753 1.58753C1.96227 1.2128 2.47005 1.00158 3 1V1Z" 
                                  stroke="#47505B" 
                                  stroke-width="0.8"
                                />
                                <path 
                                  d="M3 1.5H15.1C15.4948 1.50964 15.8707 1.67077 16.15 1.95001C16.4292 2.22925 16.5904 2.60521 16.6 3V11.4C16.5904 11.7948 16.4292 12.1707 16.15 12.45C15.8707 12.7292 15.4948 12.8904 15.1 12.9H3C2.60521 12.8904 2.22925 12.7292 1.95001 12.45C1.67077 12.1707 1.50964 11.7948 1.5 11.4V3C1.50964 2.60521 1.67077 2.22925 1.95001 1.95001C2.22925 1.67077 2.60521 1.50964 3 1.5V1.5Z" 
                                  stroke="#47505B" 
                                  stroke-width="0.8"
                                />
                                <path 
                                  d="M4.05005 5.22656L9.13933 8.22656L14.05 5.22656" 
                                  stroke="#47505B" 
                                  stroke-width="1.2" 
                                  stroke-linejoin="round"
                                />
                              </g>
                            </svg>
                            <input 
                              type="text" 
                              class="form_control" 
                              :class="{ 'pl-with-icon': showEmailIcon }" 
                              placeholder="Title" 
                              v-model="offerTitle" 
                              :disabled="readOnlyEmail"
                            />
                          </div>
                          <label class="error_msg error_msg_red"
                              v-if="!offerTitle">Required</label>
                        </div>
                      </div>
                      <div class="offerSecondDiv" v-if="showFinancialOffer">
                        <div class="widget_field">
                          <label class="engage_fields_label">Financial Offer</label>
                          <input type="text" class="form_control" placeholder="$" v-model="financialOffer">
                          <label class="error_msg error_msg_red"
                            v-if="!financialOffer">Required</label>
                        </div>
                      </div>
                    </div>
                      <div class="col-12">
                        <div class="widget_field message-field">
                          <label class="engage_fields_label">{{ textAreaTitle  }}</label>
                          <textarea 
                            class="form_control" 
                            style="min-height: 140px;" 
                            v-model="offerMessage" 
                            :placeholder="placeholderText"
                          >
                          </textarea>
                          <label class="error_msg error_msg_red"
                            v-if="!offerMessage">Required</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="make_offer_bottom" @click="validateAndEmit">
                    <p class="quote-res-text" v-html="bottomText"></p>
                    <button class="prime_button_ht30 w200 " type="button">
                      <span class="button-wrapper">
                        <span>{{ buttonText }}</span>
                        <span class="svg-icon" v-if="showButtonIcon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <path d="M21.2131 10.6071L9.54589 10.6071" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.2132 10.6066L6.89426 17.5009L9.54591 10.6066L6.89426 3.71231L21.2132 10.6066Z" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  name: "MakeOfferModal",
  data() {
    return {
      offerTitle: null,
      financialOffer: null,
      offerMessage: null,
    }
  },
  props: {
    hideBackButton:{
      type: Boolean,
      default: false
    },
    header: {
      type: String,
    },
    title: {
      type: String,
    },
    testAreaTitle: {
      type: String,
    },
    bottomText: {
      type: String,
    },
    placeholderText: {
      type: String,
      default: ''
    },
    hideDescription: {
      type: Boolean,
      default: false
    },
    showFinancialOffer: {
      type: Boolean,
      default: false
    },
    showEmailIcon: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
    },
    showButtonIcon: {
      type: Boolean,
      default: false
    },
    fullTitleWidth: {
      type: Boolean,
      default: false
    },
    textAreaTitle: {
      type: String,
    },
    emitText: {
      type: String
    },
    readOnlyEmail: {
      type: Boolean,
      default: false
    },
    email: {
      type: String
    },
  },
  computed: {
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
  },
  mounted() {
    if(this.readOnlyEmail) {
      this.offerTitle = this.email;
    }
  },
  methods: {
    closeModal () {
      this.$emit('close');
    },
    goBack () {
      this.$emit('go-back')
    },
    validateAndEmit() {
      const requiredFields = this.showFinancialOffer
        ? ['offerTitle', 'offerMessage', 'financialOffer']
        : ['offerTitle', 'offerMessage'];

      const isValid = requiredFields.every((field) => this[field]);

      if (isValid) {
        const body = {
          ...(this.readOnlyEmail ? { email: this.offerTitle } : { title: this.offerTitle }),
          message: this.offerMessage,
          page_id: this.$route.params.id,
          ...(this.financialOffer ? { price: this.financialOffer } : {})
        };
        this.$emit(this.emitText, body);
      } else {
        console.error("Validation failed: All required fields must be filled.");
      }
    }
  }
}
</script>
<style scoped lang="scss">
.input-wrapper {
  display: flex;
  align-items: center;
}

.form_control {
  flex: 1;
}

.pl-with-icon {
  padding-left: 32px;
}
.email-svg-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 11px;
}
.svg-icon {
  margin-left: 19px;
}
.button-wrapper {
  display: inline-flex;
  align-items: center;
  margin: auto;
}
.form_control::placeholder {
  color: #8B949F;
  font-family: HelveticaNeueNormal, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.quote-res-text {
  color: #8B949F;
  font-family: HelveticaNeueNormal, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.message-field {
  width: 481px;
}
.sponsor_body {
  width: 520px;
}
.quoteFirstDiv {
  width: 479px;
}
.make_offer_bottom .prime_button_ht30.w200:hover {
  background-color: #D1433A;
  color: #fff;
  .svg-icon svg {
    path {
      stroke: #fff;
    }
  }
}
.sponsarship_modal_left {
  padding: 0px;
}
.spons_avatar {
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}
.sponsor_description>h4 {
  color: #071526;
  font-family: HelveticaNeueBold, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.sponsor_description>p {
  color: #071526;
  font-family: HelveticaNeueNormal, sans-serif;
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
</style>
