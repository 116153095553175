<template>
  <div class="overflow">
    <About/>
    <contact/>
    <SocialLinks/>
    <Partners class="mb-4" />
  </div>
</template>

<script>
import About from './TabComponents/About.vue'
import Contact from './TabComponents/Contact.vue'
import SocialLinks from './TabComponents/SocialLinks.vue'
import Partners from './TabComponents/Partners.vue'

export default {
  name: "AboutTab",
  data() {
    return {
      readMore: false,
      category: null
    }
  },
  components: {
    About,
    SocialLinks,
    Contact,
    Partners
  },
  mounted() {
    this.$store.dispatch('getPageCategories')
  },
  computed: {
    pageCategories() {
      return this.$store.getters.getPageCategories.map(category => {
        return {
          value: category.id,
          text: category.name,
          subCategories: category.page_sub_category
        }
      })
    },
    currentPage() {
      return this.$store.getters.getCurrentPage;
    },
    pageType() {
      return (this.currentPage) ? this.currentPage.type : null
    },
    aboutContent() {
      let {content} = this.currentPage || {}
      return content
    },
    aboutContentDisplayed() {
      if (!this.aboutContent) return ''
      return (this.aboutContent.length > 360 && this.readMore === false) ? this.aboutContent.slice(0, 360) : this.aboutContent
    }
  }
}
</script>

<style scoped lang="scss">
.overflow {
  height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
}
.about-section-page {
  .about-title {
    font-family: HelveticaNeueMedium;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #071526;

  }

  .horizontal-line {
    border-width: 3px !important;
  }
}

</style>
