<template>
  <div class="engage_settings">
    <div class="engage_settings_header">
      <div>
        <h3 class="btn_visibility_header">Button Visibility: {{ make_offer_settings.visibility? 'Published': 'Hidden' }}</h3>
        <h6 class="btn_visibility_text">To publish, turn the switcher on.</h6>
      </div>
      <div class="switch_sm_wrapper">
        <input type="checkbox" v-model="make_offer_settings.visibility" id="switch25"/>
        <label class="switch_sm" for="switch25">
          <div class="switch_toggler"></div>
        </label>
      </div>
    </div>
    <div class="engage_settings_body">
      <div class="engage_set_title engage_set_title_bold">
        <h4 class="header">Companies can make you an offer</h4>
        <p class="text">We’ll show form with fields and financial offer value for companies</p>
      </div>
    </div>
    <div class="engage_settings_footer">
      <h4>Your Button preview:</h4>
      <button type="button">
        <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 1V15.6667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path
              d="M8.33333 3H3.33333C2.71449 3 2.121 3.24583 1.68342 3.68342C1.24583 4.121 1 4.71449 1 5.33333C1 5.95217 1.24583 6.54566 1.68342 6.98325C2.121 7.42083 2.71449 7.66667 3.33333 7.66667H6.66667C7.28551 7.66667 7.879 7.9125 8.31658 8.35008C8.75417 8.78767 9 9.38116 9 10C9 10.6188 8.75417 11.2123 8.31658 11.6499C7.879 12.0875 7.28551 12.3333 6.66667 12.3333H1"
              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Make Offer
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      make_offer_settings: {
        visibility: false
      },
      showMakeOfferModal: false
    }
  },
  props: {
    makeOfferSettings: {
      type: Object,
      default: ()=> {}
    }
  },
  watch: {
    'make_offer_settings.visibility'() {
      this.$emit('getMakeOfferData', this.make_offer_settings)
    },
    makeOfferSettings() {
      this.setMakeOfferSettingsData()
    }
  },
  mounted() {
    this.setMakeOfferSettingsData()
  },
  methods: {
    setMakeOfferSettingsData () {
      this.make_offer_settings = this.makeOfferSettings
    },
  }
}
</script>
