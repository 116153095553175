<template>
  <div
    @click="closeDateAndTimePicker"
    class="events-container-info">
    <Error 
      v-if="seeErrorType"
      text="Fin in the missing fields highlighted below" 
    />
    <h3 >Main Information</h3>
    <div class="left leftMainFix">
      <div class="left-title">
        <label for="name"
          ><p>Name your Event</p>
          <input
            type="text"
            v-model="info.eventName"
            @input="requireName"
            :class="{ required: eventNameReq, 'event-name': true }"
          />
        </label>
        <label for="page">
          <p>Choose Page, Club or Party</p>
          <div
            id="eventArtist"
            class="left-select-co for-height"
            @click.prevent.stop="info.disable ? '' : (eventArtist = !eventArtist)"
            v-click-outside="hideArtist"
            :class="{ required: selectedArtistReq }"
          >
            <div v-if="!info.selectedArtist" class="flex alignItemsCenter">
              <div class="imgArtist"></div>
              <p class="mb-0">Artist or Company Page</p>
            </div>

            <div v-else class="flex alignItemsCenter">
              <div class="imgArtist">
                <img :src="`${pageImg}`" alt="" />
              </div>
              <div class="titleArtist">{{ info.selectedArtist }}</div>
            </div>
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="#47505B"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div
              v-if="eventArtist"
              class="genres-popup"
              style="max-height: 200px; overflow: auto"
              @click.stop="eventArtist = false"
            >
              <div
                class="item flex"
                v-for="page in this.$store.getters.GET_MY_PAGES_TYPE.data"
                :key="page.id"
                @click="handleArtist(page.title, page.artwork_url, page.id)"
              >
                <div class="flex">
                  <div class="imgArtist">
                    <img :src="`${page.artwork_url}`" alt="" />
                  </div>
                  <div class="modalItemArtist">
                    <div class="artistTitle">{{ page.title }}</div>
                    <div class="artistPage">
                      {{ page.page_category.name }} page
                    </div>
                  </div>
                </div>
                <div v-if="page.id === pageId">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="8" cy="8" r="7.5" stroke="#0075FF" />
                    <circle cx="8" cy="8" r="5" fill="#D1433A" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </label>
      </div>

      <div class="left-title">
        <label for="location"
          ><p>Location</p>
          <GoogleMapAndLocation
            @getLocation="getLocation"
            :class="{ required: gMapDataReq }"
          />
        </label>
        <label for="category">
          <p>Event Type</p>
          <div
            class="left-select-co"
            @click="eventPopup = !eventPopup"
            v-click-outside="hideCategory"
            :class="{ required: selectedEventReq }"
          >
            <p class="mb-0" v-if="!info.selectedEvent">Choose Category</p>
            <p class="mb-0" v-else>{{ info.selectedEvent.title }}</p>
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="#47505B"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div
              v-if="eventPopup"
              class="genres-popup"
              @click.stop="eventPopup = false"
            >
              <perfect-scrollbar>
                <div
                  class="item"
                  v-for="event in eventTypes"
                  :key="event.id"
                  @click="handleEvent(event)"
                >
                  {{ event.title }}
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </label>
      </div>

      <div class="left-date flex">
        <div class="flex">
          <div>
            <div class="start-date">
              <h4>Start Date</h4>
              <div
                class="date-picker-container"
                @click.prevent.stop="showDateAndTimePicker('showS')"
                :class="{ required: startDateReq }"
              >
                <p class="mb-0" v-if="info.startDate">
                  {{ isMoment(info.startDate) }}
                </p>
                <p class="mb-0" v-else>DD/MM/YYYY</p>
                <date-picker
                  v-model="info.startDate"
                  type="date"
                  :show-week-number="false"
                  :open="show"
                  :confirm="true"
                  :popup-class="'new-event-post-start-date-picker'"
                  confirm-text="DONE"
                  @confirm="selectValue"
                ></date-picker>
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.60156 4.3V0"
                    stroke="#8B949F"
                    stroke-width="1.5"
                  />
                  <path
                    d="M11.3984 4.3V0"
                    stroke="#8B949F"
                    stroke-width="1.5"
                  />
                  <path
                    d="M3.09998 2.09961H13.8C14.356 2.10276 14.8883 2.32487 15.2815 2.71802C15.6746 3.11116 15.8969 3.64348 15.9 4.19946V12.7996C15.8969 13.3555 15.6746 13.8879 15.2815 14.281C14.8883 14.6742 14.356 14.8963 13.8 14.8994H3.09998C2.54399 14.8963 2.01168 14.6742 1.61853 14.281C1.22538 13.8879 1.00315 13.3555 1 12.7996V4.19946C1.02033 3.64899 1.24807 3.12657 1.63757 2.73706C2.02708 2.34755 2.5495 2.11994 3.09998 2.09961V2.09961Z"
                    stroke="#8B949F"
                    stroke-width="1.5"
                  />
                  <path
                    d="M3.10156 6.90039H13.9016"
                    stroke="#8B949F"
                    stroke-width="1.5"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div>
            <div class="start-time ml-10">
              <h4>Start Time</h4>
              <div
                class="date-picker-container w-140"
                @click.prevent.stop="showDateAndTimePicker('showSTime')"
                :class="{ required: startTimeReq }"
              >
                <p class="mb-0" v-if="info.startTime">{{ info.startTime }}</p>
                <p class="mb-0" v-else>Start Time</p>
                <date-picker
                  v-model="info.startTime"
                  type="time"
                  format="hh:mm a"
                  value-type="format"
                  :open="showSTime"
                  :confirm="true"
                  :popup-class="'new-event-post-start-time-picker'"
                  confirm-text="DONE"
                  @confirm="showSTime = false"
                ></date-picker>
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L5 5L9 1"
                    stroke="#47505B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="flex">
          <div>
            <div class="start-date">
              <h4>End Date</h4>
              <div
                class="date-picker-container"
                @click.prevent.stop="showDateAndTimePicker('showE')"
                :class="{ required: endDateReq }"
              >
                <p class="mb-0" v-if="info.endDate">
                  {{ isMoment(info.endDate) }}
                </p>
                <p class="mb-0" v-else>DD/MM/YYYY</p>
                <date-picker
                  v-model="info.endDate"
                  type="date"
                  :show-week-number="false"
                  :open="showEDate"
                  :confirm="true"
                  :popup-class="'new-event-post-end-date-picker'"
                  confirm-text="DONE"
                  @confirm="showEDate = false"
                ></date-picker>
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.60156 4.3V0"
                    stroke="#8B949F"
                    stroke-width="1.5"
                  />
                  <path
                    d="M11.3984 4.3V0"
                    stroke="#8B949F"
                    stroke-width="1.5"
                  />
                  <path
                    d="M3.09998 2.09961H13.8C14.356 2.10276 14.8883 2.32487 15.2815 2.71802C15.6746 3.11116 15.8969 3.64348 15.9 4.19946V12.7996C15.8969 13.3555 15.6746 13.8879 15.2815 14.281C14.8883 14.6742 14.356 14.8963 13.8 14.8994H3.09998C2.54399 14.8963 2.01168 14.6742 1.61853 14.281C1.22538 13.8879 1.00315 13.3555 1 12.7996V4.19946C1.02033 3.64899 1.24807 3.12657 1.63757 2.73706C2.02708 2.34755 2.5495 2.11994 3.09998 2.09961V2.09961Z"
                    stroke="#8B949F"
                    stroke-width="1.5"
                  />
                  <path
                    d="M3.10156 6.90039H13.9016"
                    stroke="#8B949F"
                    stroke-width="1.5"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div>
            <div class="start-time ml-10">
              <h4>End Time</h4>
              <div
                class="date-picker-container w-140"
                @click.prevent.stop="showDateAndTimePicker('showETime')"
                :class="{ required: endTimeReq }"
              >
                <p class="mb-0" v-if="info.endTime">{{ info.endTime }}</p>
                <p class="mb-0" v-else>End Time</p>
                <date-picker
                  v-model="info.endTime"
                  type="time"
                  format="hh:mm a"
                  value-type="format"
                  :open="showETime"
                  :confirm="true"
                  :popup-class="'new-event-post-end-time-picker'"
                  :show-second="false"
                  confirm-text="DONE"
                  @confirm="showETime = false"
                ></date-picker>
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L5 5L9 1"
                    stroke="#47505B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="left-select flex">
        <div class="left-select-genre">
          <h4>Genre</h4>
          <div
            class="left-select-co"
            @click.prevent.stop="genresPopup = !genresPopup"
            v-click-outside="hideGenresPopup"
            :class="{ required: selectedGenresReq }"
          >
            <p v-if="info.selectedGenres.length < 1">Choose Genre</p>
            <div v-else class="genres-select-wraper">
              <span v-for="item in info.selectedGenres" :key="item.id"
                >{{ item.name }}
                <svg
                  @click.prevent.stop="removeGenre(item.id)"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 2.5L2.5 7.5"
                    stroke="#47505B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.5 2.5L7.5 7.5"
                    stroke="#47505B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="#47505B"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div v-if="genresPopup" class="genres-popup">
              <perfect-scrollbar>
                <div
                  class="item"
                  v-for="genre in genres"
                  :key="genre.id"
                  @click.prevent.stop="handleGenres(genre)"
                >
                  {{ genre.name }}
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </div>
        <div class="left-select-venue">
          <h4>Venue Type</h4>
          <div
            class="left-select-co"
            @click.prevent.stop="venuePopup = !venuePopup"
            v-click-outside="hideVenue"
            :class="{ required: selectedVenueReq }"
          >
            <p v-if="!info.selectedVenue">Choose Venue</p>
            <p v-else>{{ info.selectedVenue.title }}</p>
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="#47505B"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div
              v-if="venuePopup"
              class="genres-popup"
              @click.prevent.stop="venuePopup = false"
            >
              <perfect-scrollbar>
                <div
                  class="item"
                  v-for="venue in venues"
                  :key="venue.id"
                  @click.prevent.stop="handleVenue(venue)"
                >
                  {{ venue.title }}
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </div>
      </div>
      <div class="left-editor">
        <h4>Description</h4>
        <quill-editor ref="myTextEditor" v-model="info.editorData" :config="customToolbar"/>
        <!-- <ckeditor
          class="newEvent-editor"
          v-model="info.editorData"
          :config="editorConfig"
        ></ckeditor> -->
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "/public/css/vue2-date-picker.scss";
import { mapActions } from "vuex";
// import CKEditor from "ckeditor4-vue";
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.snow.css';
import GoogleMapAndLocation from "@/components/Common/Google Location/GoogleMapAndLocation";
import mixin from "../../../../mixins/sweetAlert";
import Error from "@/components/Error.vue"
export default {
  components: {
    DatePicker,
    quillEditor,
    // ckeditor: CKEditor.component,
    GoogleMapAndLocation,
    Error,
  },
  mixins: [mixin],
  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "code-block"]
      ],
      eventNameReq: false,
      selectedArtistReq: false,
      gMapDataReq: false,
      selectedEventReq: false,
      startDateReq: false,
      startTimeReq: false,
      endDateReq: false,
      endTimeReq: false,
      selectedGenresReq: false,
      selectVenueReq: false,
      show: false,
      showSTime: false,
      showETime: false,
      showEDate: false,
      genresPopup: false,
      venuePopup: false,
      selectedVenueReq: false,
      // editorConfig: {},
      cover: null,
      genres: [],
      venues: [],
      activePrivacy: {},
      eventArtist: false,
      eventPopup: false,
      eventTypes: [],
      pageId: "",
      pageImg: "",
      info: {
        eventName: "",
        page_id: "",
        selectedArtist: null,
        gMapData: null, //{}
        selectedEvent: null,
        startDate: null,
        startTime: null,
        endTime: null,
        endDate: null,
        selectedGenres: [],
        selectedVenue: null, //[]
        editorData: null,
        disable: false,
      },
    };
  },
  computed: {
    seeErrorType() {
      return (
        this.eventNameReq ||
        this.selectedArtistReq ||
        this.gMapDataReq ||
        this.selectedEventReq ||
        this.startDateReq ||
        this.startTimeReq ||
        this.endDateReq ||
        this.endTimeReq ||
        this.selectedGenresReq ||
        this.selectedVenueReq
      );
    },
    getPages() {
      return this.$store.getters.GET_MY_PAGES_TYPE.data;
    },
    eventModal() {
      return this.$store.getters.getEventModal;
    },
  },
  async created() {
    await this.getGenres();
    await this.getVenue();
    await this.getETypes();
    await this.getMyPagesByType();
  },
  watch: {
    "info.startDate"() {
      this.startDateReq = false;
    },
    "info.startTime"() {
      this.startTimeReq = false;
    },
    "info.endDate"() {
      this.endDateReq = false;
    },
    "info.endTime"() {
      this.endTimeReq = false;
    },
    eventModal() {
      if (this.eventModal) {
        this.setPageData();
        this.$store.commit("SET_EVENT_MODAL", false);
      }
    },
  },
  methods: {
    ...mapActions([
      "getGenersType",
      "getVenueTypes",
      "getEventTypes",
      "getMyPagesByType",
    ]),
    requireName() {
      this.eventNameReq = false;
    },
    showDateAndTimePicker(payload) {
      if (payload === "showS") {
        this.show = !this.show;
        this.showEDate = false;
        this.showETime = false;
        this.showSTime = false;
      } else if (payload === "showE") {
        this.showEDate = !this.showEDate;
        this.show = false;
        this.showETime = false;
        this.showSTime = false;
      } else if (payload === "showSTime") {
        this.showSTime = !this.showSTime;
        this.show = false;
        this.showEDate = false;
        this.showETime = false;
      } else if (payload === "showETime") {
        this.showETime = !this.showETime;
        this.show = false;
        this.showEDate = false;
        this.showSTime = false;
      }
    },
    handleInformation() {
      let infoValid = Object.entries(this.info);
      infoValid.forEach((el) => {
        if (
          (el[1] === "" || el[1] === null || el[1].length < 1) &&
          el[0] != "editorData"
        ) {
          this[el[0] + "Req"] = true;
        }
      });
      this.$emit("setNewDataEvent", this.info);
    },
    hideArtist() {
      this.eventArtist = false;
    },
    hideVenue() {
      this.venuePopup = false;
    },
    hideGenresPopup() {
      this.genresPopup = false;
    },
    hideCategory() {
      this.eventPopup = false;
    },
    async getGenres() {
      try {
        const { information } = await this.getGenersType();
        this.genres =
          information && information.length
            ? information.map((genre) => {
                return {
                  id: genre.id,
                  name: genre.name,
                };
              })
            : [];
      } catch (error) {
        console.error(error);
      }
    },
    async getVenue() {
      try {
        const { status, information } = await this.getVenueTypes();
        this.venues = information;
      } catch (error) {
        console.error(error);
      }
    },
    async getETypes() {
      try {
        const { status, information } = await this.getEventTypes();
        this.eventTypes = information;
      } catch (error) {
        console.error(error);
      }
    },
    getLocation(data) {
      this.gMapDataReq = false;
      this.info.gMapData = data;
    },
    selectValue() {
      this.show = false;
    },
    isMoment(date) {
      return moment(date).startOf("day").format("Do MMM ,YYYY");
    },
    handleGenres(genre) {
      if (this.info.selectedGenres.some((elem) => elem.id === genre.id)) {
        this.genresPopup = false;
        return;
      } else {
        this.info.selectedGenres.push(genre);
      }
      this.selectedGenresReq = false;
      this.genresPopup = false;
    },
    handleVenue(venue) {
      this.info.selectedVenue = venue;
      this.venuePopup = false;
      this.selectedVenueReq = false;
    },
    handlePrivacy(data) {
      this.activePrivacy = data;
    },
    handleEvent(event) {
      this.selectedEventReq = false;
      this.info.selectedEvent = event;
    },
    handleArtist(title, img, id) {
      this.pageImg = img;
      this.info.selectedArtist = title;
      this.info.page_id = id;
      this.pageId = id;
      this.selectedArtistReq = false;
    },
    removeGenre(id) {
      this.info.selectedGenres = this.info.selectedGenres.filter(
        (elem) => elem.id !== id
      );
    },
    setPageData() {
      console.log("Checking Route ==>", this.$route.params.id);
      if (this.eventModal) {
        console.log("Checking Call ==>", this.getPages);
        if (this.getPages && this.getPages.length > 0) {
          const data = this.getPages.filter(
            (e) => e.id.toString() === this.$route.params.id.toString()
          );
          this.info.selectedArtist = data[0].title;
          this.info.page_id = data[0].id;
          this.pageId = data[0].id;
          this.pageImg = data[0].artwork_url;
        }
        this.disable = true;
      }
    },
    closeDateAndTimePicker() {
      if (this.show) {
        this.show = false;
      } else if (this.showSTime) {
        this.showSTime = false;
      } else if (this.showEDate) {
        this.showEDate = false;
      } else if (this.showETime) {
        this.showETime = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.error_section {
  position: sticky;
  top: 0px;
  background: rgba(255, 0, 57, 0.1);
  z-index: 2;
  display: flex;
  align-items: center;
  border: 1px solid #ff0039;
  border-radius: 4px;
  padding: 10px 30px;
  margin-bottom: 22px;
  .wr_icon {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #ff0039;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
  }
  h4 {
    font-family: HelveticaNeue, sans-serif !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 15.51px !important;
    text-align: left;
    text-underline-position: from-font !important;
    text-decoration-skip-ink: none !important;
    color: rgba(7, 21, 38, 1) !important;
  }
}
.required {
  border-radius: 4px;
  border: 1px solid #ff0039 !important;
}
.inpSearch {
  padding: 20px;
  input {
    height: 30px;
    padding: 7px 40px;
  }
}
.left-actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.titleArtist {
  font-family: HelveticaNeueMedium;
  font-size: 14px;
  line-height: 17px;
  color: #47505b;
}
.modalItemArtist {
  .artistTitle {
    font-family: HelveticaNeueBold;
    font-size: 13px;
    line-height: 16px;
    color: #071526;
  }
  .artistPage {
    font-family: HelveticaNeueNormal;
    font-size: 11px;
    line-height: 13px;
    color: rgba(7, 21, 38, 0.6);
  }
}
.imgArtist {
  width: 30px;
  height: 30px;
  background: #c4c4c4;
  border-radius: 4px;
  margin-right: 20px;
  img {
    border-radius: 4px;
    width: 100%;
  }
}
.cancel-popup-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
}
.events-container-info {
  margin-top: 32px;
  label {
    margin-bottom: 0 !important;
  }
  h4 {
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 15.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(71, 80, 91, 1);
  }
  p {
    margin-bottom: 0;
  }
  h3 {
    font-family: HelveticaNeue,sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.98px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(7, 21, 38, 1);
  }
  .left {
    background-color: #fff;
    width: 770px;
    max-width: 100%;
    &-title {
      display: flex;
      justify-content: space-between;
      margin-top: 17px;
      p {
        font-family: Lato,sans-serif;
        font-size: 13px;
        font-weight: 700;
        line-height: 15.6px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: rgba(71, 80, 91, 1);
        margin-bottom: 7px;
      }
      input {
        width: 340px;
        max-width: 100%;
        border: 1px solid #e6eaf0;
        border-radius: 4px;
        height: 40px;
        font-family: HelveticaNeueNormal, sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 15.51px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: rgba(7, 21, 38, 1);
      }
      input::placeholder {
        font-family: HelveticaNeueNormal, sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 15.51px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: rgba(71, 80, 91, 1);
      }
    }
    &-date {
      justify-content: space-between;
      margin-top: 17px;
    }
    &-select {
      justify-content: space-between;
      margin-top: 17px;
      &-co {
        border: 1px solid #e6eaf0;
        border-radius: 4px;
        width: 340px;
        max-width: 100%;
        min-height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 13px 0 10px;
        cursor: pointer;
        position: relative;
        span,
        p {
          color: rgba(7, 21, 38, 0.6);
          font-size: 13px;
          line-height: 16px;
          font-family: HelveticaNeueMedium;
        }
        span {
          background: #e6eaf0;
          border-radius: 2px;
          min-width: 80px;
          height: 20px;
          padding: 0 7px 0 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 10px;
        }
        .genres-popup {
          background: #ffffff;
          box-shadow: 0px 10px 20px rgba(74, 75, 77, 0.16);
          border-radius: 4px;
          width: 100%;
          position: absolute;
          z-index: 999;
          padding: 10px 0;
          left: 0;
          top: 0;
          .ps {
            max-height: 310px;
          }
          .item {
            justify-content: space-between;
            color: #071526;
            font-size: 13px;
            line-height: 16px;
            font-family: HelveticaNeueNormal;
            padding: 7px 20px;
            transition: all 0.3s;
            &:hover {
              background: #D1433A1A;
              color: #D1433A;
            }
          }
        }
      }
    }
    &-editor {
      margin-top: 27px;
    }
    &-actions {
      display: flex;
      margin-top: 30px;
      button {
        height: 40px;
        font-size: 12px;
        line-height: 16px;
        font-family: HelveticaNeueBold;
        border-radius: 4px;
        text-transform: uppercase;
      }
      .create {
        background: #D1433A;
        width: 130px;
        max-width: 100%;
        color: #fff;
        border: none;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        svg {
          margin-left: 8px;
        }
      }
      .SaveDraft {
        border: 1px solid #D1433A;
        color: #D1433A;
        width: 130px;
        background: #ffffff;
      }
      .cancel {
        border: none;
        outline: none;
        width: 130px;
        max-width: 100%;
        background: #e6eaf0;
        color: #47505b;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.date-picker-container {
  border-radius: 4px;
  border: 1px solid #e6eaf0;
  width: 190px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  p {
    color: #47505b;
    font-size: 13px;
    line-height: 16px;
    font-family: HelveticaNeueNormal,sans-serif;
  }
}

.custom-border {
  border-radius: 4px 4px 0 0 !important;
}
.arrow-down {
  transform: rotate(-180deg) !important;
}
.newEvent-editor {
  height: 170px;
  border-radius: 4px !important;
  border: 1px solid #e6eaf0 !important;
}
.bor_bot_inp {
  border-bottom: 1px solid #e6eaf0;
}
.pad_input {
  padding: 7px 20px;
}
.flex {
  display: flex;
}
.alignItemsCenter {
  align-items: center;
}
.genres-select-wraper {
  display: flex;
  flex-wrap: wrap;
}
.for-height {
  height: 40px;
}
.mb-17 {
  margin-bottom: 17px;
}
svg {
  transition: all 0.3s;
}

.left-select-co {
  p {
    font-family: HelveticaNeue,sans-serif !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    line-height: 15.87px !important;
    text-align: left !important;
    text-underline-position: from-font !important;
    text-decoration-skip-ink: none !important;
    color: rgba(7, 21, 38, 0.6) !important;
  }
}
</style>

<style lang="scss">
.new-event-post-start-date-picker {
  box-shadow: 0 10px 20px 0 rgba(74, 75, 77, 0.16);
  top: unset !important;
  left: unset !important;
  position: fixed !important;

  @media screen and (max-width: 1920px) {
    margin-top: 32rem;
    margin-left: 37.8rem;
  }

  @media screen and (max-width: 1710px) {
    margin-top: 28.4rem;
    margin-left: 31.3rem;
  }

  @media screen and (min-width: 2048px) {
    margin-top: 34.5rem;
    margin-left: 41.9rem;
  }
}

.new-event-post-end-date-picker {
  box-shadow: 0 10px 20px 0 rgba(74, 75, 77, 0.16);
  top: unset !important;
  left: unset !important;
  position: fixed !important;

  @media screen and (max-width: 1920px) {
    margin-top: 32rem;
    margin-left: 61rem;
  }

  @media screen and (max-width: 1710px) {
    margin-top: 28.4rem;
    margin-left: 54.3rem;
  }

  @media screen and (min-width: 2048px) {
    margin-top: 34.5rem;
    margin-left: 65rem;
  }
}

.new-event-post-start-time-picker {
  box-shadow: 0 10px 20px 0 rgba(74, 75, 77, 0.16);
  top: unset !important;
  left: unset !important;
  position: fixed !important;

  .mx-datepicker-content {

    .mx-datepicker-body {

      .mx-time {

        .mx-time-content {

          .mx-time-columns {

            .mx-time-list {

              .active {
                color: #D1433A;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1920px) {
    margin-top: 32rem;
    margin-left: 50.3rem;
  }

  @media screen and (max-width: 1710px) {
    margin-top: 28.4rem;
    margin-left: 43.8rem;
  }

  @media screen and (min-width: 2048px) {
    margin-top: 34.5rem;
    margin-left: 54.4rem;
  }
}

.new-event-post-end-time-picker {
  box-shadow: 0 10px 20px 0 rgba(74, 75, 77, 0.16);
  top: unset !important;
  left: unset !important;
  position: fixed !important;

  .mx-datepicker-content {

    .mx-datepicker-body {

      .mx-time {

        .mx-time-content {

          .mx-time-columns {

            .mx-time-list {

              .active {
                color: #D1433A;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1920px) {
    margin-top: 32rem;
    margin-left: 68.3rem;
  }

  @media screen and (max-width: 1710px) {
    margin-top: 28.4rem;
    margin-left: 61.55rem;
  }

  @media screen and (min-width: 2048px) {
    margin-top: 34.5rem;
    margin-left: 72.2rem;
  }
}

.genres-select-wraper {
  span {
    background-color: rgba(209, 67, 58, 0.1) !important;
  }
}


.events-container-info .cke_contents {
  height: 98px !important;
}

.left-title {

  .event-name {
    padding-left: 0.7rem;
  }

  .pac-target-input {
    width: 340px;
    max-width: 100%;
    border: 1px solid #e6eaf0;
    border-radius: 4px;
    font-family: HelveticaNeue, sans-serif !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 15.51px !important;
    text-align: left;
    text-underline-position: from-font !important;
    text-decoration-skip-ink: none !important;
    color: rgba(7, 21, 38, 1) !important;
    padding-left: 10px;
    &::placeholder {
      font-family: HelveticaNeue, sans-serif !important;
      font-size: 13px !important;
      font-weight: 400 !important;
      line-height: 15.51px !important;
      text-align: left;
      text-underline-position: from-font !important;
      text-decoration-skip-ink: none !important;
      color: rgba(71, 80, 91, 1);
    }
  }
}
</style>
<style lang="scss">
.newEvent-editor {
  .cke_toolbar:nth-child(1) {
    display: none;
  }
  .cke_toolbar:nth-child(2) {
    display: none;
  }
  .cke_toolbar:nth-child(3) {
    display: none;
  }
  .cke_toolbar:nth-child(4) {
    display: none;
  }
  .cke_toolbar:nth-child(5) {
    display: none;
  }
  .cke_toolbar:nth-child(6) {
    display: none;
  }
  .cke_toolbar:nth-child(9) {
    display: none;
  }
  .cke_toolbar:nth-child(10) {
    display: none;
  }
  .cke_toolbar:nth-child(11) {
    display: none;
  }
  .cke_toolbar:nth-child(8) {
    display: block !important;
    .cke_toolgroup :nth-child(3) {
      display: none;
    }
    .cke_toolgroup :nth-child(5) {
      display: none;
    }
  }
}
</style>
<style scoped>
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4ccd6;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c4ccd6;
}
</style>
