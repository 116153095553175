<template>
    <div class="Signup_pop_overlay">
      <div class="modal fade Choose-sponsorship-model show centerAlignModal" id="login" tabindex="-1" role="dialog"
           aria-labelledby="exampleModalLabel" style="display: block; padding-right: 15px;" aria-modal="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="sponsorship_modal">
              <div class="sponsarship_modal_left">
                <div class="spons_avatar">
                  <img :src="require(`@/assets/img/svg/artist-img.jpg`)" />
                </div>
                <div class="spons_name">NHOAH</div>
                <div class="spons_desg">Artist</div>
                <div class="sponsor_description">
                  <h4>Sponsor this artist</h4>
                  <p style="white-space: nowrap; font-size: 11px; font-family: HelveticaNeueNormal, sans-serif ;">Get access to membership perks</p>
                </div>
              </div>
              <div class="sponsorship_modal_right">
                <div class="sponsor_header">
                  <h3>Choose Membership Plan</h3>
                  <div class="ml-auto">
  
                    <div class="create-video-close fixCreateVidoClose" v-on:click="closeModal">
                      <a href="javascript:;">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.5 4.5L4.5 13.5" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                          <path d="M4.5 4.5L13.5 13.5" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                        </svg>
                      </a>
                    </div>
  
                  </div>
                </div>
                <div class="sponsor_body">
                  <div class="sponsor_listing">
                    <div class="sponsor_listing_header">
                      <div>
                        <h3>Light</h3>
                        <h4>$ 4,99 / month</h4>
                      </div>
                      <button style="font-family: HelveticaNeueBold, sans-serif !important;" type="button" class="outline_primary_button_md font_13 text-capitalize w130">$ 4,99/month
                      </button>
                    </div>
                    <div class="sponsor_listing_body">
                      <ul>
                        <li>Free 2 Livestreaming Shows</li>
                        <li>Advance listen of new music</li>
                        <li>Free 1 Album &amp; 1 Track</li>
                        <li>10% Discount on Merch</li>
                      </ul>
                    </div>
                  </div>
                  <div class="sponsor_listing">
                    <div class="sponsor_listing_header">
                      <div>
                        <h3>Medium</h3>
                        <h4>$ 9,99 / month</h4>
                      </div>
                      <button style="font-family: HelveticaNeueBold, sans-serif !important;" type="button" class="outline_primary_button_md font_13 text-capitalize w130">$ 9,99/month
                      </button>
                    </div>
                    <div class="sponsor_listing_body">
                      <ul>
                        <li>Free 4 Livestreaming Shows</li>
                        <li>Advance listen of new music</li>
                        <li>Free 1 Album &amp; 1 Track</li>
                        <li>20% Discount on Merch</li>
                      </ul>
                    </div>
                  </div>
                  <div class="sponsor_listing">
                    <div class="sponsor_listing_header">
                      <div>
                        <h3>Advance Sponsorship (for Companies)</h3>
                        <h4>Your offer to Artist</h4>
                      </div>
                      <button @click="makeOffer" type="button"
                      class="outline_primary_button_md font_13 text-capitalize w130 makeOfferBtn">
                      MAKE OFFER
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script type="application/javascript">
  export default {
    name: "MembershipModal",
    data() {
      return {}
    },
    components: {},
    methods: {
      closeModal: function () {
        this.$emit('close');
      },
      makeOffer () {
        this.$emit('open-make-offer-model');
      }
    }
  }
  </script>
<style lang="scss" scoped>
.sponsarship_modal_left {
  padding: 0px;
}
.spons_avatar {
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}
</style>