<template>
  <div class="center_centerbar_wraper mt-4 mb-5">
    <div id="blocking" class="settings-content right-setting-bar active">
      <div class="set-block setting-header d-flex flex-column">
        <div class="set-block-head d-flex flex-row align-items-center">
          <div class="svg-icon">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.3911 12.4518C10.1902 13.4202 8.66283 14 7 14C3.13401 14 0 10.866 0 7C0 5.33717 0.579792 3.80976 1.54824 2.6089L11.3911 12.4518ZM11.8649 12.0332L11.4187 12.4794L11.3911 12.4518C11.5553 12.3193 11.7134 12.1796 11.8649 12.0332ZM11.8649 12.0332C11.9219 11.978 11.978 11.9219 12.0332 11.8649L11.8649 12.0332ZM12.4518 11.3911L12.4794 11.4187L12.0332 11.8649C12.1796 11.7134 12.3193 11.5553 12.4518 11.3911ZM12.4518 11.3911L2.6089 1.54824C3.80976 0.579792 5.33717 0 7 0C10.866 0 14 3.13401 14 7C14 8.66283 13.4202 10.1902 12.4518 11.3911ZM2.13946 1.96261L2.58137 1.52071L2.6089 1.54824C2.44628 1.6794 2.28964 1.81768 2.13946 1.96261ZM1.96261 2.13946L2.13946 1.96261C2.07947 2.02051 2.02051 2.07947 1.96261 2.13946ZM1.96261 2.13946C1.81768 2.28964 1.6794 2.44628 1.54824 2.6089L1.52071 2.58137L1.96261 2.13946Z"
                fill="#47505B"
              />
            </svg>
          </div>
          <span> Manage Blocking</span>
          <button
            type="button"
            class="btn-view ml-auto"
            @click="redirectToProfilePage(username)"
          >
            <span class="pr-2">
              <EyeIcon />
          </span>
            View Profile
          </button>
        </div>
        <div class="set-block-content d-flex flex-column blocking">
          <div
            class="set-notyfication warning d-flex align-items-center flex-row width730"
          >
            <div class="svg-icon">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 17.9874C14.4113 17.9874 17.9874 14.4113 17.9874 10C17.9874 5.58868 14.4113 2.0126 10 2.0126C5.58868 2.01261 2.0126 5.58868 2.0126 10C2.01261 14.4113 5.58868 17.9874 10 17.9874ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 -4.82823e-07 10 0C4.47715 4.82823e-07 -4.82823e-07 4.47715 0 10C4.82823e-07 15.5228 4.47715 20 10 20Z" fill="#D1433A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 -4.82823e-07 10 0C4.47715 4.82823e-07 -4.82823e-07 4.47715 0 10C4.82823e-07 15.5228 4.47715 20 10 20ZM10 17.9874C14.4113 17.9874 17.9874 14.4113 17.9874 10C17.9874 5.58868 14.4113 2.0126 10 2.0126C5.58868 2.01261 2.0126 5.58868 2.0126 10C2.01261 14.4113 5.58868 17.9874 10 17.9874Z" fill="#D1433A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99978 11.0058C9.44401 11.0058 8.99347 10.5552 8.99347 9.99946V6.30198C8.99347 5.74622 9.44401 5.29568 9.99978 5.29568C10.5555 5.29568 11.0061 5.74622 11.0061 6.30198V9.99946C11.0061 10.5552 10.5555 11.0058 9.99978 11.0058ZM11.0043 13.6975C11.0043 14.2533 10.5538 14.7038 9.998 14.7038H9.99001C9.43425 14.7038 8.98371 14.2533 8.98371 13.6975C8.98371 13.1417 9.43425 12.6912 9.99001 12.6912H9.998C10.5538 12.6912 11.0043 13.1417 11.0043 13.6975Z" fill="#D1433A"/>
                </svg>

            </div>
            <span style="margin-top: 1px;">
              Once you block someone, that person can no longer see things you
              post on your timeline
            </span>
          </div>
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
          <div class="search-lg d-flex flex-row align-items-center width730">
            <div class="svg-icon">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="svg-search-ico"
              >
                <path
                  class="c-line-1"
                  d="M12.1 6.54294C12.1 3.48162 9.61518 0.999937 6.55 0.999937C3.48482 0.999937 1 3.48162 1 6.54294C1 9.60425 3.48482 12.0859 6.55 12.0859C9.61518 12.0859 12.1 9.60425 12.1 6.54294Z"
                  stroke="#47505B"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  class="c-line-1"
                  d="M12.5 12.501L14.5 14.501"
                  stroke="#47505B"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              v-model="search"
              placeholder="Search for blocked people"
            />
          </div>
          <table class="table blocking-table updateBlockingTable" v-if="getBlockedUsersList && getBlockedUsersList.length>0">
            <thead>
              <tr>
                <th scope="col">User</th>
                <th scope="col">Block Date</th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="user in getBlockedUsersList" :key="user.id">
              <th scope="row">
                <div class="user">
                  <div class="user-wrapper">
                    <div class="img-wrapper">
                      <img
                        src="../../../../src/assets/img/defaults/avatar-img.svg"
                        alt=""
                      />
                      <span class="user-status"></span>
                    </div>
                  </div>
                  <div class="name">{{ user.blocked_user.title }}</div>
                </div>
              </th>
              <td>
                <div class="date">
                  {{  moment(user.created_at).format('DD MMM YYYY, hh:mm A') }}
                  <div @click="openBlockModal(user.block_id, user.blocked_user.type)" class="unblock">
                    <div class="unblock-text">Unblock</div>
                    <div class="svg-icon">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          class="c-line-1"
                          d="M12 4L4 12"
                          stroke="#D1433A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          class="c-line-1"
                          d="M4 4L12 12"
                          stroke="#D1433A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <p v-else style="margin-top: 29px; text-align: center; font-size: 14px"> Blocked Users not found </p>
<!--          <div class="row">-->
<!--            <div class="col text-right">-->
<!--              <button type="submit" class="save-btn">Save Changes</button>-->
<!--            </div>-->
<!--          </div>-->
          <!-- <div class="d-flex flex-column table-row">
            <div class="d-flex flex-row align-items-center list-header">
              <div>User</div>
              <div>Block Date</div>
            </div>
            <div
              v-for="(eachOp, index) in blocked_users_list"
              :key="index"
              class="d-flex flex-row list-row"
            >
              <div class="d-flex flex-row align-items-center">
                <div class="cover-image">
                  <div class="img-wrapper"><img src="" alt="" /></div>
                  <div class="online"></div>
                </div>
                {{ eachOp.name }}
              </div>
              <div
                class="
                  d-flex
                  flex-wrap
                  align-items-center
                  justify-content-between
                "
              >
                <div class="date">
                  {{ eachOp.date_time }}
                </div>
                <UnblockUserBtn
                  :id="eachOp.blocked_id"
                  :unblockUser="unblockUser"
                />
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <full-page-loader :show="list_loading" />
    <ConfirmBlockModal
      v-if="is_unblock_Modal"
      @closeBlockModal="closeBlockModal"
      :blockableId="unblock_user_id"
      :blockableType="unblock_type"
      type="Unblock"
    />
  </div>
</template>
<script type="application/javascript">
import { mapActions, mapMutations, mapGetters } from "vuex";
import { blockedUsersListing } from "../../../apis/APIs";
import EyeIcon from "../../../assets/svgs/EyeIcon.vue";
import moment from "moment/moment";
import ConfirmBlockModal from "./ConfirmBlockModal.vue";

export default {
  data() {
    return {
      blocked_users_list: [],
      is_unblock_Modal: false,
      unblock_user_id: "",
      unblock_type: "",
      list_loading: false,
      debounceTimer: null,
      search: "",

      message: "",
      success_message: "",
      loading: false,

      search_loading: false,
    };
  },
  watch: {
    search: function (value) {
      this.handleChange(value);
    },
  },
  mounted() {
    this.init();
  },
  components: {
    ConfirmBlockModal,
    EyeIcon
  }
  ,
  computed: {
    moment() {
      return moment
    },
    ...mapGetters({
      form_data: "edit_profile_form_map_to_props",
      user: "user_map_to_props",
    }),
    errors() {
      const { errors = [] } = this.form_data || {};

      return errors;
    },
    getBlockedUsersList(){
      return this.$store.getters.getBlockedUsers
    },
  },
  methods: {
    ...mapActions(["fetchUserData"]),
    ...mapMutations([
      "updateEditProfileFormData",
      "updateEditProfileUIConstraints",
    ]),
    isError(key) {
      if (this.errors && this.errors.length) {
        return this.errors.findIndex((ele) => ele.fieldName === key) > -1
          ? {
              status: true,
              message:
                this.errors[
                  this.errors.findIndex((ele) => ele.fieldName === key)
                ].message,
            }
          : { status: false, message: "" };
      } else return { status: false, message: "" };
    },
    _handleErrorMessage(key) {
      const { message = "" } = this.isError(key);

      return message;
    },
    openBlockModal(userId, type){
      this.unblock_user_id=userId;
      this.unblock_type= type;
      this.is_unblock_Modal = true;
    },
    closeBlockModal(){
      this.is_unblock_Modal = false;
    },
    init() {
      this.$store.dispatch("fetchBlockUsersData", "")
    },

    async handleChange(value) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.$store.dispatch("fetchBlockUsersData", value)
      }, 300);
    },
    async unblockUser(id, cb = () => {}) {
      try {
        // const res = await unblockUser(id);
        // console.log(res, 'response');
        const { success, message, information } = res || {};

        if (success === 0) {
          this.message = message;
        }

        await this.fetchBlockUsersData();
        cb();
      } catch (error) {
        cb();
      }
    },
  },
};
</script>
