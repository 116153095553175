<template>
  <div class="related-widget">
    <div class="widget-title">
      {{title}}
      <div v-if="listData.length > 0" class="link-more near_arrow without-slider in-title pointerClass">
        <a v-on:click="changeTab">See All
        <span class="sl-arrow"> <img
            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgY2xhc3M9InN2Zy1hcnJvdy1yaWd0aCIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZD0iTTEuMjkzNTggMS4zOTQ3OEw3LjM0NTUgNi4zMTI5MkwxLjI5MzU4IDExLjY2MzYiICBzdHJva2U9IiM0NzUwNUIiICBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIg0KICAgICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg0KDQo="> </span>
      </a>
      </div>
    </div>
    <WidgetLoading v-if="isMediaLoading" :is-loading="isMediaLoading"/>
    <div v-else class="widget_photos_view">
      <ul v-if="listData.length">
        <li v-for="(photo,index) in listData" :key="index">
          <img :src="photo.artwork_url" alt="image">
        </li>
      </ul>
      <div v-else class="mt-1">No Photos</div>
    </div>
  </div>
</template>
<script type="application/javascript">
import WidgetLoading from "../../Common/Widget/WidgetLoading.vue";

export default {
  props: {
    header: Boolean,
    listData: {
      type: Array,
      default: () => []
    },
    totalMediaPhotos: {
     type:Number
    },
    title:{
      type:String,
      default:"Photos"
    },
    isMediaLoading:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {}
  },
  components: { WidgetLoading },
  methods: {
    changeTab: function () {
      this.$parent.change_active_tab('media_main');
    }
  }
}
</script>
<style scoped lang="scss">
.related-widget{
  position: relative;
}
</style>
