<template>
  <div>
    <spinner v-if="getSpinnerData && (getSpinnerData.display && getSpinnerData.for === 'about')"/>
    <div v-else>
      <about-section :userData="userData" :user_experience="experienceList && experienceList.length > 0 ? experienceList[0] : {}"/>
      <Experience
        v-if="(checkIfPageOwnerIsYourFriend(userData.id) || !userData.who_can_see_your_work_info) && (experienceList && experienceList.length > 0)"
        :experiences="experienceList"
        :experience-total="experienceTotal"
        :experience-limit="experiencesLimit"
        :experiences-offset="offset"
        :edit-able="false"
        :typeCheck="true"
        :user-data="userData"
        @update:experienceLimit="(value) => experiencesLimit = value"
        @update:experiencesOffset="(value) => offset = value"
        @showMoreExperience="fetchUserExperiencesAndEducations"
      />
      <Education
        v-if="checkIfPageOwnerIsYourFriend(userData.id) || !userData.who_can_see_your_education_info && (educationList && educationList.length > 0)"
        :educations="educationList"
        :education-total="educationTotal"
        :education-limit="educationsLimit"
        :education-offset="offset"
        :edit-able="false"
        :typeCheck="true"
        :user-data="userData"
        @update:educationLimit="(value) => educationsLimit = value"
        @update:educationOffset="(value) => offset = value"
        @showMoreEducation="fetchUserExperiencesAndEducations"
      />
      <social-links :userData="userData"/>
    </div>
  </div>
</template>

<script>
import aboutSection from "./aboutSection.vue";
import socialLinks from "./socialLinks.vue";
import Experience from "../../../../components/PageManager/Pages/Components/TabComponents/Experience.vue";
import Education from "../../../../components/PageManager/Pages/Components/TabComponents/Education.vue";
import { get_user_education_api, get_user_work_api } from "../../../../apis/APIs";
import fetchApis from "../../../../utils/DynamicFunctionToCallApis";
import arrayToObjectByKey from "../../../../utils/ArrayToObjectByKey";
import Spinner from "../../../Common/Spinners/spinner.vue";

export default {
  name: "aboutMainPage",
  data() {
    return {
      experienceList: [],
      educationList: [],
      educationsLimit: 3,
      experiencesLimit: 3,
      offset: 0,
      educationTotal: 0,
      experienceTotal: 0
    }
  },
  props:{
    userData:{
      type:Object,
      required:true,
      default: ()=>({})
    }
  },
  components: {
    Spinner,
    aboutSection,
    socialLinks,
    Experience,
    Education
  },
  computed:{
    followersCount(){
       return (this.userData && this.userData.follower_count) ? String(this.userData.follower_count) : '0'
    },
    followingCount(){
       return (this.userData && this.userData.following_count) ? String(this.userData.following_count) : '0'
    },
    getSpinnerData(){
      return this.$store.getters.getSpinner
    }
  },
  methods: {

    fetchUserExperiencesAndEducations(spinnerFor = 'about') {
      fetchApis( [this.fetchUserEducations(), this.fetchUserExperiences()], this.$store, false, true, spinnerFor).then((resp) => {

        const userEducations = arrayToObjectByKey(resp, 'user_educations');

        this.educationList = userEducations.data.data;
        this.educationTotal = userEducations.data.total;

        const userExperiences = arrayToObjectByKey(resp, 'user_experiences');

        if (userExperiences && userExperiences.data && userExperiences.data.data && userExperiences.data.data.length > 0) {
          this.experienceList = userExperiences.data.data.map((experience) => ({
            from_year: experience.from_year || "",
            to_year: experience.to_year || "",
            from_month: experience.from_month || "",
            to_month: experience.to_month || "",
            company_name: experience.company_name || "",
            description: experience.description || "",
            title: experience.title || "",
            location: experience.description || "",
            id: experience.id || "",
            currently_working: experience.currently_working || "",
            artwork_url: experience.artwork_url || null,
          }));
          this.experienceTotal = userExperiences.data.total;
        }

      }).catch((error) => {
        console.log(error);
      });
    },

    fetchUserExperiences(returnKey = 'user_experiences') {
      return async () => {
        try {
          const { data } = await get_user_work_api(this.offset, this.experiencesLimit, this.userData.id);
          return { [returnKey]: data };
        } catch (error) {
          throw error;
        }
      }
    },

    fetchUserEducations(returnKey = 'user_educations') {
      return async () => {
        try {
          const { data } = await get_user_education_api(this.offset, this.educationsLimit, this.userData.id);
          return { [returnKey]: data };
        } catch (error) {
          throw error;
        }
      }
    },
  },

  mounted() {
    this.fetchUserExperiencesAndEducations();
  }
}
</script>

<style scoped>

</style>
