<template>
  <div
    class="song-album-card card slider"
    :class="['js-container-hover', item.isHovering ? 'hover' : '', type === 'review' || type === 'song' ? 'review-card': 'release-card']"
  >
    <div class="song-album-square-card">
      <div
        class="card channel-card"
        @mouseover="isHoveringFun(true)"
        @mouseleave="isHoveringFun(false)"
      >
        <div
          :class="['card-cover js-card-hover', item.isHovering ? 'hover' : '']"
          class="for_hover"
        >
          <div
            class="cover square channel-card-cover cover-hover grid-pagfes"
            :class="{
              'visible-overflow': type === 'playlist' || type === 'record',
            }"
          >
            <img
              class="rounded-top img-fluid w-100"
              :src="
                type === 'review'
                  ? item.reviewable.artwork_url
                  : item.artwork_url
              "
              alt=""
            />
            <div class="card-rating" v-if="showRating">
              <div class="svg-icon">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.55653 1.49401C6.69064 1.05973 7.30505 1.05913 7.44 1.49315L8.613 5.2655H12.3693C12.8115 5.2655 13.0015 5.82658 12.6504 6.09534L9.59534 8.43377L10.7659 12.2242C10.8985 12.6538 10.4024 13.001 10.0444 12.7291L7.00045 10.4181L3.97053 12.7373C3.61284 13.0111 3.11467 12.6639 3.2476 12.2335L4.41801 8.4434L1.34844 6.09543C0.99716 5.82673 1.18715 5.2655 1.62938 5.2655H5.39186L6.55653 1.49401Z"
                    fill="white"
                  />
                </svg>
              </div>
              <p class="mb-0">
                {{
                  type === "review"
                    ? item.rating
                    : (type === "song" || type === "album" || type === "sample_pack") && item.avg > 0
                      ? item.avg
                      : "NA"
                }}
              </p>
            </div>
            <div
              class="top-week-album-img-hover"
              v-if="type === 'song' || type === 'album' || type === 'review'"
            >
              <div
                v-show="item.isHovering"
                class="btn-slider-head position-absolute like-icon"
              >
                <div
                  class="svg-icon"
                  @click="addToLoveToggle($event, item, type, favorite)"
                >
                  <b-spinner
                    v-if="spinner"
                    class="spinner-color"
                    small
                    label="Small Spinner"
                  ></b-spinner>
                  <svg
                    v-else
                    :class="{ 'fill-icon': favorite === true }"
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.1991 2.22387C12.8748 1.9063 12.4898 1.65438 12.0661 1.48251C11.6423 1.31063 11.1881 1.22217 10.7295 1.22217C10.2708 1.22217 9.8166 1.31063 9.39285 1.48251C8.9691 1.65438 8.58408 1.9063 8.25977 2.22387L7.58631 2.88326L6.91286 2.22387C6.25341 1.60394 5.37204 1.26181 4.45788 1.2709C3.54372 1.28 2.66963 1.63959 2.02319 2.27252C1.37675 2.90545 1.00947 3.76128 1.00018 4.65633C0.990891 5.55138 1.34033 6.41433 1.9735 7.05999L2.64696 7.71938L7.58631 12.5555L12.5257 7.71938L13.1991 7.05999C13.5235 6.74247 13.7808 6.36549 13.9563 5.9506C14.1319 5.53571 14.2222 5.09102 14.2222 4.64193C14.2222 4.19285 14.1319 3.74816 13.9563 3.333 27C13.7808 2.91837 13.5235 2.5414 13.1991 2.22387V2.22387Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div
                v-show="item.isHovering"
                :class="[
                  'play-button js-play-button new-play-button pg-new-play-button position-absolute play-pause-icon',
                  item.isHovering ? 'd-block' : '',
                  PlayIconStatus(
                    item,
                    currentPlayer,
                    currentPlayId,
                    songStatus,
                    currentPlayTypeID
                  ),
                ]"
                @click="
                  playPause(
                    $event,
                    item.type === 'song' ? [item] : item.album_songs,
                    'footerPlayer',
                    item.type === 'album' ? item.id : item.id
                  )
                "
                v-if="type === 'song' || type === 'album'"
              >
                <div class="svg-icon">
                  <svg
                    width="13"
                    height="16"
                    viewBox="0 0 13 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="play-type-1"
                  >
                    <path
                      class="c-fill-1 c-line-1"
                      d="M2.48967 14.5038C2.35174 14.5004 2.21628 14.4666 2.093 14.4046C1.95246 14.322 1.83398 14.2066 1.74764 14.0683C1.66131 13.93 1.60969 13.7729 1.59717 13.6103L1.59717 2.30439C1.60458 2.14673 1.64886 1.99301 1.72644 1.85556C1.80402 1.71811 1.91275 1.60077 2.04389 1.51295C2.18183 1.43494 2.33926 1.39819 2.49748 1.40706C2.6557 1.41593 2.80804 1.47006 2.93639 1.563L11.6139 7.2155C11.7361 7.29735 11.8362 7.40803 11.9054 7.53774C11.9746 7.66746 12.0108 7.81222 12.0108 7.95925C12.0108 8.10628 11.9746 8.25104 11.9054 8.38076C11.8362 8.51047 11.7361 8.62115 11.6139 8.703L2.93639 14.3574C2.80583 14.4508 2.65018 14.5028 2.48967 14.5066"
                      stroke="white"
                      stroke-width="1.3"
                    ></path>
                  </svg>
                  <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="c-line-1 c-fill-1"
                      d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                      fill="white"
                    ></path>
                    <path
                      class="c-line-1 c-fill-1"
                      d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                      stroke="white"
                      stroke-width="1.3"
                    ></path>
                  </svg>
                  <svg
                    width="10"
                    height="14"
                    viewBox="0 0 10 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="c-line-1 c-fill-1"
                      d="M1 1V13"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                    ></path>
                    <path
                      class="c-line-1 c-fill-1"
                      d="M9 1V13"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </div>
              </div>
              <div
                v-show="item.isHovering"
                :class="[
                  'play-button js-play-button new-play-button pg-new-play-button position-absolute play-pause-icon',
                  item.isHovering ? 'd-block' : '',
                  PlayIconStatus(
                    item.reviewable,
                    currentPlayer,
                    currentPlayId,
                    songStatus,
                    currentPlayTypeID
                  ),
                ]"
                @click="
                  playPause(
                    $event,
                    item.reviewable.type === 'song'
                      ? [item.reviewable]
                      : item.reviewable.album_songs,
                    'footerPlayer',
                    item.reviewable.type === 'album' ? item.reviewable.id : null
                  )
                "
                v-if="type === 'review'"
              >
                <div class="svg-icon">
                  <svg
                    width="13"
                    height="16"
                    viewBox="0 0 13 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="play-type-1"
                  >
                    <path
                      class="c-fill-1 c-line-1"
                      d="M2.48967 14.5038C2.35174 14.5004 2.21628 14.4666 2.093 14.4046C1.95246 14.322 1.83398 14.2066 1.74764 14.0683C1.66131 13.93 1.60969 13.7729 1.59717 13.6103L1.59717 2.30439C1.60458 2.14673 1.64886 1.99301 1.72644 1.85556C1.80402 1.71811 1.91275 1.60077 2.04389 1.51295C2.18183 1.43494 2.33926 1.39819 2.49748 1.40706C2.6557 1.41593 2.80804 1.47006 2.93639 1.563L11.6139 7.2155C11.7361 7.29735 11.8362 7.40803 11.9054 7.53774C11.9746 7.66746 12.0108 7.81222 12.0108 7.95925C12.0108 8.10628 11.9746 8.25104 11.9054 8.38076C11.8362 8.51047 11.7361 8.62115 11.6139 8.703L2.93639 14.3574C2.80583 14.4508 2.65018 14.5028 2.48967 14.5066"
                      stroke="white"
                      stroke-width="1.3"
                    ></path>
                  </svg>
                  <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="c-line-1 c-fill-1"
                      d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                      fill="white"
                    ></path>
                    <path
                      class="c-line-1 c-fill-1"
                      d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                      stroke="white"
                      stroke-width="1.3"
                    ></path>
                  </svg>
                  <svg
                    width="10"
                    height="14"
                    viewBox="0 0 10 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="c-line-1 c-fill-1"
                      d="M1 1V13"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                    ></path>
                    <path
                      class="c-line-1 c-fill-1"
                      d="M9 1V13"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </div>
              </div>
              <div
                v-show="item.isHovering"
                class="btn-more position-absolute list-icon"
                v-if="type === 'song' || type === 'album'"
              >
                <div
                  class="dropdown more-dropdown icon"
                  @click="
                    openListOption(
                      $event,
                      item.type === 'song' ? [item] : item.album_songs,
                      'default_option_list',
                      item,
                      '',
                      favorite
                    )
                  "
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-display="static"
                >
                  <div class="svg-icon more-position">
                    <svg
                      width="15"
                      height="3"
                      viewBox="0 0 15 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="svg-more-hor"
                    >
                      <path
                        class="c-fill-1"
                        d="M2.22144 2.99805C3.01872 2.99805 3.66505 2.35173 3.66505 1.55445C3.66505 0.757165 3.01872 0.11084 2.22144 0.11084C1.42416 0.11084 0.777832 0.757165 0.777832 1.55445C0.777832 2.35173 1.42416 2.99805 2.22144 2.99805Z"
                        fill="white"
                      ></path>
                      <path
                        class="c-fill-1"
                        d="M7.79615 2.99805C8.59343 2.99805 9.23975 2.35173 9.23975 1.55445C9.23975 0.757165 8.59343 0.11084 7.79615 0.11084C6.99886 0.11084 6.35254 0.757165 6.35254 1.55445C6.35254 2.35173 6.99886 2.99805 7.79615 2.99805Z"
                        fill="white"
                      ></path>
                      <path
                        class="c-fill-1"
                        d="M13.3709 2.99805C14.1681 2.99805 14.8145 2.35173 14.8145 1.55445C14.8145 0.757165 14.1681 0.11084 13.3709 0.11084C12.5736 0.11084 11.9272 0.757165 11.9272 1.55445C11.9272 2.35173 12.5736 2.99805 13.3709 2.99805Z"
                        fill="white"
                      ></path>
                    </svg>
                    <svg
                      width="3"
                      height="15"
                      viewBox="0 0 3 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="svg-more"
                    >
                      <path
                        d="M-6.55671e-08 1.5C-1.01779e-07 2.32843 0.671573 3 1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 -2.93554e-08 1.5 -6.55671e-08C0.671573 -1.01779e-07 -2.93554e-08 0.671573 -6.55671e-08 1.5Z"
                        class="c-fill-1"
                      ></path>
                      <path
                        d="M-6.55671e-08 7.29199C-1.01779e-07 8.12042 0.671573 8.79199 1.5 8.79199C2.32843 8.79199 3 8.12042 3 7.29199C3 6.46357 2.32843 5.79199 1.5 5.79199C0.671573 5.79199 -2.93554e-08 6.46356 -6.55671e-08 7.29199Z"
                        class="c-fill-1"
                      ></path>
                      <path
                        d="M-6.55671e-08 13.084C-1.01779e-07 13.9124 0.671573 14.584 1.5 14.584C2.32843 14.584 3 13.9124 3 13.084C3 12.2556 2.32843 11.584 1.5 11.584C0.671573 11.584 -2.93554e-08 12.2556 -6.55671e-08 13.084Z"
                        class="c-fill-1"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div
                v-show="item.isHovering"
                class="btn-more position-absolute list-icon"
                v-if="type === 'review'"
              >
                <div
                  class="dropdown more-dropdown icon"
                  @click="
                    openListOption(
                      $event,
                      item.reviewable.type === 'song'
                        ? [item.reviewable]
                        : item.reviewable.album_songs,
                      'default_option_list',
                      item.reviewable,
                      '',
                      item.reviewable.favorite
                    )
                  "
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-display="static"
                >
                  <div class="svg-icon more-position">
                    <svg
                      width="15"
                      height="3"
                      viewBox="0 0 15 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="svg-more-hor"
                    >
                      <path
                        class="c-fill-1"
                        d="M2.22144 2.99805C3.01872 2.99805 3.66505 2.35173 3.66505 1.55445C3.66505 0.757165 3.01872 0.11084 2.22144 0.11084C1.42416 0.11084 0.777832 0.757165 0.777832 1.55445C0.777832 2.35173 1.42416 2.99805 2.22144 2.99805Z"
                        fill="white"
                      ></path>
                      <path
                        class="c-fill-1"
                        d="M7.79615 2.99805C8.59343 2.99805 9.23975 2.35173 9.23975 1.55445C9.23975 0.757165 8.59343 0.11084 7.79615 0.11084C6.99886 0.11084 6.35254 0.757165 6.35254 1.55445C6.35254 2.35173 6.99886 2.99805 7.79615 2.99805Z"
                        fill="white"
                      ></path>
                      <path
                        class="c-fill-1"
                        d="M13.3709 2.99805C14.1681 2.99805 14.8145 2.35173 14.8145 1.55445C14.8145 0.757165 14.1681 0.11084 13.3709 0.11084C12.5736 0.11084 11.9272 0.757165 11.9272 1.55445C11.9272 2.35173 12.5736 2.99805 13.3709 2.99805Z"
                        fill="white"
                      ></path>
                    </svg>
                    <svg
                      width="3"
                      height="15"
                      viewBox="0 0 3 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="svg-more"
                    >
                      <path
                        d="M-6.55671e-08 1.5C-1.01779e-07 2.32843 0.671573 3 1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 -2.93554e-08 1.5 -6.55671e-08C0.671573 -1.01779e-07 -2.93554e-08 0.671573 -6.55671e-08 1.5Z"
                        class="c-fill-1"
                      ></path>
                      <path
                        d="M-6.55671e-08 7.29199C-1.01779e-07 8.12042 0.671573 8.79199 1.5 8.79199C2.32843 8.79199 3 8.12042 3 7.29199C3 6.46357 2.32843 5.79199 1.5 5.79199C0.671573 5.79199 -2.93554e-08 6.46356 -6.55671e-08 7.29199Z"
                        class="c-fill-1"
                      ></path>
                      <path
                        d="M-6.55671e-08 13.084C-1.01779e-07 13.9124 0.671573 14.584 1.5 14.584C2.32843 14.584 3 13.9124 3 13.084C3 12.2556 2.32843 11.584 1.5 11.584C0.671573 11.584 -2.93554e-08 12.2556 -6.55671e-08 13.084Z"
                        class="c-fill-1"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="type === 'record' || type === 'playlist'"
              class="cover-mood js-cover-mood"
              style="z-index: +8"
            >
              <div
                class="mood Electronic"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                #{{ this.item && this.item.genre[0].name }}
              </div>
              <div class="dropdown-menu dropdown-menu-right dropdown-moods">
                <div class="moods">#Electronic</div>
                <div class="moods">#Ambient</div>
              </div>
            </div>
          </div>
          <div
            v-if="type === 'song' || type === 'album'"
            class="card-description"
            @click="redirectToFullViewPage($event, item.type, item.id)"
          >
            <div class="title marquee js-marquee album-title-div" style="margin-bottom: 7px !important">
              <div class="gird-title-name album-title">
                <a href="#">
                  {{ item.title ? item.title : "" }}
                </a>
              </div>
              <div class="fvrt-svg-icon" v-if="page === 'favorite'">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
                  <path d="M13.9167 2.06063C13.5733 1.72437 13.1657 1.45764 12.717 1.27565C12.2683 1.09367 11.7874 1 11.3018 1C10.8161 1 10.3352 1.09367 9.88655 1.27565C9.43787 1.45764 9.0302 1.72437 8.68682 2.06063L7.97375 2.7588L7.26067 2.06063C6.56244 1.40423 5.62922 1.04197 4.66128 1.0516C3.69335 1.06123 2.76784 1.44198 2.08337 2.11214C1.39891 2.7823 1.01003 3.68847 1.00019 4.63617C0.990355 5.58387 1.36035 6.49759 2.03076 7.18123L2.74384 7.8794L7.97375 13L13.2037 7.8794L13.9167 7.18123C14.2602 6.84503 14.5326 6.44588 14.7185 6.00658C14.9043 5.56728 15 5.09643 15 4.62093C15 4.14543 14.9043 3.67458 14.7185 3.23528C14.5326 2.79598 14.2602 2.39683 13.9167 2.06063Z" fill="#8B949F" stroke="#8B949F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>

            <div class="description marquee js-marquee">
              <div class="gird-title-subname">
                <a href="#">
                  {{ item.subgenre[0].name }}
                  <span>
                    •
                  </span>
                  {{ item.song_count}} {{ item.song_count > 1 ? "Samples" : "Sample" }}
                </a>
              </div>
            </div>
            <div v-if="sellingNow" class="price">
              <div class="sold-for-container">
                <a href="#" class="sold_for"> Sold for</a>
                <span class="font-14 price-text">€2.66</span>
              </div>
            </div>
            <div
              v-if="sellingNow"
              class="description marquee js-marquee song_description_location"
            >
              <div><a href="#"> in Germany </a></div>
            </div>
            <div
              v-if="sellingNow"
              class="description marquee js-marquee song_description_time"
            >
              <div><a href="#"> 1 second ago </a></div>
            </div>
            <div v-if="!sellingNow && !stream" style="margin-top: 10px !important;" class="price">
              <div>
                <span class="font-14 price-text">€{{ getPrice(item) }}</span>
              </div>
            </div>
          </div>
          <div
            v-if="type === 'playlist'"
            class="
              card-description
              playlist-description
              card-description-padding
            "
          >
            <div class="title marquee js-marquee playlist-text">
              <div class="gird-title-name">
                <a href="#"> {{ this.item && this.item.title }} </a>
              </div>
            </div>
            <div class="description marquee js-marquee playlist-text">
              <div class="gird-title-subname">
                <a href="#">
                  by {{ this.item && this.item.creator[0].name }}
                </a>
              </div>
            </div>
            <div class="heart-icons-blue">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.9167 2.06063C13.5733 1.72437 13.1657 1.45764 12.717 1.27565C12.2683 1.09367 11.7874 1 11.3018 1C10.8161 1 10.3352 1.09367 9.88655 1.27565C9.43787 1.45764 9.0302 1.72437 8.68682 2.06063L7.97375 2.7588L7.26067 2.06063C6.56244 1.40423 5.62922 1.04197 4.66128 1.0516C3.69335 1.06123 2.76784 1.44198 2.08337 2.11214C1.39891 2.7823 1.01003 3.68847 1.00019 4.63617C0.990355 5.58387 1.36035 6.49759 2.03076 7.18123L2.74384 7.8794L7.97375 13L13.2037 7.8794L13.9167 7.18123C14.2602 6.84503 14.5326 6.44588 14.7185 6.00658C14.9043 5.56728 15 5.09643 15 4.62093C15 4.14543 14.9043 3.67458 14.7185 3.23528C14.5326 2.79598 14.2602 2.39683 13.9167 2.06063V2.06063Z"
                  stroke="#D1433A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="followers">
              <div class="text">
                <div class="svg-icon">
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="c-line-1 c-fill-1"
                      d="M1 8.00938C3.757 8.00938 6 10.2547 6 13.0145C6 13.5671 6.448 14.0156 7 14.0156C7.552 14.0156 8 13.5671 8 13.0145C8 9.15056 4.86 6.00732 1 6.00732C0.448 6.00732 0 6.45579 0 7.00835C0 7.56092 0.448 8.00938 1 8.00938Z"
                      fill="#D1433A"
                    ></path>
                    <path
                      class="c-line-1 c-fill-1"
                      d="M1 2.00206C7.0655 2.00206 12 6.94164 12 13.0134C12 13.566 12.448 14.0144 13 14.0144C13.552 14.0144 14 13.566 14 13.0134C14 5.83801 8.1685 0 1 0C0.448 0 0 0.448461 0 1.00103C0 1.5536 0.448 2.00206 1 2.00206Z"
                      fill="#D1433A"
                    ></path>
                    <path
                      class="c-line-1 c-fill-1"
                      d="M1 14.0148C1.55228 14.0148 2 13.5666 2 13.0137C2 12.4609 1.55228 12.0127 1 12.0127C0.447715 12.0127 0 12.4609 0 13.0137C0 13.5666 0.447715 14.0148 1 14.0148Z"
                      fill="#D1433A"
                    ></path>
                  </svg>
                </div>
                Follow 873.7k
              </div>
            </div>
          </div>
          <div v-if="type === 'record'" class="card-description pt-1">
            <div class="title text-overflow mb-1">
              <div class="gird-title-name"><a href="#"> Warp Records </a></div>
            </div>
            <div class="description text-overflow mb-1">
              <div class="gird-title-subname">
                <a href="#"> #Record Label </a>
              </div>
            </div>
            <div class="followers">
              <div class="text">
                <div class="svg-icon">
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="c-line-1 c-fill-1"
                      d="M1 8.00938C3.757 8.00938 6 10.2547 6 13.0145C6 13.5671 6.448 14.0156 7 14.0156C7.552 14.0156 8 13.5671 8 13.0145C8 9.15056 4.86 6.00732 1 6.00732C0.448 6.00732 0 6.45579 0 7.00835C0 7.56092 0.448 8.00938 1 8.00938Z"
                      fill="#D1433A"
                    ></path>
                    <path
                      class="c-line-1 c-fill-1"
                      d="M1 2.00206C7.0655 2.00206 12 6.94164 12 13.0134C12 13.566 12.448 14.0144 13 14.0144C13.552 14.0144 14 13.566 14 13.0134C14 5.83801 8.1685 0 1 0C0.448 0 0 0.448461 0 1.00103C0 1.5536 0.448 2.00206 1 2.00206Z"
                      fill="#D1433A"
                    ></path>
                    <path
                      class="c-line-1 c-fill-1"
                      d="M1 14.0148C1.55228 14.0148 2 13.5666 2 13.0137C2 12.4609 1.55228 12.0127 1 12.0127C0.447715 12.0127 0 12.4609 0 13.0137C0 13.5666 0.447715 14.0148 1 14.0148Z"
                      fill="#D1433A"
                    ></path>
                  </svg>
                </div>
                Follow 873.7k
              </div>
            </div>
          </div>
          <div
            v-if="type === 'review'"
            class="card-description with-media"
            @click="
              redirectToFullViewPage(
                $event,
                item.reviewable.type,
                item.reviewable_id
              )
            "
          >
            <div class="title album-review-title tooltip">
              <span
                :title="item.reviewable.title"
                data-toggle="tooltip"
                class=""
                style="text-overflow: ellipsis; width: 100%"
              >{{
                  item.reviewable.title.length > 14
                    ? item.reviewable.title.slice(0, 14) + "..."
                    : item.reviewable.title
                }}</span
              >
            </div>
            <div class="name artist-div" id="item">
              <span class="by-text">by</span>
              <span class="artist-name">{{ getArtist(item.reviewable.page) }}</span>
            </div>
            <div class="forlabel label-div">
              <span class="label-text">Label:</span>
              <span class="record-label">Sony Records{{ item.reviewable.record_label }}</span>
            </div>
            <div class="description description-text">
              <div>
                Brother hundred he assured reached on up no. On am nearer missed lovers...
                {{
                  item.comment && item.comment.length > 72
                    ? item.comment.slice(0, 72) + "..."
                    : item.comment
                }}
              </div>
            </div>
            <div class="media custom-media align-items-center">
              <img :src="getImage(item.reviewable)" alt="..." />
              <div class="media-body">
                <div class="name">
                  {{ item.reviewable_user ? item.reviewable_user.name : "" }}
                </div>
                <div class="time">
                  {{ convertDateIntoHumanReadableForm(item.created_at) }}
                </div>
              </div>
            </div>
            <a href="#" class="read-more-text">Read more</a>
          </div>
          <button class="download-btn" v-if="page ==='favorite'">
            <svg class="download-icon" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
              <path d="M12.25 8.75V11.0833C12.25 11.3928 12.1271 11.6895 11.9083 11.9083C11.6895 12.1271 11.3928 12.25 11.0833 12.25H2.91667C2.60725 12.25 2.3105 12.1271 2.09171 11.9083C1.87292 11.6895 1.75 11.3928 1.75 11.0833V8.75" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4.08203 5.83331L6.9987 8.74998L9.91536 5.83331" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7 8.75V1.75" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";

export default {
  name: "SongOrArtistCardWithSquareImage",
  data() {
    return {
      favorite: false,
      spinner: false,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: "song",
    },
    stream: {
      type: Boolean,
      default: false,
    },
    sellingNow: {
      type: Boolean,
      default: false,
    },
    page: {
      type: String,
    }
  },
  watch: {
    getListOptionFavorite() {
      if (
        this.item.id === this.getListOptionFavoriteId ||
        this.item.reviewable_id === this.getListOptionFavoriteId
      ) {
        this.favorite = this.getListOptionFavorite;
      }
    },
  },
  components: {},
  mounted() {
    if (this.item) {
      if (this.type === "review") {
        this.favorite = this.item.reviewable.favorite;
      } else {
        this.favorite = this.item.favorite;
      }
    }
    $('[data-toggle="tooltip"]').tooltip();
  },
  methods: {
    convertDateIntoHumanReadableForm(date) {
      return moment(date).fromNow();
    },
    async addToLoveToggle(e, song, type, favorite) {
      this.spinner = true;
      const res = await this.setFavourite(e, song, type, favorite);
      if (res.success === 1) {
        this.favorite = this.favorite !== true;
      }
      this.spinner = false;
    },
    getHeight: function(e, ei) {
      e.stopPropagation();
      this.showOption = !this.showOption;
      if (this.lastWidth != e.screenX) {
        this.showOption = true;
        this.lastWidth = e.screenX;
      }
      console.log(e.screenX, "screen x");
      const height = 112;
      this.optionWidth =
        parseInt(e.screenX) - this.$refs.busstop.getBoundingClientRect().left;
      this.optionHeight = parseInt(height);
    },
    isHoveringFun: function(val) {
      Vue.set(this.item, "isHovering", val);
    },
  },
  computed: {
    showRating() {
      if (
        this.type === "review" ||
        this.type === "song" ||
        this.type === "album"
      ) {
        if (this.sellingNow) {
          return false;
        }
        if (!this.stream) {
          return true;
        }
      }
      return false;
    },
    currentPlayer() {
      return this.$store.getters.getCurrentPlayer;
    },
    currentPlayId() {
      return this.$store.getters.getCurrentPlayId;
    },
    songStatus() {
      return this.$store.getters.getSongsStatus;
    },
    currentPlayTypeID() {
      return this.$store.getters.getCurrentPlayTypeId;
    },
    getListOptionFavorite() {
      return this.$store.getters.getListOptionFavorite;
    },
    getListOptionFavoriteId() {
      return this.$store.getters.getListOptionFavoriteId;
    },
  },
};
var tooltipTriggerList = [].slice.call(
  document.querySelectorAll('[data-toggle="tooltip"]')
);
var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl);
});
</script>

<style scoped lang="scss">
.for_hover {
  transition: 0.1s all !important;
  &:hover {
    transition: 0.1s all;
    box-shadow: 0 4px 10px rgba(74, 75, 77, 0.5);
    border-radius: 4px !important;
  }
}
.channel-card-cover:hover .top-week-album-img-hover {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
      180deg,
      rgba(7, 21, 38, 0.3588) 0%,
      rgba(7, 21, 38, 0.69) 100%
  );
}
.song-album-card {
  // max-width: 170px;
  .song-album-square-card {
    // max-width: 170px;
    border: 1px solid #e6eaf0 !important;
    .card {
      border: transparent;
      .channel-card-cover {
        position: relative;
        min-height: 170px;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          -o-object-fit: cover;
        }
      }
      .card-rating {
        background: #8b949f;
        border-radius: 4px;
        padding: 5px 8px;
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 10px;
        right: 10px;
        .svg-icon {
          margin-right: 3px;
        }

        p {
          color: #fff;
          font-family: Helvetica Neue;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }

      .price {
        .price-text {
          cursor: pointer;
          background-color: #D1433A1A;
          border-radius: 2px;
          width: 50px;
          height: 20px;
          font-family: "HelveticaNeueBold";
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: #071526;
          margin-top: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .play-pause-icon {
        cursor: pointer;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: +9;
      }

      .like-icon {
        cursor: pointer;
        top: 0;
        bottom: 0;
        left: 0;
        right: 5.5rem;
        margin: auto;
        z-index: +8;

        .svg-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          .fill-icon {
            fill: #fff;
          }
        }
      }

      .list-icon {
        cursor: pointer;
        top: 0;
        bottom: 0;
        right: 0;
        left: 5.5rem;
        margin: auto;
        z-index: +8;

        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

}
.playlist-description {
  .followers {
    border: 1px solid #D1433A;
    border-radius: 4px;
    color: #D1433A;
    width: 100%;
    margin: auto;
    height: 30px;
    display: flex;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
.visible-overflow {
  overflow: visible;
}

.spinner-color {
  color: #fff !important;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  /*width: 120px;*/
  background-color: #D1433A;
  position: fixed;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;

  /* Position the tooltip */
  /*position: absolute;*/
  z-index: 9999;
}
.playlist-text {
  padding-left: 12px !important;
}
.card-description-padding {
  padding: 10px !important;
}
/*.tooltip:hover .tooltiptext {*/
/*  visibility: visible;*/
/*}*/
/*#item{*/
/*  border: 1px solid black;*/
/*  height: 20px;*/
/*  width: 120px;*/
/*  text-overflow: ellipsis;*/
/*  overflow: hidden;*/
/*  white-space: nowrap;*/
/*}*/

@media screen and (min-width: 2560px){
  .music-overview-cards {
    .gird-title-name a {
      font-size: 1.25rem;
    }
    .card.slider .card-description .description a {
      font-size: 1.25rem;
    }
  }
}
</style>
