import { render, staticRenderFns } from "./BlockModalHeader.vue?vue&type=template&id=9de6196e&scoped=true"
import script from "./BlockModalHeader.vue?vue&type=script&lang=js"
export * from "./BlockModalHeader.vue?vue&type=script&lang=js"
import style0 from "./BlockModalHeader.vue?vue&type=style&index=0&id=9de6196e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9de6196e",
  null
  
)

export default component.exports