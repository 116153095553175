var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-select',{staticClass:"select-dropdown",class:{ 'vs__actions-hidden': _vm.hideCrossIcon },style:({ 
    '--dropdown-border-radius': _vm.borderRadius, 
    '--dropdown-background-color': _vm.backgroundColor,
    '--dropdown-min-width': _vm.minWidth,
    '--search-input-color': _vm.inputColor,
      ..._vm.style 
    }),attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"options":_vm.options,"value":_vm.value,"reduce":option => option.value,"label":"label"},on:{"input":_vm.handleInput},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}})
}
var staticRenderFns = []

export { render, staticRenderFns }