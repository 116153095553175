<template>
  <div class="engage_settings">
    <div class="engage_settings_header">
      <div>
        <h3 class="btn_visibility_header">Button Visibility: {{ listen_now_settings.visibility? 'Published': 'Hidden' }}</h3>
        <h6 class="btn_visibility_text">To publish, turn the switcher on.</h6>
      </div>
      <div class="switch_sm_wrapper">
        <input type="checkbox" v-model="listen_now_settings.visibility" id="switch27"/>
        <label class="switch_sm" for="switch27">
          <div class="switch_toggler"></div>
        </label>
      </div>
    </div>
    <div class="engage_settings_body">
      <div class="engage_set_title engage_set_title_bold">
        <h4 class="header">What do you want user to listen?</h4>
        <p class="text">Choose from list below</p>
      </div>

      <div class="engage_fields_group">
        <div class="row">

          <div class="col-5">
            <div class="engage_field mb-0">
              <label class="engage_fields_label">
                What to Listen
                <span class="color_red">*</span>
              </label>
              <div class="select_box engage_event_dropdown">
                <SelectDropdown
                  :options="whatToListenOptionsList"
                  v-model="listen_now_settings.what_to_listen"
                  @change="((value) => listen_now_settings.what_to_listen = value)"
                  :style="'width: 285.83px; border: 1px solid #E6EAF0;'"
                  placeholder="Album"
                  :class="{'red-error-border': activeRedBorder && !listen_now_settings.what_to_listen }"
                  input-color="#071526"
                  hideCrossIcon
                />
              </div>
              <div class="svg-icon">
                <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L5 5L9 1" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
          </div>
          <div class="col-7">
            <div class="engage_field mb-0">
              <label class="engage_fields_label">
                Album
                <span class="color_red">*</span>
              </label>
              <div class="select_box select_box engage_event_dropdown">
                <SelectDropdown
                  :options="listen_now_settings.what_to_listen === 'Songs'? latestSongs: latestAlbums"
                  v-model="listen_now_settings.album"
                  @change="onChange"
                  :style="'width: 412.16px; border: 1px solid #E6EAF0;'"
                  placeholder="Choose album from list"
                  :class="{'red-error-border': activeRedBorder && !listen_now_settings.album }"
                  input-color="#071526"
                  hideCrossIcon
                />
              </div>
              <div class="svg-icon">
                <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L5 5L9 1" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>

            </div>
          </div>

        </div>

      </div>


    </div>
    <div class="engage_settings_footer">
      <h4>Your Button preview:</h4>
      <button type="button">
        <!-- <img src="img/svg/music.svg" alt="music"/> -->
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 12V3.33333L14 2V10.6667" stroke="white" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"/>
          <path
              d="M4 14C5.10457 14 6 13.1046 6 12C6 10.8954 5.10457 10 4 10C2.89543 10 2 10.8954 2 12C2 13.1046 2.89543 14 4 14Z"
              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path
              d="M12 12.667C13.1046 12.667 14 11.7716 14 10.667C14 9.56242 13.1046 8.66699 12 8.66699C10.8954 8.66699 10 9.56242 10 10.667C10 11.7716 10.8954 12.667 12 12.667Z"
              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Listen Now
      </button>
    </div>
  </div>
</template>

<script>
import SelectDropdown from '../../../Common/Dropdown/SelectDropdown.vue';
export default {
  components: {
    SelectDropdown
  },
  data() {
    return {
      listen_now_settings: {
        visibility: false,
        what_to_listen: null,
        album: null
      }
    }
  },
  props: {
    listenNowSettings: {
      type: Object,
      default: ()=> {}
    },
    activeRedBorder: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    whatToListenOptionsList() {
      return [
        { value: "Songs", label: "Songs" },
        { value: "Albums", label: "Albums" },
      ]
    },
    latestSongs() {
      const songs = this.$store.getters.getPageLatestSongList.data || null;
        if (!songs) {
          return [];
        }

      return songs.flatMap((song) => {
        return song.album_songs.map((album_song) => ({
          value: album_song.id,
          label: album_song.title,
        }));
      });
    },
    latestAlbums() {
      const albums = this.$store.getters.getPageMusicFilteredList;
      if (!albums || !Array.isArray(albums)) {
        return [];
      }
      return albums.map((album) => ({
        value: album.id,
        label: album.title,
      }));
    },
  },
  watch: {
    'listen_now_settings.visibility'() {
      this.$emit('getListenNowData', this.listen_now_settings)
    },
    'listen_now_settings.what_to_listen'() {
      this.$emit('getListenNowData', this.listen_now_settings)
    },
    'listen_now_settings.album'() {
      this.$emit('getListenNowData', this.listen_now_settings)
    },
    listenNowSettings() {
      this.setListenNowSettingData()
    }
  },
  mounted() {
    this.setListenNowSettingData();
    this.getTracksAndAlbums();
  },
  methods: {
    setListenNowSettingData () {
      this.listen_now_settings = this.listenNowSettings
    },
    getTracksAndAlbums(){
      this.$store.dispatch('songsAndAlbums')
    },
    onChange(value){
      switch (this.listen_now_settings.what_to_listen) {
        case 'Songs':
          this.listen_now_settings.album = this.latestSongs.find((song) => song.value === value) || null;
          break;

        case 'Albums':
          this.listen_now_settings.album = this.latestAlbums.find((album) => album.value === value) || null;
          break;

        default:
          console.error('Invalid option for what_to_listen');
          this.listen_now_settings.album = null;
          break;
      }
    }
  }
}
</script>
<style scoped lang="scss">
.red-error-border {
  border: 1px solid #D1433A !important;
}
</style>