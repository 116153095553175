<template>
  <div class="row">
    <div class="col">

      <spinner v-if="getSpinnerData && (getSpinnerData.display && getSpinnerData.for === 'history')" />
      <div v-else>
        <div v-if="listeningSongs.length > 0">
          <div>
            <listening-history-listing :listData="listeningSongs" :title="'Listening History'" />
          </div>
          <spinner v-if="getSpinnerData && (getSpinnerData.display && getSpinnerData.for === 'show_more_history')" />
          <div class="show_more_btn" v-if="nextBatchCount && !getSpinnerData">
            <div class="show-more musicbar_more">
              <a @click="fetchHistoryData(true, 'show_more_history')" href="javascript:;">
                <span class="show_more_bg">
                  <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.24219 0.689941V7.01994" stroke="#071526" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M1 3.85498H7.48702" stroke="#071526" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <span class="mt-1">
                  SHOW {{ nextBatchCount }} MORE SONGS
                </span>
              </span>
              </a>
            </div>
          </div>
        </div>
        <no-data v-else
                 cardTitle="You don't have any listening history yet."
                 cardSubTitle="Browse the music to create listening history."
                 cardIconType="FriendsIcon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import listeningHistoryListing from "@/components/Home/Profile/favorites/music/listeningHistoryListing.vue";
import { getPageData } from "../../../../apis/APIs";
import fetchApis from "../../../../utils/DynamicFunctionToCallApis";
import arrayToObjectByKey from "../../../../utils/ArrayToObjectByKey";
import Spinner from "../../../Common/Spinners/spinner.vue";

export default {
  name: "listeningHistory",
  data() {
    return {
      listeningSongs: [],
      totalRows: 0,
      loading: false,
      limit: 6,
      offset: 0,
    }
  },
  mounted() {
    this.fetchHistoryData();
  },
  computed: {
    nextBatchCount() {
      if ((this.totalRows - this.listeningSongs.length) > 6) {
        return 6
      } else if ((this.totalRows - this.listeningSongs.length) < 6 && (this.totalRows - this.listeningSongs.length) > 0) {
        return Number(this.totalRows - this.listeningSongs.length)
      } else {
        return false
      }
    },
    getSpinnerData() {
      return this.$store.getters.getSpinner;
    },
  },
  components: {
    Spinner,
    // SongsStreaming
    "no-data": require("@/components/Home/People/NoData").default,
    listeningHistoryListing
  },
  
  methods: {
    async fetchHistoryData(showMore = false, spinnerFor = 'history') {
      try {
        if (!showMore) {
          this.offset = 0;
          this.listeningSongs = [];
        }

        const responses = await fetchApis(
          [this.getListeningHistory()],
          this.$store,
          false,
          true,
          spinnerFor
        );

        const { channels } = arrayToObjectByKey(responses, 'listening_history');

        this.listeningSongs = showMore
          ? [...this.listeningSongs, ...channels.center.objects.data]
          : channels.center.objects.data;

        this.totalRows = channels.center.objects.total;

        if (showMore) {
          this.offset += this.limit;
        }

      } catch (error) {
        console.error("Error loading data:", error);
      }
    },


    getListeningHistory(returnKey = 'listening_history') {
      return async () => {
        try {
          const { data } = await getPageData(
            'continue-listening-to',
            this.limit,
            this.offset,
            this.filterApplied
          );
          return { [returnKey]: data || {}};
        } catch (error) {
          throw error
        }
      }
    },

  }
}
</script>

<style scoped></style>
