<template>
  <div class="related-widget">
    <div class="widget-title">
      Friends
      <div v-if="friendsList && friendsList.length > 0" class="link-more near_arrow without-slider in-title pointerClass">
        <a v-on:click="changeTab"
          >See All
          <span class="sl-arrow">
            <img
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgY2xhc3M9InN2Zy1hcnJvdy1yaWd0aCIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZD0iTTEuMjkzNTggMS4zOTQ3OEw3LjM0NTUgNi4zMTI5MkwxLjI5MzU4IDExLjY2MzYiICBzdHJva2U9IiM0NzUwNUIiICBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIg0KICAgICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg0KDQo="
            />
          </span>
        </a>
      </div>
    </div>
    <div class="frd-list-details" v-if="friendsList && friendsList.length > 0">
      <div
        class="friends-lists"
        v-for="(friend, index) in friendsList"
        :key="index"
      >
        <div class="friend-cover">
          <div class="friend-cover-image">
            <div class="friend-image-wrapper" style="cursor: pointer;">
              <img :src="friend.artwork_url" alt="friend_image" @click="redirectTo(friend.username)" @error="onProfileImageError($event, getFirstAlphabetOfUserName(friend))" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mt-1">No Friends</div>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {
      friends_list: null,
    };
  },
  components: {},
  computed: {
    friendsList() {
      return this.$store.getters.getWidgetFriends;
    }
  },
  methods: {
    changeTab: function () {
      this.$parent.change_active_tab("friends");
    },
    redirectTo(name = ''){
    this.$router.push(`/home/page/user-profile/${name}`)
    }
  },

};
</script>
