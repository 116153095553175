<template>
    <div class="widget-follow" v-if="upcomingEvents && upcomingEvents.length > 0">
        <div class="widget-title">
            {{ title }}
            <div class="link-more near_arrow without-slider in-title pointerClass"><a v-on:click="changeTab">See All
                    <span class="sl-arrow">
                        <img
                            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgY2xhc3M9InN2Zy1hcnJvdy1yaWd0aCIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZD0iTTEuMjkzNTggMS4zOTQ3OEw3LjM0NTUgNi4zMTI5MkwxLjI5MzU4IDExLjY2MzYiICBzdHJva2U9IiM0NzUwNUIiICBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIg0KICAgICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg0KDQo=">
                    </span>
                </a>
            </div>
        </div>
        <div>
          <div class="widget-item max-width" v-for="(event, index) in upcomingEvents.slice(0, 3)" :key="index">
            <div class="event-date-box">
                <div class="event-date-box-text">{{getEventMonth(event.started_at)}}</div>
                <div class="event-date-box-value">{{getEventDate(event.started_at)}}</div>
            </div>
            <div v-if="event" class="event-title">
                <div class="name">{{ (event.title) ?
                    event.title : 'Name'}}</div>
                <div class="info">
                    <div>
                        <span class="event-location">
                            {{ event.city ? event.city : '' }}{{ event.city && event.country ? ',' : ''}}{{
                            event.country ? ' ' + event.country : '' }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="loader-container" v-if="loadingEventId === event.id">
              <div class="loader"></div>
            </div>
            <template v-else>
              <div class="interested-svg-icon" v-if="event.eventStatus === 'Interested'">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                  <path d="M5.116 0L6.716 3.133L10.236 3.58701L7.655 6.039L8.26498 9.444L5.121 7.80901L1.97699 9.444L2.58701 6.039L0 3.58701L3.51999 3.133L5.116 0Z" fill="#D1433A"/>
                </svg>
              </div>
              <SelectDropdown
                :options="eventsOption"
                hide-cross-icon
                class="interested-select"
                :class="{ 'pl-3': event.eventStatus === 'Interested' }"
                min-width="102px"
                v-model="event.eventStatus"
                inputColor="#D1433A"
                @change="((value) => {
                  onChange(value, event);
                })"
              />
              <div class="dropdown-svg-icon">
                <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L5 5L9 1" stroke="#D1433A" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </template>
          </div>
        </div>
    </div>
</template>
<script type="application/javascript">
import moment from "moment";
import SelectDropdown from "../../../Common/Dropdown/SelectDropdown.vue";
import { mapActions } from "vuex";
export default {
  props: {
    title: {
      type: String,
      default: "Events"
    },
  },
  data() {
    return {
      upcomingDisplayEvents: [],
      loadingEventId: null,
    }
  },
  computed: {
    upcomingEvents() {
        const events = this.$store.getters.getCurrentPageUpcomingEvents;
        if (!events || !Array.isArray(events)) return [];
        return events.map(event => ({
          ...event,
          eventStatus: event.interested ? 'Interested' : event.going ? 'Going' : null
        }));
    },
    eventsOption() {
      return [
        { value: 'Interested', label: 'Interested' },
        { value: 'Going', label: 'Going' }
      ]
    },
  },
  components: {
    SelectDropdown
  },
  methods: {
    ...mapActions(["InterestedGoing"]),
    changeTab: function () {
        this.$parent.change_active_tab('events');
    },
    getEventMonth(isoString) {
        return moment(isoString).tz('UTC').format('MMM').toUpperCase();
    },
    getEventDate(isoString) {
        return moment(isoString).tz('UTC').format('DD');
    },
    async onChange(status, event) {
      this.loadingEventId = event.id;
      const payload = {
        event_id: event.id,
        interest: status.toLowerCase(),
      };
      try {
        await this.InterestedGoing(payload);
        event.eventStatus = status; 
      } catch (error) {
        console.error("Error updating event status:", error);
      } finally {
        this.loadingEventId = null;
      }
    },
  },
}
  </script>

<style scoped>
.loader-container {
  margin-left: auto;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #D1433A;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.pl-3 {
  padding-left: 0.7rem !important
}
.widget-follow .widget-item.max-width:hover {
  background: #fff;
  transition: none;
}
.interested-svg-icon {
  position: absolute;
  left: 16rem;
  top: 1.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.dropdown-svg-icon {
  position: absolute;
  right: 1.7rem;
  top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.event-date-box {
    border: 1px solid #C4CCD6;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    width: 50px;
    margin-right: 16px
}

.event-date-box-text {
    font-family: 'Helvetica Neue';
    font-size: 12px;
    font-weight: 700;
    line-height: 14.65px;
    background-color: #95B3D7;
    color: #FFFFFF;
    width: full;
    text-align: center;
    padding: 4px 0px;
}

.event-date-box-value {
    font-family: 'Helvetica Neue';
    font-size: 18px;
    font-weight: 700;
    line-height: 21.98px;
    text-align: center;
    margin: 3px auto;
}

.event-title {
    font-family: 'Helvetica Neue';
    font-size: 13px;
    font-weight: 500;
    color: #071526;
}

.event-location {
    font-family: 'Helvetica Neue';
    font-size: 12px;
    font-weight: 500;
    color: #47505B;
    opacity: 70%;
}

.interested-select {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 11px;
  font-weight: 400;
  border: 2px solid #D1433A;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  margin-left: auto;
  width: 100px;
}

.interested-select:focus {
    border-color: #D1433A;
}

.interested-select option {
    font-weight: 400;
    color: #333;
}
</style>
  
