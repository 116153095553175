<template>
  <div class="newsFeedContainer">
    <div class="row">
      <div class="col">
        <h1 class="Page_title" v-if="this.$route.name">{{
            (this.$route.name === 'home.other-detail') ? this.$route.params.name.toUpperCase() : 'News Feed'
          }}</h1>
      </div>
    </div>
    <div class="feed_wrapper">
      <div class="center_centerbar_wraper_home"  v-if="!getTrendingViewCheck">
        <div class="row">
          <div class="col">
            <div class="page-filter d-flex d-flex flex-row align-items-center">
              <div class="back-link">
                <a class="d-flex flex-row align-items-center" @click="goBack">
                  <div class="svg-icon">
                    <svg
                        width="7"
                        height="14"
                        viewBox="0 0 7 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          d="M5.8645 12.7285L0.135502 7.00052L5.8645 1.27152"
                          stroke="#071526"
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="c-line-1"
                      ></path>
                    </svg>
                  </div>
                  <div v-if="this.$route.name">
                    {{
                      (this.$route.name === 'home.other-detail') ? this.$route.params.name.toUpperCase() : 'NEWS FEED'
                    }}
                  </div>
                </a>
              </div>
              <div class="filter-title">POST</div>
            </div>
          </div>
        </div>

        <!-- <Social /> -->
        <PostDetail :item="single_post" :index="0" :expandable="true" :comment_check="true" :userPicture="picture"
                    :pageCheck="'single_post'" @addCommentInList="addCommentInList"/>

        <div class="row">
          <div class="col">
            <CommentListing :item="single_post" ref="commentList"/>
          </div>
        </div>
      </div>

      <TrendingArticles
        v-if="getTrendingViewCheck"
        last-url="News Feed"
        @showMoreTrending="showMoreTrending"
      />
      <NewsFeedOtherData />
    </div>
  </div>
</template>

<script type="application/javascript">
import {mapGetters} from "vuex";
import {post_api} from '../../apis/APIs';
import PostDetail from './NewsFeed/PostItem/PostDetail';
import NewsFeedOtherData from './NewsFeed/NewsFeedOtherData';
import CommentListing from "@/components/Home/NewsFeed/PostItem/CommentListing.vue";
import TrendingArticles from "./Article/TrendingArticles";

export default {
  data() {
    return {
      message: "",
      register_error: false,
      post_detail: {},
      post_detail_loading: false,
      comment_count: 0,
      picture: '',
      trending_limit : 10,
    };
  },
  mounted() {
    this.$store.dispatch('getIndividualPost', this.$route.params.id);
    this.getPicture();
    this.$store.dispatch("getTrendingList");
  },
  components: {
    PostDetail,
    CommentListing,
    NewsFeedOtherData,
    TrendingArticles,
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    }),
    single_post() {
      return this.$store.getters.getSinglePost
    },
    getTrendingViewCheck() {
      return this.$store.getters.getTrendingViewCheck;
    },
  },
  methods: {
    showMoreTrending() {
      this.trending_limit += 10
      this.$store.dispatch("getTrendingList", { offset: 0, limit: this.trending_limit });
    },
    async init() {
      try {
        const {id} = this.$route.params;

        const {user_data} = this.user || {};
        const {user_id} = user_data || {};

        this.post_detail_loading = true;

        const query = `?action=5&user_id=${user_id}&post_id=${id}`;
        const {status, message, data} = await post_api(query);
        this.post_detail_loading = false;

        this.post_detail = data && data.post_id ? data : {};

      } catch (error) {
        this.post_detail_loading = false;
      }
    },
    increment_comment_count(value) {
      this.comment_count = value;
    },
    goBack() {
      if (this.$route.params.name === 'timeline') {
        this.$router.replace(`/home/page/user-profile/${this.user.user_data.username}`);
      } else if (this.$route.params.name === 'artist') {
        this.$store.commit('SET_PAGE_ACTIVE_TAB', 'posts');
        this.$router.replace(`/page-manager/page/${this.$route.params.page_id}`);
      }  else if (this.$route.params.name === 'article') {
        this.$store.commit('SET_PAGE_ACTIVE_TAB', 'posts');
        this.$router.replace(`/home/page/article`);
      } else {
        this.$router.replace("/home/page/news-feed");
      }
    },
    getPicture() {
      if (this.single_post.user) {
        this.picture = this.single_post.user.artwork_url ? this.single_post.user.artwork_url : ''
      }
    },
    addCommentInList(comment) {
      this.$refs.commentList.addCommentInList(comment)
    },
  },
};
</script>
