<template>
  <div class="engage_settings">
    <div class="engage_settings_header">
      <div>
        <h3 class="btn_visibility_header">Button Visibility: {{ learn_more_settings.visibility? 'Published': 'Hidden' }}</h3>
        <h6 class="btn_visibility_text">Published (switcher is on) anyone can see this button in your Engage. Switch off if you want to hide your
          button</h6>
      </div>

      <div class="switch_sm_wrapper">
        <input type="checkbox" v-model="learn_more_settings.visibility" id="switch21">
        <label for="switch21" class="switch_sm">
          <div class="switch_toggler"></div>
        </label>
      </div>
    </div>
    <div class="engage_settings_body">
      <div class="engage_set_title">
        <h4 class="header">Where would you like this button to send people to?</h4>
        <p class="text">When customers click your button, they will be directed to a place where they can take an action or find more
          information.</p>
      </div>
      <div class="engage_fields_group">
        <div class="engage_field">
          <label class="engage_fields_label action_btn_label">
            Action Button Link
            <span class="color_red">*</span>
          </label>
          <input 
            type="url" 
            v-model="learn_more_settings.action_button_link" 
            class="form_control action_link_input" 
            :class="{'red-error-border': activeRedBorder && !learn_more_settings.action_button_link }"
          >
          <small class="text_muted action_btn_placeholder">Website link must start with http:// or https://</small>
        </div>
      </div>
    </div>
    <div class="engage_settings_footer">
      <h4 class="btn-preview-text">Your Button preview:</h4>
      <button type="button">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M7.9987 14.6663C11.6806 14.6663 14.6654 11.6816 14.6654 7.99967C14.6654 4.31778 11.6806 1.33301 7.9987 1.33301C4.3168 1.33301 1.33203 4.31778 1.33203 7.99967C1.33203 11.6816 4.3168 14.6663 7.9987 14.6663Z"
              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8 10.6667V8" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8 5.33301H8.00667" stroke="white" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
        Learn More
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      learn_more_settings:{
        visibility: false,
        action_button_link: null
      }
    }
  },
  props: {
    learnMoreSettings: {
      type: Object,
      default: ()=> {}
    },
    activeRedBorder: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    'learn_more_settings.visibility'() {
      this.$emit('getLearnMoreData', this.learn_more_settings)
    },
    'learn_more_settings.action_button_link'() {
      this.$emit('getLearnMoreData', this.learn_more_settings)
    },
    learnMoreSettings() {
      this.setLearnMoreData()
    }
  },
  mounted() {
    this.setLearnMoreData()
  },
  methods: {
    setLearnMoreData () {
      this.learn_more_settings = this.learnMoreSettings
    },
  }
}
</script>
<style scoped lang="scss">
.red-error-border {
  border: 1px solid #D1433A;
}
</style>