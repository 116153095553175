<template>
  <div>
    <div v-if="getFollowingsList.length > 0">
      <div class="row mt_10">
      <div class="col-md-6 " v-for="(my_followings, index) in getFollowingsList" :key="index">
        <div class="frind_list_box">
          <div class="friend_profile_image">
            <div class="friend-image-wrapper cursor-pointer"
                 @click="redirectWidgetsToProfilePage($event, my_followings.username ? my_followings.username : '')"
            >
              <img :src="my_followings.artwork_url" alt="user_image" @error="onProfileImageError($event, getFirstAlphabetOfUserName(my_followings))">
            </div>
          </div>
          <div class="friend_profile_content">
            <h4 class="friend_profile_name cursor-pointer"
                @click="redirectWidgetsToProfilePage($event, my_followings.username ? my_followings.username : '')"
            >{{ my_followings.name}}</h4>
            <p class="friend_profile_location">
                <span class="location_map">
                <svg width="10" height="12" viewBox="0 0 10 12" fill="none"
                     xmlns="http://www.w3.org/2000/svg" >
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M5 11.5C5 11.5 9.5 8.49997 9.5 4.99997C9.5 3.8065 9.02589 2.6619 8.18198 1.81799C7.33807 0.974075 6.19347 0.499969 5 0.499969C3.80653 0.499969 2.66193 0.974075 1.81802 1.81799C0.974106 2.6619 0.5 3.8065 0.5 4.99997C0.5 8.49997 5 11.5 5 11.5ZM6.5 4.99997C6.5 5.8284 5.82843 6.49997 5 6.49997C4.17157 6.49997 3.5 5.8284 3.5 4.99997C3.5 4.17154 4.17157 3.49997 5 3.49997C5.82843 3.49997 6.5 4.17154 6.5 4.99997Z"
                        fill="#47505B"/>
               </svg>
              </span>
              <span class="mr-0"> {{(my_followings.country.length)?my_followings.country[0].name:''}}</span>
            </p>
          </div>
          <div class="friends_friend_icon" @click="changeFollowStatus(my_followings.id, 'user',1, my_followings)" v-if="my_followings.follow_status === 0">
            <svg width="18" height="15" viewBox="0 0 18 15" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path class="friends_icon_path"
                    d="M12.0345 14.1553V12.776C12.0345 12.0443 11.7439 11.3427 11.2265 10.8253C10.7092 10.308 10.0075 10.0173 9.27588 10.0173H3.75863C3.02699 10.0173 2.32533 10.308 1.80798 10.8253C1.29064 11.3427 1 12.0443 1 12.776V14.1553"
                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="friends_icon_path"
                    d="M6.51644 7.25846C8.03998 7.25846 9.27506 6.02338 9.27506 4.49984C9.27506 2.97629 8.03998 1.74121 6.51644 1.74121C4.99289 1.74121 3.75781 2.97629 3.75781 4.49984C3.75781 6.02338 4.99289 7.25846 6.51644 7.25846Z"
                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="friends_icon_path" d="M14.3906 4.47363V8.91254" stroke="#D1433A"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="friends_icon_path" d="M12.2461 6.69336H16.685" stroke="#D1433A"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="friends_friend_icon frd-following-icon" v-if="my_followings.follow_status === 1" @click="changeFollowStatus(my_followings.id, 'user', 0, my_followings)">
            <svg width="18" height="15" viewBox="0 0 18 15" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path class="friends_icon_path"
                    d="M12.0345 14.1553V12.776C12.0345 12.0443 11.7439 11.3427 11.2265 10.8253C10.7092 10.308 10.0075 10.0173 9.27588 10.0173H3.75863C3.02699 10.0173 2.32533 10.308 1.80798 10.8253C1.29064 11.3427 1 12.0443 1 12.776V14.1553"
                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="friends_icon_path"
                    d="M6.51644 7.25846C8.03998 7.25846 9.27506 6.02338 9.27506 4.49984C9.27506 2.97629 8.03998 1.74121 6.51644 1.74121C4.99289 1.74121 3.75781 2.97629 3.75781 4.49984C3.75781 6.02338 4.99289 7.25846 6.51644 7.25846Z"
                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="friends_icon_path" d="M14.3906 4.47363V8.91254" stroke="#D1433A"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="friends_icon_path" d="M12.2461 6.69336H16.685" stroke="#D1433A"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
      <spinner v-if="getSpinnerData && (getSpinnerData.display && getSpinnerData.for === 'show_more_followings')"/>
      <div class="show_more_btn" v-if="(getFollowingsTotal > getFollowingsList.length) && !getSpinnerData">
      <div class="show-more musicbar_more">
        <a href="javascript:;" @click="showMoreFollowings">
          <span class="show_more_bg">
             <svg width="9" height="8" viewBox="0 0 9 8" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                <path d="M4.24219 0.689941V7.01994" stroke="#071526" stroke-width="1.3"
                      stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M1 3.85498H7.48702" stroke="#071526" stroke-width="1.3"
                      stroke-linecap="round" stroke-linejoin="round"></path>
             </svg>
             <span class="mt-1">
               SHOW MORE FOLLOWINGS
             </span>
          </span>
        </a>
      </div>
    </div>
  </div>
  <no-data v-else cardTitle="You don't have any followings yet."
  cardSubTitle="Browse the playyground to find followings." cardIconType="FriendsIcon"/>
  </div>
</template>
<script type="application/javascript">
import fetchApis from "../../../../utils/DynamicFunctionToCallApis";
import Spinner from "../../../Common/Spinners/spinner.vue";

export default {
  props:['friendsSearchKey','currentActiveFriendTab'],
  data() {
    return {
      showMore:true
    }
  },
  components: {
    Spinner,
    "no-data": require("@/components/Home/People/NoData").default,
  },
  computed:{
    getFollowingsList(){
      return this.$store.getters.getMyFollowings
    },
    getFollowingsTotal(){
      return this.$store.getters['getFollowingsTotal']
    },
    getSpinnerData(){
      return this.$store.getters.getSpinner
    }
  },
  methods:{
    showMoreFollowings(){
      let payload = {
        showMore : this.showMore,
        friendsSearchKey : this.friendsSearchKey,
        search_type: this.currentActiveFriendTab
      }
      fetchApis([() => this.$store.dispatch('getMyFollowings',payload)], this.$store, false, true, 'show_more_followings').catch((error) => {
        console.log(error)
      })
    },
    changeFollowStatus(id,object_type,action, my_followings){
      let payload = {
        id : id,
        object_type : object_type,
        action : action,
        user : my_followings
      }
      this.$store.dispatch('changeFollowStatus', payload)
    }
  }
}
</script>
