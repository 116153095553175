<template>
  <div class="ag-container">
<!--    <MusicSubHeader/>-->
    <div class="container_xxl">
      <div class="artist_body mx-auto">
        <div class="panel_wrapper">
          <div class="panel_inside" style="width: 830px">
            <div class="panel_header">
              <div class="panel_title">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.28 0H0.72C0.32175 0 0 0.32175 0 0.72V17.28C0 17.6783 0.32175 18 0.72 18H17.28C17.6783 18 18 17.6783 18 17.28V0.72C18 0.32175 17.6783 0 17.28 0ZM5.76 14.22C5.76 14.319 5.679 14.4 5.58 14.4H3.78C3.681 14.4 3.6 14.319 3.6 14.22V3.78C3.6 3.681 3.681 3.6 3.78 3.6H5.58C5.679 3.6 5.76 3.681 5.76 3.78V14.22ZM10.08 7.92C10.08 8.019 9.999 8.1 9.9 8.1H8.1C8.001 8.1 7.92 8.019 7.92 7.92V3.78C7.92 3.681 8.001 3.6 8.1 3.6H9.9C9.999 3.6 10.08 3.681 10.08 3.78V7.92ZM14.4 9.54C14.4 9.639 14.319 9.72 14.22 9.72H12.42C12.321 9.72 12.24 9.639 12.24 9.54V3.78C12.24 3.681 12.321 3.6 12.42 3.6H14.22C14.319 3.6 14.4 3.681 14.4 3.78V9.54Z" fill="#47505B"/>
                </svg>

                Projects
              </div>
              <button class=" btn-view ml-auto" type="button" @click="redirectToProfilePage(pageUserName)">
                        <span class="pr-2">
                           <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0)">
                                 <path
                                     d="M0.666504 8.00008C0.666504 8.00008 3.33317 2.66675 7.99984 2.66675C12.6665 2.66675 15.3332 8.00008 15.3332 8.00008C15.3332 8.00008 12.6665 13.3334 7.99984 13.3334C3.33317 13.3334 0.666504 8.00008 0.666504 8.00008Z"
                                     stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                     stroke-linejoin="round"></path>
                                 <path
                                     d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                                     stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                     stroke-linejoin="round"></path>
                              </g>
                              <defs>
                                 <clipPath id="clip0">
                                    <rect width="16" height="16" fill="white"></rect>
                                 </clipPath>
                              </defs>
                           </svg>
                        </span>
                PREVIEW
              </button>
            </div>
            <div class="artist_body_setting">
              <div class="artist-setting-tab">
                <div class="artist-setting-panel">
                  <div class="work-heading-block">
                    <div class="work-experience">Project Experience</div>
                    <div class="work-add-more">
                      <button v-if="pageProjects && pageProjects.length" v-on:click="addWork()">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path class="add-btn-active"
                                d="M7.00008 12.8332C10.2217 12.8332 12.8334 10.2215 12.8334 6.99984C12.8334 3.77818 10.2217 1.1665 7.00008 1.1665C3.77842 1.1665 1.16675 3.77818 1.16675 6.99984C1.16675 10.2215 3.77842 12.8332 7.00008 12.8332Z"
                                stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path class="add-btn-active" d="M7 4.6665V9.33317" stroke="#D1433A" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                          <path class="add-btn-active" d="M4.66675 7H9.33342" stroke="#D1433A" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        ADD MORE
                      </button>
                    </div>
                  </div>
                  <ul class="add-work-experience">
                    <li class="empty-exp" v-if="pageProjects && !pageProjects.length">
                      <div class="empty-experience">
                        <h1>You haven’t added any Project Experience yet</h1>
                        <p>Use button «Add Project Experience» to fill out this information</p>
                        <div class=" ad-work-exp-btn">
                          <button type="button" v-on:click="addWork()">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M6.99996 12.8332C10.2216 12.8332 12.8333 10.2215 12.8333 6.99984C12.8333 3.77818 10.2216 1.1665 6.99996 1.1665C3.7783 1.1665 1.16663 3.77818 1.16663 6.99984C1.16663 10.2215 3.7783 12.8332 6.99996 12.8332Z"
                                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                              <path d="M7 4.6665V9.33317" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                              <path d="M4.66663 7H9.33329" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                            </svg>
                            ADD PROJECT EXPERIENCE
                          </button>
                        </div>
                      </div>
                    </li>

                    <li v-for="(project, index) in pageProjects" :key="index">
                      <div class="experiance-detail-section">
                        <div class="experiance-detail-section-heading">
                          <h1>{{ project.project_name+' / '+project.project_title}}</h1>
                          <p>{{ new Date(0, project.from_month + 1, 0).toLocaleString('en-us', {month: 'long'}) }}
                            {{ project.from_year }} -
                            {{ project.to_month ? new Date(0, project.to_month + 1, 0).toLocaleString('en-us', {month: 'long'}) : '' }}
                            {{ project.to_year }}</p>
                        </div>
                        <div class="edit-delete" >
                          <button @click="editProject(project)">
                            <!-- <img src="img/edit-icon.png"> -->
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M11.3333 2.00004C11.5083 1.82494 11.7162 1.68605 11.945 1.59129C12.1738 1.49653 12.419 1.44775 12.6666 1.44775C12.9142 1.44775 13.1594 1.49653 13.3882 1.59129C13.617 1.68605 13.8248 1.82494 13.9999 2.00004C14.175 2.17513 14.3139 2.383 14.4087 2.61178C14.5034 2.84055 14.5522 3.08575 14.5522 3.33337C14.5522 3.58099 14.5034 3.82619 14.4087 4.05497C14.3139 4.28374 14.175 4.49161 13.9999 4.66671L4.99992 13.6667L1.33325 14.6667L2.33325 11L11.3333 2.00004Z"
                                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </button>
                          <button type="button" @click="deleteProject(project.id)">
                            <svg width="18"  height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 4.5L4.5 13.5" stroke="#FF0039" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M4.5 4.5L13.5 13.5" stroke="#FF0039" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </button>
                        </div>
                      </div>
                      <p class="exp-des project-description">
                        {{ project.description }}
                      </p>
                      <div v-if="project.artwork_url && project.artwork_url.length > 0" class="border mt-3 mb-7"></div>
                      <div class="row">
                        <div class="col">
                          <div class="upload-photos-section">
                            <ul>
                              <!-- <li>
                              <div class="uploaded-image">
                                  <img src="img/Rectangle1.png">
                              </div>
                              <span class="cross-icon">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path d="M10.5 3.5L3.5 10.5" stroke="white" stroke-linecap="round"
                                          stroke-linejoin="round"></path>
                                      <path d="M3.5 3.5L10.5 10.5" stroke="white" stroke-linecap="round"
                                          stroke-linejoin="round"></path>
                                  </svg>
                              </span>
                              </li> -->
                              <!-- <li>
                              <div class="uploaded-image">
                                  <img src="img/Rectangle2.png">
                              </div>
                              <span class="cross-icon">
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path d="M10.5 3.5L3.5 10.5" stroke="white" stroke-linecap="round"
                                          stroke-linejoin="round"></path>
                                      <path d="M3.5 3.5L10.5 10.5" stroke="white" stroke-linecap="round"
                                          stroke-linejoin="round"></path>
                                  </svg>
                              </span>
                              </li> -->

                              <li  v-for="(eachOp, index) in project.artwork_url" :key="'image'+index" class="images-list-container">
                                <div class="uploaded-image">
                                  <img :src="eachOp.original_url">
                                </div>
                                 <span  @click="editProject(project)" class="cross-icon cursor-pointer">
                                    <svg width="15" height="15" viewBox="0 0 14 14" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.5 3.5L3.5 10.5" stroke="white" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                        <path d="M3.5 3.5L10.5 10.5" stroke="white" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                    </svg>
                                </span>
                              </li>
                              <li class="upload-photo-section-li images-list-container cursor-pointer">
                                <div class="upload-photo-icons" @click="editProject(project)">
                                  <img :src="require('@/assets/img/add-p.png')">
                                  <input type="hidden" multiple="multiple" accept="image/x-png,image/gif,image/jpeg" name="fileToUpload"
                                          id="fileToUpload">
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
<!--                  Commented the save changes button-->
<!--                  <div class="d-flex justify-content-end">-->
<!--                    <button class="prime_button" type="button">Save Changes</button>-->
<!--                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddProject v-if="projectModal" @click="closeWindow" :item="selectedProjectItem" :pageID="currentPage.id"/>
  </div>
</template>
<script type="application/javascript">
import AddProject from '@/components/PageManager/Pages/Components/AddProject.vue'
import { deletePageProjects } from "../../../../apis/APIs";

export default {
  data() {
    return {
      work_list:[],
      preview_list: [],
      images: [],
      projectModal: false,
      selectedProjectItem: null,
    }
  },
  components: {
    AddProject,
    // MusicSubHeader,
  },
  computed: {
    pageType(){
      return this.currentPage.type
    },
    currentPage() {
      return this.$store.getters.getCurrentPage;
    },
    pageProjects(){
      return (this.currentPage)?this.currentPage.projects:[]
    }
  },
  methods: {
    closeWindow: function () {
      this.projectModal = false;
      this.selectedProjectItem = undefined;
    },
    addWork: function () {
      this.projectModal = !this.projectModal;
    },
    editProject(item) {
      this.selectedProjectItem = item;
      this.addWork();
    },
    validateState(name) {
      const {$dirty, $error} = this.$v.createNewPageForm[name];
      return $dirty ? !$error : null;
    },
    uploadImages(event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          }
          this.images.push(input.files[index]);
          reader.readAsDataURL(input.files[index]);
          index++;
        }
        this.selectedImages = this.images
      }
    },

    async deleteProject(id) {
        try {
          await this.$store.dispatch('loading', true)
            const {data}  = await deletePageProjects(id);
          const {success, message} = data || {}
          await this.$store.dispatch('loading', false)
          if (success === 1) {
            const page = data.information || {};
            this.$store.commit('SET_PAGE_DATA', page)
            await this.notificationToast(true, "Page!", message, 5000, 'success')

          } else {
            await this.notificationToast(true, "Page!", message, 5000, 'error')

          }
        } catch (e) {
          await this.$store.dispatch('loading', false)
          await this.notificationToast(true, "Page!", e.message, 5000, 'error')

        }
    },
  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
.images-list-container{
  border: none !important;
  padding: 0;
  margin-bottom: 0;
  width: 170px;
  height: 110px;

  .uploaded-image {
    width: 170px;
    height: 110px;

    img {
      width: 170px;
      height: 110px;
    }
  }
}

.project-description{
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.ad-work-exp-btn {
  button {
    width: 290px;
  }
}

.cross-icon {
  position: absolute;
  top: 11.5px;
  right: 11.5px;
}

</style>
<style scoped lang="scss">
.ag-container {
  width: 100%;
  padding: 0;
}

.panel_wrapper {
  margin-top: 59px;
  margin-top: 130px;
  margin-bottom: 70px;
}
</style>
