<template>
  <div class="host-wrapper">
    <span>Host</span>
    <div class="host-content">
      <div class="host-image-and-info-section">
        <div class="host-img">
          <img 
            :src="hostImage" 
            :alt="hostTitle" 
          />
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z" fill="#0075FF"/>
            <path d="M5.94219 7.26891L3.81219 8.81691L4.62619 6.30991L2.49219 4.76091H5.12819L5.94219 2.25391L6.76019 4.76091H9.39219L7.26219 6.30991L8.07619 8.81691L5.94219 7.26891Z" fill="white"/>
          </svg>
        </div>
        <div class="host-info">
          <span>{{ hostTitle }}</span>
          <div>
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.9">
                <path
                  d="M1.01197 10.9998C1.01197 10.9998 0.573983 8.51785 4.99598 8.42285C4.99598 8.42285 7.99595 8.42285 8.12995 8.42285C12.23 8.42285 12.276 10.9998 12.276 10.9998"
                  stroke="#8B949F"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
                  stroke="#8B949F"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
            <span>{{ hostFollowers }}</span>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.17188 3.17455V0"
                stroke="#8B949F"
                stroke-width="1.2"
              />
              <path
                d="M8.16406 3.17455V0"
                stroke="#8B949F"
                stroke-width="1.2"
              />
              <path
                d="M2.44696 1.5498H9.81971C10.2028 1.55213 10.5696 1.71611 10.8405 2.00636C11.1114 2.2966 11.2645 2.6896 11.2667 3.10006V9.44924C11.2645 9.85971 11.1114 10.2527 10.8405 10.5429C10.5696 10.8332 10.2028 10.9972 9.81971 10.9995H2.44696C2.06387 10.9972 1.69708 10.8332 1.42619 10.5429C1.1553 10.2527 1.00217 9.85971 1 9.44924V3.10006C1.01401 2.69366 1.17093 2.30798 1.43931 2.02041C1.7077 1.73285 2.06767 1.56481 2.44696 1.5498V1.5498Z"
                stroke="#8B949F"
                stroke-width="1.2"
              />
              <path
                d="M2.44531 5.09375H9.88691"
                stroke="#8B949F"
                stroke-width="1.2"
              />
            </svg>
            <span>{{ hostEventsCount }}</span>
          </div>
        </div>
      </div>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.75"
          y="0.75"
          width="28.5"
          height="28.5"
          rx="3.25"
          stroke="#D1433A"
          stroke-width="1.5"
        />
        <path
          d="M9 16.0099C11.757 16.0099 14 18.2552 14 21.015C14 21.5676 14.448 22.0161 15 22.0161C15.552 22.0161 16 21.5676 16 21.015C16 17.151 12.86 14.0078 9 14.0078C8.448 14.0078 8 14.4563 8 15.0088C8 15.5614 8.448 16.0099 9 16.0099Z"
          fill="#D1433A"
        />
        <path
          d="M9 10.0021C15.0655 10.0021 20 14.9416 20 21.0134C20 21.566 20.448 22.0144 21 22.0144C21.552 22.0144 22 21.566 22 21.0134C22 13.838 16.1685 8 9 8C8.448 8 8 8.44846 8 9.00103C8 9.5536 8.448 10.0021 9 10.0021Z"
          fill="#D1433A"
        />
        <path
          d="M9 22.0148C9.55228 22.0148 10 21.5666 10 21.0137C10 20.4609 9.55228 20.0127 9 20.0127C8.44772 20.0127 8 20.4609 8 21.0137C8 21.5666 8.44772 22.0148 9 22.0148Z"
          fill="#D1433A"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['GET_SINGLE_EVENT_DETAILS']),
    hostImage() {
      return (this.GET_SINGLE_EVENT_DETAILS && 
              this.GET_SINGLE_EVENT_DETAILS.host_name && 
              this.GET_SINGLE_EVENT_DETAILS.host_name.host_image) || '';
    },
    hostTitle() {
      return (this.GET_SINGLE_EVENT_DETAILS && 
              this.GET_SINGLE_EVENT_DETAILS.host_name && 
              this.GET_SINGLE_EVENT_DETAILS.host_name.title) || 'Promoter Name';
    },
    hostFollowers() {
      return (this.GET_SINGLE_EVENT_DETAILS && 
              this.GET_SINGLE_EVENT_DETAILS.host_name && 
              this.GET_SINGLE_EVENT_DETAILS.host_name.followers) || '0000';
    },
    hostEventsCount() {
      return (this.GET_SINGLE_EVENT_DETAILS && 
              this.GET_SINGLE_EVENT_DETAILS.host_name && 
              this.GET_SINGLE_EVENT_DETAILS.host_name.events_count) || '27';
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/style/events/eventdetailspage.scss";
</style>
