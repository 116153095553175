<template>
  <div class="ag-container">
<!--    <MusicSubHeader/>-->
    <div class="container_xxl">
      <div class="artist_body mx-auto">
        <div class="panel_wrapper">
          <div class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">
                <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6 15.3346C6 15.3346 12 11.3346 12 6.66797C12 5.07667 11.3679 3.55055 10.2426 2.42533C9.11742 1.30011 7.5913 0.667969 6 0.667969C4.4087 0.667969 2.88258 1.30011 1.75736 2.42533C0.632141 3.55055 0 5.07667 0 6.66797C0 11.3346 6 15.3346 6 15.3346ZM8 6.66797C8 7.77254 7.10457 8.66797 6 8.66797C4.89543 8.66797 4 7.77254 4 6.66797C4 5.5634 4.89543 4.66797 6 4.66797C7.10457 4.66797 8 5.5634 8 6.66797Z" fill="#47505B"/>
                </svg>

                Location
              </div>
              <button class=" btn-view ml-auto" type="button" @click="redirectToProfilePage(username)">
                        <span class="pr-2">
                          <EyeIcon />
                        </span>
                View Profile
              </button>
            </div>
            <div class="artist_body_setting">
              <div class="artist-setting-tab">
                <b-form @submit.prevent="updateLocation">
                  <div class="artist-setting-panel">
                    <div class="row">
                      <div class="col-6">
                        <b-form-group
                            label-for="category"
                        >
                          <template v-slot:label>
                            Country
                          </template>
                          <div class="set-custom-select">
                            <SelectDropdown
                              :options="countries"
                              v-model="location.country"
                              @change="onCountryChange"
                              :style="'width: 349px; border: 1px solid #E6EAF0;'"
                            />
                            <label class="error_msg error_msg_red"
                            v-if="!location.country">Required</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-6">
                        <b-form-group
                            label-for="category"
                        >
                          <template v-slot:label>
                            City
                          </template>
                          <div class="set-custom-select">
                            <SelectDropdown
                              :options="cities"
                              v-model="location.city"
                              @change="((value) => location.city = value)"
                              :style="'width: 349px; border: 1px solid #E6EAF0;'"
                            />
                            <label class="error_msg error_msg_red"
                            v-if="!location.city">Required</label>
                          </div>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <b-form-group
                            label-for="category"
                        >
                          <template v-slot:label>
                            Work Location
                          </template>
                          <div class="set-custom-select">
                            <!-- <SelectDropdown
                              :options="countries"
                              v-model="location.work_location"
                              @change="((value) => location.work_location = value)"
                              :style="'width: 728px; border: 1px solid #E6EAF0;'"
                            /> -->
                            <input 
                            type="text" 
                            id="location.work_location" 
                            v-model="location.work_location"
                            value="null" 
                            class="work_location_input"
                            :style="'width: 350px; border: 1px solid #E6EAF0;'"
                            />
                            <label class="error_msg error_msg_red"
                            v-if="!location.work_location">Required</label>
                          </div>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="d-flex justify-content-end">
                      <button class="prime_button" type="submit">Save Changes</button>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
// import MusicSubHeader from '../MusicSubHeader'
import MusicSubHeader from '@/components/PageManager/PMSubHeader.vue'
import DeletePageModal from '../DeletePageModal'
import {required} from "vuelidate/lib/validators";
import {getCitiesOfCountry, updateLocationOFPage} from "../../../../apis/APIs";
import EyeIcon from '../../../../assets/svgs/EyeIcon.vue';
import SelectDropdown from "../../../Common/Dropdown/SelectDropdown.vue";

export default {
  data() {
    return {
      deletePage: false,
      location: {
        country: null,
        city: null,
        work_location: null
      },
      citiesOFSelectedCountry: {
        loading: false,
        list: []
      },
      workArea: [
        {
          label: 'Worldwide',
          value: 'Worldwide'
        },
        {
          label: 'Anywhere in the UK',
          value: 'Anywhere in the UK'
        },
        {
          label: 'East',
          value: 'East'
        },
        {
          label: 'International',
          value: 'International'
        },
        {
          label: 'Midlands',
          value: 'Midlands'
        },
        {
          label: 'NE England',
          value: 'NE England'
        },
        {
          label: 'Northern Ireland',
          value: 'Northern Ireland'
        },
        {
          label: 'NW England',
          value: 'NW England'
        },
        {
          label: 'Remote',
          value: 'Remote'
        },
        {
          label: 'Republic of Ireland',
          value: 'Republic of Ireland'
        },
        {
          label: 'Scotland',
          value: 'Scotland'
        },
        {
          label: 'SW England',
          value: 'SW England'
        },
        {
          label: 'Wales',
          value: 'Wales'
        }
      ]
    }
  },
  components: {
    EyeIcon,
    SelectDropdown,
    // MusicSubHeader,
  },
  computed: {
    countries() {
      return this.$store.getters.getCountries.map(country => {
        return {
          value: country.id,
          label: country.name,
        }
      })
    },
    cities() {
      if (this.citiesOFSelectedCountry.list.length > 0) {
        return this.citiesOFSelectedCountry.list.map(city => {
          return {
            value: city.id,
            label: city.name,
          }
        })
      }
      return []

    },
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
    pageType() {
      return this.current_page.type
    },
    locationData() {
      return this.$store.getters.getLocationData;
    },
  },
  validations: {
    location: {
      country: {
        required
      },
      city: {
        required
      },
      work_location: {
        required
      },
    }
  },
  watch: {
    // 'location.country': async function (newVal, oldVal) {
    //   this.location.city = null
    //   this.location.work_location = null
    //   if (newVal === null) {
    //     this.citiesOFSelectedCountry.list = []
    //   } else {
    //     console.log("new", newVal)
    //     await this.FetchCitiesOfSelectedCountry(newVal)
    //   }
    // },
    "location.country": {
    immediate: true,
    async handler(newCountry) {
      if (newCountry) {
        await this.FetchCitiesOfSelectedCountry(newCountry);
        // this.location.city = null;
      } else {
        this.location.city = null;
      }
    },
  },
    locationData: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.location.country = newValue.country ? newValue.country.id : null;
          if (this.location.country) {
            this.FetchCitiesOfSelectedCountry(this.location.country);
          }
          this.location.city = newValue.city ? newValue.city.id : null;
          this.location.work_location = newValue.work_location;
        }
      },
    },
  },
  methods: {
    validateState(name) {
      const {$dirty, $error} = this.$v.location[name];
      return $dirty ? !$error : null;
    },
    async FetchCitiesOfSelectedCountry(id) {
      try {
        this.citiesOFSelectedCountry.loading = true
        const {data} = await getCitiesOfCountry(id)
        const {success, information} = data
        if (success === 1) {
          this.citiesOFSelectedCountry.list = information
        }
        this.citiesOFSelectedCountry.loading = false
      } catch (e) {
        this.citiesOFSelectedCountry.loading = false
        console.error(e)
      }
    },
    async updateLocation() {
      this.$v.location.$touch();
      if (this.$v.location.$anyError) {
        return;
      } else {
        try {
          await this.$store.dispatch('loading', true)
          const formData = new FormData();
          for (const field in this.location) {
            if (!!(this.location[field])) {
              formData.append(field, this.location[field]);
            }

          }
          const {data} = await updateLocationOFPage(this.current_page.id, formData);
          const {success, message} = data || {}
          await this.$store.dispatch('loading', false)
          if (success === 1) {
            const page = data.information || {};
            this.$store.commit('SET_PAGE_DATA', page)
            this.notificationToast(true, 'Page', message, 5000, 'success')
          } else {
            await this.notificationToast(true,'Page', message, 5000, 'error')
          }
        } catch (e) {
          await this.$store.dispatch('loading', false)
          await this.notificationToast(true, 'Page', e.message, 5000, 'error')
        }

      }
    },
    async getPageDataFromStore() {
      if(!this.current_page){
        await this.getMusicData()
        this.location.country = (this.locationData.country) ? this.locationData.country.id : null  
        if (this.location.country) {
        await this.FetchCitiesOfSelectedCountry(this.location.country)
      }
        this.location.city = (this.locationData.city) ? this.locationData.city.id : null
        this.location.work_location = this.locationData.work_location
      } else {
      this.location.country = (this.current_page.country) ? this.current_page.country.id : null
      if (this.location.country) {
        await this.FetchCitiesOfSelectedCountry(this.location.country)
      }
      this.location.city = (this.current_page.city) ? this.current_page.city.id : null
      this.location.work_location = this.current_page.work_location
      // this.location.work_location = this.countries.find((work_location) => work_location.value == this.current_page.work_location)
     }
    },
    async getMusicData() {
      this.$store.dispatch("getMusicData", { page_id: this.$route.params.id });
    },
    async onCountryChange(value) {
    this.location.country = value;
    this.location.city = null;
    if (value) {
      await this.FetchCitiesOfSelectedCountry(value);
    }
  },
  },
  async mounted() {
    await this.getPageDataFromStore()
    this.$store.dispatch('fetchCountries')
  }
}
</script>
<style>
.category-select::after {
  background-image: url(../../../../assets/img/artist-select.png);
}

.container-checkbox .week-checkmark::after {
  background-image: url(../../../../assets/img/setting-checkbox.png);
}
</style>
<style lang="scss">
.custom-select {
  &.is-valid {
    border-color: unset!important;
  }
}
.was-validated {
  .custom-select:valid {
    border-color: blue!important;
  }
}
</style>
<style lang="scss" scoped>
.ag-container {
  width: 100%;
  padding: 0;
}
.panel_wrapper {
  margin-top: 59px;
  margin-top: 130px;
}
.work_location_input {
  font-size: 13px;
  color: #8b949f;
  padding: 6px 8px;
  border-radius : 4px;
  font-family: HelveticaNeueNormal, sans-serif !important;
  font-weight: normal !important;
  letter-spacing: 0.5px;
}
</style>
