<template>
  <div class="w-100">
    <div class="px-0">
      <div class="wrapper">
        <div class="row">
          <div class="col">
            <div class="overview-mains g-mood">
              <h1 class="Page_title gen-mood">PODCASTS</h1>
              <div class="switch-field">
                <payStreamSwitch />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="gen-mood-tag">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    >MUSIC</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    >TALK</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div class="row">
                    <div class="col">
                      <div class="section-title-music">
                        What kind of Podcasts are you looking for?
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-sm-2 col-2 genres-select-list"
                      v-for="(eachOp, index) in geners"
                      :key="index"
                    >
                      <div class="genres_checkbox_outer">
                        <input
                          :id="'genres_' + index"
                          type="checkbox"
                          name=""
                          @click="selectGenre($event, eachOp.id)"
                          :checked="isSelectedGenre(eachOp.id)"
                        />
                        <label :for="'genres_' + index" class="genres_box">
                          <!--                          <div-->
                          <!--                              :class="['genres_icon svg-icon', eachOp.name+'_class' ]">-->
                          <!--&lt;!&ndash;                            <img :src="eachOp.icon_url"/>&ndash;&gt;-->
                          <!--                            <img src="" :class="'icon-'+ eachOp.name +'-stroke'"/>-->
                          <!--                          </div>-->
                          <div
                            :class="[
                              'genres-themes',
                              isAddedGenre(eachOp.id) ? 'activeGenre' : '',
                            ]"
                            :style="{
                              'mask-image': `url('` + eachOp.icon_url + `')`,
                            }"
                          ></div>
                          <div class="genres_info">
                            <p class="genres_title" v-html="eachOp.name"></p>
                          </div>
                          <div class="genres_checkbox">
                            <div class="genres_checkbox_lab">
                              <svg
                                width="7px"
                                height="5px"
                                viewBox="0 0 13 10"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                              >
                                <g
                                  id="Page-1"
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <g
                                    id="checked(2)"
                                    fill="#D1433A"
                                    fill-rule="nonzero"
                                  >
                                    <polygon
                                      id="Path"
                                      class="chekmark-check"
                                      points="0.664935065 5.38032418 4.76358812 9.48680081 12.6337662 1.62439985 11.5038734 0.510131007 4.76358812 7.24259274 1.7791807 4.25820853"
                                    ></polygon>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="gen_mooods">
                        <ul>
                          <li
                            v-for="(subGen, index) in selectedGenresSubGenres"
                            :key="index"
                            class="cursor-pointer gen-mood-wrapper"
                            @click="selectSubGenres($event, subGen.id)"
                          >
                            <a
                              :class="{ active: isSelectedSubGenre(subGen.id) }"
                              href="javascript:;"
                              >{{ subGen.name }}</a
                            >
                          </li>
                        </ul>
                        <div
                          @click="clearGenreFilter"
                          class="curson-pointer clear-history1"
                          v-if="
                            selectedGenres.length > 0 ||
                            selectedGenresSubGenres.length > 0
                          "
                        >
                          Clear all history
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="content discover m-t-20">
                    <p v-if="loading" class="text-center">Please Wait...</p>
                    <div
                      v-for="(singleChannel, index) in allChannels.center"
                      v-bind:key="index"
                    >
                      <component
                        v-bind:is="
                          myComponentList[singleChannel.alt_name].component
                        "
                        :cname="singleChannel.description"
                        :myInd="index"
                        :gridTitle="singleChannel.description"
                        :header="true"
                        :redirectLink="'continue-listening-to'"
                        :listData="singleChannel.objects.data"
                        :class="singleChannel.class"
                        v-if="
                          singleChannel.channel_element_view ==
                          'six_grid_slider'
                        "
                      />
                    </div>

                    <!-- <NewEpisodesSlider :gridTitle="'Featured Episodes'" :header="false" :redirectLink="'featured-podcast-see-all'"/> -->
                    <div class="row options m-t-20">
                      <div class="col-sm-2">
                        <div class="btn btn-play-all">
                          <svg
                            width="11"
                            height="13"
                            viewBox="0 0 11 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="svg-play filled"
                          >
                            <path
                              class="c-line-1"
                              d="M1.93569 11.9999C1.81909 11.998 1.70435 11.9704 1.59969 11.9189C1.48036 11.8487 1.37983 11.7506 1.30668 11.6331C1.23354 11.5155 1.18996 11.382 1.17969 11.2439V1.66692C1.18566 1.53314 1.22298 1.40262 1.28864 1.2859C1.3543 1.16917 1.44645 1.06951 1.55769 0.994924C1.67446 0.928694 1.80782 0.89744 1.94186 0.904887C2.0759 0.912333 2.20497 0.958167 2.31369 1.03692L9.66469 5.82492C9.76802 5.89435 9.85268 5.98813 9.91123 6.09799C9.96977 6.20786 10.0004 6.33044 10.0004 6.45492C10.0004 6.57941 9.96977 6.70199 9.91123 6.81185C9.85268 6.92172 9.76802 7.0155 9.66469 7.08492L2.31369 11.8739C2.20318 11.9529 2.07147 11.9968 1.93569 11.9999"
                              stroke="white"
                              stroke-width="1.3"
                            ></path>
                          </svg>
                          PLAY ALL
                        </div>
                      </div>
                      <div class="col">
                        <div
                          class="
                            d-flex
                            flex-wrap
                            justify-content-end
                            align-items-end
                            h-100
                          "
                        >
                          <div class="d-flex flex-wrap align-items-end ml-auto">
                            <div
                              class="
                                text
                                with-icon
                                d-flex
                                justify-content-between justify-content-end
                                align-items-center
                              "
                            >
                              Filters
                              <div
                                class="filter-total-count"
                                :class="{ active: showFilters }"
                                @click="showFilters = !showFilters"
                              >
                                <span class="svg-i">
                                  <svg
                                    width="18"
                                    height="16"
                                    viewBox="0 0 18 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M16.75 1H1L7.3 8.36296V12.563L10.45 15V8.36296L16.75 1Z"
                                      stroke="white"
                                      stroke-width="1.3"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </span>
                                <p class="filter-count">2</p>
                              </div>
                            </div>
                            <div class="change-view js-change-view">
                              <div class="btn-group">
                                <div
                                  data-type="grid"
                                  :class="[
                                    'btn btn-svg-icon js-set-view',
                                    listing_type == 'grid' ? 'active' : '',
                                  ]"
                                  v-on:click="showForm('grid')"
                                >
                                  <div
                                    data-type="grid"
                                    class="svg-icon show-grid grid-svd"
                                  >
                                    <svg
                                      width="30"
                                      height="30"
                                      viewBox="0 0 30 30"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M30 0H0V30H30V0Z"
                                        fill="#D1433A"
                                        class="c-fill-1"
                                      ></path>
                                      <path
                                        d="M16.5 13.5V8.5H21.5V13.5H16.5Z"
                                        stroke="white"
                                        stroke-linecap="square"
                                        class="c-line-1"
                                      ></path>
                                      <path
                                        d="M8.5 13.5V8.5H13.5V13.5H8.5Z"
                                        stroke="white"
                                        stroke-linecap="square"
                                        class="c-line-1"
                                      ></path>
                                      <path
                                        d="M16.5 21.5V16.5H21.5V21.5H16.5Z"
                                        stroke="white"
                                        stroke-linecap="square"
                                        class="c-line-1"
                                      ></path>
                                      <path
                                        d="M8.5 21.5V16.5H13.5V21.5H8.5Z"
                                        stroke="white"
                                        stroke-linecap="square"
                                        class="c-line-1"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                                <div
                                  :class="[
                                    'btn btn-svg-icon js-set-view',
                                    listing_type == 'list' ? 'active' : '',
                                  ]"
                                  v-on:click="showForm('list')"
                                >
                                  <div
                                    data-type="list"
                                    class="svg-icon show-list grid-svd"
                                  >
                                    <svg
                                      width="30"
                                      height="30"
                                      viewBox="0 0 30 30"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M30 0H0V30H30V0Z"
                                        fill="white"
                                        class="c-fill-1"
                                      ></path>
                                      <path
                                        d="M8.5 12.5V8.5H21.5V12.5H8.5Z"
                                        stroke="#8B949F"
                                        stroke-linecap="square"
                                        class="c-line-1"
                                      ></path>
                                      <path
                                        d="M8.5 21.5V17.5H21.5V21.5H8.5Z"
                                        stroke="#8B949F"
                                        stroke-linecap="square"
                                        class="c-line-1"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-show="showFilters">
                      <div class="col-12">
                        <GenresMoodsSelling
                          @setType="setValue"
                          :podcastCheck="true"
                        />
                      </div>
                    </div>
                    <div class="js-container">
                      <div class="sale-items m-t-40">
                        <div class="row justify-content-between">
                          <div class="col-8">
                            <div class="row position-relative">
                              <div
                                v-for="(
                                  singleChannel, index
                                ) in allChannels.center"
                                v-bind:key="index"
                              >
                                <component
                                  v-bind:is="
                                    myComponentList[singleChannel.alt_name]
                                      .component
                                  "
                                  :cname="singleChannel.description"
                                  :myInd="index"
                                  :gridTitle="singleChannel.description"
                                  :header="true"
                                  :pageTitle="singleChannel.title"
                                  :redirectLink="
                                    myComponentList[singleChannel.alt_name].link
                                  "
                                  :listData="singleChannel.objects.data"
                                  :channelType="singleChannel.type"
                                  :altName="singleChannel.alt_name"
                                  v-if="
                                    singleChannel.channel_element_view ==
                                      'four_grid' && listing_type == 'grid'
                                  "
                                />
                                <div
                                  class="col-12 pl-1 mb-2"
                                  v-if="
                                    singleChannel.channel_element_view ==
                                      'four_grid' && listing_type != 'grid'
                                  "
                                >
                                  <h4 class="title-head top-sign-bottom">
                                    {{ singleChannel.title }}
                                  </h4>
                                  <div
                                    class="
                                      link-more
                                      near_arrow
                                      without-slider
                                      cursor-pointer
                                    "
                                    style="top: 0"
                                  >
                                    <a
                                      @click="
                                        redirectToChannel(
                                          singleChannel.type,
                                          singleChannel.alt_name,
                                          singleChannel.title
                                        )
                                      "
                                      >See all
                                      <span class="sl-arrow">
                                        <img
                                          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgY2xhc3M9InN2Zy1hcnJvdy1yaWd0aCIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZD0iTTEuMjkzNTggMS4zOTQ3OEw3LjM0NTUgNi4zMTI5MkwxLjI5MzU4IDExLjY2MzYiICBzdHJva2U9IiM0NzUwNUIiICBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIg0KICAgICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg0KDQo="
                                        />
                                      </span>
                                    </a>
                                  </div>
                                  <div class="lists">
                                    <div class="row">
                                      <component
                                        v-bind:is="'SongsPopularity'"
                                        :cname="singleChannel.description"
                                        :myInd="index"
                                        :gridTitle="singleChannel.description"
                                        :header="true"
                                        :redirectLink="
                                          myComponentList[
                                            singleChannel.alt_name
                                          ].link
                                        "
                                        :listData="singleChannel.objects.data"
                                        :channelType="singleChannel.type"
                                        :altName="singleChannel.alt_name"
                                        :pageTitle="singleChannel.title"
                                        :showRating="false"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="podcast content-sidebar">
                              <div
                                v-for="(
                                  singleChannel, index
                                ) in allChannels.rightbar"
                                v-bind:key="index"
                              >
                                <!-- <span>{{ myComponentList[singleChannel.filter].component }}</span> -->
                                <component
                                  v-bind:is="
                                    myComponentList[singleChannel.alt_name]
                                      .component
                                  "
                                  :cname="singleChannel.description"
                                  :myInd="index"
                                  :gridTitle="singleChannel.description"
                                  :header="true"
                                  :redirectLink="'continue-listening-to'"
                                  :listData="singleChannel.objects.data"
                                  :altName="singleChannel.alt_name"
                                  :channelType="singleChannel.type"
                                  :pageTitle="singleChannel.title"
                                  v-if="
                                    singleChannel.alt_name !=
                                    'most_popular_track_albums_tat_realeased_in_last_month'
                                  "
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div class="row">
                    <div class="col">
                      <div class="section-title-music">
                        What kind of Podcasts are you looking for?
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-sm-2 col-2 genres-select-list"
                      v-for="(eachOp, index) in talks"
                      :key="index"
                    >
                      <div class="genres_checkbox_outer">
                        <input
                          :id="'talks_' + index"
                          type="checkbox"
                          name=""
                          @click="selectTalks($event, eachOp.id)"
                        />
                        <label :for="'talks_' + index" class="genres_box">
                          <!--                          <div-->
                          <!--                              :class="['genres_icon svg-icon', eachOp.name+'_class' ]">-->
                          <!--                            <img :src="eachOp.artwork_url"/>-->
                          <!--                          </div>-->
                          <div
                            :class="[
                              'talks-themes',
                              isAddedTalk(eachOp.id) ? 'activeTalk' : '',
                            ]"
                            :style="{
                              'mask-image': `url('` + eachOp.artwork_url + `')`,
                            }"
                          ></div>
                          <div class="genres_info">
                            <p class="genres_title" v-html="eachOp.name"></p>
                          </div>
                          <div class="genres_checkbox">
                            <div class="genres_checkbox_lab">
                              <svg
                                width="7px"
                                height="5px"
                                viewBox="0 0 13 10"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                              >
                                <g
                                  id="Page-1"
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <g
                                    id="checked(2)"
                                    fill="#D1433A"
                                    fill-rule="nonzero"
                                  >
                                    <polygon
                                      id="Path"
                                      class="chekmark-check"
                                      points="0.664935065 5.38032418 4.76358812 9.48680081 12.6337662 1.62439985 11.5038734 0.510131007 4.76358812 7.24259274 1.7791807 4.25820853"
                                    ></polygon>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <!--                    <h4 class="title-h4 alb-rev mb-3 ml-1">Genres</h4>-->
                    <!--                    <div class="col-12">-->
                    <!--                      <div class="gen_mooods">-->
                    <!--                        <ul>-->
                    <!--                          <li v-for="(eachOp, index) in geners" :key="index"-->
                    <!--                              class="cursor-pointer" @click="selectGenre($event,eachOp.id)"><a-->
                    <!--                              :class="{'active':isSelectedGenre(eachOp.id)}" href="javascript:;">{{ eachOp.name }}</a>-->
                    <!--                          </li>-->
                    <!--                        </ul>-->
                    <!--                        <div @click="clearGenreFilter" class="curson-pointer clear-history1"-->
                    <!--                             v-if="selectedGenres.length>0 ">Clear all history-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <div class="col-12">
                      <div class="new-releae-geners">
                        <h1>Genres</h1>
                        <div class="gen_mooods">
                          <ul>
                            <li
                              @click="selectGenre($event, eachOp.id)"
                              v-for="(eachOp, index) in geners"
                              :key="index"
                            >
                              <a
                                :class="{ active: isSelectedGenre(eachOp.id) }"
                                href="javascript:;"
                              >
                                <span v-html="eachOp.name"></span>
                              </a>
                            </li>
                          </ul>
                          <div
                            @click="clearGenreFilter"
                            class="clear-history"
                            v-if="selectedGenres.length > 0"
                          >
                            Clear all history
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="content discover m-t-20">
                    <p v-if="loading" class="text-center">Please Wait...</p>
                    <div
                      v-for="(singleChannel, index) in talkChannels.center"
                      v-bind:key="index"
                    >
                      <component
                        v-bind:is="
                          myComponentList[singleChannel.alt_name].component
                        "
                        :cname="singleChannel.description"
                        :myInd="index"
                        :gridTitle="singleChannel.description"
                        :header="true"
                        :redirectLink="'continue-listening-to'"
                        :listData="singleChannel.objects.data"
                        v-if="
                          singleChannel.channel_element_view ==
                          'six_grid_slider'
                        "
                      />
                    </div>

                    <!-- <NewEpisodesSlider :gridTitle="'Featured Episodes'" :header="false" :redirectLink="'featured-podcast-see-all'"/> -->
                    <div class="row options m-t-20">
                      <div class="col-sm-2">
                        <div class="btn btn-play-all">
                          <svg
                            width="11"
                            height="13"
                            viewBox="0 0 11 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="svg-play filled"
                          >
                            <path
                              class="c-line-1"
                              d="M1.93569 11.9999C1.81909 11.998 1.70435 11.9704 1.59969 11.9189C1.48036 11.8487 1.37983 11.7506 1.30668 11.6331C1.23354 11.5155 1.18996 11.382 1.17969 11.2439V1.66692C1.18566 1.53314 1.22298 1.40262 1.28864 1.2859C1.3543 1.16917 1.44645 1.06951 1.55769 0.994924C1.67446 0.928694 1.80782 0.89744 1.94186 0.904887C2.0759 0.912333 2.20497 0.958167 2.31369 1.03692L9.66469 5.82492C9.76802 5.89435 9.85268 5.98813 9.91123 6.09799C9.96977 6.20786 10.0004 6.33044 10.0004 6.45492C10.0004 6.57941 9.96977 6.70199 9.91123 6.81185C9.85268 6.92172 9.76802 7.0155 9.66469 7.08492L2.31369 11.8739C2.20318 11.9529 2.07147 11.9968 1.93569 11.9999"
                              stroke="white"
                              stroke-width="1.3"
                            ></path>
                          </svg>
                          PLAY ALL
                        </div>
                      </div>
                      <div class="col">
                        <div
                          class="
                            d-flex
                            flex-wrap
                            justify-content-end
                            align-items-end
                            h-100
                          "
                        >
                          <div class="d-flex flex-wrap align-items-end ml-auto">
                            <div
                              class="
                                text
                                with-icon
                                d-flex
                                justify-content-between justify-content-end
                                align-items-center
                              "
                            >
                              Filters
                              <div
                                class="filter-total-count"
                                :class="{ active: showFilters }"
                                @click="showFilters = !showFilters"
                              >
                                <span class="svg-i">
                                  <svg
                                    width="18"
                                    height="16"
                                    viewBox="0 0 18 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M16.75 1H1L7.3 8.36296V12.563L10.45 15V8.36296L16.75 1Z"
                                      stroke="white"
                                      stroke-width="1.3"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </span>
                                <p class="filter-count">2</p>
                              </div>
                            </div>

                            <div class="change-view js-change-view">
                              <div class="btn-group">
                                <div
                                  data-type="grid"
                                  :class="[
                                    'btn btn-svg-icon js-set-view',
                                    listing_type == 'grid' ? 'active' : '',
                                  ]"
                                  v-on:click="showForm('grid')"
                                >
                                  <div
                                    data-type="grid"
                                    class="svg-icon show-grid grid-svd"
                                  >
                                    <svg
                                      width="30"
                                      height="30"
                                      viewBox="0 0 30 30"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M30 0H0V30H30V0Z"
                                        fill="#D1433A"
                                        class="c-fill-1"
                                      ></path>
                                      <path
                                        d="M16.5 13.5V8.5H21.5V13.5H16.5Z"
                                        stroke="white"
                                        stroke-linecap="square"
                                        class="c-line-1"
                                      ></path>
                                      <path
                                        d="M8.5 13.5V8.5H13.5V13.5H8.5Z"
                                        stroke="white"
                                        stroke-linecap="square"
                                        class="c-line-1"
                                      ></path>
                                      <path
                                        d="M16.5 21.5V16.5H21.5V21.5H16.5Z"
                                        stroke="white"
                                        stroke-linecap="square"
                                        class="c-line-1"
                                      ></path>
                                      <path
                                        d="M8.5 21.5V16.5H13.5V21.5H8.5Z"
                                        stroke="white"
                                        stroke-linecap="square"
                                        class="c-line-1"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                                <div
                                  :class="[
                                    'btn btn-svg-icon js-set-view',
                                    listing_type == 'list' ? 'active' : '',
                                  ]"
                                  v-on:click="showForm('list')"
                                >
                                  <div
                                    data-type="list"
                                    class="svg-icon show-list grid-svd"
                                  >
                                    <svg
                                      width="30"
                                      height="30"
                                      viewBox="0 0 30 30"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M30 0H0V30H30V0Z"
                                        fill="white"
                                        class="c-fill-1"
                                      ></path>
                                      <path
                                        d="M8.5 12.5V8.5H21.5V12.5H8.5Z"
                                        stroke="#8B949F"
                                        stroke-linecap="square"
                                        class="c-line-1"
                                      ></path>
                                      <path
                                        d="M8.5 21.5V17.5H21.5V21.5H8.5Z"
                                        stroke="#8B949F"
                                        stroke-linecap="square"
                                        class="c-line-1"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-show="showFilters">
                      <div class="col-12">
                        <GenresMoodsSelling
                          @setType="setValue"
                          :podcastCheck="true"
                        />
                      </div>
                    </div>
                    <div class="js-container">
                      <div class="sale-items m-t-40">
                        <div class="row justify-content-between">
                          <div class="col-8">
                            <!-- <h4 class="title1-h4">TRENDING NOW</h4> -->

                            <div class="row position-relative">
                              <!-- <GridSelling4 v-if="listing_type == 'grid'"/>
                              <SongsPopularity :width="'750 px'" v-else/> -->

                              <div
                                v-for="(
                                  singleChannel, index
                                ) in talkChannels.center"
                                v-bind:key="index"
                              >
                                <!-- <span>{{ myComponentList[singleChannel.filter].component }}</span> -->
                                <component
                                  v-bind:is="
                                    myComponentList[singleChannel.alt_name]
                                      .component
                                  "
                                  :cname="singleChannel.description"
                                  :myInd="index"
                                  :gridTitle="singleChannel.description"
                                  :header="true"
                                  :redirectLink="'continue-listening-to'"
                                  :listData="singleChannel.objects.data"
                                  v-if="
                                    singleChannel.channel_element_view ==
                                      'four_grid' && listing_type == 'grid'
                                  "
                                />
                                <component
                                  v-bind:is="'SongsPopularity'"
                                  :cname="singleChannel.description"
                                  :myInd="index"
                                  :gridTitle="singleChannel.description"
                                  :header="true"
                                  :redirectLink="'continue-listening-to'"
                                  :listData="singleChannel.objects.data"
                                  :showRating="false"
                                  itemType='podcast'
                                  v-if="
                                    singleChannel.channel_element_view ==
                                      'four_grid' && listing_type != 'grid'
                                  "
                                />
                              </div>

                              <div class="link-more near_arrow without-slider">
                                <a @click="redirect('treanding-artist-reviews')"
                                  >See all
                                  <span class="sl-arrow">
                                    <img
                                      src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgY2xhc3M9InN2Zy1hcnJvdy1yaWd0aCIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZD0iTTEuMjkzNTggMS4zOTQ3OEw3LjM0NTUgNi4zMTI5MkwxLjI5MzU4IDExLjY2MzYiICBzdHJva2U9IiM0NzUwNUIiICBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIg0KICAgICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg0KDQo="
                                    />
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="content-sidebar">
                              <div
                                v-for="(
                                  singleChannel, index
                                ) in talkChannels.rightbar"
                                v-bind:key="index"
                              >
                                <!-- <span>{{ myComponentList[singleChannel.filter].component }}</span> -->
                                <component
                                  v-bind:is="
                                    myComponentList[singleChannel.alt_name]
                                      .component
                                  "
                                  :cname="singleChannel.description"
                                  :myInd="index"
                                  :gridTitle="singleChannel.description"
                                  :header="true"
                                  :redirectLink="'continue-listening-to'"
                                  :listData="singleChannel.objects.data"
                                  :altName="singleChannel.alt_name"
                                  :channelType="singleChannel.type"
                                  :pageTitle="singleChannel.title"
                                  v-if="
                                    singleChannel.alt_name !=
                                    'most_popular_track_albums_tat_realeased_in_last_month'
                                  "
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <notifications group="foo" class="notification-style" />
    <full-page-loader :show="loading" />
  </div>
</template>
<script type="application/javascript">
import payStreamSwitch from "@/components/Music/UI-components/payStreamToggleSwitch.vue";
import NewEpisodesSlider from "@/components/Common/Elements/NewEpisodesSlider.vue";
import SongsPopularity from "@/components/Common/Elements/SongsPopularity.vue";
import GridSelling4 from "@/components/Common/Elements/GridSelling4.vue";

import WhoToFollow from "@/components/Common/Elements/WhoToFollow.vue";
import Boosted from "@/components/Common/Elements/Boosted.vue";
import RecentReviews from "@/components/Common/Elements/RecentReviews.vue";

import { mapActions } from "vuex";
import {
  get_podcast_categories,
  getChannelData,
  getMoods,
} from "../../../apis/APIs";
import GenresMoodsSelling from "../../Common/UiList/GenresMoodsSelling";

export default {
  data() {
    return {
      subGeners: [],
      subGenerCounter: [],
      selectedGenres: [],
      selectedSubGenres: [],
      selectedTalks: [],
      showAd: false,
      listing_type: "list",
      talks: [],
      dataLoaded: false,
      inProgress: false,
      allChannels: [],
      talkChannels: [],
      filters: {
        sort_by: "",
        type: "",
        time_period: 0,
        start_price: null,
        end_price: null,
        country: "",
      },
      loading: false,
      showMd: false,
      showFilters: false,
      myComponentList: {
        // PodCasts
        "featured-episodes": {
          component: "NewEpisodesSlider",
          link: "featured-podcast-see-all",
          subComponent: "",
        },
        "trending-this-week": {
          component: "GridSelling4",
          link: "continue-listening-to",
          subComponent: "",
          class: "mb-2",
        },
        "suggested-podcasters": {
          component: "WhoToFollow",
          link: "podcast-recent-reviews",
          subComponent: "",
        },
        "recent-reviews": {
          component: "RecentReviews",
          link: "podcast-recent-reviews",
          subComponent: "",
        },
      },
    };
  },
  components: {
    GenresMoodsSelling,
    payStreamSwitch,
    NewEpisodesSlider,
    SongsPopularity,
    GridSelling4,
    WhoToFollow,
    Boosted,
    RecentReviews,
  },
  mounted() {
    this.pageChannels();
    this.getTalkChannels();
    this.getTalks();
    this.getGenres();
  },
  computed: {
    selectedGenresSubGenres() {
      if (this.selectedGenres.length > 0) {
        let subGenres = [];
        for (const genreId of this.selectedGenres) {
          const index = this.geners.findIndex((e) => e.id === Number(genreId));
          if (index !== -1) {
            subGenres = [...subGenres, ...this.geners[index].subgenre];
          }
        }
        return subGenres;
      }
      return [];
    },
    geners() {
      return this.$store.getters.getGenres &&
        this.$store.getters.getGenres.length > 0
        ? this.$store.getters.getGenres
        : [];
    },
  },
  watch: {
    selectedGenres() {
      this.pageChannels();
    },
    selectedSubGenres() {
      this.pageChannels();
    },
    selectedTalks() {
      this.pageChannels();
    },
  },
  methods: {
    isAddedTalk(id) {
      const talkId = this.selectedTalks.findIndex((ele) => ele === id);
      return talkId !== -1;
    },
    isAddedGenre(id) {
      const genreId = this.selectedGenres.findIndex((ele) => ele === id);
      return genreId !== -1;
    },
    ...mapActions(["get_geners"]),
    async pageChannels() {
      try {
        this.allChannels = [];
        await this.$store.dispatch("loading", true);
        let data = await getChannelData(
          "music_podcast",
          this.selectedGenres,
          this.selectedSubGenres,
          [],
          this.selectedTalks,
          this.filters.sort_by,
          this.filters.type,
          this.filters.time_period,
          this.filters.start_price,
          this.filters.end_price,
          this.filters.country
        );
        this.allChannels = data.data.channels;
        await this.$store.dispatch("loading", false);
      } catch (error) {
        console.error("error", error);
        await this.$store.dispatch("loading", false);
      }
    },
    async getTalkChannels() {
      try {
        this.loading = true;
        let { data } = await getChannelData("talk_podcast");
        this.talkChannels = data.channels;
        this.dataLoaded = true;
        this.loading = false;
        console.log(this.talkChannels, "all page channels");
      } catch (error) {
        console.error("error", error);
        this.loading = false;
      }
    },
    showForm: function (type) {
      this.listing_type = type;
    },
    async getTalks() {
      try {
        const { data } = await get_podcast_categories();
        this.talks = data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getGenres() {
      try {
        if (
          !this.$store.getters.getGenres &&
          !this.$store.getters.getGenres.length > 0
        ) {
          await this.$store.dispatch("get_geners");
        }
      } catch (error) {
        console.error(error);
      }
    },
    redirect: function (url) {
      this.$router.push({ name: url, params: { title: "Hot new singles" } });
    },
    selectGenre(e, value) {
      e.stopPropagation();
      const index = this.selectedGenres.findIndex((e) => e === value);
      if (index !== -1) {
        this.removeUnSelectedGenresSubGenre(value);
        this.selectedGenres.splice(index, 1);
      } else {
        this.selectedGenres.push(value);
      }
    },
    selectTalks(e, value) {
      e.stopPropagation();
      const index = this.selectedTalks.findIndex((e) => e === value);
      if (index !== -1) {
        this.selectedTalks.splice(index, 1);
      } else {
        this.selectedTalks.push(value);
      }
    },
    selectSubGenres(e, value) {
      e.stopPropagation();
      const index = this.selectedSubGenres.findIndex((e) => e === value);
      if (index !== -1) {
        this.selectedSubGenres.splice(index, 1);
      } else {
        this.selectedSubGenres.push(value);
      }
    },
    clearGenreFilter() {
      this.selectedGenres = [];
      this.selectedSubGenres = [];
    },
    isSelectedSubGenre(id) {
      const index = this.selectedSubGenres.findIndex((e) => e === id);
      return index !== -1;
    },
    isSelectedGenre(id) {
      const index = this.selectedGenres.findIndex((e) => e === id);
      return index !== -1;
    },
    removeUnSelectedGenresSubGenre(id) {
      const genreIdIndex = this.geners.findIndex((e) => e.id === id);
      const SelectedSubGenresOfGenres = this.geners[genreIdIndex].subgenre;
      for (const subGenre of SelectedSubGenresOfGenres) {
        const index = this.selectedSubGenres.findIndex(
          (e) => e.id === subGenre.id
        );
        this.selectedSubGenres.splice(index, 1);
      }
    },
    setValue(val, type) {
      if (type === "price") {
        this.filters["start_price"] = val.start;
        this.filters["end_price"] = val.end;
      } else {
        this.filters[type] = val;
      }
      this.pageChannels();
    },
  },
};
</script>
<style lang="scss" scoped>
.notification-style {
  margin-top: 5rem;
}
.filter-total-count {
  &.active {
    .svg-i {
      svg {
        path {
          stroke: white !important;
        }
      }
    }
  }
  .svg-i {
    svg {
      path {
        stroke: #D1433A !important;
      }
    }
  }
}
.genres-select-list {
  .genres_checkbox_outer {
    &:hover .genres-themes {
      background-color: #D1433A;
    }
    .genres-themes {
      width: 40px;
      height: 26px;
      background: #47505b;
      mask-position: center;
      mask-repeat: no-repeat;
    }
    .activeGenre {
      background-color: #D1433A;
    }
    &:hover .talks-themes {
      background-color: #D1433A;
    }
    .talks-themes {
      width: 40px;
      height: 26px;
      background: #47505b;
      mask-position: center;
      mask-repeat: no-repeat;
    }
    .activeTalk {
      background-color: #D1433A;
    }
  }
}
</style>