<template>
  <div class="my-page-card-section" @click="redirectToPage()">
    <div class="my-pages-section-left">
      <div class="my-pages-section-img-wrapper">
        <div class="my-pages-section-img-wrapper-image-cover">
          <img
              :src="profile"
              alt="">
        </div>
        <div class="my-pages-check-ico">
          <div class="svg-icon">
            <svg width="10" height="8" viewBox="0 0 10 8" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M9 1L3.5 6.5L1 4" stroke="white" stroke-width="2"
                    stroke-linejoin="round"></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="my-pages-section-right">
      <div class="my-pages-section-title">
        <span>
          {{ (item.title) ? item.title : '' }}
        </span>
      </div>
      <div class="my-pages-section-details">
        <div class="my-pages-section-user">
          <svg width="12" height="13" viewBox="0 0 12 13" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 11.0967V10.0967C10 9.56625 9.78929 9.05754 9.41421 8.68247C9.03914 8.30739 8.53043 8.09668 8 8.09668H4C3.46957 8.09668 2.96086 8.30739 2.58579 8.68247C2.21071 9.05754 2 9.56625 2 10.0967V11.0967"
                stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"></path>
            <path
                d="M6 6.09668C7.10457 6.09668 8 5.20125 8 4.09668C8 2.99211 7.10457 2.09668 6 2.09668C4.89543 2.09668 4 2.99211 4 4.09668C4 5.20125 4.89543 6.09668 6 6.09668Z"
                stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
          <p>
            {{ (item.followers_count) ? item.followers_count : 0 }}
            <span>(+12k/week)</span>
          </p>
        </div>
        <div class="my-pages-section-user">
          <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path
                  d="M1.11719 6.06543C1.11719 6.06543 3.11719 2.06543 6.61719 2.06543C10.1172 2.06543 12.1172 6.06543 12.1172 6.06543C12.1172 6.06543 10.1172 10.0654 6.61719 10.0654C3.11719 10.0654 1.11719 6.06543 1.11719 6.06543Z"
                  stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"></path>
              <path
                  d="M6.61719 7.56543C7.44561 7.56543 8.11719 6.89386 8.11719 6.06543C8.11719 5.237 7.44561 4.56543 6.61719 4.56543C5.78876 4.56543 5.11719 5.237 5.11719 6.06543C5.11719 6.89386 5.78876 7.56543 6.61719 7.56543Z"
                  stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"></path>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="12" height="12" fill="white"
                      transform="translate(0.617188 0.0654297)"></rect>
              </clipPath>
            </defs>
          </svg>
          <p>
            525k
            <span>(+20k/week)</span>
          </p>
        </div>
        <div class="my-pages-section-user">
          <svg width="13" height="12" viewBox="0 0 13 12" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path
                  d="M4.39453 11H2.89453C2.62931 11 2.37496 10.8946 2.18742 10.7071C1.99989 10.5196 1.89453 10.2652 1.89453 10V6.5C1.89453 6.23478 1.99989 5.98043 2.18742 5.79289C2.37496 5.60536 2.62931 5.5 2.89453 5.5H4.39453M7.89453 4.5V2.5C7.89453 2.10218 7.7365 1.72064 7.45519 1.43934C7.17389 1.15804 6.79236 1 6.39453 1L4.39453 5.5V11H10.0345C10.2757 11.0027 10.5097 10.9182 10.6935 10.762C10.8772 10.6058 10.9984 10.3885 11.0345 10.15L11.7245 5.65C11.7463 5.50668 11.7366 5.36034 11.6962 5.22113C11.6558 5.08191 11.5856 4.95315 11.4905 4.84376C11.3953 4.73437 11.2776 4.64697 11.1453 4.58761C11.0131 4.52825 10.8695 4.49836 10.7245 4.5H7.89453Z"
                  stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"></path>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="12" height="12" fill="white" transform="translate(0.894531)"></rect>
              </clipPath>
            </defs>
          </svg>
          <p>
            30k
            <span>(+3k/week)</span>
          </p>
        </div>
      </div>
      <div class="my-pages-buttons-section">
        <div class="my-pages-buttons-left-section">
          <button class="my-pages-buttons-left-msg">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M8.75 4.79168C8.75143 5.34162 8.62294 5.88413 8.375 6.37501C8.08101 6.96324 7.62906 7.458 7.06977 7.80388C6.51048 8.14976 5.86593 8.33309 5.20833 8.33334C4.65839 8.33478 4.11588 8.20629 3.625 7.95834L1.25 8.75001L2.04167 6.37501C1.79372 5.88413 1.66523 5.34162 1.66667 4.79168C1.66692 4.13408 1.85025 3.48953 2.19613 2.93024C2.54201 2.37095 3.03677 1.919 3.625 1.62501C4.11588 1.37707 4.65839 1.24858 5.20833 1.25001H5.41667C6.28514 1.29793 7.10543 1.6645 7.72047 2.27954C8.33552 2.89458 8.70209 3.71487 8.75 4.58334V4.79168Z"
                  fill="#D1433A"></path>
            </svg>
            <span>
              10 Messages
            </span>
          </button>
          <button class="my-pages-buttons-left-notifi">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M7.5 3.33301C7.5 2.66997 7.23661 2.03408 6.76777 1.56524C6.29893 1.0964 5.66304 0.833008 5 0.833008C4.33696 0.833008 3.70107 1.0964 3.23223 1.56524C2.76339 2.03408 2.5 2.66997 2.5 3.33301C2.5 6.24967 1.25 7.08301 1.25 7.08301H8.75C8.75 7.08301 7.5 6.24967 7.5 3.33301Z"
                  fill="#D1433A"></path>
              <path
                  d="M5.72292 8.75C5.64966 8.87628 5.54452 8.9811 5.41801 9.05397C5.2915 9.12684 5.14807 9.16519 5.00208 9.16519C4.85609 9.16519 4.71266 9.12684 4.58616 9.05397C4.45965 8.9811 4.3545 8.87628 4.28125 8.75"
                  stroke="#D1433A" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
            <span>
              13 Notifications
            </span>
          </button>
        </div>
        <div class="my-pages-buttons-right-section">
          <button class="create-posts">
            <span>CREATE POST</span>
          </button>
          <button class="create-promote">
            <span>PROMOTE</span>
          </button>
          <button class="my-pages-buttons-more">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M7.9987 8.66634C8.36689 8.66634 8.66536 8.36786 8.66536 7.99967C8.66536 7.63148 8.36689 7.33301 7.9987 7.33301C7.63051 7.33301 7.33203 7.63148 7.33203 7.99967C7.33203 8.36786 7.63051 8.66634 7.9987 8.66634Z"
                  fill="#47505B" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"></path>
              <path
                  d="M7.9987 4.00033C8.36689 4.00033 8.66536 3.70185 8.66536 3.33366C8.66536 2.96547 8.36689 2.66699 7.9987 2.66699C7.63051 2.66699 7.33203 2.96547 7.33203 3.33366C7.33203 3.70185 7.63051 4.00033 7.9987 4.00033Z"
                  fill="#47505B" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"></path>
              <path
                  d="M7.9987 13.3333C8.36689 13.3333 8.66536 13.0349 8.66536 12.6667C8.66536 12.2985 8.36689 12 7.9987 12C7.63051 12 7.33203 12.2985 7.33203 12.6667C7.33203 13.0349 7.63051 13.3333 7.9987 13.3333Z"
                  fill="#47505B" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyPageCard",
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  computed: {
    profile() {
      const {avatar_image} = this.item
      return (avatar_image && avatar_image[0] && avatar_image[0].original_url) ? avatar_image[0].original_url : require(`@/assets/img/photo-1.png`);
    }
  },
  methods:{
    redirectToPage(){
      if (this.item.type === 'artist' || this.item.type === 'company') {
        this.$router.push(`/page-manager/page/artist/${this.item.id}`)
        this.$store.commit("SET_PAGE_ACTIVE_TAB", "music")
      } else if (this.item.type === 'clubroom') {
        this.$store.commit("SET_PAGE_ACTIVE_TAB", "posts")
        this.$router.push(`/page-manager/page/club/${this.item.id}`)
      } else if (this.item.type === 'partyroom') {
        this.$store.commit("SET_PAGE_ACTIVE_TAB", "posts")
        this.$router.push(`/page-manager/page/party/${this.item.id}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/pages/mypagecard.scss";
</style>
