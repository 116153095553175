<template>
  <div class="icon" >
    <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" class="item-option-list" no-caret >
      <template #button-content>
        <slot name="icon">

        </slot>
      </template>
      <b-dropdown-item href="#">
        <div class="drop-down-item d-flex bd-highlight">
          <div class="bd-highlight">Buy</div>
          <div class="price ml-auto bd-highlight">$2.66</div>
        </div>
      </b-dropdown-item>
      <b-dropdown-item href="#">
        <div class="drop-down-item d-flex bd-highlight">
          <div class="bd-highlight">Add to Playlist</div>
          <div class="price ml-auto bd-highlight">5.0</div>
        </div>
      </b-dropdown-item>
      <b-dropdown-item href="#">
        <div class=" drop-down-item d-flex bd-highlight">
          <div class="bd-highlight">Favourite</div>
        </div>
      </b-dropdown-item>
      <b-dropdown-item href="#">
        <div class="drop-down-item d-flex bd-highlight">
          <div class="bd-highlight">Rate</div>
          <div class="price ml-auto bd-highlight">5.0</div>
        </div>
      </b-dropdown-item>
      <b-dropdown-item href="#">
        <div class="drop-down-item d-flex bd-highlight">
          <div class="bd-highlight">Repost</div>
        </div>
      </b-dropdown-item>
      <b-dropdown-item href="#">
        <div class="drop-down-item d-flex bd-highlight">
          <div class="bd-highlight">Share</div>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: "ItemOptionList"
}
</script>

<style scoped lang="scss">
.item-option-list{
  .price{
    font-family: HelveticaNeueMedium, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #071526;
  }
  .drop-down-item{
    padding-top: .2rem;
    padding-bottom: .2rem;
    font-family: HelveticaNeueMedium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #071526;
    transition: background-color .3s;
  }
}
</style>
