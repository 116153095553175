<template>
  <div>
    <div class="check-in-container" v-if="!selectedTab">
      <div class="row">
        <div class="col">
          <div class="upload-photos-title">
            <div class="back-photo-icon" @click="goBack()">
              <svg
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 9L1 5L5 1"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
            <div class="bac-text-tite">Search for location</div>
          </div>
        </div>
      </div>
      <div class="row" v-if="!is_edit_post">
        <div class="col">
          <div class="Sponsors-section upload-photo-sponsers">
            <div class="action-block ">
              <div class="switch-button">
                <label class="switch ">
                  <input type="checkbox" checked="checked" class="default" />
                  <span class="slider round"></span>
                </label>
              </div>
              For All Users
            </div>
            <div
              class="select-audience-button"
              v-if="!selectedTab"
              @click="selectedTab = 'selectAudience'"
            >
              <span v-if="!audience"> Choose Audience</span>
              <span v-if="audience"> {{audience.toUpperCase().replace('_', ' ')}}</span>
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L5 5L9 1"
                  stroke="#47505B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sponser-type w-100" v-if="selectedTab === 'selectAudience'">
      <SelectAudience @goBackToPostMain="goBackToPostMain" :selected-tab="audience"/>
    </div>
  </div>
</template>
<script>
import SelectAudience from "./components/selectAudience";
import "../../../../public/css/checkIn.scss";
import audience from "../../Events/components/Detail/Audience.vue";

export default {
  name: "CheckIn",
  components: {
    SelectAudience,
  },
  data() {
    return {
      user_location: "",
      selectedTab: "",
      audience:null
    };
  },
  props: {
    is_edit_post: {
      type: Boolean,
    },
    post: {
      type: Object,
    },
  },
  watch: {
    selectedTab(value) {
      if (value) this.hideTopBarAndShowPostBtn();
    },
  },
  mounted() {
    if(this.is_edit_post){
        this.locationValue();
    }
  },
  methods: {
    goBack() {
      const createPostOptionsContainer = document.getElementById(
        "create-post-option-container"
      );
      if (createPostOptionsContainer.style.display === "none")
        createPostOptionsContainer.style.display = "block";
      this.$parent.goBack();
    },
    async getLocation(data) {
      this.user_location = data.location;
      this.$store.commit("SET_TYPE_DESCRIPTION", this.user_location);
      this.$store.commit("SET_TYPE", "check_in");
    },
    hideTopBarAndShowPostBtn() {
      const createPostOptionsContainer = document.getElementById(
        "create-post-option-container"
      );
      createPostOptionsContainer.style.display = "none";
    },
    goBackToPostMain(audience) {
      this.selectedTab = null;
      this.audience  = audience;
      const createPostOptionsContainer = document.getElementById(
        "create-post-option-container"
      );
      if (createPostOptionsContainer.style.display === "none")
        createPostOptionsContainer.style.display = "block";
    },
    locationValue(){
        document.getElementById("google-location").value = this.post && this.post.post_type_description;
    }
  },
};
</script>
<style lang="scss" scoped>
.bac-text-tite {
  margin-left: 10px;
  font-size: 13px;
  font-weight: 500;
  color: #47505b;
}

.check-in-container {
  padding: 10px 20px;
}
.select-audience-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: black;
  width: 250px;
  height: 40px;
  background: #f3f3f3;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
  padding: 7px 10px 7px 10px;
}
</style>
