<template>
  <div class="related-widget pb-23">
    <div class="widget-title">
      Listening History
      <div v-if="listeningSongs.length > 0" class="link-more near_arrow without-slider in-title pointerClass">
        <a v-on:click="changeTab"
          >show all
          <span class="sl-arrow">
            <img
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgY2xhc3M9InN2Zy1hcnJvdy1yaWd0aCIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZD0iTTEuMjkzNTggMS4zOTQ3OEw3LjM0NTUgNi4zMTI5MkwxLjI5MzU4IDExLjY2MzYiICBzdHJva2U9IiM0NzUwNUIiICBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIg0KICAgICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg0KDQo="
            />
          </span>
        </a>
      </div>
    </div>
    <WidgetLoading v-if="isDataLoading" :is-loading="isDataLoading"/>
    <div v-else>
      <div v-for="(song, index) in listeningSongs"
           :key="index"
           class="widget-item"
      >
        <div class="cover-image">
          <div class="img-wrapper">
            <img width="60" :src="getImage(song)" alt="" />
          </div>
          <div class="play-button js-play-button new-play-button">
            <div class="svg-icon">
              <svg
                width="13"
                height="16"
                viewBox="0 0 13 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="play-type-1"
              >
                <path
                  class="c-fill-1 c-line-1"
                  d="M2.48967 14.5038C2.35174 14.5004 2.21628 14.4666 2.093 14.4046C1.95246 14.322 1.83398 14.2066 1.74764 14.0683C1.66131 13.93 1.60969 13.7729 1.59717 13.6103L1.59717 2.30439C1.60458 2.14673 1.64886 1.99301 1.72644 1.85556C1.80402 1.71811 1.91275 1.60077 2.04389 1.51295C2.18183 1.43494 2.33926 1.39819 2.49748 1.40706C2.6557 1.41593 2.80804 1.47006 2.93639 1.563L11.6139 7.2155C11.7361 7.29735 11.8362 7.40803 11.9054 7.53774C11.9746 7.66746 12.0108 7.81222 12.0108 7.95925C12.0108 8.10628 11.9746 8.25104 11.9054 8.38076C11.8362 8.51047 11.7361 8.62115 11.6139 8.703L2.93639 14.3574C2.80583 14.4508 2.65018 14.5028 2.48967 14.5066"
                  stroke="white"
                  stroke-width="1.3"
                ></path>
              </svg>
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  class="c-line-1 c-fill-1"
                  d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                  fill="white"
                ></path>
                <path
                  class="c-line-1 c-fill-1"
                  d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                  stroke="white"
                  stroke-width="1.3"
                ></path>
              </svg>
              <svg
                width="10"
                height="14"
                viewBox="0 0 10 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  class="c-line-1 c-fill-1"
                  d="M1 1V13"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                ></path>
                <path
                  class="c-line-1 c-fill-1"
                  d="M9 1V13"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div class="item-desc">
          <div class="item-title" @click="redirectWidgetsToArtistPage($event, song.page.id, 'artist')">{{ getArtist(song.page) }}</div>
          <div class="item-subtitle" @click="redirectToFullViewPage($event, song.type, song.id)">{{ song.title }}</div>
          <div class="action">
            <div class="action-item">
              <div class="svg-icon">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.53962 1.16974C3.32291 1.15751 3.10788 1.20891 2.92037 1.31691L2.90563 1.3254L2.89157 1.33497C2.72642 1.44742 2.59052 1.59705 2.49407 1.77116C2.39765 1.94524 2.34314 2.13929 2.33441 2.33772L2.33398 2.34751V11.6684L2.33518 11.6847C2.35017 11.8893 2.41379 12.0878 2.52122 12.2631C2.62867 12.4385 2.77699 12.5858 2.95424 12.6917L2.96895 12.7005L2.98428 12.7082C3.14467 12.7882 3.32101 12.8315 3.50059 12.8345L3.50968 12.8346L3.51876 12.8344C3.73767 12.8293 3.94918 12.7585 4.12676 12.6324L11.1452 7.98919L11.1505 7.98556C11.3108 7.87617 11.4412 7.72912 11.5309 7.55808C11.6207 7.38709 11.6673 7.19692 11.6673 7.00423C11.6673 6.81153 11.6207 6.62136 11.5309 6.45037C11.4412 6.27933 11.3109 6.13225 11.1505 6.02286L4.12783 1.37778C3.95589 1.25427 3.75206 1.18172 3.53962 1.16974ZM3.52885 2.4094V11.6L10.4721 7.00651L10.4723 7.00585L10.4723 7.00532C10.4724 7.00498 10.4724 7.00461 10.4724 7.00423L10.4724 7.0036L10.4723 7.00293C10.4723 7.00258 10.4722 7.00225 10.4721 7.00195L3.52885 2.4094Z"
                    fill="#8B949F"
                  />
                </svg>
              </div>
              {{ getCount(song, "plays") }}
            </div>
            <div class="action-item">
              <div class="svg-icon">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.53918 1.75C9.1057 1.75 8.67665 1.83696 8.27656 2.00572C7.8765 2.17448 7.51343 2.42166 7.20794 2.73277L6.97915 2.96574L6.75034 2.73274L6.74387 2.72629C6.12252 2.1188 5.29065 1.78236 4.42648 1.7913C3.56231 1.80025 2.7374 2.15382 2.1284 2.77395C1.51961 3.39386 1.17491 4.23067 1.16619 5.10443C1.15747 5.9782 1.48539 6.82188 2.0816 7.45417L2.08793 7.46075L6.65945 12.1158C6.74379 12.2016 6.85896 12.25 6.97915 12.25C7.09935 12.25 7.21451 12.2016 7.29885 12.1158L11.8704 7.46077C12.1759 7.14972 12.4179 6.7808 12.5829 6.37526C12.7479 5.96972 12.8327 5.5353 12.8327 5.09675C12.8327 4.65819 12.7479 4.22377 12.5829 3.81823C12.4179 3.41269 12.1759 3.04377 11.8704 2.73272C11.5649 2.42162 11.2018 2.17447 10.8018 2.00572C10.4017 1.83696 9.97264 1.75 9.53918 1.75ZM9.53918 2.94167C9.26453 2.94167 8.99229 2.99674 8.73788 3.10406C8.48344 3.21138 8.25146 3.36903 8.05551 3.56859L7.50695 4.12717C7.36786 4.2688 7.17775 4.34871 6.97915 4.34871C6.78056 4.34871 6.59045 4.2688 6.45136 4.12717L5.90851 3.57441C5.51092 3.18822 4.98341 2.97727 4.43875 2.98291C3.8918 2.98857 3.36636 3.21225 2.9759 3.60984C2.58504 4.00784 2.3609 4.54847 2.35523 5.11635C2.34958 5.68188 2.56111 6.225 2.94109 6.63062L6.97915 10.7424L11.0229 6.62486C11.2189 6.4253 11.375 6.18768 11.4818 5.92536C11.5885 5.66301 11.6436 5.38141 11.6436 5.09675C11.6436 4.81208 11.5885 4.53048 11.4818 4.26813C11.375 4.0058 11.2189 3.76823 11.0229 3.56868C10.827 3.36911 10.5949 3.21138 10.3405 3.10406C10.0861 2.99674 9.81382 2.94167 9.53918 2.94167Z"
                    fill="#8B949F"
                  />
                </svg>
              </div>
              {{ getCount(song, "loves") }}
            </div>
            <div class="action-item">
              <div class="svg-icon">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.82529 1.75244C2.39701 1.72759 1.98711 1.89399 1.68146 2.19731C1.37368 2.50273 1.19429 2.92248 1.16689 3.36093C1.16631 3.37027 1.16602 3.37963 1.16602 3.38899V12.384C1.16602 12.5556 1.26363 12.7122 1.41759 12.7876C1.57154 12.863 1.75494 12.844 1.89022 12.7387L4.17316 10.9607H11.1734C11.6017 10.9855 12.0116 10.8191 12.3172 10.5158C12.625 10.2104 12.8044 9.79064 12.8318 9.35219C12.8324 9.34273 12.8327 9.33326 12.8327 9.32379L12.8282 3.38865C12.8282 3.3794 12.8279 3.37016 12.8273 3.36093C12.7999 2.92248 12.6205 2.50273 12.3127 2.19731C12.0071 1.89399 11.5972 1.72759 11.1689 1.75244H2.82529ZM11.4756 3.04264C11.393 2.96072 11.3057 2.936 11.2346 2.94096C11.2174 2.94215 11.2003 2.94275 11.1831 2.94275H2.81111C2.79394 2.94275 2.77676 2.94215 2.75963 2.94096C2.68853 2.936 2.60117 2.96072 2.51862 3.04264C2.43588 3.12474 2.36943 3.25607 2.35512 3.41622V10.8685L3.5645 9.92658C3.69446 9.82536 3.85446 9.77037 4.0192 9.77037H11.1876C11.2048 9.77037 11.2219 9.77097 11.2391 9.77217C11.3102 9.77713 11.3975 9.75241 11.4801 9.67048C11.5628 9.58843 11.6292 9.45718 11.6436 9.29714L11.6391 3.41644C11.6248 3.2562 11.5584 3.12478 11.4756 3.04264Z"
                    fill="#8B949F"
                  />
                </svg>
              </div>
              {{ getCount(song, "comment_count") }}
            </div>
            <div class="action-item">
              <div class="svg-icon">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.1372 1.33602C9.37838 1.11195 9.7694 1.11195 10.0106 1.33602L12.0691 3.24855C12.1849 3.35615 12.25 3.50208 12.25 3.65425C12.25 3.80642 12.1849 3.95236 12.0691 4.05996L10.0106 5.97249C9.7694 6.19656 9.37838 6.19656 9.1372 5.97249C8.89603 5.74842 8.89603 5.38514 9.1372 5.16107L10.1411 4.22841H4.42611C4.04394 4.22841 3.67742 4.36946 3.40718 4.62052C3.13695 4.87159 2.98513 5.21211 2.98513 5.56718V6.52344C2.98513 6.84032 2.70864 7.0972 2.36756 7.0972C2.02649 7.0972 1.75 6.84032 1.75 6.52344V5.56718C1.75 4.90777 2.03195 4.27538 2.53381 3.80911C3.03568 3.34284 3.71636 3.08089 4.42611 3.08089H10.1419L9.1372 2.14744C8.89603 1.92337 8.89603 1.56009 9.1372 1.33602Z"
                    fill="#8B949F"
                  />
                  <path
                    d="M4.86279 8.03012C5.10397 8.25418 5.10397 8.61747 4.86279 8.84153L3.85871 9.7744H9.57248C9.95465 9.7744 10.3212 9.63335 10.5914 9.38228C10.8616 9.13121 11.0135 8.79069 11.0135 8.43563V7.47936C11.0135 7.16249 11.29 6.9056 11.631 6.9056C11.9721 6.9056 12.2486 7.16249 12.2486 7.47936V8.43563C12.2486 9.09503 11.9666 9.72743 11.4648 10.1937C10.9629 10.66 10.2822 10.9219 9.57248 10.9219H3.85828L4.86279 11.8552C5.10397 12.0792 5.10397 12.4425 4.86279 12.6666C4.62162 12.8907 4.2306 12.8907 3.98943 12.6666L1.93088 10.7541C1.68971 10.53 1.68971 10.1667 1.93088 9.94264L3.98943 8.03012C4.2306 7.80605 4.62162 7.80605 4.86279 8.03012Z"
                    fill="#8B949F"
                  />
                </svg>
              </div>
              000
            </div>
          </div>
        </div>
      </div>
      <div class="mt-1" v-if="listeningSongs.length === 0" style="margin-bottom: -13px;">No Listening History</div>
    </div>
  </div>
</template>
<script type="application/javascript">
import { getPageData } from "../../../apis/APIs";
import WidgetLoading from "./WidgetLoading.vue";

export default {
  components: { WidgetLoading },
  props: {
    listeningSongs: {
      type: Array,
      required: true,
      default: () => [],
    },
    isDataLoading:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      totalRows: 0,
      loading: false,
      limit: 6,
      offset: 0,
    };
  },

  methods: {
    changeTab: function () {
      this.$parent.change_active_tab("history");
    },
  },
};
</script>
<style lang="scss" scoped>
.related-widget{
  position: relative;
}
</style>