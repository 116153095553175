<template>
  <div class="Signup_pop_overlay user_profile_share_model">
    <div
      class="Signup_pop_overlay user_profile_share_model"
      v-if="selected_tab === '' "
    >
      <div
        class="modal fade show d-block"
        id="login"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-modal="true"
      >
        <div class="modal-dialog">
          <div class="modal-content w-120 h-100">
            <div class="sponser-type w-100" v-if="selectedTab === 'selectAudience'">
              <SelectAudience @goBackToPostMain="goBackToPostMain" :selected-tab="audience"/>
            </div>
            <div v-else>
              <div class="user_and_link">
                <div style="display: flex; align-items: baseline;">
              <span class="user_image_container">
                <img
                  class="user_image"
                  :src="this.user.artwork_url"
                  alt="user_profile"
                />
              </span>
                  <h1>
                    {{this.user.full_name}}
                  </h1>
                  <!--              <p class="">added a post</p>-->
                  <!--              <span class="user_dot">-->
                  <!--                <svg-->
                  <!--                  fill="none"-->
                  <!--                  height="2"-->
                  <!--                  viewBox="0 0 2 2"-->
                  <!--                  width="2"-->
                  <!--                  xmlns="http://www.w3.org/2000/svg"-->
                  <!--                >-->
                  <!--                  <circle cx="1" cy="1" fill="#8B949F" r="1" />-->
                  <!--                </svg>-->
                  <!--              </span>-->
                  <!--              <p class="post-time">20 minutes ago</p>-->
                  <span class="icon_globe">
                <svg
                  fill="none"
                  height="14"
                  viewBox="0 0 14 14"
                  width="14"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
                    stroke="#8B949F"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <path
                    d="M1 7H13"
                    stroke="#8B949F"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <path
                    d="M6.99961 1C8.50038 2.64301 9.35326 4.77522 9.39961 7C9.35326 9.22478 8.50038 11.357 6.99961 13C5.49884 11.357 4.64596 9.22478 4.59961 7C4.64596 4.77522 5.49884 2.64301 6.99961 1V1Z"
                    stroke="#8B949F"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                </svg>
              </span>
                </div>
                <div class="cursor-pointer" @click="closeModal">
              <span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 6L6 18" stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6 6L18 18" stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
                </div>
              </div>
              <div class="user_link">
                <a :href="current_url"
                ><span ref="Input" @click="emoji = false"
                >{{current_url}}</span
                ></a
                >
                <span class="post_emoji" @click="toggleEmoji" v-if="emoji">
              <svg
                width="19"
                height="19"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                  stroke="#D1433A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.07617 13.2422C7.51371 13.8384 8.08286 14.3256 8.73937 14.6659C9.39588 15.0063 10.122 15.1906 10.8614 15.2046C11.6008 15.1906 12.3269 15.0063 12.9834 14.6659C13.64 14.3256 14.2091 13.8384 14.6466 13.2422"
                  stroke="#D1433A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.8711 10.1543C15.5917 10.1543 16.1758 9.57018 16.1758 8.84962C16.1758 8.12906 15.5917 7.54492 14.8711 7.54492C14.1505 7.54492 13.5664 8.12906 13.5664 8.84962C13.5664 9.57018 14.1505 10.1543 14.8711 10.1543Z"
                  fill="#D1433A"
                />
                <path
                  d="M7.13185 10.1543C7.85241 10.1543 8.43654 9.57018 8.43654 8.84962C8.43654 8.12906 7.85241 7.54492 7.13185 7.54492C6.41128 7.54492 5.82715 8.12906 5.82715 8.84962C5.82715 9.57018 6.41128 10.1543 7.13185 10.1543Z"
                  fill="#D1433A"
                />
              </svg>
            </span>

                <span class="post_emoji" @click="toggleEmoji" v-if="!emoji">
              <svg
                width="19"
                height="19"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                  stroke="#8B949F"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.07617 13.2432C7.51371 13.8393 8.08286 14.3265 8.73937 14.6669C9.39588 15.0073 10.122 15.1916 10.8614 15.2056C11.6008 15.1916 12.3269 15.0073 12.9834 14.6669C13.64 14.3265 14.2091 13.8393 14.6466 13.2432"
                  stroke="#8B949F"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.8711 10.1543C15.5917 10.1543 16.1758 9.57018 16.1758 8.84962C16.1758 8.12906 15.5917 7.54492 14.8711 7.54492C14.1505 7.54492 13.5664 8.12906 13.5664 8.84962C13.5664 9.57018 14.1505 10.1543 14.8711 10.1543Z"
                  fill="#8B949F"
                />
                <path
                  d="M7.13282 10.1543C7.85339 10.1543 8.43752 9.57018 8.43752 8.84962C8.43752 8.12906 7.85339 7.54492 7.13282 7.54492C6.41226 7.54492 5.82812 8.12906 5.82812 8.84962C5.82812 9.57018 6.41226 10.1543 7.13282 10.1543Z"
                  fill="#8B949F"
                />
              </svg>
            </span>
              </div>
              <div class="image_container">
                <div>
                  <img :src="current_cover" />
                </div>
                <div class="sub_container">
                  <img class="user_image" :src="current_profile" />
                  <h1 style="margin-left: 16px;">{{current_name}}</h1>
                </div>
              </div>
              <hr class="border-line" />
              <!-- <div class="row">
                <div class="post-col w-260">
                  <div
                    class="post-option-select"
                    :class="selected_tab === 'upload_photos' ? 'selected-div' : ''"
                    @click="updateTab('upload_photos')"
                  >
                    <a href="javascript:;">
                  <span class="post-option-icon">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5Z"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.08398 8.33301C7.77434 8.33301 8.33398 7.77336 8.33398 7.08301C8.33398 6.39265 7.77434 5.83301 7.08398 5.83301C6.39363 5.83301 5.83398 6.39265 5.83398 7.08301C5.83398 7.77336 6.39363 8.33301 7.08398 8.33301Z"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17.4993 12.4997L13.3327 8.33301L4.16602 17.4997"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                      <p>Upload Photos</p>
                    </a>
                  </div>
                </div>
                <div class="post-col w-260">
                  <div
                    class="post-option-select"
                    :class="
                      selected_tab === 'feelings_activity' ? 'selected-div' : '' 
                    "
                    @click="updateTab('feelings_activity')"
                  >
                    <a href="javascript:;">
                  <span class="post-option-icon">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.99935 18.3337C14.6017 18.3337 18.3327 14.6027 18.3327 10.0003C18.3327 5.39795 14.6017 1.66699 9.99935 1.66699C5.39698 1.66699 1.66602 5.39795 1.66602 10.0003C1.66602 14.6027 5.39698 18.3337 9.99935 18.3337Z"
                        stroke="#FC7C5F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.66602 11.667C6.66602 11.667 7.91602 13.3337 9.99935 13.3337C12.0827 13.3337 13.3327 11.667 13.3327 11.667"
                        stroke="#FC7C5F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.5 7.5H7.50833"
                        stroke="#FC7C5F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.5 7.5H12.5083"
                        stroke="#FC7C5F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                      <p>Feelings / Activity</p>
                    </a>
                  </div>
                </div>
              </div> -->
              <!-- <hr class="border-line" /> -->
              <div class="row">
                <div class="col">
                  <div class="Sponsors-section ">
                    <div class="action-block ">
                      <div class="switch-button">
                        <label class="switch ">
                          <input
                            type="checkbox"
                            checked="checked"
                            class="default"
                          />
                          <span class="slider round"></span>
                        </label>
                      </div>
                      For All Users
                    </div>
                    <div class="select-audience-button" v-if="!selectedTab" @click="selectedTab = 'selectAudience'">
                      <span v-if="!audience"> Choose Audience</span>
                      <span v-if="audience"> {{audience}}</span>
                      <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L5 5L9 1" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </div>
                    <!--                <div-->
                    <!--                  class="select-audience-button"-->
                    <!--                  @click="selectedTab = 'selectAudience'"-->
                    <!--                >-->
                    <!--                  <span>Select Audience</span>-->
                    <!--                  <svg-->
                    <!--                    width="10"-->
                    <!--                    height="6"-->
                    <!--                    viewBox="0 0 10 6"-->
                    <!--                    fill="none"-->
                    <!--                    xmlns="http://www.w3.org/2000/svg"-->
                    <!--                  >-->
                    <!--                    <path-->
                    <!--                      d="M1 1L5 5L9 1"-->
                    <!--                      stroke="#47505B"-->
                    <!--                      stroke-linecap="round"-->
                    <!--                      stroke-linejoin="round"-->
                    <!--                    />-->
                    <!--                  </svg>-->
                    <!--                </div>-->
                  </div>
                  <div id="create-colored-post-btn" class="row">
                    <div class="col">
                      <div class="post-btn"><button @click="addPost">POST</button></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="selected_tab === 'upload_photos'">
      <UploadPhoto @closeThisModel="goBack"  :share_model=true />
    </div>
    <div v-else-if="selected_tab === 'feelings_activity' ">
      <FeelingActivity @closeThisModel="goBack" :share_model=true />
    </div>
  </div>
</template>
<script>
import UploadPhoto from "../../../components/Home/NewsFeed/UploadPhoto.vue";
import FeelingActivity from "../../../components/Home/NewsFeed/FeelingActivity.vue";
import SelectAudience from "../../../components/Home/NewsFeed/components/selectAudience.vue";
import { mapActions } from "vuex";
import audience from "../../Events/components/Detail/Audience.vue";
export default {
  name: "SharePageModel",
  data() {
    return {
      emoji: false,
      content: "",
      current_tab: "",
      selected_tab: "",
      current_cover:"",
      current_profile:"",
      current_url:"",
      current_name:"",
      page_type:"",
      post_type:"",
      post_type_id:"",
      selectedTab:"",
      audience:null,
      access: 'public',
    };
  },
  props:{
    openFrom:{
      type: String,
      default: "",
    },
    modalData:{
      type: Object,
      default:()=>{}
    }
  },
  components: {
    UploadPhoto,
    FeelingActivity,
    SelectAudience,
  },
  computed:{
    user() {
      return this.$store.getters.user_map_to_props.user_data;
    }
  },
  watch:{
    selectedTab(value) {
      console.log(value);
      if (value) this.hideTopBarAndShowPostBtn();
    }
  },
  mounted() {
  this.getCurrentData()
    },
  methods: {
    toggleEmoji() {
      this.emoji = !this.emoji;
    },
    onShown() {
      this.$refs.dialog.focus();
    },
    onHidden() {
      this.$refs.Input.focus();
    },
    selectEmoji(emoji) {
      const { data, key } = emoji || {};
      this.content = this.content ? `${this.content} ${data}` : ` ${data}`;
    },
    closeModal(){
      this.$emit('closeSharePageModel');
    },
    updateTab(value) {
      // if (value === "feelings_activity") return (this.current_tab = value && this.s);
      // if( value === "upload_photos") return (this.current_tab = value)
      // if (this.selected_tab) return;
      this.selected_tab = value;
      this.current_tab = value;
    },
    hideTopBarAndShowPostBtn() {
      console.log('Checking Elemnet ==>', document.getElementById("create-post-option-container"))
      // const createPostOptionsContainer = document.getElementById("create-post-option-container");
      // createPostOptionsContainer.style.display = "none";
    },
    goBack() {
      this.selected_tab = '';
      // const createPostOptionsContainer = document.getElementById("create-post-option-container");
      // if (createPostOptionsContainer.style.display === "none") createPostOptionsContainer.style.display = "block";
      //this.$parent.goBack();
    },
    getCurrentData(){
      this.post_type_id=this.modalData.id
        if(this.openFrom==='profile')
        {
          this.post_type="User"
          this.current_cover=this.modalData.cover_photo_url
          this.current_profile=this.modalData.artwork_url
          this.current_name=this.modalData.full_name
          this.current_url= window.location.origin +"/home/page/user-profile/"+this.modalData.username
        }
        else if(this.openFrom==='page')
        {
          this.post_type="Page"
          this.current_cover=this.modalData.cover_photo_url
          this.current_profile=this.modalData.artwork_url
          this.current_name=this.modalData.title
          if(this.modalData.type==='artist')
          {
            this.page_type='artist'
          }
          else if(this.modalData.type==='clubroom')
          {
            this.page_type='club'
          }
          else if(this.modalData.type==='partyroom')
          {
            this.page_type='party'
          }
          else if(this.modalData.type==='company')
          {
            this.page_type='company'
          }

          this.current_url=window.location.origin+"/page-manager/page/"+this.page_type+"/"+this.modalData.id
        }
      },
      addPost()
      {
        this.checkAccess(this.audience)
        this.$store.commit('SET_CATEGORY','share_profile')
        this.$store.commit('SET_ACCESS',this.access)
        this.$store.commit('SET_TYPE_DESCRIPTION',this.current_url)
        this.$store.commit('SET_TYPE',this.post_type)
        this.$store.commit('SET_POST_TYPE_ID',this.post_type_id)
        this.$store.dispatch('post',{})
        this.closeModal()
      },
    goBackToPostMain(audience) {
      this.selectedTab = null;
      this.selected_tab = '',
      this.audience = audience;
      // const createPostOptionsContainer = document.getElementById("create-post-option-container");
      // if (createPostOptionsContainer.style.display === "none") createPostOptionsContainer.style.display = "block";
    },
    checkAccess(access){
      if(access === 'Friends'){
        this.access = 'friends'
      }
      else if(access === 'Close Friends'){
        this.access = 'close_friends'
      }
      else if(access === 'Public'){
        this.access = 'public'
      }
      else{
        this.access = 'sponsers'
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.post-option-select {
  width: 260px;
}
.icon_globe {
  margin-top: -5px;
  margin-left: 0px;
}
.w-260 {
  width: 260px;
}
.user_profile_share_model {
  .modal-content {
    width: 570px;
    height: 700px;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(71, 80, 91, 0.15);
    border-radius: 4px;
    padding: 20px;
    .user_and_link {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      h1 {
        margin-right: 13px;
      }
      .user_image_container {
        width: 51px;
        height: 40px;
        .user_image {
          width: 40px;
          height: 40px;
          border-radius: 20px;
        }
      }
    }
    .user_link {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      a {
        font-family: HelveticaNeueNormal, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      span {
        color: #D1433A;
      }
      .post_emoji {
        max-width: 19px;
        max-height: 19px;
      }
    }
    .image_container {
      margin-top: 27px;
      display: flex;
      align-items: start;
      flex-direction: column;
      background: #f7f7f7;
      border-radius: 2px;
      width: 530px;
      h1 {
        color:  #071526;
        font-family: HelveticaNeueNormal, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      img{
        width: 530px;
        height: 200px;
      }
      .sub_container {
        display: flex;
        align-items: center;
        margin: 20px 0px 20px 20px;
        flex-direction: row;
        .user_image {
          width: 80px;
          height: 80px;
          border-radius: 50px;
        }
      }
    }
    .border-line {
      height: 1px;
      margin-bottom: 21px;
      margin-top: 19px;
    }
    .select-audience-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: black;
      width: 250px;
      height: 40px;
      background: #f3f3f3;
      border: 1px solid #e6eaf0;
      border-radius: 4px;
      padding: 7px 10px 7px 10px;
    }
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 572px;
    margin: 1.75rem auto;
  }
}
</style>
