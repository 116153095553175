<template>
  <div>
    <div class="collection_tab">
      <nav>
        <div class="nav ct" id="nav-tab" role="tablist">
          <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-music-fav"
             role="tab" aria-controls="nav-home" aria-selected="true">MUSIC </a>
          <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-podcast" role="tab"
             aria-controls="nav-contact" aria-selected="false">PODCASTS </a>
          <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-playlist" role="tab"
             aria-controls="nav-profile" aria-selected="false">PLAYLISTS </a>          
          <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-samples" role="tab"
             aria-controls="nav-contact" aria-selected="false">SAMPLES </a>
          <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-license" role="tab"
             aria-controls="nav-contact" aria-selected="false">LICENSES </a>
          <!-- <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-NFTs" role="tab"
             aria-controls="nav-contact" aria-selected="false">NFT's </a> -->
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-music-fav" role="tabpanel"
             aria-labelledby="nav-home-tab">
          <spinner v-if="getSpinnerData && (getSpinnerData.display && getSpinnerData.for === 'wishlist')"/>
          <favorite-songs v-else :all-favorite-songs="allFavoriteSongs" :total-favorite-songs="totalFavoriteSongs"/>
        </div>
        <div class="tab-pane fade" id="nav-playlist" role="tabpanel" aria-labelledby="nav-playlist">
          <PlaylistSongs/>
        </div>
        <div class="tab-pane fade" id="nav-podcast" role="tabpanel" aria-labelledby="nav-contact-tab">
          <favorite-podcasts/>
        </div>
        <div class="tab-pane fade" id="nav-samples" role="tabpanel" aria-labelledby="nav-contact-tab">
          <favorite-samples/>
        </div>
        <div class="tab-pane fade" id="nav-license" role="tabpanel" aria-labelledby="nav-contact-tab">
          <License/>
        </div>
        <!-- TODO: NOTE: NFTs component will be implemented in future -->
<!--        <div class="tab-pane fade" id="nav-NFTs" role="tabpanel" aria-labelledby="nav-contact-tab">-->
<!--          <NFTs/>-->
<!--        </div>-->
      </div>
    </div>

  </div>
</template>


<script>

import PlaylistSongs from "@/components/Home/Profile/favorites/Playlist/Index.vue";
import License from "@/components/Home/Profile/favorites/license/Index.vue";
import favoriteSongs from "../favorites/music/index.vue";
import favoritePodcasts from "@/components/Home/Profile/favorites/podcasts/index.vue";
import favoriteSamples from "../favorites/samples/index.vue";
import Spinner from "../../../Common/Spinners/spinner.vue";
// import NFTs from "@/components/Home/Profile/favorites/nft's/index.vue";

export default {
  name: "Favorites",
  props: {
    allFavoriteSongs: {
      type: Array,
    },
    totalFavoriteSongs: {
      type: String,
      default: '',
    },
  },
  computed: {
    getSpinnerData() {
      return this.$store.getters.getSpinner;
    },
  },
  components: {
    Spinner,
    // NFTs,
    PlaylistSongs,
    License,
    favoriteSongs,
    favoritePodcasts,
    favoriteSamples
  }
}
</script>

<style scoped>
.ct .active:hover, 
.ct .active:focus{
  color: #47505b;
}

.ct>a:hover,
.ct>a:focus{
  color: #c4ccd6;
}
</style>
