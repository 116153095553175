<template>
  <div class="bs-container events-overview-content d-flex justify-content-center">
    <div class="row">
      <div class="col">
        <div class="d-grid gap-4">
<!--          <div class="d-flex justify-content-center">-->
<!--            <span class="events-overview-page-title">{{ title_event_type.toUpperCase() }}</span>-->
<!--          </div>-->
          <b-skeleton-wrapper :loading="!displayPage">
            <template #loading>
              <SkeletonLoader v-for="item in 5" :key="item" />
            </template>
            <div class="d-grid gap-4">
              <Search
                @changeFilterKey="changeFilterKey"
                @explorEvents="explorEvents"
              />
              <VenueContainer @explorEvents="explorEvents" />
              <Genres @explorEvents="explorEvents" />
              <Featured
                v-if="
                GET_ALL_EVENTS.featured &&
                GET_ALL_EVENTS.featured.data.length > 0
              "
                :events="GET_ALL_EVENTS.featured"
                :key="keyFeatured"
                :eventsType="`${
                title_event_type === 'Explore Events'
                  ? 'Featured'
                  : 'Featured' + ' ' + title_event_type
              }`"
                eventsPath="featured-events"
              />
              <div class="events-overview-events-and-filter-section">
                <div class="events-overview-events-section d-grid gap-4">
                  <EventsContainer
                    v-if="
                  GET_ALL_EVENTS.popular &&
                  GET_ALL_EVENTS.popular.data.length > 0
                "
                    :key="keyPopular"
                    :events="GET_ALL_EVENTS.popular"
                    class="mt-0"
                    :eventsType="`${
                  title_event_type === 'Explore Events'
                    ? 'Popular Events'
                    : 'Popular' + ' ' + title_event_type
                }`"
                    eventsPath="popular-events"
                  />
                  <EventsContainer
                    v-if="
                  GET_ALL_EVENTS.thisweek &&
                  GET_ALL_EVENTS.thisweek.data.length > 0
                "
                    :key="keyThisweek"
                    :events="GET_ALL_EVENTS.thisweek"
                    :eventsType="`${
                  title_event_type === 'Explore Events'
                    ? 'This Week'
                    : 'This Week' + ' ' + title_event_type
                }`"
                    eventsPath="this-week"
                  />
                  <EventsContainer
                    v-if="
                  GET_ALL_EVENTS.online &&
                  GET_ALL_EVENTS.online.data.length > 0 &&
                  GET_EVENTS_SEARCH_FOR.filterStatus !== 'offline'
                "
                    :key="keyOnline"
                    :events="GET_ALL_EVENTS.online"
                    :eventsType="`${
                  title_event_type === 'Explore Events'
                    ? 'Online Events'
                    : 'Online' + ' ' + title_event_type
                }`"
                    eventsPath="online-events"
                  />
                  <EventsContainer
                    v-if="
                  GET_ALL_EVENTS.festivals &&
                  GET_ALL_EVENTS.festivals.data.length > 0
                "
                    :key="keyFestivals"
                    :events="GET_ALL_EVENTS.festivals"
                    :eventsType="`${
                  title_event_type === 'Explore Events'
                    ? 'Festival Events'
                    : 'Festival' + ' ' + title_event_type
                }`"
                    eventsPath="festival-events"
                  />
                  <EventsContainer
                    v-if="
                  GET_ALL_EVENTS.latest && GET_ALL_EVENTS.latest.data.length > 0
                "
                    :key="keyLatest"
                    :events="GET_ALL_EVENTS.latest"
                    :eventsType="`${
                  title_event_type === 'Explore Events'
                    ? 'Latest Events'
                    : 'Latest' + ' ' + title_event_type
                }`"
                    eventsPath="Latest-events"
                  />
                </div>
                <div class="events-overview-filter-section d-grid gap-2">
                  <AddEventButtonComponent
                    :create-events-modal="createEventsModal"
                    @openCreateEventModal="createEventsModal = !createEventsModal"
                  />
                  <FilterEvents
                    :key="filtrKey"
                    @changeFilterKey="changeFilterKey"
                    @explorEvents="explorEvents"
                  />
                  <news-feed />
                </div>
              </div>
            </div>
          </b-skeleton-wrapper>
        </div>
        <div v-if="createEventsModal" class="create-events-wrapper">
          <event-post-container @closeEventModal="createEventsModal = false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Search from "../components/Search.vue";
import Genres from "../components/Genres.vue";
import VenueContainer from "../components/Venue/VenueContainer.vue";
import Featured from "../components/Featured/Featured.vue";
import EventsContainer from "../components/EventsContainer.vue";
import FilterEvents from "../components/Filter.vue";
import NewsFeed from "../components/NewsFeed.vue";
import { mapActions, mapGetters } from "vuex";
import EventPostContainer from "../components/CreateEvents/EventPostContainer.vue";
import SkeletonLoader from "../../Common/Loaders/SkeletonLoader.vue";
import AddEventButtonComponent from "../components/AddEventButtonComponent.vue";

export default {
  components: {
    AddEventButtonComponent,
    SkeletonLoader,
    Search,
    Genres,
    VenueContainer,
    Featured,
    EventsContainer,
    FilterEvents,
    NewsFeed,
    EventPostContainer,
  },
  data() {
    return {
      key_this_component: 1,
      title_event_type: "Explore Events",
      filtrKey: 0,
      createEventsModal: false,
      keyLatest: Math.random(),
      keyFeatured: Math.random(),
      keyPopular: Math.random(),
      keyThisweek: Math.random(),
      keyOnline: Math.random(),
      keyFestivals: Math.random(),
      displayPage: false,
    };
  },
  methods: {
    ...mapActions(["getAllEvents"]),
    async explorEvents(params) {
      try {
        await this.$store.dispatch("loading", true);
        await this.getAllEvents(params);
        this.change_title_event_type(this.$route.meta.event_Type_id);
        this.changeKeys();
        console.log("GET_ALL_EVENTS", this.GET_ALL_EVENTS);
        await this.$store.dispatch("loading", false);
      } catch (error) {
        console.error(error);
      }
    },
    changeKeys() {
      this.keyLatest = Math.random();
      this.keyFeatured = Math.random();
      this.keyPopular = Math.random();
      this.keyThisweek = Math.random();
      this.keyOnline = Math.random();
      this.keyFestivals = Math.random();
    },
    change_title_event_type(event_type_id) {
      if (event_type_id === 1) {
        this.title_event_type = "Gigs";
      } else if (event_type_id === 2) {
        this.title_event_type = "Clubs";
      } else if (event_type_id === 3) {
        this.title_event_type = "Festivals";
      } else if (event_type_id === 4) {
        this.title_event_type = "Livestreaming";
      } else {
        this.title_event_type = "Explore Events";
      }
    },
    changeFilterKey() {
      this.filtrKey += 1;
    },
  },

  async created() {
    await this.explorEvents({
      event_type: this.$route.meta.event_Type_id,
    });
  },
  watch: {
    "$route.path"() {
      ++this.key_this_component;
      this.explorEvents({
        event_type: this.$route.meta.event_Type_id,
      });
    },

    getAllExistEvents: {
      handler: function() {
        setTimeout(() => {
          this.displayPage = true;
        }, 3000);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      "GET_ALL_EVENTS",
      "GET_EVENTS_SEARCH_FOR",
      "GET_EVENT_TYPES",
    ]),

    getAllExistEvents() {
      return this.GET_ALL_EVENTS;
    },
  },
};
</script>

<style lang="scss">
@import "/src/assets/style/events/overview.scss";

.create-events-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}

.overview-mains {
  h1 {
    text-transform: uppercase;
  }
}
.overview-content {
  .row {
    width: 1170px;
    max-width: 100%;
  }
}
.dontScroll {
  height: calc(100vh - 174px);
  overflow: hidden;
}

.overview-Venue {
  margin-top: 22px;
  margin-right: -30px;
}
.overview-featured {
  margin-top: 42px;
  margin-bottom: 30px;
}
.overview-events {
  margin-bottom: 150px;
  display: flex;
  justify-content: space-between;
  &-left {
    width: 770px;
    max-width: 100%;
  }
}
</style>
