<script>
export default {
  name: "WidgetLoading",
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<template>
  <div v-if="isLoading" class="loading-overlay">
    <div class="classic-circle-loader"></div>
  </div>
</template>

<style scoped lang="scss">
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7); /* Light overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-radius: inherit;
}

/* Loader Styles */
.classic-circle-loader {
  width: 40px;
  height: 40px;
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top-color: #d1433a;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>