<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <Header v-if="!isNav" />
        <router-view />
        <MusicFooter v-if="!isNav" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Common/Header";
import MusicFooter from "@/components/Common/MusicFooter";
export default {
  components: {
    Header,
    MusicFooter,
  },
  data() {
    return {
      arr: ["events.findEvents", "events.new-events"],
    };
  },
  computed: {
    isNav() {
      return this.arr.includes(this.$route.name);
    },
  },
};
</script>

<style lang="scss" scoped></style>
