<template>
    <div class="center_centerbar_wraper_home">
      <div class="row">
        <div class="col-sm-12">
          <h4 class="title-h4 selling_title" style="margin-top: 0px; margin-bottom: 20px;">
            {{getSimilarArtists && getSimilarArtists[0].type === "clubroom" ? "Similar Groups" : "Similar Artists"}}
            {{getSimilarArtists && getSimilarArtists[0].type === "clubroom" ? getSimilarArtists.length : "" }}
          </h4>
        </div>
      </div>
      <div class="row position-relative artistHover">
        <div class="col-sm-3 js-container-hover" v-for="(similar_artists, index) in getSimilarArtists" :key="index">
          <div  class="card slider round artist" @click="goArtistPage(similar_artists.id, similar_artists.type)">
            <div  class="card-cover js-card-hover">
              <div class="cover square round ">
                <img :src="(similar_artists.artwork_url) ? similar_artists.artwork_url : ''" alt="artist_image">
              </div>
            </div>
            <div class="card-description position-relative pb-10 round">
              <div class="title text-center text-truncate"> {{(similar_artists.title) ? similar_artists.title : 'Name'}} </div>
              <div class="name text-center text-truncate">
                {{ (similar_artists.country) ? similar_artists.country.name : '' }}
              </div>
              <div class="followers following-btn" v-if="(similar_artists && (similar_artists.type === 'artist' || similar_artists.type === 'company')) && similar_artists.follow_action === 'approved'" @click="followOrUnfollowPage(similar_artists.id, followStatus(similar_artists.follow_action)?0:1, $event)">
                <div class="text" style="margin-left: 2px !important; ">
                  <div class="svg-icon">
                    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.3346 1L5.0013 8.33333L1.66797 5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </div>
                  Following
                </div>
              </div>
              <div class="followers" style="justify-content: unset;" v-if="(similar_artists && (similar_artists.type === 'artist' || similar_artists.type === 'company')) && similar_artists.follow_action !== 'approved'" @click="followOrUnfollowPage(similar_artists.id, followStatus(similar_artists.follow_action)?0:1, $event)">
                <div class="text" style="margin-left: 8px;">
                  <div  class="svg-icon">
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 8.00938C3.757 8.00938 6 10.2547 6 13.0145C6 13.5671 6.448 14.0156 7 14.0156C7.552 14.0156 8 13.5671 8 13.0145C8 9.15056 4.86 6.00732 1 6.00732C0.448 6.00732 0 6.45579 0 7.00835C0 7.56092 0.448 8.00938 1 8.00938Z" fill="#D1433A" class="c-line-1 c-fill-1">
                      </path>
                      <path d="M1 2.00206C7.0655 2.00206 12 6.94164 12 13.0134C12 13.566 12.448 14.0144 13 14.0144C13.552 14.0144 14 13.566 14 13.0134C14 5.83801 8.1685 0 1 0C0.448 0 0 0.448461 0 1.00103C0 1.5536 0.448 2.00206 1 2.00206Z" fill="#D1433A" class="c-line-1 c-fill-1">
                      </path>
                      <path d="M1 14.0148C1.55228 14.0148 2 13.5666 2 13.0137C2 12.4609 1.55228 12.0127 1 12.0127C0.447715 12.0127 0 12.4609 0 13.0137C0 13.5666 0.447715 14.0148 1 14.0148Z" fill="#D1433A" class="c-line-1 c-fill-1">
                      </path>
                    </svg>
                  </div>
                  Follow <span style="margin-left: 32px;">{{similar_artists.followers_count}}</span>
                </div>
              </div>

              <div :class="{'page-or-artist-card-join-and-joined-show-more': true, 'active': similar_artists.join_status === 'approved' }" style="margin: auto"
                   v-if="(similar_artists && (similar_artists.type === 'clubroom' || similar_artists.type === 'partyroom')) && (similar_artists.current_user !== 'admin')"
                   @click="joinPage(similar_artists.join_status, similar_artists)"
              >
                <svg
                     width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_6583_3974)">
                    <path d="M10.6667 14V12.6667C10.6667 11.9594 10.3858 11.2811 9.8857 10.781C9.3856 10.281 8.70733 10 8.00008 10H3.33341C2.62617 10 1.94789 10.281 1.4478 10.781C0.9477 11.2811 0.666748 11.9594 0.666748 12.6667V14" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5.66667 7.33333C7.13943 7.33333 8.33333 6.13943 8.33333 4.66667C8.33333 3.19391 7.13943 2 5.66667 2C4.19391 2 3 3.19391 3 4.66667C3 6.13943 4.19391 7.33333 5.66667 7.33333Z" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.3333 5.33331V9.33331" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.3333 7.33331H11.3333" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_6583_3974">
                      <rect width="16" height="16" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <span class="page-or-artist-card-joined">
              {{(similar_artists && similar_artists.join_status === 'approved') ? 'Joined' : 'Join'}}
          </span>
              </div>

            </div>
          </div>
        </div>
        <div class="show_more_btn" v-if="getSimilarArtistsTotal > getSimilarArtists.length">
          <div class="show-more musicbar_more">
            <a href="javascript:;" @click="showMore">
              <span class="show_more_bg">
              <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.24219 0.689941V7.01994" stroke="#071526" stroke-width="1.3" stroke-linecap="round"
                      stroke-linejoin="round">
                </path>
                <path d="M1 3.85498H7.48702" stroke="#071526" stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round">
                </path>
              </svg>
              SHOW MORE ARTISTS
            </span>
            </a>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import { changeFollowStatus, followOrUnFollowPage, requestToJoinClubPage } from "../../../../apis/APIs";
import {mapGetters} from "vuex";
import createFormData from "../../../../mixins/common";

export default {
  date() {
    return {}
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    }),
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
    getSimilarArtists() {
      return this.$store.getters.getPageSimilarArtistsList
    },
    getSimilarArtistsTotal() {
      return this.$store.getters.getPageSimilarArtistTotal
    }
  },
  mounted(){

  },
  methods:{
    showMore(){
      let payload = {
        showMore : true,
        page_id: this.$route.params.id,
      }
      this.$store.dispatch('getPageSimilarArtists', payload);
    },

    goArtistPage(id, type) {
      let pageType;
      if (type === 'clubroom') {
        pageType = 'club'
      } else if (type === 'partyroom') {
        pageType = 'party'
      } else if (type === 'artist') {
        pageType = 'artist'
      } else if (type === 'company') {
        pageType = 'company'
      }
      this.$router.push(`/page-manager/page/${pageType}/${id}`)
    },

    async setArtistFollowStatus(similar_artists,id, action, follow_status){
      try {
        await this.$store.dispatch('loading', true)
        let obj = {
          id : id,
          object_type : 'user',
          action : (action)?1:0
        }
        const {data} = await changeFollowStatus(obj)
        await this.$store.dispatch('loading', false)
        if(data.success){
          await this.notificationToast(true, "Artist", `Successfully ` + follow_status, 5000, 'success')
          similar_artists.follow_status = action;
        }
        else{
          await this.notificationToast(true, "Page", data.message, 5000, 'error')
        }
      } catch (e) {
        await this.$store.dispatch('loading', false)
        await this.notificationToast(true, "Page", e.message, 5000, 'error')

      }
    },
    async followOrUnfollowPage(page_id,status,event) {
      if(event) event.stopPropagation()
      try {
        await this.$store.dispatch('loading', true)
        const formdata = new FormData();
        formdata.append('user_id', this.user.user_data.id);
        formdata.append('page_id', page_id);
        formdata.append('type', 'Page');
        formdata.append('action', status);
        const {data} = await followOrUnFollowPage(formdata)
        await this.$store.dispatch('loading', false)
        if(data.success){
          const payload = {
            showMore : false,
            page_id : this.$route.params.id,
          }
          await this.$store.dispatch('getPageSimilarArtists', payload)
          await this.notificationToast(true, "Page", `successfully ${(status === 1) ? 'Follow' : 'UnFollow'}`, 5000, 'success')

        }
        else{
          await this.notificationToast(true, "Page", data.message, 5000, 'error')
        }
      } catch (e) {
        await this.$store.dispatch('loading', false)
        await this.notificationToast(true, "Page", e.message, 5000, 'error')
      }
    },
    followStatus(follow_action){
      if(follow_action){
        return follow_action
      }
      return !!(follow_action)
    },
    async joinPage(status, item) {
        try {
          await this.$store.dispatch('loading', true)
          const payload = {
            type: 'Page',
            type_id: item.id,
            action: (status === 'approved') ? 0 : 1
          }
          const formData = createFormData(payload)
          const {data} = await requestToJoinClubPage(formData)
          const {message, success} = data || {}
          if (success === 1 ) {
            this.notificationToast(true, 'Club Page', message, 5000, 'success');
          } else {
            this.notificationToast(true,"Club Page", message, 5000, 'error');
          }
          await this.$store.dispatch('loading', false)
        } catch (e) {
          await this.$store.dispatch('loading', false)
          this.notificationToast(true,"Club Page", message, 5000, 'error');
          console.error(e)
        }
    },
  }
}
</script>
<style lang="scss" scoped>
.selling_title {
  color: #071526;
  font-family: HelveticaNeueBold, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.card.slider.artist .card-description .name {
  color: #47505B;
  text-align: center;
  font-family: HelveticaNeueNormal, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 20px;
}
.card.slider .card-description .followers .text {
  color: #D1433A;
  font-family: HelveticaNeueBold, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
