<template>
  <div class="profile-completetion-process" v-if="progressPercentage !== 100">
    <div class="Profile-completion-detail">
      <div class="completion-detail">
        <h1>Work Profile Completion</h1>
        <p>
          {{ progressStepsCompleted }} of {{ progressSteps }} |
          <span>Completed...</span>
        </p>
      </div>
      <div class="completeion-parsentage">
        <p>{{ progressPercentage.toFixed(1) }}%</p>
        <vue-ellipse-progress :progress="progressPercentage" :determinate="false" color="#D1433A"
          empty-color="transparent" :size="24" :thickness="3" :emptyThickness="emptyThickness" :lineMode="lineMode"
          animation="rs 700 1000" fontSize="0" :noData="false" :loading="false" :dot="4" line="square">
        </vue-ellipse-progress>
      </div>
    </div>
    <div class="profile-com-per">
      <div class="progress-bar-detail"></div>
      <div class="progress-bar-active" :style="{ width: progressPercentage + '%' }"></div>
    </div>
    <div class="profile-steps">
      <ul>
        <li v-for="(item, index) in progressFields" :class="{ 'step-done': item.value }" :key="index">
          <span v-html="getIcon(item.value)" />
          <div v-if="item.func_status">
            <a v-if="!item.value" class="link-text" @click="item.dynamic_func['showProfileCoverModelShow']">{{
              defaultTextAdd }} {{ item.title }}</a>
            <p v-else>
              {{ defaultTextAdd }} {{ item.title
              }}<a class="link-text" @click="item.dynamic_func['showProfileCoverModelShow']">
                {{ defaultTextUpdate }}</a>
            </p>
          </div>
          <div v-else>
            <p v-if="item.value">
              {{ defaultTextAdd }} {{ item.title }}
              <router-link :to="item.link">{{ defaultTextUpdate }}</router-link>
            </p>
            <router-link v-else :to="item.link">{{ defaultTextAdd }} {{ item.title }}</router-link>
          </div>
        </li>
      </ul>
    </div>

    <!-- modal -->
    <upload-profile-and-cover
     :pic-type="profileAndCoverModelType"
      :show="profileAndCoverModel"
       :imageCheck="true"
       @profileAndCoverValueChange="changeProfileAndCoverValue"
        @getImage="setImage" />
        <!-- :coverImage="Image" -->

  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UploadProfileAndCover from "../Home/Profile/componenets/uploadProfileAndCover";
import UploadProfileAndCover from "../../../Home/Profile/componenets/uploadProfileAndCover.vue";
import {
  addPost,
  followOrUnFollowPage, getChannelData,
  requestToJoinClubPage,
  uploadArtistCoverAndProfilePicture
} from "../../../../apis/APIs";

export default {
  name: "ArtistProfileCompletionStatus",
  // components: {UploadProfileAndCover},
  data() {
    return {
      // Image: {
      //   image: {
      //     type: String,
      //     default: null
      //   },
      //   defaultImage: {
      //     type: Boolean,
      //     default: false
      //   }
      // },
      defaultTextAdd: "Add your ",
      defaultTextUpdate: "Update ",
      completedIcon:
        '              <svg width="20" height="20" viewBox="0 0 20 20" fill="none"\n' +
        '                                         xmlns="http://www.w3.org/2000/svg">\n' +
        "                                       <path\n" +
        '                                           d="M18 0H2C0.89543 0 0 0.89543 0 2V18C0 19.1046 0.89543 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.89543 19.1046 0 18 0Z"\n' +
        '                                           fill="#D1433A"></path>\n' +
        "                                       <path\n" +
        '                                           d="M6.89944 10.7965C6.2147 10.1026 7.18802 9.51866 7.87276 10.2125L9.30754 11.6473L12.1771 9.13608L14.3293 6.98426C15.0825 6.29039 15.8696 6.84141 15.1849 7.60468L9.98079 12.8087C9.70689 13.0863 9.22757 13.1557 8.95367 12.8087L6.89944 10.7965Z"\n' +
        '                                           fill="white" stroke="white"></path>\n' +
        "                                    </svg>",
      pendingIcon:
        '          <svg width="20" height="20" viewBox="0 0 20 20" fill="none"\n' +
        '                                         xmlns="http://www.w3.org/2000/svg">\n' +
        "                                       <path\n" +
        '                                           d="M18 0H2C0.89543 0 0 0.89543 0 2V18C0 19.1046 0.89543 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.89543 19.1046 0 18 0Z"\n' +
        '                                           fill="#E6EAF0"></path>\n' +
        '                                       <rect x="9" y="5" width="2" height="10" rx="1" fill="#071526"></rect>\n' +
        '                                       <rect x="5" y="11" width="2" height="10" rx="1" transform="rotate(-90 5 11)"\n' +
        '                                             fill="#071526"></rect>\n' +
        "                                    </svg>",
      profileAndCoverModel: false,
      profileAndCoverModelType: "",
      progress: 45,
      lineModes: [
        "normal",
        "in",
        "in-over",
        "out",
        "out-over",
        "top",
        "bottom",
      ],
      thickness: 5,
      emptyThickness: 5,
      dot: { size: 5, width: "5px" },
      lineMode: "normal 0",
    };
  },
  props: {
    profileAndCoverModelShow: {
      type: Boolean,
      default: false,
    },
    modelType: {
      type: String,
      default: "profile",
    },
    pageData: {
      type: () => { },
      default: {},
    },
  },
  components: {
    UploadProfileAndCover
  },
  computed: {
    ...mapGetters({
      user: "user_map_to_props",
    }),
    progressFields() {
      const self = this;
      return [
        {
          title: `Profile picture`,
          value: !!(this.pageData && this.pageData.artwork_url),
          link: "/home/settings?tab=work",
          func_status: true,
          dynamic_func: {
            showProfileCoverModelShow() {
              self.profileAndCoverModel=true
              self.profileAndCoverModelType = "page_profile";
              // self.$emit("profileAndCoverType", "profile");
              // self.changeProfileAndCoverValue(true);
            },
          },
        },
        {
          title: `Background info`,
          value: !!(this.pageData && this.pageData.content),
          link: `/page-manager/page/page-information/${this.pageData.id}`,          // func_status: true,
          // dynamic_func: {
          //   showProfileCoverModelShow() {
          //     self.profileAndCoverModel=true
          //     self.profileAndCoverModelType = "cover";
          //     // self.$emit("profileAndCoverType", "profile");
          //     // self.profileAndCoverModel = true;
          //   },
          // },
        },
        {
          title: `Education`,
          value: !!(
            this.pageData && this.pageData.education.length > 0
          ),
          link: `/page-manager/page/work-and-education/${this.pageData.id}`,
        },
        {
          title: `Experience`,
          value: !!(
            this.pageData && this.pageData.experience.length > 0
          ),
          link: `/page-manager/page/work-and-education/${this.pageData.id}`,
        },
        {
          title: `Availability`,
          value: !!(
            this.pageData && this.pageData.availability_status
          ),
          link: `/page-manager/page/availability/${this.pageData.id}`,
        },
        {
          title: `Area Available to Work`,
          value: !!(
            this.pageData && this.pageData.work_location
          ),
          link: `/page-manager/page/location/${this.pageData.id}`,
        },
        {
          title: `Tools`,
          value: !!(
            this.pageData && this.pageData.tools.length > 0
          ),
          link: `/page-manager/page/about-and-skills/${this.pageData.id}`,
        },

      ];
    },
    progressSteps() {
      return this.progressFields.length;
    },
    progressPercentage() {
      let unitCost =
        this.progressFields.length > 0 ? 100 / this.progressFields.length : 100;
      let total = 0;

      for (const item of this.progressFields) {
        if (item.value) {
          total = Number(total) + Number(unitCost);
        }
      }
      return total;
    },
    progressStepsCompleted() {
      let unitCost =
        this.progressFields.length > 0 ? 100 / this.progressFields.length : 100;
      let total = 0;
      // return this.progressFields.length
      for (const item of this.progressFields) {
        if (item.value) {
          total++;
        }
      }
      return total;
    },
  },

  methods: {
    async setImage(crop_image, image, file_nam, is_Selected) {
      console.log("this.pageData ",JSON.parse(JSON.stringify(this.pageData)));
      // console.log("this.project", JSON.parse(JSON.stringify(this.project)))

      if (this.profileAndCoverModelType === 'profile') {

      } else {
        try {
          await this.$store.dispatch('loading', true)
          const formdata = new FormData();

          console.log("this.profileAndCoverModelType ",this.profileAndCoverModelType);
          
          formdata.append((this.profileAndCoverModelType === 'page_profile') ? 'artwork' : 'cover_photo', crop_image )
          const page_id = this.$route.params.id
          const {data} = await uploadArtistCoverAndProfilePicture(formdata, page_id)
          const {information, success} = data || {}
          await this.$store.dispatch('loading', false)
          if (success === 1) {
            this.$store.commit('SET_PAGE_DATA', information)
            this.notificationToast(true, `${(this.profileAndCoverModelType === 'profile' || this.profileAndCoverModelType === 'page_profile') ? "Image" : "Cover"} updated`, `${(this.profileAndCoverModelType === 'profile' || this.profileAndCoverModelType === 'page_profile') ? "Image" : "Cover"} uploaded successfully`, 5000, 'success');
            if (!is_Selected) {
              await this.uploadPost(crop_image)
            }
          }
        } catch (e) {
          await this.$store.dispatch('loading', false)
          this.notificationToast( true,`${(this.profileAndCoverModelType === 'profile' || this.profileAndCoverModelType === 'page_profile') ? "Image" : "Cover"}`, e.message, 5000, 'error')
        }
      }
    },
    // passImage (type) {
    //   this.Image.image = (type === 'page_profile') ? this.current_page.artwork_url : this.current_page.cover_photo_url;
    //   this.Image.defaultImage = (this.current_page.page_cover === 1) ? true : false;
    // },
    async uploadPost(image) {
      try {
        const form = new FormData();
        form.append('artwork[' + 0 + ']', image);
        form.append('full_content', '');
        form.append('category', 'image');
        form.append('access', 'public');
        form.append('global_type', 'page');
        const page_id = this.$route.params.id;
        form.append('page_id', page_id);
      
        (this.profileAndCoverModelType === 'profile' || this.profileAndCoverModelType === 'page_profile') ? form.append('post_type', 'profile_photo') : form.append('post_type', 'cover_photo')
        const {data} = await addPost(form);
        this.loading = false;
        const {success, message, information} = data || {};
        if (success === 1) {
          this.$store.commit('ADD_POST_OF_PAGE_IN_POSTS', information)
        } else {
          console.error(message, 'Error in creating post')
        }
      } catch (error) {
        console.error(error, 'Error in creating post')
      }
    },
    getIcon(val) {
      return val ? this.completedIcon : this.pendingIcon;
    },
    changeProfileAndCoverValue(value, image) {
      this.profileAndCoverModel = value;
    },
  },
  watch: {
    profileAndCoverModelShow(val) {
      console.log("changeProfileAndCoverValue222222", val);
      this.profileAndCoverModel = val;
      this.profileAndCoverModelType = this.modelType;
    },
  },
  created() {
    console.log("pageData props:", this.pageData);
  },
};
</script>

<style scoped lang="scss">
// .Profile-completion-detail {
//   min-height: 55px;
// }</style>