<template>
  <div class="treanding_sections">

    <!-- trending head -->
    <div class="treanding_flag">
      <div class="trending-heading">
        <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.80173 16.1C4.80173 16.1 -1.09827 14.3 1.80173 7.2C1.80173 7.1 2.90173 11.5 6.80173 10.6C5.60173 9.7 2.20173 7.2 8.50173 1C8.50173 9.2 13.4017 6.1 13.5017 11C13.5025 12.1377 13.1171 13.2419 12.4086 14.132C11.7002 15.0222 10.7106 15.6455 9.60173 15.9"
            stroke="#8B949F" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"></path>
        </svg>
        <span class="trending-title">Trending</span>
      </div>
      <div class="setting_ic" id="show-btn" v-b-modal.modal-multi-1 @click="openModel">
        <TrendingCircleIcon />
      </div>
    </div>
    <WidgetLoading v-if="tagsLoading" :is-loading="tagsLoading" />

    <!-- list section -->
    <ul v-else class="trending-list">
      <li v-for="(eachOp) in listData.slice(0, 5)" :key="eachOp.id">
        <div class="tr_block">
          <div class="tr_left">
            <p v-if="eachOp.country">Trending in {{ eachOp.country }}</p>
            <h1 @click="changeType(eachOp.tag)">#{{ eachOp.tag.replace(/#/g, '').toUpperCase() }}</h1>
            <span>{{
              eachOp.total > 1000
                ? eachOp.total + "K Posts"
                : eachOp.total > 1
                  ? eachOp.total + " Posts"
                  : eachOp.total + " Post"
            }}</span>
          </div>
          <div class="drop_tr_down ml-auto">
            <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="dropdown">
              <a href="#" class="link js-settings-alert icon m-t-3 ml-auto">
                <div class="svg-icon">
                  <svg width="3" height="15" viewBox="0 0 3 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.49036 2.99219C1.78508 2.99219 2.07317 2.90445 2.31822 2.74007C2.56327 2.57568 2.75426 2.34204 2.86704 2.06868C2.97982 1.79533 3.00933 1.49453 2.95184 1.20434C2.89434 0.914141 2.75242 0.64758 2.54403 0.438361C2.33563 0.229142 2.07012 0.0866625 1.78107 0.0289391C1.49201 -0.0287844 1.1924 0.000841461 0.920122 0.11407C0.64784 0.227298 0.415118 0.419044 0.251382 0.665059C0.0876475 0.911074 0.000253955 1.20031 0.000253948 1.49619C0.00025394 1.89295 0.157247 2.27347 0.436696 2.55402C0.716146 2.83457 1.09516 2.99219 1.49036 2.99219V2.99219Z"
                      fill="#8B949F"></path>
                    <path
                      d="M1.49036 8.97461C1.78508 8.97461 2.07317 8.88687 2.31822 8.72249C2.56327 8.55811 2.75426 8.32446 2.86704 8.05111C2.97982 7.77775 3.00933 7.47695 2.95184 7.18676C2.89434 6.89656 2.75242 6.63 2.54403 6.42078C2.33563 6.21156 2.07012 6.06908 1.78107 6.01136C1.49201 5.95364 1.1924 5.98326 0.920122 6.09649C0.64784 6.20972 0.415118 6.40147 0.251382 6.64748C0.0876475 6.8935 0.000253955 7.18273 0.000253948 7.47861C0.00025394 7.87538 0.157247 8.25589 0.436696 8.53644C0.716146 8.817 1.09516 8.97461 1.49036 8.97461V8.97461Z"
                      fill="#8B949F"></path>
                    <path
                      d="M1.49036 14.959C1.78508 14.959 2.07317 14.8712 2.31822 14.7069C2.56327 14.5425 2.75426 14.3088 2.86704 14.0355C2.97982 13.7621 3.00933 13.4613 2.95184 13.1711C2.89434 12.8809 2.75242 12.6144 2.54403 12.4052C2.33563 12.1959 2.07012 12.0535 1.78107 11.9957C1.49201 11.938 1.1924 11.9676 0.920122 12.0809C0.64784 12.1941 0.415118 12.3858 0.251382 12.6319C0.0876475 12.8779 0.000253955 13.1671 0.000253948 13.463C0.00025394 13.8598 0.157247 14.2403 0.436696 14.5208C0.716146 14.8014 1.09516 14.959 1.49036 14.959V14.959Z"
                      fill="#8B949F"></path>
                  </svg>
                </div>
              </a>
              <div aria-labelledby="createDropdownMenuButton" class="dropdown-menu for-notify trending-drop">
                <a href="#" class="dropdown-item noti-d-tr" @click="selectedValue('This trend is spam', eachOp.id)">
                  <p>This trend is spam</p>
                </a>
                <a href="#" class="dropdown-item noti-d-tr" @click="
                  selectedValue('This trend is abusive or harmful', eachOp.id)
                  ">
                  <p>This trend is abusive or harmful</p>
                </a>
                <a href="#" class="dropdown-item noti-d-tr"
                  @click="selectedValue('This trend is a duplicate', eachOp.id)">
                  <p>This trend is a duplicate</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="tr_see_more" v-if="listData.length >= 6">
      <!-- @click="showMore" -->
      <button class="tr_seemore width330" @click="changeTrendingView('TrendingArticles')">
        SEE MORE
      </button>
    </div>
    <!-- NEW MODAL TRENDING -->
    <div>
      <b-modal @hide="preventModalClose" id="modal-multi-1" modal-class="trending-modals" hide-footer ok-only
        title="Trend Settings" v-if="selectedTab === ''">
        <template v-slot:modal-header-close>
          <div class="svg-icon" @click="closeModel">
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.5 4.83398L4.5 13.834" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M4.5 4.83398L13.5 13.834" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>
        </template>
        <div class="d-block fixTrendSettingContent">
          <div class="follow-title trendingBoldTitle">Location</div>
          <div class="location-check-wrap">
            <div class="location-subtitle">
              Show content based on your current location
            </div>
            <input type="checkbox" id="checkbox" class="checkbox" name="location" v-model="visible"
              unchecked-value="not-visible" @change="getTrendsOnUserLocation" />
          </div>
          <b-collapse id="location-collapse" v-model="exploreLocation">
            <b-card v-b-modal.modal-multi-2>
              <div class="following-wrapper">
                <div class="follow-title">Explore locations</div>
                <div class="follow-subtitle" v-if="location.city !== ''">{{ location.city }}, {{ location.country }}
                </div>
              </div>
              <div class="next-btn inline-flex" @click="updateSelectedTab('location')">
                <div class="svg-icon">
                  <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="c-line-1" d="M1 1L5.3 5.231L1 9.833" stroke="#D1433A" stroke-width="1.3"
                      stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </b-card>
          </b-collapse>
          <div class="following-wrapper">
            <div class="follow-title">Following</div>
            <div class="follow-subtitle">Content based on who you follow</div>
            <div class="follow-checkbox">
              <b-form-group>
                <div class="custom-control">
                  <label> Artists </label>
                  <input type="checkbox" value="page" class="checkbox" v-model="follower_tag"
                    @change="applyTrendSettings($event)" />
                </div>
                <div class="custom-control">
                  <label>Events </label>
                  <input type="checkbox" value="event" class="checkbox" v-model="follower_tag"
                    @change="applyTrendSettings($event)" />
                </div>
                <div class="custom-control">
                  <label> Jobs </label>
                  <input type="checkbox" value="job" class="checkbox" v-model="follower_tag"
                    @change="applyTrendSettings($event)" />
                </div>
              </b-form-group>
            </div>
          </div>
        </div>
      </b-modal>

      <!-- 2nd location modal -->
      <b-modal id="modal-multi-2" modal-class="trending-modals step2" v-b-modal.trending-modal hide-backdrop hide-footer
        @hide="preventModalClose" hide-header-close ok-only v-if="selectedTab === 'location'">
        <template #modal-title>
          <div class="back-btn" no-stacking v-b-modal.modal-multi-1 @click="goBack">
            <div class="svg-icon">
              <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 9L1 5L5 1" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>
          </div>
          Locations

          <!-- <div class="svg-icon" @click="goBack">
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.5 4.83398L4.5 13.834" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M4.5 4.83398L13.5 13.834" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div> -->
        </template>



        <div class="d-block">
          <!-- LocationSearch field -->

          <div class="search-location">
            <div class="svg-icon">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.1 6.54294C12.1 3.48162 9.61518 0.999937 6.55 0.999937C3.48482 0.999937 1 3.48162 1 6.54294C1 9.60425 3.48482 12.0859 6.55 12.0859C9.61518 12.0859 12.1 9.60425 12.1 6.54294Z"
                  stroke="#8B949F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.5 12.501L14.5 14.501" stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>

            <div class="geo-location">
              <GoogleMapAndLocation @getLocation="getLocation" />
            </div>
          </div>

          <!-- <div class="country-wrapper"> -->

          <!-- <ul>
              <li v-for="(country, index) in countries" v-bind:key="index">
                <b-form-radio name="country" value="countries">{{
                  country
                }}</b-form-radio>
              </li>
            </ul> -->
          <!-- </div> -->
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import GoogleMapAndLocation from "../Google Location/GoogleMapAndLocation.vue";
import "../../../../public/css/checkIn.scss";
import { getTrendingArticlesList, getTrendingList } from "../../../apis/APIs";
import WidgetLoading from "./WidgetLoading.vue";
import TrendingCircleIcon from "../../../assets/svgs/TrendingCircleIcon.vue";

export default {
  name: "Trending",
  data() {
    return {
      listData: this.hashListData || [],
      options: [
        { text: "Artists", value: "page" },
        { text: "Events", value: "event" },
        { text: "Jobs", value: "job" },
      ],
      showUserCountry: 'invisible',
      exploreLocation: false,
      filterType: "",
      title: "",
      follower_tag: [],
      currentLocation: {
        city: "",
        country: "",
      },
      location: {
        city: "",
        country: "",
      },
      trendModal: false,
      selectedTab: "",
      visible: false,
      allowClose: false
    };
  },
  mounted() {
    this.currentLocation.city = this.userCity;
    this.currentLocation.country = this.userCountry;
    // if(!this.hashListData.length){
    //   if (!this.fromMyArticle) {
    //   this.getTrendingList()
    //     }
    //   else {
    //     this.getTrendingArticlesList()
    //   }
    // }
  },
  props: {
    hashListData: {
      type: Array,
      default: () => [],
    },
    fromMyArticle: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    WidgetLoading,
    GoogleMapAndLocation,
    TrendingCircleIcon
  },
  computed: {
    userCountry() {
      return this.$store.getters.get_user_country ? this.$store.getters.get_user_country[0].name : '';
    },
    userCity() {
      return this.$store.getters.user_map_to_props.user_data.current_city;
    }, tagsLoading() {
      return this.$store.getters.getTagsLoading;
    },
  },
  watch: {
    hashListData(data) {
      if (data.length) {
        this.listData = data
      }
    }
  },
  methods: {
    preventModalClose(event) {
      if (!this.allowClose) {
        event.preventDefault(); 
      } else {
        this.allowClose = false; 
      }
    },
    updateSelectedTab(value) {
      this.selectedTab = value;
    },
    goBack() {
      this.selectedTab = "";
      this.trendModal = false;
    },
    openModel() {
      this.trendModal = true;
    },
    closeModel() {
      this.allowClose = true; 
      this.trendModal = false; 

      this.$store.commit("SET_POST_FOLLOWER_TAG", "");
      this.$store.commit("SET_POST_COUNTRY", "");
      this.$store.commit("SET_POST_CITY", "");
    },
    showMore() {
      let offset = this.$store.getters.getPostOffset;
      let limit = this.$store.getters.getPostsLimit;
      offset = offset + limit;
      this.$store.commit("SET_POST_OFFSET", offset);
      this.$store.dispatch("getPost", true);
      this.$emit("showMore");
    },
    changeType(type) {
      if (this.fromMyArticle) {
        this.getUserPostsDetail(type, true);
        return
      }
      this.filterType = type;
      this.$store.commit("SET_POST_TYPE", "");
      this.$store.commit(
        "SET_TAG_TYPE",
        this.filterType.split("#").join("%23")
      );
      this.$store.commit("SET_POST_OFFSET", 0);
      this.$store.dispatch("getPost", false);
    },
    async getUserPostsDetail(type, loader) {
      try {
        this.$store.commit("GET_POSTS", [])
        this.$store.commit("SET_OTHER_USER_ID", (!this.otherUser) ? null : this.userData.id);
        this.$store.commit("SET_POST_OFFSET", 0);
        this.filterType = type;
        this.$store.commit(
          "SET_TAG_TYPE",
          this.filterType.split("#").join("%23")
        );
        this.$store.commit("SET_POST_TYPE", 'articles');
        await this.$store.dispatch("getUserPosts", { loader: loader });
      } catch (error) {
        this.loading = false
      }
    },
    selectedValue(value, tag_id) {
      if (value === "This trend is spam") {
        this.title = "spam";
      } else if (value === "This trend is abusive or harmful") {
        this.title = "harmful";
      } else if (value === "This trend is a duplicate") {
        this.title = "duplicate";
      }
      this.filterType = tag_id;
      this.report(this.title, tag_id);
      this.removeTag(tag_id);
    },
    removeTag(tag_id) {
      if (this.listData.length > 0) {
        const replacedIndex = this.listData.findIndex((e) => e.id === tag_id);
        if (replacedIndex !== -1) {
          this.listData.splice(replacedIndex, 1);
        }
      }
    },
    async report(title, filter) {
      const payload = {
        report_type: "PostTag",
        post_id: filter,
        title: title,
      };
      const res = await this.$store.dispatch("reportPost", payload);
      const { message, success } = res.data;
      if (success === 1) {
        await this.notificationToast(true, `Report`, message, 5000, 'success')
      }
    },
    changeTrendingView() {
      this.$store.commit("SET_TRENDING_VIEW_CHECK", 1);
    },
    applyTrendSettings() {
      this.getTrendsOnUserLocation();
    },
    async getTrendsOnUserLocation(event) {
      if (event.target.checked) {
        this.location.country = ''
        this.showUserCountry = event.target.checked
      }
      this.exploreLocation = event.target.checked;
      let offset = 0
      let limit = 10
      let follow_artist = (this.follower_tag[0] ? 1 : 0)
      let follow_event = (this.follower_tag[1] ? 1 : 0)
      let country = ''
      if (this.showUserCountry) {
        country = this.currentLocation.country.toLowerCase()
      }
      if (this.location.country !== '') {
        country = this.location.country.toLowerCase()
      }
      try {
        console.log('getTrendsOnUserLocation');
        let Data;
        if (!this.fromMyArticle) {
          Data = await getTrendingList(offset, limit, country, follow_artist, follow_event);
        } else {
          Data = await getTrendingArticlesList(offset, limit, country, follow_artist, follow_event);
        }
        this.listData = [];
        this.listData = Data.data.information.data;
      } catch (error) {
        this.loading = false
      }

      // let input = event.target;
      // this.$store.commit(
      //   "SET_POST_COUNTRY",
      //   this.currentLocation.country.toLowerCase()
      // );
      // this.$store.commit(
      //   "SET_POST_CITY",
      //   this.currentLocation.city.toLowerCase()
      // );
      // this.$store.dispatch("getPost", false);
    },
    async getLocation(value) {
      this.location.city = value.city;
      this.location.country = value.country;
      let offset = 0
      let limit = 10
      let follow_artist = (this.follower_tag[0] ? 1 : 0)
      let follow_event = (this.follower_tag[1] ? 1 : 0);
      let country = value.country.toLowerCase()
      let Data;
      if (!this.fromMyArticle) {
        Data = await getTrendingList(offset, limit, country, follow_artist, follow_event);
      } else {
        Data = await getTrendingArticlesList(offset, limit, country, follow_artist, follow_event);
      }
      this.closeModel();
      console.log('getLocation');
      this.listData = []
      this.listData = Data.data.information.data;
      this.selectedTab = "";
    },
    async getTrendingList(offset = 0, limit = 10) {
      try {
        let { data } = await getTrendingList(offset, limit);
        this.listData = data.information.data
      } catch (error) {
        // this.loading = false;
      }
    },
    async getTrendingArticlesList(offset = 0, limit = 10) {
      try {
        let { data } = await getTrendingArticlesList(offset, limit);
        this.listData = data.information.data
      } catch (error) {
        // this.loading = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.location-check-wrap .checkbox {
  right: 19px;
}

label {
  font-family: "HelveticaNeueMedium";
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #071526;
}

.follow-subtitle {
  font-family: "HelveticaNeueNormal";
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #47505b;
  margin-bottom: 9px;
}

.card-body {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
  padding-bottom: 18px;
  border-bottom: 1px solid #e6eaf0;
}

.card {
  border: none;
  background-color: transparent;
  border-radius: 0;
}

.exp-title {
  font-family: "HelveticaNeueMedium";
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #071526;
  margin-bottom: 6px;
}

.exp-subtitle {
  font-family: "HelveticaNeueNormal";
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #47505b;
}

.next-btn {
  float: inline-end;
  right: 0px;
  position: absolute;
  border: 1px solid #D1433A;
  border-radius: 2px;
  width: 20px;
  height: 20px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  cursor: pointer;
}

.next-btn:hover {
  background-color: #D1433A;
  transition: all 0.3s;
}

.inline-flex {
  display: inline-flex;
}

.location-check-wrap {
  padding: 0;
  display: flex;
  border-bottom: 1px solid #e6eaf0;
}

.location-subtitle {
  font-family: "HelveticaNeueNormal";
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.5px;
  color: #47505b;
  padding-bottom: 18px;
  margin-top: 6px;
  border: none;
}

.checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(209, 67, 58, 0.5);
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, background-image 0.3s ease;
  border-radius: 4px;
  right: -1px;
  position: absolute;
}

.checkbox:checked {
  background-color: #D1433A;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='2' fill='%23D1433A'/%3E%3Cpath d='M4.32538 11.7566C3.37089 10.7894 4.72764 9.97541 5.68213 10.9426L7.68213 12.9426L11.6821 9.44212L14.6821 6.44262C15.7321 5.4754 16.8292 6.2435 15.8747 7.30744L8.62059 14.5616C8.2388 14.9485 7.57065 15.0452 7.18886 14.5616L4.32538 11.7566Z' fill='white'/%3E%3C/svg%3E%0A");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
}

.checkbox:not(:checked) {
  background-color: #FFF8F8;
  background-image: none;
  border-radius: 4px;
}

.treanding_sections {
  position: relative;
  min-height: 530px;

  .treanding_flag {
    height: 59px;

    .trending-heading {
      display: flex;
      align-items: center;

      .trending-title {
        margin-left: 0.6rem;
        width: 81px;
        height: 22px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.05em;
        color: #071526;
        opacity: 0.9;
        font-family: HelveticaNeueBold, sans-serif;
      }
    }
  }

  .trending-list {
    li {
      height: 80px;
      display: flex;
      align-items: center;
      width: 100%;

      .tr_block {
        width: 100%;
      }
    }
  }
}
</style>
