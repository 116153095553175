import {
    _TYPE,
    ALBUM_DOWNLOAD_PRICE,
    ALL_RIGHTS_RESERVED,
    CLEAR_FOR_NOT_SALE_COUNTRIES,
    CLEAR_FOR_NOT_SALE_TERRITORY,
    CLEAR_FOR_SALE_COUNTRIES,
    CLEAR_FOR_SALE_TERRITORY,
    COMPLETE_STEP_UPLOAD,
    COMPOSER,
    COPYRIGHT,
    CREATE_ISRC,
    CREATION_COMMON,
    DESCRIPTION,
    DISPLAY_COMMENT,
    DISPLAY_EMBED_CODE,
    DISPLAY_STATS,
    DRAFT,
    EMPTY_TEMP_SONG_FORM,
    EMPTY_UPLOAD_SONG_PAGE_FORM,
    ENABLE_APP_PLAYBACK,
    ENABLE_COMMENT,
    ENABLE_DIRECT_DOWNLOAD,
    ENABLE_OFFLINE_LISTING,
    ERROR,
    EXPLICIT_CONTENT,
    FEATURE_ARTIST,
    FILE,
    GENRE,
    INCLUDE_RES,
    ISRC,
    LET_FUN_PAY_ALBUM,
    LET_FUN_PAY_TRACK,
    LICENSE_ID,
    METADATA_LANGUAGE,
    MOOD,
    PAGE_ID,
    PICTURES, PODCAST_CATEGORY_ID, PODCAST_DOWNLOAD_PRICE, PODCAST_THEME, PODCAST_TYPE,
    PREORDER_INFO,
    PRICING_TYPE,
    PRIMARY_ARTIST,
    PRIVACY,
    PRODUCER,
    PUBLISHER,
    RE_MIXER,
    RECORD_LABEL,
    RELEASED_AT,
    SALE_DATE,
    SONG_DOWNLOAD_PRICE,
    SONG_ID_TEMP,
    SUB_GENRE,
    SUCCESS,
    TAGS,
    TEMP_SONG_FORM,
    TITLE,
    UPLOAD_ALBUM_PAGE_FORM,
    UPLOAD_SONG_PAGE_FORM,
    URL,
    DISCOVER_PAGES,
    PAGES,
    TOTAL_PAGES,
    PAGE_OFFSET,
    PAGE_LIMIT,
    LOADING,
    POST_OFFSET,
    POST_LIMIT,
    SELECTED_FILTERS,
    SELECTED_CATEGORY,
    SELECTED_SUBCATEGORY,
    SELECTED_LOCATION,
    SELECTED_GENRE,
    SELECTED_SORT,
    COLLABORATORS, 
    LIKED_PAGES, USER_PAGES, 
    PICTURES_ID, 
    TEMP_SAMPLE_FORM, 
    EMPTY_TEMP_SAMPLE_FORM, 
    SAMPLE_ID_TEMP, 
    UPLOAD_SAMPLE_PAGE_FORM, 
    EMPTY_UPLOAD_SAMPLE_PAGE_FORM,
    FILE_TYPE,
    SAMPLE_TYPE,
    BEAT_TYPE,
    SAMPLE_DETAILS
  
  } from "../../types";
  import { podcastUploadDetail, songUploadDetail, uploadPodcastTemp, uploadSongTemp , getPagesForCollaborators, sampleUploadDetail, samplePackUploadDetail} from "../../../apis/APIs";
  import * as Utils from "../../../components/utils/Utils";
  import createFormData, {
    createFormDataForMusic,
    createObjectForMusicFormData,
    createRawData
  } from "../../../mixins/common.js";
  
  const INITIAL_STATE = {
    [TEMP_SAMPLE_FORM]: {
      [PRIVACY]: null,
      [_TYPE]: null,
      [FILE]: null,
      [SONG_ID_TEMP]: null
    },
    [EMPTY_TEMP_SAMPLE_FORM]: {
      [PRIVACY]: null,
      [_TYPE]: null,
      [FILE]: null,
      [SONG_ID_TEMP]: null
    },
    [UPLOAD_SAMPLE_PAGE_FORM]: {
      [SONG_ID_TEMP]: null,
      [PICTURES]: null,
      [PICTURES_ID]: null,
      [PAGE_ID]: null,
      [TITLE]: null,
      [URL]: null,
      [DESCRIPTION]: null,
      [GENRE]: [],
      [SUB_GENRE]: [],
      [MOOD]: [],
      [TAGS]: [],
      [METADATA_LANGUAGE]: null,
      [PRIMARY_ARTIST]: null,
      [FEATURE_ARTIST]: null,
      [COMPOSER]: null,
      [PRODUCER]: null,
      [RE_MIXER]: null,
      [RECORD_LABEL]: null,
      [PUBLISHER]: null,
      [RELEASED_AT]: null,
      [ISRC]: null,
      [COPYRIGHT]: null,
      [EXPLICIT_CONTENT]: null,
      [CREATE_ISRC]: false,
      [ALL_RIGHTS_RESERVED]: false,
      [CREATION_COMMON]: false,
      [PRICING_TYPE]: null,
      [SALE_DATE]: null,
      [PREORDER_INFO]: null,
      [SONG_DOWNLOAD_PRICE]: null,
      [PODCAST_DOWNLOAD_PRICE]:null,
      [LET_FUN_PAY_TRACK]: null,
      [ALBUM_DOWNLOAD_PRICE]: null,
      [LET_FUN_PAY_ALBUM]: null,
      [CLEAR_FOR_SALE_COUNTRIES]: [],
      [CLEAR_FOR_SALE_TERRITORY]: [],
      [CLEAR_FOR_NOT_SALE_COUNTRIES]: [],
      [CLEAR_FOR_NOT_SALE_TERRITORY]: [],
      [ENABLE_DIRECT_DOWNLOAD]: false,
      [ENABLE_OFFLINE_LISTING]: false,
      [INCLUDE_RES]: false,
      [DISPLAY_EMBED_CODE]: false,
      [ENABLE_APP_PLAYBACK]: false,
      [ENABLE_COMMENT]: false,
      [DISPLAY_COMMENT]: false,
      [DISPLAY_STATS]: false,
      [DRAFT]: false,
      [PODCAST_TYPE]:'Music',
      [PODCAST_CATEGORY_ID]:null,
      [COLLABORATORS]:[],
      [FILE_TYPE]: "sample",
      [SAMPLE_TYPE]: "one_shot_sample",
      [BEAT_TYPE]: "beat",
      [SAMPLE_DETAILS]: [],
    },
    [EMPTY_UPLOAD_SAMPLE_PAGE_FORM]: {
      [SONG_ID_TEMP]: null,
      [PAGE_ID]: null,
      [TITLE]: null,
      [URL]: null,
      [DESCRIPTION]: null,
      [GENRE]: [],
      [SUB_GENRE]: [],
      [MOOD]: [],
      [TAGS]: [],
      [METADATA_LANGUAGE]: null,
      [PRIMARY_ARTIST]: null,
      [FEATURE_ARTIST]: null,
      [COMPOSER]: null,
      [PRODUCER]: null,
      [RE_MIXER]: null,
      [RECORD_LABEL]: null,
      [PUBLISHER]: null,
      [RELEASED_AT]: null,
      [ISRC]: null,
      [COPYRIGHT]: null,
      [EXPLICIT_CONTENT]: null,
      [CREATE_ISRC]: false,
      [ALL_RIGHTS_RESERVED]: false,
      [CREATION_COMMON]: false,
      [PRICING_TYPE]: null,
      [SALE_DATE]: null,
      [PREORDER_INFO]: null,
      [SONG_DOWNLOAD_PRICE]: null,
      [LET_FUN_PAY_TRACK]: null,
      [ALBUM_DOWNLOAD_PRICE]: null,
      [PODCAST_DOWNLOAD_PRICE]:null,
      [LET_FUN_PAY_ALBUM]: null,
      [CLEAR_FOR_SALE_COUNTRIES]: [],
      [CLEAR_FOR_SALE_TERRITORY]: [],
      [CLEAR_FOR_NOT_SALE_COUNTRIES]: [],
      [CLEAR_FOR_NOT_SALE_TERRITORY]: [],
      [ENABLE_DIRECT_DOWNLOAD]: false,
      [ENABLE_OFFLINE_LISTING]: false,
      [INCLUDE_RES]: false,
      [DISPLAY_EMBED_CODE]: false,
      [ENABLE_APP_PLAYBACK]: false,
      [ENABLE_COMMENT]: false,
      [DISPLAY_COMMENT]: false,
      [DISPLAY_STATS]: false,
      [DRAFT]: false,
      [PODCAST_TYPE]: null,
      [PODCAST_CATEGORY_ID]:null,
      [COLLABORATORS]:[],
      [FILE_TYPE]: "sample",
      [SAMPLE_TYPE]: "one_shot_sample",
      [BEAT_TYPE]: "beat",
      [SAMPLE_DETAILS]: [],
    },
    [COMPLETE_STEP_UPLOAD]: 0,
    [DISCOVER_PAGES]: {
      [PAGES]: [],
      [TOTAL_PAGES]: 0,
      [PAGE_OFFSET]: 0,
      [PAGE_LIMIT]: 8,
      [LOADING]: false,
    },
    [LIKED_PAGES]: {
      [PAGES]: [],
      [TOTAL_PAGES]: 0,
      [PAGE_OFFSET]: 0,
      [PAGE_LIMIT]: 8,
      [LOADING]: false,
    },
    [USER_PAGES]: {
      [PAGES]: [],
      [TOTAL_PAGES]: 0,
      [PAGE_OFFSET]: 0,
      [PAGE_LIMIT]: 8,
      [LOADING]: false,
    },
  [SELECTED_FILTERS]: {
    [SELECTED_CATEGORY]: '',
    [SELECTED_SUBCATEGORY]: '',
    [SELECTED_LOCATION]: {},
    [SELECTED_GENRE]: '',
    [SELECTED_SORT]: 'latest'
  },
  };
  
  export const state = { ...INITIAL_STATE };
  export const actions = {
    async uploadSampleTemporarily({ state, commit, dispatch }) {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch("loading", true);
          const postFormData = state[TEMP_SAMPLE_FORM];
          const formData = createFormData(postFormData);
          if(postFormData.type === 'sample'){
            var { data } = await uploadSongTemp(formData);
          }else if(postFormData.type === 'podcast'){
            var { data } = await uploadPodcastTemp(formData);
          }
          const { information, success } = data;
          if (success === 1) {
            commit("SET_TEMP_SAMPLE_ID", information.id);
          }
          dispatch("loading", false);
          resolve({
            status: SUCCESS,
            message: "",
            data
          });
        } catch (error) {
          dispatch("loading", false);
          reject({
            status: ERROR,
            message: error.message ? error.message : "Please try again.",
            error
          });
        }
      });
    },
    async getAllPagesForCollaborators({state, commit, dispatch}, showMore = false) {
      return new Promise(async (resolve, reject) => {
          try {
              commit("CHANGE_LOADING_OF_SPECIFIC_CATEGORY", {
                  type: DISCOVER_PAGES,
                  loadingState: true
              });
              if (type) {
                  commit("NEXT_PAGINATE_PAGES_OFFSET_SET", DISCOVER_PAGES);
              }
              const {data} = await getPagesForCollaborators(
                  state[DISCOVER_PAGES][POST_OFFSET],
                  state[DISCOVER_PAGES][POST_LIMIT],
              );
              const {information} = data;
              if (showMore) {
                  commit("JOIN_DISCOVER_PAGES_WITH_OTHER_PAGES", information.data);
              } else {
                  commit("SET_DISCOVER_PAGES", information.data);
              }
              commit("SET_DISCOVER_PAGES_TOTAL", information.total);
              commit("CHANGE_LOADING_OF_SPECIFIC_CATEGORY", {
                  type: DISCOVER_PAGES,
                  loadingState: false
              });
              resolve({
                  status: SUCCESS,
                  message: "",
                  data
              });
          } catch (error) {
              commit("CHANGE_LOADING_OF_SPECIFIC_CATEGORY", {
                  type: DISCOVER_PAGES,
                  loadingState: false
              });
              reject({
                  status: ERROR,
                  message: error.message ? error.message : "Please try again."
              });
          }
      });
  },
    async uploadSampleDetail({ state, commit, dispatch }, type) {
        console.log("typeeeeee", type)
      return new Promise(async (resolve, reject) => {
        try {
          dispatch("loading", true);
        //   const postFormData = state[UPLOAD_SAMPLE_PAGE_FORM];
        const postFormData = { ...state[UPLOAD_SAMPLE_PAGE_FORM] };
        if (type === 'sample') {
            delete postFormData.sample_details;
        } else if (type === 'sample_pack') {
            delete postFormData.sample_type;
            delete postFormData.beat_type;
            delete postFormData.file_type;
            delete postFormData.artwork;
            delete postFormData.artwork_id;
            postFormData.sample_details = postFormData.sample_details.filter(detail => detail.title && detail.title.trim() !== '');
        }
          const formData =  createRawData(postFormData);
          if(type === 'sample'){
            var { data } = await sampleUploadDetail(formData);
          } else if(type === 'sample_pack'){
            var { data } = await samplePackUploadDetail(formData);
          } else if(type === 'podcast'){
            var { data } = await podcastUploadDetail(formData);
          }
          if (data.success === 1) {
            commit("CLEAR_UPLOAD_SONG_PAGE_FORM");
            commit("CLEAR_TEMP_SONG_FORM");
          }
          dispatch("loading", false);
          resolve({
            status: SUCCESS,
            message: "",
            data
          });
        } catch (error) {
          dispatch("loading", false);
          reject({
            status: ERROR,
            message: error.message ? error.message : "Please try again.",
            error
          });
        }
      });
    }
  };
  
  export const mutations = {
    CLEAR_TEMP_SONG_FORM(state) {
      try {
        state[TEMP_SONG_FORM] = Object.assign({}, state[EMPTY_TEMP_SONG_FORM]);
      } catch (e) {
        console.error(e.message, "unable to CLEAR_TEMP_SONG_FORM");
      }
    },
    CLEAR_UPLOAD_SONG_PAGE_FORM(state) {
      try {
        state[UPLOAD_SONG_PAGE_FORM] = Object.assign(
          {},
          state[EMPTY_UPLOAD_SONG_PAGE_FORM]
        );
      } catch (e) {
        console.error(e.message, "unable to CLEAR_UPLOAD_SONG_PAGE_FORM data");
      }
    },
    SET_UPLOAD_SAMPLE_DATA(state, payload) {
        console.log("SET_UPLOAD_SAMPLE_DATA_PAYLOAD", JSON.parse(JSON.stringify(payload)))
        try {
          state[UPLOAD_SAMPLE_PAGE_FORM][FILE_TYPE] = payload.type;
          state[UPLOAD_SAMPLE_PAGE_FORM][SAMPLE_TYPE] = payload.sampleType;
          console.log("SET_UPLOAD_SAMPLE_DATA", JSON.parse(JSON.stringify(state)));
        } catch (e) {
          console.error(e.message, "unable to SET_UPLOAD_SAMPLE_DATA");
        }
      },
    SET_SAMPLE_STEP_1_DATA(state, payload) {

      try {
        state[UPLOAD_SAMPLE_PAGE_FORM][SONG_ID_TEMP] = payload.sample_id;
      } catch (e) {
        console.error(e.message, "unable to SET_SAMPLE_STEP_1_DATA");
      }
    },
    SET_SAMPLE_STEP_2_DATA(state, payload) {
        console.log("STATE_AFTER_STEP_2_PAYLOAD", JSON.parse(JSON.stringify(payload)))
      try {
        state[UPLOAD_SAMPLE_PAGE_FORM][PICTURES] = payload[PICTURES];
        state[UPLOAD_SAMPLE_PAGE_FORM][PICTURES_ID] = payload[PICTURES_ID];
        state[UPLOAD_SAMPLE_PAGE_FORM][PAGE_ID] = payload[PAGE_ID];
        state[UPLOAD_SAMPLE_PAGE_FORM][TITLE] = payload[TITLE];
        state[UPLOAD_SAMPLE_PAGE_FORM][URL] = payload[URL];
        state[UPLOAD_SAMPLE_PAGE_FORM][DESCRIPTION] = payload[DESCRIPTION];
        state[UPLOAD_SAMPLE_PAGE_FORM][PODCAST_TYPE] = payload[PODCAST_TYPE];
        state[UPLOAD_SAMPLE_PAGE_FORM][PODCAST_CATEGORY_ID] = payload[PODCAST_CATEGORY_ID];
        state[UPLOAD_SAMPLE_PAGE_FORM][GENRE] = payload[GENRE];
        state[UPLOAD_SAMPLE_PAGE_FORM][SUB_GENRE] = payload[SUB_GENRE];
        state[UPLOAD_SAMPLE_PAGE_FORM][MOOD] = payload[MOOD];
        state[UPLOAD_SAMPLE_PAGE_FORM][TAGS] = payload[TAGS];
        state[UPLOAD_SAMPLE_PAGE_FORM][SAMPLE_DETAILS] = payload.sample_details;
        console.log("STATE_AFTER_STEP_2", JSON.parse(JSON.stringify(state)));
        state[COMPLETE_STEP_UPLOAD] = 2;
      } catch (e) {
        console.error(e.message, "unable to SET_SAMPLE_STEP_2_DATA");
      }
    },
    SET_SAMPLE_STEP_3_DATA(state, payload) {
      try {
        state[UPLOAD_SAMPLE_PAGE_FORM][METADATA_LANGUAGE] =
          payload[METADATA_LANGUAGE];
        state[UPLOAD_SAMPLE_PAGE_FORM][PRIMARY_ARTIST] = payload[PRIMARY_ARTIST];
        state[UPLOAD_SAMPLE_PAGE_FORM][FEATURE_ARTIST] = payload[FEATURE_ARTIST];
        state[UPLOAD_SAMPLE_PAGE_FORM][COMPOSER] = payload[COMPOSER];
        state[UPLOAD_SAMPLE_PAGE_FORM][PRODUCER] = payload[PRODUCER];
        state[UPLOAD_SAMPLE_PAGE_FORM][RE_MIXER] = payload[RE_MIXER];
        state[UPLOAD_SAMPLE_PAGE_FORM][RECORD_LABEL] = payload[RECORD_LABEL];
        state[UPLOAD_SAMPLE_PAGE_FORM][PUBLISHER] = payload[PUBLISHER];
        state[UPLOAD_SAMPLE_PAGE_FORM][RELEASED_AT] = payload[RELEASED_AT];
        state[UPLOAD_SAMPLE_PAGE_FORM][ISRC] = payload[ISRC];
        state[UPLOAD_SAMPLE_PAGE_FORM][COPYRIGHT] = payload[COPYRIGHT];
        state[UPLOAD_SAMPLE_PAGE_FORM][EXPLICIT_CONTENT] =
          payload[EXPLICIT_CONTENT];
        state[UPLOAD_SAMPLE_PAGE_FORM][CREATE_ISRC] = payload[CREATE_ISRC];
        state[UPLOAD_SAMPLE_PAGE_FORM][ALL_RIGHTS_RESERVED] =
          payload[ALL_RIGHTS_RESERVED];
        state[UPLOAD_SAMPLE_PAGE_FORM][CREATION_COMMON] = payload[CREATION_COMMON];
        state[COMPLETE_STEP_UPLOAD] = 3;
      } catch (e) {
        console.error(e.message, "unable to SET_SAMPLE_STEP_3_DATA");
      }
    },
    SET_SAMPLE_COLLABORATORS_DATA(state, collaborators) {
      try {
        state[UPLOAD_SAMPLE_PAGE_FORM][COLLABORATORS] = collaborators;
      } catch (e) {
        console.error(e.message, "unable to SET_SAMPLE_COLLABORATORS_DATA");
      }
    },
    SET_SAMPLE_STEP_4_DATA(state, payload) {
        console.log("STATE_AFTER_STEP_4", JSON.parse(JSON.stringify(state)));
        console.log("STATE_AFTER_STEP_4_PAYLOAD", JSON.parse(JSON.stringify(payload)))
      try {
        state[UPLOAD_SAMPLE_PAGE_FORM][PRICING_TYPE] = payload[PRICING_TYPE];
        state[UPLOAD_SAMPLE_PAGE_FORM][SALE_DATE] = payload[SALE_DATE];
        state[UPLOAD_SAMPLE_PAGE_FORM][PREORDER_INFO] = payload[PREORDER_INFO];
        state[UPLOAD_SAMPLE_PAGE_FORM][SONG_DOWNLOAD_PRICE] =
          payload[SONG_DOWNLOAD_PRICE];
        state[UPLOAD_SAMPLE_PAGE_FORM][PODCAST_DOWNLOAD_PRICE] = payload[PODCAST_DOWNLOAD_PRICE]
        state[UPLOAD_SAMPLE_PAGE_FORM][LET_FUN_PAY_TRACK] =
          payload[LET_FUN_PAY_TRACK];
        state[UPLOAD_SAMPLE_PAGE_FORM][ALBUM_DOWNLOAD_PRICE] =
          payload[ALBUM_DOWNLOAD_PRICE];
        state[UPLOAD_SAMPLE_PAGE_FORM][LET_FUN_PAY_ALBUM] =
          payload[LET_FUN_PAY_ALBUM];
        state[UPLOAD_SAMPLE_PAGE_FORM][RELEASED_AT] = payload[RELEASED_AT];
        state[COMPLETE_STEP_UPLOAD] = 4;
      } catch (e) {
        console.error(e.message, "unable to SET_SAMPLE_STEP_4_DATA");
      }
    },
    SET_SAMPLE_STEP_5_DATA(state, payload) {
        console.log("STATE_AFTER_STEP_5", JSON.parse(JSON.stringify(state)));
        console.log("STATE_AFTER_STEP_5_PAYLOAD", JSON.parse(JSON.stringify(payload)))
      try {
        state[UPLOAD_SAMPLE_PAGE_FORM][CLEAR_FOR_SALE_COUNTRIES] =
          payload[CLEAR_FOR_SALE_COUNTRIES];
        state[UPLOAD_SAMPLE_PAGE_FORM][CLEAR_FOR_SALE_TERRITORY] =
          payload[CLEAR_FOR_SALE_TERRITORY];
        state[UPLOAD_SAMPLE_PAGE_FORM][CLEAR_FOR_NOT_SALE_COUNTRIES] =
          payload[CLEAR_FOR_NOT_SALE_COUNTRIES];
        state[UPLOAD_SAMPLE_PAGE_FORM][CLEAR_FOR_NOT_SALE_TERRITORY] =
          payload[CLEAR_FOR_NOT_SALE_TERRITORY];
        state[COMPLETE_STEP_UPLOAD] = 5;
      } catch (e) {
        console.error(e.message, "unable to SET_SAMPLE_STEP_5_DATA");
      }
    },
    SET_SAMPLE_STEP_6_DATA(state, payload) {
      try {
        state[UPLOAD_SAMPLE_PAGE_FORM][ENABLE_DIRECT_DOWNLOAD] =
          payload[ENABLE_DIRECT_DOWNLOAD];
        state[UPLOAD_SAMPLE_PAGE_FORM][ENABLE_OFFLINE_LISTING] =
          payload[ENABLE_OFFLINE_LISTING];
        state[UPLOAD_SAMPLE_PAGE_FORM][INCLUDE_RES] = payload[INCLUDE_RES];
        state[UPLOAD_SAMPLE_PAGE_FORM][DISPLAY_EMBED_CODE] =
          payload[DISPLAY_EMBED_CODE];
        state[UPLOAD_SAMPLE_PAGE_FORM][ENABLE_APP_PLAYBACK] =
          payload[ENABLE_APP_PLAYBACK];
        state[UPLOAD_SAMPLE_PAGE_FORM][ENABLE_COMMENT] = payload[ENABLE_COMMENT];
        state[UPLOAD_SAMPLE_PAGE_FORM][DISPLAY_COMMENT] = payload[DISPLAY_COMMENT];
        state[UPLOAD_SAMPLE_PAGE_FORM][DISPLAY_STATS] = payload[DISPLAY_STATS];
        state[COMPLETE_STEP_UPLOAD] = 6;
      } catch (e) {
        console.error(e.message, "unable to SET_SAMPLE_STEP_6_DATA data");
      }
    },
    SET_TEMP_SONG_DATA(state, payload) {
      try {
        state[TEMP_SONG_FORM] = {
          [PRIVACY]: payload.privacy,
          [_TYPE]: payload.type,
          [FILE]: payload.file
        };
        state[COMPLETE_STEP_UPLOAD] = 1;
      } catch (e) {
        console.error(e.message, "unable to  SET_TEMP_SONG_DATA data");
      }
    },
    SET_TEMP_SAMPLE_ID(state, payload) {
      try {
        state[TEMP_SAMPLE_FORM][SONG_ID_TEMP] = payload;
        state[UPLOAD_SAMPLE_PAGE_FORM][SONG_ID_TEMP] = payload;
      } catch (e) {
        console.error(e.message, "unable to SET_TEMP_IMAGE_ID");
      }
    },
    NEXT_PAGINATE_PAGES_OFFSET_SET(state, TYPE = DISCOVER_PAGES) {
      try {
          state[TYPE][POST_OFFSET] =
              Number(state[TYPE][POST_OFFSET]) + Number(state[TYPE][POST_LIMIT]);
      } catch (e) {
          console.error(e.message, "unable to set next offset of page posts");
      }
    },
    CHANGE_LOADING_OF_SPECIFIC_CATEGORY(state, {type, loadingState}) {
      state[type][LOADING] = loadingState;
    },
    SET_DISCOVER_PAGES(state, pages) {
      try {
          state[DISCOVER_PAGES][PAGES] = pages;
      } catch (e) {
          console.error(e.message, "unable to set page categories");
      }
    },
    SET_DISCOVER_PAGES_TOTAL(state, payload) {
      try {
          state[DISCOVER_PAGES][TOTAL_PAGES] = payload;
      } catch (e) {
          console.error(e.message, "unable to SET Total post counts");
      }
    },
    SET_SELECTED_SORT(state, payload) {
      try {
          state[SELECTED_FILTERS][SELECTED_SORT] = payload
      } catch (e) {
          console.error(e.message, 'unable to set selected sort')
      }
    },
    SET_SELECTED_FILTERS_EMPTY(state) {
      try {
          state[SELECTED_FILTERS][SELECTED_CATEGORY] = ''
          state[SELECTED_FILTERS][SELECTED_SUBCATEGORY] = ''
          state[SELECTED_FILTERS][SELECTED_GENRE] = ''
          state[SELECTED_FILTERS][SELECTED_LOCATION] = ''
          state[SELECTED_FILTERS][SELECTED_SORT] = 'latest'
      } catch (e) {
          console.error(e.message, 'unable to set selected selected filters empty')
      }
  },
  };
  const getters = {
    getTempUploadSampleFormData(state) {
      let _state = Utils.parseState(state);
      return _state[TEMP_SONG_FORM];
    },
    getTempUploadSampleID(state) {
      let _state = Utils.parseState(state);
      return _state[UPLOAD_SONG_PAGE_FORM][SONG_ID_TEMP];
    },
    getUploadSampleDetailFormData(state) {
      let _state = Utils.parseState(state);
      return _state[UPLOAD_SAMPLE_PAGE_FORM];
    }
  };
  
  export default {
    state,
    actions,
    mutations,
    getters
  };
  