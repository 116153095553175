<template>
  <div class="overview-content music-overview">
    <div class="wrapper">
      <div class="row">
        <div class="col px-0">
          <div class="overview-mains">
            <h1 class="Page_title" style="margin-bottom: 21px;line-height: unset !important;">EXPLORE MUSIC</h1>
            <div class="pay-stream-switch">
              <payStreamSwitch />
            </div>
          </div>
        </div>
      </div>
      <div class="discover content helvetica-font">
        <div class="gallery-card new-style">
          <div class=" musicMain mx-0">

            <!-- col -->
            <div class="musicSubMain  px-md-0">
                <div
                  class="first-row card gallery"
                  @click="
                    redirectToChannel(
                      'country',
                      'top-100-singles-uk',
                      getUserCountryName,
                      getUserCountryId,
                      'singles'
                    )
                  "
                >
                  <img
                    :src="require(`@/assets/img/music/overview/tile-1.png`)"
                    class="js-card-img cursor-pointer"
                    alt="..."
                    style="width: 800px;height: 369px;"
                  />
                  <div class="card-img-overlay h-100">
                    <div class="row">
                      <div class="col">
                        <div class="card-bottom">
                          <h1 class="card-title">
                            <a href="#"
                              >Name Album <br>
                              <span style="font-family: HelveticaNeueNormal, sans-serif;font-size: 24px">from</span>
                              {{
                                getUserCountryCode
                                  ? getUserCountryCode
                                  : "Global"
                              }}</a
                            >
                          </h1>
                          <div class="btn-group js-active">
                            <div
                              class="
                                btn btn-icon-text
                                bright
                                btn-gradient
                                fill
                                dark
                              "
                              @click="
                                $event.currentTarget.classList.toggle('active')
                              "
                            >
                            <svg class="svg-play" xmlns="http://www.w3.org/2000/svg" width="11" height="13" viewBox="0 0 11 13" fill="none">
                              <path class="c-line-1" d="M1.756 11.9999C1.6394 11.998 1.52466 11.9704 1.42 11.9189C1.30068 11.8487 1.20014 11.7506 1.12699 11.6331C1.05385 11.5155 1.01028 11.382 1 11.2439V1.66692C1.00597 1.53314 1.04329 1.40262 1.10895 1.2859C1.17461 1.16917 1.26677 1.06951 1.378 0.994924C1.49477 0.928694 1.62813 0.89744 1.76217 0.904887C1.89621 0.912333 2.02528 0.958167 2.134 1.03692L9.485 5.82492C9.58833 5.89435 9.673 5.98813 9.73154 6.09799C9.79008 6.20786 9.8207 6.33044 9.8207 6.45492C9.8207 6.57941 9.79008 6.70199 9.73154 6.81185C9.673 6.92172 9.58833 7.0155 9.485 7.08492L2.134 11.8739C2.02349 11.9529 1.89179 11.9968 1.756 11.9999" stroke="#D1433A" stroke-width="1.3"/>
                            </svg>
                              <span>PLAY CHART</span>
                            </div>
                            <div
                              class="btn btn-gradient dark"
                              @click="
                                $event.currentTarget.classList.toggle('active')
                              "
                            >
                              <svg
                                width="18"
                                height="16"
                                viewBox="0 0 18 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="svg-save-wishlist"
                              >
                                <path
                                  class="c-line-1"
                                  d="M15.7545 2.2374C15.3623 1.8451 14.8966 1.53391 14.3841 1.32159C13.8716 1.10928 13.3223 1 12.7675 1C12.2128 1 11.6635 1.10928 11.1509 1.32159C10.6384 1.53391 10.1728 1.8451 9.78052 2.2374L8.96599 3.05193L8.15145 2.2374C7.35387 1.4716 6.28787 1.04896 5.18222 1.0602C4.07657 1.07144 3.01937 1.51565 2.23752 2.2975C1.45566 3.07935 1.01145 4.13655 1.00022 5.2422C0.988983 6.34785 1.41162 7.41385 2.17742 8.21144L2.99195 9.02597L8.96599 15L14.94 9.02597L15.7545 8.21144C16.1469 7.8192 16.458 7.35352 16.6704 6.84101C16.8827 6.32849 16.992 5.77917 16.992 5.22442C16.992 4.66967 16.8827 4.12035 16.6704 3.60783C16.458 3.09531 16.1469 2.62964 15.7545 2.2374V2.2374Z"
                                  stroke="#D1433A"
                                  stroke-width="1.3"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                            <div
                              class="btn btn-gradient dark"
                              @click="
                                $event.currentTarget.classList.toggle('active')
                              "
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="svg-shop"
                              >
                                <path
                                  d="M14.9995 10.334V13.445C14.9995 13.8575 14.8357 14.2532 14.5439 14.5449C14.2522 14.8366 13.8566 15.0005 13.444 15.0005H2.5555C2.14296 15.0005 1.74731 14.8366 1.4556 14.5449C1.16388 14.2532 1 13.8575 1 13.445V10.334"
                                  stroke="#0094FF"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="c-line-1"
                                />
                                <path
                                  d="M11.8889 4.88876L8.00009 1L4.11133 4.88876"
                                  stroke="#0094FF"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="c-line-1"
                                />
                                <path
                                  d="M8 1V10.333"
                                  stroke="#0094FF"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="c-line-1"
                                />
                              </svg>
                            </div>
                            <div
                              class="btn btn-gradient new-color dark"
                              @click="
                                $event.currentTarget.classList.toggle('active')
                              "
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  class="c-line-1 c-fill-1 svg-plus"
                                  d="M8.00006 1C8.42239 1 8.76476 1.34237 8.76476 1.76471V14.2353C8.76476 14.6576 8.42239 15 8.00006 15C7.57772 15 7.23535 14.6576 7.23535 14.2353V1.76471C7.23535 1.34237 7.57772 1 8.00006 1Z"
                                  fill="#D1433A"
                                  stroke="#D1433A"
                                  stroke-width="0.3"
                                  stroke-linecap="square"
                                  stroke-linejoin="round"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  class="c-line-1 c-fill-1 svg-plus"
                                  d="M1 8.00006C1 7.57772 1.34237 7.23535 1.76471 7.23535H14.2353C14.6576 7.23535 15 7.57772 15 8.00006C15 8.42239 14.6576 8.76476 14.2353 8.76476H1.76471C1.34237 8.76476 1 8.42239 1 8.00006Z"
                                  fill="#D1433A"
                                  stroke="#D1433A"
                                  stroke-width="0.3"
                                  stroke-linecap="square"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>

            <!-- col -->
            <div
              class="
                musicSubMain2
              "
            >
              <div class="second-row h-100">
                <div
                style="width: 360px;height: 179px;"
                  class="card gallery first js-card-gallery"
                  @click="
                    redirectToChannel(
                      'country',
                      'top-100-by-country',
                      'Global',
                      'global',
                      'singles'
                    )
                  "
                >
                  <img
                    :src="require(`@/assets/img/music/overview/music-2.png`)"
                    class="js-card-img cursor-pointer"
                    alt="..."
                  />
                  <!-- <div class="card-img-overlay1">
                    <div class="bottom">
                      <div class="title">
                        <a href="#">Top 100 LP's Global</a>
                      </div>
                    </div>
                  </div> -->
                </div>

                <div
                style="width: 360px;height: 179px;margin-bottom: unset;"
                  class="card gallery first js-card-gallery"
                  @click="
                    redirectToChannel(
                      'country',
                      'top-100-by-country',
                      'Global',
                      'global',
                      'singles'
                    )
                  "
                >
                  <img
                    :src="require(`@/assets/img/music/overview/music-3.png`)"
                    class="js-card-img cursor-pointer"
                    alt="..."
                  />
                  <div class="card-img-overlay1">
                    <div class="bottom fixtopLpGlbal">
                      <div class="title">
                        <a href="#" >Top 100 LP's Global</a>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>


        <!-- play all button -->
        <div class="row options">
          <div class="col-sm-2">
            <div class="btn btn-play-all" @click="playAll">
              <svg
                width="11"
                height="13"
                viewBox="0 0 11 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="svg-play filled"
              >
                <path
                  class="c-line-1"
                  d="M1.93569 11.9999C1.81909 11.998 1.70435 11.9704 1.59969 11.9189C1.48036 11.8487 1.37983 11.7506 1.30668 11.6331C1.23354 11.5155 1.18996 11.382 1.17969 11.2439V1.66692C1.18566 1.53314 1.22298 1.40262 1.28864 1.2859C1.3543 1.16917 1.44645 1.06951 1.55769 0.994924C1.67446 0.928694 1.80782 0.89744 1.94186 0.904887C2.0759 0.912333 2.20497 0.958167 2.31369 1.03692L9.66469 5.82492C9.76802 5.89435 9.85268 5.98813 9.91123 6.09799C9.96977 6.20786 10.0004 6.33044 10.0004 6.45492C10.0004 6.57941 9.96977 6.70199 9.91123 6.81185C9.85268 6.92172 9.76802 7.0155 9.66469 7.08492L2.31369 11.8739C2.20318 11.9529 2.07147 11.9968 1.93569 11.9999"
                  stroke="white"
                  stroke-width="1.3"
                ></path>
              </svg>
              PLAY ALL
            </div>
          </div>
          <div class="col pr-0">
            <div
              class="
                d-flex
                flex-wrap
                justify-content-end
                align-items-end
                h-100
                over-play-signle
              "
            >
              <div
                class="
                  text
                  with-icon
                  d-flex
                  justify-content-between justify-content-end
                  align-items-center
                "
              >
                Sort
                <div class="svg-icon">
                  <svg
                    width="17"
                    height="13"
                    viewBox="0 0 17 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.964844"
                      width="9.51351"
                      height="2.16216"
                      rx="1.08108"
                      fill="#D1433A"
                    />
                    <rect
                      x="0.964844"
                      y="5.18945"
                      width="16"
                      height="2.16216"
                      rx="1.08108"
                      fill="#D1433A"
                    />
                    <rect
                      x="0.964844"
                      y="10.377"
                      width="12.973"
                      height="2.16216"
                      rx="1.08108"
                      fill="#D1433A"
                    />
                  </svg>
                </div>
              </div>
              <div
                class="
                  dropdown
                  bootstrap-select
                  show-tick
                  filters-select
                  for-overview
                  pg-input-right
                  mr-3
                "
              >
                <select
                  v-model="selectedGenre"
                  class="filters-select selectpicker for-overview"
                  title="All Genres"
                  multiple=""
                  data-max-options="1"
                  dropupauto="false"
                  tabindex="-98"
                >
                  <option value="0">Classical</option>
                  <option value="1">Dance/ Electronic</option>
                  <option value="2">Hip-Hop/ Rap</option>
                  <option value="3">Indie/ Rock</option>
                  <option value="4">Pop</option>
                </select>
                <button
                  type="button"
                  class="btn dropdown-toggle btn-light bs-placeholder"
                  data-toggle="dropdown"
                  role="combobox"
                  aria-owns="bs-select-1"
                  aria-haspopup="listbox"
                  aria-expanded="false"
                  title="All Genres"
                >
                  <div class="filter-option">
                    <div class="filter-option-inner">
                      <div class="filter-option-inner-inner">
                        {{ selectedGenreDisplay }}
                      </div>
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu overview-genre-drop-down">
                  <perfect-scrollbar>
                    <div
                      class="inner show"
                      role="listbox"
                      id="bs-select-1"
                      tabindex="-1"
                      aria-multiselectable="true"
                    >
                      <ul class="dropdown-menu inner show" role="presentation">
                        <li @click="removeAllSelectedGenre()">
                          <a
                            role="option"
                            :class="{
                              'active selected ': selectedGenre.length <= 0,
                            }"
                            class="dropdown-item"
                            id="bs-select-9-0"
                            tabindex="0"
                            aria-selected="false"
                            aria-setsize="6"
                            aria-posinset="1"
                            ><span class="bs-ok-default check-mark"></span
                            ><span class="text">All Genres</span></a
                          >
                        </li>
                      </ul>
                      <ul class="dropdown-menu inner show" role="presentation">
                        <li
                          @click="selectOrUnSelectGenre(genre.value)"
                          v-for="(genre, index) in genres"
                          :key="index"
                        >
                          <a
                            role="option"
                            :class="{
                              'active selected ': isSelectedGenre(genre.value),
                            }"
                            class="dropdown-item"
                            id="bs-select-9-0"
                            tabindex="0"
                            aria-selected="false"
                            aria-setsize="6"
                            aria-posinset="1"
                            ><span class="bs-ok-default check-mark"></span
                            ><span class="text">{{ genre.text }}</span></a
                          >
                        </li>
                      </ul>
                    </div>
                  </perfect-scrollbar>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-between">
          <div
            v-for="(singleChannel, index) in allChannels.center"
            v-bind:key="index"
            class="music-overview-cards fixMusicOverviewCards"
            :class="{'weekly-top-boards': singleChannel.alt_name === 'weekly-top-boards'}"
          >
            <component
              @hovering="hovering($event, index)"
              v-bind:is="myComponentList[singleChannel.alt_name].component"
              :myInd="index"
              :gridTitle="singleChannel.description"
              :pageTitle="singleChannel.title"
              :header="true"
              :redirectLink="myComponentList[singleChannel.alt_name].link"
              :listData="singleChannel.objects.data"
              :altName="singleChannel.alt_name"
              :music_type="myComponentList[singleChannel.alt_name].music_type"
              :componentType="componentType"
              :channelType="singleChannel.type"
            />
          </div>
        </div>
        <div class="js-container">
          <div class="sale-items m-t-40">
            <div class="row justify-content-between">
              <div class="col-8">
                <div class="row position-relative">
                  <div
                    v-for="(singleChannel, index) in allChannels.center"
                    v-bind:key="index"
                  >
                    <component
                      v-bind:is="
                        myComponentList[singleChannel.alt_name].component
                      "
                      :cname="singleChannel.description"
                      :myInd="index"
                      :gridTitle="singleChannel.description"
                      :header="true"
                      :listData="singleChannel.channel_data"
                      :altName="singleChannel.alt_name"
                      :channelType="singleChannel.type"
                      v-if="
                        singleChannel.channel_element_view == 'four_grid' &&
                        listing_type == 'grid'
                      "
                    />
                    <component
                      v-bind:is="'SongsPopularity'"
                      :cname="singleChannel.description"
                      :myInd="index"
                      :gridTitle="singleChannel.description"
                      :header="true"
                      :listData="singleChannel.channel_data"
                      :altName="singleChannel.alt_name"
                      :channelType="singleChannel.type"
                      v-if="
                        singleChannel.channel_element_view == 'four_grid' &&
                        listing_type != 'grid'
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="content-sidebar">
                  <div
                    v-for="(singleChannel, index) in allChannels.right_bar"
                    v-bind:key="index"
                  >
                    <component
                      v-bind:is="
                        myComponentList[singleChannel.filter].component
                      "
                      :cname="singleChannel.description"
                      :myInd="index"
                      :gridTitle="singleChannel.description"
                      :header="true"
                      :redirectLink="myComponentList[singleChannel.filter].link"
                      :listData="singleChannel.channel_data"
                      :channelType="singleChannel.type"
                      v-if="
                        singleChannel.filter !=
                        'most_popular_track_albums_tat_realeased_in_last_month'
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <notifications group="foo" class="notification-style" />
    <full-page-loader :show="loading" />
  </div>
</template>
<script type="application/javascript">
//ui components
import payStreamSwitch from "@/components/Music/UI-components/payStreamToggleSwitch.vue";

//components
import GridSellingWithSlider from "@/components/Common/Elements/GridSellingWithSlider.vue";
import LastUpdate from "@/components/Landing/LastUpdate.vue";
import SongsPopularity from "@/components/Common/Elements/SongsPopularity.vue";
import TrendingRelease from "@/components/Common/Elements/TrendingRelease.vue";
import ListOption from "@/components/Common/Elements/ListOptions.vue";

import WeeklyTopBoards from "@/components/Common/Elements/WeeklyTopBoards.vue";

import GridSelling4 from "@/components/Common/Elements/GridSelling4.vue";
import WhoToFollow from "@/components/Common/Elements/WhoToFollow.vue";
import Boosted from "@/components/Common/Elements/Boosted.vue";
import RecentReviews from "@/components/Common/Elements/RecentReviews.vue";

import { getChannelData } from "../../../apis/APIs";
import { mapActions } from "vuex";

import "/public/css/perfect-scroll-bar.scss";
import "/public/css/carousels.scss";

export default {
  data() {
    return {
      showMd: false,
      showAd: false,
      loading: false,
      dataLoaded: false,
      componentType: "buy",
      component: "GridSellingWithSlider",
      recentPlayedSongs: [],
      allChannels: [],
      genres: [],
      selectedGenre: [],
      listing_type: "grid",
      hot_new_songs_array: [],
      myComponentList: {
        // Overview
        "continue-listening-to": {
          component: "GridSellingWithSlider",
          link: "continue-listening-to",
          subComponent: "",
          music_type: "song",
        },
        "hot-new-songs": {
          component: "LastUpdate",
          link: "hot-new-songs",
          subComponent: "",
          music_type: "album",
        },
        "top-albums-of-the-week": {
          component: "GridSellingWithSlider",
          link: "top-albums-of-the-week",
          subComponent: "",
          music_type: "album",
        },
        "weekly-top-boards": {
          component: "WeeklyTopBoards",
          link: "top-album-of-the-week",
          subComponent: "SongsPopularity",
        },
        "trending-artist-this-week": {
          component: "TrendingRelease",
          link: "trending-artist-this-week",
          subComponent: "",
        },
      },
    };
  },
  components: {
    GridSellingWithSlider,
    LastUpdate,
    SongsPopularity,
    TrendingRelease,
    ListOption,
    WeeklyTopBoards,
    GridSelling4,
    WhoToFollow,
    Boosted,
    RecentReviews,
    payStreamSwitch,
  },
  computed: {
    selectedGenreDisplay() {
      if (this.selectedGenre.length > 0) {
        const genreId = this.selectedGenre[0];
        return (
          this.genres[this.genres.findIndex((e) => e.value === genreId)].text +
          String(
            this.selectedGenre.length > 1
              ? " +" + (Number(this.selectedGenre.length) - 1)
              : ""
          )
        );
      }
      return "All Genres";
    },
    getUserCountryCode() {
      return this.$store.getters.get_user_country_code;
    },
    getUserCountry() {
      return this.$store.getters.get_user_country;
    },
    getUserCountryName() {
      if (this.getUserCountry.length > 0) {
        return this.getUserCountry[0].name;
      }
      return "global";
    },
    getUserCountryId() {
      if (this.getUserCountry.length > 0) {
        return this.getUserCountry[0].id;
      }
      return "global";
    },
    isStreaming() {
      return this.$store.getters.isMusicModeIsStreaming;
    },
  },
  watch: {
    selectedGenre() {
      this.pageChannels();
    },
  },
  mounted: async function () {
    await this.pageChannels();
    await this.getGenres();
    if (localStorage.getItem("componentType")) {
      this.componentType = localStorage.getItem("componentType");
    }
  },
  methods: {
    ...mapActions(["get_geners"]),
    updateComponentType: function (type) {
      this.componentType = type;
      localStorage.setItem("componentType", type);
    },
    hovering(object, index) {
      // console.log(this.allChannels.center[index].objects.data[object.index].isHovering)
      this.allChannels.center[index].objects.data[object.index].isHovering =
        object.val;
    },
    async pageChannels() {
      try {
        this.allChannels = [];
        this.dataLoaded = false;
        await this.$store.dispatch("loading", true);
        console.log(this.selectedGenre, 'sss');
        let data = await getChannelData("home", this.selectedGenre);
        console.log(data, 'singss');
        this.allChannels = data.data.channels;
        this.hot_new_songs_array =
          data.data.channels.center[1] &&
          data.data.channels.center[1].alt_name === "hot-new-songs"
            ? data.data.channels.center[1].objects.data
            : [];
        this.dataLoaded = true;
        await this.$store.dispatch("loading", false);
      } catch (error) {
        console.error(error);
        await this.$store.dispatch("loading", false);
      }
    },
    redirect: function (url) {
      this.$router.push({
        name: "music." + url,
        params: { title: "Hot new singles", pageFrom: "overview" },
      });
    },
    async getGenres() {
      try {
        if (
          !this.$store.getters.getGenres &&
          !this.$store.getters.getGenres.length > 0
        ) {
          await this.$store.dispatch("get_geners");
        }
        const data =
          this.$store.getters.getGenres &&
          this.$store.getters.getGenres.length > 0
            ? this.$store.getters.getGenres
            : [];
        this.genres =
          data && data.length
            ? data.map((genre) => {
                return {
                  value: genre.id,
                  text: genre.name,
                };
              })
            : [];
      } catch (error) {
        console.error(error);
      }
    },
    selectOrUnSelectGenre(id) {
      const index = this.selectedGenre.findIndex((e) => e === id);
      if (index !== -1) {
        this.selectedGenre.splice(index, 1);
      } else {
        this.selectedGenre.push(id);
      }
    },
    removeAllSelectedGenre() {
      this.selectedGenre = [];
    },
    isSelectedGenre(id) {
      const index = this.selectedGenre.findIndex((e) => e === id);
      return index !== -1;
    },
    playAll() {
      if (this.hot_new_songs_array.length > 0) {
        console.log('het');
        this.$store.commit(
          "Set_Play_All_Songs_Array",
          this.hot_new_songs_array
        );
        this.$store.commit("SET_CURRENT_PLAYER", "footerPlayer");
        this.$store.commit("Set_Song_status", true);
      } else {
        console.log("No Hot Songs Yet");
      }
    },
  },
};
</script>
<style lang="scss">

.overview-content {
  margin-top: 4.5rem;
}


  .pay-stream-switch {
    position: absolute;
    right: -29px;
    top: 1px;

  }

  .notification-style {
    margin-top: 5rem;
  }

  .top-active-btn::before {
    content: "";
    position: absolute;
    background-image: url(../../../../src/assets/img/check-white.png);
    width: 12px;
    height: 9px;
    top: 9px;
    left: 16%;
  }

  .list {
    width: 104% !important;
  }
  .gallery-card {
    position: relative;
    & > .row {
      top: 0;
      bottom: 0;
      width: 100%;
      left: 0;
      right: 0;
    }
  }

</style>
