<template>
<!--  @click="redirectToFullViewPage($event, item.type, item.id)"-->
  <div
    class="song-album-card"
    :class="['js-container-hover', item.isHovering ? 'hover' : '']"
  >
    <div
      class="song-album-square-card"
    >
      <div
        class="card channel-card"
        @mouseover="isHoveringFun(true)"
        @mouseleave="isHoveringFun(false)"
      >
        <div
          :class="['card-cover js-card-hover', item.isHovering ? 'hover' : '']"
        >
          <div class="cover square channel-card-cover">
            <img
              class="rounded-top img-fluid w-100"
              :src="item.artwork_url"
              alt=""
            />
            <div class="card-rating px-1">
              #Electro.
              <!--              <div class="svg-icon">-->
<!--                <svg-->
<!--                  width="12"-->
<!--                  height="12"-->
<!--                  viewBox="0 0 12 12"-->
<!--                  fill="none"-->
<!--                  xmlns="http://www.w3.org/2000/svg"-->
<!--                  class="svg-star"-->
<!--                >-->
<!--                  <path-->
<!--                    d="M5.90646 8.64551C5.90288 8.64279 5.89791 8.6428 5.89434 8.64553L2.84276 10.981C2.83503 10.9869 2.82426 10.9794 2.82713 10.9701L3.99444 7.19061C3.99567 7.18661 3.99428 7.18226 3.99096 7.17972L0.923852 4.83399C0.916256 4.82818 0.920364 4.81605 0.929927 4.81605H4.7135C4.71789 4.81605 4.72176 4.81319 4.72306 4.809L5.88996 1.03083C5.89286 1.02144 5.90615 1.02143 5.90907 1.03081L7.08406 4.80902C7.08536 4.8132 7.08923 4.81605 7.09361 4.81605H10.8709C10.8804 4.81605 10.8845 4.82818 10.8769 4.83399L7.82342 7.17091C7.8201 7.17346 7.81871 7.1778 7.81994 7.18181L8.98731 10.9615C8.99018 10.9708 8.97945 10.9783 8.97171 10.9724L5.90646 8.64551Z"-->
<!--                    fill="white"-->
<!--                    stroke="white"-->
<!--                    stroke-width="1.3"-->
<!--                  ></path>-->
<!--                </svg>-->
<!--              </div>-->
<!--              <div class="rating pl-1">5.0</div>-->
            </div>
            <div class="top-week-album-img-hover">
              <div
                v-show="item.isHovering"
                class="btn-slider-head position-absolute like-icon"
              >
                <div class="svg-icon" @click="addToLoveToggle($event, item)">
                  <b-spinner
                    v-if="spinner"
                    class="spinner-color"
                    small
                    label="Small Spinner"
                  ></b-spinner>
                  <svg
                    v-else
                    :class="{ 'fill-icon': favorite === true }"
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.1991 2.22387C12.8748 1.9063 12.4898 1.65438 12.0661 1.48251C11.6423 1.31063 11.1881 1.22217 10.7295 1.22217C10.2708 1.22217 9.8166 1.31063 9.39285 1.48251C8.9691 1.65438 8.58408 1.9063 8.25977 2.22387L7.58631 2.88326L6.91286 2.22387C6.25341 1.60394 5.37204 1.26181 4.45788 1.2709C3.54372 1.28 2.66963 1.63959 2.02319 2.27252C1.37675 2.90545 1.00947 3.76128 1.00018 4.65633C0.990891 5.55138 1.34033 6.41433 1.9735 7.05999L2.64696 7.71938L7.58631 12.5555L12.5257 7.71938L13.1991 7.05999C13.5235 6.74247 13.7808 6.36549 13.9563 5.9506C14.1319 5.53571 14.2222 5.09102 14.2222 4.64193C14.2222 4.19285 14.1319 3.74816 13.9563 3.333 27C13.7808 2.91837 13.5235 2.5414 13.1991 2.22387V2.22387Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div
                v-show="item.isHovering"
                :class="[
                  'play-button js-play-button new-play-button pg-new-play-button position-absolute play-pause-icon',
                  item.isHovering ? 'd-block' : 'd-block',
                  PlayIconStatus(
                    item,
                    currentPlayer,
                    currentPlayId,
                    songStatus,
                    currentPlayTypeID
                  ),
                ]"
                @click="
                  playPause(
                    $event,
                    item.type === 'song' ? [item] : item.playlist_songs,
                    'footerPlayer',
                    item.type === 'album' ? item.id : item.id
                  )
                "
              >
                <div class="svg-icon">
                  <svg
                    width="13"
                    height="16"
                    viewBox="0 0 13 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="play-type-1"
                  >
                    <path
                      class="c-fill-1 c-line-1"
                      d="M2.48967 14.5038C2.35174 14.5004 2.21628 14.4666 2.093 14.4046C1.95246 14.322 1.83398 14.2066 1.74764 14.0683C1.66131 13.93 1.60969 13.7729 1.59717 13.6103L1.59717 2.30439C1.60458 2.14673 1.64886 1.99301 1.72644 1.85556C1.80402 1.71811 1.91275 1.60077 2.04389 1.51295C2.18183 1.43494 2.33926 1.39819 2.49748 1.40706C2.6557 1.41593 2.80804 1.47006 2.93639 1.563L11.6139 7.2155C11.7361 7.29735 11.8362 7.40803 11.9054 7.53774C11.9746 7.66746 12.0108 7.81222 12.0108 7.95925C12.0108 8.10628 11.9746 8.25104 11.9054 8.38076C11.8362 8.51047 11.7361 8.62115 11.6139 8.703L2.93639 14.3574C2.80583 14.4508 2.65018 14.5028 2.48967 14.5066"
                      stroke="white"
                      stroke-width="1.3"
                    ></path>
                  </svg>
                  <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="c-line-1 c-fill-1"
                      d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                      fill="white"
                    ></path>
                    <path
                      class="c-line-1 c-fill-1"
                      d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                      stroke="white"
                      stroke-width="1.3"
                    ></path>
                  </svg>
                  <svg
                    width="10"
                    height="14"
                    viewBox="0 0 10 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="c-line-1 c-fill-1"
                      d="M1 1V13"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                    ></path>
                    <path
                      class="c-line-1 c-fill-1"
                      d="M9 1V13"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </div>
              </div>
              <div
                v-show="item.isHovering"
                class="btn-more position-absolute list-icon"
              >
                <div
                  class="dropdown more-dropdown icon"
                  @click="
                    openListOption(
                      $event,
                      item.type === 'song' ? [item] : item.playlist_songs,
                      'default_option_list',
                      item,
                      '',
                      favorite
                    )
                  "
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-display="static"
                >
                  <div class="svg-icon more-position">
                    <svg
                      width="15"
                      height="3"
                      viewBox="0 0 15 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="svg-more-hor"
                    >
                      <path
                        class="c-fill-1"
                        d="M2.22144 2.99805C3.01872 2.99805 3.66505 2.35173 3.66505 1.55445C3.66505 0.757165 3.01872 0.11084 2.22144 0.11084C1.42416 0.11084 0.777832 0.757165 0.777832 1.55445C0.777832 2.35173 1.42416 2.99805 2.22144 2.99805Z"
                        fill="white"
                      ></path>
                      <path
                        class="c-fill-1"
                        d="M7.79615 2.99805C8.59343 2.99805 9.23975 2.35173 9.23975 1.55445C9.23975 0.757165 8.59343 0.11084 7.79615 0.11084C6.99886 0.11084 6.35254 0.757165 6.35254 1.55445C6.35254 2.35173 6.99886 2.99805 7.79615 2.99805Z"
                        fill="white"
                      ></path>
                      <path
                        class="c-fill-1"
                        d="M13.3709 2.99805C14.1681 2.99805 14.8145 2.35173 14.8145 1.55445C14.8145 0.757165 14.1681 0.11084 13.3709 0.11084C12.5736 0.11084 11.9272 0.757165 11.9272 1.55445C11.9272 2.35173 12.5736 2.99805 13.3709 2.99805Z"
                        fill="white"
                      ></path>
                    </svg>
                    <svg
                      width="3"
                      height="15"
                      viewBox="0 0 3 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="svg-more"
                    >
                      <path
                        d="M-6.55671e-08 1.5C-1.01779e-07 2.32843 0.671573 3 1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 -2.93554e-08 1.5 -6.55671e-08C0.671573 -1.01779e-07 -2.93554e-08 0.671573 -6.55671e-08 1.5Z"
                        class="c-fill-1"
                      ></path>
                      <path
                        d="M-6.55671e-08 7.29199C-1.01779e-07 8.12042 0.671573 8.79199 1.5 8.79199C2.32843 8.79199 3 8.12042 3 7.29199C3 6.46357 2.32843 5.79199 1.5 5.79199C0.671573 5.79199 -2.93554e-08 6.46356 -6.55671e-08 7.29199Z"
                        class="c-fill-1"
                      ></path>
                      <path
                        d="M-6.55671e-08 13.084C-1.01779e-07 13.9124 0.671573 14.584 1.5 14.584C2.32843 14.584 3 13.9124 3 13.084C3 12.2556 2.32843 11.584 1.5 11.584C0.671573 11.584 -2.93554e-08 12.2556 -6.55671e-08 13.084Z"
                        class="c-fill-1"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-description slider-bottom">
            <div class="Continue-listing-bg">
              <div class="title marquee js-marquee">
                <div class="gird-title-name">
                  <a href="#">
                    {{ item.title ? item.title : "" }}
                  </a>
                </div>
              </div>
              <div class="description marquee js-marquee">
                <div class="gird-title-subname sub-title-text" style="margin-top: 5px">
                  <a href="#">
                    {{ "by " + getPlayListArtist(item) }}
                  </a>
                </div>
              </div>
            </div>
            <div class="followers card-follow" :class="{ 'actibe-followers': favorite === true }" @click="addToLoveToggle($event, item)">
              <div class="text">
                <div class="svg-icon">
                  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="c-line-1 c-fill-1"
                          d="M1 8.00938C3.757 8.00938 6 10.2547 6 13.0145C6 13.5671 6.448 14.0156 7 14.0156C7.552 14.0156 8 13.5671 8 13.0145C8 9.15056 4.86 6.00732 1 6.00732C0.448 6.00732 0 6.45579 0 7.00835C0 7.56092 0.448 8.00938 1 8.00938Z"
                          fill="#D1433A"></path>
                    <path class="c-line-1 c-fill-1"
                          d="M1 2.00206C7.0655 2.00206 12 6.94164 12 13.0134C12 13.566 12.448 14.0144 13 14.0144C13.552 14.0144 14 13.566 14 13.0134C14 5.83801 8.1685 0 1 0C0.448 0 0 0.448461 0 1.00103C0 1.5536 0.448 2.00206 1 2.00206Z"
                          fill="#D1433A"></path>
                    <path class="c-line-1 c-fill-1"
                          d="M1 14.0148C1.55228 14.0148 2 13.5666 2 13.0137C2 12.4609 1.55228 12.0127 1 12.0127C0.447715 12.0127 0 12.4609 0 13.0137C0 13.5666 0.447715 14.0148 1 14.0148Z"
                          fill="#D1433A"></path>
                  </svg>
                </div>
                Follow {{ item.subscriber_count ? item.subscriber_count : 0 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemOptionList from "@/components/Common/Elements/ItemOptionList.vue";
import Vue from "vue";
import { followOrUnFollowPlaylist } from "../../../../apis/APIs";
import { log } from "../../../utils/Utils";

export default {
  name: "SongOrArtistCardWithSquareImage",
  data() {
    return {
      favorite: false,
      spinner: false,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    buy: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    getListOptionFavorite() {
      if (
        this.item.id === this.getListOptionFavoriteId ||
        this.item.reviewable_id === this.getListOptionFavoriteId
      ) {
        this.favorite = this.getListOptionFavorite;
      }
    },
  },

  computed: {
    getListOptionFavorite() {
      return this.$store.getters.getListOptionFavorite;
    },
    getListOptionFavoriteId() {
      return this.$store.getters.getListOptionFavoriteId;
    },
    currentPlayer() {
      return this.$store.getters.getCurrentPlayer;
    },
    currentPlayId() {
      return this.$store.getters.getCurrentPlayId;
    },
    songStatus() {
      return this.$store.getters.getSongsStatus;
    },
    currentPlayTypeID() {
      return this.$store.getters.getCurrentPlayTypeId;
    },
  },
  components: {},

  mounted() {
    if (this.item) {
      this.favorite = this.item.favorite;
    }
  },
  methods: {
    getPlayListArtist(artist) {
      return artist && artist.creator ? artist.creator[0].name  : "Unknown";
    },
    handlefollow(item){
      const payload={
        playlist_id : item.id,
        action: true
      }
      followOrUnFollowPlaylist(payload)
    },
    async addToLoveToggle(e, song) {
      this.spinner = true;
      const res = await this.setFavourite(e, song);
      if (res.success === 1) {
        console.log('hete', 'ij');
        this.favorite = this.favorite === true ? false : true;
        console.log(this.favorite, 'favoi');
        if (song.favorite){
          this.item.subscriber_count ++
        } else {
          this.item.subscriber_count --
        }
      }
      this.spinner = false;
    },
    getHeight: function(e, ei) {
      e.stopPropagation();
      this.showOption = !this.showOption;
      if (this.lastWidth != e.screenX) {
        this.showOption = true;
        this.lastWidth = e.screenX;
      }
      console.log(e.screenX, "screen x");
      const height = 112;
      this.optionWidth =
        parseInt(e.screenX) - this.$refs.busstop.getBoundingClientRect().left;
      this.optionHeight = parseInt(height);
    },
    isHoveringFun: function(val) {
      Vue.set(this.item, "isHovering", val);
    },
    // playPause: function (e, songs) {
    //   e.currentTarget.classList.toggle('active');
    //   this.song_status = !this.song_status;
    //   if(this.album_push_status === 0){
    //     this.album_push_status = 1;
    //     this.songs_array = songs
    //   }else if(this.album_push_status === 1){
    //     this.songs_array = [];
    //   }
    //   this.$store.commit('Set_Continue_Listening_Song', {data : this.songs_array, status : this.song_status});
    //   //this.$root.$emit('eventtriggered', songs)
    // },
  },
};
</script>

<style scoped lang="scss">

.sub-title-text{
  font-weight: 400 !important;
  color: #47505B !important;
  font-size: 12px !important;
  font-family: HelveticaNeueNormal;
}

.text {
  display: flex;
  gap: 10px;
  align-items: center;
}

.card-follow{
  width: 130px !important;
  margin: 20px auto 10px auto;
}

.followers {
  margin-top: 16px;
  margin-bottom: 8px;
  border: 1px solid #D1433A;
  border-radius: 4px;
  color: #D1433A;
  width: 100%;
  height: 30px;
  display: flex;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.channel-card-cover:hover .top-week-album-img-hover {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
      180deg,
      rgba(7, 21, 38, 0.3588) 0%,
      rgba(7, 21, 38, 0.69) 100%
  );
}
.song-album-card {
  max-width: 170px;
  width: 100%;
  .song-album-square-card {
    max-width: 170px;
    width: 100%;
    border: 1px solid #e6eaf0 !important;
    .card {
      border: none !important;

      .channel-card-cover {
        position: relative;
        min-height: 170px;
        img {
          height: 170px !important;
        }
      }
      .card-rating {
        cursor: pointer;
        padding: 10px !important;
        color: #ffffff;
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: 12px !important;
        font-weight: 400 !important;
        background: #8b949f;
        border-radius: 4px;
        height: 20px;
        align-items: center;
        display: flex;
      }

      .price {
        .price-text {
          cursor: pointer;
          border-radius: 12% !important;
          padding: 0.125rem 0.25rem;
          color: #071526;
          font-weight: 700;
          background-color: #D1433A1A;
        }
      }

      .play-pause-icon {
        cursor: pointer;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: +9;
      }

      .like-icon {
        cursor: pointer;
        top: 0;
        bottom: 0;
        left: 0;
        right: 5.5rem;
        margin: auto;
        z-index: +8;

        .svg-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          .fill-icon {
            fill: #fff;
          }
        }
      }

      .list-icon {
        cursor: pointer;
        top: 0;
        bottom: 0;
        right: 0;
        left: 5.5rem;
        margin: auto;
        z-index: +8;

        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
</style>
<style scoped>
.spinner-color {
  color: #fff !important;
}
</style>
<!--class="-->
<!--play-button-->
<!--js-play-button-->
<!--new-play-button-->
<!--pg-new-play-button-->
<!--position-absolute-->
<!--play-pause-icon-->
<!--"-->
<!--:class="{ 'd-block': item.isHovering }"-->