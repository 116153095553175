<template>
  <div class="widget-recent recent-review-theme" style="margin-bottom: 50px;">
    <div class="widget-title">
      {{ pageTitle }}
      <div class="link-more near_arrow without-slider in-title cursor-pointer">
        <a @click="redirectToChannel(channelType, altName, pageTitle)"
          >show all
          <span class="sl-arrow"
            ><img
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgY2xhc3M9InN2Zy1hcnJvdy1yaWd0aCIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZD0iTTEuMjkzNTggMS4zOTQ3OEw3LjM0NTUgNi4zMTI5MkwxLjI5MzU4IDExLjY2MzYiICBzdHJva2U9IiM0NzUwNUIiICBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIg0KICAgICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg0KDQo="/></span
        ></a>
      </div>
    </div>
    <div v-for="(row, index) in listData.slice(0, 3)" :key="index">
      <div class="widget-item">
        <div class="cover-image">
          <div class="img-wrapper">
            <img
            class="w-100 h-100" style="object-fit: cover;"
              :src="
                row.reviewable && row.reviewable.artwork_url
                  ? row.reviewable.artwork_url
                  : 'https://auddiodev-api.theplayground.co.uk/common/default/default-profile.jpg'
              "
              alt=""
            />
          </div>
          <div class="status">
            <svg
              width="8"
              height="7"
              viewBox="0 0 8 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.94219 5.26891L1.81219 6.81691L2.62619 4.30991L0.492188 2.76091H3.12819L3.94219 0.253906L4.76019 2.76091H7.39219L5.26219 4.30991L6.07619 6.81691L3.94219 5.26891Z"
                fill="white"
              ></path>
            </svg>
          </div>
        </div>
        <div class="item-desc">
          <div class="name">
            {{
              row.reviewable_user && row.reviewable_user.name
                ? row.reviewable_user.name
                : "User Name"
            }}
          </div>
          <div class="info">
            <div>
              <span class="artist-name">
                {{
                  row.reviewable &&
                  row.reviewable.page &&
                  row.reviewable.page.title
                    ? row.reviewable.page.title
                    : "Artist Name"
                }}
              </span>
              -
              {{
                row.reviewable && row.reviewable.title
                  ? row.reviewable.title
                  : "Track Name"
              }}
            </div>
          </div>
        </div>
        <div class="action">
          <div class="svg-icon mr-3">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="c-line-1"
                d="M11.8894 1H14.0898C14.8899 1 15.19 1.3 15.19 2V7.4C15.19 8.1 14.9899 8.5 14.0898 8.5H11.8894M6.48847 9.6V12.8C6.47982 13.0829 6.5275 13.3646 6.62874 13.6289C6.72999 13.8932 6.88277 14.1347 7.07821 14.3394C7.27365 14.5441 7.50785 14.708 7.76718 14.8214C8.02652 14.9348 8.30581 14.9955 8.58883 15L11.7894 8.5V1H3.88803C2.68783 1 2.18774 2.5 2.08773 3.2L1.08756 8.2C1.04785 8.34446 1.03707 8.49533 1.05583 8.64396C1.07458 8.79259 1.1225 8.93606 1.19684 9.06613C1.27118 9.19621 1.37047 9.31032 1.48903 9.40194C1.60758 9.49355 1.74306 9.56086 1.88769 9.6H6.48847V9.6Z"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="svg-icon" @click="addLike">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="c-line-1 c-fill-1"
                d="M4.25523 15H2.05486C1.25472 15 1.05469 14.7 1.05469 14V8.6C1.05469 7.9 1.25472 7.5 2.15487 7.5H4.35524M9.65614 6.4V3.2C9.66479 2.91713 9.61711 2.63536 9.51587 2.37108C9.41463 2.1068 9.26184 1.86528 9.0664 1.66056C8.87096 1.45585 8.63676 1.29203 8.37743 1.17863C8.1181 1.06523 7.8388 1.00451 7.55578 1V1L4.35524 7.5V15H12.2566C13.4568 15 13.9569 13.5 14.0569 12.8L15.0571 7.8C15.0968 7.65554 15.1075 7.50467 15.0888 7.35604C15.07 7.20741 15.0221 7.06394 14.9478 6.93387C14.8734 6.80379 14.7741 6.68968 14.6556 6.59806C14.537 6.50645 14.4015 6.43914 14.2569 6.4H9.65614Z"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="review">
        <div class="cover-image">
          <div class="svg-icon">
            <svg
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.7">
                <path
                  d="M6.28807 8.85556C5.87007 7.76056 5.2963 1.28658 9.6013 0.142578C9.7013 0.202578 9.5783 1.08958 9.6013 1.12958C7.9423 2.49858 8.1013 5.61856 8.1013 5.61856C8.53476 5.52316 8.98765 5.57984 9.38426 5.77905C9.78087 5.97827 10.097 6.30791 10.2793 6.71259C10.5423 7.24137 10.5847 7.85292 10.3972 8.41296C10.2097 8.97301 9.80767 9.43576 9.27928 9.69958C9.03068 9.85313 8.75274 9.95334 8.46336 9.99387C8.17399 10.0344 7.87934 10.0143 7.59813 9.93497C7.31691 9.85561 7.05532 9.71868 6.82982 9.53287C6.60431 9.34706 6.41974 9.11641 6.28807 8.85556Z"
                  fill="#47505B"
                />
                <path
                  d="M0.288069 8.85556C-0.129931 7.76056 -0.703699 1.28658 3.6013 0.142578C3.7013 0.202578 3.5783 1.08958 3.6013 1.12958C1.9423 2.49858 2.1013 5.61856 2.1013 5.61856C2.53476 5.52316 2.98765 5.57984 3.38426 5.77905C3.78087 5.97827 4.09701 6.30791 4.27928 6.71259C4.54232 7.24137 4.58469 7.85292 4.3972 8.41296C4.20971 8.97301 3.80767 9.43576 3.27928 9.69958C3.03068 9.85313 2.75274 9.95334 2.46336 9.99387C2.17399 10.0344 1.87934 10.0143 1.59813 9.93497C1.31691 9.85561 1.05532 9.71868 0.829817 9.53287C0.604309 9.34706 0.419737 9.11641 0.288069 8.85556Z"
                  fill="#47505B"
                />
              </g>
            </svg>
          </div>
        </div>
        <div class="item-desc">
          <div class="info">
            <div>
              <span class="description">
                {{ row.comment ? row.comment : "Comments" }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {
      listing_type: "grid",
    };
  },

  components: {},
  computed: {
    getUserName() {
      return this.$store.getters.user_map_to_props.user_data
        ? this.$store.getters.user_map_to_props.user_data.name +
            " " +
            this.$store.getters.user_map_to_props.user_data.last_name
        : "";
    },
  },
  props: {
    altName: String,
    pageTitle: String,
    channelType: {
      type: String,
      default: null,
    },
    pageFrom: String,
    listData: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    showForm: function(type) {
      this.listing_type = type;
    },
    redirect: function(url) {
      this.$router.push({ name: "music." + this.redirectLink });
    },
    addLike: function(url) {
      //alert('hi');
    },
  },
};
</script>
<style scoped>
.widget-item {
  justify-content: normal !important;
  margin-top: 5px !important;
  padding-top: 5px;
}

.widget-item .action {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: auto !important;
  cursor: pointer;
  transition: all 0.3s;
}

.item-desc .name {
  color: #7e858c !important;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 5px;
}

.item-desc .info {
  color: #071526 !important;
  font-family: HelveticaNeueNormal, sans-serif;
  font-weight: 400;
}
.item-desc .info span {
  font-weight: 400;
  font-family: HelveticaNeueBold, sans-serif;
}

.artist-name {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #071526;
}
.description {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #7e858c;
  opacity: 0.7;
}
.review {
  display: flex;
  flex-direction: row;
  padding-left: 40px;
  padding-right: 20px;
  transition: 0.3s all;
  margin-top: 5px;
}
.review .cover-image {
  height: 100%;
  margin-right: 10px;
}
.review .item-desc .description {
  color: #47505b;
  opacity: 0.7;
  font-family: HelveticaNeueNormal, sans-serif;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 14px;
}

@media screen and (min-width: 2560px) {
  .item-desc .name {
    font-size: 1.2rem;
  }
  .item-desc .info {
    font-size: 1.2rem;
  }

  .artist-name {
    font-size: 1.2rem;
  }

  .review {
    padding-left: 55px;
  }
  .description {
    font-size: 1rem;
  }
}
</style>
