<template>
  <div class="podcast-featured h-100">
    <div class="item d-flex flex-row h-100">
      <div class="left-block">
        <div class="cover"><img :src="getImage(item)" alt="" /></div>
        <div class="play-button">
          <div class="svg-icon">
            <svg
              width="20"
              height="24"
              viewBox="0 0 20 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="svg-play"
            >
              <path
                d="M2.54164 23C2.30344 22.9942 2.06951 22.9372 1.85664 22.8332C1.61456 22.6948 1.41033 22.5019 1.26122 22.2706C1.11211 22.0393 1.02251 21.7765 1 21.5044V2.51033C1.01292 2.24566 1.08944 1.98761 1.22343 1.75686C1.35742 1.52612 1.54515 1.32909 1.77158 1.18156C2.00949 1.05016 2.28117 0.987834 2.55442 1.00196C2.82766 1.0161 3.091 1.10609 3.31323 1.26129L18.315 10.7561C18.5258 10.8937 18.6986 11.0796 18.8181 11.2974C18.9375 11.5153 19 11.7583 19 12.0051C19 12.252 18.9375 12.495 18.8181 12.7129C18.6986 12.9307 18.5258 13.1166 18.315 13.2542L3.31323 22.7505C3.08759 22.907 2.81876 22.9939 2.54164 23Z"
                fill="#D1433A"
                stroke="#D1433A"
                stroke-width="2"
                class="c-line-1 c-fill-1"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div
        class="right-block flex-column align-items-center justify-content-start"
      >
        <div v-if="type === 'talk'" class="talk-tag">
          <img
            :src="
              require(`@/assets/icons/desgin-guide/music/components/podcast-featured/${talkType}.svg`)
            "
            :alt="talkType"
          />
        </div>
        <div v-else class="tag-els">#Electronic</div>
        <div class="by-title">
          <span>by</span> 
          <span class="pocaster-name">{{ podcaster }}</span>
        </div>
        <div class="title">{{ podcastTitle }}</div>
        <div class="subtitle">Episode #34</div>
        <div class="text">{{ description }}</div>
        <div class="price-like">
          <div class="heat-ic">
            <svg
              width="18"
              height="16"
              viewBox="0 0 18 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.7545 2.2374C15.3623 1.8451 14.8966 1.53391 14.3841 1.32159C13.8716 1.10928 13.3223 1 12.7675 1C12.2128 1 11.6635 1.10928 11.1509 1.32159C10.6384 1.53391 10.1728 1.8451 9.78052 2.2374L8.96599 3.05193L8.15145 2.2374C7.35387 1.4716 6.28787 1.04896 5.18222 1.0602C4.07657 1.07144 3.01937 1.51565 2.23752 2.2975C1.45566 3.07936 1.01145 4.13655 1.00022 5.2422C0.988983 6.34785 1.41162 7.41385 2.17742 8.21144L2.99195 9.02597L8.96599 15L14.94 9.02597L15.7545 8.21144C16.1469 7.8192 16.458 7.35352 16.6704 6.84101C16.8827 6.32849 16.992 5.77917 16.992 5.22442C16.992 4.66967 16.8827 4.12035 16.6704 3.60783C16.458 3.09531 16.1469 2.62964 15.7545 2.2374Z"
                stroke="#47505B"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <div class="price-total" v-if="!stream">
            <div class="pric-de">$0.99</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PodcastFeatured",
  props: {
    item: {
      type: Object,
      required: true,
    },
    stream: {
      type: Boolean,
      default: false,
    },
    talkType: {
      type: String,
      default: "Music production",
    },
  },
  computed: {
    podcastTitle() {
      if (this.item && this.item.title) {
        return this.item.title;
      }
      return "Podcast Title";
    },
    podcaster() {
      if (this.item && this.item.page) {
        return this.item.page.title;
      }
      return "Unknown";
    },
    description() {
      if (
        this.item &&
        this.item.description &&
        this.item.description !== "null"
      ) {
        return this.item.description;
      }
      return "";
    },
    type() {
      if (this.item && this.item.type && this.item.type.includes("Talk")) {
        return "talk";
      }
      return "music";
    },
  },
};
</script>

<style scoped lang="scss">
.podcast-featured {
  .item {
    .left-block {
      .cover {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .right-block {
      .talk-tag {
      }
    }
  }
}
</style>
