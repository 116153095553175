<template>
  <div class="widget-follow widgt-friend fixFont">
    <div class="widget-title">
      {{ title }}
      <!-- <div class="count-follower">27</div> -->

      <!-- show all -->
      <div v-if="currentPageFollowers.length > 0" class="link-more near_arrow without-slider in-title pointerClass"><a v-on:click="changeTab">show All
          <span class="sl-arrow"> <img
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgY2xhc3M9InN2Zy1hcnJvdy1yaWd0aCIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZD0iTTEuMjkzNTggMS4zOTQ3OEw3LjM0NTUgNi4zMTI5MkwxLjI5MzU4IDExLjY2MzYiICBzdHJva2U9IiM0NzUwNUIiICBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIg0KICAgICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg0KDQo=">
          </span>
        </a>
      </div>
    </div>

    <div v-if="currentPageFollowers.length > 0">
    <div v-for="(followers, index) in currentPageFollowers.slice(0, 3)" :key="index" class="widget-item max-width"
      @click="redirectWidgetsToProfilePage($event, followers.username ? followers.username : '')">
      <div class="cover-image">
        <div class="img-wrapper"><img :src="(followers && followers.artwork_url) ? followers.artwork_url : ''"
            width="100" alt="" style="width: inherit">
        </div>
        <div class="status">
          <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3.94219 5.26891L1.81219 6.81691L2.62619 4.30991L0.492188 2.76091H3.12819L3.94219 0.253906L4.76019 2.76091H7.39219L5.26219 4.30991L6.07619 6.81691L3.94219 5.26891Z"
              fill="white"></path>
          </svg>
        </div>
      </div>
      <div class="item-desc">
        <div class="name" style="font-family: HelveticaNeueMedium, sans-serif;">{{ (followers && (followers.name &&
          followers.last_name)) ? followers.name + ' ' + followers.last_name : 'User Name'}}</div>
        <div class="info">
          <p>Followed 6 hours ago</p>
        </div>
      </div>
      <div class="action" :class="[(followers.follow_status === 1) ? 'active' : '']">
        <div class="svg-icon">
          <div class="svg-icon">
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"
              @click="changeFollowStatus(followers.id, forUser === 'true' ? 'user' : artistFollowerUserType, (followers.follow_status === 0) ? 1 : 0, followers)">
              <path class="c-fill_add-user"
                d="M11.6304 13.6049V12.3512C11.6304 11.6862 11.3663 11.0484 10.896 10.5782C10.4258 10.1079 9.78799 9.84375 9.12297 9.84375H4.10805C3.44303 9.84375 2.80525 10.1079 2.335 10.5782C1.86476 11.0484 1.60059 11.6862 1.60059 12.3512V13.6049"
                stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              <path class="c-fill_add-user"
                d="M6.61488 7.33524C7.99972 7.33524 9.12235 6.21261 9.12235 4.82778C9.12235 3.44294 7.99972 2.32031 6.61488 2.32031C5.23005 2.32031 4.10742 3.44294 4.10742 4.82778C4.10742 6.21261 5.23005 7.33524 6.61488 7.33524Z"
                stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              <path class="c-fill_add-user" d="M12.8613 1.60156V5.63632" stroke="#D1433A" stroke-width="1.5"
                stroke-linecap="round" stroke-linejoin="round"></path>
              <path class="c-fill_add-user" d="M10.9131 3.61719H14.9478" stroke="#D1433A" stroke-width="1.5"
                stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else style="margin-top: 10px;">No followers</div>
  </div>
</template>
<script type="application/javascript">
export default {
  props: {

    title: {
      type: String,
      default: "Followers"
    },
    forUser: {
      type: Boolean
    }
  },
  data() {
    return {}
  },
  components: {},
  computed: {
    currentPageFollowers() {
      return this.$store.getters.getArtistFollowerList
    },
    artistFollowerUserType() {
      return this.$store.getters['getArtistFollowerUserType']
    }
  },
  methods: {
    changeTab: function () {
      this.$parent.change_active_tab('friends');
    },
    changeFollowStatus(user_id, object_type, action, artistFollower) {
      let payload = {
        user_id: user_id,
        page_id: this.$route.params.id,
        object_type: object_type,
        action: action,
        user: artistFollower
      }
      this.$store.dispatch('pageChangeUserFollowStatus', payload);
    }
  }
}
</script>
