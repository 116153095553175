<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="step">2. Update Info</div>
      </div>
    </div>
    <div class="info_status">
      <div class="row">
        <div class="col">
<!--          <h1 class="info_heading">Location</h1>-->
        </div>
      </div>
      <div v-if="message" class="alert alert-danger" role="alert">
        {{ message }}
      </div>
      <div v-if="success_message" class="alert alert-success" role="alert">
        {{ success_message }}
      </div>
      <div class="row">
        <div class="col">
          <div class="fields_container">
            <label class="label-title">Country</label>
            <SelectDropdown
              :options="countries"
              v-model="country"
              @change="((value) => country = value)"
              :style="'width: 570px; border: 1px solid #E6EAF0;'"
              placeholder="Country"
              hideCrossIcon
            />
            <div class="dropdown-svg-icon">
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L5 5L9 1"
                  stroke="#071526"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <label class="error_msg error_msg_red"
                   v-if="_handleErrorMessage('country')">{{ _handleErrorMessage("country") }}</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="fields_container">
            <label class="label-title">Current City</label>
            <input type="text" class="input-field" v-model="current_city" placeholder="Current City">
            <label class="error_msg error_msg_red"
                   v-if="_handleErrorMessage('current_city')">{{ _handleErrorMessage("current_city") }}</label>
          </div>
        </div>
        <div class="col">
          <div class="fields_container">
            <label class="label-title">Hometown</label>
            <input type="text" class="input-field" v-model="hometown" placeholder="Hometown">
            <label class="error_msg error_msg_red"
                   v-if="_handleErrorMessage('hometown')">{{ _handleErrorMessage("hometown") }}</label>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="info_status">-->
<!--      <div class="row">-->
<!--        <div class="col">-->
<!--          <h1 class="info_heading mt_10">Work</h1>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div class="col">-->
<!--          <div class="fields_container">-->
<!--            <label class="label-title">Work Title</label>-->
<!--            <input type="text" class="input-field" v-model="work_title" placeholder="Work Title">-->
<!--            <label class="error_msg error_msg_red"-->
<!--                   v-if="_handleErrorMessage('work_title')">{{ _handleErrorMessage("work_title") }}</label>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div class="col">-->
<!--          <div class="fields_container">-->
<!--            <label class="label-title">Work Place</label>-->
<!--            <input type="text" class="input-field" v-model="work_place" placeholder="Work Place">-->
<!--            <label class="error_msg error_msg_red"-->
<!--                   v-if="_handleErrorMessage('work_place')">{{ _handleErrorMessage("work_place") }}</label>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col">-->
<!--          <div class="fields_container">-->
<!--            <label class="label-title">Work Website</label>-->
<!--            <input type="text" class="input-field" v-model="website_url" placeholder="Work Website">-->
<!--            <label class="error_msg error_msg_red" v-if="!$v.website_url.url">Invalid URL</label>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div style="height: 1px; background-color: #E6EAF0; margin-top: 10px; margin-bottom: 15px;"></div>
    <div class="info_status">
      <div class="row">
        <div class="col">
<!--          <h1 class="info_heading">Education</h1>-->
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="fields_container">
            <label class="label-title">Major</label>
            <input type="text" class="input-field" v-model="major" placeholder="Major">
            <label class="error_msg error_msg_red"
                   v-if="_handleErrorMessage('major')">{{ _handleErrorMessage("major") }}</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="fields_container mb_0">
            <label class="label-title">School</label>
            <input type="text" class="input-field" v-model="school" placeholder="School">
            <label class="error_msg error_msg_red"
                   v-if="_handleErrorMessage('school')">{{ _handleErrorMessage("school") }}</label>
          </div>
        </div>
        <div class="col">
          <div class="fields_container mb_0">
            <label class="label-title">Class</label>
            <input type="text" class="input-field" v-model="class1" placeholder="Class">
            <label class="error_msg error_msg_red"
                   v-if="_handleErrorMessage('class1')">{{ _handleErrorMessage("class1") }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5 mt_30">
        <div class="skip_btn">
          <button @click="nextTab('genres', '1')">SKIP</button>
        </div>
      </div>
      <div class="col-md-7 mt_30">
        <div class="next_step_btn">
          <button v-on:click="submit()">
            {{ loading ? "Loading..." : "NEXT STEP" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import * as Helper from '../../../apis/Helper.js';
import { get_countries_list, getCountries, update_information } from "../../../apis/APIs";
import {SUCCESS, ERROR, USER_DATA} from '../../../store/types';
import {url} from "vuelidate/lib/validators";
import SelectDropdown from '../../Common/Dropdown/SelectDropdown.vue';

export default {
  data() {
    return ({
      country: null,
      current_city: "",
      hometown: "",
      work_title: "",
      work_place: "",
      website_url: "",
      major: "",
      school: "",
      class1: "",

      message: "",
      success_message: "",

      errors: [],
      loading: false
    });
  },
  components: {
    SelectDropdown
  },
  validations: {
    website_url: {
      url,
    }
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    }),
    countries() {
      return this.$store.getters.getCountries.map((country) => {
        return {
          value: country.id,
          label: country.name
        }
      })
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    nextTab(tab, a) {
      this.$parent.nextTab(tab, a)
    },
    init() {
      const {user_data} = this.user || {};
      const {country, current_city, hometown, work_title, work_place, website_url, major, school, class1} = user_data;

      this.country = country;
      this.current_city = current_city;
      this.home_town = hometown;
      this.work_title = work_title;
      this.work_place = work_place;
      this.website_url = website_url,
          this.major = major;
      this.school = school;
      this.class1 = class1;
    },
    isError(key) {
      if (this.errors && this.errors.length) {
        return this.errors.findIndex(ele => ele.fieldName === key) > -1
            ? {
              status: true,
              message:
              this.errors[this.errors.findIndex(ele => ele.fieldName === key)].message
            }
            : {status: false, message: ""};
      } else return {status: false, message: ""};
    },
    _handleErrorMessage(key) {
      const {message = ""} = this.isError(key);

      return message;
    },
    submit(e) {
      if (e) e.preventDefault();

      const {
        loading,
        country,
        current_city,
        hometown,
        work_title,
        work_place,
        website_url,
        major,
        school,
        class1
      } = this;

      if (loading) return;

      const body = {
        country,
        current_city,
        hometown,
        // work_title,
        // work_place,
        // website_url,
        major,
        school,
        class1
      };


      const {user_data} = this.user || {};
      const {id} = user_data || {};

      Helper.validate(Object.keys(body), body)
          .then(async ({status, response}) => {
            if (status) {
              this.errors = []
              try {
                this.message = "";

                var formdata = new FormData();
                formdata.append("id", id);
                formdata.append("country", country);
                formdata.append("hometown", hometown);
                formdata.append("current_city", current_city);
                // formdata.append("work_title", work_title);
                // formdata.append("website_url", website_url);
                // formdata.append("work_place", work_place);
                formdata.append("school", school);
                formdata.append("class1", class1);
                formdata.append("major", major);

                // const query = `?country=${country}&hometown=${hometown}&current_city=${current_city}&work_title=${work_title}&website_url=${website_url}&work_place=${work_place}&school=${school}&class1=${class1}&major=${major}&id=${id}`;

                this.loading = true;
                const {data} = await update_information(formdata);
                const {success, message, information} = data
                this.loading = false;
                switch (success) {
                  case 1:
                    this.$store.commit("updateUserUIConstraints", ({
                      [USER_DATA]: information
                    }));
                    this.success_message = message;
                    setTimeout(() => this.success_message = "", 5000);
                    this.$parent.update_info_submit = true;
                    this.nextTab('genres', '2')
                    break;
                  case 0:
                    this.message = message;
                    break;
                }
              } catch (e) {
                this.loading = false;
                this.message = e && e.message ? e.message : "Please try again.";
              }
            } else this.errors = response && response.length ? response : [];
          }).catch(err => console.log(err));
    }
  }
}
</script>
<style scoped lang="scss">

.dropdown-svg-icon {
  position: absolute;
  right: 23px;
  top: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fields_container input::placeholder {
  font-size: 12px;
  font-weight: normal;
  color: #8b949f;
  font-family: HelveticaNeueNormal, sans-serif !important;
  letter-spacing: 0.5px;
}

.pla{
  font-size: 12px;
  font-weight: normal;
  color: #8b949f;
  font-family: HelveticaNeueNormal, sans-serif !important;
  letter-spacing: 0.5px;
}

</style>
