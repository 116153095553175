<template>
  <div :class="{'page-or-artist-card': true, 'h-290': item && (item.type === 'artist' || item.type === 'company'), 'h-270': item && (item.type === 'clubroom' || item.type === 'partyroom')}"
    @click="redirectToPage()"
  >
    <div class="page-or-artist-card-avatar-image-and-verified-section" >
      <div class="page-or-artist-card-avatar-image">
        <img :src="profile" alt="" />
      </div>
      <div class="page-or-artist-card-verified-icon">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#D1433A"/>
          <path d="M14 7L8.5 12.5L6 10" stroke="white" stroke-width="2" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <div class="page-or-artist-card-name-and-description-section">
      <div class="page-or-artist-card-name">
        <span class="text-truncate">
          {{ truncateText(type === "song" && item.title ? item.title : type === "review" && item.reviewable ? item.reviewable.title : "" , 12) }}
        </span>
      </div>
      <div class="page-or-artist-card-description">
        <span v-if="item && (item.type === 'artist' || item.type === 'company')"
          class="page-or-artist-card-category text-truncate" >
          #{{ truncateText(category + (subCategory ? ', ' + subCategory : ''), 20) }}
        </span>
        <span v-else class="page-or-artist-card-members-count">
          {{(item && item.members) ? item.members + ' members' : '0 member'}}
        </span>
      </div>
    </div>
    <div :class="{'page-or-artist-card-rating-and-buttons-section': true, 'mt-auto': item && (item.current_user !== 'admin')}">
      <div class="page-or-artist-card-rating-section"
           v-if="(item && (item.type === 'artist' || item.type === 'company'))"
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.55653 0.494009C5.69064 0.0597346 6.30505 0.0591306 6.44 0.493147L7.613 4.2655H11.3693C11.8115 4.2655 12.0015 4.82658 11.6504 5.09534L8.59534 7.43377L9.76586 11.2242C9.89851 11.6538 9.40241 12.001 9.04437 11.7291L6.00045 9.4181L2.97053 11.7373C2.61284 12.0111 2.11467 11.6639 2.2476 11.2335L3.41801 7.4434L0.348437 5.09543C-0.0028397 4.82673 0.187148 4.2655 0.629383 4.2655H4.39186L5.55653 0.494009Z"
            fill="white"
          />
        </svg>
        <span class="page-or-artist-card-rating">
          {{
            type === "review"
              ? item.rating
              : (type === "song" || type === "album") && item.avg > 0
                ? item.avg
                : "0.0"
          }}
        </span>
      </div>
      <div class="page-or-artist-card-rating-buttons-section">
        <b-spinner v-if="spinner"
          class="page-or-artist-card-rating-spinner" small label="Small Spinner"
        />
        <div :class="{'page-or-artist-card-follow-and-following': true, 'active': follow === 'approved'}"
             v-if="(item && (item.type === 'artist' || item.type === 'company')) && (!spinner && item.current_user !== 'admin')"
             @click="followUnfollow(type === 'review' ? item.reviewable : item, $event)"
        >
          <svg v-if="follow === 'approved'"
            width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.3346 1L5.0013 8.33333L1.66797 5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <svg v-else
            width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 8.00951C3.757 8.00951 6 10.2548 6 13.0147C6 13.5672 6.448 14.0157 7 14.0157C7.552 14.0157 8 13.5672 8 13.0147C8 9.15068 4.86 6.00745 1 6.00745C0.448 6.00745 0 6.45591 0 7.00848C0 7.56104 0.448 8.00951 1 8.00951Z" fill="#D1433A"/>
            <path d="M1 2.00206C7.0655 2.00206 12 6.94164 12 13.0134C12 13.566 12.448 14.0144 13 14.0144C13.552 14.0144 14 13.566 14 13.0134C14 5.83801 8.1685 0 1 0C0.448 0 0 0.448461 0 1.00103C0 1.5536 0.448 2.00206 1 2.00206Z" fill="#D1433A"/>
            <path d="M1 14.0149C1.55228 14.0149 2 13.5667 2 13.0139C2 12.461 1.55228 12.0128 1 12.0128C0.447715 12.0128 0 12.461 0 13.0139C0 13.5667 0.447715 14.0149 1 14.0149Z" fill="#D1433A"/>
          </svg>
          <span v-if="follow === 'approved'"
                class="page-or-artist-card-following"
          >
            {{ follow === "pending" ? "Pending" : "Following" }}
          </span>
          <span v-else
                class="page-or-artist-card-follow"
          >
            Follow {{ total_followers &&  10 > total_followers ? ('0' + total_followers) : total_followers && total_followers > 9 ? total_followers : '00' }}
          </span>
        </div>
        <div :class="{ 'page-or-artist-card-join-and-joined': true, 'active': item.join_status === 'approved' }"
             v-if="(item && (item.type === 'clubroom' || item.type === 'partyroom')) && (!spinner && item.current_user !== 'admin')"
             @click="joinPage(item.join_status)"
        >
          <svg v-if="section !== 'my_pages'"
            width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_6583_3974)">
              <path d="M10.6667 14V12.6667C10.6667 11.9594 10.3858 11.2811 9.8857 10.781C9.3856 10.281 8.70733 10 8.00008 10H3.33341C2.62617 10 1.94789 10.281 1.4478 10.781C0.9477 11.2811 0.666748 11.9594 0.666748 12.6667V14" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.66667 7.33333C7.13943 7.33333 8.33333 6.13943 8.33333 4.66667C8.33333 3.19391 7.13943 2 5.66667 2C4.19391 2 3 3.19391 3 4.66667C3 6.13943 4.19391 7.33333 5.66667 7.33333Z" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.3333 5.33331V9.33331" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.3333 7.33331H11.3333" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_6583_3974">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <span class="page-or-artist-card-joined">
            {{(section === 'my_pages') ? 'More': (item && item.join_status === 'approved') ? 'Joined' : 'Join'}}
          </span>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { followOrUnFollowPage, requestToJoinClubPage } from "../../../apis/APIs";
import { mapGetters } from "vuex";
import createFormData, { truncateText } from "../../../mixins/common";

export default {
  name: "PageOrArtistCard",
  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: "song",
    },
    section: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      follow: "",
      spinner: false,
      total_followers: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "user_map_to_props",
    }),
    category() {
      if (this.type === "review") {
        const { page_category } = this.item.reviewable;
        return page_category && page_category.description
          ? page_category.description
          : "";
      } else {
        const { page_category } = this.item;
        return page_category && page_category.description
          ? page_category.description
          : "";
      }
    },
    subCategory() {
      if (this.type === "review") {
        const { page_sub_category } = this.item.reviewable;
        return page_sub_category && page_sub_category.description
          ? page_sub_category.description
          : "";
      } else {
        const { page_sub_category } = this.item;
        return page_sub_category && page_sub_category.description
          ? page_sub_category.description
          : "";
      }
    },
    profile() {
      if (this.type === "review") {
        const { avatar_image } = this.item.reviewable;
        return avatar_image && avatar_image[0] && avatar_image[0].original_url
          ? avatar_image[0].original_url
          : require(`@/assets/img/photo-1.png`);
      } else {
        const { avatar_image } = this.item;
        return avatar_image && avatar_image[0] && avatar_image[0].original_url
          ? avatar_image[0].original_url
          : require(`@/assets/img/photo-1.png`);
      }
    },
    followerCount() {
      if (this.type === "review") {
        const { followers_count } = this.item.reviewable;
        return followers_count ? followers_count + (this.follow ? 1 : 0) : 0;
      } else {
        const { followers_count } = this.item;
        return followers_count ? followers_count + (this.follow ? 1 : 0) : 0;
      }
    },
  },
  mounted() {
    if (this.item) {
      if (this.type === "review") {
        this.follow = this.item.reviewable.follow_action;
      } else {
        this.follow = this.item.follow_action;
        this.total_followers = this.item.followers_count
      }
    }
  },
  methods: {
    truncateText,
    async followUnfollow(object, event) {
      if (event) event.stopPropagation();
      this.spinner = true;
      const res = await this.followOrUnfollowPage(object, this.follow);
      if (res.success === 1) {
        this.follow = this.follow === "approved" ? null : "approved";
        this.follow ? this.total_followers += 1 : this.total_followers -=1;
      }
      this.spinner = false;
    },
    async joinPage(status) {
      if (this.section !== 'my_pages') {
        try {
          await this.$store.dispatch('loading', true)
          const payload = {
            type: 'Page',
            type_id: this.item.id,
            action: (status === 'approved') ? 0 : 1
          }
          const formData = createFormData(payload)
          const {data} = await requestToJoinClubPage(formData)
          const {message, success} = data || {}
          if (success === 1 ) {
            this.notificationToast(true, 'Club Page', message, 5000, 'success');
          } else {
            this.notificationToast(true,"Club Page", message, 5000, 'error');
          }
          await this.$store.dispatch('loading', false)
        } catch (e) {
          await this.$store.dispatch('loading', false)
          this.notificationToast(true,"Club Page", message, 5000, 'error');
          console.error(e)
        }
      } else  {
        this.redirectToPage()
      }
    },

    redirectToPage() {
      let redirect_id = "";
      if (this.type === "review") {
        redirect_id = this.item.reviewable.id;
      } else {
        redirect_id = this.item.id;
      }
      if ((this.item.age_restrictions === 'over_18_only' && this.checkIfUserIsAbove18()) || this.item.age_restrictions === 'everyone') {
        if (this.item.type === "artist" || this.item.type === 'company') {
          this.$router.push(`/page-manager/page/${this.item.type}/${redirect_id}`);
          this.$store.commit("SET_PAGE_ACTIVE_TAB", "music");
        } else if (this.item.type === "clubroom") {
          this.$store.commit("SET_PAGE_ACTIVE_TAB", "posts");
          this.$router.push(`/page-manager/page/club/${redirect_id}`);
        } else if (this.item.type === "partyroom") {
          this.$store.commit("SET_PAGE_ACTIVE_TAB", "posts")
          this.$router.push(`/page-manager/page/party/${this.item.id}`)
        }
      } else {
        this.notificationToast(true,'Age Restriction', 'You are under 18 can not visit this page!', 5000, 'warning')
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/style/pages/pageorartistcard.scss";


.page-artist-card {
  width: 170px;
  height: 290px;
  border-radius: 2px;
  border: 1px Solid rgba(255, 255, 255, 1);

}
.spinner-color {
  color: #D1433A;
}
.join {
  &:hover {
    svg {
      path {
        stroke: #ffffff;
      }
    }
  }
}
.joined {
  background: #D1433A;
  .text {
    color: #ffffff!important;
    svg {
      path {
        stroke: #ffffff;
      }
    }
  }
}
.card-hover-effect:hover {
  background-color: #f7f8fa !important;
}
.card-description.card-hover-effect {
    background-color: unset;
}
.image-hover-effect:hover {
  transition: all 0.3s;
  opacity: 0.8;
}
</style>
