<template>
  <div class="row my-3">
    <div class="col-8">
      <div class="about-work bg-sec">
        <div class="row">
          <div class="col">
            <div class="about-work-text">
              <h1>Background</h1>
              <div v-if="userData.bio && userData.bio.length > 300 && showLess">
                <p>{{ userData.bio.slice(0, 300) }}...</p>
                <a href="javascript:;" @click="checkAbout">READ MORE</a>
              </div>
              <div
                v-else-if="
                  userData.bio && userData.bio.length > 300 && readMore
                "
              >
                <p v-if="userData.bio">{{ userData.bio }}</p>
                <a href="javascript:;" @click="checkAbout">SHOW LESS</a>
              </div>
              <div
                v-else-if="
                  userData.bio && userData.bio.length < 300 && userData.bio
                "
              >
                <p v-if="userData.bio">{{ userData.bio }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="about-work work-tab">
        <div class="row">
          <div class="col-12">
            <div class="total-projects">
              <h1>Projects</h1>
              <span>9</span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="project-detail">
              <div class="project-image">
                <img :src="require(`@/assets/img/project-1.png`)" />
              </div>
              <div class="song-type">
                <span>SONG</span><span class="dot"></span><span>MIXING</span>
              </div>
              <div class="song-mixing">Song Mixing</div>
              <div class="song-date">12.10.2020</div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="project-detail">
              <div class="project-image">
                <img :src="require(`@/assets/img/project-2.png`)" />
              </div>
              <div class="song-type">
                <span>ALBUM</span><span class="dot"></span
                ><span>PROCESSING</span>
              </div>
              <div class="song-mixing">Album Processing</div>
              <div class="song-date">12.10.2020</div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="project-detail">
              <div class="project-image">
                <img :src="require(`@/assets/img/project-3.png`)" />
              </div>
              <div class="song-type">
                <span>VIDEO</span><span class="dot"></span><span>OPERATOR</span>
              </div>
              <div class="song-mixing">Music Video shooting</div>
              <div class="song-date">12.10.2020</div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="project-detail">
              <div class="project-image">
                <img :src="require(`@/assets/img/project-4.png`)" />
              </div>
              <div class="song-type">
                <span>COVER</span><span class="dot"></span><span>DESIGN</span>
              </div>
              <div class="song-mixing">Album Yesterday cover</div>
              <div class="song-date">12.10.2020</div>
            </div>
          </div>
          <div class="col-12">
            <div class="show-all-list">
              <a href="javascript:;">SHOW 6 MORE</a>
            </div>
          </div>
        </div>
      </div>
      <div class="about-work work-tab">
        <div class="row">
          <div class="col-12">
            <div class="total-projects">
              <h1>Job history</h1>
              <span>6</span>
            </div>
          </div>
          <div class="col-12">
            <div class="job-history-detail">
              <div class="job-history-detail-description">
                <div class="job-heading">
                  Need to mix a song from the new album of a popular group in
                  United States
                </div>
                <div class="job-feedback">No Feedback Given</div>
                <div class="job-salary-detail">
                  <div class="job-price">2,400 USD</div>
                  <span class="dot"></span>
                  <div class="job-price">Fixed Price</div>
                  <span class="dot"></span>
                  <div class="job-price">June 2020 – July 2020</div>
                </div>
              </div>
            </div>
            <div class="job-history-detail">
              <div class="job-history-detail-description">
                <div class="job-heading">
                  Need interns-virtual assistance for Music Label-Agency
                </div>
                <div class="job-feedback-star">
                  <ul>
                    <li>
                      <img :src="require(`@/assets/img/star-rate.png`)" />
                    </li>
                    <li>
                      <img :src="require(`@/assets/img/star-rate.png`)" />
                    </li>
                    <li>
                      <img :src="require(`@/assets/img/star-rate.png`)" />
                    </li>
                    <li>
                      <img :src="require(`@/assets/img/star-rate.png`)" />
                    </li>
                    <li>
                      <img :src="require(`@/assets/img/star-rate.png`)" />
                    </li>
                  </ul>
                  <span>4,59</span>
                </div>
                <div class="job-salary-detail">
                  <div class="job-price">30 USD / hr</div>
                  <span class="dot"></span>
                  <div class="job-price">Hourly</div>
                  <span class="dot"></span>
                  <div class="job-price">
                    September 2019 – June 2020
                  </div>
                </div>
              </div>
            </div>
            <div class="job-history-detail">
              <div class="job-history-detail-description">
                <div class="job-heading">
                  Creating music covers- need music arrangement, mixing and
                  mastering
                </div>
                <div class="job-feedback">No Feedback Given</div>
                <div class="job-salary-detail">
                  <div class="job-price">2,400 USD</div>
                  <span class="dot"></span>
                  <div class="job-price">Fixed Price</div>
                  <span class="dot"></span>
                  <div class="job-price">June 2020 – July 2020</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="show-all-list">
          <a href="javascript:;">SHOW 3 MORE</a>
        </div>
      </div>
      <Experience 
        :experiences="experienceList"
        :experience-total="experienceTotal"
        :experience-limit="experiencesLimit"
        :experiences-offset="offset"
        :edit-able="false"
        :typeCheck="true"
        :user-data="userData"
        @update:experienceLimit="(value) => experiencesLimit = value"
        @update:experiencesOffset="(value) => offset = value"
        @showMoreExperience="fetchUserExperiencesAndEducations"
      />
      <Education 
        :educations="educationList"
        :education-total="educationTotal"
        :education-limit="educationsLimit"
        :education-offset="offset"
        :edit-able="false"
        :typeCheck="true"
        :user-data="userData"
        @update:educationLimit="(value) => educationsLimit = value"
        @update:educationOffset="(value) => offset = value"
        @showMoreEducation="fetchUserExperiencesAndEducations"
      />
    </div>
    <div class="col-md-4">
      <div class="work-ivite">
        <div class="download-resume">
          <button>Download RESUME AS .DOCX</button>
        </div>
      </div>
      <div class="statistics">
        <div class="stat-header">
          <h1>Statistics</h1>
          <div class="who-see">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.5 5.5H2.5C1.94772 5.5 1.5 5.94772 1.5 6.5V10C1.5 10.5523 1.94772 11 2.5 11H9.5C10.0523 11 10.5 10.5523 10.5 10V6.5C10.5 5.94772 10.0523 5.5 9.5 5.5Z"
                stroke="#8B949F"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.5 5.5V3.5C3.5 2.83696 3.76339 2.20107 4.23223 1.73223C4.70107 1.26339 5.33696 1 6 1C6.66304 1 7.29893 1.26339 7.76777 1.73223C8.23661 2.20107 8.5 2.83696 8.5 3.5V5.5"
                stroke="#8B949F"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p>ONLY YOU SEE THIS BLOCK</p>
          </div>
        </div>
        <div class="view-sections">
          <div class="view-today">
            <p>Views Today</p>
            <h1>140</h1>
          </div>
          <div class="search-appearance">
            <p>SEARCH APPEREANCES</p>
            <h1>230</h1>
          </div>
        </div>
      </div>
      <div class="work-skill">
        <div class="row">
          <div class="col-12">
            <div class="total-projects">
              <h1>Skills</h1>
              <span v-if="userData && userData.skills">{{userData.skills.length-1}}</span>
            </div>
          </div>
          <div class="col-12">
            <div class="skill-tags">
              <ul v-for="(skill, index) in userData.skills.filter(skill => skill !== '')" :key="index">
                <li><a href="javascript:;">#{{ skill }}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="work-skill">
        <div class="row">
          <div class="col-12">
            <div class="total-projects">
              <h1>{{ !otherUser ? 'My Availabillity' : 'Availability' }}</h1>
            </div>
          </div>
          <div class="col-12">
            <div class="availablity-work">
              <div class="item-period">
                <div class="svg-icon">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.2578 24.708C20.7807 24.708 25.2578 20.2309 25.2578 14.708C25.2578 9.18516 20.7807 4.70801 15.2578 4.70801C9.73496 4.70801 5.25781 9.18516 5.25781 14.708C5.25781 20.2309 9.73496 24.708 15.2578 24.708Z"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M15.2578 8.70801V14.708L19.2578 16.708"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                {{userData && userData.availability_time ? userData.availability_time.toUpperCase().split("_").join(" ") : ''}}
              </div>
              <div class="progress-wrapper">
                <div class="progress-outer">
                  <div class="progress-inner" style="width: 80%"></div>
                </div>
                80%
              </div>
              <div class="show-all-list mt_10">
                <a href="javascript:;">EDIT SETTINGS</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="work-skill">
        <div class="row">
          <div class="col-12">
            <div class="total-projects">
              <h1>Tools</h1>
              <span v-if="userData && userData.tools">{{userData.tools.length-1}}</span>
            </div>
          </div>
          <div class="col-12">
            <div class="tools-list">
              <ul v-for="(tool, index) in userData.tools.filter(tool => tool !== '')" :key="index">
                <li><a href="javascript:;">{{ tool }}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Experience from '/src/components/PageManager/Pages/Components/TabComponents/Experience.vue'
import Education from '/src/components/PageManager/Pages/Components/TabComponents/Education.vue'
import { get_user_work_api, get_user_education_api } from "../../../../apis/APIs.js"
import fetchApis from "@/utils/DynamicFunctionToCallApis";
import arrayToObjectByKey from "@/utils/ArrayToObjectByKey";
export default {
  name: "WorkTab",
  data() {
    return {
        showLess: true,
        readMore: false,
        experienceList: [],
        educationList: [],
        educationsLimit: 3,
        experiencesLimit: 3,
        offset: 0,
        educationTotal: 0,
        experienceTotal: 0
    }
  },
  components:{
    Experience,
    Education
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
    otherUser: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.fetchUserExperiencesAndEducations();
  },
  methods: {
    checkAbout() {
      if(this.readMore) {
        this.showLess = true;
        this.readMore = false;
      }
      else if (this.showLess) {
        this.readMore = true
        this.showLess = false
      }
    },
    setExperience(experience_data) {
      this.experience_data = experience_data
    },
    showMoreExperience(limit) {
      this.$emit("showMoreExperience", limit)
    },
    
    fetchUserExperiencesAndEducations(spinnerFor = 'about') {
      fetchApis( [this.fetchUserEducations(), this.fetchUserExperiences()], this.$store, false, true, spinnerFor).then((resp) => {

        const userEducations = arrayToObjectByKey(resp, 'user_educations');

        this.educationList = userEducations.data.data;
        this.educationTotal = userEducations.data.total;

        const userExperiences = arrayToObjectByKey(resp, 'user_experiences');

        if (userExperiences && userExperiences.data && userExperiences.data.data && userExperiences.data.data.length > 0) {
          this.experienceList = userExperiences.data.data.map((experience) => ({
            from_year: experience.from_year || "",
            to_year: experience.to_year || "",
            from_month: experience.from_month || "",
            to_month: experience.to_month || "",
            company_name: experience.company_name || "",
            description: experience.description || "",
            title: experience.title || "",
            location: experience.description || "",
            id: experience.id || "",
            currently_working: experience.currently_working || "",
            artwork_url: experience.artwork_url || null,
          }));
          this.experienceTotal = userExperiences.data.total;
        }

      }).catch((error) => {
        console.log(error);
      });
    },

    fetchUserExperiences(returnKey = 'user_experiences') {
      return async () => {
        try {
          const { data } = await get_user_work_api(this.offset, this.experiencesLimit, this.userData.id);
          return { [returnKey]: data };
        } catch (error) {
          throw error;
        }
      }
    },

    fetchUserEducations(returnKey = 'user_educations') {
      return async () => {
        try {
          const { data } = await get_user_education_api(this.offset, this.educationsLimit, this.userData.id);
          return { [returnKey]: data };
        } catch (error) {
          throw error;
        }
      }
    },
  },
};
</script>
