<template>
  <div class="Signup_pop_overlay">
    <div v-if="!showSuccess">
      <div class="wr_report_modal fixwr_report_modal">
        <ReportModalHeader @closeModal="closeModal" report-partner :reportable-type=reportableType />
        <BaseSelectReasons @selectReason="selectReason" :option="{ id: 1, option: 'Harassment' }"></BaseSelectReasons>
        <BaseSelectReasons @selectReason="selectReason" :option="{ id: 2, option: 'Violence' }"></BaseSelectReasons>
        <BaseSelectReasons @selectReason="selectReason" :option="{ id: 3, option: 'Hate Speech' }"></BaseSelectReasons>
        <BaseSelectReasons @selectReason="selectReason" :option="{ id: 4, option: 'Fraud or Scam' }">
        </BaseSelectReasons>
        <BaseSelectReasons @selectReason="selectReason" :option="{ id: 5, option: 'Spam' }"></BaseSelectReasons>
        <BaseSelectReasons @selectReason="selectReason" :option="{ id: 6, option: 'Nudity' }"></BaseSelectReasons>
        <BaseSelectReasons @selectReason="selectReason" :option="{ id: 7, option: 'Other' }"></BaseSelectReasons>

        <!-- report user -->

        <div class="report-detail" v-if="reportableType !== 'Event'">
          <div class="svgPart">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.35 8C15.35 12.0593 12.0593 15.35 8 15.35C3.94071 15.35 0.65 12.0593 0.65 8C0.65 3.94071 3.94071 0.65 8 0.65C12.0593 0.65 15.35 3.94071 15.35 8Z"
                stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M2.48169 13.5182L13.4817 2.51819" stroke="white" stroke-width="1.3" />
            </svg>
          </div>
          <div style="width: calc(100% - 140px);">
            <label id="base-select-container" class="audience-container fixAudienceContainer">
              <p class="w-100 m-0 sponsors-title"
              style="font-family: HelveticaNeueMedium, sans-serif;"
              >
                Would you like to block user?
              </p>
              <!-- toggle button -->
              <div class="Sponsors-section fixSponsorsSection">
                <div class="action-block ">
                  <div class="switch-button">
                    <label class="switch ">
                      <input type="checkbox" checked="checked" class="default" v-model="block_check"> <span
                        class="slider round"></span> </label>
                  </div>
                </div>
              </div>

            </label>
          </div>
        </div>

        <!-- description -->
        <div class="bottom-input">
          <div class="decription-container">
            <p >Description</p>
            <span>{{ this.error }}</span>
          </div>
          <div class="border-line"></div>
          <div class="row">
            <div class="col">
              <div class="post-artist-setcion">
                <div class="user-post-filed-image">
                  <img :src="picture
                    ? picture
                    : require(`@/assets/img/user-placeholder.png`)
                    " class="user-avatar" />
                </div>
                <div class="post-something-field">
                  <input class="form-control report-description-input" v-model="description"
                    name="create_post_form_post" placeholder="Describe the issue in more detail" autocomplete="off"
                    ref="Input" @click="emoji = false" />
                  <span class="post_emoji" @click="toggleEmoji" v-if="emoji">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                        stroke="#D1433A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                        d="M7.07617 13.2422C7.51371 13.8384 8.08286 14.3256 8.73937 14.6659C9.39588 15.0063 10.122 15.1906 10.8614 15.2046C11.6008 15.1906 12.3269 15.0063 12.9834 14.6659C13.64 14.3256 14.2091 13.8384 14.6466 13.2422"
                        stroke="#D1433A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                        d="M14.8711 10.1543C15.5917 10.1543 16.1758 9.57018 16.1758 8.84962C16.1758 8.12906 15.5917 7.54492 14.8711 7.54492C14.1505 7.54492 13.5664 8.12906 13.5664 8.84962C13.5664 9.57018 14.1505 10.1543 14.8711 10.1543Z"
                        fill="#D1433A" />
                      <path
                        d="M7.13185 10.1543C7.85241 10.1543 8.43654 9.57018 8.43654 8.84962C8.43654 8.12906 7.85241 7.54492 7.13185 7.54492C6.41128 7.54492 5.82715 8.12906 5.82715 8.84962C5.82715 9.57018 6.41128 10.1543 7.13185 10.1543Z"
                        fill="#D1433A" />
                    </svg>
                  </span>

                  <span class="post_emoji" @click="toggleEmoji" v-if="!emoji">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                        stroke="#8B949F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                        d="M7.07617 13.2432C7.51371 13.8393 8.08286 14.3265 8.73937 14.6669C9.39588 15.0073 10.122 15.1916 10.8614 15.2056C11.6008 15.1916 12.3269 15.0073 12.9834 14.6669C13.64 14.3265 14.2091 13.8393 14.6466 13.2432"
                        stroke="#8B949F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                        d="M14.8711 10.1543C15.5917 10.1543 16.1758 9.57018 16.1758 8.84962C16.1758 8.12906 15.5917 7.54492 14.8711 7.54492C14.1505 7.54492 13.5664 8.12906 13.5664 8.84962C13.5664 9.57018 14.1505 10.1543 14.8711 10.1543Z"
                        fill="#8B949F" />
                      <path
                        d="M7.13282 10.1543C7.85339 10.1543 8.43752 9.57018 8.43752 8.84962C8.43752 8.12906 7.85339 7.54492 7.13282 7.54492C6.41226 7.54492 5.82812 8.12906 5.82812 8.84962C5.82812 9.57018 6.41226 10.1543 7.13282 10.1543Z"
                        fill="#8B949F" />
                    </svg>
                  </span>
                </div>
                <div class="send-btn" v-if="!loading" @click="report">
                  <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                      <path d="M24.3336 12.7282L12.6664 12.7282" stroke="white" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M24.3332 12.7277L10.0143 19.622L12.6659 12.7277L10.0143 5.8334L24.3332 12.7277Z"
                        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="18" height="18" fill="white" transform="translate(13.7266) rotate(45)"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div class="send-btn" v-else>
                  <b-icon icon="arrow-clockwise" animation="spin" font-scale="2" variant="light"></b-icon>
                </div>
                <div>
                  <b-overlay :show="emoji" no-wrap @shown="onShown" @hidden="onHidden" style="top: 200px; left: 70px;">
                    <template #overlay>
                      <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false"
                        aria-labelledby="form-confirm-label" class="text-center p-3">
                        <VEmojiPicker @select="selectEmoji" :emojiWithBorder="false" :emojiSize="26"
                          :continuousList="true" :emojisByRow="6" />
                      </div>
                    </template>
                  </b-overlay>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <ReportSuccess v-if="showSuccess" @closeModal="closeModal" :reportableType="reportableType" report-partner />
    </div>
  </div>
</template>
<script type="application/javascript">
import ReportModalHeader from "./ReportModalHeader.vue";
import BaseSelectReasons from "./SlotsforReasons.vue";
import ReportSuccess from "./ReportSuccess.vue";
import { VEmojiPicker } from "v-emoji-picker";
import { reportPosts } from '../../../../apis/APIs'
export default {
  name: "ReportReason",
  props: {
    reportableId: {
      type: [String, Number],
      default: null
    },
    reportableType: {
      type: String,
      default: 'post'
    },
    user: {
      type: Object,
      default: null
    },
    page: {
      type: Object,
      default: null
    },
    post: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      showSuccess: false,
      loading: false,
      reason: "",
      description: "",
      emoji: false,
      block_check: false,
      error: ''
    };
  },
  computed: {
    reportId() {
      return this.reportableId || (this.user ? this.user.id : (this.post ? this.post.id : null));
    },
    reportType() {
      return this.reportableType || (this.user ? 'user' : (this.page ? 'page' : 'post'));
    }
  },
  created() {
  },
  components: {
    ReportModalHeader,
    BaseSelectReasons,
    ReportSuccess,
    VEmojiPicker,
  },
  computed: {
    picture() {
      return this.$store.getters.user_map_to_props.user_data.artwork_url;
    },
    reportableObject() {
      return this.user || this.page || this.post;
    },
    reportableObjectId() {
      if (this.user) {
        return this.user.id;
      } else if (this.page || this.post) {
        return (this.page || this.post).user_id;
      }
      return null;
    }
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    showSuccessModal() {
      this.showSuccess = true;
    },
    selectReason(reason) {
      this.reason = reason;
    },
    toggleEmoji() {
      this.emoji = !this.emoji;
    },
    onShown() {
      this.$refs.dialog.focus();
    },
    onHidden() {
      this.$refs.Input.focus();
    },
    selectEmoji(emoji) {
      const { data, key } = emoji || {};
      this.description = this.description
        ? `${this.description} ${data}`
        : ` ${data}`;
    },
    async report() {
      if (!this.reason) {
        this.error = "Please select a reason";
        setTimeout(() => {
          this.error = ""
        }, 3000);
      } else if (this.reason === "Other" && this.description === "") {
        this.error = "Required";
        setTimeout(() => {
          this.error = ""
        }, 3000);
      } else {
        try {
          if (this.reportableType === 'Partner') {
            const payload = {
              title: this.reason.toLowerCase().split(" ").join("_"),
              message: this.description
            }
            this.$emit("report-partner", payload, this.closeModal);
          } else {
            const payload = {
              report_type: this.reportableType === 'Page' ? 'Page' : this.reportableType === 'User' ? "User" : 'Post',
              post_id: this.reportableId,
              title: this.reason.toLowerCase().split(" ").join("_"),
              message: this.description
            };
            if (this.reportableType === 'User') {
              const blockuser = {
                user_id: this.reportableObjectId,
              };
              if(this.block_check === true){
                const response = await this.$store.dispatch('blockUserFunction', blockuser);
                if (response.success === 0) {
                  this.error = response.message || "User already has been blocked.";
                  setTimeout(() => {
                    this.error = ""
                  }, 3000);ssss
                }
              }
            }
            const response = await this.$store.dispatch('reportPost', payload);
            if (response.success === 0) {
              this.error = response.message || "This Post has already been reported.";
              setTimeout(() => {
                this.error = ""
              }, 3000);
            } else {
              this.showSuccessModal();
            }
          }
        } catch (error) {
          this.error = "Failed to submit report";
          setTimeout(() => {
            this.error = ""
          }, 3000);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.post-something-field .report-description-input:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: #f5f6f8 !important;
}

.Signup_pop_overlay {
  font-family: HelveticaNeueNormal;

  .wr_report_modal {
    background: #ffffff;
    width: 570px;
    height: 635px;
    padding: 22px 20px 29px;
    border-radius: 4px;

    .bottom-input {
      margin-top: 17px;

      p {
        font-weight: 800;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 17px;
        color: #47505b;
      }

      .border-line {
        border-bottom: 1px solid #e6eaf0;
        opacity: 0.7;
        margin-bottom: 20px;
      }

      .post-something-field {
        input {
          border-radius: 10px !important;
        }
      }

      .decription-container {
        display: flex;
        align-items: center;

        span {
          color: red;
          font-weight: 600;
          font-size: 13px;
          margin-left: 12px;
        }
      }
    }

    .user-post-filed-image {
      .user-avatar {
        width: 40px;
        height: 40px;
      }
    }
  }
}
</style>
