<template>
  <div class="buy-stream-toggle-switch">
    <div :class="{'buy-and-stream': true, active: !isSelling }" @click="setBuyStreamState(!isSelling)">
      Buy
    </div>
    <div :class="{'buy-and-stream': true, active: isSelling }" @click="setBuyStreamState(!isSelling)">
      Stream
    </div>
  </div>
</template>

<script>
export default {
  name: "payStreamToggleSwitch",
  watch: {
    buy(val) {
      this.$emit("buyState", val);
    },
  },
  data() {
    return {
      buy: true,
    };
  },
  methods: {
    setBuyStreamState(val) {
      this.$store.commit("SET_MUSIC_MODE", val);
    },
  },
  computed: {
    isSelling() {
      return this.$store.getters.isMusicModeIsStreaming;
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/assets/style/music/UI-componsnts/buyStreamToggleSwitch.scss";
</style>
