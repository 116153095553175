<template>
  <div class="row" style="background-color: white">
    <div class="col-sm-9 px-0" style="max-width: 80.45% !important;
    flex: 80.45% !important;">
      <div class="row pt-4 mx-0" style="padding-left: 20px !important;    padding-right: 30px !important;">
        <div class="col-sm-12 pl-1">
          <h5 class="song-heading">Territory Restrictions</h5>
        </div>
        <div class="col-sm-6 pl-0" style="padding-right: 5px">
          <div class="col-12 px-0 feed-filters territory-feed-filters">
            <div class="row territory-restrictions-snog-border mx-0">
            <div class="col-sm-7 pt-3">
              <h3 class="territory-h">Cleared for Sale</h3>
            </div>
            <div class="col-sm-5 pt-3">
              <span class="territory-p">{{territory_restrictions[CLEAR_FOR_SALE_COUNTRIES].length}}  of {{countries.length}}</span>
            </div>
            <div class=" col-sm-12 search-wrapper with-icon pg-search-wrapper">
              <div v-if="territory_restrictions[CLEAR_FOR_SALE_COUNTRIES].length < 1" class="svg-icon">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                     class="svg-search-ico">-->
                  <path
                      d="M12.1 6.54294C12.1 3.48162 9.61518 0.999937 6.55 0.999937C3.48482 0.999937 1 3.48162 1 6.54294C1 9.60425 3.48482 12.0859 6.55 12.0859C9.61518 12.0859 12.1 9.60425 12.1 6.54294Z"
                      stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"
                      class="c-line-1"></path>
                  -->
                  <path d="M12.5 12.501L14.5 14.501" stroke="#47505B" stroke-width="1.3" stroke-linecap="round"
                        stroke-linejoin="round" class="c-line-1"></path>
                  -->
                </svg>
              </div>
              <Select2 class="sdccas"
                  v-model="$v.territory_restrictions[CLEAR_FOR_SALE_COUNTRIES].$model"
                  id="search_country_cleared_sales"
                  placeholder="Type Country or Territory"
                  :key="'countries' + errorsList[CLEAR_FOR_SALE_COUNTRIES]"
                  :class="{'is-invalid':errorsList[CLEAR_FOR_SALE_COUNTRIES]}"
                  :state="validateState([CLEAR_FOR_SALE_COUNTRIES])"
                  :options="countries"
                  :settings="{ multiple: true, tags: true}"
              />
              <b-form-invalid-feedback
                  id="search_country_not_cleared_sales"
              >This is a required field.
              </b-form-invalid-feedback>
            </div>
            <div class="col-sm-4">
              <h5 class="territory-song-p">Select</h5>
            </div>
            <div class="col-sm-8 pt-3">
              <div :class="['territory-btn', ((cleared_for_sale_active_tab === 'all') ? 'select' : '')]">
                <button @click="selectAll('for_sale')">All</button>
              </div>
              <div :class="['territory-btn', ((cleared_for_sale_active_tab === 'none') ? 'select' : '')]">
                <button @click="selectNone('for_sale')">None</button>
              </div>
            </div>
            <div class="territory-song-li pt-4 pb-5">
              <ul>
                <li>
                  <div class="event-filter-widget with-app pg-metadata-song-checkbox-card">
                    <div class="widget-item px-0">
                      <b-form-checkbox
                          value="asia"
                          v-model="$v.territory_restrictions[CLEAR_FOR_SALE_TERRITORY].$model"
                      >
                        Asia (44)
                      </b-form-checkbox>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="event-filter-widget with-app pg-metadata-song-checkbox-card">
                    <div class="widget-item px-0">
                      <b-form-checkbox
                          value="europe"
                          v-model="$v.territory_restrictions[CLEAR_FOR_SALE_TERRITORY].$model"
                      >
                        Europe (64)
                      </b-form-checkbox>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="event-filter-widget with-app pg-metadata-song-checkbox-card">
                    <b-form-checkbox
                        value="oceania"
                        v-model="$v.territory_restrictions[CLEAR_FOR_SALE_TERRITORY].$model"
                    >
                      Oceania (25)
                    </b-form-checkbox>
                  </div>
                </li>
                <li>
                  <div class="event-filter-widget with-app pg-metadata-song-checkbox-card">
                    <b-form-checkbox
                        value="north_america"
                        v-model="$v.territory_restrictions[CLEAR_FOR_SALE_TERRITORY].$model"
                    >
                      North America (37)
                    </b-form-checkbox>
                  </div>
                </li>
                <li>
                  <div class="event-filter-widget with-app pg-metadata-song-checkbox-card">
                    <b-form-checkbox
                        value="antarctica"
                        v-model="$v.territory_restrictions[CLEAR_FOR_SALE_TERRITORY].$model"
                    >
                      Antarctica (5)
                    </b-form-checkbox>
                  </div>
                </li>
                <li>
                  <div class="event-filter-widget with-app pg-metadata-song-checkbox-card">
                    <b-form-checkbox
                        value="south_america"
                        v-model="$v.territory_restrictions[CLEAR_FOR_SALE_TERRITORY].$model"
                    >
                      South America (14)
                    </b-form-checkbox>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          </div>
        </div>
        <div class="col-sm-6 pr-0" style="padding-left: 5px">
          <div class="col-12 px-0 feed-filters territory-feed-filters">
            <div class="row territory-restrictions-snog-border mx-0">
            <div class="col-sm-8 pt-3">
              <h3 class="territory-h">Not Cleared for Sale</h3>
            </div>
            <div class="col-sm-4 pt-3">
              <span class="territory-p">{{territory_restrictions[CLEAR_FOR_NOT_SALE_COUNTRIES].length}} of {{countries.length}}</span>
            </div>
            <div class=" col-sm-12 search-wrapper with-icon pg-search-wrapper">
              <div v-if="territory_restrictions[CLEAR_FOR_NOT_SALE_COUNTRIES].length < 1" class="svg-icon">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                     class="svg-search-ico">-->
                  <path
                      d="M12.1 6.54294C12.1 3.48162 9.61518 0.999937 6.55 0.999937C3.48482 0.999937 1 3.48162 1 6.54294C1 9.60425 3.48482 12.0859 6.55 12.0859C9.61518 12.0859 12.1 9.60425 12.1 6.54294Z"
                      stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"
                      class="c-line-1"></path>
                  -->
                  <path d="M12.5 12.501L14.5 14.501" stroke="#47505B" stroke-width="1.3" stroke-linecap="round"
                        stroke-linejoin="round" class="c-line-1"></path>
                  -->
                </svg>
              </div>
              <Select2
                class="sdccas"
                  v-model="$v.territory_restrictions[CLEAR_FOR_NOT_SALE_COUNTRIES].$model"
                  id="search_country_not_cleared_sales"
                  :key="'countries' + errorsList[CLEAR_FOR_NOT_SALE_COUNTRIES]"
                  :class="{'is-invalid':errorsList[CLEAR_FOR_NOT_SALE_COUNTRIES]}"
                  :state="validateState([CLEAR_FOR_NOT_SALE_COUNTRIES])"
                  placeholder="Type Country or Territory"
                  :options="countries"
                  :settings="{ multiple: true, tags: true}"
              />
              <b-form-invalid-feedback
                  id="search_country_not_cleared_sales"
              >This is a required field.
              </b-form-invalid-feedback>
            </div>
            <div class="col-sm-4">
              <h5 class="territory-song-p">Select</h5>
            </div>
            <div class="col-sm-8 pt-3">
              <div :class="['territory-btn', ((cleared_for_not_sale_active_tab === 'all') ? 'select' : '')]">
                <button @click="selectAll('not_sale')">All</button>
              </div>
              <div :class="['territory-btn', ((cleared_for_not_sale_active_tab === 'none') ? 'select' : '')]">
                <button @click="selectNone('not_sale')">None</button>
              </div>
            </div>
            <div class="territory-song-li pt-4 pb-5">
              <ul>
                <li>
                  <div class="event-filter-widget with-app pg-metadata-song-checkbox-card">
                    <div class="widget-item px-0">
                      <b-form-checkbox
                          value="asia"
                          v-model="$v.territory_restrictions[CLEAR_FOR_NOT_SALE_TERRITORY].$model"
                      >
                        Asia (44)
                      </b-form-checkbox>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="event-filter-widget with-app pg-metadata-song-checkbox-card">
                    <div class="widget-item px-0">
                      <b-form-checkbox
                          value="europe"
                          v-model="$v.territory_restrictions[CLEAR_FOR_NOT_SALE_TERRITORY].$model"
                      >
                        Europe (64)
                      </b-form-checkbox>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="event-filter-widget with-app pg-metadata-song-checkbox-card">
                    <b-form-checkbox
                        value="oceania"
                        v-model="$v.territory_restrictions[CLEAR_FOR_NOT_SALE_TERRITORY].$model"
                    >
                      Oceania (25)
                    </b-form-checkbox>
                  </div>
                </li>
                <li>
                  <div class="event-filter-widget with-app pg-metadata-song-checkbox-card">
                    <b-form-checkbox
                        value="north_america"
                        v-model="$v.territory_restrictions[CLEAR_FOR_NOT_SALE_TERRITORY].$model"
                    >
                      North America (37)
                    </b-form-checkbox>
                  </div>
                </li>
                <li>
                  <div class="event-filter-widget with-app pg-metadata-song-checkbox-card">
                    <b-form-checkbox
                        value="antarctica"
                        v-model="$v.territory_restrictions[CLEAR_FOR_NOT_SALE_TERRITORY].$model"
                    >
                      Antarctica (5)
                    </b-form-checkbox>
                  </div>
                </li>
                <li>
                  <div class="event-filter-widget with-app pg-metadata-song-checkbox-card">
                    <b-form-checkbox
                        value="south_america"
                        v-model="$v.territory_restrictions[CLEAR_FOR_NOT_SALE_TERRITORY].$model"
                    >
                      South America (14)
                    </b-form-checkbox>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          </div>
        </div>
        <div data-v-58c6911e="" data-v-66e59ad3="" class="col-sm-12 px-0 pb-4 pt-2">
          <div data-v-58c6911e="" data-v-66e59ad3="" class=" cancel-btn-display">
            <button @click="previousStep" data-v-58c6911e="" data-v-66e59ad3="" class="upload-file-cancel-btn">CANCEL
            </button>
          </div>
          <div
              data-v-58c6911e=""
              data-v-66e59ad3=""
              style="float: right;">
            <div
                data-v-58c6911e=""
                data-v-66e59ad3=""
                class=" save-btn-display">
              <button
                  data-v-58c6911e=""
                  data-v-66e59ad3=""
                  class="upload-file-save-btn">
                SAVE TO DRAFT
              </button>
            </div>
            <div
                data-v-58c6911e=""
                data-v-66e59ad3=""
                class="upload-btn-display">
              <button
                  @click="saveDataInStore"
                  data-v-58c6911e=""
                  data-v-66e59ad3=""
                  class="upload-file-upload-btn">
                                <span data-v-58c6911e="" data-v-66e59ad3=""
                                >NEXT STEP</span>
                <svg
                    data-v-58c6911e=""
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="basic-info-upload-btn">
                  <path
                      data-v-58c6911e=""
                      d="M0.773189 13.5C0.848551 13.5 0.923913 13.4589 0.961595 13.4178L6.91522 7.21067C7.02826 7.08735 7.02826 6.92292 6.91522 6.7996L0.961596 0.59249C0.848552 0.469169 0.697828 0.469169 0.584784 0.59249C0.47174 0.71581 0.47174 0.880237 0.584784 1.00356L6.31232 7.00514L0.584783 13.0067C0.471739 13.1301 0.471739 13.2945 0.584783 13.4178C0.622464 13.5 0.697826 13.5 0.773189 13.5Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.5"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-3 upload-file-p-padding" style="max-width: 19.55%">
      <div class="row " style="padding-top: 28px;">
        <div class="col-sm-12">
          <h5 class="territory-song-heading">Territory Restrictions</h5>
        </div>
        <div class="col-sm-12">
          <div>
            <p class="song-p">
              Choose the countries that you want to distribute your music in and specify the countries that you dont want your music to be distributed to.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import Select2 from 'v-select2-component';
import {required} from "vuelidate/lib/validators";
import {getCountries} from "../../../../apis/APIs";
import {
  CLEAR_FOR_NOT_SALE_COUNTRIES,
  CLEAR_FOR_NOT_SALE_TERRITORY,
  CLEAR_FOR_SALE_COUNTRIES,
  CLEAR_FOR_SALE_TERRITORY,
} from "../../../../store/types";
import Vue from "vue";

export default {
  data() {
    return {
      errorsList: [],
      cleared_for_sale_active_tab: 'none',
      cleared_for_not_sale_active_tab: 'none',
      countries: [],
      CLEAR_FOR_SALE_COUNTRIES, CLEAR_FOR_SALE_TERRITORY, CLEAR_FOR_NOT_SALE_COUNTRIES, CLEAR_FOR_NOT_SALE_TERRITORY,
      territory_restrictions: {
        [CLEAR_FOR_SALE_COUNTRIES]: [],
        [CLEAR_FOR_SALE_TERRITORY]: [],
        [CLEAR_FOR_NOT_SALE_COUNTRIES]: [],
        [CLEAR_FOR_NOT_SALE_TERRITORY]: [],
      }
    }
  },
  watch: {
    'territory_restrictions.clear_for_sale_territory': function (newVal) {
      this.removeSimilarDataFromArrays(this.territory_restrictions[CLEAR_FOR_NOT_SALE_TERRITORY],this.territory_restrictions[CLEAR_FOR_SALE_TERRITORY])
    },
    'territory_restrictions.clear_not_for_sale_territory': function (newVal) {
      this.removeSimilarDataFromArrays(this.territory_restrictions[CLEAR_FOR_SALE_TERRITORY],this.territory_restrictions[CLEAR_FOR_NOT_SALE_TERRITORY])
    }
  },
  props: {
    type: {
      type: String,
      default: 'song'
    },
    actionType: {
      type: String,
      default: 'createSongAlbum'
    }
  },
  validations: {
    territory_restrictions: {
      [CLEAR_FOR_SALE_COUNTRIES]: {},
      [CLEAR_FOR_SALE_TERRITORY]: {},
      [CLEAR_FOR_NOT_SALE_COUNTRIES]: {},
      [CLEAR_FOR_NOT_SALE_TERRITORY]: {}
    }
  },
  components: {
    Select2
  },
  mounted() {
    this.countryList();
  },
  methods: {
    async countryList() {
      try {
        const res = await getCountries();
        const {success, information} = res.data || {};
        if (success === 1) {
          if (information && information.length) {
            this.countries = information.map(ele => ({id: ele.id, text: ele.name}));
          }
        }
      } catch (e) {

      }
    },
    selectAll(value) {
      if (value === 'not_sale') {
        this.cleared_for_not_sale_active_tab = 'all';
        this.territory_restrictions[CLEAR_FOR_NOT_SALE_TERRITORY] = ["asia", "europe", "oceania", "north_america", "antarctica", "south_america"]
      } else if (value === 'for_sale') {
        this.cleared_for_sale_active_tab = 'all';
        this.territory_restrictions[CLEAR_FOR_SALE_TERRITORY] = ["asia", "europe", "oceania", "north_america", "antarctica", "south_america"]
      }
    },
    selectNone(value) {
      if (value === 'not_sale') {
        this.cleared_for_not_sale_active_tab = 'none';
        this.territory_restrictions[CLEAR_FOR_NOT_SALE_TERRITORY] = []
      } else if (value === 'for_sale') {
        this.cleared_for_sale_active_tab = 'none';
        this.territory_restrictions[CLEAR_FOR_SALE_TERRITORY] = []
      }
    },
    validateState(name) {
      const {$dirty, $error} = this.$v.territory_restrictions[name];
      Vue.set(this.errorsList, name, $error);
      return $dirty ? !$error : null;
    },
    nextStep() {
      this.$emit('nextStep')
    },
    previousStep() {
      this.$emit('previousStep')
    },
    saveDataInStore() {
      console.log('store schedule step in store')
      this.$v.territory_restrictions.$touch();
      if (this.$v.territory_restrictions.$anyError) {
        return;
      } else {
        if (this.type === 'song' || this.type ==='podcast') {
          this.$store.commit('SET_SONG_STEP_5_DATA', this.territory_restrictions)
          this.nextStep()
        } else if (this.type === 'sample' || this.type === 'sample_pack') {
          this.$store.commit('SET_SAMPLE_STEP_5_DATA', this.territory_restrictions)
          this.nextStep()
        } else {
          this.$store.commit('SET_ALBUM_STEP_5_DATA', this.territory_restrictions)
          this.nextStep()
        }

      }

    },
    removeSimilarDataFromArrays(arr1,arr2){
      for(const item of arr1){
        const index = arr2.findIndex(e=>e === item)
        if(index !== -1){
          arr1.splice(index, 1);
        }
      }
    }
  },
}
</script>
<style scoped>
/*************/
.event-filter-widget {
  border: none !important;
}

.svg-icon {
  z-index: 1;
  margin-left: 2px;
}

/*************/
.song-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  letter-spacing: 0.03em;
  margin-bottom: 6px;
}

.song-p {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: #47505B;
}

.upload-file-p-padding {
  padding: 0 19px 0 21px;
  background-color: #F9FAFB;
}

.territory-h {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #47505B;
}

.territory-p {
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  color: #47505B;
  float: right;
}

.territory-restrictions-snog-border {
  border: 1px solid #C4CCD6;
  border-radius: 2px;
}

.territory-btn {
  width: 50%;
  float: left;
  background: #FFFFFF;
  padding: 4px 0px;
  margin-top: 0.5rem;
  border: 1px solid #C4CCD6;
  border-radius: 2px !important;
  height: 30px;
}

.territory-btn button {
  width: 100%;
  background: none;
  border-radius: 2px 0px 0px 2px;
  text-align: center;
  border: none;
}

.select {
  background: #D1433A;
  border: 1px solid #D1433A;
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.select button {
  color: white !important;
}

.territory-song-p {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #071526;
  opacity: 0.9;
  padding-top: 30px;
}

.feed-filters {
  width: 320px;
  min-height: 380px;
}

.territory-feed-filters {
  height: auto;
}

.territory-song-li li {
  list-style: none;
}

.territory-song-li ul {
  padding-left: 4px;
}

.territory-song-li ul li .event-filter-widget .widget-item {
  padding: 8px 0 0 0;
}

.territory-song-li .custom-control-label {
  color: #59626B;
  font-weight: 700;

}
.upload-file-save-btn {
  align-items: center;
  justify-content: center;
  font-weight: 700;
  transition: 0.3s all;
  border: 1px solid #D1433A !important;
  background-color: #ffffff;
  color: #D1433A;
  padding: 8px 12px;
  font-size: 13px;
  border: none;
  font-family: "HelveticaNeueMedium";
  width: 100%;
  border-radius: 4px;
  letter-spacing: 0.05em;
}
.upload-file-upload-btn {
  align-items: center;
  justify-content: center;
  font-weight: 700;
  transition: 0.3s all;
  background-color: #D1433A;
  color: #fff;
  padding: 10px;
  font-size: 13px;
  border: none;
  font-family: "HelveticaNeueMedium";
  width: 100%;
  border-radius: 4px;
  letter-spacing: 0.05em;
}
.cancel-btn-display {
  display: inline-block;
  width: 130px;
}
.save-btn-display {
  display: inline-block;
  width: 150px;
  padding-right: 10px;
}
.upload-btn-display {
  display: inline-block;
  width: 130px;
}

.pg-song-artist-checkbox-card{
  border: none !important;
}


.pg-song-artist-checkbox-card .custom-control {
  width: 100px;
  margin-left: 21px;
  display: inline-block;
}

.pg-song-artist-checkbox-card .widget-item{
  display: inline-block !important;
  padding: 0;
}
.pg-song-artist-checkbox-card .pg-item-title{
  display: inline-block;
  position: relative;
  top: -3px;
  width: auto;
  color: #071526;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;

}
.upload-btn-margin{
  margin-left: 16px;
}
.upload-file-p-padding{
  padding: 0 19px 0 21px;
  background-color: #F9FAFB;
}

.upload-file-p-position{
  position: absolute;
  bottom: 0;
}
.Choose-File-Format-bottom-p{
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #8B949F;
}
.upload-file-cancel-btn {
  align-items: center;
  justify-content: center;
  font-weight: 700;
  transition: 0.3s all;
  background: #e6eaf0;
  color: #47505b;
  padding: 10px;
  font-size: 13px;
  border: none;
  font-family: "HelveticaNeueMedium";
  width: 100%;
  border-radius: 4px;
  letter-spacing: 0.05em;
}
.basic-info-upload-btn {
  display: inline-block;
  margin-left: 12px;
  position: relative;
  top: -1px;
}
.territory-song-heading{
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: #071526;
  letter-spacing: 0.01em;
}
.territory-input{
  padding-left: 10px;
}
.sdccas .select2-container--default{
  padding-left: 120px !important;
}

</style>
<style scoped lang="scss">
.search-wrapper {
  .svg-icon {
    left: 24px!important;
    top: 16px!important;
    margin-right: 0.5rem!important;
  }
}
</style>
<style lang="scss">
.sdccas {
  .select2-container {
    .selection {
      .select2-selection {
        .select2-selection__rendered {
          .select2-search {
            input {
              margin-left: 30px !important;
              width: 242.531px !important;

              &::placeholder {
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 13px;
                color: rgba(7, 21, 38, 0.6);
              }
            }
          }
        }
      }
    }
  }
}

.territory-song-li {
  ul {
    li {
      .event-filter-widget {
        .widget-item {
          .custom-control {
            label {
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 16px;
              color: #071526;
              opacity: 0.9;
            }
          }
        }
      }
    }
  }
}
</style>
