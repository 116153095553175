<template>
  <div
    :class="{'w-100': !checkIfSkeleton, 'profileContainer': true }"
    ref="componentOne"
    v-if="otherUser ? !otherUserLoading : true"
  >
    <b-skeleton-wrapper :loading="checkIfSkeleton">
      <template #loading>
        <SkeletonLoader :type="'user-profile'" />
      </template>
      <cover
        v-if="(Object.keys(userData).length > 0)"
        :edit="!otherUser"
        :user="userData"
        :saveChanges="saveCoverAndProfile"
        :preview="previewEnabled"
        @previewEnabled="changePreview"
        @saveChangesEmit="changeSaveChangesState"
        @coverModelOpenCover="changeProfileAndCoverModel('cover')"
      />
      <div class="container w-auto" v-if="(Object.keys(userData).length > 0)">
        <div class="row">
          <div class="col-12">
            <div class="user_complete_detail">
              <profile-pic
                :edit="!otherUser"
                :user="userData"
                :saveChanges="saveCoverAndProfile"
                :preview="previewEnabled"
                @previewEnabled="changePreview"
                @saveChangesEmit="changeSaveChangesState"
                @coverModelOpenProfile="changeProfileAndCoverModel('profile')"
              />
              <div class="user-name-detail">
                <h1>{{ (name) ? name : "User Name" }}</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12" style="padding-right: 14px; padding-left: 14px;">
            <div class="edit-share-section">
              <div class="detail-tab">
                <nav>
                  <div class="nav top-tab nav-tabs1" role="tablist">
                    <a :class="['nav-item nav-link', active_sub_tab === 'posts' ? 'active' : '']"
                      id="nav-home-tab"
                      data-toggle="tab"
                      href="#timeline"
                      role="tab"
                      aria-controls="nav-home"
                      :aria-selected="active_sub_tab === 'posts'"
                      v-on:click="active_sub_tab = 'posts'"
                      @click="change_active_tab('posts')"
                    >
                      POSTS
                    </a>

                    <a :class="['nav-item nav-link', active_sub_tab === 'collection' ? 'active' : '']"
                      id="nav-profile-tab"
                      data-toggle="tab"
                      href="#Collection"
                      role="tab"
                      aria-controls="nav-profile"
                      :aria-selected="
                      active_sub_tab === 'collection'
                    "
                      v-on:click="active_sub_tab = 'collection'"
                      @click="change_active_tab('collection')"
                    >
                      COLLECTION
                    </a>

                    <a :class="['nav-item nav-link', active_sub_tab === 'wishlist' ? 'active' : '']"
                      id="nav-contact-tab"
                      data-toggle="tab"
                      href="#Wishlist"
                      role="tab"
                      aria-controls="nav-contact"
                      :aria-selected="active_sub_tab === 'wishlist'"
                      v-on:click="active_sub_tab = 'wishlist'"
                      @click="change_active_tab('wishlist')"
                    >
                      FAVORITES
                    </a>

                    <a
                      v-if="!otherUser || checkIfPageOwnerIsYourFriend(userData.id) || !userData.who_can_see_your_joined_event"
                      :class="[
                      'nav-item nav-link',
                      active_sub_tab === 'events' ? 'active' : '',
                    ]"
                      id="nav-contact-tab"
                      data-toggle="tab"
                      href="#event"
                      role="tab"
                      aria-controls="nav-contact"
                      :aria-selected="active_sub_tab === 'events'"
                      v-on:click="active_sub_tab = 'events'"
                      @click="change_active_tab('events')"
                    >EVENTS</a
                    >

                    <!--                  <a :class="['nav-item nav-link', (active_sub_tab == 'media_main') ? 'active' : ''] "-->
                    <!--                     id="nav-contact-tab"-->
                    <!--                     data-toggle="tab" href="#media_main" role="tab" aria-controls="nav-contact"-->
                    <!--                     :aria-selected="(active_sub_tab == 'media_main') ? true : false"-->
                    <!--                     v-on:click="active_sub_tab = 'media_main'" @click="change_active_tab('media_main')">MEDIA</a>-->

                    <a
                      :class="[
                      'nav-item nav-link',
                      active_sub_tab === 'about' ? 'active' : '',
                    ]"
                      id="nav-contact-tab"
                      data-toggle="tab"
                      href="#about"
                      role="tab"
                      aria-controls="nav-contact"
                      :aria-selected="active_sub_tab === 'about'"
                      v-on:click="active_sub_tab = 'about'"
                      @click="change_active_tab('about')"
                    >ABOUT</a
                    >
                    <a
                      :class="[
                      'nav-item nav-link',
                      moreTabCheck ? 'active-more-option-tab' : '',
                    ]"
                      :style="moreTabStyle"
                    >
                      <span data-toggle="dropdown">MORE</span>
                      <!-- class="dropdown-toggle" -->
                      <div class="dropdown-menu submenu_list">
                      <span class="nav top-tab nav-tabs2" role="tablist">
                        <a
                          v-if="!otherUser || checkIfPageOwnerIsYourFriend(userData.id) || !userData.who_can_see_your_photos"
                          :class="[
                            'nav-item nav-link',
                            active_sub_tab === 'media_main' ? 'active' : '',
                          ]"
                          id="nav-photos-tab"
                          data-toggle="tab"
                          href="#media_main"
                          role="tab"
                          aria-controls="nav-contact"
                          :aria-selected="
                            active_sub_tab === 'media_main'
                          "
                          v-on:click="active_sub_tab = 'media_main'"
                          @click="change_active_tab('media_main')"
                        >Media
                        </a>
                        <a
                          v-if="!otherUser || checkIfPageOwnerIsYourFriend(userData.id) || !userData.who_can_see_your_friends"
                          @click="change_active_tab('friends')"
                          :class="[
                            'nav-item nav-link',
                            active_sub_tab === 'friends' ? 'active' : '',
                          ]"
                          id="nav-friends-tab"
                          data-toggle="tab"
                          href="#friends"
                          role="tab"
                          aria-controls="nav-contact"
                          :aria-selected="
                          active_sub_tab === 'friends'
                          "
                          v-on:click="active_sub_tab = 'friends'"
                        >Friends
                        </a>
                        <a
                          :class="[
                            'nav-item nav-link',
                            active_sub_tab === 'history' ? 'active' : '',
                          ]"
                          id="nav-history-tab"
                          data-toggle="tab"
                          href="#history"
                          role="tab"
                          aria-controls="nav-contact"
                          :aria-selected="
                          active_sub_tab === 'history'
                          "
                          v-on:click="active_sub_tab = 'history'"
                          @click="change_active_tab('history')"
                        >Listening History</a
                        >
                        <a
                          v-if="!otherUser || checkIfPageOwnerIsYourFriend(userData.id) || !userData.who_can_see_your_work_info"
                          :class="[
                            'nav-item nav-link',
                            active_sub_tab === 'work' ? 'active' : '',
                          ]"
                          id="nav-work-tab"
                          data-toggle="tab"
                          href="#work"
                          role="tab"
                          aria-controls="nav-contact"
                          :aria-selected="
                          active_sub_tab ==='work'
                          "
                          v-on:click="active_sub_tab = 'work'"
                          @click="change_active_tab('work')"
                        >Work</a
                        >
                      </span>
                      </div>
                    </a>
                  </div>
                </nav>
              </div>
              <div class="edit-share-btn">
                <div class="edit-btn" v-if="!otherUser">
                  <button @click="redirectToSettingPageAndSetCheckAndTab($event,'Basic')">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.25 3H3C2.60218 3 2.22064 3.15804 1.93934 3.43934C1.65804 3.72064 1.5 4.10218 1.5 4.5V15C1.5 15.3978 1.65804 15.7794 1.93934 16.0607C2.22064 16.342 2.60218 16.5 3 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V9.75"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.875 1.87499C14.1734 1.57662 14.578 1.409 15 1.409C15.422 1.409 15.8266 1.57662 16.125 1.87499C16.4234 2.17336 16.591 2.57803 16.591 2.99999C16.591 3.42194 16.4234 3.82662 16.125 4.12499L9 11.25L6 12L6.75 8.99999L13.875 1.87499Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span class="mt-1">EDIT</span>
                  </button>
                </div>
                <div v-if="!otherUser" class="share-btn" @click="openProfileShareModel">
                  <button>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        class="share-svg"
                        d="M13.5 16.5C14.7426 16.5 15.75 15.4926 15.75 14.25C15.75 13.0074 14.7426 12 13.5 12C12.2574 12 11.25 13.0074 11.25 14.25C11.25 15.4926 12.2574 16.5 13.5 16.5Z"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        class="share-svg"
                        d="M4.5 11.25C5.74264 11.25 6.75 10.2426 6.75 9C6.75 7.75736 5.74264 6.75 4.5 6.75C3.25736 6.75 2.25 7.75736 2.25 9C2.25 10.2426 3.25736 11.25 4.5 11.25Z"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        class="share-svg"
                        d="M6.44336 10.1325L11.5659 13.1175"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        class="share-svg"
                        d="M13.5 6C14.7426 6 15.75 4.99264 15.75 3.75C15.75 2.50736 14.7426 1.5 13.5 1.5C12.2574 1.5 11.25 2.50736 11.25 3.75C11.25 4.99264 12.2574 6 13.5 6Z"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        class="share-svg"
                        d="M11.5584 4.88251L6.44336 7.86751"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span class="mt-1">SHARE</span>
                  </button>
                </div>
                <button v-if="otherUser && !checkIfPageOwnerIsYourFriend(userData.id) && !sentRequest"
                        @click="addFriendApi(userData)"
                        class="d-flex align-items-center justify-content-evenly add-friend-button">
                  <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.0345 14.1555V12.7762C12.0345 12.0446 11.7439 11.3429 11.2265 10.8256C10.7092 10.3082 10.0075 10.0176 9.27588 10.0176H3.75863C3.02699 10.0176 2.32533 10.3082 1.80798 10.8256C1.29064 11.3429 1 12.0446 1 12.7762V14.1555"
                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                      d="M6.51839 7.25846C8.04194 7.25846 9.27702 6.02338 9.27702 4.49984C9.27702 2.97629 8.04194 1.74121 6.51839 1.74121C4.99484 1.74121 3.75977 2.97629 3.75977 4.49984C3.75977 6.02338 4.99484 7.25846 6.51839 7.25846Z"
                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M14.3906 4.47363V8.91254" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round" />
                    <path d="M12.2461 6.69336H16.685" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round" />
                  </svg>
                  <span>ADD FRIEND</span>
                </button>
                <button v-else-if="otherUser && !checkIfPageOwnerIsYourFriend(userData.id) && sentRequest"
                        @click="addFriendApi(userData)"
                        class="d-flex align-items-center justify-content-evenly add-friend-button">
                  <span>Request Sent</span>
                </button>
                <div v-if="otherUser" class="artist-header-btns follow-Btn"
                     :class="{'active' : userData.follow_status === 1}"
                     @click="FollowUnFollowUser"
                >
                  <button>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.998889 8.00081C3.75283 8.00081 5.99334 10.2436 5.99334 13.0004C5.99334 13.5524 6.44084 14.0003 6.99223 14.0003C7.54361 14.0003 7.99112 13.5524 7.99112 13.0004C7.99112 9.14072 4.8546 6.00098 0.998889 6.00098C0.447502 6.00098 0 6.44894 0 7.00089C0 7.55285 0.447502 8.00081 0.998889 8.00081Z"
                        fill="#D1433A" />
                      <path
                        d="M0.99889 1.99984C7.05765 1.99984 11.9867 6.93393 11.9867 12.9989C11.9867 13.5509 12.4342 13.9989 12.9856 13.9989C13.537 13.9989 13.9845 13.5509 13.9845 12.9989C13.9845 5.83152 8.15943 0 0.99889 0C0.447503 0 0 0.447963 0 0.999918C0 1.55187 0.447503 1.99984 0.99889 1.99984Z"
                        fill="#D1433A" />
                      <path
                        d="M0.998889 13.9989C1.55056 13.9989 1.99778 13.5512 1.99778 12.9989C1.99778 12.4467 1.55056 11.999 0.998889 11.999C0.447218 11.999 0 12.4467 0 12.9989C0 13.5512 0.447218 13.9989 0.998889 13.9989Z"
                        fill="#D1433A" />
                    </svg>

                  </button>
                </div>
                <div v-if="otherUser" class="artist-header-btns">
                  <button>
                    <svg
                      width="19"
                      height="17"
                      viewBox="0 0 19 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        class="artist-btn-active"
                        d="M5.02539 8.60498H12.1837"
                        stroke="#D1433A"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        class="artist-btn-active"
                        d="M5.02539 5.92139H14.8671"
                        stroke="#D1433A"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        class="artist-btn-active"
                        d="M2.99942 1H16.0006C16.5303 1.03291 17.0288 1.26219 17.3986 1.64302C17.7683 2.02385 17.9827 2.52887 18 3.05938V11.7374C17.9703 12.2391 17.7435 12.7088 17.3689 13.0439C16.9944 13.379 16.5025 13.5525 16.0006 13.5264H5.59965L1 16.0287V3.05938C1.01725 2.52887 1.2317 2.02385 1.60144 1.64302C1.97119 1.26219 2.46965 1.03291 2.99942 1V1Z"
                        stroke="#D1433A"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div v-if="otherUser" class="artist-header-btns" @click="openProfileShareModel">
                  <button>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        class="artist-btn-active"
                        d="M13.5 16.5C14.7426 16.5 15.75 15.4926 15.75 14.25C15.75 13.0074 14.7426 12 13.5 12C12.2574 12 11.25 13.0074 11.25 14.25C11.25 15.4926 12.2574 16.5 13.5 16.5Z"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        class="artist-btn-active"
                        d="M4.5 11.25C5.74264 11.25 6.75 10.2426 6.75 9C6.75 7.75736 5.74264 6.75 4.5 6.75C3.25736 6.75 2.25 7.75736 2.25 9C2.25 10.2426 3.25736 11.25 4.5 11.25Z"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        class="artist-btn-active"
                        d="M6.44336 10.1324L11.5659 13.1174"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        class="artist-btn-active"
                        d="M13.5 6C14.7426 6 15.75 4.99264 15.75 3.75C15.75 2.50736 14.7426 1.5 13.5 1.5C12.2574 1.5 11.25 2.50736 11.25 3.75C11.25 4.99264 12.2574 6 13.5 6Z"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        class="artist-btn-active"
                        d="M11.5584 4.88245L6.44336 7.86745"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div v-if="otherUser" class="artist-header-btns">
                  <b-dropdown class="meatball" size="lg" right variant="link" toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="artist-btn-active"
                              d="M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z"
                              fill="#D1433A" stroke="#D1433A" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round">
                        </path>
                        <path class="artist-btn-active"
                              d="M14.25 9.75C14.6642 9.75 15 9.41421 15 9C15 8.58579 14.6642 8.25 14.25 8.25C13.8358 8.25 13.5 8.58579 13.5 9C13.5 9.41421 13.8358 9.75 14.25 9.75Z"
                              fill="#D1433A" stroke="#D1433A" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round">
                        </path>
                        <path class="artist-btn-active"
                              d="M3.75 9.75C4.16421 9.75 4.5 9.41421 4.5 9C4.5 8.58579 4.16421 8.25 3.75 8.25C3.33579 8.25 3 8.58579 3 9C3 9.41421 3.33579 9.75 3.75 9.75Z"
                              fill="#D1433A" stroke="#D1433A" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round">
                        </path>
                      </svg>
                    </template>
                    <div class="meatball-dropdown">
                      <b-dropdown-item class="dropdown-item cursor-pointer" href="#" @click="openReportModal">
                        <div class="float-left">
                          <span class="icon-meatball">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2.66406 14.6667V10" stroke="#47505B" stroke-width="1.5" stroke-linejoin="round" />
                              <path d="M2.66406 10.0007C2.66406 10.0007 3.33073 9.33398 5.33073 9.33398C7.33073 9.33398 8.66406 10.6673 10.6641 10.6673C12.6641 10.6673 13.3307 10.0007 13.3307 10.0007V2.00065C13.3307 2.00065 12.6641 2.66732 10.6641 2.66732C8.66406 2.66732 7.33073 1.33398 5.33073 1.33398C3.33073 1.33398 2.66406 2.00065 2.66406 2.00065V10.0007Z" stroke="#47505B" stroke-width="1.5" stroke-linejoin="round" />
                            </svg>
                          </span>
                          <span class="font-meatball">Report Profile</span>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item class="dropdown-item cursor-pointer" href="#" @click="manageBlockingRedirect($event, 'Blocking')">
                        <div class="float-left">
                          <span class="icon-meatball">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8 1.33301C4.3181 1.33301 1.33333 4.31778 1.33333 7.99967C1.33333 11.6816 4.3181 14.6663 8 14.6663C11.6819 14.6663 14.6667 11.6816 14.6667 7.99967C14.6667 4.31778 11.6819 1.33301 8 1.33301ZM8 13.333C5.05467 13.333 2.66667 10.945 2.66667 7.99967C2.66667 5.05434 5.05467 2.66634 8 2.66634C10.9453 2.66634 13.3333 5.05434 13.3333 7.99967C13.3333 10.945 10.9453 13.333 8 13.333Z" fill="#47505B"/>
                              <path d="M10.6667 5.33301L5.33333 10.6663" stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </span>
                          <span class="font-meatball">Manage Blocking</span>
                        </div>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
<!--                <div v-if="otherUser" class="artist-header-btns">-->
<!--                  <button>-->
<!--                    <svg-->
<!--                      width="18"-->
<!--                      height="18"-->
<!--                      viewBox="0 0 18 18"-->
<!--                      fill="none"-->
<!--                      xmlns="http://www.w3.org/2000/svg"-->
<!--                    >-->
<!--                      <path-->
<!--                        class="artist-btn-active"-->
<!--                        d="M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z"-->
<!--                        fill="#D1433A"-->
<!--                        stroke="#D1433A"-->
<!--                        stroke-width="2"-->
<!--                        stroke-linecap="round"-->
<!--                        stroke-linejoin="round"-->
<!--                      ></path>-->
<!--                      <path-->
<!--                        class="artist-btn-active"-->
<!--                        d="M14.25 9.75C14.6642 9.75 15 9.41421 15 9C15 8.58579 14.6642 8.25 14.25 8.25C13.8358 8.25 13.5 8.58579 13.5 9C13.5 9.41421 13.8358 9.75 14.25 9.75Z"-->
<!--                        fill="#D1433A"-->
<!--                        stroke="#D1433A"-->
<!--                        stroke-width="2"-->
<!--                        stroke-linecap="round"-->
<!--                        stroke-linejoin="round"-->
<!--                      ></path>-->
<!--                      <path-->
<!--                        class="artist-btn-active"-->
<!--                        d="M3.75 9.75C4.16421 9.75 4.5 9.41421 4.5 9C4.5 8.58579 4.16421 8.25 3.75 8.25C3.33579 8.25 3 8.58579 3 9C3 9.41421 3.33579 9.75 3.75 9.75Z"-->
<!--                        fill="#D1433A"-->
<!--                        stroke="#D1433A"-->
<!--                        stroke-width="2"-->
<!--                        stroke-linecap="round"-->
<!--                        stroke-linejoin="round"-->
<!--                      ></path>-->
<!--                    </svg>-->
<!--                  </button>-->
<!--                </div>-->
              </div>
              <div class="work-tab-message mt_10" style="display: none">
                <div class="message-work">
                  <button>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.75 10.25C14.75 10.6478 14.592 11.0294 14.3107 11.3107C14.0294 11.592 13.6478 11.75 13.25 11.75H4.25L1.25 14.75V2.75C1.25 2.35218 1.40804 1.97064 1.68934 1.68934C1.97064 1.40804 2.35218 1.25 2.75 1.25H13.25C13.6478 1.25 14.0294 1.40804 14.3107 1.68934C14.592 1.97064 14.75 2.35218 14.75 2.75V10.25Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    MESSAGE
                  </button>
                </div>
                <div class="add-user-message">
                  <button>
                    <svg
                      width="18"
                      height="16"
                      viewBox="0 0 18 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        class="add-user-message-active"
                        d="M13.0236 15V13.5373C13.0236 12.7615 12.7069 12.0174 12.1432 11.4688C11.5795 10.9201 10.8149 10.6119 10.0177 10.6119H4.0059C3.20868 10.6119 2.44412 10.9201 1.88041 11.4688C1.31669 12.0174 1 12.7615 1 13.5373V15"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        class="add-user-message-active"
                        d="M7.01371 7.68656C8.67382 7.68656 10.0196 6.37683 10.0196 4.76119C10.0196 3.14555 8.67382 1.83582 7.01371 1.83582C5.3536 1.83582 4.00781 3.14555 4.00781 4.76119C4.00781 6.37683 5.3536 7.68656 7.01371 7.68656Z"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        class="add-user-message-active"
                        d="M14.4961 1V5.70722"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        class="add-user-message-active"
                        d="M12.1602 3.35361H16.997"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div class="share-message-work">
                  <button>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        class="artist-btn-active"
                        d="M13.5 16.5C14.7426 16.5 15.75 15.4926 15.75 14.25C15.75 13.0074 14.7426 12 13.5 12C12.2574 12 11.25 13.0074 11.25 14.25C11.25 15.4926 12.2574 16.5 13.5 16.5Z"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        class="artist-btn-active"
                        d="M4.5 11.25C5.74264 11.25 6.75 10.2426 6.75 9C6.75 7.75736 5.74264 6.75 4.5 6.75C3.25736 6.75 2.25 7.75736 2.25 9C2.25 10.2426 3.25736 11.25 4.5 11.25Z"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        class="artist-btn-active"
                        d="M6.44336 10.1324L11.5659 13.1174"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        class="artist-btn-active"
                        d="M13.5 6C14.7426 6 15.75 4.99264 15.75 3.75C15.75 2.50736 14.7426 1.5 13.5 1.5C12.2574 1.5 11.25 2.50736 11.25 3.75C11.25 4.99264 12.2574 6 13.5 6Z"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        class="artist-btn-active"
                        d="M11.5584 4.88245L6.44336 7.86745"
                        stroke="#D1433A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="toggleCreateArticle" class="tab-content row user-artcile">
          <createArticleUserProfile @OpenCreateArticle="toggleCreateArticleClose"/>
        </div>
        <div v-else class="tab-content row" id="nav-tabContent">

          <!-- Posts Content Section Start -->

          <div :class="['col tab-pane fade', active_sub_tab === 'posts' ? 'show active' : '']"
            id="timeline"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            <div v-if="active_sub_tab === 'posts'"
              class="row my-3"
            >
              <div class="col-8">
                <spinner v-if="getSpinnerData && (getSpinnerData.display && getSpinnerData.for === 'posts')"/>
                <div v-else>
                  <div
                    v-if="!otherUser || !userData.who_can_post_on_your_wall || checkIfPageOwnerIsYourFriend(userData.id)"
                    class="row">
                    <div class="col-md-8 pr-10">
                      <div class="post-field mb_20">
                        <div class="user-post-filed-image">
                          <img
                            :src="profilePicture"
                            @error="onProfileImageError($event, getFirstAlphabetOfUserName(user))"
                          />
                        </div>
                        <div class="post-something-field">
                          <input
                            type="text"
                            placeholder="Post Something"
                            v-on:click="createPost"
                            readonly
                          />
                          <span class="post_emoji">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.45 15.9C12.5645 15.9 15.9 12.5645 15.9 8.45C15.9 4.33548 12.5645 1 8.45 1C4.33548 1 1 4.33548 1 8.45C1 12.5645 4.33548 15.9 8.45 15.9Z"
                            stroke="#47505B"
                            stroke-width="1.3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5.52637 10.1211C5.85233 10.5652 6.27635 10.9282 6.76545 11.1818C7.25455 11.4353 7.79554 11.5727 8.34637 11.5831C8.89719 11.5727 9.43819 11.4353 9.92729 11.1818C10.4164 10.9282 10.8404 10.5652 11.1664 10.1211"
                            stroke="#47505B"
                            stroke-width="1.3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.3314 7.82095C11.8682 7.82095 12.3034 7.38577 12.3034 6.84895C12.3034 6.31213 11.8682 5.87695 11.3314 5.87695C10.7946 5.87695 10.3594 6.31213 10.3594 6.84895C10.3594 7.38577 10.7946 7.82095 11.3314 7.82095Z"
                            fill="#47505B"
                          />
                          <path
                            d="M5.5677 7.82095C6.10452 7.82095 6.5397 7.38577 6.5397 6.84895C6.5397 6.31213 6.10452 5.87695 5.5677 5.87695C5.03088 5.87695 4.5957 6.31213 4.5957 6.84895C4.5957 7.38577 5.03088 7.82095 5.5677 7.82095Z"
                            fill="#47505B"
                          />
                        </svg>
                      </span>
                        </div>
                        <div class="send-btn">
                          <svg
                            width="27"
                            height="26"
                            viewBox="0 0 27 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0)">
                              <path
                                d="M24.3336 12.7282L12.6664 12.7282"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M24.3332 12.7277L10.0143 19.622L12.6659 12.7277L10.0143 5.8334L24.3332 12.7277Z"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0">
                                <rect
                                  width="18"
                                  height="18"
                                  fill="white"
                                  transform="translate(13.7266) rotate(45)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 pl-10">
                      <div
                        class="artist-music-profile-select user-profile"
                        v-on:click="optionOptions = true"
                      >
                        <div class="main-poset-select-box">
                          <div class="mt-1">
                            <ul disabled>
                              <li v-on:click="optionOptions = true" class="cursor-pointer">{{ filterName }}</li>
                            </ul>
                            <span class="mt-1">
                           <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M10.4336 1.33691L5.75458 6.43303L0.663575 1.33691" stroke="#D1433A" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col" v-if="!post_list.length">
                      <NoDataComponent
                        header="There are no Posts yet"
                        content="Post your first article, message or image in your Post Feed!"
                      />
                    </div>
                    <div class="col" v-else>
                      <PostDetail
                        v-for="(eachOp, index) in post_list"
                        :key="'post-detail' + eachOp.id"
                        :index="index"
                        :item="eachOp"
                        :pageCheck="'timeline'"
                        :userPicture="userData.artwork_url"
                      />
                    </div>
                    <spinner v-if="getSpinnerData && (getSpinnerData.display && getSpinnerData.for === 'more_posts')"/>
                    <div class="col" v-if="is_more_posts && !getSpinnerData">
                      <div class="show_more_btn" style="margin-bottom: 80px">
                        <div class="show-more musicbar_more">
                          <a @click="showMorePosts" href="javascript:;">
                            <span class="show_more_bg">
                              <svg
                                width="9"
                                height="8"
                                viewBox="0 0 9 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M4.24219 0.689941V7.01994"
                                      stroke="#071526"
                                      stroke-width="1.3"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                >
                                </path>
                                <path d="M1 3.85498H7.48702" stroke="#071526" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg>
                              <span class="mt-1">
                                SHOW MORE
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <create-post
                      @OpenCreateArticle="OpenCreateArticle"
                      :fromUserProfile="true"
                      ref="post"
                      :check="check"
                      :picture="userData.artwork_url"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="content-sidebar">
                  <FriendCollectionWishlist
                    :followersCount="followersCount"
                    :followingCount="followingCount"
                    :friendsCount="friendsCount"
                  />
                </div>
                <ProfileCompletionStatus
                  v-if="!otherUser"
                  :profileAndCoverModelShow="openModelProfileAndCover"
                  :modelType="modelTypeOfProfileAndCover"
                  @profileAndCoverValue="profileAndCoverValueFromChild"
                  @profileAndCoverType="profileAndCoverValueFromChildType"
                />
                <AboutWidget
                  :userData="userData"
                  :work_list="work_list"
                  @change_active_tab="change_active_tab"
                />
                <WidgetPhotoView
                  v-if="!otherUser || !userData.who_can_see_your_photos || checkIfPageOwnerIsYourFriend(userData.id)"
                  @change_active_tab="change_active_tab"
                  @getWidgetPhotosLists="getWidgetPhotos"
                  :listData="mediaPhotosList"
                  :totalMediaPhotos="mediaPhotosCount"
                  :is-media-loading="mediaPhotosLoading"
                />
                <FriendListDetail
                  v-if="!otherUser || !userData.who_can_see_your_friends || checkIfPageOwnerIsYourFriend(userData.id)"
                  @change_active_tab="change_active_tab" />
                <ListeningHistory
                  :listeningSongs="listeningHistoryWidget.songs"
                  @change_active_tab="change_active_tab"
                  :isDataLoading="listeningHistoryWidget.loading"
                />

<!--                Commented the social section on the request -->

<!--                <SocialWidget />-->
              </div>
            </div>
          </div>

          <!-- Posts Content Section End -->

          <!-- Collection Content Section Start --->

          <div :class="['tab-pane fade', active_sub_tab === 'collection' ? 'show active' : '']"
            id="Collection"
            role="tabpanel"
            aria-labelledby="nav-profile-tab"
          >
            <div v-if="active_sub_tab === 'collection'"
              class="row my-3"
            >
              <div class="col-8">
                <div class="collection_tab width770">
                  <nav>
                    <div class="nav ct" id="nav-tab" role="tablist">
                      <a
                        class="nav-item nav-link active"
                        id="nav-home-tab"
                        data-toggle="tab"
                        href="#nav-music"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >music</a
                      >
                      <a
                        class="nav-item nav-link"
                        id="nav-profile-tab"
                        data-toggle="tab"
                        href="#nav-podcasts"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >podcasts</a
                      >
                      <!-- <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-merch" role="tab"
                         aria-controls="nav-contact" aria-selected="false">merch</a> -->
                      <a
                        class="nav-item nav-link"
                        id="nav-sampless-tab"
                        data-toggle="tab"
                        href="#nav-sampless"
                        role="tab"
                        aria-controls="nav-contact"
                        aria-selected="false"
                      >SAMPLES</a
                      >
                      <a
                        class="nav-item nav-link"
                        id="nav-licenses-tab"
                        data-toggle="tab"
                        href="#nav-licenses"
                        role="tab"
                        aria-controls="nav-contact"
                        aria-selected="false"
                      >LICENSES</a
                      >
                      <!-- TODO: NOTE: NFT’s tab is commented out because it will be implemented in future -->
<!--                      <a-->
<!--                        class="nav-item nav-link"-->
<!--                        id="nav-NFT’s-tab"-->
<!--                        data-toggle="tab"-->
<!--                        href="#nav-NFT’s"-->
<!--                        role="tab"-->
<!--                        aria-controls="nav-contact"-->
<!--                        aria-selected="false"-->
<!--                      >-->
<!--                        NFT’s-->
<!--                      </a>-->
                      <a
                        class="nav-item nav-link"
                        id="nav-memberships-tab"
                        data-toggle="tab"
                        href="#nav-memberships"
                        role="tab"
                        aria-controls="nav-contact"
                        aria-selected="false"
                      >MEMBERSHIPS</a
                      >
                    </div>
                  </nav>

                  <div class="tab-content" id="nav-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="nav-music"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                    >
                      <div class="main-music-tab-content">
                        <MyMusicEmptyCard
                          @redirectFunction="redirectFunction"
                          :title="' You haven’t added any Audio, NFT’s or Memberships to your Collection yet'"
                          :text="'Start owning Music, Samples, Podcasts, NFT’s or Memberships from your favorite artists and labels!'"
                          :icon="'play'"
                          :buttonText="'EXPLORE MUSIC'"
                        />
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="nav-podcasts"
                      role="tabpanel"
                      aria-labelledby="nav-profile-tab"
                    >
                      <div class="main-music-tab-content">
                        <MyMusicEmptyCard
                          @redirectFunction="redirectFunction"
                          :title="' You haven’t added any Audio, NFT’s or Memberships to your Collection yet'"
                          :text="'Start owning Music, Samples, Podcasts, NFT’s or Memberships from your favorite artists and labels!'"
                          :icon="'play'"
                          :buttonText="'EXPLORE MUSIC'"
                        />
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="nav-sampless"
                      role="tabpanel"
                      aria-labelledby="nav-profile-tab"
                    >
                      <div class="main-music-tab-content">
                        <MyMusicEmptyCard
                          @redirectFunction="redirectFunction"
                          :title="' You haven’t added any Audio, NFT’s or Memberships to your Collection yet'"
                          :text="'Start owning Music, Samples, Podcasts, NFT’s or Memberships from your favorite artists and labels!'"
                          :icon="'play'"
                          :buttonText="'EXPLORE MUSIC'"
                        />
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="nav-licenses"
                      role="tabpanel"
                      aria-labelledby="nav-profile-tab"
                    >
                      <div class="main-music-tab-content">
                        <MyMusicEmptyCard
                          @redirectFunction="redirectFunction"
                          :title="' You haven’t added any Audio, NFT’s or Memberships to your Collection yet'"
                          :text="'Start owning Music, Samples, Podcasts, NFT’s or Memberships from your favorite artists and labels!'"
                          :icon="'play'"
                          :buttonText="'EXPLORE MUSIC'"
                        />
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="nav-NFT’s"
                      role="tabpanel"
                      aria-labelledby="nav-profile-tab"
                    >
                      <div class="main-music-tab-content">
                        <MyMusicEmptyCard
                          @redirectFunction="redirectFunction"
                          :title="' You haven’t added any Audio, NFT’s or Memberships to your Collection yet'"
                          :text="'Start owning Music, Samples, Podcasts, NFT’s or Memberships from your favorite artists and labels!'"
                          :icon="'play'"
                          :buttonText="'EXPLORE MUSIC'"
                        />
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="nav-memberships"
                      role="tabpanel"
                      aria-labelledby="nav-profile-tab"
                    >
                      <div class="main-music-tab-content">
                        <MyMusicEmptyCard
                          @redirectFunction="redirectFunction"
                          :title="' You haven’t added any Audio, NFT’s or Memberships to your Collection yet'"
                          :text="'Start owning Music, Samples, Podcasts, NFT’s or Memberships from your favorite artists and labels!'"
                          :icon="'play'"
                          :buttonText="'EXPLORE MUSIC'"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="content-sidebar width370">
                  <FriendCollectionWishlist
                    :followersCount="followersCount"
                    :followingCount="followingCount"
                    :friendsCount="friendsCount"
                  />
                  <AboutWidget
                    :userData="userData"
                    :work_list="work_list"
                    @change_active_tab="change_active_tab"
                  />
                  <WidgetPhotoView
                    v-if="!otherUser || !userData.who_can_see_your_photos || checkIfPageOwnerIsYourFriend(userData.id)"
                    @change_active_tab="change_active_tab"
                    @getWidgetPhotosLists="getWidgetPhotos"
                    :listData="mediaPhotosList"
                    :totalMediaPhotos="mediaPhotosCount"
                    :is-media-loading="mediaPhotosLoading"
                  />
                  <FriendListDetail
                    v-if="!otherUser || !userData.who_can_see_your_friends || checkIfPageOwnerIsYourFriend(userData.id)"
                    @change_active_tab="change_active_tab" />
                  <ListeningHistory
                    :listeningSongs="listeningHistoryWidget.songs"
                    @change_active_tab="change_active_tab"
                    :isDataLoading="listeningHistoryWidget.loading"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Collection Content Section End --->

          <!-- Favorite Content Section Start --->

          <div :class="['tab-pane fade', active_sub_tab === 'wishlist' ? 'show active' : '']"
            id="Wishlist"
            role="tabpanel"
            aria-labelledby="nav-contact-tab"
          >
            <div v-if="active_sub_tab === 'wishlist'"
              class="row my-3"
            >
              <favorites :allFavoriteSongs="allFavoriteSongs"
                         :totalFavoriteSongs="totalFavoriteSongs"
                         class="col-8"
              />
              <div class="col-md-4">
                <div class="content-sidebar width370">
                  <FriendCollectionWishlist
                    :followersCount="followersCount"
                    :followingCount="followingCount"
                    :friendsCount="friendsCount"
                  />
                  <AboutWidget
                    :userData="userData"
                    :work_list="work_list"
                    @change_active_tab="change_active_tab"
                  />
                  <WidgetPhotoView
                    v-if="!otherUser || !userData.who_can_see_your_photos || checkIfPageOwnerIsYourFriend(userData.id)"
                    @change_active_tab="change_active_tab"
                    @getWidgetPhotosLists="getWidgetPhotos"
                    :listData="mediaPhotosList"
                    :totalMediaPhotos="mediaPhotosCount"
                    :is-media-loading="mediaPhotosLoading"
                  />
                  <FriendListDetail
                    v-if="!otherUser || !userData.who_can_see_your_friends || checkIfPageOwnerIsYourFriend(userData.id)"
                    @change_active_tab="change_active_tab" />
                  <ListeningHistory
                    :listeningSongs="listeningHistoryWidget.songs"
                    @change_active_tab="change_active_tab"
                    :isDataLoading="listeningHistoryWidget.loading"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Favorite Content Section End --->

          <!-- Events Content Section Start --->

          <div :class="['tab-pane fade', active_sub_tab === 'events' ? 'show active' : '']"
            id="event"
            role="tabpanel"
            aria-labelledby="nav-contact-tab"
          >
            <div v-if="active_sub_tab === 'events'"
              class="row my-3"
            >
              <div class="col-8">
                <div class="event-main width770">
                  <div class="detail-tab detail-tab-artist event_tab">
                    <nav>
                      <div
                        id="nav-tab1"
                        role="tablist"
                        class="nav top-tab nav-tabs1"
                      >
                        <a
                          id="nav-upcoming-tab"
                          data-toggle="tab"
                          href="#nav-upcoming"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="false"
                          class="nav-item nav-link active"
                        >Upcoming
                          <span class="event_counting">{{ getUpcomingTotal }}</span>
                        </a>
                        <a
                          id="nav-past-tab"
                          data-toggle="tab"
                          href="#nav-past"
                          role="tab"
                          aria-controls="nav-profile"
                          aria-selected="false"
                          class="nav-item nav-link"
                        >Past
                          <span class="event_counting">{{ getPastTotal }}</span>
                        </a>
                      </div>
                    </nav>
                    <span class="friend_tab_search_filter">
                    <div class="change-view js-change-view">
                      <div class="btn-group">
                        <div
                          @click="changeTabsAccordingType({ tab_name: 'grid', tab_Type: 'activ_form'})"
                          data-type="grid"
                          class="btn btn-svg-icon js-set-view "
                          :class="{active: activ_form.tab_name === 'grid'}"
                        >
                          <div
                            data-type="grid"
                            class="svg-icon show-grid grid-svd"
                          >
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M30 0H0V30H30V0Z"
                                fill="#D1433A"
                                class="c-fill-1"
                              ></path>
                              <path
                                d="M16.5 13.5V8.5H21.5V13.5H16.5Z"
                                stroke="white"
                                stroke-linecap="square"
                                class="c-line-1"
                              ></path>
                              <path
                                d="M8.5 13.5V8.5H13.5V13.5H8.5Z"
                                stroke="white"
                                stroke-linecap="square"
                                class="c-line-1"
                              ></path>
                              <path
                                d="M16.5 21.5V16.5H21.5V21.5H16.5Z"
                                stroke="white"
                                stroke-linecap="square"
                                class="c-line-1"
                              ></path>
                              <path
                                d="M8.5 21.5V16.5H13.5V21.5H8.5Z"
                                stroke="white"
                                stroke-linecap="square"
                                class="c-line-1"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div
                          @click="
                            changeTabsAccordingType({
                            tab_name: 'list',
                            tab_Type: 'activ_form',
                             })
                              "
                          class="btn btn-svg-icon js-set-view">
                          <div
                            data-type="list"
                            class="svg-icon show-list list-svd"
                            :class="{active: activ_form.tab_name === 'list'}"
                          >
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M30 0H0V30H30V0Z"
                                fill="white"
                                class="c-fill-1"
                              ></path>
                              <path
                                d="M8.5 12.5V8.5H21.5V12.5H8.5Z"
                                stroke="#8B949F"
                                stroke-linecap="square"
                                class="c-line-1"
                              ></path>
                              <path
                                d="M8.5 21.5V17.5H21.5V21.5H8.5Z"
                                stroke="#8B949F"
                                stroke-linecap="square"
                                class="c-line-1"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                  </div>
                </div>
                <spinner v-if="getSpinnerData && (getSpinnerData.display && getSpinnerData.for === active_sub_tab)" />
                <div v-else id="myTabContent" class="tab-content">
                  <div
                    id="nav-upcoming"
                    role="tabpanel"
                    aria-labelledby="nav-upcoming-tab"
                    class="tab-pane fade active show"
                  >

                    <div v-if="getUpcomingEvents.length > 0 && activ_form.tab_name === 'grid'"
                         style="display: flex; flex-wrap: wrap; gap: 1.86rem; row-gap: 1rem;">
                      <EventsCard
                        v-for="(event, index) in getUpcomingEvents"
                        :key="index"
                        :event="event"
                      />
                    </div>
                    <div class="listEventCard" v-else-if="getUpcomingEvents.length > 0 && activ_form.tab_name === 'list'">
                      <EventListCart
                        v-for="item in getUpcomingEvents"
                        :key="item.id"
                        :event="item"
                      />
                    </div>
                    <div v-else>
                      <MyMusicEmptyCard
                        :title="'No Upcoming Events yet'"
                        :text="'You haven`t confirmed that you`re going to any events yet. Let`s find some events!'"
                        :icon="'coupon'"
                        :buttonText="'EXPLORE EVENTS'"
                      />
                    </div>
                    <div class="show_more_btn" v-if="getUpcomingEvents.length > 8">
                      <div class="show-more musicbar_more">
                        <a @click="getMoreEvents('upcoming')" href="javascript:;"><span class="show_more_bg"><svg
                          width="9"
                          height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.24219 0.689941V7.01994" stroke="#071526" stroke-width="1.3"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M1 3.85498H7.48702" stroke="#071526" stroke-width="1.3" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                          </svg>
                          SHOW MORE
                        </span></a>
                      </div>
                    </div>
                  </div>
                  <div
                    id="nav-past"
                    role="tabpanel"
                    aria-labelledby="nav-past-tab"
                    class="tab-pane fade"
                  >
                    <div v-if="getPastEvents.length > 0 && activ_form.tab_name === 'grid'"
                         style="display: flex; flex-wrap: wrap; gap: 1.86rem; row-gap: 1rem;">
                      <EventsCard
                        v-for="(event, index) in getPastEvents"
                        :key="index"
                        :event="event"
                      />
                    </div>
                    <div class="listEventCard" v-else-if="getPastEvents.length > 0 && activ_form.tab_name === 'list'">
                      <EventListCart
                        v-for="item in getPastEvents"
                        :key="item.id"
                        :event="item"
                      />
                    </div>
                    <div v-else>
                      <MyMusicEmptyCard
                        :title="'No Past Events yet'"
                        :text="'You haven`t confirmed that you`re going to any events yet. Let`s find some events!'"
                        :icon="'coupon'"
                        :buttonText="'EXPLORE EVENTS'"
                      />
                    </div>
                    <div class="show_more_btn" v-if="getPastEvents.length >= 8 ">
                      <div class="show-more musicbar_more">
                        <a @click="getMoreEvents('past')"
                           href="javascript:;"><span class="show_more_bg"><svg width="9" height="8" viewBox="0 0 9 8"
                                                                               fill="none"
                                                                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.24219 0.689941V7.01994" stroke="#071526" stroke-width="1.3"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M1 3.85498H7.48702" stroke="#071526" stroke-width="1.3" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                          </svg>
                          SHOW MORE
                        </span></a>
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class="col-12">
                        <div class="past-events_main">
                          <div class="past_event_main_img">
                            <img :src="require(`@/assets/img/rec_img.png`)" />
                          </div>
                          <div class="past-events_main_detail">
                            <h1>Playgrøund presents Nina Kraviz</h1>
                            <div class="past-events_main_detail_sub">
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0)">
                                  <path
                                    d="M12.25 5.8335C12.25 9.91683 7 13.4168 7 13.4168C7 13.4168 1.75 9.91683 1.75 5.8335C1.75 4.44111 2.30312 3.10575 3.28769 2.12119C4.27226 1.13662 5.60761 0.583496 7 0.583496C8.39239 0.583496 9.72774 1.13662 10.7123 2.12119C11.6969 3.10575 12.25 4.44111 12.25 5.8335Z"
                                    stroke="#47505B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M7 7.5835C7.9665 7.5835 8.75 6.79999 8.75 5.8335C8.75 4.867 7.9665 4.0835 7 4.0835C6.0335 4.0835 5.25 4.867 5.25 5.8335C5.25 6.79999 6.0335 7.5835 7 7.5835Z"
                                    stroke="#47505B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0">
                                    <rect width="14" height="14" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              <p>Albert Hall in Manchester</p>
                            </div>
                            <div class="past-events_main_detail_sub">
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.0833 2.3335H2.91667C2.27233 2.3335 1.75 2.85583 1.75 3.50016V11.6668C1.75 12.3112 2.27233 12.8335 2.91667 12.8335H11.0833C11.7277 12.8335 12.25 12.3112 12.25 11.6668V3.50016C12.25 2.85583 11.7277 2.3335 11.0833 2.3335Z"
                                  stroke="#47505B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M9.33203 1.1665V3.49984"
                                  stroke="#47505B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M4.66797 1.1665V3.49984"
                                  stroke="#47505B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M1.75 5.8335H12.25"
                                  stroke="#47505B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>

                              <p>Friday 16th October</p>
                            </div>
                            <div class="past-events_main_detail_sub">
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.0013 12.8332C10.223 12.8332 12.8346 10.2215 12.8346 6.99984C12.8346 3.77818 10.223 1.1665 7.0013 1.1665C3.77964 1.1665 1.16797 3.77818 1.16797 6.99984C1.16797 10.2215 3.77964 12.8332 7.0013 12.8332Z"
                                  stroke="#47505B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7 3.5V7L9.33333 8.16667"
                                  stroke="#47505B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <p>8:00pm til 4:00am (last entry 1:00am)</p>
                            </div>
                          </div>
                          <div class="user_count_top">
                            <svg
                              width="13"
                              height="12"
                              viewBox="0 0 13 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
                                stroke="white"
                                stroke-width="1.3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M1.01197 10.9998C1.01197 10.9998 0.573983 8.51785 4.99598 8.42285C4.99598 8.42285 7.99595 8.42285 8.12995 8.42285C12.23 8.42285 12.276 10.9998 12.276 10.9998"
                                stroke="white"
                                stroke-width="1.3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <span>000</span>
                          </div>
                          <div class="int_bottom">
                            <div class="inter-going-btn">
                              <button class="Interested_btn btn_act">
                                Interested
                              </button>
                              <button class="Interested_btn_going">Going</button>
                            </div>
                          </div>
                        </div>
                        <div class="past-events_main">
                          <div class="past_event_main_img">
                            <img :src="require(`@/assets/img/rec_img.png`)" />
                          </div>
                          <div class="past-events_main_detail">
                            <h1>Playgrøund presents Nina Kraviz</h1>
                            <div class="past-events_main_detail_sub">
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0)">
                                  <path
                                    d="M12.25 5.8335C12.25 9.91683 7 13.4168 7 13.4168C7 13.4168 1.75 9.91683 1.75 5.8335C1.75 4.44111 2.30312 3.10575 3.28769 2.12119C4.27226 1.13662 5.60761 0.583496 7 0.583496C8.39239 0.583496 9.72774 1.13662 10.7123 2.12119C11.6969 3.10575 12.25 4.44111 12.25 5.8335Z"
                                    stroke="#47505B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M7 7.5835C7.9665 7.5835 8.75 6.79999 8.75 5.8335C8.75 4.867 7.9665 4.0835 7 4.0835C6.0335 4.0835 5.25 4.867 5.25 5.8335C5.25 6.79999 6.0335 7.5835 7 7.5835Z"
                                    stroke="#47505B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0">
                                    <rect width="14" height="14" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              <p>Albert Hall in Manchester</p>
                            </div>
                            <div class="past-events_main_detail_sub">
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.0833 2.3335H2.91667C2.27233 2.3335 1.75 2.85583 1.75 3.50016V11.6668C1.75 12.3112 2.27233 12.8335 2.91667 12.8335H11.0833C11.7277 12.8335 12.25 12.3112 12.25 11.6668V3.50016C12.25 2.85583 11.7277 2.3335 11.0833 2.3335Z"
                                  stroke="#47505B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M9.33203 1.1665V3.49984"
                                  stroke="#47505B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M4.66797 1.1665V3.49984"
                                  stroke="#47505B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M1.75 5.8335H12.25"
                                  stroke="#47505B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>

                              <p>Friday 16th October</p>
                            </div>
                            <div class="past-events_main_detail_sub">
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.0013 12.8332C10.223 12.8332 12.8346 10.2215 12.8346 6.99984C12.8346 3.77818 10.223 1.1665 7.0013 1.1665C3.77964 1.1665 1.16797 3.77818 1.16797 6.99984C1.16797 10.2215 3.77964 12.8332 7.0013 12.8332Z"
                                  stroke="#47505B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7 3.5V7L9.33333 8.16667"
                                  stroke="#47505B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <p>8:00pm til 4:00am (last entry 1:00am)</p>
                            </div>
                          </div>
                          <div class="user_count_top">
                            <svg
                              width="13"
                              height="12"
                              viewBox="0 0 13 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
                                stroke="white"
                                stroke-width="1.3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M1.01197 10.9998C1.01197 10.9998 0.573983 8.51785 4.99598 8.42285C4.99598 8.42285 7.99595 8.42285 8.12995 8.42285C12.23 8.42285 12.276 10.9998 12.276 10.9998"
                                stroke="white"
                                stroke-width="1.3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <span>000</span>
                          </div>
                          <div class="int_bottom">
                            <div class="inter-going-btn">
                              <button class="Interested_btn btn_act">
                                Interested
                              </button>
                              <button class="Interested_btn_going">Going</button>
                            </div>
                          </div>
                        </div>
                        <div class="past-events_main">
                          <div class="past_event_main_img">
                            <img :src="require(`@/assets/img/rec_img.png`)" />
                          </div>
                          <div class="past-events_main_detail">
                            <h1>Playgrøund presents Nina Kraviz</h1>
                            <div class="past-events_main_detail_sub">
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0)">
                                  <path
                                    d="M12.25 5.8335C12.25 9.91683 7 13.4168 7 13.4168C7 13.4168 1.75 9.91683 1.75 5.8335C1.75 4.44111 2.30312 3.10575 3.28769 2.12119C4.27226 1.13662 5.60761 0.583496 7 0.583496C8.39239 0.583496 9.72774 1.13662 10.7123 2.12119C11.6969 3.10575 12.25 4.44111 12.25 5.8335Z"
                                    stroke="#47505B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M7 7.5835C7.9665 7.5835 8.75 6.79999 8.75 5.8335C8.75 4.867 7.9665 4.0835 7 4.0835C6.0335 4.0835 5.25 4.867 5.25 5.8335C5.25 6.79999 6.0335 7.5835 7 7.5835Z"
                                    stroke="#47505B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0">
                                    <rect width="14" height="14" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              <p>Albert Hall in Manchester</p>
                            </div>
                            <div class="past-events_main_detail_sub">
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.0833 2.3335H2.91667C2.27233 2.3335 1.75 2.85583 1.75 3.50016V11.6668C1.75 12.3112 2.27233 12.8335 2.91667 12.8335H11.0833C11.7277 12.8335 12.25 12.3112 12.25 11.6668V3.50016C12.25 2.85583 11.7277 2.3335 11.0833 2.3335Z"
                                  stroke="#47505B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M9.33203 1.1665V3.49984"
                                  stroke="#47505B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M4.66797 1.1665V3.49984"
                                  stroke="#47505B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M1.75 5.8335H12.25"
                                  stroke="#47505B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>

                              <p>Friday 16th October</p>
                            </div>
                            <div class="past-events_main_detail_sub">
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.0013 12.8332C10.223 12.8332 12.8346 10.2215 12.8346 6.99984C12.8346 3.77818 10.223 1.1665 7.0013 1.1665C3.77964 1.1665 1.16797 3.77818 1.16797 6.99984C1.16797 10.2215 3.77964 12.8332 7.0013 12.8332Z"
                                  stroke="#47505B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7 3.5V7L9.33333 8.16667"
                                  stroke="#47505B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <p>8:00pm til 4:00am (last entry 1:00am)</p>
                            </div>
                          </div>
                          <div class="user_count_top">
                            <svg
                              width="13"
                              height="12"
                              viewBox="0 0 13 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
                                stroke="white"
                                stroke-width="1.3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M1.01197 10.9998C1.01197 10.9998 0.573983 8.51785 4.99598 8.42285C4.99598 8.42285 7.99595 8.42285 8.12995 8.42285C12.23 8.42285 12.276 10.9998 12.276 10.9998"
                                stroke="white"
                                stroke-width="1.3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <span>000</span>
                          </div>
                          <div class="int_bottom">
                            <div class="inter-going-btn">
                              <button class="Interested_btn btn_act">
                                Interested
                              </button>
                              <button class="Interested_btn_going">Going</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="content-sidebar width370">
                  <FriendCollectionWishlist
                    :followersCount="followersCount"
                    :followingCount="followingCount"
                    :friendsCount="friendsCount"
                  />
                  <AboutWidget
                    :userData="userData"
                    :work_list="work_list"
                    @change_active_tab="change_active_tab"
                  />
                  <WidgetPhotoView
                    v-if="!otherUser || !userData.who_can_see_your_photos || checkIfPageOwnerIsYourFriend(userData.id)"
                    @change_active_tab="change_active_tab"
                    @getWidgetPhotosLists="getWidgetPhotos"
                    :listData="mediaPhotosList"
                    :totalMediaPhotos="mediaPhotosCount"
                    :is-media-loading="mediaPhotosLoading"
                  />
                  <FriendListDetail
                    v-if="!otherUser || !userData.who_can_see_your_friends || checkIfPageOwnerIsYourFriend(userData.id)"
                    @change_active_tab="change_active_tab" />
                  <ListeningHistory
                    :listeningSongs="listeningHistoryWidget.songs"
                    @change_active_tab="change_active_tab"
                    :isDataLoading="listeningHistoryWidget.loading"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Events Content Section End -->


          <div :class="['tab-pane fade', active_sub_tab === 'media_main' ? 'show active' : '']"
            id="media_main"
            role="tabpanel"
            aria-labelledby="nav-profile-tab1"
          >
            <div v-if="active_sub_tab === 'media_main'"
              class="row my-3"
            >
              <media
                :edit="!otherUser"
                class="col-8"
                :user_id="otherUser ? userData.id : null"
              />
              <div class="col-md-4">
                <div class="content-sidebar width370">
                  <FriendCollectionWishlist
                    :followersCount="followersCount"
                    :followingCount="followingCount"
                    :friendsCount="friendsCount"
                  />
                  <AboutWidget
                    :userData="userData"
                    :work_list="work_list"
                    @change_active_tab="change_active_tab"
                  />
                  <FriendListDetail
                    v-if="!otherUser || !userData.who_can_see_your_friends || checkIfPageOwnerIsYourFriend(userData.id)"
                    @change_active_tab="change_active_tab" />
                  <ListeningHistory
                    :listeningSongs="listeningHistoryWidget.songs"
                    @change_active_tab="change_active_tab"
                    :isDataLoading="listeningHistoryWidget.loading"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- About Content Section Start -->
          <div :class="['tab-pane fade', active_sub_tab === 'about' ? 'show active' : '']"
            id="about"
            role="tabpanel"
            aria-labelledby="nav-contact-tab"
          >
            <div v-if="active_sub_tab === 'about'"
              class="row my-3"
            >
              <about-main-page class="col-8 aboutMainSec" :user-data="userData"/>
              <div class="col-md-4">
                <div class="content-sidebar width370">
                  <FriendCollectionWishlist
                    :followersCount="followersCount"
                    :followingCount="followingCount"
                    :friendsCount="friendsCount"
                  />
                  <!-- <AboutWidget :userData="userData" @change_active_tab="change_active_tab"/> -->
                  <WidgetPhotoView
                    v-if="!otherUser || !userData.who_can_see_your_photos || checkIfPageOwnerIsYourFriend(userData.id)"
                    @change_active_tab="change_active_tab"
                    @getWidgetPhotosLists="getWidgetPhotos"
                    :listData="mediaPhotosList"
                    :totalMediaPhotos="mediaPhotosCount"
                    :is-media-loading="mediaPhotosLoading"
                  />
                  <FriendListDetail
                    v-if="!otherUser || !userData.who_can_see_your_friends || checkIfPageOwnerIsYourFriend(userData.id)"
                    @change_active_tab="change_active_tab" />
                  <ListeningHistory
                    :listeningSongs="listeningHistoryWidget.songs"
                    @change_active_tab="change_active_tab"
                    :isDataLoading="listeningHistoryWidget.loading"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- About Content Section End -->


          <!-- Media Content Section Start -->
          <div :class="['tab-pane fade', active_sub_tab === 'media' ? 'show active' : '']"
            id="Media"
            role="tabpanel"
            aria-labelledby="nav-contact-tab"
          >
            <div v-if="active_sub_tab === 'media'"
              class="row my-3"
            >
              <media
                :edit="!otherUser"
                class="col-8"
                :user_id="otherUser ? userData.id : null"
              />
              <div class="col-md-4">
                <div class="content-sidebar width370">
                  <FriendCollectionWishlist
                    :followersCount="followersCount"
                    :followingCount="followingCount"
                    :friendsCount="friendsCount"
                  />
                  <AboutWidget
                    :userData="userData"
                    :work_list="work_list"
                    @change_active_tab="change_active_tab"
                  />
                  <FriendListDetail
                    v-if="!otherUser || !userData.who_can_see_your_friends || checkIfPageOwnerIsYourFriend(userData.id)"
                    @change_active_tab="change_active_tab" />
                  <ListeningHistory
                    :listeningSongs="listeningHistoryWidget.songs"
                    @change_active_tab="change_active_tab"
                    :isDataLoading="listeningHistoryWidget.loading"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- Media Content Section End -->


          <!-- Friends Content Section Start -->
          <div :class="['tab-pane fade', active_sub_tab === 'friends' ? 'show active' : '']"
            id="friends"
            role="tabpanel"
            aria-labelledby="nav-contact-tab"
          >
            <div v-if="active_sub_tab === 'friends'"
              class="row my-3"
            >
              <div class="col-8">
                <div class="media-tabs friends_tab">
                  <ul class="nav nav-tabs5" id="myTab" role="tablist">
                    <li class="nav-item" @click="fetchFriendsTabDataBasedOnType('friends')">
                      <a
                        class="nav-link active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#Friends"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        Friends
                      </a>
                    </li>
                    <li class="nav-item" @click="fetchFriendsTabDataBasedOnType('followers')">
                      <a
                        class="nav-link"
                        id="profile-tab"
                        data-toggle="tab"
                        href="#Followers"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        Followers
                      </a>
                    </li>
                    <li class="nav-item" @click="fetchFriendsTabDataBasedOnType('followings')">
                      <a
                        class="nav-link"
                        id="contact-tab"
                        data-toggle="tab"
                        href="#Following"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                      >
                        Following
                      </a>
                    </li>
                  </ul>
                  <SearchFriends
                    :currentActiveFriendTab="currentActiveFriendTab"
                    @searchFriendValue="getSearchValue"
                  />
                </div>
                <div class="tab-content" id="myTabContent">
                  <div
                    v-if="currentActiveFriendTab === 'friends'"
                    class="tab-pane fade show active mt_20"
                    id="Friends"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <spinner v-if="getSpinnerData && (getSpinnerData.display && getSpinnerData.for === currentActiveFriendTab)"/>
                    <MyFriends
                      v-else
                      :currentActiveFriendTab="currentActiveFriendTab"
                      :friendsSearchKey="friendsSearchKey"
                    />
                  </div>
                  <div
                    v-if="currentActiveFriendTab === 'followers'"
                    class="tab-pane fade mt_20"
                    id="Followers"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <spinner v-if="getSpinnerData && (getSpinnerData.display && getSpinnerData.for === currentActiveFriendTab)"/>
                    <MyFollowers
                      v-else
                      :currentActiveFriendTab="currentActiveFriendTab"
                      :friendsSearchKey="friendsSearchKey"
                    />
                  </div>
                  <div
                    v-if="currentActiveFriendTab === 'followings'"
                    class="tab-pane fade mt_20"
                    id="Following"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <spinner v-if="getSpinnerData && (getSpinnerData.display && getSpinnerData.for === currentActiveFriendTab)"/>
                    <MyFollowings
                      v-else
                      :currentActiveFriendTab="currentActiveFriendTab"
                      :friendsSearchKey="friendsSearchKey"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="content-sidebar width370">
                  <FriendCollectionWishlist
                    :followersCount="followersCount"
                    :followingCount="followingCount"
                    :friendsCount="friendsCount"
                  />
                  <WidgetPhotoView
                    v-if="!otherUser || !userData.who_can_see_your_photos || checkIfPageOwnerIsYourFriend(userData.id)"
                    @change_active_tab="change_active_tab"
                    @getWidgetPhotosLists="getWidgetPhotos"
                    :listData="mediaPhotosList"
                    :totalMediaPhotos="mediaPhotosCount"
                    :is-media-loading="mediaPhotosLoading"
                  />
                  <!-- <FriendListDetail @change_active_tab="change_active_tab"/> -->
                  <ListeningHistory
                    :listeningSongs="listeningHistoryWidget.songs"
                    @change_active_tab="change_active_tab"
                    :isDataLoading="listeningHistoryWidget.loading"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- Friends Content Section End -->

          <!-- History Content Section Start -->
          <div :class="['tab-pane fade col-12', active_sub_tab === 'history' ? 'show active' : '']"
            id="history"
            role="tabpanel"
            aria-labelledby="nav-contact-tab"
          >
            <div class="about-work-text">
              <h1 class="pl-0">Listening History</h1>
            </div>

            <div v-if="active_sub_tab === 'history'"
              class="row my-3">
              <div class="col-8">
                <listening-history-tab />
              </div>
              <div class="col-md-4">
                <div class="content-sidebar width370">
                  <FriendCollectionWishlist
                    :followersCount="followersCount"
                    :followingCount="followingCount"
                    :friendsCount="friendsCount"
                  />
                  <WidgetPhotoView
                    v-if="!otherUser || !userData.who_can_see_your_photos || checkIfPageOwnerIsYourFriend(userData.id)"
                    @change_active_tab="change_active_tab"
                    @getWidgetPhotosLists="getWidgetPhotos"
                    :listData="mediaPhotosList"
                    :totalMediaPhotos="mediaPhotosCount"
                    :is-media-loading="mediaPhotosLoading"
                  />
                  <FriendListDetail
                    v-if="!otherUser || !userData.who_can_see_your_friends || checkIfPageOwnerIsYourFriend(userData.id)"
                    @change_active_tab="change_active_tab" />
                </div>
              </div>
            </div>
          </div>
          <!-- History Content Section End -->

          <!-- Work Content Section Start -->
          <div :class="['tab-pane fade', active_sub_tab === 'work' ? 'show active' : '']"
            id="work"
            role="tabpanel"
            aria-labelledby="nav-contact-tab"
          >
            <WorkTab v-if="active_sub_tab === 'work'" :userData="userData" :otherUser="otherUser" />
          </div>
          <!-- Work Content Section End -->
        </div>
      </div>
      <div style="height: 80px"></div>
      <OptionModal v-if="optionOptions" @filter="filterPosts" @closeModal="closeModal" />
      <upload-profile-and-cover
        :pic-type="modelTypeOfProfileAndCover"
        :show="openModelProfileAndCover"
        @profileAndCoverValueChange="changeProfileAndCoverValue"
      />
      <div>
        <UserProfileShareModel
          v-if="openProfileModel" @closeSharePageModel="closeSharePageModel" :open-from="'profile'"
          :modalData="userData"
        />
      </div>
    </b-skeleton-wrapper>
    <ConfirmReportModal
      v-if="is_report_group"
      @closeReportModal="closeReportModal"
      :reportableId="userData.id"
      :reportableType="'User'"
      :user="userData"
    />
  </div>
</template>

<script type="application/javascript">
import MyFriends from "../Profile/Friends/MyFriends.vue";
import MyFollowers from "../Profile/Friends/MyFollowers.vue";
import MyFollowings from "../Profile/Friends/MyFollowings.vue";
import SearchFriends from "../Profile/Friends/SearchFriends.vue";
import OptionModal from "@/components/Home/Profile/OptionModal.vue";
import listeningHistoryTab from "@/components/Home/Profile/Sections/listeningHistory.vue";
import FriendCollectionWishlist from "./FriendCollectionWishlist.vue";
import WidgetPhotoView from "./WidgetPhotoView.vue";
import ListeningHistory from "@/components/Common/Widget/ListeningHistory.vue";
import AboutWidget from "./AboutWidget.vue";
import Favorites from "./favorites/";
import Media from "./media/";
import CreatePost from "@/components/Home//NewsFeed/CreatePost.vue";
import createArticleUserProfile from "../../Home/Article/CreateArticleUserProfile";
import aboutMainPage from "./about/";
import PostDetail from "@/components/Home/NewsFeed/PostItem/PostDetail.vue";
import MyMusicEmptyCard from "@/components/Common/UiComponenets/MyMusicEmptyCard.vue";
import ConfirmReportModal from '@/components/Home/NewsFeed/PostItem/ConfirmReportModal.vue';
import { get_user_work_api, getFavoriteSongsOfUsers } from "../../../apis/APIs";
import { mapActions } from "vuex";
import {
  getAllMediaPhotosOfUsers,
  getOtherUserData,
  getPageData,
} from "../../../apis/APIs";
import cover from "./componenets/Cover";
import ProfilePic from "./componenets/ProfilePic";
import ProfileCompletionStatus from "./componenets/ProfileCompletionStatus";
import UploadProfileAndCover from "./componenets/uploadProfileAndCover";
import UserProfileShareModel from "../../Common/ShareModal/SharePageModel.vue";
import WorkTab from "./work/WorkTab.vue";
import EventsCard from "../../Events/components/EventsCard";
import EventListCart from "../../Events/components/EventListCart";
import  "../../../../public/css/finalStyle.scss";
import NoDataComponent from "../../Common/NoDataComponent.vue";
import fetchApis from "../../../utils/DynamicFunctionToCallApis";
import SkeletonLoader from "../../Common/Loaders/SkeletonLoader.vue";
import arrayToObjectByKey from "../../../utils/ArrayToObjectByKey";
import FriendListDetail from "./FriendListDetail.vue";
import Spinner from "../../Common/Spinners/spinner.vue";

export default {
  data() {
    return {
      eventsPastOffset: 0,
      eventsUpcomingOffset: 0,
      activ_form: { tab_name: "grid" },
      title_event_type: "EVENTS",
      keyPast: Math.random(),
      keyUpcoming: Math.random(),
      allFavoriteSongs: [],
      totalFavoriteSongs: "",
      albumsCardsListData: [],
      showMembershipsListCurrent: 4,
      MembershipsListData: [
        {
          id: 1,
          img: "img1.png",
          artistName: "Artist Name ",
          type: "Light",
          date: "2  days ago",
          price: "5.99"
        },
        {
          id: 2,
          img: "img1.png",
          artistName: "Artist Name ",
          type: "Light",
          date: "2  days ago",
          price: "5.99"
        },
        {
          id: 3,
          img: "img1.png",
          artistName: "Artist Name ",
          type: "Light",
          date: "2  days ago",
          price: "5.99"
        },
        {
          id: 4,
          img: "img1.png",
          artistName: "Artist Name ",
          type: "Light",
          date: "2  days ago",
          price: "5.99"
        },
        {
          id: 5,
          img: "img1.png",
          artistName: "Artist Name ",
          type: "Light",
          date: "2  days ago",
          price: "5.99"
        },
        {
          id: 6,
          img: "img1.png",
          artistName: "Artist Name ",
          type: "Light",
          date: "2  days ago",
          price: "5.99"
        },
        {
          id: 7,
          img: "img1.png",
          artistName: "Artist Name ",
          type: "Light",
          date: "2  days ago",
          price: "5.99"
        },
        {
          id: 8,
          img: "img1.png",
          artistName: "Artist Name ",
          type: "Light",
          date: "2  days ago",
          price: "5.99"
        }
      ],
      NFTsAudioData: [
        {
          id: 1,
          img: "img1.png",
          artistName: "Electronic Moskva",
          singlName: "Synthopia",
          hours: "2 days left",
          price: "0.25",
          heart: 1
        },
        {
          id: 2,
          img: "img2.png",
          artistName: "Electronic Moskva",
          singlName: "Synthopia",
          hours: "5 days left",
          price: "0.25",
          heart: 2
        },
        {
          id: 3,
          img: "img1.png",
          artistName: "Electronic Moskva",
          singlName: "Synthopia",
          hours: "2 days left",
          price: "0.25",
          heart: 1
        },
        {
          id: 4,
          img: "img2.png",
          artistName: "Electronic Moskva",
          singlName: "Synthopia",
          hours: "5 days left",
          price: "0.25",
          heart: 2
        },
        {
          id: 5,
          img: "img1.png",
          artistName: "Electronic Moskva",
          singlName: "Synthopia",
          hours: "2 days left",
          price: "0.25",
          heart: 1
        },
        {
          id: 6,
          img: "img2.png",
          artistName: "Electronic Moskva",
          singlName: "Synthopia",
          hours: "5 days left",
          price: "0.25",
          heart: 7
        },
        {
          id: 7,
          img: "img1.png",
          artistName: "Electronic Moskva",
          singlName: "Synthopia",
          hours: "2 days left",
          price: "0.25",
          heart: 1
        },
        {
          id: 8,
          img: "img2.png",
          artistName: "Electronic Moskva",
          singlName: "Synthopia",
          hours: "5 days left",
          price: "0.25",
          heart: 2
        },
        {
          id: 9,
          img: "img2.png",
          artistName: "Electronic Moskva",
          singlName: "Synthopia",
          hours: "5 days left",
          price: "0.25",
          heart: 2
        }
      ],
      NFTsAudioVisualData: [
        {
          id: 1,
          img: "img1.png",
          artistName: "Electronic Moskva",
          singlName: "Synthopia",
          hours: "2 days left",
          price: "0.25",
          heart: 1
        },
        {
          id: 2,
          img: "img2.png",
          artistName: "Electronic Moskva",
          singlName: "Synthopia",
          hours: "5 days left",
          price: "0.25",
          heart: 2
        },
        {
          id: 3,
          img: "img1.png",
          artistName: "Electronic Moskva",
          singlName: "Synthopia",
          hours: "2 days left",
          price: "0.25",
          heart: 1
        }
      ],
      email: "",
      password: "",
      profilePicture: "",
      has_error: true,
      optionOptions: false,
      openMore: false,
      showMore: false,
      active_sub_tab: "posts",
      coverPreview: null,
      image: null,
      userData: {},
      followersCount: "",
      followingCount: "",
      friendsCount: "",
      previewEnabled: false,
      saveCoverAndProfile: false,
      openModelProfileAndCover: false,
      modelTypeOfProfileAndCover: "cover",
      mediaPhotosList: [],
      mediaPhotosLoading: false,
      offSetLimit: 0,
      limit: 9,
      mediaPhotosCount: 0,
      check: true,
      moreTabCheck: false,
      otherUserLoading: false,
      listeningHistoryWidget: {
        songs: [],
        offset: 0,
        limit: 3,
        totalRows: 0,
        loading:false
      },
      currentActiveFriendTab: "friends",
      friendsSearchKey: null,
      moreTabStyle: "",
      openProfileModel: false,
      postLoading: false,
      myFriends: null,
      work_list: [],
      sentRequest: false,
      toggleCreateArticle: false,
      filterName: 'All',
      listOfApisToCall: [],
      is_report_group: false,
    };
  },
  props: {
    activePage: String
  },
  watch: {
    user(val) {
      const { user_data } = val || {};
      if (user_data) {
        this.userData = user_data ? user_data : {};
        this.profilePicture = this.userData.artwork_url;
      }
    }
  },
  computed: {
    getPastEvents() {
      return this.$store.getters.getPastEvents;
    },
    getUpcomingEvents() {
      return this.$store.getters.getUpcomingEvents;
    },
    getUpcomingTotal() {
      return this.$store.getters.getUpcomingTotal;
    },
    getPastTotal() {
      return this.$store.getters.getPastTotal;
    },
    user() {
      return this.$store.getters.user_map_to_props;
    },
    name() {
      return this.userData.name
        ? this.userData.name + " " + this.userData.last_name
        : false;
    },
    post_list() {
      return this.$store.getters.getPosts;
    },
    otherUser() {
      return !(
        this.user &&
        this.user.user_data &&
        this.user.user_data.username === this.$route.params.username
      );
    },
    activities() {
      return this.$store.getters.getPostsActivities;
    },
    getLoadingState() {
      return this.$store.getters.getLoadingState;
    },
    is_more_posts() {
      const allPosts = this.$store.getters.getTotalPosts; // all posts which are available in DB
      const PostsLenght = this.post_list.length; // postlist array
      return PostsLenght >= this.$store.getters.getPostsLimit && PostsLenght < allPosts;
    },
    checkIfSkeleton() {
      return this.$store.getters.getSkeleton;
    },
    getSpinnerData() {
      return this.$store.getters.getSpinner;
    },
  },
  components: {
    ConfirmReportModal,
    Spinner,
    SkeletonLoader,
    MyMusicEmptyCard,
    UploadProfileAndCover,
    ProfileCompletionStatus,
    ProfilePic,
    cover,
    PostDetail,
    listeningHistoryTab,
    OptionModal,
    FriendCollectionWishlist,
    WidgetPhotoView,
    FriendListDetail,
    ListeningHistory,
    AboutWidget,
    aboutMainPage,
    Favorites,
    Media,
    CreatePost,
    MyFriends,
    MyFollowers,
    MyFollowings,
    SearchFriends,
    UserProfileShareModel,
    WorkTab,
    EventsCard,
    EventListCart,
    createArticleUserProfile,
    // SocialWidget,
    NoDataComponent,
    // PopularEmptyDiv
  },
  mounted() {

    this.callAllApisForUserProfile();

    if (this.activePage) {
      this.change_active_tab(this.activePage);
    } else {
      this.change_active_tab("posts");
    }
  },
  destroyed() {
    this.$store.commit('SET_OTHER_USER_ID', null);
  },
  methods: {
    ...mapActions(["get_post_data"]),
    changeProfileAndCoverValue(value) {
      this.openModelProfileAndCover = value;
    },

    async changeTabsAccordingType(params) {
      this[params.tab_Type].tab_name = params.tab_name;
    },

    redirectToSettingPageAndSetCheckAndTab(event, tab) {
      if (tab) {
        if (tab === "Basic") {
          this.$store.commit("SET_REDIRECT_TO_SETTING_PAGE_CHECK", true);
          this.$store.commit("SET_REDIRECT_TO_SETTING_PAGE_TAB", tab);
        } else {
        }
        this.redirectToSettingPage(event);
      }
    },

    getFavoriteSongs(type = "song", returnKey = "song") {
      return async () => {
        try {
          const { data } = await getFavoriteSongsOfUsers(type);
          return { [returnKey]: data || {} };
        } catch (error) {
          throw error;
        }
      }
    },

    redirectFunction(){
      this.$router.push('/music/page/overview')
    },
    closeModal: function() {
      this.optionOptions = false;
    },
    OpenCreateArticle(){
      this.toggleCreateArticle = true
    },
    toggleCreateArticleClose(){
      this.toggleCreateArticle = false
    },

    change_active_tab(tab) {

      this.toggleCreateArticle = false
      this.active_sub_tab = tab;

      this.moreTabStyle = ['media_main', 'friends', 'history', 'work'].includes(this.active_sub_tab)
        ? "border-bottom: 3px solid #D1433A !important"
        : "border-bottom: none";

      switch (this.active_sub_tab) {

        case 'collection':

          fetchApis([this.getFavoriteSongs("song", "song")], this.$store, false, true, 'collection').then((resp) => {
            if (resp) {
              const song = arrayToObjectByKey(resp, 'song');
              this.allFavoriteSongs = song.information.data;
              this.totalFavoriteSongs = song.information.total;
            }
          }).catch((error) => {
            console.error('Error in getting the collection data: ', error);
          });

          break;

        case 'wishlist':

          fetchApis([this.getFavoriteSongs("song", "song")], this.$store, false, true, 'wishlist').then((resp) => {
            if (resp) {
              const song = arrayToObjectByKey(resp, 'song');
              this.allFavoriteSongs = song.information.data;
              this.totalFavoriteSongs = song.information.total;
            }
          }).catch((error) => {
            console.error('Error in getting the favorite songs data: ', error);
          });

          break;

        case 'events':

          fetchApis([this.getEvents("past"), this.getEvents("upcoming")], this.$store, false, true, 'events').catch((error) => {
            console.error('Error in getting the events data: ', error);
          });

          break;

        default:
          break;
      }

      window.scrollTo(0, 0);
    },

    getEvents(eventType, showMore = false) {

      const eventsOffsetKey = `events${eventType}Offset`;
      const eventsListKey = `get${eventType.charAt(0).toUpperCase() + eventType.slice(1)}Events`;
      const eventsLimit = 8;

      const eventsLength = this[eventsListKey].length || 0;
      const currentOffset = this[eventsOffsetKey] || 0;

      if (showMore) {
        if (eventsLength <= currentOffset) return () => {};

        this[eventsOffsetKey] += eventsLimit;

        this.$store.commit(`SET_${eventType.toUpperCase()}_OFFSET`, this[eventsOffsetKey]);

        return () => this.$store.dispatch("getUserEvents", { eventType, limit: eventsLimit, showMore: true });
      } else {
        this[eventsOffsetKey] = 0;

        this.$store.commit(`SET_${eventType.toUpperCase()}_OFFSET`, 0);

        return () => this.$store.dispatch("getUserEvents", { eventType, limit: eventsLimit });
      }
    },

    // TODO: NOTE: Same functionality is already implemented in the change_active_tab method so I have commented this method
    // setActiveTab(tab_name) {
    //   this.toggleCreateArticle = false
    //   this.active_sub_tab = tab_name;
    //   if (
    //     this.active_sub_tab === "media_main" ||
    //     this.active_sub_tab === "friends" ||
    //     this.active_sub_tab === "history" ||
    //     this.active_sub_tab === "work"
    //   ) {
    //     this.moreTabStyle = "border-bottom: 3px solid #D1433A !important";
    //   } else {
    //     this.moreTabStyle = "border-bottom: none";
    //   }
    // },

    async callAllApisForUserProfile () {
      await this.getUserDataForProfile();
      await this.getProfileData();
    },

    async getUserDataForProfile() {
      if (this.otherUser) {
        await fetchApis([this.getOtherUser(this.$route.params.username)], this.$store).then((resp) => {
          if (resp) {
            const { information } = arrayToObjectByKey(resp, 'other_user_data');
            this.userData = information;
          }
        }).catch((error) => {
          console.error('Error in getting the other user data: ', error);
        });
      } else {
        this.userData = this.user && this.user.user_data ? this.user.user_data : {};
      }
    },

    // This Function is used to get the data for the profile page except the user data
    async getProfileData() {
      try {
        // Initialize API calls array
        this.listOfApisToCall = [];

        // Reset posts data
        this.$store.commit("GET_POSTS", []);

        // Set user data based on the current user context
        const userId = this.otherUser ? this.userData.id : null;
        this.$store.commit("SET_OTHER_USER_ID", userId);
        this.$store.commit("SET_POST_OFFSET", 0);

        // Add user posts API
        this.listOfApisToCall.push(this.getUserPosts());

        // Add activities API if not already fetched
        if (!this.activities || this.activities.length < 1) {
          this.listOfApisToCall.push(this.getActivities());
        }

        // Add media photos API if not already fetched
        if (!this.mediaPhotosList || this.mediaPhotosList.length < 1) {
          this.listOfApisToCall.push(this.getWidgetPhotos(this.offset, this.limit, userId));
        }

        // Add listening history API
        this.listOfApisToCall.push(
          this.getListeningHistoryWidgetSongs('continue-listening-to', this.listeningHistoryWidget.limit, this.listeningHistoryWidget.offset)
        );

        // Add work list API if not already fetched
        if ((!this.work_list || this.work_list.length < 1) && this.userData) {
          this.listOfApisToCall.push(this.fetchWorkData(0, 3, userId));
        }

        // Add request check API
        this.listOfApisToCall.push(this.checkIfRequestSent());

        const responses = await fetchApis(this.listOfApisToCall, this.$store);

        this.processApiResponses(responses);

        // Update user profile details
        this.updateProfileDetails();
      } catch (error) {
        console.error("Error fetching profile data:", error);
        this.notificationToast(true, "Profile", `Error:  ${error}`, 5000, "error");
      }
    },

    processApiResponses(responses) {
      if (!responses || responses.length === 0) return;

      // Process widget photos
      const widgetPhotos = arrayToObjectByKey(responses, "widget_photos");
      this.mediaPhotosList = (widgetPhotos && widgetPhotos.information && widgetPhotos.information.data) || [];
      this.mediaPhotosCount = (widgetPhotos && widgetPhotos.information && widgetPhotos.information.total) || 0;

      // Process listening history
      const listeningHistory = arrayToObjectByKey(responses, "listening_history");
      this.listeningHistoryWidget.songs =
        (listeningHistory && listeningHistory.channels && listeningHistory.channels.center && listeningHistory.channels.center.objects && listeningHistory.channels.center.objects.data) || [];
      this.listeningHistoryWidget.totalRows =
        (listeningHistory && listeningHistory.channels && listeningHistory.channels.center && listeningHistory.channels.center.objects && listeningHistory.channels.center.objects.total) || 0;

      // Process work list
      const workList = arrayToObjectByKey(responses, "work_list");
      this.work_list = (workList && workList.data && workList.data.data) || [];

      // Process sent requests
      const sentFriendRequest = arrayToObjectByKey(responses, "sent_friend_requests");
      if (sentFriendRequest && sentFriendRequest.information && sentFriendRequest.information.data) {
        this.sentRequest = sentFriendRequest.information.data.some(
          (req) => req.id === this.userData.id
        );
      }

      this.listOfApisToCall = [];
    },

    updateProfileDetails() {
      this.profilePicture = this.userData.artwork_url || "";
      this.followersCount = String(this.userData.follower_count || 0);
      this.followingCount = String(this.userData.following_count || 0);
      this.friendsCount = String(this.userData.friends_count || 0);
    },

    getOtherUser(username, returnKey = 'other_user_data') {
      return async () => {
        try {
          const { data } = await getOtherUserData(username);
          return { [returnKey]: data || {} };
        } catch (error) {
          throw error;
        }
      }
    },

    getActivities() {
      return () => this.$store.dispatch("getActivities");
    },

    getUserPosts(showMore = false, search = '') {
      return () => this.$store.dispatch("getUserPosts", { showMore: showMore, search: search });
    },

    getWidgetPhotos(offset = 0, limit = 8, otherUser = null, returnKey = 'widget_photos') {
      return async () => {
        try {
          const { data } = await getAllMediaPhotosOfUsers(offset, limit, otherUser);
          return { [returnKey]: data || {} };
        } catch (error) {
          throw error;
        }
      }
    },

    getListeningHistoryWidgetSongs(pageFilter = 'continue-listening-to', limit = 8, offset = 0, returnKey = 'listening_history') {
      return async () => {
        try {
          const { data } = await getPageData(pageFilter, limit, offset);
          return { [returnKey]: data || {} };
        } catch (error) {
          throw error;
        }
      }
    },

    fetchWorkData(offset = 0, limit = 3, userId = null, returnKey = 'work_list') {
      return async () => {
        try {
          const { data } = await get_user_work_api(offset, limit, userId);
          return { [returnKey]: data || {} };
        } catch (error) {
          throw error;
        }
      }
    },

    checkIfRequestSent() {
      return () => this.$store.dispatch("getSentRequests");
    },

    changePreview(val) {
      this.previewEnabled = val;
    },

    changeSaveChangesState(val) {
      this.saveCoverAndProfile = val;
    },

    changeProfileAndCoverModel(value) {
      if (!this.otherUser) {
        this.openModelProfileAndCover = true;
        this.modelTypeOfProfileAndCover = value;
      }
    },

    profileAndCoverValueFromChild(value) {
      this.openModelProfileAndCover = value;
    },

    profileAndCoverValueFromChildType(value) {
      this.modelTypeOfProfileAndCover = value;
    },

    showMorePosts() {
      let offset = this.$store.getters.getPostOffset;
      let limit = this.$store.getters.getPostsLimit;
      offset = offset + limit;
      this.$store.commit("SET_POST_OFFSET", offset);

      fetchApis([this.getUserPosts(true)], this.$store, false, true, 'more_posts').catch((error) => {
        console.error('Error in getting the show more posts data: ', error);
      });
    },

    createPost() {
      this.$refs.post.toggleCreatePostModal();
    },

    async getAllFavoritesData_going_and_interested(payload) {
      await this.$store.dispatch("loading", true);
      await this.getDataFavorites({
        value: "going",
        limit: this.currentGoing,
        ...payload
      });
      await this.getDataFavorites({
        value: "interested",
        limit: this.currentintersted,
        ...payload
      });

      await this.$store.dispatch("loading", false);
    },

    getSearchValue(value) {
      this.friendsSearchKey = value;
    },

    fetchFriendsTabDataBasedOnType(type = 'friends') {
      fetchApis([this.getMyFriendsData(type)], this.$store, false, true, type).catch((error) => {
        console.error('Error in getting the friends data: ', error);
      });
    },

    getMyFriendsData(type = 'friends') {
      this.currentActiveFriendTab = type.toLowerCase();
      let payload = {
        friendsSearchKey: null,
        showMore: false,
        search_type: this.currentActiveFriendTab
      };
      return () => this.$store.dispatch(`getMy${type.charAt(0).toUpperCase() + type.slice(1)}`, payload);
    },

    async FollowUnFollowUser(){
      let payload = {
        id : this.userData.id,
        object_type : 'user',
        action : (this.userData.follow_status === 0) ? 1: 0,
        user : this.user
      }
      const {status} = await this.$store.dispatch('changeFollowStatus', payload)
      if (status === 'success') {
        this.userData.follow_status =  (this.userData.follow_status === 0) ? 1: 0
        await this.notificationToast(true, "User", "Follow Status Changed!", 5000, 'success')

      }
      this.openMore = false;
    },

    showPosts(tab, type) {
      this.$store.commit("SET_POST_OFFSET", 0);
      this.$store.commit("GET_POSTS", []);
      this.$store.commit("SET_POST_TYPE", type);

      fetchApis([this.getUserPosts(false)], this.$store, false, true, 'posts').catch((error) => {
        console.error('Error in getting the posts data: ', error);
      });
    },

    filterPosts(value) {
      this.filterName = (value) ? value.charAt(0).toUpperCase() + value.slice(1) : 'All'
      this.showPosts(this.active_sub_tab, value);
      this.closeModal();
    },
    openProfileShareModel() {
      this.openProfileModel = true;
    },
    closeSharePageModel() {
      this.openProfileModel = false;
    },
    addFriendApi(payload) {
      this.$store.dispatch("addFriendApi", payload);
      this.sentRequest = true;
    },
    openReportModal(){
      this.is_report_group = true;
    },
    closeReportModal(){
      this.is_report_group = false;
    },
    manageBlockingRedirect(event,tab) {
      if(tab) {
        if(tab === 'Privacy') {
          this.$store.commit('SET_REDIRECT_TO_SETTING_PAGE_CHECK', true)
          this.$store.commit('SET_REDIRECT_TO_SETTING_PAGE_TAB', tab)
        } else if(tab === 'Blocking') {
          this.$store.commit('SET_REDIRECT_TO_SETTING_PAGE_CHECK', true)
          this.$store.commit('SET_REDIRECT_TO_SETTING_PAGE_TAB', tab)
        } else {
        }
        this.redirectToSettingPage(event)
      }
    }
  },
};
</script>
<style lang="scss" scoped>

.titleSection {
  margin: 42px 0 16px;
  color: #071526;
  text-transform: capitalize;
  font-size: 18px;
}

.post-detail:first-child {
  margin-top: 0;
}

.post-something-field {
  input {
    border-radius: 8px !important;
  }
}

.add-friend-button {
  height: 40px;
  width: 130px;
  border: 1px solid #D1433A;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 5px;
  background: #FFFFFF;

  &:hover {
    background: #D1433A;

    span {
      color: #FFFFFF;
    }

    svg {

      path {
        stroke: #FFFFFF;
      }
    }
  }

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.325px;
    text-transform: uppercase;
    color: #D1433A;
    margin-top: 3px;
  }
}

.follow-Btn {
  &.active {
    button {
      background: #D1433A !important;
    }
    svg {
      path {
        fill: #FFFFFF;
      }
    }
  }

  &:hover {
    svg {
      path {
        fill: #FFFFFF;
      }
    }
  }
}
.main-poset-select-box {
  width: 210px;
  height: 40px;
  border: 1px solid #E6EAF0;
  border-radius: 4px;
  position: relative;
}
.user-artcile{
  padding-left: 0;
  padding-right: 0;
}

.top-tab .active:hover,
.top-tab .active:focus, 
.ct .active:hover, 
.ct .active:focus{
  color: #47505b;
}

.nav-link:hover,
.nav-link:focus,
.ct>a:hover,
.ct>a:focus{
  color: #c4ccd6;
}

</style>
