<template>
  <div class="row">
    <div class="col-12">
      <div class="artist-music-heading" v-if="current_page">{{current_page.title}} {{(pageAlbumsListTotal > 0) ? 'Has '+pageAlbumsListTotal+' Sample Packs':'' }} </div>
    </div>
    <div class="col-12">
      <div class="no-content-here" v-if="pageAlbumsList && pageAlbumsList.length < 1">
        <div class="no-detail-content">
          <div class="category-content-icon">
            <svg width="50" height="50" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.8"
                    d="M26 1C12.2152 1 1 12.2152 1 26C1 39.7854 12.2152 51 26 51C39.7854 51 51 39.7848 51 26C51 12.2146 39.7854 1 26 1ZM26 49.0909C13.2674 49.0909 2.9091 38.7321 2.9091 26C2.9091 13.2679 13.2674 2.9091 26 2.9091C38.7321 2.9091 49.0909 13.2679 49.0909 26C49.0909 38.7321 38.7321 49.0909 26 49.0909Z"
                    fill="#47505B" stroke="#47505B" stroke-width="0.41"></path>
              <g opacity="0.8">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M22.4721 35.2308C22.4721 37.7078 20.463 39.7168 17.986 39.7168C15.509 39.7168 13.5 37.7078 13.5 35.2308C13.5 32.7538 15.509 30.7448 17.986 30.7448C20.463 30.7448 22.4721 32.7538 22.4721 35.2308ZM16.0391 35.23C16.0391 36.3046 16.9113 37.1768 17.9859 37.1768C19.0605 37.1768 19.9327 36.3046 19.9327 35.23C19.9327 34.1554 19.0605 33.2832 17.9859 33.2832C16.9113 33.2832 16.0391 34.1554 16.0391 35.23Z"
                      fill="#47505B"></path>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M36.353 30.9981C36.353 33.4751 34.3439 35.4842 31.8669 35.4842C29.3899 35.4842 27.3809 33.4751 27.3809 30.9981C27.3809 28.5211 29.3899 26.5121 31.8669 26.5121C34.3439 26.5121 36.353 28.5211 36.353 30.9981ZM29.92 30.9981C29.92 32.0727 30.7922 32.9448 31.8668 32.9448C32.9414 32.9448 33.8136 32.0727 33.8136 30.9981C33.8136 29.9235 32.9414 29.0513 31.8668 29.0513C30.7922 29.0513 29.92 29.9235 29.92 30.9981Z"
                      fill="#47505B"></path>
                <path
                  d="M33.8149 17.0095V30.9982H36.3542V13.5L19.9336 18.9068V35.2303H22.4729V20.7441L33.8149 17.0095Z"
                  fill="#47505B"></path>
              </g>
            </svg>
          </div>
          <div class="no-detail-content-heading">No Songs Uploaded Yet</div>
          <div class="add-new-product-buttons" v-if="current_page && current_page.current_user === 'admin'">
            <router-link :to="'/market-manager/page/create-product-song-album/'+current_page.id" tag="button">
              <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M4.49941 0C4.77091 0 4.99101 0.220095 4.99101 0.491596V8.5084C4.99101 8.7799 4.77091 9 4.49941 9C4.22791 9 4.00781 8.7799 4.00781 8.5084V0.491596C4.00781 0.220095 4.22791 0 4.49941 0Z"
                      fill="white"></path>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M0 4.4999C0 4.2284 0.220095 4.0083 0.491596 4.0083H8.5084C8.7799 4.0083 9 4.2284 9 4.4999C9 4.7714 8.7799 4.99149 8.5084 4.99149H0.491596C0.220095 4.99149 0 4.7714 0 4.4999Z"
                      fill="white"></path>
              </svg>
              ADD NEW SAMPLE PACK
            </router-link>
          </div>
        </div>
      </div>
      <div class="row" style="margin-right: -8px; margin-left: -8px;">
        <div v-for="(song, index) in pageAlbumsList" :key="index" class="col-sm-3 px-2 mb-3">
          <SamplePacks :item="song" :buy="true"/>
        </div>
      </div>
      <div class="show_more_btn" v-if="pageAlbumsList && pageAlbumsListTotal > pageAlbumsList.length">
        <div class="show-more musicbar_more">
          <a href="javascript:;" @click="showMoreAlbums(filteredListPayload, pageAlbumsList.length, true)">
        <span class="show_more_bg">
          <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.24219 0.689941V7.01994" stroke="#071526" stroke-width="1.3" stroke-linecap="round"
                  stroke-linejoin="round">
            </path>
            <path d="M1 3.85498H7.48702" stroke="#071526" stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round">
            </path>
          </svg>
          SHOW  MORE ALBUMS
        </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import SongOrAlbumCardWithSquareImage from "@/components/DesignGuide/Music/Components/Grid.vue";
import SamplePacks from "../../../Common/UiComponenets/Music/SamplePacks.vue";
export default {
  data() {
    return {}
  },
  components:{
    SamplePacks
  },
  computed:{
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
    pageAlbumsList(){
      return this.$store.getters.getPageMusicFilteredList;
    },
    pageAlbumsListTotal(){
      return this.$store.getters.getPageMusicFilteredListTotal;
    },
    filteredListPayload(){
      return this.$store.getters.getPageMusicFilteredListPayload;
    }
  },
  methods:{
    showMoreAlbums(obj, offset, showMore){
      if(showMore === true){
        const payload = {
          type : obj.type,
          filter_by : obj.filter_by,
          showMore : true,
          page_id : obj.page_id,
          offset : offset,
          limit: offset + 8
        }
        this.$store.dispatch('getMusicFilteredData',payload)
      }else{
        const payload = {
          type : obj.type,
          filter_by : obj.filter_by,
          showMore : false,
          page_id : obj.page_id,
          offset : 0,
          limit: offset
        }
        this.$store.dispatch('getMusicFilteredData',payload)
      }
    },
    getFavoriteSongs() {
      this.showMoreTracks(this.filteredListPayload, this.pageAlbumsList.length, false)
    },
  }
}
</script>
