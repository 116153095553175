<template>
  <div v-if="article" class="row">
    <div class="col">
      <div class="feed-news-item">
        <div class="head-item">
          <div class="item">
            <div class="">
              <div class="profile_content">
                <span class="news-feed-pic"
                      @click="redirectWidgetsToProfilePage($event, article.user.username ? article.user.username : '')"
                >
                   <img
                     :src="article.user.artwork_url"
                   />
                </span>
              </div>
            </div>

            <div class="item-desc">
              <div class="item-title"
                   @click="redirectWidgetsToProfilePage($event, article.user.username ? article.user.username : '')"
              >
                {{article.user_name}}
                <span class="wrotArticle"
                  >wrote an article
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="16" height="16" rx="1" fill="#D1433A" />
                    <ellipse cx="8.5" cy="6" rx="3.5" ry="3" fill="white" />
                    <rect x="6" y="3" width="3" height="2" fill="white" />
                    <rect x="6" y="7" width="3" height="2" fill="white" />
                    <rect x="4" y="3" width="2" height="10" fill="white" />
                    <rect x="6" y="5" width="3" height="2" fill="#D1433A" />
                    <circle cx="9" cy="6" r="1" fill="#D1433A" />
                  </svg>
                </span>
              </div>
              <div class="item-time_ago">
                {{time}}
                <component :is="getSvg(article.access)" />
<!--                <svg-->
<!--                  width="14"-->
<!--                  height="14"-->
<!--                  viewBox="0 0 14 14"-->
<!--                  fill="none"-->
<!--                  xmlns="http://www.w3.org/2000/svg"-->
<!--                >-->
<!--                  <path-->
<!--                    d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"-->
<!--                    stroke="#8B949F"-->
<!--                    stroke-width="1.5"-->
<!--                    stroke-linecap="round"-->
<!--                    stroke-linejoin="round"-->
<!--                  />-->
<!--                  <path-->
<!--                    d="M1 7H13"-->
<!--                    stroke="#8B949F"-->
<!--                    stroke-width="1.5"-->
<!--                    stroke-linecap="round"-->
<!--                    stroke-linejoin="round"-->
<!--                  />-->
<!--                  <path-->
<!--                    d="M6.99961 1C8.50038 2.64301 9.35326 4.77522 9.39961 7C9.35326 9.22478 8.50038 11.357 6.99961 13C5.49884 11.357 4.64596 9.22478 4.59961 7C4.64596 4.77522 5.49884 2.64301 6.99961 1V1Z"-->
<!--                    stroke="#8B949F"-->
<!--                    stroke-width="1.5"-->
<!--                    stroke-linecap="round"-->
<!--                    stroke-linejoin="round"-->
<!--                  />-->
<!--                </svg>-->
              </div>
            </div>
            <div class="dropdown-wrapper mr-2 mt-1">
              <div v-if="article.categorylist[0]" class="head-tag">{{(article.categorylist[0]) ? article.categorylist[0].title : ''}}</div>
            </div>
            <PostDetailDropDown :post="article" :global_type="article.global_type" :activeTab="'article'" @pin-post="$emit('pin-post')" />
          </div>
        </div>
        <div class="content-item">
          <div class="news-feed-pic-detials-photo-video1">
            <div v-if="article.artwork_url[0]" class="photo-timeline">
              <img :src="article.artwork_url[0].original_url"  @error="onPostImageError" />
            </div>
          </div>

          <div class="under-img">
            <div class="item-title">
              {{article.title}}
            </div>
            <div class="item-text">
              {{ content.slice(0, text_length)
              }}<span @click="changeTextLength">{{ more_less }}</span>
            </div>
                <div class="tags" >
                  <div v-for="tag in article.tags" :key="tag.id">{{tag.tag}}</div>
                </div>
          </div>
        </div>
        <div class="news-feed-post-detail-list">
          <div class="news-feed-pic-detials">
            <div class="artist-timeline-activity">
              <div class="activity-main">
                <PostLike :item="article" :index="index" />
                <div @click="toggleCommentSection($event)">
                  <PostComment v-if="article.allow_comments === 1" :post="article" :comments_count="article.comment_count" />
                </div>
                <PostShare :post="article" />
              </div>
            </div>
          </div>
        </div>

        <CommentInput
          v-if="write_comment && article.allow_comments === 1"
          :item="article"
          :comment_check="comment_check"
          @comments_count="commentsCount"
          @addComment="addCommentInList" @commentToggle="toggleCommentSection"
        />

      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import PostDetailDropDown from "../../Home/NewsFeed/PostItem/PostDetailDropDown";
import PostLike from "../../Home/NewsFeed/PostItem/PostLike";
import PostComment from "../../Home/NewsFeed/PostItem/PostComment";
import PostShare from "../../Home/NewsFeed/PostItem/PostShare";
import CommentInput from "../../Home/NewsFeed/PostItem/CommentInput";
import PublicSvg from "../NewsFeed/PostItem/PublicSvg.vue";
import FriendsSvg from "../NewsFeed/PostItem/FriendsSvg.vue";
import OnlyMeSvg from "../NewsFeed/PostItem/OnlyMeSvg.vue";

export default {
  data() {
    return {
      write_comment: false,
      openMenuArticle: false,
      more_less: "...more",
      text_length: 150,
    };
  },
  components:{
    PostDetailDropDown,
    PostLike,
    PostComment,
    PostShare,
    CommentInput
  },
  props: {
    article: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    },
    comment_check: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openPostNewTab (id) {
      const pathname = `/home/page/article/post-detail/${id}`;
      window.open(pathname);
    },
    changeTextLength() {
      if (this.more_less === "...more") {
        this.more_less = "less";
        this.text_length = Infinity;
      } else if (this.more_less === "less") {
        this.more_less = "...more";
        this.text_length = 150;
      }
    },
    getSvg(access) {
      switch (access) {
        case 'public':
          return PublicSvg;
        case 'friends':
          return FriendsSvg;
        case 'only_me':
          return OnlyMeSvg;
        default:
          return FriendsSvg;
      }
    },

    addCommentInList(comment) {
      this.$emit("addCommentInList", comment);
    },
    toggleCommentSection(e) {
      if (e) e.stopPropagation();
      this.write_comment = !this.write_comment;
    },
    commentsCount(comments_count) {
      if (comments_count) {
        this.article.comment_count = this.article.comment_count + 1;
      }
    },
  },
  computed:{
    time() {
      const { created_at } = this.article || {};
      return moment(created_at).fromNow();
    },
    content() {
      return this.article.full_content.replace(/(<([^>]+)>)/ig, "");
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapMenu {
  position: relative;
  display: flex;
  height: 20px;
  align-items: center;
  .menu {
    padding: 11px 10px;
    width: 240px;
    position: absolute;
    list-style-type: none;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border: 1px solid transparent;
    background: #ffffff;
    right: -10px;
    top: 23px;
    margin: 0;
    hr {
      margin: 0;
    }
    li {
      display: flex;
      font-family: HelveticaNeueMedium;
      color: #071526;
      padding: 15px 20px;
      font-size: 13px;
      align-items: center;
      .icon {
        margin: 0 19px 0 0 !important;
      }
      &:hover {
        background: #D1433A1A;
      }
    }
  }
}
.dropdown-wrapper {
  display: flex;
  align-items: center;
}
.wrotArticle {
  font-weight: 400;
  font-size: 15px;
  font-family: HelveticaNeueNormal, sans-serif;
  color: #999;
  margin-left: 8px;
  display: flex;
  svg {
    margin-left: 8px;
  }
}
.item-time_ago {
  font-size: 14px;
  display: flex;
  align-items: center;
  svg {
    margin-left: 15px;
  }
}
.feed-news-item .content-item .under-img .item-text {
  span {
    cursor: pointer;
    color: #D1433A !important;
  }
}
.tags {
  margin: 20px 0 10px !important;
  div {
    font-family: Lato;
    border: 1px solid #D1433A;
    box-sizing: border-box;
    border-radius: 4px;
    color: #D1433A !important;
    background-color: #ffffff !important;
    transition: all 0.3s;
    &:hover {
      background-color: rgba(65, 108, 255, 0.9) !important;
      color: #fff !important;
      transition: all 0.3s;
    }
  }
}
.item-title{
  word-break: break-all;
}
.item-text{
  word-break: break-all;
}
.footer-post_item {
  display: flex;
  border-top: 1px solid #e6eaf0;
  padding-top: 10px;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.3s;
  div {
    width: 70px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin-right: 10px;
    color: #071526;
    font-size: 11px;
    span {
      margin-left: 5px;
      opacity: 0.7;
      font-family: HelveticaNeueNormal, sans-serif !important;
      letter-spacing: 0.5px;
    }
    &:hover {
      transition: all 0.3s;
      &.like {
        background: rgba(247, 35, 129, 0.1);
      }
      &.comment {
        background: #D1433A1A;
      }
      &.share {
        background: rgba(46, 191, 136, 0.1);
      }
      &.upload {
        background: #D1433A1A;
      }
    }
  }
}
</style>
