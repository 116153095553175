<script>
export default {
  name: "SkeletonLoader",
  props: {
    type: {
      type: String,
      default: "default",
    }
  },
};


</script>

<template>
  <div style="width: 1170px">
    <div v-if="type === 'default'">
      <b-skeleton animation="wave" width="100%"></b-skeleton>
      <b-skeleton class="mt-1" animation="wave" width="85%"></b-skeleton>
      <b-skeleton class="mt-1" animation="wave" width="70%"></b-skeleton>
      <b-skeleton class="mt-1" animation="wave" width="60%"></b-skeleton>
      <b-row class="mt-3">
        <b-col>
          <b-skeleton-img></b-skeleton-img>
        </b-col>
        <b-col>
          <b-skeleton-img></b-skeleton-img>
        </b-col>
        <b-col>
          <b-skeleton-img></b-skeleton-img>
        </b-col>
        <b-col cols="12" class="mt-3">
          <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
        </b-col>
      </b-row>
      <b-skeleton class="mt-3" animation="wave" width="55%"></b-skeleton>
      <b-skeleton class="mt-1" animation="wave" width="70%"></b-skeleton>
      <b-skeleton class="mt-1" animation="wave" width="60%"></b-skeleton>
    </div>
    <div v-else-if="type === 'news-feed'">
      <b-row class="mt-3">
        <b-col cols="8">
          <b-skeleton-img></b-skeleton-img>
        </b-col>
        <b-col cols="4">
          <b-skeleton-img></b-skeleton-img>
          <b-skeleton-img class="mt-3"></b-skeleton-img>
        </b-col>
      </b-row>
    </div>
    <div v-else-if="type === 'pages'">
      <b-row class="mt-3">
        <b-col cols="2">
          <b-skeleton-img></b-skeleton-img>
          <b-skeleton-img></b-skeleton-img>
          <b-skeleton-img></b-skeleton-img>
        </b-col>
        <b-col cols="2">
          <b-skeleton-img></b-skeleton-img>
          <b-skeleton-img></b-skeleton-img>
          <b-skeleton-img></b-skeleton-img>
        </b-col>
        <b-col cols="2">
          <b-skeleton-img></b-skeleton-img>
          <b-skeleton-img></b-skeleton-img>
          <b-skeleton-img></b-skeleton-img>
        </b-col>
        <b-col cols="2">
          <b-skeleton-img></b-skeleton-img>
          <b-skeleton-img></b-skeleton-img>
          <b-skeleton-img></b-skeleton-img>
        </b-col>
        <b-col cols="2">
          <b-skeleton-img></b-skeleton-img>
          <b-skeleton-img></b-skeleton-img>
          <b-skeleton-img></b-skeleton-img>
        </b-col>
        <b-col cols="2">
          <b-skeleton-img></b-skeleton-img>
          <b-skeleton-img></b-skeleton-img>
          <b-skeleton-img></b-skeleton-img>
        </b-col>
      </b-row>
    </div>
    <div v-else-if="type === 'friends'">
      <b-row class="mt-3">
        <b-col cols="8">
          <b-skeleton-img class="mt-1" no-aspect height="150px"></b-skeleton-img>
          <b-skeleton-img class="mt-1" no-aspect height="150px"></b-skeleton-img>
          <b-skeleton-img class="mt-1" no-aspect height="150px"></b-skeleton-img>
        </b-col>
        <b-col cols="4">
          <b-skeleton-img no-aspect height="460px"></b-skeleton-img>
        </b-col>
      </b-row>
    </div>
    <div v-else-if="type === 'user-profile'">
      <b-row>
        <b-col cols="12">
          <b-skeleton-img no-aspect height="300px"></b-skeleton-img>
        </b-col>
        <b-col class="mt-3" cols="8">
          <b-skeleton-img height="450px"></b-skeleton-img>
        </b-col>
        <b-col class="mt-3" cols="4">
          <b-skeleton-img height="218px"></b-skeleton-img>
          <b-skeleton-img height="218px" class="mt-3"></b-skeleton-img>
        </b-col>
        <b-col class="mt-3" cols="8">
          <b-skeleton-img height="450px"></b-skeleton-img>
        </b-col>
        <b-col class="mt-3" cols="4">
          <b-skeleton-img height="218px"></b-skeleton-img>
          <b-skeleton-img height="218px" class="mt-3"></b-skeleton-img>
        </b-col>
      </b-row>
      <b-row class="mt-3">

      </b-row>
    </div>
  </div>
</template>