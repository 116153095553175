<script>
export default {
  name: "spinner"
};
</script>

<template>
  <div class="text-primary d-flex justify-content-center mt-3">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>