<template>
  <div>
    <div
      :class="[
        'left-bar open show leftbar_custom engage_leftbar',
        toggleLeftNav ? 'collapse-left-bar' : '',
      ]"
      id="left_sidebar"
    >
      <perfect-scrollbar class="event-perfect-scrollbar">
        <div class="for-home general-menu home-style">
          <div class="left-bar-full-logo-collapsed">
            <div class="collapse_logo">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="15" r="15" fill="#071526" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22.8085 16.8538L13.6785 7.72388L13.6272 7.77517L13.6249 7.77287L4.46924 16.9285L6.27462 18.7339L13.6762 11.3323L21.0288 18.6849L22.8139 16.8998L25.0449 19.1309L27.3016 16.8741L25.0449 14.6174L22.8085 16.8538ZM11.4904 18.7253L13.7472 16.4685L16.0039 18.7253L13.7472 20.982L11.4904 18.7253Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <div class="home-menu menu-title events-btn">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15.484 9.23855H4.61158V7.98855H15.484V9.23855Z" fill="#AE4EA9"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.50293 1.66699H7.75293V3.33366H12.3428V1.66699H13.5928V3.33366H15.5251C16.2678 3.33777 16.979 3.62771 17.5043 4.14104C18.0296 4.6543 18.3265 5.34924 18.3307 6.07505L18.3307 6.0786L18.3307 14.7554L18.3307 14.7589C18.3265 15.4847 18.0296 16.1797 17.5043 16.6929C16.979 17.2063 16.2678 17.4962 15.5251 17.5003L15.5214 17.5003H4.47336L4.46973 17.5003C3.72703 17.4962 3.0158 17.2063 2.49046 16.6929C1.96519 16.1797 1.66828 15.4847 1.66407 14.7589L1.66406 14.7554V6.06704L1.6645 6.05549C1.69146 5.34212 1.9935 4.66507 2.51012 4.16025C3.02681 3.65538 3.71974 3.36042 4.44971 3.33409L4.46153 3.33366H6.50293V1.66699ZM6.50293 5.99594H7.75293V4.58638H12.3428V5.99594H13.5928V4.58638H15.5194C15.9243 4.58905 16.3116 4.74725 16.5978 5.02684C16.884 5.30652 17.046 5.68508 17.0487 6.0806V14.7534C17.046 15.1489 16.884 15.5275 16.5978 15.8071C16.3116 16.0867 15.9243 16.2449 15.5195 16.2476H4.47529C4.07048 16.2449 3.68313 16.0867 3.39701 15.8071C3.11081 15.5275 2.94884 15.1489 2.94611 14.7535V6.09112C2.96369 5.69762 3.1315 5.32471 3.41667 5.04606C3.70178 4.76747 4.08339 4.60356 4.48618 4.58638H6.50293V5.99594Z" fill="#AE4EA9"/>
            </svg>
            <span>EVENTS</span>
          </div>
          <ul class="left-bar-menu-links left-bar_list mCustomScrollbar">
            <li :class="{'music-sub-menus parent': true,
            'child-active': isExploreActive,
            'active': isExploreCollapseOpen && !isExploreActive
            }">
              <a
                class="with-drop"
                data-toggle="collapse"
                href="#multiCollapseExample1"
                role="button"
                aria-expanded="true"
                aria-controls="multiCollapseExample1"
              >
                <div class="music-left-icon">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.9974 1.94904C5.10308 1.94904 1.94611 5.10601 1.94611 9.00033C1.94611 12.8946 5.10308 16.0516 8.9974 16.0516C12.8917 16.0516 16.0487 12.8946 16.0487 9.00033C16.0487 5.10601 12.8917 1.94904 8.9974 1.94904ZM0.664062 9.00033C0.664062 4.39795 4.39502 0.666992 8.9974 0.666992C13.5998 0.666992 17.3307 4.39795 17.3307 9.00033C17.3307 13.6027 13.5998 17.3337 8.9974 17.3337C4.39502 17.3337 0.664062 13.6027 0.664062 9.00033Z" fill="#071526"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1703 5.82737C12.317 5.97402 12.3682 6.19093 12.3026 6.38767L10.9097 10.5664C10.8552 10.7299 10.7269 10.8582 10.5634 10.9127L6.38474 12.3055C6.188 12.3711 5.97109 12.3199 5.82444 12.1733C5.6778 12.0266 5.62659 11.8097 5.69217 11.613L7.08507 7.43429C7.13957 7.27079 7.26786 7.1425 7.43136 7.088L11.6101 5.6951C11.8068 5.62952 12.0237 5.68073 12.1703 5.82737ZM8.03735 8.04028L7.07731 10.9204L9.95744 9.96037L10.9175 7.08024L8.03735 8.04028Z" fill="#071526"/>
                  </svg>
                </div>
                <span class="main-link-tab-music">EXPLORE</span>
              </a>
            </li>
            <div
              class="multi-collapse left-bar-menu-links collapse show"
              id="multiCollapseExample1"
            >
              <ul class="subitem-list-ul left-bar-menu-links">
                <router-link
                  tag="li"
                  to="/events/page/overview"
                  class="music-sub-menus"
                >
                  <a href="javascript:">
                    <span class="main-link-tab-sub">Overview</span>
                  </a>
                </router-link>
                <router-link
                  tag="li"
                  to="/events/page/gigs"
                  class="music-sub-menus"
                >
                  <a href="javascript:">
                    <span class="main-link-tab-sub">Gigs</span>
                  </a>
                </router-link>
                <router-link
                  tag="li"
                  to="/events/page/clubs"
                  class="music-sub-menus"
                >
                  <a href="javascript:">
                    <span class="main-link-tab-sub">Clubs</span>
                  </a>
                </router-link>
                <router-link
                  tag="li"
                  to="/events/page/festivals"
                  class="music-sub-menus"
                >
                  <a href="javascript:">
                    <span class="main-link-tab-sub">Festivals</span>
                  </a>
                </router-link>
                <router-link
                  tag="li"
                  to="/events/page/livestreaming"
                  class="music-sub-menus"
                >
                  <a href="javascript:">
                    <span class="main-link-tab-sub">Livestreaming</span>
                  </a>
                </router-link>
              </ul>
            </div>
            <li class="music-sub-menus parent">
              <a>
                <div class="music-left-icon">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.664062 8.03839C0.664062 7.68436 0.95106 7.39737 1.30509 7.39737C3.77024 7.39737 6.13443 8.37665 7.87756 10.1198C9.62068 11.8629 10.6 14.2271 10.6 16.6922C10.6 17.0463 10.313 17.3333 9.95894 17.3333C9.60491 17.3333 9.31791 17.0463 9.31791 16.6922C9.31791 14.5671 8.47371 12.529 6.97101 11.0263C5.46831 9.52362 3.43022 8.67942 1.30509 8.67942C0.95106 8.67942 0.664062 8.39242 0.664062 8.03839Z" fill="#071526"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.664062 1.30802C0.664062 0.953989 0.95106 0.666992 1.30509 0.666992C5.55535 0.666992 9.63154 2.3554 12.6369 5.36079C15.6423 8.36619 17.3307 12.4424 17.3307 16.6926C17.3307 17.0467 17.0437 17.3337 16.6897 17.3337C16.3357 17.3337 16.0487 17.0467 16.0487 16.6926C16.0487 12.7824 14.4953 9.0323 11.7304 6.26734C8.96542 3.50238 5.21533 1.94904 1.30509 1.94904C0.95106 1.94904 0.664062 1.66205 0.664062 1.30802Z" fill="#071526"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.664062 15.7307C0.664062 14.8456 1.38156 14.1281 2.26663 14.1281C3.1517 14.1281 3.86919 14.8456 3.86919 15.7307C3.86919 16.6158 3.1517 17.3333 2.26663 17.3333C1.38156 17.3333 0.664062 16.6158 0.664062 15.7307Z" fill="#071526"/>
                  </svg>
                </div>
                <span class="main-link-tab-music">FEED</span>
              </a>
            </li>
            <li :class="{'music-sub-menus parent': true,
            'child-active': isMyEventsActive,
            'active': isMyEventsCollapseOpen && !isMyEventsActive
            }">
              <a
                class="with-drop"
                data-toggle="collapse"
                href="#multiCollapseExample2"
                role="button"
                aria-expanded="true"
                aria-controls="multiCollapseExample2"
              >
                <div class="music-left-icon">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.31143 1.9512C2.19428 1.9512 1.91536 2.21638 1.91406 2.33366L1.94687 15.8331C1.94555 15.9522 2.04165 16.0495 2.16065 16.0495H15.8341C15.9522 16.0495 16.0479 15.9536 16.0479 15.8354V5.39335C16.0479 5.27515 15.9522 5.17932 15.8341 5.17932H7.23622C6.75075 5.17932 6.29545 4.94358 6.01488 4.54696L4.24256 2.04153C4.20247 1.98487 4.13743 1.9512 4.06808 1.9512H2.31143ZM0.664063 2.33366C0.673118 1.51271 1.49134 0.666992 2.31143 0.666992H4.06808C4.55355 0.666992 5.00885 0.90273 5.28942 1.29935L7.06174 3.80478C7.10183 3.86144 7.16687 3.89511 7.23622 3.89511H15.8341C16.6607 3.89511 17.3307 4.5659 17.3307 5.39335V15.8354C17.3307 16.6629 16.6607 17.3337 15.8341 17.3337H2.16065C1.32765 17.3337 0.654958 16.6528 0.664156 15.8189L0.664063 2.33366Z" fill="#071526"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.34375 1.50033C7.34375 1.15515 7.62357 0.875326 7.96875 0.875326H16.4975C16.8426 0.875326 17.1225 1.15515 17.1225 1.50033C17.1225 1.8455 16.8426 2.12533 16.4975 2.12533H7.96875C7.62357 2.12533 7.34375 1.8455 7.34375 1.50033Z" fill="#071526"/>
                  </svg>
                </div>
                <span class="main-link-tab-music">MY EVENTS</span>
              </a>
            </li>
            <div
              class="multi-collapse left-bar-menu-links collapse"
              id="multiCollapseExample2"
            >
              <ul class="subitem-list-ul left-bar-menu-links">
                <li
                  class="music-sub-menus"
                  :class="[$route.path.includes('favorites') ? 'active' : '']"
                >
                  <router-link to="/events/page/favorites">
                    <span class="main-link-tab-sub">Favorites</span>
                  </router-link>
                </li>
                <li
                  class="music-sub-menus"
                  :class="[$route.path.includes('invites') ? 'active' : '']"
                >
                  <router-link to="/events/page/invites">
                    <span class="main-link-tab-sub">Invites</span>
                  </router-link>
                </li>
                <li
                  class="music-sub-menus"
                  :class="[$route.path.includes('my_Tickets') ? 'active' : '']"
                >
                  <router-link to="/events/page/my_Tickets">
                    <span class="main-link-tab-sub">My Tickets</span>
                  </router-link>
                </li>
              </ul>
            </div>
            <li>
              <div class="menu-left-seprator-line"></div>
            </li>
            <li>
              <div class="cup new-playlist-music" @click="createEventsModal = !createEventsModal">
                <div class="new-playlist-icon">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 0.5C8.34518 0.5 8.625 0.787817 8.625 1.14286V7.375H14.8571C15.2122 7.375 15.5 7.65482 15.5 8C15.5 8.34518 15.2122 8.625 14.8571 8.625H8.625V14.8571C8.625 15.2122 8.34518 15.5 8 15.5C7.65482 15.5 7.375 15.2122 7.375 14.8571V8.625H1.14286C0.787817 8.625 0.5 8.34518 0.5 8C0.5 7.65482 0.787817 7.375 1.14286 7.375H7.375V1.14286C7.375 0.787817 7.65482 0.5 8 0.5Z" fill="#D1433A"/>
                  </svg>
                </div>
                <div class="play-word">New Event</div>
              </div>
            </li>
          </ul>
        </div>
      </perfect-scrollbar>
    </div>
    <div v-if="createEventsModal" class="create-events-wrapper">
      <event-post-container @closeEventModal="createEventsModal = false" />
    </div>
  </div>
</template>

<script>
import EventPostContainer from "./components/CreateEvents/EventPostContainer.vue";

export default {
  components: { EventPostContainer },
  data() {
    return {
      is_active: "event.overview",
      toggleLeftNav: false,
      collapseExploreId: "multiCollapseExample1",
      collapseMyEventsId: "multiCollapseExample2",
      isExploreCollapseOpen: false,
      isMyEventsCollapseOpen: false,
      createEventsModal: false,
    };
  },
  mounted() {
    this.updateCollapseState();
    this.observeCollapse();
  },
  computed: {
    isExploreActive() {
      const paths = ["overview", "gigs", "clubs", "festivals", "livestreaming"];
      return paths.some((path) => this.$route.path.includes(path));
    },
    isMyEventsActive() {
      const paths = ["favorites", "invites", "my_Tickets"];
      return paths.some((path) => this.$route.path.includes(path));
    },
  },
  methods: {
    redirect(url) {
      this.is_active = url;
      this.$router.push({ name: url });
    },

    updateCollapseState() {
      const elementExplore = document.getElementById(this.collapseExploreId);
      if (elementExplore) {
        this.isExploreCollapseOpen = elementExplore.classList.contains("show");
      }

      const elementMyEvents = document.getElementById(this.collapseMyEventsId);
      if (elementMyEvents) {
        this.isMyEventsCollapseOpen = elementMyEvents.classList.contains("show");
      }
    },

    observeCollapse() {
      const elementExplore = document.getElementById(this.collapseExploreId);
      const elementMyEvents = document.getElementById(this.collapseMyEventsId);

      if (elementExplore) {
        const observerExplore = new MutationObserver(() => {
          this.updateCollapseState();
        });

        observerExplore.observe(elementExplore, {
          attributes: true,
          attributeFilter: ["class"],
        });

        this.$once("hook:beforeDestroy", () => observerExplore.disconnect());
      }

      if (elementMyEvents) {
        const observerMyEvents = new MutationObserver(() => {
          this.updateCollapseState();
        });

        observerMyEvents.observe(elementMyEvents, {
          attributes: true,
          attributeFilter: ["class"],
        });

        this.$once("hook:beforeDestroy", () => observerMyEvents.disconnect());
      }
    },
  },
};
</script>

<style scoped lang="scss">

#left_sidebar {
  width: 230px;
  height: 100%;
  box-shadow: 0 0 20px 0 rgba(71, 80, 91, 0.15);

  .ps {
    margin-top: 70px;
    width: 230px;
    height: unset !important;
  }

  .general-menu {
     &.home-style {
       padding: 20px;

       .menu-title {

         &.events-btn {
           margin: 0 !important;
           width: 190px;
           height: 40px;
           top: 90px;
           left: 20px;
           padding: 10px 0 10px 0;
           gap: 10px;
           border-radius: 4px;
           border: 1px Solid rgba(174, 78, 169, 1);
           display: flex;
           align-items: center;
           justify-content: center;
           background-color: inherit;

           svg {
             path {
               stroke: rgba(174, 78, 169, 1);
               stroke-width: 0.3px;
             }
           }

           span {
             font-family: HelveticaNeue, sans-serif;
             font-size: 13px;
             font-weight: 700;
             line-height: 15.87px;
             text-align: left;
             text-underline-position: from-font;
             text-decoration-skip-ink: none;
             color: rgba(174, 78, 169, 1);
             margin-top: 0.1rem;
           }
         }
       }

       .left-bar-menu-links {
          &.left-bar_list {
            margin-top: 2rem;
            padding: 0;
            width: 190px;
            gap: 20px;

            .parent {
              &.music-sub-menus {
                width: 190px;
                height: 30px;
                gap: 0;
                transition: border-color, background-color 0.3s ease;
                border-left: 4px Solid white;

                &.active, &:hover  {
                  border-radius: 0 4px 4px 0;
                  border-color: rgba(209, 67, 58, 1);
                  background-color: rgba(209, 67, 58, 0.1);

                  a {
                    border: none;

                    svg {
                      path {
                        fill: rgba(209, 67, 58, 1);
                        stroke-width: 0;
                      }
                    }

                    span {
                      color: rgba(209, 67, 58, 1);
                    }
                  }
                }

                &.child-active {
                  a {
                    svg {
                      path {
                        fill: rgba(209, 67, 58, 1);
                        stroke-width: 0;
                      }
                    }

                    span {
                      color: rgba(209, 67, 58, 1);
                    }
                  }
                }

                a {
                  display: flex;
                  align-items: center;
                  justify-content: start;
                  gap: 15px;
                  border: none;

                  .music-left-icon {
                    margin: 0;
                  }

                  span {
                    font-family: HelveticaNeue, sans-serif !important;
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 15.87px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: rgba(7, 21, 38, 1);
                    margin-top: 0.1rem;
                  }
                }

              }
            }

            .multi-collapse {
              &.left-bar-menu-links {

                .subitem-list-ul {

                  .music-sub-menus {

                    &:hover {
                      a {
                        span {
                          color: rgba(209, 67, 58, 1);
                        }
                      }
                    }

                    a {

                      span {
                        font-family: HelveticaNeue, sans-serif !important;
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 15.87px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: rgba(71, 80, 91, 1);
                      }
                    }
                  }
                }
              }
            }

            .cup {
              &.new-playlist-music {

                .new-playlist-icon {
                  background-color: white;
                }

                .play-word {
                  font-family: HelveticaNeue,sans-serif;
                  font-size: 12px;
                  font-weight: 700;
                  line-height: 14.65px;
                  text-align: left;
                  text-underline-position: from-font;
                  text-decoration-skip-ink: none;
                  color: rgba(7, 21, 38, 1);
                }
              }
            }
          }
       }
     }
  }
}
.cup {
  cursor: pointer;
}
</style>