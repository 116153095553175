<template>
  <div>
    <div v-if="getFollowersList.length > 0">
      <div class="row mt_10">
      <div class="col-md-6 " v-for="(my_follower, index) in getFollowersList" :key="index">
        <div class="frind_list_box">
          <div class="friend_profile_image">
            <div class="friend-image-wrapper cursor-pointer"
                 @click="redirectWidgetsToProfilePage($event, my_follower.username ? my_follower.username : '')"
            >
              <img :src="my_follower.artwork_url" alt="user_image" @error="onProfileImageError($event, getFirstAlphabetOfUserName(my_follower))">
            </div>
          </div>
          <div class="friend_profile_content">
            <h4 class="friend_profile_name cursor-pointer"
                @click="redirectWidgetsToProfilePage($event, my_follower.username ? my_follower.username : '')"
            >{{ my_follower.name}}</h4>
            <p class="friend_profile_location">
              <span class="location_map">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  v-if="my_follower.mututal_friends !== 0"
                >
                  <path
                    d="M5.25 10.5C5.25 10.5 4.5 10.5 4.5 9.75C4.5 9 5.25 6.75 8.25 6.75C11.25 6.75 12 9 12 9.75C12 10.5 11.25 10.5 11.25 10.5H5.25ZM8.25 6C8.84674 6 9.41903 5.76295 9.84099 5.34099C10.2629 4.91903 10.5 4.34674 10.5 3.75C10.5 3.15326 10.2629 2.58097 9.84099 2.15901C9.41903 1.73705 8.84674 1.5 8.25 1.5C7.65326 1.5 7.08097 1.73705 6.65901 2.15901C6.23705 2.58097 6 3.15326 6 3.75C6 4.34674 6.23705 4.91903 6.65901 5.34099C7.08097 5.76295 7.65326 6 8.25 6Z"
                    fill="#47505B"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.912 10.4998C3.80082 10.2657 3.74537 10.009 3.75 9.74983C3.75 8.73358 4.26 7.68733 5.202 6.95983C4.73182 6.81496 4.24196 6.74411 3.75 6.74983C0.75 6.74983 0 8.99983 0 9.74983C0 10.4998 0.75 10.4998 0.75 10.4998H3.912Z"
                    fill="#47505B"
                  />
                  <path
                    d="M3.375 6C3.87228 6 4.34919 5.80246 4.70083 5.45083C5.05246 5.09919 5.25 4.62228 5.25 4.125C5.25 3.62772 5.05246 3.15081 4.70083 2.79917C4.34919 2.44754 3.87228 2.25 3.375 2.25C2.87772 2.25 2.40081 2.44754 2.04917 2.79917C1.69754 3.15081 1.5 3.62772 1.5 4.125C1.5 4.62228 1.69754 5.09919 2.04917 5.45083C2.40081 5.80246 2.87772 6 3.375 6Z"
                    fill="#47505B"
                  />
                </svg>
                <svg width="10" height="12" viewBox="0 0 10 12" fill="none"
                     xmlns="http://www.w3.org/2000/svg" v-if="my_follower.mututal_friends === 0">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M5 11.5C5 11.5 9.5 8.49997 9.5 4.99997C9.5 3.8065 9.02589 2.6619 8.18198 1.81799C7.33807 0.974075 6.19347 0.499969 5 0.499969C3.80653 0.499969 2.66193 0.974075 1.81802 1.81799C0.974106 2.6619 0.5 3.8065 0.5 4.99997C0.5 8.49997 5 11.5 5 11.5ZM6.5 4.99997C6.5 5.8284 5.82843 6.49997 5 6.49997C4.17157 6.49997 3.5 5.8284 3.5 4.99997C3.5 4.17154 4.17157 3.49997 5 3.49997C5.82843 3.49997 6.5 4.17154 6.5 4.99997Z"
                        fill="#47505B"/>
               </svg>
              </span>
              <span v-if="my_follower.mututal_friends !== 0">{{ my_follower.mututal_friends }} mutual friends</span>
              <span class="mr-0" v-if="my_follower.mututal_friends === 0"> {{(my_follower.country.length) ? my_follower.country[0].name:''}}</span>
            </p>
          </div>
          <div class="friends_friend_icon" @click="changeFollowStatus(my_follower.id, 'user',1, my_follower)" v-if="my_follower.follow_status === 0">
            <svg width="18" height="15" viewBox="0 0 18 15" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path class="friends_icon_path"
                    d="M12.0345 14.1553V12.776C12.0345 12.0443 11.7439 11.3427 11.2265 10.8253C10.7092 10.308 10.0075 10.0173 9.27588 10.0173H3.75863C3.02699 10.0173 2.32533 10.308 1.80798 10.8253C1.29064 11.3427 1 12.0443 1 12.776V14.1553"
                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="friends_icon_path"
                    d="M6.51644 7.25846C8.03998 7.25846 9.27506 6.02338 9.27506 4.49984C9.27506 2.97629 8.03998 1.74121 6.51644 1.74121C4.99289 1.74121 3.75781 2.97629 3.75781 4.49984C3.75781 6.02338 4.99289 7.25846 6.51644 7.25846Z"
                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="friends_icon_path" d="M14.3906 4.47363V8.91254" stroke="#D1433A"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="friends_icon_path" d="M12.2461 6.69336H16.685" stroke="#D1433A"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="friends_friend_icon frd-following-icon" @click="changeFollowStatus(my_follower.id, 'user', 0, my_follower)" v-if="my_follower.follow_status === 1">
            <svg width="18" height="15" viewBox="0 0 18 15" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path class="friends_icon_path"
                    d="M12.0345 14.1553V12.776C12.0345 12.0443 11.7439 11.3427 11.2265 10.8253C10.7092 10.308 10.0075 10.0173 9.27588 10.0173H3.75863C3.02699 10.0173 2.32533 10.308 1.80798 10.8253C1.29064 11.3427 1 12.0443 1 12.776V14.1553"
                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="friends_icon_path"
                    d="M6.51644 7.25846C8.03998 7.25846 9.27506 6.02338 9.27506 4.49984C9.27506 2.97629 8.03998 1.74121 6.51644 1.74121C4.99289 1.74121 3.75781 2.97629 3.75781 4.49984C3.75781 6.02338 4.99289 7.25846 6.51644 7.25846Z"
                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="friends_icon_path" d="M14.3906 4.47363V8.91254" stroke="#D1433A"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="friends_icon_path" d="M12.2461 6.69336H16.685" stroke="#D1433A"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
      <spinner v-if="getSpinnerData && (getSpinnerData.display && getSpinnerData.for === 'show_more_followers')"/>
      <div class="show_more_btn" v-if="(getFollowersTotal > getFollowersList.length) && !getSpinnerData">
      <div class="show-more musicbar_more">
        <a href="javascript:;" @click="showMoreFollowers">
          <span class="show_more_bg">
             <svg width="9" height="8" viewBox="0 0 9 8" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                <path d="M4.24219 0.689941V7.01994" stroke="#071526" stroke-width="1.3"
                      stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M1 3.85498H7.48702" stroke="#071526" stroke-width="1.3"
                      stroke-linecap="round" stroke-linejoin="round"></path>
             </svg>
             <span class="mt-1">
               SHOW 10 MORE FOLLOWERS
             </span>
          </span>
        </a>
      </div>
    </div>
  </div>
  <no-data v-else cardTitle="You don't have any followers yet."
  cardSubTitle="Browse the playyground to find followers." cardIconType="FriendsIcon"/>
  </div>
</template>
<script type="application/javascript">
import fetchApis from "../../../../utils/DynamicFunctionToCallApis";
import Spinner from "../../../Common/Spinners/spinner.vue";

export default {
  props:['friendsSearchKey','currentActiveFriendTab'],
  data() {
    return {
      showMore:true
    }
  },
  components: {
    Spinner,
    "no-data": require("@/components/Home/People/NoData").default,
  },
  computed:{
    getFollowersList(){
      return this.$store.getters['getMyFollowers']
    },
    getFollowersTotal(){
      return this.$store.getters['getFollowersTotal']
    },
    getSpinnerData(){
      return this.$store.getters.getSpinner
    }
  },
  methods:{
    showMoreFollowers(){
      let payload = {
        showMore : this.showMore,
        friendsSearchKey : this.friendsSearchKey,
        search_type: this.currentActiveFriendTab
      }
      fetchApis([() => this.$store.dispatch('getMyFollowers', payload)], this.$store, false, true, 'show_more_followers').catch((error) => {
        console.log(error)
      })
    },
     changeFollowStatus(id,object_type,action, my_follower){
      let payload = {
        id : id,
        object_type : object_type,
        action : action,
        user : my_follower
      }
       this.$store.dispatch('changeFollowStatus', payload)
    }
  }
}
</script>