<template>
  <div class="main-container_full" :class="custom_css_class">
    <LeftNav
      v-if="ShowLeftNav"
      category="manager"
      type="event"
      class="left-bar-placement"
    />
    <div class="app-container wr_Event_Manager_content fixWrEventManager">
      <router-view />
    </div>
  </div>
</template>
<script>
import LeftNav from "@/components/DesignGuide/Layout/LeftNav.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      currentUrl: "",
    };
  },
  computed: {
    ...mapGetters({
      form_data: "main_container_full_class_map_to_props",
    }),
    currentPage() {
      return this.$store.getters.getCurrentPage;
    },
    currentUserIsAdmin() {
      return !!(
        this.currentPage &&
        this.currentPage.current_user &&
        this.currentPage.current_user === "admin"
      );
    },
    custom_css_class() {
      const { main_container_full_classes = "" } = this.form_data;

      return main_container_full_classes;
    },
    ShowLeftNav() {
      if (!this.$route.meta.showLeftNav) {
        return false;
      }
      if (
        this.currentUrl === "book-artist" ||
        this.currentUrl === "related-album-list"
      ) {
        return false;
      }
      return true;
    },
  },
  props: {
    ShowMusicFooter: Boolean,
  },
  beforeMount() {
    this.showCon();
  },
  components: {
    LeftNav,
  },
  methods: {
    showCon: function () {
      this.currentUrl = this.$route.name;
    },
  },
};
</script>
<style lang="scss" scoped>
.app-container {
  padding-right: 21% !important;
  padding-left: 18% !important;
  padding-bottom: 100px;
}
</style>
<style lang="scss">
.wr_Event_Manager_content {
  margin-top: 25px;
  .EM_Pages_title {
    font-family: DrukWide;
    color: #071526;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    margin-bottom: 13px;
  }
  .event_manager_contener {
    display: flex;
    justify-content: space-between;
  }
  .w-1170 {
    width: 1170px;
  }
  .w-770 {
    width: 770px;
  }
  .w-370 {
    width: 370px;
  }
  .wr_nav {
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #e6eaf0;
    border-radius: 4px;
    height: 70px;
    font-family: HelveticaNeueMedium;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: #47505b;
    margin-bottom: 30px;
    .back-link {
      height: 100%;
      display: flex;
      align-items: center;
      width: 169px;
      justify-content: center;
      border-right: 1px solid #e6eaf0;
      margin-right: 30px;
      font-family: HelveticaNeueBold;
      color: #071526;
      font-weight: 700;
      svg {
        margin-right: 10px;
      }
    }
  }
}
</style>