var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
  'tile-music-waves',
  _vm.fullWaves ? 'full-waves-width' : '',
  _vm.fullChatView ? 'full-chat-width' : '',
]},[_c('div',{class:[
    'cursor-pointer',
    _vm.fullWaves || _vm.fullChatView ? 'full-waves' : 'waveform-holder ',
  ]},[_c('div',{staticClass:"wavess",attrs:{"id":'wave' + _vm.latest_list_id}})]),(_vm.songReady < 100 && !_vm.audioExpired)?_c('div',{staticClass:"rel fixAudioLodr"},[_vm._m(0)]):_vm._e(),(_vm.audioExpired)?_c('div',{staticClass:"rel fixAudioLodr"},[_vm._m(1)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"abs"},[_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"abs abs1"},[_c('p',[_vm._v("Audio expired")])])
}]

export { render, staticRenderFns }