<template>
  <div class="engage_settings">
    <div class="engage_settings_header">
      <div>
        <h3 class="btn_visibility_header">Button Visibility: {{ subscribe_settings.visibility? 'Published': 'Hidden' }}</h3>
        <h6 class="btn_visibility_text">Published (switcher is on) anyone can see this button in your Engage. Switch off if you want to hide your
          button</h6>
      </div>
      <div class="switch_sm_wrapper">
        <input type="checkbox" v-model="subscribe_settings.visibility" id="switch23">
        <label class="switch_sm" for="switch23">
          <div class="switch_toggler"></div>
        </label>
      </div>
    </div>
    <div class="enge-btn-sub-plan">
      <h1 class="membership-plan-header">Membership plans</h1>
      <p class="membership-plan-text">Create and edit Membership plans via Subscriptions tab</p>
    </div>
    <div class="engage_settings_footer">
      <h4 class="btn-preview-text">our Button preview:</h4>
      <button type="button" class="engage_preview_btn">
        <!-- <img src="img/svg/sponsor.svg" alt="Sponsor">  -->
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M15.2602 11.0859L13.7103 12.7498C13.5099 12.1057 12.9084 11.6367 12.1992 11.6367H9.72791C9.38686 11.6367 9.05797 11.5126 8.8019 11.2873C7.42475 10.0754 5.35472 10.0648 3.96457 11.2538C3.67791 10.8479 3.20551 10.582 2.67188 10.582H0.527344C0.236109 10.582 0 10.8181 0 11.1094V17.4726C0 17.7639 0.236109 18 0.527344 18H2.67188C3.14546 18 3.57082 17.7905 3.861 17.4597C4.9757 17.773 6.01847 18 7.45534 18H12.1992C13.3549 18 14.4589 17.448 15.1539 16.5213L17.6839 13.1129C17.684 13.1128 17.6841 13.1128 17.6841 13.1127C17.6844 13.1124 17.6846 13.112 17.6848 13.1117L17.6857 13.1106C17.6858 13.1104 17.6859 13.1102 17.686 13.1101C17.8914 12.8351 18 12.5081 18 12.1641C18 10.7277 16.233 10.0417 15.2602 11.0859ZM3.19922 16.418C3.19922 16.7087 2.96265 16.9453 2.67188 16.9453H1.05469V11.6367H2.67188C2.96265 11.6367 3.19922 11.8733 3.19922 12.1641V16.418ZM16.8404 12.4798C16.8401 12.4802 16.8399 12.4806 16.8396 12.4809C16.8394 12.4812 16.839 12.4816 16.8388 12.4819L14.3086 15.8906C13.8133 16.551 13.0247 16.9453 12.1992 16.9453H7.45534C6.21875 16.9453 5.24704 16.7557 4.24543 16.4742C4.24754 16.4137 4.24688 16.7383 4.24688 12.4025L4.62139 12.0791C5.61442 11.2052 7.11211 11.2052 8.1052 12.0791C8.55394 12.4739 9.13018 12.6914 9.72791 12.6914H12.1992C12.49 12.6914 12.7266 12.928 12.7266 13.2187C12.7266 13.5095 12.49 13.7461 12.1992 13.7461H8.41619C8.12496 13.7461 7.88885 13.9822 7.88885 14.2734C7.88885 14.5647 8.12496 14.8008 8.41619 14.8008H12.5527C12.9902 14.8008 13.4121 14.6172 13.7103 14.2971L16.032 11.8048C16.1329 11.6964 16.27 11.6367 16.418 11.6367C16.8524 11.6367 17.0991 12.1349 16.8404 12.4798Z"
              fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.39844 5.80078C6.39844 2.60223 9.00067 0 12.1992 0C15.3978 0 18 2.60223 18 5.80078C18 8.99933 15.3978 11.6016 12.1992 11.6016C9.00067 11.6016 6.39844 8.99933 6.39844 5.80078ZM11.6748 4.74609C11.6748 5.03687 11.9114 5.27344 12.2022 5.27344C13.0745 5.27344 13.7842 5.98314 13.7842 6.85547C13.7842 7.51806 13.366 8.11557 12.7436 8.34226L12.7295 8.34704V8.96484C12.7295 9.25608 12.4934 9.49219 12.2022 9.49219C11.911 9.49219 11.6748 9.25608 11.6748 8.96484V8.35179C11.3269 8.23461 10.9951 8.00184 10.71 7.66245C10.5227 7.43941 10.5516 7.10677 10.7746 6.91945C10.9977 6.73214 11.3303 6.76111 11.5176 6.98411C11.7965 7.31616 12.112 7.44993 12.3827 7.35128C12.5901 7.27569 12.7295 7.07646 12.7295 6.85547C12.7295 6.56469 12.493 6.32812 12.2022 6.32812C11.3299 6.32812 10.6202 5.61843 10.6202 4.74609C10.6202 4.08593 11.0289 3.48427 11.6748 3.25445V2.63672C11.6748 2.34548 11.911 2.10938 12.2022 2.10938C12.4934 2.10938 12.7295 2.34548 12.7295 2.63672V3.25104C13.0035 3.34304 13.2682 3.50606 13.5062 3.73672C13.7154 3.93936 13.7207 4.27321 13.5181 4.48239C13.3153 4.69153 12.9816 4.6968 12.7724 4.49416C12.5156 4.2454 12.233 4.16007 11.9968 4.26006C11.8013 4.34292 11.6748 4.53368 11.6748 4.74609Z"
                fill="white"/>
        </svg>
        <span class="btn_text">Membership</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      subscribe_settings: {
        visibility: false
      },
    }
  },
  props: {
    subscribeSettings: {
      type: Object,
      default: ()=>{}
    }
  },
  watch: {
    'subscribe_settings.visibility'() {
      this.$emit('getSubscribeData', this.subscribe_settings)
    },
    subscribeSettings() {
      this.setSubscribeData()
    }
  },
  mounted() {
    this.setSubscribeData()
  },
  methods: {
    setSubscribeData() {
      this.subscribe_settings = this.subscribeSettings
    },
  }
}
</script>
