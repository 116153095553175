<template>
  <div>
    <div v-if="!input_check" class="comment-post-html-newsfeed" :style="(design_check) ? 'margin-top: 0;' : ''">
      <div class="user-post-filed-image">
        <img
          :src="user.user_data.artwork_url ? user.user_data.artwork_url : require(`@/assets/img/user-placeholder.png`)">
      </div>
      <div class="post-something-field" :style="emoji ? 'z-index: 1' : ''">
        <textarea
          type="text"
          class="scroll"
          placeholder="Write a Comment"
          v-model="comment"
          v-on:keyup="checkEnter"
          ref="Input"
          @click="emoji = false"
        />
        <div
          class="comment-emojis"
          v-if="emoji"
          @shown="onShown"
          @hidden="onHidden"
          ref="dialog"
          :style="emojiAbove ? { bottom: 'calc(100% + 10px)' } : { top: 'calc(100% + 10px)' }"
        >
          <VEmojiPicker
            @select="selectEmoji"
            :emojiWithBorder="false"
            :emojiSize="26"
            :continuousList="true"
            :emojisByRow="6"
            class="emoji-picker"
          />
        </div>
        <ul class="list_of_icons">
          <li v-if="!design_check" @click="openImageAndCoverModel()">
            <span>
            <div class="f-up"><input type="file" id="myfile" name="myfile" @change="updateImage"></div>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.8571 10.5714L14.1429 7.85714L9 13L6 10L1 15.1429V18.8571C1.01377 19.4211 1.24396 19.9582 1.64287 20.3571C2.04179 20.756 2.57887 20.9862 3.14286 21H18.8571C19.4211 20.9862 19.9582 20.756 20.3571 20.3571C20.756 19.9582 20.9862 19.4211 21 18.8571V3.14286C20.9862 2.57887 20.756 2.04179 20.3571 1.64287C19.9582 1.24396 19.4211 1.01377 18.8571 1H3.14286C2.57887 1.01377 2.04179 1.24396 1.64287 1.64287C1.24396 2.04179 1.01377 2.57887 1 3.14286V9.57143"
              stroke="#8B949F" stroke-width="2" stroke-miterlimit="10" />
            </svg>

            </span>
          </li>

          <li v-if="!design_check">
                            <span>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M20.2097 14.8896C19.5735 16.394 18.5785 17.7197 17.3116 18.7507C16.0448 19.7818 14.5446 20.4868 12.9424 20.8041C11.3401 21.1215 9.68449 21.0415 8.12027 20.5711C6.55606 20.1008 5.13087 19.2545 3.96931 18.1061C2.80775 16.9577 1.94518 15.5423 1.45702 13.9836C0.96886 12.4248 0.86997 10.7702 1.16899 9.16443C1.46802 7.55863 2.15585 6.05054 3.17236 4.77199C4.18887 3.49344 5.50311 2.48337 7.00017 1.83008"
                              stroke="#8B949F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                              d="M20.9996 10.9996C20.9996 9.68644 20.741 8.38613 20.2384 7.17292C19.7359 5.95972 18.9993 4.85737 18.0708 3.92882C17.1422 3.00027 16.0399 2.2637 14.8267 1.76117C13.6135 1.25865 12.3132 1 11 1V10.9996H20.9996Z"
                              stroke="#8B949F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            </span>
          </li>

          <li @click="toggleEmoji" v-if="emoji">
                            <span>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                              stroke="#D1433A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                              d="M7.07617 13.2422C7.51371 13.8384 8.08286 14.3256 8.73937 14.6659C9.39588 15.0063 10.122 15.1906 10.8614 15.2046C11.6008 15.1906 12.3269 15.0063 12.9834 14.6659C13.64 14.3256 14.2091 13.8384 14.6466 13.2422"
                              stroke="#D1433A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                              d="M14.8711 10.1543C15.5917 10.1543 16.1758 9.57018 16.1758 8.84962C16.1758 8.12906 15.5917 7.54492 14.8711 7.54492C14.1505 7.54492 13.5664 8.12906 13.5664 8.84962C13.5664 9.57018 14.1505 10.1543 14.8711 10.1543Z"
                              fill="#D1433A" />
                            <path
                              d="M7.13185 10.1543C7.85241 10.1543 8.43654 9.57018 8.43654 8.84962C8.43654 8.12906 7.85241 7.54492 7.13185 7.54492C6.41128 7.54492 5.82715 8.12906 5.82715 8.84962C5.82715 9.57018 6.41128 10.1543 7.13185 10.1543Z"
                              fill="#D1433A" />
                            </svg>
                            </span>
          </li>

          <li @click="toggleEmoji" v-if="!emoji">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                stroke="#8B949F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M7.07617 13.2432C7.51371 13.8393 8.08286 14.3265 8.73937 14.6669C9.39588 15.0073 10.122 15.1916 10.8614 15.2056C11.6008 15.1916 12.3269 15.0073 12.9834 14.6669C13.64 14.3265 14.2091 13.8393 14.6466 13.2432"
                stroke="#8B949F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M14.8711 10.1543C15.5917 10.1543 16.1758 9.57018 16.1758 8.84962C16.1758 8.12906 15.5917 7.54492 14.8711 7.54492C14.1505 7.54492 13.5664 8.12906 13.5664 8.84962C13.5664 9.57018 14.1505 10.1543 14.8711 10.1543Z"
                fill="#8B949F" />
              <path
                d="M7.13282 10.1543C7.85339 10.1543 8.43752 9.57018 8.43752 8.84962C8.43752 8.12906 7.85339 7.54492 7.13282 7.54492C6.41226 7.54492 5.82812 8.12906 5.82812 8.84962C5.82812 9.57018 6.41226 10.1543 7.13282 10.1543Z"
                fill="#8B949F" />
            </svg>

          </li>
        </ul>
        <img v-if="commentImage.image" :src="commentImage.image" alt="" class="comment-image" />
      </div>
      <b-button class="send-btn" v-if="!addCommentLoading" variant="primary" @click="submit" :disabled="!comment">
        <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0)">
            <path d="M24.3336 12.7282L12.6664 12.7282" stroke="white" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"></path>
            <path d="M24.3332 12.7277L10.0143 19.622L12.6659 12.7277L10.0143 5.8334L24.3332 12.7277Z" stroke="white"
                  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="18" height="18" fill="white" transform="translate(13.7266) rotate(45)"></rect>
            </clipPath>
          </defs>
        </svg>
      </b-button>
      <b-button class="send-btn" v-else variant="primary">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </b-button>

    </div>

    <UploadProfileAndCover
      :show="profileAndCoverModel"
      :imageCheck="true"
      @profileAndCoverValueChange="changeProfileAndCoverValue"
      @getImage="setImage"
      pic-type="profile"
    />
  </div>
</template>
<script>
import { addCommentsOfPost, getCommentsOfPost } from "../../../../apis/APIs";
import { mapGetters } from "vuex";
import { VEmojiPicker } from "v-emoji-picker";
import Vue from "vue";
import UploadProfileAndCover from "../../Profile/componenets/uploadProfileAndCover.vue";
import CommentSection from "@/components/Home/NewsFeed/components/CommentSection";

export default {
  name: "CommentInput",
  data() {
    return {
      commentItem: {},
      commentKey: 0,
      commentList: [],
      comments_count: null,
      comment: null,
      commentImage: {
        fileName: null,
        image: null,
        cropImage: null
      },
      addCommentLoading: false,
      photo: undefined,
      photo_src: undefined,

      comment_list_loading: false,
      comment_list: [],

      message: "",
      success_message: "",

      emoji: false,
      emojiAbove: true,

      profileAndCoverModel: false,
    };
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    comment_check: {
      type: Boolean,
      default: false
    },
    input_check: {
      type: Boolean,
      default: false
    },
    design_check: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.id && this.type) {
      this.init();
    }
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    }),
    id() {
      const { id = "" } = this.item || {};
      return id;
    },
    type() {
      const { type = "", post_type = "" } = this.item || {};
      if (type) {
        return type.charAt(0).toUpperCase() + type.slice(1);
      } else if (post_type || post_type === null) {
        return "Post";
      } else {
        return "";
      }
    },
    userName() {
      if (this.item.user) {
        return this.item.user.name ? "@" + this.item.user.name.charAt(0).toUpperCase() + this.item.user.name.slice(1) + " " + this.item.user.last_name.charAt(0).toUpperCase() + this.item.user.last_name.slice(1) : "";
      } else {
        return "";
      }
    },
    comments() {
      return this.commentList.length > 0 ? this.commentList : [];
    }
  },
  components: {
    VEmojiPicker,
    UploadProfileAndCover
    // CommentSection
  },
  watch: {
    type() {
      if (this.id && this.type) {
        this.init();
      }
    },
    input_check() {
      this.init();
    }
  },
  methods: {
    stopPropagationOnCommentClick(e) {
      if (e) e.stopPropagation();
    },
    async init() {
      try {
        this.comment_list_loading = true;
        const formData = new FormData();
        formData.append("commentable_type", (this.type && this.type === "Music Podcast") ? "Podcast" : this.type);
        formData.append("commentable_id", this.$route.params.id ?this.$route.params.id:this.id);
        const { data } = await getCommentsOfPost(formData);
        this.commentList = (data.information && data.information.data.length > 0) ? data.information.data : [];
        this.comments_count = (data.information) ? data.information.total : null;
        this.comment_list_loading = false;
      } catch (error) {
        console.error(error);
        this.comment_list_loading = false;
      }
    },
    async submit() {
      this.emoji = false;
      try {
        this.addCommentLoading = true;
        const formData = new FormData();
        formData.append("commentable_type", (this.type && this.type === "Music Podcast") ? "Podcast" : this.type);
        formData.append("commentable_id", this.id);
        formData.append("content", this.comment);
        formData.append("artwork", this.commentImage.cropImage);
        const { data } = await addCommentsOfPost(formData);
        this.commentItem = data.information;
        this.commentList.unshift(data.information);
        this.$emit("addComment", data.information);
        this.$emit('commentToggle');
        // Object.assign(this.commentList, [])
        if (data.success === 1) {
          let comments;
          comments = this.comments_count + 1;
          this.$emit("comments_count", comments);
          this.$emit("toggleCommentSection", this.$event);
        }
        this.reset_data();
        this.addCommentLoading = false;
      } catch (error) {
        console.error(error);
        this.addCommentLoading = false;
      }
    },
    setImage(crop_image, image, file_name) {
      this.commentImage.image = image;
      this.commentImage.fileName = file_name;
      this.commentImage.cropImage = crop_image;
    },
    openImageAndCoverModel() {
      this.profileAndCoverModel = !this.profileAndCoverModel;
      this.$store.commit("SET_CROPPER", false);
    }, 
    changeProfileAndCoverValue() {
      this.profileAndCoverModel = !this.profileAndCoverModel;
    },
    async updateImage(e) {
      const files = e.target.files;
      const file = files && files.length ? files[0] : null;
      this.photo = file;
      this.photo_src = URL.createObjectURL(file);
    },
    checkEnter(event) {
      if (event.keyCode === 13) {
        this.emoji = false;
        this.submit();
      }
    },
    reset_data() {
      this.comment = null;
      this.photo = null;
      this.photo_src = null;
    },
    toggleEmoji() {
      this.emoji = !this.emoji;

      if (this.emoji) {
        this.calculateEmojiPosition();
      }
    },
    calculateEmojiPosition() {
      const fieldRect = this.$refs.Input.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const spaceBelow = viewportHeight - fieldRect.bottom;
      const spaceAbove = fieldRect.top;

      this.emojiAbove = spaceAbove > spaceBelow;
    },
    onShown() {
      this.$refs.dialog.focus();
    },
    onHidden() {
      this.$refs.Input.focus();
    },
    selectEmoji(emoji) {
      const { data, key } = emoji || {};
      const { comment } = this;
      this.comment = comment ? `${comment} ${data}` : data;
    },
    async reactOrUnReactOnComment(comment) {
      const temp = comment;
      let obj = {
        reaction_able_id: comment.id,
        reaction_able_type: "Comment",
        reaction_type: "like"
      };
      if (comment.reacted) {
        Vue.set(comment, "reacted", null);
        const index = comment.reactions.findIndex(e => e.type = obj.reaction_type);
        if (index !== -1) {
          comment.reactions[index].count = --comment.reactions[index].count;
        }
      } else {
        Vue.set(comment, "reacted", obj);
        const index = comment.reactions.findIndex(e => e.type = obj.reaction_type);
        if (index !== -1) {
          comment.reactions[index].count = ++comment.reactions[index].count;
        } else {
          comment.reactions.push({
            type: obj.reaction_type,
            count: 1
          });
        }
      }

      let { success } = await this.$store.dispatch("reactOrUnReact", obj);
      if (!(Boolean(success))) {
        Vue.set(comment, "reacted", (comment.reacted) ? null : obj);
        comment = temp;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.comment-image {
  width: 100px;
  height: 100px;
  display: block;
  padding-bottom: 1rem;
}
.emoji-picker {
  position: absolute; 
  bottom: calc(100% + 10px); 
  left: -6rem; 
  z-index: 1000; 
  background: white; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px; 
  width: fit-content;
  padding: 5px;
}


.post-something-field {
  position: relative; 
  background: #F5F6F8;
  border-radius: 10px;
  padding-left: 20px;
  textarea {
    padding-top: 10px;
    width: 100%;
    max-width: 78% !important;
    height: 40px !important;
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
    margin-top: 3px !important;
    box-sizing: border-box;
  }

  .scroll::-webkit-scrollbar {
    display: none;
  }
}
</style>
