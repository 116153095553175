<template>
  <div class="wrap_favorites_page">
    <h1 class="titlePage">FAVORITES</h1>
    <div class="content">
      <h6>What saved items do you want to view?</h6>
      <p>Access all saved items from this dashboard</p>
      <div class="wrap_item_favorites">
        <div
          class="item_favorites"
          v-for="item in dataFavorites"
          :key="item.id"
        >
          <div class="iconDiv">
            <div v-html="item.icon"></div>
          </div>
          <h6>{{ item.title }}</h6>
          <p>{{ item.info }}</p>
          <button @click="redirectToFavorite(item.btnText)">
            Saved {{ item.btnText }}
          </button>
        </div>
      </div>
    </div>
    <!-- <div style="height:80px"></div> -->
  </div>
</template>

<script>
import { dataFavorites } from '../Constants/dataFavorites';

export default {
  data() {
    return {
      dataFavorites
    };
  },
};
</script>

<style lang="scss" scoped>
.wrap_favorites_page {
  margin: 3.5rem 0 6rem 0 !important;
  width: 1170px;
  text-align: center;
  color: #071526;
  h1 {
    font-weight: 500;
    font-size: 24px;
    font-family: DrukWide;
    margin-bottom: 13px;
    width: 1170px;
  }
  .content {
    background: #ffffff;
    border: 1px solid #e6eaf0;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 32px 70px 84px 70px;
    width: 1170px;
    h6 {
      font-family: HelveticaNeueBold;
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 21px;
    }
    p {
      font-family: HelveticaNeueNormal;
      font-weight: 400;
      font-size: 13px;
      color: #47505b;
    }
    .wrap_item_favorites {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item_favorites {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 230px;
        border: 1px solid #e6eaf0;
        box-sizing: border-box;
        border-radius: 2px;
        padding: 29px 11px;
        margin-bottom: 30px;
        &:hover {
          // 4px 0px 10px 0px rgba(71,80,91,0.15)

          box-shadow: 1px 4px 4px 1px rgba(71, 80, 91, 0.15);
          transition: all 0.3s;
          border: transparent button {
            color: #ffffff;
            background: #D1433A;
          }
          .iconDiv {
            background: #D1433A1A;
          }
        }
        .iconDiv {
          width: 90px;
          height: 90px;
          border-radius: 50%;
          background: #f3f3f3;
          margin-bottom: 27px;
          display: flex;
          align-items: center;
          justify-content: center;
          div {
            width: 40px;
            height: 40px;
            border: 0.41px solid #071526;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        h6 {
          font-size: 13px;
          margin-bottom: 5px;
        }
        p {
          font-size: 11px;
        }
        button {
          font-family: HelveticaNeueMedium;
          outline: none;
          border: 1px solid #D1433A;
          width: 130px;
          padding: 7px 0 8px;
          background: #ffffff;
          color: #D1433A;
          border-radius: 4px;
          font-size: 11px;
          line-height: 13px;
          transition: all 0.3s;
          &:hover {
            background-color: #D1433A;
            color: #fff;
            transition: all 0.3s;
          }
        }
      }
    }
  }
}
</style>
