<template>
  <div class="w-100">
    <div class="container_xxl ss px-0">
      <div class="wrapper">
        <div class="row">
          <div class="col">
            <div class="overview-mains">
              <h1 class="Page_title">Reviews</h1>
              <div class="switch-field">
                <payStreamSwitch/>
              </div>
            </div>
          </div>
        </div>
        <div class=" discover content">
          <div class=" gallery-card new-style">
            <div class="row">
              <div class="col-sm-12">
                <LatRelease :songName="'Song Name is XYZ'" :backgroundType="'type-1'"/>
              </div>
            </div>
          </div>
          <div class="tab-pane">
            <div class="row">
              <div class="col-12">
                <div class="new-releae-geners">
                  <h1 class="genres-title">Genres</h1>
                  <div class="gen_mooods">
                    <ul>
                      <li @click="selectGenre($event,eachOp.id)" v-for="(eachOp, index) in geners" :key="index" class="gen-mood-wrapper">
                        <a :class="{'active':isSelectedGenre(eachOp.id)}" href="javascript:;">
                          <span v-html="eachOp.name"></span>
                        </a>
                      </li>
                    </ul>
                    <div @click="clearGenreFilter" class="clear-history" v-if="selectedGenres.length>0">Clear all
                      history
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Start -->
          <div class="js-container">
            <div class="sale-items">
              <div class="row justify-content-between m-t-30">
                <div class="col-8 ">
                  <div v-for="(singleChannel, index) in allChannels.center" v-bind:key="index" class="m-t-20">
                    <component
                      v-bind:is="myComponentList[singleChannel.alt_name].component"
                      :cname="singleChannel.description"
                      :myInd="index"
                      :gridTitle="singleChannel.description"
                      :header="true"
                      :redirectLink="myComponentList[singleChannel.alt_name].link"
                      :altName="singleChannel.alt_name"
                      :listData="singleChannel.objects.data"
                      :pageTitle="singleChannel.title"
                      :channelType="singleChannel.type"
                       v-if="singleChannel.channel_element_view === 'six_grid_slider' && myComponentList[singleChannel.alt_name].show && singleChannel.objects.data.length > 0" />
                  </div>
                </div>
                <div class="col-md-4">
                  <MusicReviewFilters/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <notifications group="foo" class="notification-style"/>
  </div>
</template>
<script type="application/javascript">
import payStreamSwitch from "@/components/Music/UI-components/payStreamToggleSwitch.vue";
import Review4Slider from "@/components/Common/Elements/Review4Slider.vue";
import TrendingRelease from "@/components/Common/Elements/TrendingRelease.vue";
import LatRelease from "@/components/Common/Elements/LatestRelease";
import { mapActions } from "vuex";
import { getChannelData } from "../../../apis/APIs";
import MusicReviewFilters from "@/components/Music/UI-components/MusicReviewFilters";

export default {
  data() {
    return {
      selectedGenres: [],
      showMd: false,
      showAd: false,
      loading: false,
      dataLoaded: false,
      inProgress: false,
      allChannels: [],
      selected_country : '',
      selected_start_date: '',
      selected_end_date: '',
      selected_sort_by : 'most_viewed',
      myComponentList: {
        // Reviews
        'album-reviews': {'component': 'Review4Slider', 'link': 'featured-albums', 'subComponent': '', 'show':true},
        'single-reviews': {'component': 'Review4Slider', 'link': 'featured-single-reviews', 'subComponent': '', 'show':true},
        'podcast-reviews': {'component': 'Review4Slider', 'link': 'featured-podcast-reviews', 'subComponent': '', 'show':true},
        'trending-artist-reviews': { 'component': 'TrendingRelease', 'link': 'explore-review-treanding', 'subComponent': '', 'show':true },
      }
    }
  },
  components: {
    payStreamSwitch,
    LatRelease,
    Review4Slider,
    TrendingRelease,
    MusicReviewFilters
  },
  computed: {
    geners() {
      return (this.$store.getters.getGenres && this.$store.getters.getGenres.length > 0) ? this.$store.getters.getGenres.map(({ image, ...rest }) => ({ image: image ? `https://social-music.iapplabz.co.in/api/Script/content/uploads/${image}` : image, ...rest })) : [];
    }
  },
  mounted() {
    this.pageChannels();
    this.getGeners();
  },
  watch: {
    selectedGenres() {
      this.pageChannels();
    }
  },
  methods: {
    updateFilters(payload){
      console.log("payload", payload);
      this.selected_country = payload.country;
      this.selected_sort_by = payload.sort_by;
      this.selected_start_date = payload.startDate;
      this.selected_end_date = payload.endDate;
      this.pageChannels();
    },
    updateComponentList(val){
      if(val !== null){
        this.myComponentList = val;
      }else{
        this.myComponentList = {
          // Reviews
          'album-reviews': {'component': 'Review4Slider', 'link': 'featured-albums', 'subComponent': '', 'show':true},
          'single-reviews': {'component': 'Review4Slider', 'link': 'featured-single-reviews', 'subComponent': '', 'show':true},
          'podcast-reviews': {'component': 'Review4Slider', 'link': 'featured-podcast-reviews', 'subComponent': '', 'show':true},
          'trending-artist-reviews': { 'component': 'TrendingRelease', 'link': 'explore-review-treanding', 'subComponent': '', 'show':true },
        }
      }
    },
    selectGenre(e, value) {
      e.stopPropagation();
      const index = this.selectedGenres.findIndex(e => e === value);
      if (index !== -1) {
        this.selectedGenres.splice(index, 1);
      } else {
        this.selectedGenres.push(value);
      }
    },
    isSelectedGenre(id) {
      const index = this.selectedGenres.findIndex(e => e === id);
      return (index !== -1);
    },
    clearGenreFilter() {
      this.selectedGenres = [];
    },
    async pageChannels() {
      try {
        await this.$store.dispatch('loading', true)
        this.allChannels = [];
        let data = await getChannelData('reviews', this.selectedGenres, '','', '', this.selected_sort_by,'','','','',this.selected_country,this.selected_start_date,this.selected_end_date);
        this.allChannels = data.data.channels;
        this.dataLoaded = true;
        await this.$store.dispatch("loading", false);
      } catch (error) {
        console.error("error", error);
        await this.$store.dispatch("loading", false);
      }
    },
    ...mapActions([
      "get_geners"
    ]),
    async getGeners() {
      try {
        if (!this.$store.getters.getGenres && !this.$store.getters.getGenres.length > 0) {
          await this.$store.dispatch('get_geners')
        }
      } catch (error) {
        console.error(error.methods)
      }
    },
    redirect: function(url) {
      this.$router.push({ name: url, params: { title: "Hot new singles", pageFrom: "overview" } });
    }

  }
};
</script>
<style>
.switch-field input:checked + label::before {
  content: '';
  position: absolute;
  background-image: url(../../../../src/assets/img/check-white.png);
  width: 12px;
  height: 9px;
  top: 9px;
  left: 8%;
}

.all_singles .owl-item {
  width: 393px !important;
}

.sort-cehkbox-main input:checked + label::before {
  background-image: url(../../../../src/assets/img/check-page.svg);
}
</style>
<style lang="scss" scoped>
.notification-style {
  margin-top: 5rem;
}
</style>
