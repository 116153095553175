<template>
  <div class="d-flex" :key="index">
    <a
      class="FB_reactions ml-auto"
      style="position: unset;"
      :class="'FB_reactions-'+index"
      :data-reactions-type="'horizontal'"
      :data-unique-id="index"
      :data-emoji-class="reaction"
      :message-id="messageId"
      :active-user="activeUser"
      :user-id="userId"
      @mouseover="showReactions"
    >
      <div
        v-show="showBar" class="_bar"
        @mouseleave="hideReactions"
      >
        <div class="_inner">
          <img
            v-for="(emoji, idx) in emojis"
            :key="idx"
            :src="emoji.src"
            :alt="emoji.alt"
            :data-emoji-value="emoji.value"
            class="emoji"
            @click="onEmojiClick($event, emoji.value)"
          />
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import '/public/css/facebook-reaction.css';
import "/src/plugins/facebook-reactions";
import mixin from "../../mixins/getStream.js";

export default {
  name: "ReactionComponent",
  props: {
    index: {
      type: Number,
      required: true
    },
    messageId: {
      type: String,
      required: true
    },
    reaction: {
      type: String
    },
    activeUser: {
      type: String
    },
    from:{
      type: String
    },
  },
  data() {
    return {
      showBar: false
    };
  },

  computed: {
    userId() {
      return this.$store.getters.user_map_to_props.user_data.id;
    },
    emojis() {
      return [
        { src: '/css/emojis/like.svg', value: 'like', alt: 'like-emoji' },
        { src: '/css/emojis/love.svg', value: 'love', alt: 'love-emoji' },
        { src: '/css/emojis/haha.svg', value: 'haha', alt: 'haha-emoji' },
        { src: '/css/emojis/wow.svg', value: 'wow', alt: 'wow-emoji' },
        { src: '/css/emojis/sad.svg', value: 'sad', alt: 'sad-emoji' },
        { src: '/css/emojis/angry.svg', value: 'angry', alt: 'angry-emoji' }
      ];
    }
  },
  methods: {
    onEmojiClick(event, emojiValue) {
      const move_emoji = $(event.target); // jQuery reference to the clicked emoji
      if (!move_emoji || !move_emoji.length) {
        console.log('Move emoji is not valid');
        return;
      }

      const base = move_emoji.closest('.FB_reactions');
      console.log('base:', base);

      if (!base.length) {
        console.log('Base element (FB_reactions) not found!');
        return;
      }

      const base_offset = base.offset();
      if (!base_offset) {
        console.log('Error: base_offset is undefined');
        return;
      }

      const move_emoji_offset = move_emoji.position();
      if (!move_emoji_offset) {
        console.log('Error: move_emoji_offset is undefined');
        return;
      }

      const cloneemoji = move_emoji.clone().css({
        'height': '40px',
        'width': '40px',
        'opacity': '0.9',
        'position': 'absolute',
        'z-index': '99',
        'top': move_emoji_offset.top,
        'left': move_emoji_offset.left
      }).appendTo($('body'));

      cloneemoji.animate({
        'top': base_offset.top + 5,
        'left': base_offset.left + 10,
        'width': 30,
        'height': 30
      }, 300, 'easeInBack', function() {
        $(this).animate({
          'width': 27,
          'height': 27
        }, 100, function() {
          const _imgicon = $(this);
          _imgicon.fadeOut(100, function() {
            _imgicon.detach();
            base.attr("data-emoji-class", emojiValue);
            base.find('span').html(emojiValue);

            let msg_id = base.attr('message-id');
            let active_user = base.attr('active-user');
            let user_id = base.attr('user-id');
            console.log('Checking Message Id ==>', msg_id, emojiValue, active_user, user_id);

            mixin.methods.sendMessageReaction(msg_id, emojiValue, active_user, user_id).then(resp => {
            }).catch(err => {
              console.log('error', err);
            });
          });
        });
      });
    },

    async deleteReaction() {
      await mixin.methods.removeReaction(this.messageId, this.reaction, this.activeUser, this.userId).then(resp => {
      }).catch(err => {
        console.log('error', err);
      });
    },

    showReactions() {
      if (this.from!=="sender"){
        this.showBar = true;
      }
    },

    hideReactions() {
      this.showBar = false;
    }
  },
};
</script>
