<template>
  <div class="info">
    <div class="info-item">
      <span class="count">{{ GET_SINGLE_EVENT_DETAILS.going }}</span>
      <span class="text">GOING</span>
    </div>
    <div class="info-item">
      <span class="count">{{ GET_SINGLE_EVENT_DETAILS.interested }}</span>
      <span class="text">INTERESTED</span>
    </div>
    <div class="info-item">
      <span class="count">{{ GET_SINGLE_EVENT_DETAILS.total_invites }}</span>
      <span class="text">INVITED</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters(["GET_SINGLE_EVENT_DETAILS"]),
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/style/events/eventdetailspage.scss";
</style>
