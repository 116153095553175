<template>
  <div class="row">
    <div class="col-2">
      <LeftNav v-if="!isNav" />
    </div>
    <div class="col-8 d-flex justify-content-center">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import LeftNav from "@/components/Events/LeftNav";
export default {
  components: {
    LeftNav,
  },
  data() {
    return {
      arr: ["events.findEvents", "events.new-events"],
    };
  },
  computed: {
    isNav() {
      return this.arr.includes(this.$route.name) ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  padding-right: 21% !important;
  padding-left: 18% !important;
  padding-bottom: 100px;
}

.right-nav-space {
  padding-right: 280px;
}


</style>
