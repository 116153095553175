<template>
  <div class="wr_launch_info">
    <div class="launch_name">
      <span>Room Name</span>
      <div><input type="text" v-model="title" /></div>
    </div>
    <div class="wr_typs">
      <span>Room Type</span>
      <div class="wr_typs_launch" :class="{'not-justify' : (getActiveLaunchRoomFromRoom && current_page.type === 'clubroom') || (getActiveLaunchRoomFromRoom && current_page.type === 'partyroom')}">
        <div
          :class="{ active_type: activeType === 'text' }"
          @click="changeType('text')"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.25 7.75C11.25 8.05942 11.1271 8.35616 10.9083 8.57496C10.6895 8.79375 10.3928 8.91667 10.0833 8.91667H3.08333L0.75 11.25V1.91667C0.75 1.60725 0.872916 1.3105 1.09171 1.09171C1.3105 0.872916 1.60725 0.75 1.91667 0.75H10.0833C10.3928 0.75 10.6895 0.872916 10.9083 1.09171C11.1271 1.3105 11.25 1.60725 11.25 1.91667V7.75Z"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Text
        </div>

          <div
            :class="{ active_type: activeType === 'clubroom', 'ml-3':(getActiveLaunchRoomFromRoom && current_page.type==='clubroom')}"
            @click="changeType('clubroom')"
            v-show="(getActiveLaunchRoomFromRoom && current_page.type==='clubroom') || !getActiveLaunchRoomFromRoom"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_3533_16337)">
                <path
                  d="M7 0.583252C6.53587 0.583252 6.09075 0.767626 5.76256 1.09582C5.43437 1.424 5.25 1.86912 5.25 2.33325V6.99992C5.25 7.46405 5.43437 7.90917 5.76256 8.23736C6.09075 8.56554 6.53587 8.74992 7 8.74992C7.46413 8.74992 7.90925 8.56554 8.23744 8.23736C8.56563 7.90917 8.75 7.46405 8.75 6.99992V2.33325C8.75 1.86912 8.56563 1.424 8.23744 1.09582C7.90925 0.767626 7.46413 0.583252 7 0.583252V0.583252Z"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.0837 5.83325V6.99992C11.0837 8.08289 10.6535 9.1215 9.88768 9.88727C9.12191 10.653 8.08329 11.0833 7.00033 11.0833C5.91736 11.0833 4.87875 10.653 4.11297 9.88727C3.3472 9.1215 2.91699 8.08289 2.91699 6.99992V5.83325"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7 11.0833V13.4166"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.66699 13.4167H9.33366"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_3533_16337">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Audio
          </div>

          <div
            :class="{ active_type: activeType === 'partyroom', 'ml-3':(getActiveLaunchRoomFromRoom && current_page.type==='partyroom')}"
            @click="changeType('partyroom')"
            v-show="(getActiveLaunchRoomFromRoom && current_page.type==='partyroom') || !getActiveLaunchRoomFromRoom"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_3533_16345)">
                <path
                  d="M13.4163 4.08325L9.33301 6.99992L13.4163 9.91659V4.08325Z"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.16634 2.91675H1.74967C1.10534 2.91675 0.583008 3.43908 0.583008 4.08341V9.91675C0.583008 10.5611 1.10534 11.0834 1.74967 11.0834H8.16634C8.81067 11.0834 9.33301 10.5611 9.33301 9.91675V4.08341C9.33301 3.43908 8.81067 2.91675 8.16634 2.91675Z"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_3533_16345">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Video
          </div>
      </div>
    </div>
    <div class="wr-room" v-if="getActiveLaunchRoomFromRoom || activeType != 'text'">
      <span>Choose ClubRoom or PartyRoom</span>
      <div class="wr_dr_down" @click.stop="openDrDown">
        <div class="d-flex align-items-center">
          <div class="wr_img"><img :src="roomType.artwork_url" alt="" /></div>
          <p>{{ roomType.title }}</p>
        </div>
        <div>
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L5 5L9 1"
              stroke="#47505B"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="dr_down" v-if="dr_down_room">
          <div @click.stop="" class="wr_search">
            <div>
              <input
                type="text"
                placeholder="Search your pages, groups, clubs or parties"
                v-model="search"
                @input="searchPage"
              />
            </div>
          </div>
          <div
            @click.stop="handleRoom(item)"
            v-for="item in clubsPartyTypes"
            :key="item.id"
            class="item_clubsPartyType"
          >
            <div class="d-flex align-items-center">
              <div class="wr_img"><img :src="item.artwork_url" alt="" /></div>
              <div>
                <span>{{ item.title }}</span>
                <p>{{ item.content | shortTheContentLenght }}</p>
              </div>
            </div>
            <div class="radio" v-if="item.visibility">
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wr_des_cover">
      <div class="wr_des fixWidthDescModal">
        <span>Room Description</span>
        <div class="div_textarea">
          <textarea v-model="description"></textarea>
        </div>
      </div>
      <div class="wr_cover">
        <span>Cover</span>
        <div class="wr_cover_content">
          <div class="div_img">
            <img :src="readableFile"  alt="" />
          </div>
          <input type="file" class="file" ref="image" accept="image/*" @change="getUrl" />
          <div class="upload">
            <div>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.25 8.75V11.0833C12.25 11.3928 12.1271 11.6895 11.9083 11.9083C11.6895 12.1271 11.3928 12.25 11.0833 12.25H2.91667C2.60725 12.25 2.3105 12.1271 2.09171 11.9083C1.87292 11.6895 1.75 11.3928 1.75 11.0833V8.75"
                  stroke="#D1433A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.91634 4.66667L6.99967 1.75L4.08301 4.66667"
                  stroke="#D1433A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7 1.75V8.75"
                  stroke="#D1433A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Upload
            </div>
            <p>{{(file && file.name) ? file.name : "No Image"}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="wr_public_or_private">
      <div
        class="wr_public"
        :class="{ public_Or_Private: publicOrPrivate === 'public' }"
        @click="changePublicOrPrivate('public')"
      >
        <div class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.99967 14.6668C11.6816 14.6668 14.6663 11.6821 14.6663 8.00016C14.6663 4.31826 11.6816 1.3335 7.99967 1.3335C4.31778 1.3335 1.33301 4.31826 1.33301 8.00016C1.33301 11.6821 4.31778 14.6668 7.99967 14.6668Z"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.33301 8H14.6663"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.99967 1.3335C9.66719 3.15906 10.6148 5.52819 10.6663 8.00016C10.6148 10.4721 9.66719 12.8413 7.99967 14.6668C6.33215 12.8413 5.38451 10.4721 5.33301 8.00016C5.38451 5.52819 6.33215 3.15906 7.99967 1.3335V1.3335Z"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="wr_text">
          <span>Public</span>
          <p>Any user can have access to this room</p>
        </div>
        <div class="radio">
          <div></div>
        </div>
      </div>
      <div
        class="wr_private"
        :class="{ public_Or_Private: publicOrPrivate === 'private' }"
        @click="changePublicOrPrivate('private')"
      >
        <div class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6667 7.3335H3.33333C2.59695 7.3335 2 7.93045 2 8.66683V13.3335C2 14.0699 2.59695 14.6668 3.33333 14.6668H12.6667C13.403 14.6668 14 14.0699 14 13.3335V8.66683C14 7.93045 13.403 7.3335 12.6667 7.3335Z"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.66699 7.3335V4.66683C4.66699 3.78277 5.01818 2.93493 5.6433 2.30981C6.26842 1.68469 7.11627 1.3335 8.00033 1.3335C8.88438 1.3335 9.73223 1.68469 10.3573 2.30981C10.9825 2.93493 11.3337 3.78277 11.3337 4.66683V7.3335"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="wr_text">
          <span>Private</span>
          <p>Only user you’ll invite will have access to this room</p>
        </div>
        <div class="radio">
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeType: "text",
      publicOrPrivate: "public",
      dr_down_room: false,
      roomType: { },
      clubsPartyTypes: [],
      title:"",
      description:"",
      search:"",
      file:{},
      readableFile:""
    };
  },
  filters: {
    shortTheContentLenght: function(value) {
      return (value && value.length > 60) ? value.slice(0,60)+'...' : value
    }
  },
  mounted() {
  this.checkRoomData()
    },
  computed:{
    getMyPagesByType() {
      return this.$store.getters.GET_MY_PAGES_TYPE;
    },
    getActiveLaunchRoomFromRoom(){
      return this.$store.getters.getActiveLaunchRoomFromRoom;
    },
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
  },


  methods: {
    changeType(type) {
      this.activeType = type;
      this.myPages(type)
      this.dr_down_room = false;
      if(!this.getActiveLaunchRoomFromRoom)
      {
        this.roomType={}
      }

    },
    changePublicOrPrivate(type) {
      this.publicOrPrivate = type;
    },
    openDrDown() {
      if(!this.getActiveLaunchRoomFromRoom)
      {
        this.dr_down_room = !this.dr_down_room;
      }

    },
    handleRoom(roomType) {
      this.clubsPartyTypes.forEach((el) => {
        if (el.id === roomType.id) {
          el.active = true;
        } else {
          el.active = false;
        }
      });
      this.roomType = roomType;
      this.dr_down_room = false;
    },
    myPages(type)
    {
      const pages=this.getMyPagesByType
      const result = pages.data.filter((x) => x.type===type);
      this.clubsPartyTypes=result.map((e) => {
        return{
          id:e.id,
          title:e.title,
          artwork_url:e.artwork_url,
          content:e.content,
          active:e.visibility
        }
      })
    },
    searchPage(){
      let searchWord=this.search
      const myPages=this.getMyPagesByType
      const pages = myPages.data.filter((x) => x.type===this.activeType);
      let result = pages.filter(o => o.title.toLowerCase().includes(searchWord.toLowerCase()))
      this.clubsPartyTypes=result
    },
    setRoomInfo()
    {
      let success=0
      if(this.title && this.activeType && this.publicOrPrivate)
      {
        success=1
        const payload={
          room_info_name:this.title,
          room_info_type:this.activeType,
          room_page_type:this.current_page.type,
          room_page_id:(this.roomType && this.roomType.id)?this.roomType.id : '',
          room_info_description:this.description,
          room_info_privacy:this.publicOrPrivate,
        };
        this.$store.commit("SET_LAUNCH_ROOM_INFO", payload);
      }
      return { success: success, file: this.file }
    },
    async getUrl(event) {
      const files = event.target.files;
      if (files) {
        this.file = files
      }
      const reader = new FileReader()
      reader.onload = (event) => {
       this.readableFile= event.target.result
      }
      reader.readAsDataURL(this.file[0])
    },
    checkRoomData()
    {
      if(this.getActiveLaunchRoomFromRoom && this.current_page)
      {
        const payload={
          id:this.current_page.id?this.current_page.id:'',
          title:this.current_page.title?this.current_page.title:'',
          artwork_url:this.current_page.artwork_url?this.current_page.artwork_url:'',
          content:this.current_page.content?this.current_page.content:'',
          active:this.current_page.visibility?this.current_page.visibility:'',
        };
        this.roomType =payload
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.wr_launch_info {
  span {
    color: #47505b;
    font-family: HelveticaNeueBold;
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
  }
  .launch_name {
    margin-top: 6px;
    div {
      border: 1px solid #e6eaf0;
      border-radius: 4px;
      height: 30px;
      input {
        width: 100%;
        border: none;
        border-radius: 4px;
        height: 100%;
        padding: 0 10px;
      }
    }
  }
  .wr_typs {
    margin-top: 16px;
    .wr_typs_launch {
      &.not-justify {
        justify-content: unset!important;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        width: 170px;
        height: 40px;
        border: 1px solid #e6eaf0;
        border-radius: 2px;
        display: flex;
        align-items: center;
        padding-left: 22px;
        svg {
          margin-right: 10px;
          color: #47505b;
        }
      }
      .active_type {
        border: 1px solid #D1433A;
        border-radius: 2px;
        background: #D1433A;
        color: #ffffff;
        svg {
          path {
            stroke: #ffffff;
          }
        }
      }
    }
  }
  .wr-room {
    margin-top: 16px;
    .wr_dr_down {
      height: 40px;
      display: flex;
      position: relative;
      border: 1px solid #e6eaf0;
      border-radius: 4px;
      align-items: center;
      justify-content: space-between;
      font-family: HelveticaNeueMedium;
      font-size: 14px;
      padding: 0 20px;
      p {
        margin: 0;
        color: #8b949f;
      }
      .wr_img {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        background: #c4c4c4;
        margin-right: 13px;
        img {
          width: 100%;
          border-radius: 4px;
          object-fit: cover;
        }
      }
      .dr_down {
        position: absolute;
        background: #ffffff;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        border-radius: 4px;
        box-shadow: 0px 10px 20px rgba(74, 75, 77, 0.16);
        padding: 10px 0;
        .item_clubsPartyType {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 4px 20px;
          cursor: pointer;
          &:hover {
            background: #D1433A1A;
          }
          span {
            font-family: HelveticaNeueBold;
            font-weight: 700;
            font-size: 13px;
            color: #071526;
            margin: 0;
          }
          p {
            font-family: HelveticaNeueNormal;
            font-weight: 400;
            font-size: 11px;
            color: rgba(7, 21, 38, 0.6);
          }
          .radio {
            border: 1px solid #D1433A;
            div {
              background: #D1433A;
            }
          }
        }
        .wr_search {
          padding: 0 20px;
          div {
            height: 30px;
            width: 100%;
            border: 1px solid #e6eaf0;
            border-radius: 4px;
            margin-bottom: 20px;
            input {
              height: 100%;
              width: 100%;
              border: none;
              outline: none;
              border-radius: 4px;
              padding: 0 20px;
              color: #8b949f;
              font-family: HelveticaNeueNormal;
              font-weight: 400;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
  .wr_des_cover {
    margin-top: 16px;
    display: flex;
    // justify-content: space-between;
    gap: 11px;
    .wr_des {
      .div_textarea {
        width: 330px;
        height: 100px;
        border: 1px solid #e6eaf0;
        border-radius: 4px;
        textarea {
          width: 100%;
          border: none;
          height: 100%;
          border-radius: 4px;
          padding: 5px 10px;
          resize: none;
          color: rgba(7, 21, 38, 0.6);
          font-family: HelveticaNeueNormal;
          font-size: 12px;
        }
      }
    }
    .wr_cover {
      .wr_cover_content {
        width: 170px;
        height: 100px;
        padding: 20px 10px;
        display: flex;
        border: 1px solid #e6eaf0;
        border-radius: 4px;
        background: #f7f8fa;
        position: relative;
        justify-content: center;
        align-items: center;
        .div_img {
          margin-right: 10px;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background: #c4c4c4;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        .file {
          cursor: pointer;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          opacity: 0;
        }
        .upload {
          div {
            display: flex;
            color: #D1433A;
            font-family: HelveticaNeueMedium;
            font-size: 13px;
            svg {
              margin-right: 8px;
            }
          }
          p {
            margin: 12px 0 0 0;
            font-family: HelveticaNeueNormal;
            color: #7e858c;
            font-weight: 400;
            font-size: 11px;
          }
        }
      }
    }
  }
  .wr_public_or_private {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .wr_public,
    .wr_private {
      border: 1px solid #e6eaf0;
      border-radius: 4px;
      width: 260px;
      height: 70px;
      padding: 7px 20px 16px;
      display: flex;
      justify-content: space-between;
      .icon {
        padding-top: 7px;
      }
      .wr_text {
        span {
          color: rgba(7, 21, 38, 0.6);
          font-family: HelveticaNeueMedium;
          font-size: 13px;
          font-weight: 500;
        }
        p {
          width: 137px;
          margin-top: 7px;
          color: rgba(7, 21, 38, 0.6);
          font-size: 10px;
          font-family: HelveticaNeueNormal;
          line-height: 12px;
        }
      }
    }
    .public_Or_Private {
      border: 1px solid #D1433A;
      .icon {
        svg {
          path {
            stroke: #D1433A;
          }
        }
      }
      .wr_text {
        span {
          color: #D1433A;
        }
      }
      .radio {
        border: 1px solid #D1433A;
        div {
          background: #D1433A;
        }
      }
    }
  }
  .radio {
    margin-top: 7px;
    width: 20px;
    height: 20px;
    border: 1px solid #e6eaf0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }
}
</style>
