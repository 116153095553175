<template>
  <div class="event-details-page-container">
    <div class="event-details-page-right-content-section">
      <event-detail :activeNav="activeNav" @setActivNav="changeActivNav" />
      <div class="active-content">
        <about-detail v-if="activeNav === 'about'" />
        <posts-container v-if="activeNav === 'posts'" />
      </div>
    </div>
    <div class="event-details-page-left-content-section">
      <div class="wrap-right-part">
        <host />
      </div>
      <div class="wrap-right-part">
        <audience />
      </div>
      <div class="wrap-right-part">
        <reposts />
      </div>
      <RelatedUpcomingEvents />
    </div>
  </div>
</template>

<script>
import AboutDetail from "../components/Detail/AboutDetail.vue";
import EventDetail from "../components/Detail/EventDetail.vue";
import PostsContainer from "../components/Detail/posts/PostsContainer.vue";
import Host from "../components/Detail/Host.vue";
import Audience from "../components/Detail/Audience.vue";
import Reposts from "../components/Detail/Reposts.vue";
import RelatedUpcomingEvents from "../components/Detail/RelatedUpcomingEvents.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    EventDetail,
    AboutDetail,
    PostsContainer,
    Host,
    Audience,
    Reposts,
    RelatedUpcomingEvents,
  },
  data() {
    return {
      activeNav: "about",
    };
  },
  methods: {
    ...mapActions(["getSingleEventDetails"]),
    changeActivNav(nav) {
      this.activeNav = nav;
    },
  },
  computed: {
    ...mapGetters(["GET_SINGLE_EVENT_DETAILS", "GET_EVENT_POSTS"]),
  },
  async created() {
    try {
      await this.$store.dispatch("loading", true);
      await this.getSingleEventDetails(this.$route.params.id);
      await this.$store.dispatch("getEventPosts", this.$route.params.id);
      await this.$store.dispatch("loading", false);
    } catch (error) {
      console.error('Error in created hook:', error);
      await this.$store.dispatch("loading", false);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/style/events/eventdetailspage.scss";

.active-content {
  width: 770px;
  max-width: 100%;
}
</style>
<style lang="scss">
.titleDetalsRightPart {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  h3 {
    font-family: HelveticaNeueBold;
    color: #071526;
    font-size: 18px;
    opacity: 0.9;
    margin: 0;
  }
  .showAll {
    font-family: HelveticaNeueBold;
    font-size: 12px;
    display: flex;
    color: #071526;
    align-items: center;
    span {
      margin-left: 10px;
      width: 24px;
      height: 24px;
      background: #e4e6eb;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
</style>
