<template>
  <div class="cover-section">
    <div class="cover-section">
      <div class="cover-container">
        <img :src="GET_SINGLE_EVENT_DETAILS.artwork_url" alt="" />
        <div class="cover-content">
          <div class="date">
            <div class="month">
              <span class="box-month">{{ monthOnly(GET_SINGLE_EVENT_DETAILS.started_at) }}</span>
              <span class="box-date">{{ dayOnly(GET_SINGLE_EVENT_DETAILS.started_at) }}</span>
            </div>
            <div class="time-and-title">
              <span class="title">{{ GET_SINGLE_EVENT_DETAILS.title }}</span>
              <span class="time">
                <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.992 0C3.576 0 0 3.584 0 8C0 12.416 3.576 16 7.992 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 7.992 0ZM11.2 11.2C11.126 11.2742 11.0381 11.333 10.9413 11.3731C10.8445 11.4133 10.7408 11.434 10.636 11.434C10.5312 11.434 10.4275 11.4133 10.3307 11.3731C10.2339 11.333 10.146 11.2742 10.072 11.2L7.44 8.568C7.36441 8.49391 7.30427 8.40555 7.26308 8.30805C7.22188 8.21055 7.20044 8.10585 7.2 8V4.8C7.2 4.36 7.56 4 8 4C8.44 4 8.8 4.36 8.8 4.8V7.672L11.2 10.072C11.512 10.384 11.512 10.888 11.2 11.2Z"
                  fill="white"
                />
              </svg>
                <span class="text-uppercase">
                  {{ GET_SINGLE_EVENT_DETAILS.start_time }}
                </span>
                {{ " -  " + monthDayFormat(GET_SINGLE_EVENT_DETAILS.end_at) }}
                <span class="text-uppercase">
                  {{ GET_SINGLE_EVENT_DETAILS.end_time }}
                </span>
              </span>
            </div>
          </div>
          <div class="visitors-counts">
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
                stroke="white"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.01197 10.9998C1.01197 10.9998 0.573983 8.51785 4.99598 8.42285C4.99598 8.42285 7.99595 8.42285 8.12995 8.42285C12.23 8.42285 12.276 10.9998 12.276 10.9998"
                stroke="white"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span>
              {{GET_SINGLE_EVENT_DETAILS.going + GET_SINGLE_EVENT_DETAILS.interested }}
            </span>
          </div>
        </div>
      </div>
      <div class="buttons-and-counts-section">
        <event-info />
        <div class="options">
          <div class="top">
            <div class="buttons">
              <span
                :class="{'active': GET_SINGLE_EVENT_DETAILS.user_interest === 'interested'}"
                @click="handleInterest({event_id: GET_SINGLE_EVENT_DETAILS.id, interest: 'interested'})
              "
              >
                Interested
              </span>
              <span
                class="going"
                :class="{'active': GET_SINGLE_EVENT_DETAILS.user_interest === 'going'}"
                @click="handleInterest({ event_id: GET_SINGLE_EVENT_DETAILS.id, interest: 'going'})"
              >
                Going
              </span>
            </div>
            <div class="settings">
              <button class="share">
                <svg
                  width="18"
                  height="15"
                  viewBox="0 0 18 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 11.619C1 11.619 2.26951 5.80698 9.88122 5.62087C9.99792 4.08255 9.99792 2.53831 9.88122 1L17 7.34856L9.88122 14V9.12244C9.88122 9.12244 5.70755 7.94414 1 11.619Z"
                    fill="#D1433A"
                    stroke="#D1433A"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <div class="artist-header-btns">
                <b-dropdown class="meatball" size="lg" right variant="link" toggle-class="text-decoration-none" no-caret>
                  <template #button-content>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path 
                            d="M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z"
                            fill="#D1433A" stroke="#D1433A" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round">
                      </path>
                      <path 
                            d="M14.25 9.75C14.6642 9.75 15 9.41421 15 9C15 8.58579 14.6642 8.25 14.25 8.25C13.8358 8.25 13.5 8.58579 13.5 9C13.5 9.41421 13.8358 9.75 14.25 9.75Z"
                            fill="#D1433A" stroke="#D1433A" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round">
                      </path>
                      <path 
                            d="M3.75 9.75C4.16421 9.75 4.5 9.41421 4.5 9C4.5 8.58579 4.16421 8.25 3.75 8.25C3.33579 8.25 3 8.58579 3 9C3 9.41421 3.33579 9.75 3.75 9.75Z"
                            fill="#D1433A" stroke="#D1433A" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round">
                      </path>
                    </svg>
                  </template>
                  <div class="meatball-dropdown">
                                        
                    <b-dropdown-item class="dropdown-item cursor-pointer" href="#">
                      <div class="float-left">
                        <span class="icon-meatball">
                          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.0499 1.93548L14.0506 1.93622C15.6507 3.54311 15.648 6.03385 14.0534 7.62769L14.0533 7.6278L8.0002 13.6809L1.94707 7.6278L1.94696 7.62769C0.352116 6.03352 0.350033 3.54277 1.94659 1.93943L1.94768 1.93833C2.32059 1.56229 2.76417 1.26371 3.25291 1.05977C3.74153 0.85588 4.26563 0.750605 4.79508 0.75C5.79429 0.75013 6.75702 1.12554 7.49255 1.80187L8.00013 2.2686L8.50777 1.80194C9.24345 1.12566 10.2062 0.750244 11.2055 0.75C11.7343 0.750455 12.2577 0.855395 12.7458 1.05879C13.234 1.26224 13.6772 1.56018 14.0499 1.93548Z" stroke="#47505B" stroke-width="1.5"/>
                          </svg>
                        </span>
                        <span class="font-meatball">Save Event</span>
                      </div>
                    </b-dropdown-item>
                    <hr>
                    <b-dropdown-item class="dropdown-item cursor-pointer" data-toggle="modal"
                                     data-target="#report-event"
                                     data-backdrop="false" href="#" @click="handleOpenReportModal">
                      <div class="float-left">
                          <span class="icon-meatball">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2.66406 14.6667V10" stroke="#47505B" stroke-width="1.5" stroke-linejoin="round" />
                              <path d="M2.66406 10.0007C2.66406 10.0007 3.33073 9.33398 5.33073 9.33398C7.33073 9.33398 8.66406 10.6673 10.6641 10.6673C12.6641 10.6673 13.3307 10.0007 13.3307 10.0007V2.00065C13.3307 2.00065 12.6641 2.66732 10.6641 2.66732C8.66406 2.66732 7.33073 1.33398 5.33073 1.33398C3.33073 1.33398 2.66406 2.00065 2.66406 2.00065V10.0007Z" stroke="#47505B" stroke-width="1.5" stroke-linejoin="round" />
                            </svg>
                          </span>
                        <span class="font-meatball">Report Event</span>
                      </div>
                    </b-dropdown-item>
                    <hr>
                    <b-dropdown-item class="dropdown-item cursor-pointer" href="#">
                      <div class="float-left">
                          <span class="icon-meatball">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_4537_109238)">
                              <path d="M6.66406 8.66697C6.95036 9.04972 7.31563 9.36642 7.7351 9.5956C8.15456 9.82477 8.6184 9.96105 9.09516 9.99519C9.57192 10.0293 10.0505 9.96055 10.4983 9.79349C10.9461 9.62643 11.3528 9.36502 11.6907 9.02697L13.6907 7.02697C14.2979 6.3983 14.6339 5.55629 14.6263 4.6823C14.6187 3.80831 14.2682 2.97227 13.6501 2.35424C13.0321 1.73621 12.1961 1.38565 11.3221 1.37806C10.4481 1.37046 9.60607 1.70644 8.9774 2.31364L7.83073 3.45364" stroke="#47505B" stroke-width="1.5" stroke-linejoin="round"/>
                              <path d="M9.32956 7.33381C9.04326 6.95106 8.67799 6.63435 8.25853 6.40518C7.83906 6.17601 7.37522 6.03973 6.89846 6.00559C6.4217 5.97144 5.94317 6.04023 5.49533 6.20729C5.04749 6.37435 4.64082 6.63576 4.30289 6.97381L2.30289 8.97381C1.6957 9.60248 1.35972 10.4445 1.36731 11.3185C1.37491 12.1925 1.72547 13.0285 2.3435 13.6465C2.96153 14.2646 3.79757 14.6151 4.67156 14.6227C5.54554 14.6303 6.38755 14.2943 7.01623 13.6871L8.15623 12.5471" stroke="#47505B" stroke-width="1.5" stroke-linejoin="round"/>
                              </g>
                              <defs>
                              <clipPath id="clip0_4537_109238">
                              <rect width="16" height="16" fill="white"/>
                              </clipPath>
                              </defs>
                            </svg>
                          </span>
                        <span class="font-meatball">Open event in new tab</span>
                      </div>
                    </b-dropdown-item>
                  </div>
                </b-dropdown>
              </div>
            </div>
          </div>
          <div class="buy-ticket">
            <router-link
              :to="{
              name: 'buy.Ticket',
              params: { id: GET_SINGLE_EVENT_DETAILS.id },
            }"
            >
              <span>
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 11.3759L1.24704 11.623L3.11533 13.4912L3.36238 13.7383L3.60944 13.4912C4.22635 12.8744 5.21598 12.8744 5.83285 13.4912C6.44976 14.1082 6.44976 15.0978 5.83285 15.7147L5.58579 15.9617L5.83285 16.2088L7.70114 18.0771L7.94818 18.3241L8.19523 18.0771L18.0771 8.19522L18.3241 7.94818L18.0771 7.70113L16.2088 5.83284L15.9618 5.58579L15.7147 5.83284C15.0978 6.44973 14.1082 6.44975 13.4913 5.83284C12.8744 5.21595 12.8744 4.22632 13.4913 3.60942L13.7383 3.36238L13.4913 3.11533L11.623 1.24705L11.3759 1L11.1289 1.24705L1.24704 11.1289L1 11.3759ZM1.98819 11.3759L8.28786 5.07626L9.02899 5.8174L9.5231 5.3233L8.78195 4.58217L11.3759 1.98819L12.7733 3.38554C12.1258 4.27258 12.1974 5.52718 12.9972 6.32693C13.7969 7.12666 15.0477 7.19432 15.9347 6.54696L17.3359 7.94818L14.7419 10.5422L14.0008 9.80103L13.5067 10.2951L14.2479 11.0362L7.94817 17.3359L6.54696 15.9347C7.19432 15.0477 7.12668 13.7969 6.32694 12.9972C5.52718 12.1974 4.27257 12.1258 3.38554 12.7733L1.98819 11.3759ZM10.0326 6.82102L10.5113 7.29967L11.0054 6.80558L10.5267 6.32693L10.0326 6.82102ZM12.0244 8.81283L12.5031 9.2915L12.9972 8.79739L12.5185 8.31874L12.0244 8.81283Z"
                    fill="#D1433A"
                    stroke="#D1433A"
                    stroke-width="0.4"
                  />
                </svg>
                Buy Tickets
              </span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="tabs-section">
        <div
          class="nav-item"
          :class="{ 'active': item.title === activeNav }"
          v-for="item in nav"
          :key="item.id"
          @click="handleActiveNav(item.title)"
        >
          <p>{{ item.title }}</p>
          <span></span>
        </div>
      </div>
    </div>
    <ConfirmReportModal 
      v-if="openReportModal" 
      @closeReportModal="handleCloseReportModal"
      reportableType="Event"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EventInfo from "./EventInfo.vue";
import dateFormats from "@/mixins/dateFormats.js";
import ConfirmReportModal from "../../../Home/NewsFeed/PostItem/ConfirmReportModal.vue";
export default {
  mixins: [dateFormats],
  components: {
    ConfirmReportModal,
    EventInfo,
  },
  props: {
    activeNav: {
      type: String,
      default: () => "about",
    },
  },
  data() {
    return {
      activeBtn: "",
      openReportModal: false,
      nav: [
        {
          id: 1,
          title: "about",
        },
        {
          id: 2,
          title: "posts",
        },
        {
          id: 3,
          title: "media",
        },
        {
          id: 4,
          title: "members",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["getSingleEventDetails", "InterestedGoing"]),
    handleBtn(value) {
      this.activeBtn = value;
    },
    handleActiveNav(nav) {
      this.$emit("setActivNav", nav);
    },
    handleInterest(params) {
      if(params.interest !== this.GET_SINGLE_EVENT_DETAILS.user_interest) {
        this.InterestedGoing(params).then(() => {
          this.getSingleEventDetails(this.$route.params.id);
        }).catch((error) => {
          console.error('Error in handleInterest:', error);
          this.notificationToast(true, "error", "Something went wrong");
        });
      }
    },
    handleOpenReportModal(){
      this.openReportModal = true;
    },
    handleCloseReportModal(){
      this.openReportModal = false;
    },
  },
  computed: {
    ...mapGetters(["GET_SINGLE_EVENT_DETAILS"]),
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/style/events/eventdetailspage.scss";

.settings {
  .artist-header-btns {
    .meatball {
      .meatball-dropdown {
        hr {
          height: 1px;
          background-color: #E6EAF0;
          margin: 7px 0px;
        }
        li {
          
          &:hover {
            background-color: #f3f6ff !important;
            a {
              span {
                color: #D1433A !important;
                svg {
                  path {
                    stroke: #D1433A !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

</style>
