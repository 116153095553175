<template>
  <div class=" col-12" style="padding-left: 0">
    <div class="media-tabs friends_tab">
      <ul id="myTab" role="tablist" class="nav nav-tabs5">
        <li class="nav-item">
          <a id="home-tab" data-toggle="tab" href="#Upcoming" role="tab" aria-controls="home" aria-selected="true" class="nav-link active" @click="getUpcomingEvents">
            UPCOMING
          </a>
        </li>
        <li class="nav-item">
          <a id="profile-tab" data-toggle="tab" href="#Past" role="tab" aria-controls="profile" aria-selected="false" class="nav-link" @click="getPastEvents">
            PAST
          </a>
        </li>
      </ul>
      <SearchEvent :currentActiveEventTab="currentActiveEventTab" />
    </div>
    <div id="myTabContent" class="tab-content">
      <div id="Upcoming" role="tabpanel" aria-labelledby="home-tab" class="tab-pane fade mt_20 show active">
        <EventsCard
          v-for="(event, index) in getCurrentPageUpcomingEvents"
          :key="index"
          :event="event"
        />
        <div v-if="getCurrentPageUpcomingEvents && getCurrentPageUpcomingEvents.length < 1" class="no-content-here-company">
          <div class="no-content-here no-event-here">
            <div class="no-detail-content">
              <div class="category-content-icon">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M47.5 10H12.5C9.73858 10 7.5 12.2386 7.5 15V50C7.5 52.7614 9.73858 55 12.5 55H47.5C50.2614 55 52.5 52.7614 52.5 50V15C52.5 12.2386 50.2614 10 47.5 10Z"
                    stroke="#47505B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M40 5V15" stroke="#47505B" stroke-width="3" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                  <path d="M20 5V15" stroke="#47505B" stroke-width="3" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                  <path d="M7.5 25H52.5" stroke="#47505B" stroke-width="3" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </div>
              <div class="no-detail-content-heading">
                You haven’t set up any Events yet
              </div>
              <div class="no-detail-content-subheading">
                Create your first Event now!
              </div>
              <div class="add-new-product-buttons">
                <button data-toggle="modal"
                        data-target="#create-event-modal"
                        v-if="isAdmin" @click="$store.commit('SET_EVENT_MODAL', true)">
                  <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M4.49941 0C4.77091 0 4.99101 0.220095 4.99101 0.491596V8.5084C4.99101 8.7799 4.77091 9 4.49941 9C4.22791 9 4.00781 8.7799 4.00781 8.5084V0.491596C4.00781 0.220095 4.22791 0 4.49941 0Z"
                          fill="white"></path>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M0 4.4999C0 4.2284 0.220095 4.0083 0.491596 4.0083H8.5084C8.7799 4.0083 9 4.2284 9 4.4999C9 4.7714 8.7799 4.99149 8.5084 4.99149H0.491596C0.220095 4.99149 0 4.7714 0 4.4999Z"
                          fill="white"></path>
                  </svg>
                  ADD NEW EVENT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="Past" role="tabpanel" aria-labelledby="profile-tab" class="tab-pane fade mt_20">
        <EventsCard
          v-for="(event, index) in getCurrentPagePastEvents"
          :key="index"
          :event="event"
        />
        <div v-if="getCurrentPagePastEvents && getCurrentPagePastEvents.length < 1" class="no-content-here-company">
          <div class="no-content-here no-event-here">
            <div class="no-detail-content">
              <div class="category-content-icon">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M47.5 10H12.5C9.73858 10 7.5 12.2386 7.5 15V50C7.5 52.7614 9.73858 55 12.5 55H47.5C50.2614 55 52.5 52.7614 52.5 50V15C52.5 12.2386 50.2614 10 47.5 10Z"
                    stroke="#47505B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M40 5V15" stroke="#47505B" stroke-width="3" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                  <path d="M20 5V15" stroke="#47505B" stroke-width="3" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                  <path d="M7.5 25H52.5" stroke="#47505B" stroke-width="3" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </div>
              <div class="no-detail-content-heading">
                You haven’t set up any Events yet
              </div>
              <div class="no-detail-content-subheading">
                Create your first Event now!
              </div>
              <div class="add-new-product-buttons">
                <button data-toggle="modal"
                        data-target="#create-event-modal"
                        v-if="isAdmin" @click="$store.commit('SET_EVENT_MODAL', true)">
                  <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M4.49941 0C4.77091 0 4.99101 0.220095 4.99101 0.491596V8.5084C4.99101 8.7799 4.77091 9 4.49941 9C4.22791 9 4.00781 8.7799 4.00781 8.5084V0.491596C4.00781 0.220095 4.22791 0 4.49941 0Z"
                          fill="white"></path>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M0 4.4999C0 4.2284 0.220095 4.0083 0.491596 4.0083H8.5084C8.7799 4.0083 9 4.2284 9 4.4999C9 4.7714 8.7799 4.99149 8.5084 4.99149H0.491596C0.220095 4.99149 0 4.7714 0 4.4999Z"
                          fill="white"></path>
                  </svg>
                  ADD NEW EVENT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import EventsCard from "@/components/Events/components/EventsCard";
import SearchEvent from "@/components/ClubManager/Club/SearchEvent.vue";

export default {
  data(){
    return{
      currentActiveEventTab:'upcoming',
      eventsSearchKey:''
    }
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  components: {
    EventsCard,
    SearchEvent
  },
  computed:{
    getCurrentPageUpcomingEvents() {
      return this.$store.getters.getCurrentPageUpcomingEvents;
    },
    getCurrentPagePastEvents() {
      return this.$store.getters.getCurrentPagePastEvents
    },
  },
  methods:{
    searchEvent(value){
      this.eventsSearchKey = value;
    },
    getUpcomingEvents(){
      this.eventsSearchKey = '';
      this.currentActiveEventTab = 'upcoming'
      // let payload = {
      //   showMore : false,
      //   page_id: this.$route.params.id,
      //   artistFollowersSearchKey: null,
      //   search_type: this.currentActiveFollowerTab,
      //   name: null,
      // }
      // this.$store.dispatch('getArtistPageFollowers', payload)
    },
    getPastEvents(){
      this.eventsSearchKey = '';
      this.currentActiveEventTab = 'past'
      // let payload = {
      //   showMore : false,
      //   page_id: this.$route.params.id,
      //   artistFollowersSearchKey: null,
      //   search_type: this.currentActiveFollowerTab,
      //   name: null,
      // }
      // this.$store.dispatch('getArtistPageFollowings', payload)
    }
  }
}
</script>

<style>
/* .center_centerbar_wraper_home {
  flex: 1;
  min-width: 770px !important;
} */
</style>
