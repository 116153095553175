<script>
import { capitalizeFirstLetter } from "../../../../mixins/common";

export default {
  name: "ExploreFriendCard",
  props: {
    friend: {
      type: Object,
      required: true
    }
  },
  methods: {
    capitalizeFirstLetter,
    addFriend() {
      this.$emit('addFriend', this.friend);
    },
    removeFriend() {
      this.$emit('removeFriend', this.friend);
    }
  }
};
</script>

<template>
  <div class="friends-card">
    <div class="friends-card-avatar-and-name-section">
      <img class="friends-card-avatar" :src="friend.artwork_url" alt=""/>
      <div class="friends-card-name-and-location-section">
        <span class="friends-card-name">{{ capitalizeFirstLetter(friend.name) }}</span>
        <div v-if="friend.mututal_friends === 0 && friend.current_city" class="friends-card-location-section">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6 11.5C6 11.5 10.5 8.5 10.5 5C10.5 3.80653 10.0259 2.66193 9.18198 1.81802C8.33807 0.974106 7.19347 0.5 6 0.5C4.80653 0.5 3.66193 0.974106 2.81802 1.81802C1.97411 2.66193 1.5 3.80653 1.5 5C1.5 8.5 6 11.5 6 11.5ZM7.5 5C7.5 5.82843 6.82843 6.5 6 6.5C5.17157 6.5 4.5 5.82843 4.5 5C4.5 4.17157 5.17157 3.5 6 3.5C6.82843 3.5 7.5 4.17157 7.5 5Z" fill="#47505B"/>
          </svg>
          <span class="friends-card-location">
              {{ 'Lives in ' + friend.current_city + (friend && friend.country.length > 0 ? ', ' + friend.country[0].name : '')}}
            </span>
        </div>
        <div v-if="friend.mututal_friends !== 0" class="friends-card-mutual-section">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.25 10.5C5.25 10.5 4.5 10.5 4.5 9.75C4.5 9 5.25 6.75 8.25 6.75C11.25 6.75 12 9 12 9.75C12 10.5 11.25 10.5 11.25 10.5H5.25ZM8.25 6C8.84674 6 9.41903 5.76295 9.84099 5.34099C10.2629 4.91903 10.5 4.34674 10.5 3.75C10.5 3.15326 10.2629 2.58097 9.84099 2.15901C9.41903 1.73705 8.84674 1.5 8.25 1.5C7.65326 1.5 7.08097 1.73705 6.65901 2.15901C6.23705 2.58097 6 3.15326 6 3.75C6 4.34674 6.23705 4.91903 6.65901 5.34099C7.08097 5.76295 7.65326 6 8.25 6Z" fill="#47505B"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.912 10.5003C3.80082 10.2662 3.74537 10.0095 3.75 9.75032C3.75 8.73407 4.26 7.68782 5.202 6.96032C4.73182 6.81545 4.24196 6.7446 3.75 6.75032C0.75 6.75032 0 9.00032 0 9.75032C0 10.5003 0.75 10.5003 0.75 10.5003H3.912Z" fill="#47505B"/>
            <path d="M3.375 6C3.87228 6 4.34919 5.80246 4.70083 5.45083C5.05246 5.09919 5.25 4.62228 5.25 4.125C5.25 3.62772 5.05246 3.15081 4.70083 2.79917C4.34919 2.44754 3.87228 2.25 3.375 2.25C2.87772 2.25 2.40081 2.44754 2.04917 2.79917C1.69754 3.15081 1.5 3.62772 1.5 4.125C1.5 4.62228 1.69754 5.09919 2.04917 5.45083C2.40081 5.80246 2.87772 6 3.375 6Z" fill="#47505B"/>
          </svg>
          <span class="friends-card-mutual">
              {{ friend.mututal_friends }} Mutual Friends
            </span>
        </div>
      </div>
    </div>
    <div class="friends-card-buttons-section">
      <div class="friends-card-add-btn" @click="addFriend">
          <Span>
            Add Friend
          </Span>
      </div>
      <div class="friends-card-remove-btn" @click="removeFriend">
          <Span>
            Remove
          </Span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../../../assets/style/pages/friends/explorefriendcard.scss";
</style>