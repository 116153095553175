<template>
  <div class="engage_settings">
    <div class="engage_settings_header">
      <div>
        <h3 class="btn_visibility_header">Button Visibility: {{ watch_now_settings.visibility? 'Published': 'Hidden' }}</h3>
        <h6 class="btn_visibility_text">To publish, turn the switcher on.</h6>
      </div>
      <div class="switch_sm_wrapper">
        <input type="checkbox" v-model="watch_now_settings.visibility" id="switch28"/>
        <label class="switch_sm" for="switch28">
          <div class="switch_toggler"></div>
        </label>
      </div>
    </div>
    <div class="engage_settings_body">
      <div class="engage_set_title engage_set_title_bold">
        <h4 class="header">What do you want user to watch?</h4>
        <p class="text">Add link from any platform you want (YouTube/Vimeo/etc)</p>
      </div>

      <div class="engage_fields_group">
        <div class="row">
          <div class="col-12">
            <div class="engage_field mb-0">
              <label class="engage_fields_label action_btn_label">
                Video Link
                <span class="color_red">*</span>
              </label>
              <input 
                type="url" 
                v-model="watch_now_settings.video_link_url" 
                class="form_control engage-vedio-link-input" 
                :class="{'red-error-border': activeRedBorder && !watch_now_settings.video_link_url }"
              >
              <small class="text_muted action_btn_placeholder">Link must start with http:// or https://</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="engage_settings_footer">
      <h4>Your Button preview:</h4>
      <button type="button">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M8.00065 14.6663C11.6825 14.6663 14.6673 11.6816 14.6673 7.99967C14.6673 4.31778 11.6825 1.33301 8.00065 1.33301C4.31875 1.33301 1.33398 4.31778 1.33398 7.99967C1.33398 11.6816 4.31875 14.6663 8.00065 14.6663Z"
              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6.66602 5.33301L10.666 7.99967L6.66602 10.6663V5.33301Z" stroke="white" stroke-width="1.5"
                stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Watch Now
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      watch_now_settings: {
        visibility: false,
        video_link_url: null
      }
    }
  },
  props: {
    watchNowSettings: {
      type: Object,
      default: ()=> {}
    },
    activeRedBorder: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    'watch_now_settings.visibility'() {
      this.$emit('getWatchNowData', this.watch_now_settings)
    },
    'watch_now_settings.video_link_url'() {
      this.$emit('getWatchNowData', this.watch_now_settings)
    },
    watchNowSettings() {
      this.setWatchNowSettings()
    }
  },
  mounted() {
    this.setWatchNowSettings()
  },
  methods: {
    setWatchNowSettings() {
      this.watch_now_settings = this.watchNowSettings
    },
  }
}
</script>
<style scoped lang="scss">
.red-error-border {
  border: 1px solid #D1433A;
}
</style>