<template>
  <div :class="['main-div',(receiver) ?'receiver-music' :'',(fullChat) ?'full-chat' :'',(display==='sender')?'sender-music-border':'',(display==='receiver')?'receiver-music-border':'']">
    <div class="row">

      <div class="d-flex align-items-center justify-content-evenly">
        <div class="d-flex w-95 seek-wrapper tile-music-style justify-content-start">
          <TileViewWavesPlayer
            :key="index+'_'+id"
            :ref="'songPlayerRef'+index+'_'+id"
            :song-loaded="songLoaded"
            :is-song-playing.sync="isSongPlaying"
            :music="url"
            :latest_list_id="id"
            @updateIcon="updateIcon"
            :fullWaves="receiver"
            :fullChatView="fullChat"
          />
        </div>
        <div class="d-flex w-5 justify-content-end playIconn"
             :class="[(icon && last_play_song_index === index+'_'+id) ? 'active' : '']"
             @click="playPauseSong(index+'_'+id,id)"
        >
          <div class="svg-icon">

            <svg v-if="icon && last_play_song_index === index+'_'+id" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="17" cy="17" r="17" fill="#0075FF" fill-opacity="0.2"/>
              <rect x="2" y="2" width="30" height="30" rx="15" fill="#D1433A"/>
              <rect x="13" y="12" width="1" height="10" rx="0.5" fill="white" stroke="white"/>
              <rect x="20" y="12" width="1" height="10" rx="0.5" fill="white" stroke="white"/>
            </svg>

            <svg class="play-hover" v-else width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="30" height="30" rx="15" fill="#D1433A"/>
              <path d="M11.333 10.8317C11.333 10.0405 12.2083 9.56266 12.8738 9.99049L19.3578 14.1588C19.9702 14.5525 19.9702 15.4475 19.3578 15.8412L12.8738 20.0095C12.2083 20.4373 11.333 19.9595 11.333 19.1683V10.8317Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import TileViewWavesPlayer from "@/components/DesignGuide/Music/Components/TileViewWavesPlayer.vue";

export default {
  name: "ChatMusic",
  data() {
    return {
      spinner: false,
      wavesurfer: null,
      songLoaded: true,
      isSongPlaying: false,
      icon: false,
      last_play_song_index: null,
      currentPlayingIndex: null

    };
  },
  props: {
    id: {
      type: String,
      default: null
    },
    url: {
      type: String,
      default: null
    },
    index: {
      type: Number,
      default: null
    },
    receiver: {
      type: Boolean,
      default: false
    },
    fullChat: {
      type: Boolean,
      default: false
    },
    display: {
      type: String,
      default: null
    },
    textCheck: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TileViewWavesPlayer
  },
  computed: {
    currentPlayId() {
      return this.$store.getters.getCurrentPlayId;
    }
  },
  methods: {
    async playPauseSong(Index, id) {
      this.$store.commit("SET_CURRENT_PLAYER", "waveSurferPlayer");
      if (this.last_play_song_index !== null && this.last_play_song_index !== Index) {
        await this.pauseSong(this.last_play_song_index);
      }
      await this.PlaySong(Index, id);

    },
    changeIcon() {
      this.icon = false;
    },
    async PlaySong(Index, id) {
      this.last_play_song_index = Index;
      this.icon = !this.icon;
      if (this.icon === true) {
        if ((this.currentPlayId !== id)) {

          this.$store.commit("SET_CURRENT_PLAY_ID", id);
        }
      }
      await this.$refs["songPlayerRef" + Index].playPauseSong();
      this.isSongPlaying = this.$refs["songPlayerRef" + Index].wavesurfer.isPlaying();
    },
    async pauseSong(Index) {
      this.icon = false;
      await this.$refs["songPlayerRef" + Index][0].pauseSong();
    },
    updateIcon(val) {
      this.icon = val;
    }


  }
};
</script>

<style scoped lang="scss">
.main-div {
  padding: 0.8rem;
  background: #ECF0FF;
  border-radius: 10px;
  margin-bottom: 0.7rem;
  width: 250px;

  .seek-wrapper {
    margin-right: 35px;
    margin-right: 20px;
  }

}

.receiver-music {
  width: inherit !important;
  margin-left: 0.5rem;
}
.full-chat {
  width: 540px !important;
  height: 70px !important;
}
.sender-music-border{
  border-radius: 10px 20px 0px 10px !important;
}
.receiver-music-border{
  border-radius: 20px 10px 10px 0px !important;
}

.svg-icon {
  cursor: pointer;
  &:hover {
    .play-hover {
      rect {
        // fill: #DA6961;
        fill: #5C81FF;
      }
      fill: #FFFFFF;
    }
  }
}
</style>
