<template>
  <div class="row" style="background-color: white">
    <div class="col-sm-9" style="max-width: 80.45% !important;
    flex: 80.45% !important;">
      <div class="row pt-4 px-3 setting-form">
        <div class="col-sm-12 pl-1" >
          <h5 class="song-heading">Permissions</h5>
        </div>
        <div class="col-sm-12 pl-1">
          <h2 class="acces-h" >Access</h2>
        </div>
        <div class="pl-1">
          <hr class="m-0 hr-border-style">
        </div>
        <div class="col-sm-6 pl-0 pt-3">
          <div  class="event-filter-widget schedule-license-check-box with-app pg-metadata-song-checkbox-card">
            <div  class="widget-item px-0">
              <div  class="custom-control custom-checkbox">
                <div class="">
                  <input
                    type="checkbox"
                    id="direct_downloads"
                    v-model="song_permissions[ENABLE_DIRECT_DOWNLOAD]"
                    name="direct_downloads"
                    class="custom-control-input"
                    :checked="song_permissions[ENABLE_DIRECT_DOWNLOAD] != song_permissions[ENABLE_DIRECT_DOWNLOAD]"
                  />
                  <label for="direct_downloads" class="custom-control-label check">Enable Direct Downloads</label>
                </div>
                <div>
                  <span class="licence-p">This track will not be available for direct download in the original format it was uploaded.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div  class="col-sm-6 px-0 pt-3">
          <div  class="event-filter-widget schedule-license-check-box with-app pg-metadata-song-checkbox-card">
            <div  class="event-filter-widget schedule-license-check-box with-app pg-metadata-song-checkbox-card">
              <div  class="widget-item px-0">
                <div  class="custom-control custom-checkbox">
                  <div class="">
                    <input
                      type="checkbox"
                      id="offline_listening"
                      v-model="song_permissions[ENABLE_OFFLINE_LISTING]"
                      name="offline_listening"
                      class="custom-control-input"
                      :checked="song_permissions[ENABLE_OFFLINE_LISTING] != song_permissions[ENABLE_OFFLINE_LISTING]"
                    />
                    <label for="offline_listening" class="custom-control-label check">Offline Listening</label>
                  </div>
                  <div>
                    <span class="licence-p">This track can be played on devices without an internet connection. </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 px-0 pt-3">
          <div class="event-filter-widget schedule-license-check-box with-app pg-metadata-song-checkbox-card">
            <div  class="widget-item px-0">
              <div  class="custom-control custom-checkbox">
                <div class="">
                  <input
                    type="checkbox"
                    id="rss_feed"
                    v-model="song_permissions[INCLUDE_RES]"
                    name="rss_feed"
                    class="custom-control-input"
                    :checked="song_permissions[INCLUDE_RES] != song_permissions[INCLUDE_RES]"
                  />
                  <label for="rss_feed" class="custom-control-label check">Include in RSS feed</label>
                </div>
                <div>
                  <span class="licence-p">This track will not be included in your RSS feed. </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 px-0 pt-3">
          <div class="event-filter-widget schedule-license-check-box with-app pg-metadata-song-checkbox-card">
            <div class="widget-item px-0">
              <div  class="custom-control custom-checkbox">
                <div class="">
                  <input
                    type="checkbox"
                    id="display_embed_code"
                    v-model="song_permissions[DISPLAY_EMBED_CODE]"
                    name="display_embed_code"
                    class="custom-control-input"
                    :checked="song_permissions[DISPLAY_EMBED_CODE] != song_permissions[DISPLAY_EMBED_CODE]"
                  />
                  <label for="display_embed_code" class="custom-control-label check">Display Embed Code</label>
                </div>
                <div>
                  <span class="licence-p">This track's embedded-layer code will be displayed publicly. </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 px-0 pt-3">
          <div class="event-filter-widget schedule-license-check-box with-app pg-metadata-song-checkbox-card">
            <div class="widget-item px-0">
              <div  class="custom-control custom-checkbox">
                <div class="">
                  <input
                    type="checkbox"
                    id="enable_app_playback"
                    v-model="song_permissions[ENABLE_APP_PLAYBACK]"
                    name="enable_app_playback"
                    class="custom-control-input"
                    :checked="song_permissions[ENABLE_APP_PLAYBACK] != song_permissions[ENABLE_APP_PLAYBACK]"
                  />
                  <label for="enable_app_playback" class="custom-control-label check">Enable App Playback</label>
                </div>
                <div>
                  <span class="licence-p">This track will be playable outside off SoundCloud and its apps.  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 pl-1 pt-3">
          <h2 class="acces-h" >Quiet Mode</h2>
        </div>
        <div class="pl-1">
          <hr class="m-0 hr-border-style">
        </div>
        <div class="col-sm-6 px-0 pt-3">
          <div class="event-filter-widget schedule-license-check-box with-app pg-metadata-song-checkbox-card">
            <div class="widget-item px-0">
              <div  class="custom-control custom-checkbox">
                <div class="">
                  <input
                    type="checkbox"
                    id="enable_comments"
                    v-model="song_permissions[ENABLE_COMMENT]"
                    name="enable_comments"
                    class="custom-control-input"
                    :checked="song_permissions[ENABLE_COMMENT] != song_permissions[ENABLE_COMMENT]"
                  />
                  <label for="enable_comments" class="custom-control-label check">Enable Comments</label>
                </div>
                <div>
                  <span class="licence-p">People will be able to comment on your track.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 px-0 pt-3">
          <div class="event-filter-widget schedule-license-check-box with-app pg-metadata-song-checkbox-card">
            <div class="widget-item px-0">
              <div  class="custom-control custom-checkbox">
                <div class="">
                  <input
                    type="checkbox"
                    id="display_comments"
                    v-model="song_permissions[DISPLAY_COMMENT]"
                    name="display_comments"
                    class="custom-control-input"
                    :checked="song_permissions[DISPLAY_COMMENT] != song_permissions[DISPLAY_COMMENT]"
                  />
                  <label for="display_comments" class="custom-control-label check">Display Comments</label>
                </div>
                <div>
                  <span class="licence-p">Comments are visible to everyone.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 px-0 pt-3">
          <div class="event-filter-widget schedule-license-check-box with-app pg-metadata-song-checkbox-card">
            <div class="widget-item px-0">
              <div  class="custom-control custom-checkbox">
                <div class="">
                  <input
                    type="checkbox"
                    id="display_stat"
                    v-model="song_permissions[DISPLAY_STATS]"
                    name="display_stat"
                    class="custom-control-input"
                    :checked="song_permissions[DISPLAY_STATS] != song_permissions[DISPLAY_STATS]"
                  />
                  <label for="display_stat" class="custom-control-label check">Display Stats</label>
                </div>
                <div>
                  <span class="licence-p">This track's stats will be displayed publicly.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div data-v-58c6911e="" data-v-66e59ad3="" class="col-sm-12 pl-0 pb-4 pt-2 pr-2 mt-2">
          <div data-v-58c6911e="" data-v-66e59ad3="" class=" cancel-btn-display">
            <button @click="previousStep" data-v-58c6911e="" data-v-66e59ad3="" class="upload-file-cancel-btn">CANCEL
            </button>
          </div>
          <div
            data-v-58c6911e=""
            data-v-66e59ad3=""
            style="float: right;">
            <div
              data-v-58c6911e=""
              data-v-66e59ad3=""
              class=" save-btn-display">
              <button
                data-v-58c6911e=""
                data-v-66e59ad3=""
                class="upload-file-save-btn">
                SAVE TO DRAFT
              </button>
            </div>
            <div
              data-v-58c6911e=""
              data-v-66e59ad3=""
              class="upload-btn-display">
              <button
                  @click="saveDataInStore"
                data-v-58c6911e=""
                data-v-66e59ad3=""
                class="upload-file-upload-btn">
                                <span data-v-58c6911e="" data-v-66e59ad3=""
                                >SAVE & SEND</span><svg
                data-v-58c6911e=""
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="basic-info-upload-btn">
                <path
                  data-v-58c6911e=""
                  d="M0.773189 13.5C0.848551 13.5 0.923913 13.4589 0.961595 13.4178L6.91522 7.21067C7.02826 7.08735 7.02826 6.92292 6.91522 6.7996L0.961596 0.59249C0.848552 0.469169 0.697828 0.469169 0.584784 0.59249C0.47174 0.71581 0.47174 0.880237 0.584784 1.00356L6.31232 7.00514L0.584783 13.0067C0.471739 13.1301 0.471739 13.2945 0.584783 13.4178C0.622464 13.5 0.697826 13.5 0.773189 13.5Z"
                  fill="white"
                  stroke="white"
                  stroke-width="0.5"
                ></path>
              </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-3 upload-file-p-padding"  style="max-width: 19.55%">
      <div class="row" style="padding-top: 28px;">
        <div class="col-sm-12">
          <h5 class="song-heading-1">Permissions</h5>
        </div>
        <div class="col-sm-12">
          <div>
            <p class="song-p">
              Set your file access and comments mode here
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import {
  ALL_RIGHTS_RESERVED,
  COMPOSER, COPYRIGHT, CREATE_ISRC, CREATION_COMMON,
  DISPLAY_COMMENT,
  DISPLAY_EMBED_CODE, DISPLAY_STATS, ENABLE_APP_PLAYBACK, ENABLE_COMMENT,
  ENABLE_DIRECT_DOWNLOAD,
  ENABLE_OFFLINE_LISTING, EXPLICIT_CONTENT, FEATURE_ARTIST,
  INCLUDE_RES, ISRC, METADATA_LANGUAGE, PRIMARY_ARTIST, PRODUCER, PUBLISHER, RE_MIXER, RECORD_LABEL, RELEASED_AT
} from "../../../../store/types";
import Vue from "vue";
import {required} from "vuelidate/lib/validators";

  export default {

    data(){
      return{
        ENABLE_DIRECT_DOWNLOAD,ENABLE_OFFLINE_LISTING,INCLUDE_RES,DISPLAY_EMBED_CODE,ENABLE_APP_PLAYBACK,ENABLE_COMMENT,DISPLAY_COMMENT,DISPLAY_STATS,
        song_permissions:{
          [ENABLE_DIRECT_DOWNLOAD]: false,
          [ENABLE_OFFLINE_LISTING]: false,
          [INCLUDE_RES]: false,
          [DISPLAY_EMBED_CODE]: false,
          [ENABLE_APP_PLAYBACK]: false,
          [ENABLE_COMMENT]: false,
          [DISPLAY_COMMENT]: false,
          [DISPLAY_STATS]: false,
        }
      }
    },
    props:{
      type:{
        type:String,
        default:'song'
      },
      actionType: {
        type: String,
        default: 'createSongAlbum'
      },
      currentType: {
        type: String,
        default: null
      },
    },
    validations: {
      song_permissions:{
        [ENABLE_DIRECT_DOWNLOAD]: {},
        [ENABLE_OFFLINE_LISTING]: {},
        [INCLUDE_RES]: {},
        [DISPLAY_EMBED_CODE]: {},
        [ENABLE_APP_PLAYBACK]: {},
        [ENABLE_COMMENT]: {},
        [DISPLAY_COMMENT]: {},
        [DISPLAY_STATS]: {},
      }
    },
    computed:{
      pageId(){
        if(this.$route.params.id){
          return this.$route.params.id
        }
        return (this.$route.query  && this.$route.query.page_id)?this.$route.query.page_id:null
      }
    },
    methods:{
      validateState(name) {
        const {$dirty, $error} = this.$v.song_permissions[name];
        return $dirty ? !$error : null;
      },
      nextStep(){
        this.$emit('nextStep')
      },
      previousStep(){
        this.$emit('previousStep')
      },
    async saveDataInStore(){
        this.$v.song_permissions.$touch();
        if (this.$v.song_permissions.$anyError)
        {
          return;
        }
        else
        {
          try {
            let res
            if(this.type ==='song' || this.type === 'podcast'){
              this.$store.commit('SET_SONG_STEP_6_DATA',this.song_permissions)
               res = await this.$store.dispatch('uploadSongDetail', this.type)
            } else if(this.type ==='sample' || this.type === 'sample_pack'){
              this.$store.commit('SET_SAMPLE_STEP_6_DATA',this.song_permissions)
               res = await this.$store.dispatch('uploadSampleDetail', this.currentType)
            }
            else {
              this.$store.commit('SET_ALBUM_STEP_6_DATA',this.song_permissions)
               res = await this.$store.dispatch('uploadAlbumDetail')
            }
            const {data} = res
            if(data && data.message){
              if(data.success === 1){
                //await this.makeToast('success', `${this.type==='song'?'Song':'Album'}' Upload'`, data.message)
                await this.notificationToast(true, `${this.type.charAt(0).toUpperCase() + this.type.substring(1)} Upload`, data.message, 5000, 'success')
                await this.$router.push('/page-manager/page/artist/' + this.pageId)
              }else {
                //await this.makeToast('error', `${this.type==='song'?'Song':'Album'}' Upload'`, data.message)
                await this.notificationToast(true, `${this.type.charAt(0).toUpperCase() + this.type.substring(1)} Upload`, data.message, 5000, 'error')
              }
            }
          }catch (e) {
            console.error(e ,'error')
            await this.notificationToast(true, `${this.type.charAt(0).toUpperCase() + this.type.substring(1)} Upload`, e.message, 5000, 'error')
          }

        }

      }
    }
  }
</script>
<style scoped>
.song-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #071526;
  opacity: 0.9;
  letter-spacing: 0.03em;
  margin-bottom: 16px;
}
.song-p {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: #47505B;
}
.upload-file-p-padding{
  padding: 0 19px 0 21px;
  background-color: #F9FAFB;
}
.acces-h{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #071526;
  opacity: 0.9;
}

.schedule-license-check-box .custom-control .custom-control-input {
  width: 30px;
  height: 30px;
}
.schedule-license-check-box .custom-control.custom-checkbox .custom-control-label:before {
  width: 30px;
  height: 30px;
}
.schedule-license-check-box .custom-control-label::after, .custom-control-label::before {

  width: 30px;
  height: 30px;
}

.schedule-license-check-box .custom-control .custom-control-label:before {
  top: 3px!important;
  left: -29px!important;
}
.schedule-license-check-box .custom-control{
  display: inline-block;
}

.custom-control-label span svg{
  display: inline-block !important;
}
.schedule-license-check-box .custom-control .custom-control-label{
  padding-left: 13px;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  color: #59626B;

}
.licence-p{
  padding-left: 13px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  float: left;
  line-height: 13px;
  color: #59626B;

}

.schedule-license-check-box .custom-control .custom-control-input .custom-control-input:checked~.custom-control-label:before {
  color: #fff;
  border-color: #D1433A;
  background-color: #D1433A;
}
.pg-metadata-song-checkbox-card{
  border: none;
}
.schedule-license-check-box .widget-item{
  padding: 0;
}
.upload-file-save-btn {
  align-items: center;
  justify-content: center;
  font-weight: 700;
  transition: 0.3s all;
  border: 1px solid #D1433A !important;
  background-color: #ffffff;
  color: #D1433A;
  padding: 8px 12px;
  font-size: 13px;
  border: none;
  font-family: "HelveticaNeueMedium";
  width: 100%;
  border-radius: 4px;
  letter-spacing: 0.05em;
}
.upload-file-upload-btn {
  align-items: center;
  justify-content: center;
  font-weight: 700;
  transition: 0.3s all;
  background-color: #D1433A;
  color: #fff;
  padding: 10px;
  font-size: 13px;
  border: none;
  font-family: "HelveticaNeueMedium";
  width: 100%;
  border-radius: 4px;
  letter-spacing: 0.05em;
}
.cancel-btn-display {
  display: inline-block;
  width: 130px;
}
.save-btn-display {
  display: inline-block;
  width: 150px;
  padding-right: 10px;
}
.upload-btn-display {
  display: inline-block;
  width: 130px;
}

.pg-song-artist-checkbox-card{
  border: none !important;
}


.pg-song-artist-checkbox-card .custom-control {
  width: 100px;
  margin-left: 21px;
  display: inline-block;
}

.pg-song-artist-checkbox-card .widget-item{
  display: inline-block !important;
  padding: 0;
}
.pg-song-artist-checkbox-card .pg-item-title{
  display: inline-block;
  position: relative;
  top: -3px;
  width: auto;
  color: #071526;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;

}
.upload-btn-margin{
  margin-left: 16px;
}
.upload-file-p-padding{
  padding: 0 19px 0 21px;
  background-color: #F9FAFB;
}

.upload-file-p-position{
  position: absolute;
  bottom: 0;
}
.Choose-File-Format-bottom-p{
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #8B949F;
}
.upload-file-cancel-btn {
  align-items: center;
  justify-content: center;
  font-weight: 700;
  transition: 0.3s all;
  background: #e6eaf0;
  color: #47505b;
  padding: 10px;
  font-size: 13px;
  border: none;
  font-family: "HelveticaNeueMedium";
  width: 100%;
  border-radius: 4px;
  letter-spacing: 0.05em;
}
.basic-info-upload-btn {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: -1px;
}
.event-filter-widget .custom-control .custom-control-label:after, .event-filter-widget .custom-control .custom-control-label:before{
  top: 3px!important;
  left: -29px!important;
}
.hr-border-style{
  border: 1px solid #E6EAF0 !important;
}
.song-heading-1{
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: #071526;
}

.check {
  font-family: unset!important;
  font-style: normal!important;
  font-weight: 500!important;
  font-size: 13px!important;
  line-height: 16px!important;
  color: #071526!important;
  opacity: 0.9!important;
}
</style>
