<template>
  <div class="w-100 fixEventDetailsPage">
    <MusicSubHeader />
    <div class="container_xxl">
      <div class="row mt_10">
        <div class="col">
          <div class="page-filter d-flex d-flex flex-row align-items-center">
            <div class="back-link" @click="goBack">
              <a href="javascript:;" class="d-flex flex-row align-items-center">
                <div class="svg-icon">
                  <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="c-line-1" d="M5.8645 12.7285L0.135502 7.00052L5.8645 1.27152" stroke="#071526"
                      stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
                PROFILE
              </a>
            </div>
            <div class="filter-title ff_lato">
              BOOK ARTIST
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-md-8">
          <div class="widget_form">
            <div class="widget_form_title">
              Event Details
            </div>
            <div class="row">
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Event Name</label>
                  <input type="email" class="form_control" v-model="bookArtist.events.eventName">
                </div>
              </div>
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Promoter Name<span class="color_red">*</span></label>
                  <input type="email" class="form_control" v-model="bookArtist.events.promotorName" :class="{ 'error-border': $v.bookArtist.events.promotorName.$error }" 
                  >
                </div>
              </div>
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Promoter website</label>
                  <input type="email" class="form_control" v-model="bookArtist.events.promotorWebsite">
                </div>
              </div>
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Ticket Price</label>
                  <input type="email" class="form_control" v-model="bookArtist.events.ticketPrice">
                </div>
              </div>
            </div>
          </div>
          <div class="widget_form">
            <div class="widget_form_title">
              Venue / Club / Location
            </div>
            <div class="row">
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Venue Name<span class="color_red">*</span></label>
                  <input type="text" class="form_control" v-model="bookArtist.location.venueName" :class="{ 'error-border': $v.bookArtist.location.venueName.$error }" >
                </div>
              </div>
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Website</label>
                  <input type="text" class="form_control" v-model="bookArtist.location.website">
                </div>
              </div>

              <div class="col-6">
                <label class="engage_fields_label">Type of Event</label>
                  <div class="event-svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                      <path d="M15 23C19.4183 23 23 19.4183 23 15C23 10.5817 19.4183 7 15 7C10.5817 7 7 10.5817 7 15C7 19.4183 10.5817 23 15 23Z" stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M7 15H23" stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M14.9988 7C16.9999 9.19068 18.137 12.0336 18.1988 15C18.137 17.9664 16.9999 20.8093 14.9988 23C12.9978 20.8093 11.8606 17.9664 11.7988 15C11.8606 12.0336 12.9978 9.19068 14.9988 7V7Z" stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <div class="select_box">
                    <SelectDropdown
                      :options="eventTypes"
                      :style="'width: 340px; border: 1px solid #E6EAF0; padding-left: 1.5rem'"
                      placeholder='Choose event type from list'
                      hide-cross-icon
                      v-model="bookArtist.location.eventType"
                      @change="((value) => bookArtist.location.eventType = value)"
                      :class="{ 'error-border': $v.bookArtist.location.eventType.$error }"
                    />
                  </div>
                  <div class="dropdown-svg-icon">
                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1L5 5L9 1" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
              </div>
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Capacity (pax)</label>
                  <input type="number" min="0" class="form_control" v-model="bookArtist.location.capacity" :class="{ 'error-border': $v.bookArtist.location.capacity.$error }" >
                </div>
              </div>
              <div class="col-12">
                <div class="divider_line mb_17px mt_8px"></div>
              </div>
              <div class="col-12">
                <div class="widget_field">
                  <label class="engage_fields_label">Address Line 1<span class="color_red">*</span></label>
                  <input type="text" class="form_control" v-model="bookArtist.location.addressLine1" :class="{ 'error-border': $v.bookArtist.location.addressLine1.$error }" >
                </div>
              </div>
              <div class="col-12">
                <div class="widget_field">
                  <label class="engage_fields_label">Address Line 2<span class="color_red">*</span></label>
                  <input type="text" class="form_control" v-model="bookArtist.location.addressLine2" :class="{ 'error-border': $v.bookArtist.location.addressLine2.$error }" >
                </div>
              </div>
              <div class="col-4">
                <div class="widget_field">
                  <label class="engage_fields_label">City<span class="color_red">*</span></label>
                  <input type="text" class="form_control" v-model="bookArtist.location.city" :class="{ 'error-border': $v.bookArtist.location.city.$error }" >
                </div>
              </div>
              <div class="col-4">
                <div class="widget_field">
                  <label class="engage_fields_label">Postal Code<span class="color_red">*</span></label>
                  <input type="number" class="form_control" v-model="bookArtist.location.postalCode" :class="{ 'error-border': $v.bookArtist.location.postalCode.$error }" >
                </div>
              </div>
              <div class="col-4">
                <div class="">
                  <label class="engage_fields_label">Country<span class="color_red">*</span></label>
                  <div class="select_box">
                    <SelectDropdown
                      :options="countries"
                      :style="'width: 216.66px; border: 1px solid #E6EAF0;'"
                      placeholder='Choose country from list'
                      hide-cross-icon
                      v-model="bookArtist.location.country"
                      @change="((value) => bookArtist.location.country = value)"
                      :class="{ 'error-border': $v.bookArtist.location.country.$error }"
                    />
                  </div>
                  <div class="dropdown-svg-icon">
                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1L5 5L9 1" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="widget_form">
            <div class="widget_form_title">
              Additional Information
            </div>
            <div class="row">
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Sponsors<span class="color_red">*</span></label>
                  <input type="text" class="form_control" v-model="bookArtist.additionalInformation.sponsors" :class="{ 'error-border': $v.bookArtist.additionalInformation.sponsors.$error }" >
                </div>
              </div>
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Other Confirmed Acts<span class="color_red">*</span></label>
                  <input type="text" class="form_control" v-model="bookArtist.additionalInformation.otherConfirmedActs" :class="{ 'error-border': $v.bookArtist.additionalInformation.otherConfirmedActs.$error }" >
                </div>
              </div>
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Nearest International Airport (Name)<span
                      class="color_red">*</span></label>
                  <input type="text" class="form_control" v-model="bookArtist.additionalInformation.nearestInternalAirport" :class="{ 'error-border': $v.bookArtist.additionalInformation.nearestInternalAirport.$error }" >
                </div>
              </div>
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Distance (Airport - Hotel)</label>
                  <input type="text" class="form_control" v-model="bookArtist.additionalInformation.distance">
                </div>
              </div>
              <div class="col-12">
                <div class="widget_field">
                  <label class="engage_fields_label">References (Bookings, Events, Websites)</label>
                  <input type="text" class="form_control" v-model="bookArtist.additionalInformation.references">
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- offer -->
        <div class="col-md-4">
          <div class="widget_form sticky_top_30 fixOfferWidgetForm">
            <div class="widget_form_title">
              Offer
            </div>
            
            <div class="row">
              <div class="col-12">
                <div class="widget_field">
                  <label class="engage_fields_label">Preffered Dates</label>
                  <div class="calander_box">
                    <DateRangePicker class="form_control range-picker" v-model="bookArtist.offer.prefferedDates" />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="widget_field">
                  <label class="engage_fields_label">Type of Event</label>
                  <div class="event-svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                      <path d="M15 23C19.4183 23 23 19.4183 23 15C23 10.5817 19.4183 7 15 7C10.5817 7 7 10.5817 7 15C7 19.4183 10.5817 23 15 23Z" stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M7 15H23" stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M14.9988 7C16.9999 9.19068 18.137 12.0336 18.1988 15C18.137 17.9664 16.9999 20.8093 14.9988 23C12.9978 20.8093 11.8606 17.9664 11.7988 15C11.8606 12.0336 12.9978 9.19068 14.9988 7V7Z" stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <div class="select_box">
                    <SelectDropdown
                      :options="eventTypes"
                      :style="'width: 310px; border: 1px solid #E6EAF0; padding-left: 1.5rem'"
                      placeholder='Choose event type from list'
                      hide-cross-icon
                      v-model="bookArtist.offer.offerEventType"
                      @change="((value) => bookArtist.offer.offerEventType = value)"
                      :class="{ 'error-border': $v.bookArtist.offer.offerEventType.$error }"
                      />
                  </div>
                  <div class="dropdown-svg-icon">
                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1L5 5L9 1" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="widget_field">
                  <label class="engage_fields_label">Financial Offer</label>
                  <div class="prepend">
                    <input type="text" class="form_control" v-model="bookArtist.offer.financialOffer">
                    <div class="prepend_box">
                      $
                    </div>
                    <small class="text_muted d-block">Your “landed” offer. Excluding tax.</small>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="widget_field">
                  <div class="checkbox_prepende">
                    <div>
                      <input
                        type="checkbox"
                        id="checkbox"
                        class="checkbox"
                        @change="(e)=> {
                          bookArtist.offer.hotelIncluded= e.target.checked;
                        }"
                        v-model="bookArtist.offer.hotelIncluded" 
                      />
                      <label for="Hotel_Included">Hotel Included</label>  
                    </div>
                    <div class="">
                      <input
                        type="checkbox"
                        id="checkbox"
                        class="checkbox"
                        @change="(e)=> {
                          bookArtist.offer.transportIncluded= e.target.checked;
                        }"
                        v-model="bookArtist.offer.transportIncluded" 
                      />
                      <label for="Transport_Included">Transport Included</label>
                    </div>
                    <div class="">
                      <input
                        type="checkbox"
                        id="checkbox"
                        class="checkbox"
                        @change="(e)=> {
                          bookArtist.offer.riderIncluded= e.target.checked;
                        }"
                        v-model="bookArtist.offer.riderIncluded" 
                      />
                      <label for="Rider_Included">Rider Included</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="widget_field">
                  <label class="engage_fields_label">Comment</label>
                  <textarea class="form_control" v-model="bookArtist.offer.comment"></textarea>
                </div>
              </div>
              <div class="col-12">
                <div class="widget_field">
                  <button class="prime_button w-100" @click="saveBookArtist">Send Request</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import MusicSubHeader from '@/components/PageManager/PMSubHeader.vue';
import SelectDropdown from '../../Common/Dropdown/SelectDropdown.vue';
import { createRawData } from "@/mixins/common.js";
import { addBookArtist } from "@/apis/APIs.js";
import * as Helper from '@/apis/Helper.js';
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import DateRangePicker from '@/components/Common/DateRangePicker.vue'

export default {
  data() {
    return {
      deletePage: false,
      bookArtist: {
        events: {
          eventName: null,
          promotorName: null,
          promotorWebsite: null,
          ticketPrice: 0
        },
        location: {
          venueName: null,
          website: null,
          eventType: null,
          capacity: null,
          addressLine1: null,
          addressLine2: null,
          city: null,
          postalCode: null,
          country: null
        },
        additionalInformation: {
          sponsors: null,
          otherConfirmedActs: null,
          nearestInternalAirport: null,
          distance: null,
          references: null
        },
        offer: {
          prefferedDates: null,
          offerEventType: null,
          financialOffer: null,
          hotelIncluded: false,
          transportIncluded: false,
          riderIncluded: false,
          comment: null
        }
      },
      eventTypes: [],
    }
  },
  validations:{
    bookArtist: {
      events: {
        promotorName: {
          required
        }
      },
      location: {
        venueName: {
          required
        },
        addressLine1:{
          required
        },
        addressLine2:{
          required
        },
        capacity: {
          required,
        },
        city: {
          required
        },
        postalCode: {
          required
        },
        country: {
          required
        },
        eventType: {
          required
        }
      },
      additionalInformation: {
        sponsors: {
          required
        },
        otherConfirmedActs: {
          required
        },
        nearestInternalAirport: {
          required
        },
      },
      offer: {
        offerEventType: {
          required
        }
      }
    }
  },
  components: {
    MusicSubHeader,
    SelectDropdown,
    DateRangePicker
  },
  computed: {
    countries() {
      return this.$store.getters.getCountries.map(country => {
        return {
          value: country.id,
          label: country.name,
        }
      })
    },
    getCurrentPageUpcomingEvents() {
      return this.$store.getters.getCurrentPageUpcomingEvents.map((event) => {
        return {
          value: event.id,
          label: event.title
        };
      });
    },
  },
  async mounted() {
    await this.$store.dispatch("fetchCountries");
    this.getETypes();
  },
  methods: {
    ...mapActions([
      "getEventTypes",
    ]),
    goBack() {
      this.$router.go(-1);
    },
    closeModal () {
      this.deletePage = false;
    },
    showPiker() {
      this.show = !this.show;
    },
    selectValue(date) {
      this.bookArtist.offer.prefferedDates = moment(date).format(" DD/MM/YYYY");
      this.show = false;
    },
    async saveBookArtist() {
       // Touch all fields to trigger validation
       this.$v.$touch();

      // Check if form is valid
      if (this.$v.$invalid) {
        return;
      }

      try {
        const body = {
        eventName: this.bookArtist.events.eventName,
        promoter_name: this.bookArtist.events.promotorName, 
        promotorWebsite: this.bookArtist.events.promotorWebsite, 
        ticketPrice: this.bookArtist.events.ticketPrice,
        venue_name: this.bookArtist.location.venueName, 
        website: this.bookArtist.location.website, 
        event_type_id: this.bookArtist.location.eventType, 
        capacity: this.bookArtist.location.capacity, 
        address_line_1: this.bookArtist.location.addressLine1, 
        address_line_2: this.bookArtist.location.addressLine2, 
        city: this.bookArtist.location.city, 
        postal_code: this.bookArtist.location.postalCode, 
        country: this.bookArtist.location.country, 
        sponsors: this.bookArtist.additionalInformation.sponsors, 
        otherConfirmedActs: this.bookArtist.additionalInformation.otherConfirmedActs, 
        nearestInternalAirport: this.bookArtist.additionalInformation.nearestInternalAirport,
        distance: this.bookArtist.additionalInformation.distance,
        references: this.bookArtist.additionalInformation.references, 
        prefferedDates: this.bookArtist.offer.prefferedDates, 
        offer_event_type_id: this.bookArtist.offer.offerEventType,
        financialOffer: this.bookArtist.offer.financialOffer, 
        hotelIncluded: this.bookArtist.offer.hotelIncluded, 
        transportIncluded: this.bookArtist.offer.transportIncluded, 
        riderIncluded: this.bookArtist.offer.riderIncluded, 
        comment: this.bookArtist.offer.comment,
        page_id: this.$route.params.id
      };

        const res = await addBookArtist(body)
        this.notificationToast(true, 'Book Artist', res.data.message, 5000, res.data.success === 1? 'success': 'error');
        this.goBack();
      } catch (e) {
        console.error(e);
      }
    },
    async getETypes() {
      try {
        const { information } = await this.getEventTypes();
        this.eventTypes = information.map((eventType) => {
          return {
            value: eventType.id,
            label: eventType.title 
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
  }
}
</script>

<style scoped>
.checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(209, 67, 58, 0.5);
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, background-image 0.3s ease;
  margin-right: 13px;
  top: 4px;
  position: relative;
}

.checkbox:checked {
  background-color: white;
  background-image: url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2214%22 height=%2214%22 viewBox=%220 0 14 14%22 fill=%22none%22%3E%3Cpath d=%22M11.6673 3.5L5.25065 9.91667L2.33398 7%22 stroke=%22%23D1433A%22 stroke-width=%221.5%22 stroke-linecap=%22round%22 stroke-linejoin=%22round%22/%3E%3C/svg%3E');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.checkbox:not(:checked) {
  background-image: none;
  background-color: white;
}
.dropdown-svg-icon {
  position: absolute;
  right: 26px;
  top: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.event-svg-icon {
  position: absolute;
  top: 26px;
  left: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.w-100.fixEventDetailsPage {
  margin-top: 4.5rem;
}

.w-100.fixEventDetailsPage .page-filter {
  margin-bottom: 21px;
  padding-left: 50px;
}

.fixEventDetailsPage .container_xxl {
  width: 1200px;
}

.fixEventDetailsPage .widget_form {
  padding: 22px 28px 13px 30px;
}

.fixOfferWidgetForm .checkbox_list>label {
  display: flex;
  align-items: center;
}
.error-border {
  border: 1px solid #D1433A !important;
}
</style>