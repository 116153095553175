import { render, staticRenderFns } from "./ReportReason.vue?vue&type=template&id=8a14e720&scoped=true"
import script from "./ReportReason.vue?vue&type=script&lang=js"
export * from "./ReportReason.vue?vue&type=script&lang=js"
import style0 from "./ReportReason.vue?vue&type=style&index=0&id=8a14e720&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a14e720",
  null
  
)

export default component.exports