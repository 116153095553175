<template>
  <div class="Signup_pop_overlay fixChatAttachment" v-if="checkModalStatus">
    <div class="modal fade show" id="login" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      style="display: block; padding-right: 15px;" aria-modal="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="close-button-pop" ref="closeModal" data-dismiss="modal" aria-label="Close"
            @click="closeChatAttachmentModal($event)">
            <div class="svg-icon">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5 4.5L4.5 13.5" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M4.5 4.5L13.5 13.5" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>
          </div>
          <div class="title-headings d-flex align-items-center">
            <div class="text short-border">Chat Attachments</div>
          </div>
          <div class="tabs-div">
            <div v-for="(tab, index) in tab_list" :key="index">
              <div class="tab" :class="{ 'active': tab.check }" @click="changeActiveTab(tab.title, index)">
                <span>
                  {{ tab.title }}
                </span>
                <span v-if="tab.title === 'images'" class="ml-1">
                  ({{ (getActiveAllImages.length > 0) ? getActiveAllImages.length : 0 }})
                </span>
                <span v-if="tab.title === 'files'" class="ml-1">
                  ({{ (getActiveAllFiles.length > 0) ? getActiveAllFiles.length : 0 }})
                </span>
                <span v-if="tab.title === 'audio'" class="ml-1">
                  ({{ (getActiveAllAudios.length > 0) ? getActiveAllAudios.length : 0 }})
                </span>
                <span v-if="tab.title === 'links'" class="ml-1">
                  ({{ (getActiveAllLinks.length > 0) ? getActiveAllLinks.length : 0 }})
                </span>
                <span v-if="tab.title === 'voice'" class="ml-1">
                  ({{ (getActiveAllVoices.length > 0) ? getActiveAllVoices.length : 0 }})
                </span>
                <span v-if="tab.title === 'gifs'" class="ml-1">
                  ({{ (getActiveAllGifs.length > 0) ? getActiveAllGifs.length : 0 }})
                </span>
              </div>
            </div>
          </div>
          
          <!-- content -->
          <div class="data-div">
            <!-- images -->
            <div class="images-div" v-if="default_active_tab === 'images'">
              <template v-if="getActiveAllImages.length > 0">
                <img v-for="(image, index) in getActiveAllImages" :key="index" v-img :src="image.attachments[0].file" />
              </template>
              <p v-else>No Data</p>

            </div>

            <!-- files -->
            <div class="files-div" v-else-if="default_active_tab === 'files'">
              <template v-if="getActiveAllFiles.length > 0">
                <div class="file" v-for="(file, index) in getActiveAllFiles" :key="index">
                  <div class="file-image">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.5 2.5H7.5C6.83696 2.5 6.20107 2.76339 5.73223 3.23223C5.26339 3.70107 5 4.33696 5 5V25C5 25.663 5.26339 26.2989 5.73223 26.7678C6.20107 27.2366 6.83696 27.5 7.5 27.5H22.5C23.163 27.5 23.7989 27.2366 24.2678 26.7678C24.7366 26.2989 25 25.663 25 25V10L17.5 2.5Z"
                        stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M17.5 2.5V10H25" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M20 16.25H10" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M20 21.25H10" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M12.5 11.25H11.25H10" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </div>
                  <div class="file-info">
                    <p class="file-name">{{ file.attachments[0].name }}</p>
                    <span class="file-size"> {{ formatSizeUnits(file.attachments[0].size) }} /
                      {{ formatSizeUnits(file.created_at) }}</span>
                  </div>
                  <div class="file-download cursor-pointer">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"
                      @click.prevent="downloadItem(file.attachments[0].file, file.attachments[0].name, file.attachments[0].type)">
                      <path
                        d="M26.25 18.75V23.75C26.25 24.413 25.9866 25.0489 25.5178 25.5178C25.0489 25.9866 24.413 26.25 23.75 26.25H6.25C5.58696 26.25 4.95107 25.9866 4.48223 25.5178C4.01339 25.0489 3.75 24.413 3.75 23.75V18.75"
                        stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M8.75 12.5L15 18.75L21.25 12.5" stroke="#47505B" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M15 18.75V3.75" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
              </template>
              <p v-else>No Data</p>

            </div>

            <!-- audio -->
            <div class="audios-div" v-else-if="default_active_tab === 'audio'">
              <template v-if="getActiveAllAudios.length > 0">
                <div class="audio" v-for="(audioFile, index) in getActiveAllAudios" :key="index">
                  <div class="audio-image"
                    :class="{ 'active': (currentPlayId === audioFile.id && songStatus === true) }">
                    <img src="../../../src/assets/img/2.png" />
                    <div class="voice-play-btn" @click="playPause($event, filterSongData(audioFile), 'footerPlayer');">
                      <svg v-if="(currentPlayId === audioFile.id && songStatus === true)" width="14" height="18"
                        viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 2.5V15.5" stroke="white" stroke-width="4" stroke-linecap="round" />
                        <path d="M11.8828 2.5V15.5" stroke="white" stroke-width="4" stroke-linecap="round" />
                      </svg>
                      <svg v-else width="12" height="16" viewBox="0 0 12 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.166992 0.5L11.8337 8L0.166992 15.5V0.5Z" fill="white" />
                      </svg>
                    </div>
                  </div>
                  <div class="audio-details">
                    <span class="artist-name">
                      Artist Name
                    </span>
                    <p class="song-name">
                      {{ audioFile.attachments[0].name.slice(0, 18) }}
                    </p>
                  </div>
                  <div class="other-details">
                    <span class="duration" :id="index">
                      {{ getDuration(audioFile.attachments[0].file, index) }}
                    </span>
                    <div class="popularity">
                      <svg width="39" height="12" viewBox="0 0 39 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M30.4865 0H28.4541V12H30.4865V0ZM32.519 0H34.5514V12H32.519V0ZM36.5838 0H38.6163V12H36.5838V0Z"
                          fill="#E6EAF0" />
                        <mask id="mask0_126_9063" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="28" y="0"
                          width="11" height="12">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M30.4865 0H28.4541V12H30.4865V0ZM32.519 0H34.5514V12H32.519V0ZM36.5838 0H38.6163V12H36.5838V0Z"
                            fill="white" />
                        </mask>
                        <g mask="url(#mask0_126_9063)"></g>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M2.03244 0H0V12H2.03244V0ZM4.06487 0H6.09731V12H4.06487V0ZM10.1622 0H8.12974V12H10.1622V0ZM12.1946 0H14.227V12H12.1946V0ZM18.2919 0H16.2595V12H18.2919V0ZM20.3244 0H22.3568V12H20.3244V0ZM24.3892 0H26.4217V12H24.3892V0Z"
                          fill="#47505B" />
                      </svg>
                    </div>
                    <div class="book-mark">
                      <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.80601 14.1694L6 9.3158L10.194 14.1694C10.262 14.24 10.3506 14.2875 10.447 14.3051C10.5435 14.3228 10.6431 14.3097 10.7318 14.2678C10.8204 14.2259 10.8937 14.1572 10.9413 14.0714C10.9888 13.9856 11.0082 13.887 10.9968 13.7896V1.75951C11.0103 1.57183 10.9487 1.38647 10.8257 1.24409C10.7027 1.1017 10.5282 1.01393 10.3406 1H1.65944C1.47179 1.01393 1.29732 1.1017 1.1743 1.24409C1.05127 1.38647 0.989741 1.57183 1.00319 1.75951V13.7896C0.991763 13.887 1.01119 13.9856 1.05874 14.0714C1.10628 14.1572 1.17955 14.2259 1.26822 14.2678C1.35689 14.3097 1.45648 14.3228 1.55296 14.3051C1.64944 14.2875 1.73795 14.24 1.80601 14.1694V14.1694Z"
                          stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </div>
                    <div class="option-menu">
                      <svg width="3" height="15" viewBox="0 0 3 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.5 3C1.79667 3 2.08668 2.91203 2.33335 2.7472C2.58003 2.58238 2.77229 2.34811 2.88582 2.07403C2.99935 1.79994 3.02906 1.49834 2.97118 1.20736C2.9133 0.916393 2.77044 0.649119 2.56066 0.43934C2.35088 0.229561 2.08361 0.0867 1.79263 0.0288221C1.50166 -0.0290557 1.20006 0.000649277 0.925974 0.114181C0.651885 0.227712 0.417617 0.419971 0.252795 0.666644C0.087973 0.913318 -6.36721e-07 1.20333 -6.49689e-07 1.5C-6.67078e-07 1.89782 0.158035 2.27936 0.439339 2.56066C0.720644 2.84196 1.10217 3 1.5 3V3Z"
                          fill="#47505B" />
                        <path
                          d="M1.5 9C1.79667 9 2.08668 8.91203 2.33335 8.7472C2.58003 8.58238 2.77229 8.34811 2.88582 8.07403C2.99935 7.79994 3.02906 7.49834 2.97118 7.20736C2.9133 6.91639 2.77044 6.64912 2.56066 6.43934C2.35088 6.22956 2.08361 6.0867 1.79263 6.02882C1.50166 5.97094 1.20006 6.00065 0.925974 6.11418C0.651885 6.22771 0.417617 6.41997 0.252795 6.66664C0.087973 6.91332 -6.36721e-07 7.20333 -6.49689e-07 7.5C-6.67078e-07 7.89782 0.158035 8.27936 0.439339 8.56066C0.720644 8.84196 1.10217 9 1.5 9V9Z"
                          fill="#47505B" />
                        <path
                          d="M1.5 15C1.79667 15 2.08668 14.912 2.33335 14.7472C2.58003 14.5824 2.77229 14.3481 2.88582 14.074C2.99935 13.7999 3.02906 13.4983 2.97118 13.2074C2.9133 12.9164 2.77044 12.6491 2.56066 12.4393C2.35088 12.2296 2.08361 12.0867 1.79263 12.0288C1.50166 11.9709 1.20006 12.0006 0.925974 12.1142C0.651885 12.2277 0.417617 12.42 0.252795 12.6666C0.087973 12.9133 -6.36721e-07 13.2033 -6.49689e-07 13.5C-6.67078e-07 13.8978 0.158035 14.2794 0.439339 14.5607C0.720644 14.842 1.10217 15 1.5 15V15Z"
                          fill="#47505B" />
                      </svg>
                    </div>
                  </div>
                </div>
              </template>
              <p v-else>No Data</p>

            </div>

            <!-- link -->
            <div class="links-div" v-else-if="default_active_tab === 'links'">
              <template v-if="getActiveAllLinks.length > 0">

                <div v-for="(link, index) in getActiveAllLinks" :key="index" class="link">
                  <div class="link-info">
                    <p class="link-heading">
                      {{ (link.attachments && link.attachments.length > 0) ? link.attachments[0].title : 'Title' }}
                    </p>
                    <span class="link-url">
                      {{ (link.attachments && link.attachments.length > 0) ? link.attachments[0].title_link : 'Link Url'
                      }}
                    </span>
                  </div>
                  <div class="redirect" @click="redirectToLink(link.attachments[0].title_link)">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M22.5 16.25V23.75C22.5 24.413 22.2366 25.0489 21.7678 25.5178C21.2989 25.9866 20.663 26.25 20 26.25H6.25C5.58696 26.25 4.95107 25.9866 4.48223 25.5178C4.01339 25.0489 3.75 24.413 3.75 23.75V10C3.75 9.33696 4.01339 8.70107 4.48223 8.23223C4.95107 7.76339 5.58696 7.5 6.25 7.5H13.75"
                        stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M18.75 3.75H26.25V11.25" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M12.5 17.5L26.25 3.75" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
              </template>
              <p v-else>No Data</p>

            </div>

            <!-- voice -->
            <div class="voices-div" v-else-if="default_active_tab === 'voice'">
              <template v-if="getActiveAllVoices.length > 0">
                <div v-for="(voice, index) in getActiveAllVoices" :key="index" class="voice"
                  :class="{ 'active': voice_id === voice.cid }">
                  <div class="voice-play-btn" :class="{ 'active': voice_id === voice.cid }">
                    <svg v-if="!playPauseCheck" @click="playPauseRecording($event, voice.cid)" width="12" height="16"
                      viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.166992 0.5L11.8337 8L0.166992 15.5V0.5Z" fill="white" />
                    </svg>
                    <svg v-else @click="playPauseRecording($event, voice.cid)" width="14" height="18"
                      viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 2.5V15.5" stroke="white" stroke-width="4" stroke-linecap="round" />
                      <path d="M11.8828 2.5V15.5" stroke="white" stroke-width="4" stroke-linecap="round" />
                    </svg>
                  </div>
                  <div class="voice-wave">
                    <!-- Here you can integrate the  waves component -->
                  </div>
                  <div class="voice-info">
                    <span class="date">{{ voice.created_at | formatDateForVoice }}</span>
                    <p class="duration">{{ getVoiceDuration(voice.cid) }}</p>
                  </div>
                  <audio :id="voice.cid" controls
                    :src="(voice.attachments && voice.attachments.length > 0) ? voice.attachments[0].file : ''"
                    style="display: none;" />
                </div>
              </template>
              <p v-else>No Data</p>

            </div>

            <!-- Gifs -->
            <!-- <div class="images-div" v-else-if="default_active_tab === 'gifs'" >
              <img  v-if="getActiveAllGifs.length > 1"
               v-for="(gif,index) in getActiveAllGifs" :key="index" v-img
                     :src="gif.attachments[0].file"
                />
            </div>
          -->
            <div class="images-div" v-else-if="default_active_tab === 'gifs'">
              <template v-if="getActiveAllGifs.length > 0">
                <img v-for="(gif, index) in getActiveAllGifs" :key="index" v-img :src="gif.attachments[0].file" />
              </template>
              <p v-else>No Data</p>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueImg from "v-img";
import moment from "moment";
const vueImgConfig = {
  // Display 'download' button near 'close' that opens source image in new tab
  sourceButton: false,
}
Vue.use(VueImg, vueImgConfig);

export default {
  name: "ChatAttachmentModal",
  data() {
    return {
      tab_list: [
        {
          title: 'images',
          check: true
        },
        {
          title: 'files',
          check: false
        },
        {
          title: 'audio',
          check: false
        },
        {
          title: 'links',
          check: false
        },
        {
          title: 'voice',
          check: false
        },
        {
          title: 'gifs',
          check: false
        }
      ],
      default_active_tab: 'images',
      last_active_tab_index: 0,
      playPauseCheck: false,
      voice_id: null
    }
  },
  watch: {
    checkActiveTab(newVal) {
      this.updateTabChecks(newVal);
    }
  },
  filters: {
    formatDateForVoice: function (date) {
      return moment(date).format('DD/MM/YYYY')
    }
  },
  computed: {
    checkActiveTab() {
      const self = this;
      return self.$store.getters.getChatAttachmentChatModalActiveTab
    },
    currentActiveTab() {
      return this.$store.getters.getCurrentPlayId;
    },
    currentPlayId() {
      return this.$store.getters.getCurrentPlayId;
    },
    songStatus() {
      return this.$store.getters.getSongsStatus;
    },
    checkModalStatus() {
      const self = this;
      return self.$store.getters.getChatAttachmentChatModalStatus
    },
    getActiveAllImages() {
      return this.$store.getters.getActiveAllImages
    },
    getActiveAllFiles() {
      return this.$store.getters.getActiveAllFiles
    },
    getActiveAllAudios() {
      return this.$store.getters.getActiveAllAudios
    },
    getActiveAllLinks() {
      return this.$store.getters.getActiveAllLinks
    },
    getActiveAllVoices() {
      return this.$store.getters.getActiveAllVoices
    },
    getActiveAllGifs() {
      return this.$store.getters.getActiveAllGifs
    }
  },
  methods: {
    updateTabChecks(activeTab) {
      console.log('updateTabChecks');
      
      this.tab_list.forEach(tab => {
        tab.check = false;
      });
      const matchedTab = this.tab_list.find(tab => tab.title === activeTab);
      if (matchedTab) {
        matchedTab.check = true;
      }
      this.default_active_tab = activeTab
    },
    closeChatAttachmentModal(event) {
      event.stopPropagation()
      const self = this;
      self.$store.commit('SET_CHAT_ATTACHMENT_MODAL', false)
    },
    changeActiveTab(title, index) {
      if (this.default_active_tab) {
        // this.tab_list[this.last_active_tab_index].check = false
        this.tab_list.forEach(tab => tab.check = false);

        this.tab_list[index].check = true
        this.default_active_tab = title
        this.last_active_tab_index = index
      }
    },
    getDuration(mp3file, index) {
      const self = this
      var duration
      var au = document.createElement('audio');
      au.src = mp3file;
      au.addEventListener('loadedmetadata', function () {
        duration = au.duration;
        const minutes = Math.floor(duration / 60);
        const seconds = duration % 60;
        const result = self.padTo2Digits(minutes) + ":" + self.padTo2Digits(seconds);
        document.getElementById(index).innerHTML = result;
        // console.log("The duration of the song is of inside: " + x + " seconds");
      }, false);
    },
    padTo2Digits(num) {
      return num.toString().slice(0, 2).padStart(2, '0');
    },
    filterSongData(song) {
      const song_id = song.id
      const song_audio_url = song.attachments[0].file
      return [{ id: song_id, audio_url: song_audio_url, type: 'song' }]
    },
    redirectToLink(link) {
      window.open(link)
    },
    playPauseRecording(event, id) {
      event.stopPropagation();
      this.playPauseCheck = !this.playPauseCheck;
      if (this.playPauseCheck) {
        this.voice_id = id
        document.getElementById(id).play();
      } else {
        this.voice_id = null
        document.getElementById(id).pause();
      }
    },
    getVoiceDuration(id) {
      if (document.getElementById(id)) {
        const duration = document.getElementById(id).duration
        const result = parseInt(duration)
        return moment.utc(result * 1000).format('mm:ss')
      }
    }
  }
};
</script>

<style scoped lang="scss">
.Signup_pop_overlay {
  .modal {
    overflow: hidden;

    .modal-dialog {
      top: 97px;
      right: 36px;

      .modal-content {
        padding: 0 30px 30px 30px;
        width: 570px;
        height: 661px;

        .close-button-pop {
          right: 28px;
          border-radius: 2px;
        }

        .title-headings {
          height: 70px;
          width: 510px;
          border-bottom: 1px solid #E6EAF0;

          .text {
            margin-left: unset;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #071526;
          }
        }

        .tabs-div {
          height: 70px;
          border-bottom: 1px solid #E6EAF0;
          display: flex;
          align-items: center;

          div {
            height: 70px;

            .tab {
              height: inherit;
              width: 75px;
              display: flex;
              align-items: end;
              justify-content: center;
              margin-right: 0.8rem;
              cursor: pointer;

              &.active {
                border-bottom: 4px solid #D1433A;

                span {
                  margin-bottom: 24px;
                  color: #47505B;
                }
              }

              span {
                font-style: normal;
                font-weight: 700;
                font-size: 13px;
                line-height: 16px;
                text-transform: uppercase;
                color: #8B949F;
                font-family: HelveticaNeueBold, sans-serif;
                margin-bottom: 14px;
                text-transform: uppercase;
              }
            }
          }
        }

        .data-div {
          width: 510px;
          height: 480px;
          margin-top: 20px;

          .images-div {
            width: 510px;
            height: 480px;
            overflow: auto;

            img {
              width: 240px;
              height: 150px;
              margin: 0 10px 10px 0;
              cursor: pointer;
            }
          }

          .files-div {
            width: 510px;
            height: 480px;
            overflow: auto;

            .file {
              display: flex;
              align-items: center;
              width: 490px;
              height: 70px;
              background: #F9FAFB;
              border-radius: 2px;
              margin-bottom: 10px;

              &:last-child {
                margin-bottom: unset;
              }

              &:hover {
                background: #D1433A1A;

                .file-image {
                  svg {
                    path {
                      stroke: #D1433A;
                    }
                  }
                }

                .file-download {
                  svg {
                    path {
                      stroke: #D1433A;
                    }
                  }
                }
              }

              .file-image {
                background: #FFFFFF;
                border-radius: 2px;
                height: 50px;
                width: 50px;
                margin-left: 10px;
                display: flex;

                svg {
                  margin: auto;
                }
              }

              .file-info {
                margin-left: 20px;

                .file-name {
                  font-style: normal;
                  font-weight: 700;
                  font-size: 13px;
                  line-height: 16px;
                  color: #47505B;
                  margin-bottom: 6px;
                }

                .file-size {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 11px;
                  line-height: 13px;
                  color: #8B949F;
                }
              }

              .file-download {
                margin-left: auto;
                margin-right: 20px;
                cursor: pointer;
              }
            }
          }

          .audios-div {
            width: 510px;
            height: 480px;
            overflow: auto;

            .audio {
              background: #F9FAFB;
              border-radius: 2px;
              display: flex;
              align-items: center;
              width: 490px;
              height: 70px;
              margin-bottom: 10px;

              &:last-child {
                margin-bottom: unset;
              }

              .audio-image {
                height: 50px;
                width: 50px;
                margin-left: 10px;
                border-radius: 4px;
                display: flex;

                &:hover {
                  img {
                    display: none;
                  }

                  .voice-play-btn {
                    display: flex;
                  }
                }

                &.active {
                  img {
                    display: none;
                  }

                  .voice-play-btn {
                    display: flex;
                    background: #D1433A;
                  }
                }

                img {
                  width: inherit;
                  border-radius: 4px;
                }

                .voice-play-btn {
                  height: 30px;
                  width: 30px;
                  background: #47505B;
                  display: none;
                  cursor: pointer;
                  margin: auto;

                  &:hover {
                    background: #D1433A;
                  }

                  svg {
                    margin: auto;
                  }
                }
              }

              .audio-details {
                margin-left: 20px;

                .artist-name {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 11px;
                  line-height: 13px;
                  color: #8B949F;
                  margin-bottom: 6px;
                }

                .song-name {
                  font-style: normal;
                  font-weight: 700;
                  font-size: 13px;
                  line-height: 16px;
                  color: #47505B;
                  margin-bottom: unset;
                }
              }

              .other-details {
                margin-left: auto;
                display: flex;
                align-items: center;

                .duration {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 16px;
                  color: #8B949F;
                }

                .popularity {
                  display: flex;
                  margin-left: 44px;
                  cursor: pointer;

                  svg {
                    margin: auto;
                  }
                }

                .book-mark {
                  display: flex;
                  margin-left: 71px;
                  cursor: pointer;

                  svg {
                    margin: auto;
                  }
                }

                .option-menu {
                  display: flex;
                  margin: 0 27px 0 30px;
                  cursor: pointer;

                  svg {
                    margin: auto;
                  }
                }

              }
            }
          }

          .links-div {
            width: 510px;
            height: 480px;
            overflow: auto;

            .link {
              background: #F9FAFB;
              border-radius: 2px;
              display: flex;
              align-items: center;
              width: 490px;
              height: 70px;
              margin-bottom: 10px;

              &:last-child {
                margin-bottom: unset;
              }

              &:hover {
                background: #F9FAFB;

                .redirect {
                  svg {
                    path {
                      stroke: #D1433A;
                    }
                  }
                }
              }

              .link-info {
                margin-left: 20px;

                .link-heading {
                  font-style: normal;
                  font-weight: 700;
                  font-size: 13px;
                  line-height: 16px;
                  color: #47505B;
                  margin-bottom: 6px;
                }

                .link-url {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 11px;
                  line-height: 13px;
                  color: #D1433A;
                  cursor: pointer;
                  width: 250px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: inline-block;
                }
              }

              .redirect {
                margin-left: auto;
                display: flex;
                margin-right: 20px;
                cursor: pointer;

                svg {
                  margin: auto;
                }
              }

            }
          }

          .voices-div {
            width: 510px;
            height: 480px;
            overflow: auto;

            .voice {
              background: #F9FAFB;
              border-radius: 2px;
              display: flex;
              align-items: center;
              width: 490px;
              height: 70px;
              margin-bottom: 10px;

              &.active {
                background: #D1433A1A;
              }

              &:last-child {
                margin-bottom: unset;
              }

              .voice-play-btn {
                margin-left: 20px;
                height: 30px;
                width: 30px;
                background: #47505B;
                display: flex;
                cursor: pointer;

                &.active {
                  background: #D1433A;
                }

                &:hover {
                  background: #D1433A;
                }

                svg {
                  margin: auto;
                }
              }

              .voice-wave {
                width: 340px;
                margin-left: 20px;
              }

              .voice-info {
                margin-left: auto;
                margin-right: 20px;

                .date {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 10px;
                  line-height: 12px;
                  text-align: right;
                  color: #8B949F;
                  margin-bottom: 4px;
                }

                .duration {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 16px;
                  text-align: right;
                  color: #8B949F;
                  margin-bottom: unset;
                }
              }

            }
          }
        }

      }
    }
  }
}
</style>
<style scoped>
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #E6EAF0;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #D1433A;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #D1433A;
}
</style>
