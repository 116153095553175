<template>
    <div class="row upload-product bg-white" style="background-color: white">
      <div class="col-9 file-format-p" style="">
        <b-form @submit.prevent="uploadSong">
          <div class="row pt-4">
            <div class="col-sm-12">
              <h5 class="song-heading">Choose File Format</h5>
            </div>
            <div class="col-sm-12 pt-2">
              <button
                type="button"
                  :class="{'active':(tempSampleUpload.type==='sample')}"
                  @click="tempSampleUpload.type=(actionType === 'createSample') ? 'sample' : 'sample_pack'"
                  class="song-btn"
              >
                <span
                  class="song-btn-icon-display">
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.8">
<path d="M11 1C5.48607 1 1 5.48607 1 11C1 16.5141 5.48607 21 11 21C16.5141 21 21 16.5139 21 11C21 5.48585 16.5141 1 11 1ZM11 20.2364C5.90694 20.2364 1.76364 16.0928 1.76364 11C1.76364 5.90716 5.90694 1.76364 11 1.76364C16.0928 1.76364 20.2364 5.90716 20.2364 11C20.2364 16.0928 16.0928 20.2364 11 20.2364Z" fill="white" stroke="white" stroke-width="0.41"/>
<path d="M13.7047 12.8224L14.7565 10.9675C14.822 10.8454 14.9711 10.7895 15.1125 10.7895H17.7083C17.8073 10.7895 17.901 10.8165 17.9726 10.8665C18.0445 10.9166 18.1 10.9952 18.1 11.093C18.1 11.1909 18.0445 11.2694 17.9726 11.3195C17.901 11.3695 17.8073 11.3965 17.7083 11.3965H15.369L13.9686 13.861L13.9684 13.8614C13.896 13.9877 13.7345 14.0424 13.5868 14.0377C13.434 14.0329 13.2806 13.9544 13.233 13.8159C13.233 13.8158 13.2329 13.8156 13.2329 13.8154L11.6704 9.35906L9.7176 14.8747L9.71701 14.8763L9.717 14.8763C9.66217 15.0229 9.49621 15.1 9.3375 15.1H9.33167C9.17236 15.1 9.00318 15.0178 8.95619 14.867L8.95581 14.8657L8.95581 14.8657L7.72904 10.7398L7.1593 11.9323C7.12923 12.0004 7.0703 12.0491 7.00877 12.0799C6.94539 12.1115 6.87019 12.1291 6.79417 12.1291H4.29167C4.19267 12.1291 4.09905 12.1021 4.02737 12.0521C3.95554 12.002 3.9 11.9234 3.9 11.8256C3.9 11.7277 3.95554 11.6492 4.02737 11.5991C4.09905 11.5491 4.19267 11.5221 4.29167 11.5221H6.52123L7.45644 9.57259C7.45644 9.57257 7.45645 9.57255 7.45646 9.57254L7.4565 9.57245L7.54667 9.6157C7.5875 9.53023 7.70417 9.47326 7.83833 9.47733L13.7047 12.8224ZM13.7047 12.8224L12.0513 8.12646C12.0029 7.98032 11.8337 7.9 11.6708 7.9C11.5127 7.9 11.3473 7.97653 11.2919 8.11824L11.2918 8.11822L11.2907 8.12137L9.37475 13.5493L13.7047 12.8224Z" fill="white" stroke="white" stroke-width="0.2"/>
</g>
</svg>
                </span>
                <span class="song-btn-margin">Sample</span>
              </button>
              <button
                type="button"
                :class="{'active':(tempSampleUpload.type==='sample_pack')}"
                @click="tempSampleUpload.type=(actionType === 'createSample') ?'sample_pack' : 'sample'"
                class="album-btn"
              >
                <span
                  class="song-btn-icon-display">
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.8" d="M11 1C5.48607 1 1 5.48607 1 11C1 16.5141 5.48607 21 11 21C16.5141 21 21 16.5139 21 11C21 5.48585 16.5141 1 11 1ZM11 20.2364C5.90694 20.2364 1.76364 16.0928 1.76364 11C1.76364 5.90716 5.90694 1.76364 11 1.76364C16.0928 1.76364 20.2364 5.90716 20.2364 11C20.2364 16.0928 16.0928 20.2364 11 20.2364Z" fill="#47505B" stroke="#47505B" stroke-width="0.41"/>
<path opacity="0.8" d="M14.1257 10.0551L13.0747 13.0325L12.0267 10.9233L11.9365 10.88L11.9364 10.8801L11.4137 11.9698H10.1667C10.1027 11.9698 10.0403 11.9872 9.99112 12.0215C9.94179 12.0559 9.9 12.1126 9.9 12.186C9.9 12.2595 9.94179 12.3162 9.99112 12.3506C10.0403 12.3849 10.1027 12.4023 10.1667 12.4023H11.5967C11.6464 12.4023 11.696 12.3909 11.7385 12.3697C11.7791 12.3494 11.8217 12.3155 11.8443 12.2649L12.1215 11.6846L12.7908 13.9355L12.7908 13.9355L12.7912 13.9367C12.8257 14.0474 12.9449 14.1 13.0467 14.1H13.05C13.1512 14.1 13.2672 14.0508 13.307 13.9443L13.307 13.9443L13.3076 13.9427L14.3829 10.9056L15.2354 13.3369C15.2354 13.3371 15.2355 13.3372 15.2355 13.3374C15.2705 13.4393 15.379 13.4899 15.4768 13.493C15.5695 13.4959 15.681 13.4621 15.7334 13.3707L15.7336 13.3703L16.5215 11.9837H17.8333C17.8973 11.9837 17.9597 11.9663 18.0089 11.932C18.0582 11.8976 18.1 11.8409 18.1 11.7674C18.1 11.694 18.0582 11.6373 18.0089 11.6029C17.9597 11.5686 17.8973 11.5512 17.8333 11.5512H16.35C16.261 11.5512 16.157 11.5861 16.1091 11.675L15.5547 12.6526L14.6413 10.0584C14.6054 9.95074 14.4862 9.9 14.3833 9.9C14.2827 9.9 14.1673 9.94863 14.1269 10.052L14.1268 10.0519L14.1257 10.0551Z" fill="#47505B" stroke="#47505B" stroke-width="0.2"/>
<path opacity="0.8" fill-rule="evenodd" clip-rule="evenodd" d="M6.64645 7.14645C6.74021 7.05268 6.86739 7 7 7H9.23241L10.084 8.27735C10.1767 8.41645 10.3328 8.5 10.5 8.5H15C15.1326 8.5 15.2598 8.55268 15.3536 8.64645C15.4473 8.74021 15.5 8.86739 15.5 9V11.2928L15.6402 11.691L15.7593 11.4809C15.9052 11.2149 16.1844 11.1512 16.35 11.1512H16.5V9C16.5 8.60217 16.342 8.22064 16.0607 7.93934C15.7794 7.65804 15.3978 7.5 15 7.5H10.7676L9.91603 6.22265C9.82329 6.08355 9.66718 6 9.5 6H7C6.60218 6 6.22064 6.15804 5.93934 6.43934C5.65804 6.72064 5.5 7.10218 5.5 7.5V14.5C5.5 14.8978 5.65804 15.2794 5.93934 15.5607C6.22064 15.842 6.60218 16 7 16H15C15.3978 16 15.7794 15.842 16.0607 15.5607C16.342 15.2794 16.5 14.8978 16.5 14.5V12.8313L16.0814 13.568L16.0804 13.5696C15.9469 13.8026 15.7133 13.8778 15.5423 13.8909L15.5 13.9654V14.5C15.5 14.6326 15.4473 14.7598 15.3536 14.8536C15.2598 14.9473 15.1326 15 15 15H7C6.86739 15 6.74021 14.9473 6.64645 14.8536C6.55268 14.7598 6.5 14.6326 6.5 14.5V7.5C6.5 7.36739 6.55268 7.24021 6.64645 7.14645Z" fill="#47505B"/>
</svg>
                </span>
                <span class="song-btn-margin">Sample Pack</span>
              </button>
              <button
                type="button"
                :class="{'active':(tempSampleUpload.type==='licence')}"
                @click="tempSampleUpload.type=(actionType === 'createSample') ?'licence' : 'sample'"
                class="album-btn"
              >
                <span
                  class="song-btn-icon-display">
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.8" d="M11 1C5.48607 1 1 5.48607 1 11C1 16.5141 5.48607 21 11 21C16.5141 21 21 16.5139 21 11C21 5.48585 16.5141 1 11 1ZM11 20.2364C5.90694 20.2364 1.76364 16.0928 1.76364 11C1.76364 5.90716 5.90694 1.76364 11 1.76364C16.0928 1.76364 20.2364 5.90716 20.2364 11C20.2364 16.0928 16.0928 20.2364 11 20.2364Z" fill="#47505B" stroke="#47505B" stroke-width="0.41"/>
<path opacity="0.8" d="M11.1667 8L7 10.0833L11.1667 12.1667L15.3333 10.0833L11.1667 8Z" stroke="#47505B" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path opacity="0.8" d="M7 12.166L11.1667 14.2493L15.3333 12.166" stroke="#47505B" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                </span>
                <span class="song-btn-margin">Licence</span>
              </button>
            </div>
          </div>
          <div v-if="tempSampleUpload.type==='sample'" class="row pt-4">
            <div class="col-sm-12">
              <h5 class="song-heading">Choose Sample Type</h5>
            </div>
            <div class="col-sm-12 pt-2">
              <button
                type="button"
                  :class="{'active':(tempSampleUpload.sampleType==='one_shot_sample')}"
                  @click="tempSampleUpload.sampleType=(actionType === 'createSample') ? 'one_shot_sample' : 'loop_sample'"
                  class="song-btn"
              >
                <span
                  class="song-btn-icon-display">
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.8">
<path d="M11 1C5.48607 1 1 5.48607 1 11C1 16.5141 5.48607 21 11 21C16.5141 21 21 16.5139 21 11C21 5.48585 16.5141 1 11 1ZM11 20.2364C5.90694 20.2364 1.76364 16.0928 1.76364 11C1.76364 5.90716 5.90694 1.76364 11 1.76364C16.0928 1.76364 20.2364 5.90716 20.2364 11C20.2364 16.0928 16.0928 20.2364 11 20.2364Z" fill="white" stroke="white" stroke-width="0.41"/>
<path d="M13.7047 12.8224L14.7565 10.9675C14.822 10.8454 14.9711 10.7895 15.1125 10.7895H17.7083C17.8073 10.7895 17.901 10.8165 17.9726 10.8665C18.0445 10.9166 18.1 10.9952 18.1 11.093C18.1 11.1909 18.0445 11.2694 17.9726 11.3195C17.901 11.3695 17.8073 11.3965 17.7083 11.3965H15.369L13.9686 13.861L13.9684 13.8614C13.896 13.9877 13.7345 14.0424 13.5868 14.0377C13.434 14.0329 13.2806 13.9544 13.233 13.8159C13.233 13.8158 13.2329 13.8156 13.2329 13.8154L11.6704 9.35906L9.7176 14.8747L9.71701 14.8763L9.717 14.8763C9.66217 15.0229 9.49621 15.1 9.3375 15.1H9.33167C9.17236 15.1 9.00318 15.0178 8.95619 14.867L8.95581 14.8657L8.95581 14.8657L7.72904 10.7398L7.1593 11.9323C7.12923 12.0004 7.0703 12.0491 7.00877 12.0799C6.94539 12.1115 6.87019 12.1291 6.79417 12.1291H4.29167C4.19267 12.1291 4.09905 12.1021 4.02737 12.0521C3.95554 12.002 3.9 11.9234 3.9 11.8256C3.9 11.7277 3.95554 11.6492 4.02737 11.5991C4.09905 11.5491 4.19267 11.5221 4.29167 11.5221H6.52123L7.45644 9.57259C7.45644 9.57257 7.45645 9.57255 7.45646 9.57254L7.4565 9.57245L7.54667 9.6157C7.5875 9.53023 7.70417 9.47326 7.83833 9.47733L13.7047 12.8224ZM13.7047 12.8224L12.0513 8.12646C12.0029 7.98032 11.8337 7.9 11.6708 7.9C11.5127 7.9 11.3473 7.97653 11.2919 8.11824L11.2918 8.11822L11.2907 8.12137L9.37475 13.5493L13.7047 12.8224Z" fill="white" stroke="white" stroke-width="0.2"/>
</g>
</svg>
                </span>
                <span class="song-btn-margin">One Shot Sample</span>
              </button>
              <button
                type="button"
                :class="{'active':(tempSampleUpload.sampleType==='loop_sample')}"
                @click="tempSampleUpload.sampleType=(actionType === 'createSample') ?'loop_sample' : 'one_shot_sample'"
                class="album-btn"
              >
                <span
                  class="song-btn-icon-display">
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.8">
<path d="M11 1C5.48607 1 1 5.48607 1 11C1 16.5141 5.48607 21 11 21C16.5141 21 21 16.5139 21 11C21 5.48585 16.5141 1 11 1ZM11 20.2364C5.90694 20.2364 1.76364 16.0928 1.76364 11C1.76364 5.90716 5.90694 1.76364 11 1.76364C16.0928 1.76364 20.2364 5.90716 20.2364 11C20.2364 16.0928 16.0928 20.2364 11 20.2364Z" fill="#47505B" stroke="#47505B" stroke-width="0.41"/>
<path d="M13.3104 8.62038L12.6788 9.23871C12.5239 9.38184 12.5143 9.64083 12.6572 9.79574C12.7325 9.87734 12.835 9.9273 12.9378 9.9273C13.0303 9.9273 13.1232 9.89806 13.1967 9.83043L14.5377 8.5942C14.616 8.52198 14.6605 8.4214 14.6605 8.31471C14.6605 8.20802 14.616 8.107 14.5377 8.03478L13.1967 6.79659C13.0418 6.65346 12.8001 6.66328 12.6572 6.81819C12.514 6.9731 12.5236 7.23426 12.6788 7.37739L13.2947 7.96583H8.09103C6.79764 7.96583 5.76367 8.97886 5.76367 10.2722V11.7269C5.76367 11.9379 5.88018 12.1087 6.09095 12.1087C6.30171 12.1087 6.41822 11.9379 6.41822 11.7269V10.2722C6.41822 9.39995 7.21874 8.62038 8.09103 8.62038H13.3104Z" fill="#47505B" stroke="#47505B" stroke-width="0.41"/>
<path d="M15.4801 10C15.2692 10 15.0438 10.1711 15.0438 10.3818V11.8364C15.0438 12.7085 14.4614 13.3118 13.5891 13.3118H8.35579L8.98176 12.7869C9.13667 12.6439 9.14627 12.4288 9.00314 12.2739C8.86023 12.1192 8.6187 12.1225 8.46357 12.2658L7.12262 13.5108C7.04451 13.583 7 13.688 7 13.7944C7 13.9009 7.04451 14.0043 7.12262 14.0765L8.46357 15.3158C8.5371 15.3839 8.62983 15.4177 8.72256 15.4177C8.82532 15.4177 8.92787 15.3767 9.00314 15.2951C9.14627 15.1402 9.13667 14.9 8.98176 14.7571L8.35994 14.1845H13.5894C14.8825 14.1845 15.9165 13.1296 15.9165 11.8362V10.3816C15.9165 10.1708 15.6911 10 15.4801 10Z" fill="#47505B" stroke="#47505B" stroke-width="0.41"/>
</g>
</svg>

                </span>
                <span class="song-btn-margin">Loop Sample</span>
              </button>
            </div>
          </div>
          <div class="col-sm-12 px-0 mb-3" style="margin-top: 22px;">
            <h5 class="song-heading">Upload File</h5>
          </div>
          <div @click="$refs.file.click()" @drop="dragFile" @dragover.prevent @drop.prevent
               class="col-sm-12 pt-5 pb-5 upload-file-border">
            <div>
              <div>
                <p class="product-song-p">
                  Browse for file or drag and drop it here
                </p>
              </div>
              <div class="upload-file-icon">
                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="49" height="49" rx="3.5" fill="#D1433A" fill-opacity="0.1" stroke="#D1433A"/>
                  <path d="M25 16.25V33.75" stroke="#D1433A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M16.25 25H33.75" stroke="#D1433A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <input type="file" accept="audio/mp3,audio/*;capture=microphone" ref="file" @change="uploadFile" style="display:none;">
              <div class="d-flex justify-content-center align-items-center mt-3">
                <span v-for="(file,index) in tempSampleUpload.names" :key="index">{{ file }}<br></span>
              </div>
            </div>
          </div>
  <!--        <div class="invalid-feedback d-block" v-if="$v.tempSongUpload.file.$dirty">-->
  <!--            <span v-if="($v.tempSongUpload.file.required !== null && !$v.tempSongUpload.file.required)|| ($v.tempSongUpload.songs.required !== null && !$v.tempSongUpload.songs.required)">-->
  <!--              This is a required field.-->
  <!--            </span>-->
  <!--        </div>-->
          <div class="invalid-feedback d-block" v-if="$v.tempSampleUpload.file.$dirty">
            <span v-if="($v.tempSampleUpload.file.required !== null && !$v.tempSampleUpload.file.required) ||
                        ($v.tempSampleUpload.songs.required !== null && !$v.tempSampleUpload.songs.required) ||
                        ($v.tempSampleUpload.file.extension !== null && !$v.tempSampleUpload.file.extension)">
                This is a required field. File must be of .mp3 type.
            </span>
          </div>
  
          <div style="margin-top: 30px;">
            <div class="event-filter-widget with-app pg-song-artist-checkbox-card">
              <div class="widget-item">
                <div class="pg-item-title  with-checkbox">Privacy:</div>
                <div class="custom-control custom-checkbox" @click="setPrivacyValue('public')">
                  <input type="checkbox" id="public" value="public"  name="privacy"
                         class="custom-control-input" :checked="tempSampleUpload.privacy === 'public'"/>
                  <label for="public" class="custom-control-label check">Public</label>
                </div>
                <div class="custom-control custom-checkbox" @click="setPrivacyValue('private')">
                  <input type="checkbox" id="private" value="private" name="privacy"
                         class="custom-control-input" :checked="tempSampleUpload.privacy === 'private'"/>
                  <label for="private" class="custom-control-label check">Private</label>
                </div>
              </div>
            </div>
          </div>
          <div style="height: 1px; background: #E6EAF0; margin-top:20px;"></div>
          <div class="pb-4" style="margin-top:20px;">
            <div class=" cancel-btn-display">
              <button type="button" class="upload-file-cancel-btn">
                CANCEL
              </button>
            </div>
            <div class="" style="float: right">
              <div class=" save-btn-display">
                <button type="button" class="upload-file-save-btn">
                  SAVE TO DRAFT
                </button>
              </div>
              <div class="upload-btn-display">
                <button class="upload-file-upload-btn" type="submit">
                  <span >NEXT STEP</span>
                  <svg  class=" song-btn-icon-display upload" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.773189 13.5C0.848551 13.5 0.923913 13.4589 0.961595 13.4178L6.91522 7.21067C7.02826 7.08735 7.02826 6.92292 6.91522 6.7996L0.961596 0.59249C0.848552 0.469169 0.697828 0.469169 0.584784 0.59249C0.47174 0.71581 0.47174 0.880237 0.584784 1.00356L6.31232 7.00514L0.584783 13.0067C0.471739 13.1301 0.471739 13.2945 0.584783 13.4178C0.622464 13.5 0.697826 13.5 0.773189 13.5Z" fill="white" stroke="white" stroke-width="0.5"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </b-form>
      </div>
      <div class="col-sm-3 upload-file-p-padding" style="max-width: 19.55%">
        <div class="row" style="padding-top: 28px;">
          <div class="col-sm-12">
            <h5 class="upload-song-heading">
              {{((actionType === 'createSample')) ? 'Adding New Sample' : (actionType === 'createPodcast') ? 'Adding New Podcast' : ''}}
            </h5>
          </div>
          <div class="col-sm-12">
            <div>
              <p class="song-p">
                {{((actionType === 'createSample')) ? 'Choose your sample type (one shot or loop sample), upload files and go through setting steps' :
                  (actionType === 'createPodcast') ? 'Choose your Podcast type here (Music Mix or Talk Podcast), upload files and go through setting steps' : ''
                }}
              </p>
            </div>
          </div>
          <div class="col-sm-12 upload-file-p-position">
            <p class="Choose-File-Format-bottom-p">By uploading to AUDDIO, you agree to our <span style="color: #D1433A; margin-right: 2px; text-decoration: underline;">Terms of Use.</span>. You confirm that you own the copyrights or have explicit permission to distribute. Offending accounts will be deleted and banned</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {required, requiredIf} from "vuelidate/lib/validators";
  
  export default {
    name: "uploadSampleAndSamplePack",
    props: {
      actionType: {
        type: String,
        default: 'createSample'
      },
      type:{
        type:String,
        default: 'sample'
      },
      sampleType:{
        type:String,
        default: 'one_shot_sample'
      }
    },
    data() {
      return {
        fileName: [],
        tempSampleUpload: {
          privacy: 'public',
          type: this.type,
          sampleType: this.sampleType,
          file: null,
          songs:[],
          names:[]
        },
        emptyTempSampleUpload: {
          privacy: 'public',
          type: this.type,
          sampleType: this.sampleType,
          file: null,
          songs:[],
          names:[]
        },
      }
    },
  
    computed: {
      privacy() {
        return (this.tempSampleUpload.privacy === 'public')
      }
    },
    watch:{
      'tempSampleUpload.type': {
        handler: function (after) {
          this.$emit('changeType',after)
          this.resetTempUploadObject()
        },
        deep: true
      },
    },
    validations: {
        tempSampleUpload: {
        privacy: {
          required
        },
        type: {
          required
        },
        file: {
          required:requiredIf(function (nestedModel) {
            if(this.actionType === 'createSample'){
              return this.tempSampleUpload.type==='sample' || this.tempSampleUpload.type==='sample_pack';
            }
  
          }
        ),
          extension: value => {
            if (value) {
              const extension = value.name.split('.').pop().toLowerCase();
              return extension === 'mp3';
            }
            return true; // If no file is selected, skip the extension check
          }
        },
        // songs:{
        //   required:requiredIf(function (nestedModel) {
        //     if(this.actionType === 'createSampleAlbum') {
        //       return this.tempSampleUpload.type === 'sample'
        //     }
        //   })
        // }
      }
    },
    methods: {
      uploadFile(e) {
        for (const file of e.target.files) {
          const extension = file.name.split('.').pop().toLowerCase();
  
          if (this.tempSampleUpload.type === 'sample' || this.tempSampleUpload.type === 'sample_pack' || this.tempSampleUpload.type === 'licence') {
            if (extension === 'mp3') {
              this.tempSampleUpload.names.push(file.name);
              this.tempSampleUpload.file = file;
            } else {
              console.error('Invalid file type. Only .mp3 files allowed for song or podcast type.');
            }
          } else {
            if (extension === 'mp3') {
              this.tempSampleUpload.names.push(file.name);
              this.tempSampleUpload.songs.push(file);
            } else {
              console.error('Invalid file type. Only .mp3 files allowed for this type.');
            }
          }
        }
      },
      dragFile(e) {
        this.fileName = e.dataTransfer.files[0].name;
      },
      setPrivacyValue(value) {
        this.tempSampleUpload.privacy = value
      },
      nextStep(check, data) {
        this.$emit('nextStep', {check: check, data: data})
      },
      previousStep() {
        this.$emit('previousStep')
      },
      async uploadSong() {
        this.$emit('updateCurrentType', this.tempSampleUpload.type);
        this.$v.tempSampleUpload.$touch();
        if (this.$v.tempSampleUpload.$anyError)
        {
          return;
        }
        else
        {
          if(this.tempSampleUpload.type === 'sample'){
            this.$store.commit('SET_UPLOAD_SAMPLE_DATA', this.tempSampleUpload)
          } else if(this.tempSampleUpload.type === 'sample_pack') {
          }
          this.nextStep('upload', this.tempSampleUpload)
        }
      },
      resetTempUploadObject(){
        this.tempSampleUpload['file']=null
        this.tempSampleUpload['songs']=[]
        this.tempSampleUpload['names']=[]
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  
  .upload-product{
    height: 580px;
    .widget-item{
      .custom-control-label{
        padding: 5px 10px 5px;
        &:before,&:after{
          width: 30px!important;
          height: 30px!important;
        }
      }
    }
  }
  
  
  
  .song-card {
    border: 1px solid #e6eaf0;
    box-sizing: border-box;
    border-radius: 4px;
  }
  
  .song-card-body {
    padding: 10px 20px 10px 20px;
  }
  
  .song-btn {
    align-items: center;
    justify-content: center;
    font-weight: 500;
    transition: 0.3s all;
    width: 33%;
    border-radius: 2px 0px 0px 2px;
    background-color: #f5f7f9;
    color: #47505b;
    padding: 10px;
    font-size: 13px;
    border: none;
    font-family: "HelveticaNeueMedium";
    &.active{
      background-color: #D1433A;
      color: #fff;
      svg{
        path{
          stroke: #fff;
        }
      }
    }
    svg{
      path{
        stroke: black;
      }
    }
  }
  .upload-file-p-padding{
    .song-heading {
      letter-spacing: 0em !important;
    }
  }
  .album-btn {
    align-items: center;
    justify-content: center;
    background-color: #f5f7f9;
    color: #47505b;
    font-weight: 500;
    border-radius: 2px 0px 0px 2px;
    transition: 0.3s all;
    border: none;
    padding: 10px;
    width: 33%;
    font-size: 13px;
    font-family: "HelveticaNeueMedium";
    &.active{
      background-color: #D1433A;
      color: #fff;
      svg{
        path{
          stroke: #fff;
        }
      }
    }
  }
  
  .song-heading {
    font-family: "HelveticaNeueMedium";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.03em;
  }
  
  .song-p {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
    color: #47505B;
  }
  
  .product-song-p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #47505b;
  }
  
  .upload-file-border {
    height: 250px;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 10px rgba(71, 80, 91, 0.1);
  }
  
  .upload-file-icon {
    width: 50px;
    //background-color: #c4ccd6;
    height: 50px;
    border-radius: 5px;
    margin: 0 auto;
    cursor: pointer;
  }
  
  .plus-icon {
    padding-top: 10px;
    height: 40px;
    margin: 0 auto;
  }
  
  .upload-file-bottom-heading {
    font-family: "HelveticaNeueMedium";
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
  }
  
  .song-display {
    display: inline-block;
  }
  
  .song-btn-icon-display {
    &.upload{
      left: 2px;
      top: -1px!important;
      margin: 0 5px 0;
    }
    display: inline-block;
    position: relative;
    top: 4px;
    font-size: 20px;
  }
  
  .song-btn-margin {
    margin-left: 9px;
    position: relative;
    top: -3px;
  }
  
  .pg-event-filter-widget {
    display: flex;
    flex-direction: column;
  }
  
  .pg-event-filter-widget .pg-widget-item {
    flex-direction: column;
    padding: 13px 0px 17px;
  }
  
  .pg-event-filter-widget .pg-widget-item .pg-item-title.with-checkbox {
    margin-bottom: 13px;
    display: inline-block;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
  }
  
  .pg-event-filter-widget .pg-custom-control {
    margin-bottom: 7px;
    flex-direction: row;
    align-items: center;
    padding-left: 29px;
    cursor: pointer;
    display: inline-block;
  }
  
  .pg-event-filter-widget .pg-custom-control .pg-custom-control-input {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  
  .pg-event-filter-widget .pg-custom-control .pg-custom-control-label {
    cursor: pointer;
    margin-left: 13px;
  
    font-weight: bold;
    font-size: 13px;
  }
  
  .pg-event-filter-widget .pg-custom-control-label {
    font-family: HelveticaNeueMedium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #47505b;
  }
  
  .pg-custom-control .pg-custom-control-label:before {
    color: #fff;
    border: 1.3px solid #c4ccd6;
    background-color: #000000;
  }
  
  .pg-custom-control .pg-custom-control-label:after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgOCA2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KPHBhdGggZD0iTTAuOTAxMzY3IDIuODIyMjdMMi44MjY0MiA0Ljc0NzA3TDYuNzQyMzEgMC44MzAwNzgiIGZpbGw9IiM0MTZDRkYiLz4NCjxwYXRoIGQ9Ik0wLjkwMTM2NyAyLjgyMjI3TDIuODI2NDIgNC43NDcwN0w2Ljc0MjMxIDAuODMwMDc4IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+DQo8L3N2Zz4NCg==);
    background-color: transparent;
    border-radius: 0 !important;
  }
  
  .upload-file-cancel-btn {
    align-items: center;
    justify-content: center;
    font-weight: 700;
    transition: 0.3s all;
    background: #e6eaf0;
    color: #47505b;
    padding: 10px;
    font-size: 13px;
    border: none;
    font-family: "HelveticaNeueMedium";
    width: 100%;
    border-radius: 4px;
  }
  
  .upload-file-save-btn {
    align-items: center;
    justify-content: center;
    font-weight: 700;
    transition: 0.3s all;
    border: 1px solid #D1433A !important;
    background-color: #ffffff;
    color: #D1433A;
    padding: 9px 8px 9px 8px;
    font-size: 13px;
    border: none;
    font-family: "HelveticaNeueMedium";
    width: 100%;
    border-radius: 4px;
  }
  
  .upload-file-upload-btn {
    align-items: center;
    justify-content: center;
    font-weight: 700;
    transition: 0.3s all;
    background-color: #D1433A;
    color: #fff;
    padding: 10px;
    font-size: 13px;
    border: none;
    font-family: "HelveticaNeueMedium";
    width: 100%;
    border-radius: 4px;
  }
  
  .cancel-btn-display {
    display: inline-block;
    width: 130px;
  }
  
  .save-btn-display {
    display: inline-block;
    width: 130px;
    padding-right: 10px;
  }
  
  .upload-btn-display {
    display: inline-block;
    width: 130px;
  }
  
  .pg-song-artist-checkbox-card {
    border: none !important;
  }
  
  
  .pg-song-artist-checkbox-card .custom-control {
    width: 100px;
    margin-left: 21px;
    display: inline-block;
  }
  
  .pg-song-artist-checkbox-card .widget-item {
    display: inline-block !important;
    padding: 0;
  }
  
  .pg-song-artist-checkbox-card .pg-item-title {
    display: inline-block;
    position: relative;
    top: 1px;
    width: auto;
    color: #071526;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
  
  }
  
  .upload-btn-margin {
    margin-left: 16px;
  }
  
  .upload-file-p-padding {
    padding: 0 19px 0 21px;
    background-color: #F9FAFB;
  }
  
  .upload-file-p-position {
    position: absolute;
    bottom: 0;
  }
  
  .Choose-File-Format-bottom-p {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
    color: #8B949F;
    margin-bottom: 182px;
  }
  .upload-file-save-btn {
    align-items: center;
    justify-content: center;
    font-weight: 700;
    transition: 0.3s all;
    border: 1px solid #D1433A !important;
    background-color: #ffffff;
    color: #D1433A;
    padding: 8px 12px;
    font-size: 13px;
    border: none;
    width: 100%;
    border-radius: 4px;
    letter-spacing: 0.05em;
  }
  .upload-file-upload-btn {
    align-items: center;
    justify-content: center;
    font-weight: 700;
    transition: 0.3s all;
    background-color: #D1433A;
    color: #fff;
    padding: 10px;
    font-size: 13px;
    border: none;
    font-family: "HelveticaNeueMedium";
    width: 100%;
    border-radius: 4px;
    letter-spacing: 0.05em;
  }
  .cancel-btn-display {
    display: inline-block;
    width: 130px;
  }
  .save-btn-display {
    display: inline-block;
    width: 150px;
    padding-right: 10px;
  }
  .upload-btn-display {
    display: inline-block;
    width: 130px;
  }
  
  .pg-song-artist-checkbox-card{
    border: none !important;
  }
  
  
  .pg-song-artist-checkbox-card .custom-control {
    width: 100px;
    margin-left: 21px;
    display: inline-block;
  }
  
  .pg-song-artist-checkbox-card .widget-item{
    display: inline-block !important;
    padding: 0;
  }
  .pg-song-artist-checkbox-card .pg-item-title{
    display: inline-block;
    position: relative;
    top: 3px;
    width: auto;
    color: #071526;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
  
  }
  .upload-btn-margin{
    margin-left: 16px;
  }
  .upload-file-p-padding{
    padding: 0 19px 0 21px;
    background-color: #F9FAFB;
  }
  
  .upload-file-p-position{
    position: absolute;
    bottom: 0;
  }
  .Choose-File-Format-bottom-p{
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: #8B949F;
  }
  .upload-file-cancel-btn {
    align-items: center;
    justify-content: center;
    font-weight: 700;
    transition: 0.3s all;
    background: #e6eaf0;
    color: #47505b;
    padding: 10px;
    font-size: 13px;
    border: none;
    width: 100%;
    border-radius: 4px;
    letter-spacing: 0.05em;
  }
  .upload-song-heading{
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    color: #071526;
    opacity: 0.9;
  }
  .file-format-p{
    max-width: 80.45% !important;
    flex: 80.45% !important;
    padding-left: 20px;
    padding-right: 30px;
  }
  
  .song-btn.active span:before {
    color: #fff;
  }
  .album-btn.active span:before{
    color: #fff;
  }
  
  </style>
  