const fetchApis = async (apiFunctions, store, skeleton = true, spinner = false, spinnerFor = '') => {

  store.commit('SET_SKELETON', skeleton);

  if (spinner) {
    store.commit('SET_SPINNER', { display: spinner, for: spinnerFor });
  }

  console.log('apiFunctions', apiFunctions);

  try {
    return await Promise.all(apiFunctions.map((func) => func()));
  } catch (error) {
    console.log('error', error);
  } finally {
    store.commit('SET_SKELETON', false);
    store.commit('SET_SPINNER', false, '');
  }
};

export default fetchApis;