<template>
  <div class="header_wrapper" v-if="current_page">
    <section class="artist_middle_section artist_middle_section-artist">
      <!-- cover section -->
      <div class="artist_header" :style="'background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(8, 0, 0, 0.8) 100%), url(' + getCoverImage() + ')'">
        <div class="artist_infomation">
          <div class="artist_thumb cursor-pointer">
            <img v-if="current_page.artwork_url" @click="openImageAndCoverModel('page_profile')" width="160px" height="160px" :src="current_page.artwork_url" alt="artist">
            <span>
              <img :src="require(`@/assets/img/svg/check.svg`)" alt="icon">
            </span>
          </div>
          <div class="artist_details text-truncate">
            <div class="artist_label">
              {{ current_page && current_page.type ? current_page.type.charAt(0).toUpperCase()+current_page.type.slice(1) : 'Page Type' }}
            </div>
            <h2 class="">{{ current_page.title }}</h2>
            <div v-if="currentPageType" class="follower-following-stat">
              <div class="art-followers"><span>{{ city }}</span></div>
              <div class="art-followers"><span>{{ country }}</span></div>
            </div>
            <div v-else class="follower-following-stat">
              <div class="art-followers"><span>{{ followers }}</span> followers</div>
              <div class="sep-dot">
              </div>
              <div class="art-followers"><span>{{ getFollowing(current_page.followings_count ? current_page.followings_count : 0) }}</span> following</div>
            </div>
            <div v-if="currentPageType" class="flexy_aligned">
              <button v-if="current_page_user !== 'admin'" class="btn_follow  btn-join" :class="{'btn-joined': (current_page && current_page.join_status === 'approved')}" @click="joinPage(current_page.join_status)">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_21777_8360)">
                    <path d="M10.667 14V12.6667C10.667 11.9594 10.386 11.2811 9.88594 10.781C9.38585 10.281 8.70757 10 8.00033 10H3.33366C2.62641 10 1.94814 10.281 1.44804 10.781C0.947944 11.2811 0.666992 11.9594 0.666992 12.6667V14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5.66667 7.33333C7.13943 7.33333 8.33333 6.13943 8.33333 4.66667C8.33333 3.19391 7.13943 2 5.66667 2C4.19391 2 3 3.19391 3 4.66667C3 6.13943 4.19391 7.33333 5.66667 7.33333Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.333 5.33301V9.33301" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.333 7.33301H11.333" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_21777_8360">
                      <rect width="16" height="16" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <span>{{(current_page && current_page.join_status === 'approved') ? 'JOINED' : 'JOIN'}}</span>
              </button>
            </div>
            <div v-else class="flexy_aligned">
              <button @click="playAll" class="btn_play">
                <img :src="require(`@/assets/img/svg/play.svg`)" alt="">
                <span> PLAY ALL</span></button>
              <button v-if="current_page_user !== 'admin'" class="btn_follow" :class="{'active':followStatus}" @click="followOrUnfollowPage(followStatus?0:1,$event)">
                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M1 8.00938C3.757 8.00938 6 10.2547 6 13.0145C6 13.5671 6.448 14.0156 7 14.0156C7.552 14.0156 8 13.5671 8 13.0145C8 9.15056 4.86 6.00732 1 6.00732C0.448 6.00732 0 6.45579 0 7.00835C0 7.56092 0.448 8.00938 1 8.00938Z"
                      fill="#D1433A"></path>
                  <path
                      d="M1 2.00206C7.0655 2.00206 12 6.94164 12 13.0134C12 13.566 12.448 14.0144 13 14.0144C13.552 14.0144 14 13.566 14 13.0134C14 5.83801 8.1685 0 1 0C0.448 0 0 0.448461 0 1.00103C0 1.5536 0.448 2.00206 1 2.00206Z"
                      fill="#D1433A"></path>
                  <path
                      d="M1 14.0148C1.55228 14.0148 2 13.5666 2 13.0137C2 12.4609 1.55228 12.0127 1 12.0127C0.447715 12.0127 0 12.4609 0 13.0137C0 13.5666 0.447715 14.0148 1 14.0148Z"
                      fill="#D1433A"></path>
                </svg>
                <span>{{(!followStatus)?'Follow':((followStatus==='pending')?'Pending':"Followed")}}</span>
              </button>
            </div>
          </div>
          <div class="artist_cover_button" v-if="current_page_user === 'admin'">
            <label class="artist_cover" @click="openImageAndCoverModel('cover')">
              <img :src="require(`@/assets/img/svg/image.svg`)" alt="icon">
              <span>Change Cover</span>
            </label>
          </div>
        </div>
      </div>

      <!-- modal -->
      <upload-profile-and-cover
        :pic-type="profileAndCoverModelType"
        :show="profileAndCoverModel"
        :imageCheck="true"
        :coverImage="Image"
        @profileAndCoverValueChange="changeProfileAndCoverValue"
        @getImage="setImage"
      />
    </section>
  </div>
</template>

<script type="application/javascript">


import {
  addPost,
  followOrUnFollowPage, getChannelData,
  requestToJoinClubPage,
  uploadArtistCoverAndProfilePicture
} from "../../apis/APIs";
import {mapGetters} from "vuex";
import UploadProfileAndCover from "../Home/Profile/componenets/uploadProfileAndCover";
import createFormData from "../../mixins/common";

export default {
  props:['current_page_user'],
  data(){
    return{
      tempFollow:null,
      profileAndCoverModel: false,
      profileAndCoverModelType: '',
      hot_new_songs_array: [],
      selectedGenre: [],
      Image: {
        image: {
          type: String,
          default: null
        },
        defaultImage: {
          type: Boolean,
          default: false
        }
      }
    }
  },
  components: {
    UploadProfileAndCover
  },
  computed:{
    ...mapGetters({
      "user": "user_map_to_props"
    }),
    followStatus(){
      if(this.tempFollow !== null){
        return this.tempFollow
      }
      const {follow_action} = this.current_page
      if(follow_action){
        return follow_action
      }
      return !!(follow_action)
    },
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
    followers() {
      return this.$store.getters.getCurrentPageFollowersCount;
    },
    city() {
      return  (this.$store.getters.getCurrentPage && this.$store.getters.getCurrentPage.city && this.$store.getters.getCurrentPage.city.name) ? this.$store.getters.getCurrentPage.city.name : ''
    },
    country() {
      return  (this.$store.getters.getCurrentPage && this.$store.getters.getCurrentPage.country && this.$store.getters.getCurrentPage.country.name) ? ', '+this.$store.getters.getCurrentPage.country.name : ''
    },
    currentPageType() {
      return  (this.$store.getters.getCurrentPage && (this.$store.getters.getCurrentPage.type === 'clubroom' || this.$store.getters.getCurrentPage.type === 'partyroom'))
    }
  },

  mounted: async function () {
    await this.pageChannels();
  },

  methods: {
    getFollowing (following) {
      if (following > 999) {
        return following+'k';
      }
      else {
        return following;
      }
    },
    playAll() {
      if (this.hot_new_songs_array.length > 0) {
        this.$store.commit(
          "Set_Play_All_Songs_Array",
          this.hot_new_songs_array
        );
        this.$store.commit("SET_CURRENT_PLAYER", "footerPlayer");
        this.$store.commit("Set_Song_status", true);
      } else {
        console.log("No Hot Songs Yet");
      }
    },
    async pageChannels() {
      try {
        this.allChannels = [];
        this.dataLoaded = false;
        await this.$store.dispatch("loading", true);
        let data = await getChannelData("home", this.selectedGenre);
        this.allChannels = data.data.channels;
        this.hot_new_songs_array =
          data.data.channels.center[1] &&
          data.data.channels.center[1].alt_name === "hot-new-songs"
            ? data.data.channels.center[1].objects.data
            : [];
        this.dataLoaded = true;
        await this.$store.dispatch("loading", false);
      } catch (error) {
        console.error(error);
        await this.$store.dispatch("loading", false);
      }
    },

    getCoverImage() {
      return this.current_page.cover_photo_url;
    },
    changeProfileAndCoverValue(value, image) {
      this.profileAndCoverModel = value;
    },
    openImageAndCoverModel(type) {
      if (this.current_page_user === 'admin') {
        this.passImage(type);
        this.profileAndCoverModel = true
        this.profileAndCoverModelType = type
      }
    },
    async setImage(crop_image, image, file_nam, is_Selected) {
      if (this.profileAndCoverModelType === 'profile') {
       //
      } else {
        try {
          await this.$store.dispatch('loading', true)
          const formdata = new FormData();
          
          formdata.append((this.profileAndCoverModelType === 'page_profile') ? 'artwork' : 'cover_photo', crop_image )
          const page_id = this.$route.params.id
          const {data} = await uploadArtistCoverAndProfilePicture(formdata, page_id)
          const {information, success} = data || {}
          await this.$store.dispatch('loading', false)
          if (success === 1) {
            this.$store.commit('SET_PAGE_DATA', information)
            this.notificationToast(true, `${(this.profileAndCoverModelType === 'profile' || this.profileAndCoverModelType === 'page_profile') ? "Image" : "Cover"} updated`, `${(this.profileAndCoverModelType === 'profile' || this.profileAndCoverModelType === 'page_profile') ? "Image" : "Cover"} uploaded successfully`, 5000, 'success');
            if (!is_Selected) {
              await this.uploadPost(crop_image)
            }
          }
        } catch (e) {
          await this.$store.dispatch('loading', false)
          this.notificationToast( true,`${(this.profileAndCoverModelType === 'profile' || this.profileAndCoverModelType === 'page_profile') ? "Image" : "Cover"}`, e.message, 5000, 'error')
        }
      }
    },
    async followOrUnfollowPage(status,event) {
      if(event) event.stopPropagation()
      try {
        await this.$store.dispatch('loading', true)
        const formdata = new FormData();
        formdata.append('user_id', this.user.user_data.id);
        formdata.append('page_id', this.current_page.id);
        formdata.append('type', 'Page');
        formdata.append('action', status);
        const {data} = await followOrUnFollowPage(formdata)
        await this.$store.dispatch('loading', false)
        if(data.success){
          await this.notificationToast(true, "Page", `successfully ${(status === 1) ? 'Follow' : 'UnFollow'}`,  5000, 'success')
          this.$emit('refreshPagesList','getLikedPages')
          this.tempFollow = !!(status === 1)
          await this.$store.commit('FOLLOWERS_COUNT_CHANGE',(status === 1)?1:-1)
        }
        else{
          await this.notificationToast(true, "Page", data.message, 5000, 'error')
        }
      } catch (e) {
        await this.$store.dispatch('loading', false)
        await this.notificationToast(true, "Page", e.message, 5000, 'error')
      }
    },
    async uploadPost(image) {
      try {
        const form = new FormData();
        form.append('artwork[' + 0 + ']', image);
        form.append('full_content', '');
        form.append('category', 'image');
        form.append('access', 'public');
        form.append('global_type', 'page');
        const page_id = this.$route.params.id;
        form.append('page_id', page_id);

        console.log("this.profileAndCoverModelType uploadpost" , this.profileAndCoverModelType);
        
        (this.profileAndCoverModelType === 'profile' || this.profileAndCoverModelType === 'page_profile') ? form.append('post_type', 'profile_photo') : form.append('post_type', 'cover_photo')
        const {data} = await addPost(form);
        this.loading = false;
        const {success, message, information} = data || {};
        if (success === 1) {
          this.$store.commit('ADD_POST_OF_PAGE_IN_POSTS', information)
        } else {
          console.error(message, 'Error in creating post')
        }
      } catch (error) {
        console.error(error, 'Error in creating post')
      }
    },
    async joinPage(status) {
      try {
        await this.$store.dispatch('loading', true)
        const payload = {
          type: 'Page',
          type_id: this.$route.params.id,
          action: (status === 'approved') ? 0 : 1
        }
        const formData = createFormData(payload)
        const {data} = await requestToJoinClubPage(formData)
        const {message, success} = data || {}
        if (success === 1 ) {
          this.notificationToast(true, 'Club Page', 'Your request to join the club has been send. Owner will review and accept it.', 5000, 'success')
        } else {
          this.notificationToast(true,"Club Page", message, 5000, "error");
        }
        await this.$store.dispatch('loading', false)
      } catch (e) {
        await this.$store.dispatch('loading', false)
        this.notificationToast(true,"Club Page", 'Please try again latter', 5000, "error");
        console.error(e)
      }
    },
    passImage (type) {
      this.Image.image = (type === 'page_profile') ? this.current_page.artwork_url : this.current_page.cover_photo_url;
      this.Image.defaultImage = (this.current_page.page_cover === 1) ? true : false;
    }
  }
}
</script>
<style lang="scss" scoped>
.btn-joined {
  background: #D1433A!important;
}
.btn-join {
  border: 1px solid #D1433A;
  background: transparent;
}
.artist_header {
  height: 240px;
}
</style>
