<template>
  <div class="pl-0 rooms">
    <WidgetLoading v-if="isRoomsLoading" :is-loading="isRoomsLoading"/>
    <div v-else>
      <div class="media-tabs friends_tab">
        <ul id="myTab" role="tablist" class="nav nav-tabs5">
          <li class="nav-item">
            <a id="home-tab" data-toggle="tab" href="#all_rooms" role="tab" aria-controls="home" aria-selected="true" class="nav-link active" >
              ALL ROOM
              <div class="count-div">
              <span>
                {{getPageRooms.length}}
              </span>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a id="profile-tab" data-toggle="tab" href="#text_rooms" role="tab" aria-controls="profile" aria-selected="false" class="nav-link" >
              TEXT ROOMS
              <div class="count-div">
              <span>
                {{getPageTextRooms.length}}
              </span>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a id="profile-tab" data-toggle="tab" href="#audio_rooms" role="tab" aria-controls="profile" aria-selected="false" class="nav-link" >
              {{((current_page && current_page.type==='clubroom')?'Audio ROOMS':'video ROOMS')}}
              <div class="count-div">
              <span>
                {{getPageAudioVideoRooms.length}}
              </span>
              </div>
            </a>
          </li>
        </ul>
        <div class="create-room-button cursor-pointer">
          <div class="create-room-icon">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.99935 12.8337C10.221 12.8337 12.8327 10.222 12.8327 7.00033C12.8327 3.77866 10.221 1.16699 6.99935 1.16699C3.77769 1.16699 1.16602 3.77866 1.16602 7.00033C1.16602 10.222 3.77769 12.8337 6.99935 12.8337Z" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7 4.66699V9.33366" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4.66602 7H9.33268" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="create-room-text" @click="setActiveLaunchRoom()">
            Create Room
          </div>
        </div>
      </div>
      <div id="myTabContent" class="tab-content">
        <div id="all_rooms" role="tabpanel" aria-labelledby="home-tab" class="tab-pane fade mt_20 show active">
          <RoomListTile v-for="(room, index) in getPageRooms"
                        :key="index"
                        :room="room"
                        :room_type="'text'"
                        :room_id="index"
          />
        </div>
        <div id="text_rooms" role="tabpanel" aria-labelledby="profile-tab" class="tab-pane fade mt_20">
          <RoomListTile v-for="(room, index) in getPageTextRooms"
                        :key="index"
                        :room="room"
                        :room_type="'text'"
          />
        </div>
        <div id="audio_rooms" role="tabpanel" aria-labelledby="profile-tab" class="tab-pane fade mt_20">
          <RoomListTile v-for="(room, index) in getPageAudioVideoRooms"
                        :key="index"
                        :room="room"
                        :room_type="'audio'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RoomListTile from "./RoomListTile";
import WidgetLoading from "../../Common/Widget/WidgetLoading.vue";

export default {
  name: "Rooms",
  data(){
    return{
      isRoomsLoading:false
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    WidgetLoading,
    RoomListTile
  },
  watch: {
    current_page(val) {
    },
    loading(val){
      this.isRoomsLoading=this.loading
    }
  },
  mounted() {
    },
  computed:{
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
    getPageRooms() {
      return this.$store.getters.getPageRooms
    },
    getPageTextRooms() {
      return this.$store.getters.getPageTextRooms
    },
    getPageAudioVideoRooms() {
      return this.$store.getters.getPageAudioVideoRooms
    },
  },
  methods:{
    setActiveLaunchRoom()
    {
      this.$store.commit('SET_ACTIVE_LAUNCH_ROOM',true)
    },
  }
};
</script>

<style scoped lang="scss">
.rooms{
  position:relative;
  .loading-overlay{
    margin-top: 1.5rem;
  }
}
.media-tabs {
  .nav-tabs5 {
    .nav-item {
      .nav-link {
        display: flex;

        .count-div {
          background: #D1433A;
          border-radius: 2px;
          width: 28px;
          height: 18px;
          display: flex;
          justify-content: center;
          margin-left: 6px;
          align-items: center;

          span {
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            color: #FFFFFF;
          }
        }
      }
    }
  }
  .create-room-button {
    border: 1.5px solid #D1433A;
    border-radius: 2px;
    width: 210px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    .create-room-text {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      color: #D1433A;
      text-transform: uppercase;
      margin-left: 7px;
      margin-top: 1px;
    }

    &:hover {
      background: #D1433A;
      .create-room-icon {
        svg {
          path {
            stroke: #ffffff;
          }
        }
      }
      .create-room-text {
        color: #ffffff;
      }
    }
  }

}
</style>
