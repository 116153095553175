<template>
  <div>
    <LeftNav v-if="ShowLeftNav" category="navigation" type="home" class="left-bar-placement" />
    <div class="app-container" :class="[{ 'right-nav-space': rightBar }, custom_css_class]">
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
</template>
<script>
import LeftNav from "@/components/DesignGuide/Layout/LeftNav.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      currentUrl: "",
    };
  },
  computed: {
    ...mapGetters({
      form_data: "main_container_full_class_map_to_props",
      rightBar: "getRightBarValue",
    }),
    custom_css_class() {
      const { main_container_full_classes = "" } = this.form_data;

      return main_container_full_classes;
    },
    ShowLeftNav() {
      if (this.$route.meta.showLeftNav === false) {
        return false;
      } else {
        return true;
      }
    },
  },
  components: {
    LeftNav,
  },
  beforeMount() {
    this.showCon();
  },
  methods: {
    showCon: function () {
      this.currentUrl = this.$route.name;
    },
  },
};
</script>
<style scoped lang="scss">
.app-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 230px;
  margin-right: 260px;
}

.right-nav-space {
  padding-right: 280px;
}
</style>