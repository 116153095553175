<template>
  <div :class="[
    'tile-music-waves',
    fullWaves ? 'full-waves-width' : '',
    fullChatView ? 'full-chat-width' : '',
  ]">
    <div :class="[
      'cursor-pointer',
      fullWaves || fullChatView ? 'full-waves' : 'waveform-holder ',
    ]">

      <div class="wavess" :id="'wave' + latest_list_id" />
    </div>
    <div v-if="songReady < 100 && !audioExpired" class="rel fixAudioLodr">
      <div class="abs">
        <div class="spinner-border" role="status">
          <span class="visually-hidden" />
        </div>
      </div>
    </div>
    <div v-if="audioExpired" class="rel fixAudioLodr">
      <div class="abs abs1">
        <p>Audio expired</p>
      </div>
    </div>
  </div>
</template>
<script>
import WaveSurfer from "wavesurfer.js";
import moment from "moment";
export default {
  name: "TileViewWavesPlayer",
  props: {
    songLoaded: Boolean,
    isSongPlaying: Boolean,
    music: String,
    fullWaves: {
      type: Boolean,
      default: false,
    },
    fullChatView: {
      type: Boolean,
      default: false,
    },
    latest_list_id: {},
  },
  data() {
    return {
      wavesurfer: false,
      songReady: 0,
      time: 0,
      song_duration: 0,
      audioExpired: false,
    };
  },
  computed: {
    waveSurfer() {
      if (this.wavesurfer) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.time = this.wavesurfer.getCurrentTime();
      } else {
      }
      return 0;
    },
    currentPlayer() {
      return this.$store.getters.getCurrentPlayer;
    },
    songStatus() {
      return this.$store.getters.getSongsStatus;
    },
  },
  watch: {
    music() {
      this.createSurfer(this.music);
    },
    currentPlayer: function (newVal, oldVal) {
      if (newVal === "footerPlayer") {
        this.stopPlayer();
      }
    },
    songReady: {
      handler(newVal) {
        if (newVal === 100) {
          this.audioExpired = false;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.music) {
      this.createSurfer(this.music);
    }
    this.startExpirationTimer();
  },
  destroyed() {
    this.destroySong();
  },
  methods: {
    startExpirationTimer() {
      setTimeout(() => {
        if (this.songReady < 100) {
          this.audioExpired = true; 
        }
      }, 7000); 
    },
    setSongReady(val) {
      this.songReady = val;
    },

    createSurfer(song_url) {
      let self = this;

      if (self.wavesurfer) {
        self.wavesurfer.destroy();
        self.wavesurfer = null;
      }

      const waveContainer = document.querySelector(`#wave${self.latest_list_id}`);
      if (waveContainer) {
        waveContainer.innerHTML = ''; // Ensure no duplicate child divs
      }

      // Initialize WaveSurfer
      self.wavesurfer = WaveSurfer.create({
        container: `#wave${self.latest_list_id}`,
        barWidth: 2,
        barHeight: 7,
        barMinHeight: 7,
        barGap: 2,
        waveColor: self.fullWaves || self.fullChatView ? "#D1433A" : "#D1433A",
        cursorColor: "transparent",
        progressColor: "white",
        height: self.fullWaves || self.fullChatView ? 28 : 60,
        backend: "WebAudio",
        splitChannelsOptions: {
          progressColor: "#FFFFFF",
          waveColor: "#D1433A",
        },
      });

      // Load the audio file
      self.wavesurfer.load(song_url);

      // Event: When audio finishes
      self.wavesurfer.on("finish", () => {
        self.wavesurfer.pause();
        self.$emit("update:isSongPlaying", self.wavesurfer.isPlaying());
        self.$emit("updateIcon", false);
      });

      // Event: When WaveSurfer is ready
      self.wavesurfer.on("ready", () => {
        self.setSongReady(100);
      });

      // Event: On audio progress
      self.wavesurfer.on("audioprocess", () => {


        const duration = self.wavesurfer.getDuration();
        const currentTime = self.wavesurfer.getCurrentTime();

        if (duration > 0) {
          let progress = (currentTime / duration) * 100;
          const waveWidth = 420;
          const paddingLeft = 32;

          const progressInPixels = (progress / 100) * waveWidth + paddingLeft;

          // Update sender side background
          const senderElement = document
            .querySelector(`#wave${self.latest_list_id}`)
            .closest(".type-attachment.text-type-2.ml-auto")
            .querySelector(".main-div.sender-music-border");


          console.log("Sender element width:", senderElement.offsetWidth);
          console.log("Progress in pixels:", progressInPixels);

          // console.log("self.latest_list_id ",self.latest_list_id);
          // console.log("senderElement ",senderElement);

          if (senderElement) {
            senderElement.style.background = `linear-gradient(to right, #D1433A ${progressInPixels}px, #D1433A1A ${progressInPixels}px)`;
          }

          // Update receiver side background
          const receiverElement = document
            .querySelector(`#wave${self.latest_list_id}`)
            .closest(".chatAudioMain")
            .querySelector(".main-div.full-chat.receiver-music-border");



          // const smallChatReceiverElement = document
          //   .querySelector(`#wave${self.latest_list_id}`)
          //   .closest(".music-receive")
          //   .querySelector(".main-div.receiver-music.receiver-music-border");


          if (receiverElement) {
            receiverElement.style.background = `linear-gradient(to right, #D1433A ${progressInPixels}px, #E6EAF0 ${progressInPixels}px)`;
          }

          // else if (smallChatReceiverElement) {
          //   console.log('single chat');

          //   receiverElement.style.background = `linear-gradient(to right, #D1433A ${progressInPixels}px, #E6EAF0 ${progressInPixels}px)`;

          // }
        }
      });
    },

    destroySong() {
      this.wavesurfer.destroy();
    },
    async playPauseSong() {
      if (this.currentPlayer === "waveSurferPlayer") {
        if (this.wavesurfer.isPlaying()) {
          this.$emit("update:isSongPlaying", this.wavesurfer.isPlaying());
        }
        await this.wavesurfer.playPause();
      }
    },
    playSong() {
      if (this.currentPlayer === "waveSurferPlayer") {
        this.wavesurfer.play();
      }
    },
    pauseSong() {
      if (this.currentPlayer === "waveSurferPlayer") {
        this.wavesurfer.pause();
      }
    },
    stopPlayer() {
      this.wavesurfer.pause();
      this.$emit("updateIcon", false);
    },
    getCurrentTime() {
      if (this.wavesurfer) {
        this.time = this.wavesurfer.getCurrentTime();
      }
    },
  },
};
</script>
<style lang="scss">
.full-waves-width {
  width: 150px !important;
}

.full-chat-width {
  width: 420px !important;
}

.tile-music-waves {
  .waveform-holder {
    height: 30px;
    overflow: hidden;
  }

  .rel {
    .abs {
      .spinner-border {
        width: 1em !important;
        height: 1em !important;
        border: 0.2em solid !important;
        border-right-color: transparent !important;
      }
    }
  }
}
</style>
