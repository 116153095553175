
import {
  IS_ROOM_SCHEDULE,
  LAUNCH_ROOM,
  LAUNCH_ROOM_INFO,
  LAUNCH_ROOM_SCHEDULE,
  LAUNCH_ROOM_USERS, PAGE, PHONE_NO,
  ROOM_INFO_COVER,
  ROOM_INFO_DESCRIPTION,
  ROOM_INFO_NAME,
  ROOM_INFO_PRIVACY,
  ROOM_INFO_TYPE,
  ROOM_PAGE_ID, ROOM_PAGE_TYPE,
  ROOM_SCHEDULE_END_DATE,
  ROOM_SCHEDULE_END_TIME,
  ROOM_SCHEDULE_START_DATE,
  ROOM_SCHEDULE_START_TIME,
  ROOM_USERS_IDS, VERIFICATION_DESCRIPTION, VERIFICATION_FORM

} from "./types";
import * as Utils from "../components/utils/Utils";

const INITIAL_STATE = {
   [LAUNCH_ROOM]: {
     [LAUNCH_ROOM_INFO]:{
       [ROOM_INFO_NAME]:null,
       [ROOM_INFO_TYPE]:null,
       [ROOM_PAGE_TYPE]:null,
       [ROOM_PAGE_ID]:null,
       [ROOM_INFO_DESCRIPTION]:null,
       [ROOM_INFO_COVER]:null,
       [ROOM_INFO_PRIVACY]:null,
     },
     [LAUNCH_ROOM_USERS]:{
         [ROOM_USERS_IDS]:[]
     },
     [LAUNCH_ROOM_SCHEDULE]:{
         [IS_ROOM_SCHEDULE]:false,
         [ROOM_SCHEDULE_START_DATE]:null,
         [ROOM_SCHEDULE_START_TIME]:null,
         [ROOM_SCHEDULE_END_DATE]:null,
         [ROOM_SCHEDULE_END_TIME]:null,
     },
   },

};

export const state = { ...INITIAL_STATE };

export const actions = {

};

export const mutations = {
  SET_LAUNCH_ROOM_INFO(state, payload) {
    try{
      state[LAUNCH_ROOM][LAUNCH_ROOM_INFO][ROOM_INFO_NAME] = payload[ROOM_INFO_NAME]
      state[LAUNCH_ROOM][LAUNCH_ROOM_INFO][ROOM_INFO_TYPE] = payload[ROOM_INFO_TYPE]
      state[LAUNCH_ROOM][LAUNCH_ROOM_INFO][ROOM_PAGE_TYPE] = payload[ROOM_PAGE_TYPE]
      state[LAUNCH_ROOM][LAUNCH_ROOM_INFO][ROOM_PAGE_ID] = payload[ROOM_PAGE_ID]
      state[LAUNCH_ROOM][LAUNCH_ROOM_INFO][ROOM_INFO_DESCRIPTION] = payload[ROOM_INFO_DESCRIPTION]
      state[LAUNCH_ROOM][LAUNCH_ROOM_INFO][ROOM_INFO_COVER] = payload[ROOM_INFO_COVER]
      state[LAUNCH_ROOM][LAUNCH_ROOM_INFO][ROOM_INFO_PRIVACY] = payload[ROOM_INFO_PRIVACY]

    } catch (e) {
      console.error(e.message, 'Unable to set launch room info form data')
    }
  },
  SET_LAUNCH_ROOM_USERS(state, payload) {
    try{
      state[LAUNCH_ROOM][LAUNCH_ROOM_USERS][ROOM_USERS_IDS] = payload[ROOM_USERS_IDS]

    } catch (e) {
      console.error(e.message, 'Unable to set launch room users form data')
    }
  },
  SET_LAUNCH_ROOM_SCHEDULE(state, payload) {
    try{
      state[LAUNCH_ROOM][LAUNCH_ROOM_SCHEDULE][IS_ROOM_SCHEDULE] = payload[IS_ROOM_SCHEDULE]
      state[LAUNCH_ROOM][LAUNCH_ROOM_SCHEDULE][ROOM_SCHEDULE_START_DATE] = payload[ROOM_SCHEDULE_START_DATE]
      state[LAUNCH_ROOM][LAUNCH_ROOM_SCHEDULE][ROOM_SCHEDULE_START_TIME] = payload[ROOM_SCHEDULE_START_TIME]
      state[LAUNCH_ROOM][LAUNCH_ROOM_SCHEDULE][ROOM_SCHEDULE_END_DATE] = payload[ROOM_SCHEDULE_END_DATE]
      state[LAUNCH_ROOM][LAUNCH_ROOM_SCHEDULE][ROOM_SCHEDULE_END_TIME] = payload[ROOM_SCHEDULE_END_TIME]

    } catch (e) {
      console.error(e.message, 'Unable to set launch room schedule form data')
    }
  },
};

const getters = {
  getLaunchRoomData(state) {
    let _state = Utils.parseState(state);
    return _state[LAUNCH_ROOM];
  },

};

export default {
  state,
  actions,
  mutations,
  getters,
};
