<template>
  <div>
    <spinner v-if="getSpinnerData && (getSpinnerData.display && getSpinnerData.for === 'media_albums')" />
    <div v-else>
      <div v-if="mediaAlbumsList.length">
        <div class="photo-list">
          <media-albums-listing @getMediaAlbumsList="getMediaAlbums" :listData="mediaAlbumsList" :totalMediaAlbums="mediaAlbumsCount" @setShowMore="setShowMore" @showMore = "showMore"/>
          <spinner v-if="getSpinnerData && (getSpinnerData.display && getSpinnerData.for === 'show_more')" />
          <div v-else class="col-12" style="margin-bottom: 30px;">
            <div v-if="mediaAlbumsList.length >= 4 && mediaAlbumsList.length != mediaAlbumsCount && current_page" class="artist-show-more" @click="setLimit">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M6 1V11.0003" stroke="#071526" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"></path>
                <path d="M11 6L0.999665 6" stroke="#071526" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"></path>
              </svg>
              <span class="mt-1">
                SHOW MORE
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-content-here no-event-here no-page-style">
        <div class="no-detail-content">
          <div class="category-content-icon">
            <svg width="58" height="60" viewBox="0 0 58 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M45.9167 7.5H12.0833C9.41396 7.5 7.25 9.73858 7.25 12.5V47.5C7.25 50.2614 9.41396 52.5 12.0833 52.5H45.9167C48.586 52.5 50.75 50.2614 50.75 47.5V12.5C50.75 9.73858 48.586 7.5 45.9167 7.5Z" stroke="#47505B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M20.543 25C22.545 25 24.168 23.3211 24.168 21.25C24.168 19.1789 22.545 17.5 20.543 17.5C18.5409 17.5 16.918 19.1789 16.918 21.25C16.918 23.3211 18.5409 25 20.543 25Z" stroke="#47505B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M50.7487 37.5L38.6654 25L12.082 52.5" stroke="#47505B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <AddPhotosModal v-if="showModal" @closePhotoModel="closePhotoModel"/>
          <div class="no-detail-content-heading">
            You don’t have any Album yet
          </div>
          <div class="no-detail-content-subheading">
            Upload your Images or Videos now!
          </div>
          <div class="add-new-product-buttons button-photos">
            <button @click="openPhotoModel">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.25 8.75V11.0833C12.25 11.3928 12.1271 11.6895 11.9083 11.9083C11.6895 12.1271 11.3928 12.25 11.0833 12.25H2.91667C2.60725 12.25 2.3105 12.1271 2.09171 11.9083C1.87292 11.6895 1.75 11.3928 1.75 11.0833V8.75" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.91536 4.66667L6.9987 1.75L4.08203 4.66667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7 1.75V8.75" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Upload Media
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="application/javascript">
import mediaAlbumsListing from "@/components/Home/Profile/media/albums/mediaAlbumsListing.vue";
import { getAllMediaAlbumsOfUsers } from "../../../../../apis/APIs";
import AddPhotosModal from "../../AddPhotosModal.vue";
import Spinner from "../../../../Common/Spinners/spinner.vue";
import fetchApis from "../../../../../utils/DynamicFunctionToCallApis";
import arrayToObjectByKey from "../../../../../utils/ArrayToObjectByKey";

export default {
  name: "mediaAlbums",
  props:{
    user_id:{
      type:Number,
      Default:null,
    }
  },
  data() {
    return {
      mediaAlbumsList: [],
      offSetLimit: 0,
      limit: 4,
      loading: false,
      dataLoaded: false,
      mediaAlbumsCount: '',
      current_page: true,
      showModal: false
    }
  },

  computed: {
    getSpinnerData() {
      return this.$store.getters.getSpinner;
    }
  },

  components: {
    Spinner,
    mediaAlbumsListing,
    AddPhotosModal
  },

  mounted() {
    this.fetchAlbums()
  },

  methods: {
    fetchAlbums (spinnerFor = 'media_albums') {
       fetchApis([this.getMediaAlbums()], this.$store, false, true, spinnerFor).then((resp) => {
         const { information } = arrayToObjectByKey(resp, 'albums');
          this.mediaAlbumsList = information.data || [];
          this.mediaAlbumsCount = information.total || 0;
       }).catch(() => {
         console.log('error');
       });
    },

    getMediaAlbums(returnKey = 'albums') {
      return async () => {
        try {
          const { data } = await getAllMediaAlbumsOfUsers(this.offSetLimit, this.limit,(this.user_id) ? this.user_id :  null);
          return { [returnKey]: data || {} };
        } catch (error) {
          throw error;
        }
      }
    },

    setShowMore(){
      this.current_page = false
    },

    showMore(){
      this.current_page = true
    },

    setLimit() {
      this.limit = this.mediaAlbumsList.length + 4;
      this.fetchAlbums('show_more')
    },

    openPhotoModel(){
      this.showModal = true
    },

    closePhotoModel(){
      this.showModal = false
    },
  }
}
</script>

<style scoped>
.no-page-style {
  margin-top: 30px !important;
}
.button-photos{
  button {
    height: 40px !important;
    width: 230px !important;
  }
}
</style>
