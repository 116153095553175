<template>
  <div>
    <div class="music-container">
      <Header/>
      <div class="router-view-div">
        <router-view></router-view>
      </div>
      <MusicFooter v-if="(musicFooter || ShowMusicBar)"/>
      <GettingStartedStep1 v-if="is_welcome_page"/>
    </div>
    <div class="dock-wrapper"></div>
  </div>
</template>
<script>
import Header from "@/components/Common/Header.vue";
import MusicFooter from "@/components/Common/MusicFooter.vue";
import GettingStartedStep1 from "@/components/Home/GettingStarted/GettingStartedStep1.vue";
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      musicFooter: true
    };
  },
  computed: {
    ...mapGetters({
      "system_data": "system_map_to_props"
    }),
    is_welcome_page() {
      const {is_welcome_page} = this.system_data || {};

      return is_welcome_page ? true : false;
    },
    ShowMusicBar () {
      if(!this.$route.meta.ShowMusicFooter)
      {
        return false
      }
      return true
    },

  },
  props: {
    ShowMusicFooter: Boolean,
    ShowLeftNav: Boolean
  },
  components: {
    Header,
    MusicFooter,
    GettingStartedStep1,
  },
  created() {
    this.addEventListener();


  },
  methods: {
    addEventListener: function () {
      this.$root.$on('music_footer', (data) => {
        this.musicFooter = data;
      })
    },
//from music main container:
    toggleRightBar(val) {
      this.rightBar = val
    }

  }
};
</script>

<style lang="scss">
  .router-view-div {
    margin-top: 4.5rem;
  }
</style>
