<template>
  <div class="ag-container">
<!--    <MusicSubHeader/>-->
    <div class="container_xxl">
      <div class="artist_body mx-auto">
        <div class="panel_wrapper">
          <div class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">
                <img :src="require(`@/assets/img/work-icon.png`)">
                Work Settings
              </div>
            </div>
            <div class="artist-setting-panel">
              <div class="artist-general-setting">
                <div class="engage_settings_header">
                  <div>
                    <h3>Work Info Visibility: Public</h3>
                    <h6>If status set as Public, then anyone can access your Work info. </h6>
                  </div>
                  <div class="switch_sm_wrapper artist-drop">
                    <div :class="['custom-dropdown', (activeDropDown === 'work-visibility')? 'open':'']"
                         v-on:click="openDropDown('work-visibility')">
                      <div class="custom-dropdown-head d-flex flex-row align-items-center ht40drpdown" data-value="1">
                        <div class="svg-icon">
                          <svg style="width:16px !important; height:16px !important;" v-if="workVisibility === 'public'" width="16" height="16" viewBox="0 0 16 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M4.8 8.79999H0C0.198991 10.7802 1.12892 12.6151 2.60819 13.9464C4.08747 15.2778 6.00987 16.01 8 16C5.952 16 4.944 12.408 4.8 8.79999Z"
                              fill="#47505B"/>
                            <path
                              d="M0 7.20022H4.8C4.928 3.59222 5.936 0.000222633 8 0.000222633C6.00987 -0.00975066 4.08747 0.722422 2.60819 2.05377C1.12892 3.38512 0.198991 5.22004 0 7.20022Z"
                              fill="#47505B"/>
                            <path
                              d="M7.9999 14.4C8.3839 14.4 9.4479 12.48 9.5999 8.79999H6.3999C6.5519 12.48 7.6159 14.4 7.9999 14.4Z"
                              fill="#47505B"/>
                            <path
                              d="M7.9999 1.59998C7.6159 1.59998 6.5519 3.51998 6.3999 7.19998H9.5999C9.4479 3.51998 8.3839 1.59998 7.9999 1.59998Z"
                              fill="#47505B"/>
                            <path
                              d="M8 16C9.99013 16.01 11.9125 15.2778 13.3918 13.9464C14.8711 12.6151 15.801 10.7802 16 8.79999H11.2C11.056 12.408 10.048 16 8 16Z"
                              fill="#47505B"/>
                            <path
                              d="M8 -2.15078e-05C10.048 -2.15078e-05 11.056 3.59198 11.2 7.19998H16C15.801 5.21979 14.8711 3.38487 13.3918 2.05352C11.9125 0.722178 9.99013 -0.0099948 8 -2.15078e-05Z"
                              fill="#47505B"/>
                          </svg>
                          <svg style="width:16px !important; height:16px !important;" v-if="workVisibility === 'connections'" width="16" height="16" viewBox="0 0 16 12" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M7 12C7 12 6 12 6 11C6 10 7 7 11 7C15 7 16 10 16 11C16 12 15 12 15 12H7ZM11 6C11.7956 6 12.5587 5.68393 13.1213 5.12132C13.6839 4.55871 14 3.79565 14 3C14 2.20435 13.6839 1.44129 13.1213 0.87868C12.5587 0.316071 11.7956 0 11 0C10.2044 0 9.44129 0.316071 8.87868 0.87868C8.31607 1.44129 8 2.20435 8 3C8 3.79565 8.31607 4.55871 8.87868 5.12132C9.44129 5.68393 10.2044 6 11 6Z"
                              fill="#47505B"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M5.216 12C5.06776 11.6878 4.99382 11.3455 5 11C5 9.645 5.68 8.25 6.936 7.28C6.30909 7.08684 5.65595 6.99237 5 7C1 7 0 10 0 11C0 12 1 12 1 12H5.216Z"
                                  fill="#47505B"/>
                            <path
                              d="M4.5 6C5.16304 6 5.79893 5.73661 6.26777 5.26777C6.73661 4.79893 7 4.16304 7 3.5C7 2.83696 6.73661 2.20107 6.26777 1.73223C5.79893 1.26339 5.16304 1 4.5 1C3.83696 1 3.20107 1.26339 2.73223 1.73223C2.26339 2.20107 2 2.83696 2 3.5C2 4.16304 2.26339 4.79893 2.73223 5.26777C3.20107 5.73661 3.83696 6 4.5 6Z"
                              fill="#47505B"/>
                          </svg>
                          <svg style="width:16px !important; height:16px !important;" v-if="workVisibility === 'private'" width="16" height="16" viewBox="0 0 16 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12.6667 7.33331H3.33333C2.59695 7.33331 2 7.93027 2 8.66665V13.3333C2 14.0697 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0697 14 13.3333V8.66665C14 7.93027 13.403 7.33331 12.6667 7.33331Z"
                              fill="#47505B" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                            <path
                              d="M4.6665 7.33331V4.66665C4.6665 3.78259 5.01769 2.93475 5.64281 2.30962C6.26794 1.6845 7.11578 1.33331 7.99984 1.33331C8.88389 1.33331 9.73174 1.6845 10.3569 2.30962C10.982 2.93475 11.3332 3.78259 11.3332 4.66665V7.33331"
                              stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>
                        <span v-if="workVisibility === 'public'">Public</span>
                        <span v-if="workVisibility === 'connections'">Friends</span>
                        <span v-if="workVisibility === 'private'">Private</span>
                        <div class="for-drop d-flex ml-auto">
                          <div class="svg-icon">
                            <svg width="10" height="6" viewBox="0 0 10 6" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path class="c-line-1" d="M9 1L5.16868 5L1 1" stroke="#8B949F" stroke-width="1.3"
                                    stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div class="custom-dropdown-menu flex-column" style="width: inherit; z-index: 1;">
                        <div
                          :class="['custom-dropdown-item d-flex flex-row align-items-center',(workVisibility === 'public')?'active':'']"
                          data-value="0" v-on:click="updateDropDown('public', 'work-visibility')">
                          <div class="svg-icon">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path class="post-drop-svg"
                                    d="M4.8 8.79999H0C0.198991 10.7802 1.12892 12.6151 2.60819 13.9464C4.08747 15.2778 6.00987 16.01 8 16C5.952 16 4.944 12.408 4.8 8.79999Z"
                                    fill="#47505B"/>
                              <path class="post-drop-svg"
                                    d="M0 7.20022H4.8C4.928 3.59222 5.936 0.000222633 8 0.000222633C6.00987 -0.00975066 4.08747 0.722422 2.60819 2.05377C1.12892 3.38512 0.198991 5.22004 0 7.20022Z"
                                    fill="#47505B"/>
                              <path class="post-drop-svg"
                                    d="M7.9999 14.4C8.3839 14.4 9.4479 12.48 9.5999 8.79999H6.3999C6.5519 12.48 7.6159 14.4 7.9999 14.4Z"
                                    fill="#47505B"/>
                              <path class="post-drop-svg"
                                    d="M7.9999 1.59998C7.6159 1.59998 6.5519 3.51998 6.3999 7.19998H9.5999C9.4479 3.51998 8.3839 1.59998 7.9999 1.59998Z"
                                    fill="#47505B"/>
                              <path class="post-drop-svg"
                                    d="M8 16C9.99013 16.01 11.9125 15.2778 13.3918 13.9464C14.8711 12.6151 15.801 10.7802 16 8.79999H11.2C11.056 12.408 10.048 16 8 16Z"
                                    fill="#47505B"/>
                              <path class="post-drop-svg"
                                    d="M8 -2.15078e-05C10.048 -2.15078e-05 11.056 3.59198 11.2 7.19998H16C15.801 5.21979 14.8711 3.38487 13.3918 2.05352C11.9125 0.722178 9.99013 -0.0099948 8 -2.15078e-05Z"
                                    fill="#47505B"/>
                            </svg>
                          </div>
                          Public
                        </div>
                        <div
                          :class="['custom-dropdown-item d-flex flex-row align-items-center',(workVisibility === 'connections')?'active':'']"
                          data-value="1" v-on:click="updateDropDown('connections', 'work-visibility')">
                          <div class="svg-icon">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path class="post-drop-svg"
                                    d="M7 12C7 12 6 12 6 11C6 10 7 7 11 7C15 7 16 10 16 11C16 12 15 12 15 12H7ZM11 6C11.7956 6 12.5587 5.68393 13.1213 5.12132C13.6839 4.55871 14 3.79565 14 3C14 2.20435 13.6839 1.44129 13.1213 0.87868C12.5587 0.316071 11.7956 0 11 0C10.2044 0 9.44129 0.316071 8.87868 0.87868C8.31607 1.44129 8 2.20435 8 3C8 3.79565 8.31607 4.55871 8.87868 5.12132C9.44129 5.68393 10.2044 6 11 6Z"
                                    fill="#47505B"/>
                              <path fill-rule="evenodd" clip-rule="evenodd" class="post-drop-svg"
                                    d="M5.216 12C5.06776 11.6878 4.99382 11.3455 5 11C5 9.645 5.68 8.25 6.936 7.28C6.30909 7.08684 5.65595 6.99237 5 7C1 7 0 10 0 11C0 12 1 12 1 12H5.216Z"
                                    fill="#47505B"/>
                              <path class="post-drop-svg"
                                    d="M4.5 6C5.16304 6 5.79893 5.73661 6.26777 5.26777C6.73661 4.79893 7 4.16304 7 3.5C7 2.83696 6.73661 2.20107 6.26777 1.73223C5.79893 1.26339 5.16304 1 4.5 1C3.83696 1 3.20107 1.26339 2.73223 1.73223C2.26339 2.20107 2 2.83696 2 3.5C2 4.16304 2.26339 4.79893 2.73223 5.26777C3.20107 5.73661 3.83696 6 4.5 6Z"
                                    fill="#47505B"/>
                            </svg>

                          </div>
                          Friends
                        </div>
                        <div
                          :class="['custom-dropdown-item d-flex flex-row align-items-center',(workVisibility === 'private')?'active':'']"
                          data-value="2" v-on:click="updateDropDown('private', 'work-visibility')">
                          <div class="svg-icon">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path class="post-drop-svg post-drop-svg-1"
                                    d="M12.6667 7.33331H3.33333C2.59695 7.33331 2 7.93027 2 8.66665V13.3333C2 14.0697 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0697 14 13.3333V8.66665C14 7.93027 13.403 7.33331 12.6667 7.33331Z"
                                    fill="#47505B" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"/>
                              <path class="post-drop-svg-1"
                                    d="M4.6665 7.33331V4.66665C4.6665 3.78259 5.01769 2.93475 5.64281 2.30962C6.26794 1.6845 7.11578 1.33331 7.99984 1.33331C8.88389 1.33331 9.73174 1.6845 10.3569 2.30962C10.982 2.93475 11.3332 3.78259 11.3332 4.66665V7.33331"
                                    stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                          </div>
                          Private
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="engage_settings_header">
                  <div>
                    <h3>Project Duration</h3>
                    <h6>Specify whether you prefer short or long term projects?</h6>
                  </div>
                  <div class="switch_sm_wrapper artist-drop">
                    <div :class="['custom-dropdown', (activeDropDown === 'project-duration')? 'open':'']"
                         v-on:click="openDropDown('project-duration')">
                      <div class="custom-dropdown-head d-flex flex-row align-items-center ht40drpdown" data-value="1">
                        <div class="svg-icon">
                          <svg style="width:16px !important; height:16px !important;" v-if="projectDuration === 'no_preferences'"  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 1V0.75C6.99947 0.55125 6.92029 0.360791 6.77975 0.220253C6.63921 0.079715 6.44875 0.000527891 6.25 0H3.75C3.55125 0.000527891 3.36079 0.079715 3.22025 0.220253C3.07971 0.360791 3.00053 0.55125 3 0.75V1H0V3H3V3.25C3 3.662 3.337 4 3.75 4H6.25C6.662 4 7 3.662 7 3.25V3H16V1H7ZM4 3V1H6V3H4ZM13 5.75C12.9992 5.55133 12.9199 5.36102 12.7795 5.22054C12.639 5.08006 12.4487 5.00079 12.25 5H9.75C9.55133 5.00079 9.36102 5.08006 9.22054 5.22054C9.08006 5.36102 9.00079 5.55133 9 5.75V6H0V8H9V8.25C9 8.662 9.338 9 9.75 9H12.25C12.662 9 13 8.662 13 8.25V8H16V6H13V5.75ZM10 8V6H12V8H10ZM7 10.75C6.99921 10.5513 6.91994 10.361 6.77946 10.2205C6.63898 10.0801 6.44867 10.0008 6.25 10H3.75C3.55125 10.0005 3.36079 10.0797 3.22025 10.2203C3.07971 10.3608 3.00053 10.5512 3 10.75V11H0V13H3V13.25C3 13.662 3.337 14 3.75 14H6.25C6.662 14 7 13.662 7 13.25V13H16V11H7V10.75ZM4 13V11H6V13H4Z" fill="#47505B"/>
                          </svg>
                          <svg style="width:16px !important; height:16px !important;" v-if="projectDuration === 'short_and_long_projects'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.4444 11.5556V9.77778H3.55556V11.5556H12.4444ZM14.2222 0C14.6937 0 15.1459 0.187301 15.4793 0.520699C15.8127 0.854097 16 1.30628 16 1.77778V14.2222C16 14.6937 15.8127 15.1459 15.4793 15.4793C15.1459 15.8127 14.6937 16 14.2222 16H1.77778C1.30628 16 0.854097 15.8127 0.520699 15.4793C0.187301 15.1459 0 14.6937 0 14.2222V1.77778C0 0.791111 0.791111 0 1.77778 0H14.2222ZM12.4444 6.22222V4.44444H3.55556V6.22222H12.4444Z" fill="#47505B"/>
                          </svg>
                          <svg style="width:16px !important; height:16px !important;" v-if="projectDuration === 'long_projects'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.58667 3.92L10.6667 8L6.58667 12.0889L5.33333 10.8267L8.16 8L5.33333 5.17333L6.58667 3.92ZM1.77778 0C1.30628 0 0.854097 0.187301 0.520699 0.520699C0.187301 0.854097 -1.19209e-07 1.30628 -1.19209e-07 1.77778V14.2222C-1.19209e-07 14.6937 0.187301 15.1459 0.520699 15.4793C0.854097 15.8127 1.30628 16 1.77778 16H14.2222C14.6937 16 15.1459 15.8127 15.4793 15.4793C15.8127 15.1459 16 14.6937 16 14.2222V1.77778C16 1.30628 15.8127 0.854097 15.4793 0.520699C15.1459 0.187301 14.6937 0 14.2222 0H1.77778Z" fill="#47505B"/>
                          </svg>
                          <svg style="width:16px !important; height:16px !important;" v-if="projectDuration === 'short_projects'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.41333 3.92L5.33333 8L9.41333 12.0889L10.6667 10.8267L7.84 8L10.6667 5.17333L9.41333 3.92ZM14.2222 0C14.6937 0 15.1459 0.187301 15.4793 0.520699C15.8127 0.854097 16 1.30628 16 1.77778V14.2222C16 14.6937 15.8127 15.1459 15.4793 15.4793C15.1459 15.8127 14.6937 16 14.2222 16H1.77778C1.30628 16 0.854097 15.8127 0.520699 15.4793C0.187301 15.1459 0 14.6937 0 14.2222V1.77778C0 1.30628 0.187301 0.854097 0.520699 0.520699C0.854097 0.187301 1.30628 0 1.77778 0H14.2222Z" fill="#47505B"/>
                          </svg>
                        </div>
                        <span v-if="projectDuration === 'no_preferences'">No Preference</span>
                        <span v-if="projectDuration === 'short_and_long_projects'">Short and Long Projects</span>
                        <span v-if="projectDuration === 'long_projects'">Long Projects</span>
                        <span v-if="projectDuration === 'short_projects'">Short Projects</span>
                        <div class="for-drop d-flex ml-auto">
                          <div class="svg-icon">
                            <svg width="10" height="6" viewBox="0 0 10 6" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path class="c-line-1" d="M9 1L5.16868 5L1 1" stroke="#8B949F" stroke-width="1.3"
                                    stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div class="custom-dropdown-menu flex-column" style="width: inherit; z-index: 1;">
                        <div
                          :class="['custom-dropdown-item d-flex flex-row align-items-center',(projectDuration === 'no_preferences')?'active':'']"
                          data-value="0" v-on:click="updateDropDown('no_preferences', 'project-duration')">
                          <div class="svg-icon">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path class="post-drop-svg" d="M7 1V0.75C6.99947 0.55125 6.92029 0.360791 6.77975 0.220253C6.63921 0.079715 6.44875 0.000527891 6.25 0H3.75C3.55125 0.000527891 3.36079 0.079715 3.22025 0.220253C3.07971 0.360791 3.00053 0.55125 3 0.75V1H0V3H3V3.25C3 3.662 3.337 4 3.75 4H6.25C6.662 4 7 3.662 7 3.25V3H16V1H7ZM4 3V1H6V3H4ZM13 5.75C12.9992 5.55133 12.9199 5.36102 12.7795 5.22054C12.639 5.08006 12.4487 5.00079 12.25 5H9.75C9.55133 5.00079 9.36102 5.08006 9.22054 5.22054C9.08006 5.36102 9.00079 5.55133 9 5.75V6H0V8H9V8.25C9 8.662 9.338 9 9.75 9H12.25C12.662 9 13 8.662 13 8.25V8H16V6H13V5.75ZM10 8V6H12V8H10ZM7 10.75C6.99921 10.5513 6.91994 10.361 6.77946 10.2205C6.63898 10.0801 6.44867 10.0008 6.25 10H3.75C3.55125 10.0005 3.36079 10.0797 3.22025 10.2203C3.07971 10.3608 3.00053 10.5512 3 10.75V11H0V13H3V13.25C3 13.662 3.337 14 3.75 14H6.25C6.662 14 7 13.662 7 13.25V13H16V11H7V10.75ZM4 13V11H6V13H4Z" fill="#47505B"/>
                            </svg>
                          </div>
                          No Preference
                        </div>
                        <div
                          :class="['custom-dropdown-item d-flex flex-row align-items-center',(projectDuration === 'short_and_long_projects')?'active':'']"
                          data-value="0" v-on:click="updateDropDown('short_and_long_projects', 'project-duration')">
                          <div class="svg-icon">
                            <svg  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path class="post-drop-svg" d="M12.4444 11.5556V9.77778H3.55556V11.5556H12.4444ZM14.2222 0C14.6937 0 15.1459 0.187301 15.4793 0.520699C15.8127 0.854097 16 1.30628 16 1.77778V14.2222C16 14.6937 15.8127 15.1459 15.4793 15.4793C15.1459 15.8127 14.6937 16 14.2222 16H1.77778C1.30628 16 0.854097 15.8127 0.520699 15.4793C0.187301 15.1459 0 14.6937 0 14.2222V1.77778C0 0.791111 0.791111 0 1.77778 0H14.2222ZM12.4444 6.22222V4.44444H3.55556V6.22222H12.4444Z" fill="#47505B"/>
                            </svg>
                          </div>
                          Short and Long Projects
                        </div>
                        <div
                          :class="['custom-dropdown-item d-flex flex-row align-items-center',(projectDuration === 'long_projects')?'active':'']"
                          data-value="1" v-on:click="updateDropDown('long_projects', 'project-duration')">
                          <div class="svg-icon">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path class="post-drop-svg" d="M6.58667 3.92L10.6667 8L6.58667 12.0889L5.33333 10.8267L8.16 8L5.33333 5.17333L6.58667 3.92ZM1.77778 0C1.30628 0 0.854097 0.187301 0.520699 0.520699C0.187301 0.854097 -1.19209e-07 1.30628 -1.19209e-07 1.77778V14.2222C-1.19209e-07 14.6937 0.187301 15.1459 0.520699 15.4793C0.854097 15.8127 1.30628 16 1.77778 16H14.2222C14.6937 16 15.1459 15.8127 15.4793 15.4793C15.8127 15.1459 16 14.6937 16 14.2222V1.77778C16 1.30628 15.8127 0.854097 15.4793 0.520699C15.1459 0.187301 14.6937 0 14.2222 0H1.77778Z" fill="#47505B"/>
                            </svg>

                          </div>
                          Long Projects
                        </div>
                        <div
                          :class="['custom-dropdown-item d-flex flex-row align-items-center',(projectDuration === 'short_projects')?'active':'']"
                          data-value="2" v-on:click="updateDropDown('short_projects', 'project-duration')">
                          <div class="svg-icon">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path class="post-drop-svg" d="M9.41333 3.92L5.33333 8L9.41333 12.0889L10.6667 10.8267L7.84 8L10.6667 5.17333L9.41333 3.92ZM14.2222 0C14.6937 0 15.1459 0.187301 15.4793 0.520699C15.8127 0.854097 16 1.30628 16 1.77778V14.2222C16 14.6937 15.8127 15.1459 15.4793 15.4793C15.1459 15.8127 14.6937 16 14.2222 16H1.77778C1.30628 16 0.854097 15.8127 0.520699 15.4793C0.187301 15.1459 0 14.6937 0 14.2222V1.77778C0 1.30628 0.187301 0.854097 0.520699 0.520699C0.854097 0.187301 1.30628 0 1.77778 0H14.2222Z" fill="#47505B"/>
                            </svg>
                          </div>
                          Short Projects
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="engage_settings_header">
                  <div>
                    <h3>Earnings Privacy</h3>
                    <h6>Specify whether your hourly rate is made public.</h6>
                  </div>
                  <div class="switch_sm_wrapper">
                    <input type="checkbox" id="switch2" v-model="earningPrivacy">
                    <label class="switch_sm" for="switch2">
                      <div class="switch_toggler"></div>
                    </label>
                  </div>
                </div>
                <div class="experience-level" style="border-bottom: 1px solid #E6EAF0;">
                    <div class="experience-head">
                      Experience Level
                    </div>
                    <div class="row mt-3 mb-2" style="margin-right:-19px !important;">
                      <div class="col-12">
                        <div class="expert-label">
                          <div class="form-group">
                            <ul>
                              <li>
                                <div class="expert-radio-2">
                                  <input type="radio" id="Entry" name="radio-group" value="entry" v-model="selected_expertise_level" :checked="selected_expertise_level === 'entry'">
                                  <label  class="label-text" for="Entry">Entry</label>
                                  <div class="label-description-style">I am at the beginning stages of understanding and development</div>
                                </div>
                              </li>
                              <li>
                                <div class="expert-radio-2">
                                  <input type="radio" id="Intermediate" name="radio-group" value="intermediate" v-model="selected_expertise_level" :checked="selected_expertise_level === 'intermediate'">
                                  <label  class="label-text" for="Intermediate">Intermediate</label>
                                  <div class="label-description-style">I have a strong understanding and experience in this field</div>
                                </div>
                              </li>
                              <li>
                                <div class="expert-radio-2">
                                  <input type="radio" id="Expert" name="radio-group" value="expert" v-model="selected_expertise_level" :checked="selected_expertise_level === 'expert'">
                                  <label class="label-text" for="Expert">Expert</label>
                                  <div class="label-description-style">I have a deep understanding with years of experience in this field</div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="availability mt-4 mb-2">
                  <div class="experience-head  mb-4">
                    Availability
                  </div>
                  <div class="engage_settings_header">
                    <div>
                      <h3>Music Production Jobs</h3>
                      <h6>Are you available for Music Production Jobs? Types of Jobs offered are dependent on your About & Skills <br>page settings.</h6>
                    </div>
                    <div class="switch_sm_wrapper">
                      <input type="checkbox" id="switch3" v-model="music_production_availability">
                      <label class="switch_sm" for="switch3">
                        <div class="switch_toggler"></div>
                      </label>
                    </div>
                  </div>
                  <div class="engage_settings_header">
                    <div>
                      <h3>Bookings</h3>
                      <h6>Are you available for Bookings? Types of Bookings  offered are dependent on your About & Skills<br>page settings.</h6>
                    </div>
                    <div class="switch_sm_wrapper">
                      <input type="checkbox" id="switch4" v-model="availability_bookings">
                      <label class="switch_sm" for="switch4">
                        <div class="switch_toggler"></div>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end mt-3">
                <button class="prime_button" type="button" @click="pageWorkSetting">Save Changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import MusicSubHeader from '@/components/PageManager/PMSubHeader.vue'

export default {
  data() {
    return {
      expertise_level:'',
      earningPrivacy: false,
      deletePage: false,
      activeDropDown: '',
      last_dropdown: '',
      workVisibility: 'public',
      projectDuration: 'short_projects',
      page_id: null,
      earning_key : 1,
      selected_expertise_level:'entry',
      music_production_availability: false,
      availability_bookings:false
    }
  },
  components: {
    // MusicSubHeader,
  },
  computed: {
    currentPage(){
      return this.$store.getters.getCurrentPage
    }
  },
  watch:{
    currentPage(val){
      if(val){
        this.initData()
      }
    }
  },
  async mounted(){
    await  this.initData()
  },
  methods: {
    closeModal: function () {
      this.deletePage = false;
    },
    openDropDown: function (active_dropdown) {
      if (this.last_dropdown !== active_dropdown) {
        this.last_dropdown = active_dropdown;
        this.activeDropDown = active_dropdown;

      } else {
        this.activeDropDown = '';
        this.last_dropdown = '';
      }
    },
    updateDropDown: function (selected, changedFor) {
      if (changedFor === 'work-visibility') {
        this.workVisibility = selected;
      }
      if (changedFor === 'project-duration') {
        this.projectDuration = selected;
      }
    },
    async initData(){
      if(Object.keys(this.currentPage).length !== 0){
        this.earningPrivacy = (this.currentPage.earning_privacy === 1)
        this.workVisibility = this.currentPage.work_info_visibility;
        this.projectDuration = this.currentPage.project_duration;
        this.selected_expertise_level = this.currentPage.experience_level;
        this.music_production_availability = (this.currentPage.music_production_jobs === 1);
        this.availability_bookings = (this.currentPage.bookings === 1);
        this.page_id = this.currentPage.id;
      }
    },
    pageWorkSetting(){
      let payload = {
        body: {
          earning_privacy : (this.earningPrivacy === true) ? 1 : 0,
          work_info_visibility : this.workVisibility,
          project_duration : this.projectDuration,
          experience_level : this.selected_expertise_level,
          music_production_jobs : (this.music_production_availability === true) ? 1 : 0,
          bookings : (this.availability_bookings === true) ? 1 : 0,
        },
        page_id : this.page_id
      }
      this.$store.dispatch('pageWorkSetting', payload)
    }
  }
}
</script>
<style scoped>
.experience-head{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}
.expert-radio-2{
  background-color: #fff;
  border: 1px solid #E6EAF0;
  border-radius: 4px;
  position: relative;
  padding: 10px;
  transition: none;
  height:90px;
  width:230px;
}
.expert-label ul li {
  width: 230px;
  height: 90px;
  margin-right: 20px;
}
.label-text{
  font-size: 13px !important;
  font-weight: 500;
  color: #8B949F;
  padding-left: 4px;
  text-align: center;
}
.label-description-style{
  padding-top:4px !important;
  padding-left:10px !important;
  font-size:12px !important;
  color: #8B949F !important;
  font-weight: normal;
  line-height: 14px;
}
input[type=checkbox], input[type=radio] {
  width: 10px !important;
  height: 10px !important;
}
.artist-drop .custom-dropdown {
  width: 240px !important;
  height: 30px !important;
}
.artist-drop .custom-dropdown.open {
  position: relative;
}
.artist-drop .custom-dropdown.open .custom-dropdown-menu {
  display: flex;
}
.artist-drop .custom-dropdown .custom-dropdown-menu .custom-dropdown-item{
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #333333 !important;
}
.artist-drop .custom-dropdown .custom-dropdown-menu .custom-dropdown-item.active{
  background-color: #D1433A1A !important;
  color: #D1433A !important;
}
.artist-drop .custom-dropdown .custom-dropdown-menu .custom-dropdown-item .for-drop .ml-auto{
  display: none;
}
.artist-drop .custom-dropdown .custom-dropdown-menu {
  top : 29px !important;
}
.artist-drop .custom-dropdown .custom-dropdown-menu .custom-dropdown-item .svg-icon {
  margin-right: 8px !important;
  margin-left: 1px !important;
}
.artist-drop .custom-dropdown .custom-dropdown-menu .custom-dropdown-item.active .post-drop-svg {
  fill: #D1433A !important;
}
.artist-drop .custom-dropdown .custom-dropdown-menu .custom-dropdown-item.active .svg-icon .c-line-1 {
  stroke: #D1433A
}
.artist-drop .custom-dropdown .custom-dropdown-menu .custom-dropdown-item:hover {
  background-color: #D1433A1A !important;
}
</style>
<style lang="scss" scoped>
.ag-container {
  width: 100%;
  padding: 0;
}
.panel_wrapper {
  margin-top: 59px;
  margin-top: 130px;
  margin-bottom: 70px;
}
</style>
