<template>
  <div class="news-feed-layer" v-if="checkRegister.is_welcome_page"></div>
  <div class="news-feed-container" v-else>
    <div class="row"
         v-if="!getTrendingViewCheck"
    >
      <div class="col">
        <h1 class="Page_title">News Feed</h1>
      </div>
    </div>
    <b-skeleton-wrapper :loading="checkIfSkeleton">
      <template #loading>
        <SkeletonLoader v-for="item in 5" :key="item" :type="'news-feed'" />
      </template>
      <div class="feed_wrapper">
        <div class="center_centerbar_wraper_home" v-if="!getTrendingViewCheck">
          <div class="artist-main-premium-btns">
            <CreatePost :picture="picture" news-feed />
            <div class="btns-premium">
              <div class="news-feed-radio">
                <input
                  type="radio"
                  id="radio-one-main"
                  name="switch-one-main"
                  value="yes"
                  checked="checked"
                />
                <label for="radio-one-main">Main</label>
                <input
                  type="radio"
                  id="radio-two-main"
                  name="switch-one-main"
                  value="no"
                />
                <label for="radio-two-main">Premium</label>
              </div>
              <div class="artist-music-profile-select">
                <div class="main-poset-select-box">
                  <ul>
                    <li
                      class="cursor-pointer"
                      v-on:click="optionOptions = true"
                    >
                      {{ filterName }}
                    </li>
                  </ul>
                  <span>
                      <svg
                        width="7"
                        height="12"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L6.09612 5.67901L1 10.77"
                          stroke="#D1433A"
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                </div>
              </div>
            </div>
          </div>

          <div class="edit-share-section fixEditShareSection" >
            <div class="detail-tab">
              <nav>
                <div class="nav top-tab nav-tabs1" id="nav-tab" role="tablist">
                  <a
                    :class="[
                    'nav-item nav-link',
                    active_tab === 'discover_posts' ? 'active' : '',
                  ]"
                    id="nav-home-tab"
                    data-toggle="tab"
                    href="#nav-home"
                    role="tab"
                    aria-controls="nav-home"
                    :aria-selected="active_tab === 'discover_posts'
                  "
                    @click="setActiveTab('discover_posts')"
                  >DISCOVER POSTS</a
                  >
                  <a
                    :class="[
                    'nav-item nav-link',
                    active_tab === 'liked_posts' ? 'active' : '',
                  ]"
                    ref="liked_posts"
                    id="nav-profile-tab"
                    data-toggle="tab"
                    href="#nav-profile"
                    role="tab"
                    aria-controls="nav-profile"
                    :aria-selected="active_tab === 'liked_posts' ? true : false"
                    @click="setActiveTab('liked_posts')"
                  >LIKED POSTS</a
                  >
                  <a
                    :class="[
                    'nav-item nav-link',
                    active_tab === 'my_posts' ? 'active' : '',
                  ]"
                    id="nav-contact-tab"
                    data-toggle="tab"
                    href="#nav-contact"
                    role="tab"
                    aria-controls="nav-contact"
                    :aria-selected="active_tab === 'my_posts' ? true : false"
                    @click="setActiveTab('my_posts')"
                  >MY POSTS
                  </a>
                  <!-- Save posts -->
                  <a
                    :class="[
                    'nav-item nav-link',
                    active_tab === 'save_posts' ? 'active' : '',
                  ]"
                    ref="saved_posts"
                    id="nav-contact-tab"
                    data-toggle="tab"
                    href="#nav-contact"
                    role="tab"
                    aria-controls="nav-contact"
                    :aria-selected="active_tab === 'save_posts' ? true : false"
                    @click="setActiveTab('save_posts')"
                  >SAVED POSTS
                  </a>
                </div>
              </nav>
            </div>
          </div>

          <div class="tab-content" id="nav-tabContent" >
            <!--DISCOVER POSTS START -->
            <div v-if="active_tab === 'discover_posts'"
              :class="['tab-pane fade', active_tab === 'discover_posts' ? 'active show' : '']"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <spinner v-if="getSpinnerData && (getSpinnerData.display && getSpinnerData.for ===  active_tab)" />
              <div v-else>
                <NoDataComponent
                  v-if="!post_list.length"
                  header="There are no Posts yet"
                  content="Post your first article, message or image in your Post Feed!"
                />
                <!-- Post List -->
                <PostDetail
                  v-else
                  v-for="(eachOp, index) in post_list"
                  :key="'post-detail' + eachOp.id"
                  :index="index"
                  :item="eachOp"
                  :activeTab="active_tab"
                />
                <!-- Post End -->
                <spinner v-if="getSpinnerData && (getSpinnerData.display && getSpinnerData.for ===  'show_more')" />
                <ShowMore @emitposts="showMorePosts('discover_posts')" v-if="is_more_posts && !getSpinnerData && post_list.length" />
              </div>
            </div>
            <!-- DISCOVER POSTS END -->

            <!--LIKED POSTS START -->
            <div
              v-if="active_tab === 'liked_posts'"
              :class="['tab-pane fade', active_tab === 'liked_posts' ? 'active show' : '']"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <spinner v-if="getSpinnerData && (getSpinnerData.display && getSpinnerData.for ===  active_tab)" />
              <div v-else>
                <!-- Post List -->
                <NoDataComponent
                  v-if="!post_list.length"
                  header="There are no Posts yet"
                  content="Post your first article, message or image in your Post Feed!"
                />
                <PostDetail
                  v-else
                  v-for="(eachOp, index1) in post_list"
                  :key="'post-detail' + eachOp.id"
                  :index="index1"
                  :item="eachOp"
                />
                <spinner v-if="getSpinnerData && (getSpinnerData.display && getSpinnerData.for ===  'show_more')" />
                <ShowMore @emitposts="showMorePosts('liked_posts')" v-if="is_more_posts && !getSpinnerData && post_list.length" />
                <!-- Post End -->
              </div>
            </div>
            <!--LIKED POSTS END -->

            <!-- MY_POSTS POSTS START -->
            <div
              v-if=" active_tab === 'my_posts'"
              :class="['tab-pane fade', active_tab === 'my_posts' ? 'active show' : '']"
              id="nav-contact"
              role="tabpanel"
              aria-labelledby="nav-contact-tab"
            >
              <spinner v-if="getSpinnerData && (getSpinnerData.display && getSpinnerData.for ===  active_tab)" />
              <div v-else>
                <NoDataComponent
                  v-if="!post_list.length"
                  header="There are no Posts yet"
                  content="Post your first article, message or image in your Post Feed!"
                />
                <PostDetail
                  v-else
                  v-for="(eachOp, index2) in post_list"
                  :key="'post-detail' + eachOp.id"
                  :index="index2"
                  :item="eachOp"
                  :activeTab="active_tab"
                />
                <spinner v-if="getSpinnerData && (getSpinnerData.display && getSpinnerData.for ===  'show_more')" />
                <ShowMore @emitposts="showMorePosts('my_posts')" v-if="is_more_posts && !getSpinnerData && post_list.length" />
              </div>
            </div>
            <!-- MY_POSTS POSTS END -->

            <!-- SAVED_POSTS POSTS START -->
            <div
              v-if=" active_tab === 'save_posts'"
              :class="['tab-pane fade', active_tab === 'save_posts' ? 'active show' : '']"
              id="nav-contact"
              role="tabpanel"
              aria-labelledby="nav-contact-tab"
            >
              <spinner v-if="getSpinnerData && (getSpinnerData.display && getSpinnerData.for ===  active_tab)" />
              <div v-else>
                <NoDataComponent
                  v-if="!post_list.length"
                  img='/css/emojis/message-square.png'
                  header="There are no Posts yet"
                  content="Post your first article, message or image in your Post Feed!"
                />
                <PostDetail
                  v-else
                  v-for="(eachOp, index2) in post_list"
                  :key="'post-detail' + eachOp.id"
                  :index="index2"
                  :item="eachOp"
                  :activeTab="active_tab"
                />
                <spinner v-if="getSpinnerData && (getSpinnerData.display && getSpinnerData.for ===  'show_more')" />
                <ShowMore @emitposts="showMorePosts('save_posts')" v-if="is_more_posts && !getSpinnerData && post_list.length" />
              </div>
            </div>
            <!-- SAVED_POSTS POSTS END -->
          </div>

        </div>
        <TrendingArticles
          v-if="getTrendingViewCheck"
          last-url="News Feed"
          @showMoreTrending="showMoreTrending"
        />
        <div :class="{'news-feed-right-side-section': true, 'mt-4-5': getTrendingViewCheck}">
          <NewsFeedOtherData :channel-data="channelsData" />
        </div>
      </div>
    </b-skeleton-wrapper>
    <OptionModal
      v-if="optionOptions"
      @filter="filterPosts"
      @closeModal="closeModal"
    />
  </div>
</template>


<script type="application/javascript">
import { mapActions, mapGetters, mapMutations } from "vuex";
import CreatePost from "./NewsFeed/CreatePost";
import NewsFeedOtherData from "./NewsFeed/NewsFeedOtherData";
import PostDetail from "./NewsFeed/PostItem/PostDetail";
import OptionModal from "../../components/Home/Profile/OptionModal.vue";
import TrendingArticles from "./Article/TrendingArticles";
import ShowMore from "./NewsFeed/components/ShowMore.vue";
import NoDataComponent from "@/components/Common/NoDataComponent.vue";
import SkeletonLoader from "../Common/Loaders/SkeletonLoader.vue";
import fetchApis from "../../utils/DynamicFunctionToCallApis";
import { getActivities, getChannelData, getWhoToFollowData } from "../../apis/APIs";
import arrayToObjectByKey from "../../utils/ArrayToObjectByKey";
import Spinner from "../Common/Spinners/spinner.vue";

export default {
  data() {
    return {
      message: "",
      addPhoto: false,
      register_error: false,
      picture: "",
      optionOptions: false,
      trending_limit : 10,
      filterName: 'All',
      channelsData: [],
    };
  },
  watch: {
    user() {
      this.getUserPicture();
    },
  },
  components: {
    Spinner,
    SkeletonLoader,
    CreatePost,
    PostDetail,
    NewsFeedOtherData,
    OptionModal,
    TrendingArticles,
    ShowMore,
    NoDataComponent
},
  computed: {
    ...mapGetters({
      form_data: "home_map_to_props",
      user: "user_map_to_props",
      checkRegister:'system_map_to_props',
    }),
    data() {
      const { data = [] } = this.form_data || {};

      return data && data.length ? data : [];
    },

    post_list() {
      return this.$store.getters.getPosts;
    },

    is_more_posts() {
      const allPosts = this.$store.getters.getTotalPosts; // all posts which are available in DB
      const PostsLenght = this.post_list.length; // postlist array
      // return PostsLenght >= this.$store.getters.getPostsLimit || PostsLenght < allPosts;
      return PostsLenght < allPosts;
    },

    previous_route() {
      return this.$store.getters.getArticlePreviousRoute;
    },

    getTrendingViewCheck() {
      return this.$store.getters.getTrendingViewCheck;
    },

    active_tab: {
      set(value) {
        this.$store.commit("SET_POST_ACTIVE_TAB", value);
      },
      get() {
        return this.$store.getters.getPostActiveTab;
      },
    },

    checkIfSkeleton() {
      return this.$store.getters.getSkeleton;
    },

    getSpinnerData() {
      return this.$store.getters.getSpinner;
    },
  },
  mounted() {
    this.$store.commit('SET_POST_TYPE', '')

    if (this.previous_route === "home.favorites") {

      this.$refs.saved_posts.click();
      this.$store.commit("SET_PREVIOUS_ROUTE", "");

    } else {

      this.active_tab = "discover_posts";
      fetchApis([this.showPosts("discover_posts"), this.fetchTrendingList(0, 10), this.fetchCountries(), this.fetchActivities(), this.fetchWhoToFollow()], this.$store).then((res) => {
        if (res && res.length > 0) {
          const { channels } = arrayToObjectByKey(res, 'who_to_follow');
          this.channelsData = channels && channels.center && channels.center.objects ? channels.center.objects.data : [];
        }
      }).catch((err) => {
        console.log('News Feed Apis Error: ', err);
      });
    }

    if (this.$route.query.Post === "yes" && this.$route.query.Type === "photo") {
      this.addPhoto = true;
    }
    if (this.user) {
      this.getUserPicture();
    }

  },
  methods: {
    ...mapActions(["get_post_data"]),
    ...mapMutations(["updateSignupFormData", "updateSignupUIConstraints"]),
    closeRegisterModal: function () {
      this.$parent.closeModal();
    },
    goBack: function () {
      this.$parent.newModal("register-step-1");
    },
    updateGender: function (row) {
      this.gender = row.value;
      this.genderName = row.name;

      this.updateSignupFormData({
        [SIGNUP_FORM_GENDER]: row.value,
      });
    },
    updateAge: function (row) {
      this.UserAge = row;
    },

    getUserPicture() {
      const { user_data } = this.user;
      const { artwork_url } = user_data || "";
      this.picture = artwork_url;
    },

    showMorePosts(tabName) {
      fetchApis([this.showMore(tabName)], this.$store, false, true, 'show_more').catch((err) => {
        console.log(`Show More ${this.active_tab.toUpperCase()} Apis Error: `, err);
      });
    },

    showMore(tab_name) {
      if (tab_name === "discover_posts") {

        let offset = this.$store.getters.getPostOffset;
        let limit = this.$store.getters.getPostsLimit;
        offset = offset + limit;
        this.$store.commit("SET_POST_OFFSET", offset);
        return () => this.$store.dispatch("getPost", true);

      } else if (tab_name === "my_posts") {

        let offset = this.$store.getters.getPostOffset;
        let limit = this.$store.getters.getPostsLimit;
        offset = offset + limit;
        this.$store.commit("SET_POST_OFFSET", offset);
        return  () => this.$store.dispatch("getUserPosts", {showMore: true, loader: false});

      } else if (tab_name === "liked_posts") {

        let offset = this.$store.getters.getPostOffset;
        let limit = this.$store.getters.getPostsLimit;
        offset = offset + limit;
        this.$store.commit("SET_POST_OFFSET", offset);
        return () => this.$store.dispatch("getUserLikedPost", true);

      }
    },

    setActiveTab(tab_name) {
      this.active_tab = tab_name;
      this.filterName = 'All';

      fetchApis([this.showPosts(tab_name)], this.$store, false, true, tab_name).catch((err) => {
        console.log(`${tab_name.toUpperCase()} Apis Error: `, err);
      });
    },

    showPosts(tab_name, type = 'all') {
      const actions = {
        discover_posts: () => this.$store.dispatch("getPost", false),
        liked_posts: () => this.$store.dispatch("getUserLikedPost", false),
        my_posts: () => {
          this.$store.commit("SET_OTHER_USER_ID", null);
          return this.$store.dispatch("getUserPosts", { showMore: false });
        },
        save_posts: () => this.$store.dispatch("getSavedPosts", false),
      };

      this.$store.commit("SET_POST_OFFSET", 0);
      this.$store.commit("GET_POSTS", []);
      this.$store.commit("SET_POST_TYPE", type ? type.toLowerCase() : null);

      return actions[tab_name] || (() => {});
    },

    showMoreTrending() {
      this.trending_limit += 10
      this.$store.dispatch("getTrendingList", { offset: 0, limit: this.trending_limit });
    },
    getGenres() {
      if (!this.$store.getters.getGenres && !this.$store.getters.getGenres.length > 0) {
        return () => this.$store.dispatch("get_geners");
      } else {
        return () => {};
      }
    },
    closeModal: function () {
      this.optionOptions = false;
    },
    filterPosts(value) {
      this.filterName = (value) ? value.charAt(0).toUpperCase() + value.slice(1) : 'All'

      fetchApis([this.showPosts(this.active_tab, value)], this.$store, false, true, this.active_tab).catch((error) => {
        console.log(`${this.active_tab.toUpperCase()} Apis Error: `, error);
      });

      this.closeModal();
    },
    fetchTrendingList (offset = 0, limit = 10) {
      return () => this.$store.dispatch("getTrendingList", { offset, limit });
    },
    fetchCountries () {
      return () => this.$store.dispatch("fetchCountries");
    },
    fetchActivities () {
      return () => this.$store.dispatch("getActivities");
    },
    fetchWhoToFollow (returnKey = 'who_to_follow') {
      return async () => {
        try {
          const { data } = await getWhoToFollowData();
          return { [returnKey]: data };
        } catch (error) {
          throw error;
        }
      }
    },
    fetchChannels (pageFor = 'talk_podcast', returnKey = 'channels') {
      return async () => {
        try {
          const { data } = await getChannelData(pageFor);
          return { [returnKey]: data };
        } catch (error) {
          throw error;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.news-feed-container {
  width: 1170px;

  .feed_wrapper {
    display: flex;
    gap: 1.875rem;

    .center_centerbar_wraper_home {
      min-width: unset !important;
      width: 770px;
      margin: 0 auto;
      box-sizing: border-box;
      overflow: hidden;

      .artist-main-premium-btns {
        width: inherit;
      }

      .edit-share-section {
        width: inherit;
      }

      .tab-content {
        width: inherit;
      }
    }

    .news-feed-right-side-section {
      width: 370px;
    }
  }
}

.edit-share-section {
  border-radius: 4px !important;
  margin-bottom: 4px;
}

.mt-4-5 {
  margin-top: 4.5rem;
}
</style>
