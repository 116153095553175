<template>
  <div class="main-music-tab-content m-t-20">
    <div class="row">
      <div class="col">
        <div class="placeholder_nav_music" v-if="allFavoriteSongs && allFavoriteSongs.length < 1" >
          <MyMusicEmptyCard
            :redirectFunction="redirectFunction"
            :title="'You haven’t favorited any Music, Playlists, Podcasts or Artists yet'"
            :text="'Go to Explore music and start favoriting music, Podcasts and Artists that you like'"
            :icon="'heart'"
            :buttonText="'EXPLORE MUSIC'"
          />
        </div>
        <div v-else >
          <favorite-songs-listing :userProfile="'user_profile'" @getFavoriteSongs="getFavoriteSongs" :listData="allFavoriteSongs" :totalFavoriteSongs="totalFavoriteSongs" :title="'Favorited Songs'"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="application/javascript">

import favoriteSongsListing from "@/components/Home/Profile/favorites/music/favoriteSongsListing.vue";
import MyMusicEmptyCard from "@/components/Common/UiComponenets/MyMusicEmptyCard.vue";
export default {
  name: "favoriteSongs",
  props: {
    allFavoriteSongs: {
      type: Array,
    },
    totalFavoriteSongs: {
      type: String,
      default: '',
    },
  },
  components: {
    favoriteSongsListing,
    MyMusicEmptyCard
  },
  methods: {
    redirectFunction(){
      this.$router.push('music/page/overview')
    },
  }
}
</script>

<style>
.fav-song-list {
  width: 10% !important;
}
</style>
