<template>
  <div class="content-sidebar mb-4">
    <div class="content-sidebar">
      <div class="event-filter-widget with-app">
        <div class="widget-title">
          <span class="filter-text">Filters</span>
          <div class="clear-history" @click="clearHistory">Clear all history</div>
        </div>
        <div class="divider"></div>
        <div class="widget-item">
          <div class="item-title format-text">Formats</div>
          <div class="choose-wrapper">
            <div class="item-choose more-filters-all-js item-choose-text"
                 @click="$event.target.classList.toggle('active'); albums_view =! albums_view">
              Albums
              <div class="point"></div>
            </div>
            <div class="item-choose more-filters-js item-choose-text"
                 @click="$event.target.classList.toggle('active'); singles_view =! singles_view">
              Singles
              <div class="point"></div>
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="widget-item">
          <div class="item-title week-text">Week of Release</div>
          <div class="choose-wrapper">
            <FilterDatePicker :check="true" ref="clearDate"/>
          </div>
        </div>
        <div class="divider"></div>
        <div class="widget-item">
          <div class="item-title  with-checkbox country-text">Country</div>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="c1" name="all" :checked="selected_country === ''" @click="changeCountry('')">
            <label class="custom-control-label check checkbox-label-text" for="c1">All</label>
          </div>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="c2" name="c2" :checked="selected_country === 232" @click="changeCountry(232)">
            <label class="custom-control-label check checkbox-label-text" for="c2">United Kingdom</label>
          </div>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="c3" name="c3" :checked="selected_country === 233" @click="changeCountry(233)">
            <label class="custom-control-label check checkbox-label-text" for="c3">United States</label>
          </div>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="c4" name="c4" :checked="selected_country === 75" @click="changeCountry(75)">
            <label class="custom-control-label check checkbox-label-text" for="c4">France</label>
          </div>
          <div class="row search-row_border">
            <div class="col-1 p-0 d-flex align-items-center">
              <div class="svg-icon ml-1">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="c-line-1"
                        d="M7.33333 12.8464C10.2789 12.8464 12.6667 10.4585 12.6667 7.51302C12.6667 4.5675 10.2789 2.17969 7.33333 2.17969C4.38781 2.17969 2 4.5675 2 7.51302C2 10.4585 4.38781 12.8464 7.33333 12.8464Z"
                        stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                  <path class="c-line-1" d="M14 14.1793L11.1 11.2793" stroke="#8B949F" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </div>
            </div>
            <div class="col-11 p-0">
              <v-select class="v-select-main-class v-select-without-border" label="name"
                :filterable="true"
                v-model="selected_country"
                :options="countries"
                :reduce="(option) => option.id"
                placeholder="Search countries"
              >
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.name }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }}
                  </div>
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="widget-item">
          <div class="item-title  with-checkbox sort-text">Sort</div>
          <ul class="sort-cehkbox">
            <li>
              <div class="sort-cehkbox-main">
                <input type="radio" id="most_viewed" name="radio-group" value="most_viewed" v-model="sort_by" :checked="sort_by === 'most_viewed'">
                <label for="most_viewed" class="radio-label-text"><span>Most Viewed</span></label>
              </div>
            </li>
            <li>
              <div class="sort-cehkbox-main">
                <input type="radio" id="latest" name="radio-group"  value="latest" v-model="sort_by" :checked="sort_by === 'latest'">
                <label for="latest" class="radio-label-text"><span>Latest</span></label>
              </div>
            </li>
            <li>
              <div class="sort-cehkbox-main">
                <input type="radio" id="high_rating" name="radio-group" value="high_rating" v-model="sort_by" :checked="sort_by === 'high_rating'">
                <label for="high_rating" class="radio-label-text"><span>High Rating</span></label>
              </div>
            </li>
            <li>
              <div class="sort-cehkbox-main">
                <input type="radio" id="low_rating" name="radio-group" value="low_rating" v-model="sort_by" :checked="sort_by === 'low_rating'">
                <label for="low_rating"><span>Low Rating</span></label>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import { getCountries } from "@/apis/APIs";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import '/public/css/vue-select-style.scss';
import FilterDatePicker from "@/components/Music/UI-components/filterDatePicker";
export default {
  data() {
    return {
      countries : [],
      selected_country : '',
      sort_by : 'most_viewed',
      albums_view : false,
      singles_view : false,
      startDate : '',
      endDate: '',
    }
  },
  components:{
    vSelect,
    FilterDatePicker
  },
  mounted() {
    this.getCountries();
  },
  watch:{
    sort_by(){
      this.setFilters();
    },
    selected_country(){
      this.setFilters();
    },
    albums_view(){
      this.updateComponentList();
    },
    singles_view(){
      this.updateComponentList();
    }
  },
  methods:{
    applyWeekFilters(payload){
      this.startDate = payload.startDate;
      this.endDate = payload.endDate;
      if(this.startDate !== '' && this.endDate !== ''){
        this.setFilters();
      }
    },
    setFilters(){
      this.$parent.updateFilters({
        country : this.selected_country,
        sort_by : this.sort_by,
        startDate:this.startDate,
        endDate: this.endDate
      });
    },
    updateComponentList(){
      var myComponentList = null;
      if(this.albums_view === false && this.singles_view === false){
        myComponentList = null;
      }else{
        myComponentList = {
          'album-reviews': { 'component': 'Review4Slider', 'link': 'featured-albums', 'subComponent': '', 'show': this.albums_view },
          'single-reviews': { 'component': 'Review4Slider', 'link': 'featured-single-reviews', 'subComponent': '', 'show': this.singles_view },
          'podcast-reviews': { 'component': 'Review4Slider', 'link': 'featured-podcast-reviews', 'subComponent': '', 'show': false },
          'trending-artist-reviews': { 'component': 'TrendingRelease', 'link': 'explore-review-treanding', 'subComponent': '', 'show': false },
        }
      }
      this.$parent.updateComponentList(myComponentList);
    },
    changeCountry(val){
      this.selected_country = val;
    },
    async getCountries(){
      try {
        await this.$store.dispatch('fetchCountries')
        this.countries = (this.$store.getters.getCountries && this.$store.getters.getCountries.length > 0) ? this.$store.getters.getCountries : [];
      } catch (e) {
        console.error('error',e)
      }
    },
    clearHistory(){
      this.selected_country = '';
      this.sort_by = 'most_viewed';
      this.startDate = '';
      this.endDate = '';
      this.$refs.clearDate.clearDate();
      this.setFilters();
    }
  }
}
</script>
<style scoped>
.search-row_border {
  border: 1px solid #E6EAF0;
  margin-left: 0rem !important;
  margin-right: 0.1rem !important;
  margin-bottom: 0.5rem !important;
  border-radius: 4px;
}
</style>
