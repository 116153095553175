<template>
  <div class=" sale-items">
    <div class="row"  style="margin-top: -20px">
      <div class="col-sm-12">
        <h4 class="title-head top-sign-bottom">{{ gridTitle }}</h4>
        <div class="link-more near_arrow without-slider cursor-pointer" style="top: 0"><a
            @click="redirectToChannel(channelType,altName,gridTitle)">See
          all
          <span class="sl-arrow">
                                    <img
                                        src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgY2xhc3M9InN2Zy1hcnJvdy1yaWd0aCIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZD0iTTEuMjkzNTggMS4zOTQ3OEw3LjM0NTUgNi4zMTI5MkwxLjI5MzU4IDExLjY2MzYiICBzdHJva2U9IiM0NzUwNUIiICBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIg0KICAgICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg0KDQo="> </span>
        </a></div>
      </div>
    </div>
    <div class="justify-content-between" >
      <div class="col custom-col-810" >
        <div class="row position-relative">
          <div v-for="(song, index) in listData" :key="index" class="trendingVCard" >
            <SongOrAlbumCardWithSquareImage :item="song"  :stream="isStreaming"/>
          </div>
        </div>
      </div>
      <div class="col-4 custom-col-370 p-l-5"></div>
    </div>
  </div>
</template>
<script type="application/javascript">
import SongOrAlbumCardWithSquareImage from "@/components/DesignGuide/Music/Components/Grid.vue";
export default {
  data() {
    return {

      optionWidth: 0,
      isHovering: false,
    }
  },
  props: {
    gridTitle: String,
    listData: {
      type: Array,
      default: () => []
    },
    // isStreaming: {
    //   type: Boolean,
    //   default: false
    // },
    altName: String,
    channelType:{
      type:String,
      default:null
    }
  },
  components: {
    SongOrAlbumCardWithSquareImage
  },
  computed: {
    isStreaming(){
      return this.$store.getters.isMusicModeIsStreaming
    }
  },
  methods: {
    getImgUrl(filename) {
      if (filename) {
        var xhr = new XMLHttpRequest();
        xhr.open('HEAD', filename, false);
        xhr.send();
        if (xhr.status == "404") {
          return require(`@/assets/img/placeholders.png`);
        } else {
          return filename;
        }

      } else {
        return require(`@/assets/img/placeholders.png`);
      }
    },
    isHoveringFun: function (val, index) {
      this.listData[index].isHovering = val;
    }
    ,
    getHeight: function (e) {
      if (this.optionWidth == 0)
        this.optionWidth = e.screenX;
    },
    getArtistName(artists) {
      let name = "";
      for (const artist of artists) {
        name = name + artist.name + ", "
      }
      return name
    }
  }
}
</script>
