<template>
  <div class="ag-container">
<!--    <MusicSubHeader/>-->
    <div class="container_xxl">
      <div class="artist_body mx-auto">
        <div class="panel_wrapper">
          <div class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 0C4.0374 0 0 4.0374 0 9C0 13.9626 4.0374 18 9 18C13.9626 18 18 13.9626 18 9C18 4.0374 13.9626 0 9 0ZM14.175 9.9H8.1V3.6H9.9V8.1H14.175V9.9Z" fill="#47505B"/>
                </svg>

                Availability & Rate
              </div>
              <button class=" btn-view ml-auto" type="button" @click="redirectToProfilePage(username)">
                        <span class="pr-2">
                          <EyeIcon />
                        </span>
                View Profile
              </button>
            </div>
            <div class="artist_body_setting">
              <div class="artist-setting-tab">
                <b-form @submit.prevent="updateAvailabilityAndRate">
                  <div class="artist-setting-panel">
                    <div class="row">
                      <div class="col-12">
                        <b-form-group
                            label-for="name-your-page"
                            class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            I am currently
                          </template>
                          <div class="row">
                            <div class="col-md-4">
                              <div class=" custom-radio expert-radio artist-setting-radio">
                                <input type="radio" id="available" :value="'available'" name="radio-group"
                                       v-model="currentStatus">
                                <label for="available">Available</label>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class=" custom-radio expert-radio artist-setting-radio">
                                <input type="radio" id="not-available" :value="'not-available'" name="radio-group"
                                       v-model="currentStatus">
                                <label for="not-available">Not Available</label>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class=" custom-radio expert-radio artist-setting-radio">
                                <input type="radio" id="part-time" :value="'part-time'" name="radio-group"
                                       v-model="currentStatus">
                                <label for="part-time">Part time</label>
                              </div>
                            </div>
                          </div>
<!--                          <div class="invalid-feedback d-block"-->
<!--                               v-if="$v.setAvailabilityAndRate.availability_status.$dirty">-->
<!--                            <span v-if="!$v.setAvailabilityAndRate.availability_status.required">-->
<!--                                                        This is a required field.-->
<!--                            </span>-->
<!--                          </div>-->
                        </b-form-group>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <b-form-group
                            label-for="name-your-page"
                            class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            Availability
                          </template>
                          <div class="row">
                            <div class="col-md-4">
                              <div class=" custom-radio expert-radio artist-setting-radio">
                                <input type="radio" id="greaterThen30" value="greaterThen30" name="availability"
                                       v-model="currentAvailabilityTime">
                                <label for="greaterThen30">More then 30 hrs/week</label>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class=" custom-radio expert-radio artist-setting-radio">
                                <input type="radio" id="lessThen30" value="lessThen30" name="availability"
                                       v-model="currentAvailabilityTime">
                                <label for="lessThen30">Less then 30 hrs/week</label>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class=" custom-radio expert-radio artist-setting-radio">
                                <input type="radio" id="openOffer" value="openOffer" name="availability"
                                       v-model="currentAvailabilityTime">
                                <label for="openOffer">As needed -open to offers</label>
                              </div>
                            </div>
                          </div>
<!--                          <div class="invalid-feedback d-block" v-if="$v.setAvailabilityAndRate.availability_time.$dirty">-->
<!--                            <span v-if="!$v.setAvailabilityAndRate.availability_time.required">-->
<!--                            This is a required field.-->
<!--                            </span>-->
<!--                          </div>-->
                        </b-form-group>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 col-md-4">
                        <b-form-group
                            label="Rate (Per Hour)"
                            label-for="page-address"
                            label-align="left"
                            class="artist-page-form-field"
                        >
                          <b-input-group
                              append="USD"
                              size="sm"
                              class="mb-2"
                          >
                            <b-form-input
                                id="amount-added"
                                placeholder="amount"
                                :type="'number'"
                                :state="validateState('rate_per_hour')"
                                v-model="ratePerHour"
                            >
                            </b-form-input>
                          </b-input-group>
                          <b-form-invalid-feedback
                              id="amount-added"
                          >This is a required field.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                      <div class="col-sm-12 col-md-4">
                        <b-form-group
                            label="You’ll get (-10% fee)"
                            label-for="page-address"
                            label-align="left"
                            class="artist-page-form-field"
                        >
                          <b-input-group
                              append="USD"
                              size="sm"
                              class="mb-2"

                          >
                            <b-form-input
                                id="amount-you-get"
                                placeholder="amount"
                                v-model="youGet"
                                readonly
                            >
                            </b-form-input>
                          </b-input-group>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="d-flex justify-content-end">
                      <button class="prime_button" type="submit">Save Changes</button>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
// import MusicSubHeader from '../MusicSubHeader'
import MusicSubHeader from '@/components/PageManager/PMSubHeader.vue'
import {required} from "vuelidate/lib/validators";
import {setAvailabilityAndRatePage} from "../../../../apis/APIs";
import EyeIcon from '../../../../assets/svgs/EyeIcon.vue';

export default {
  data() {
    return {
      setAvailabilityAndRate: {
        availability_status: null,
        availability_time: null,
        rate_per_hour: null,
      },
      errorsList: []
    }
  },
  components: {
    // MusicSubHeader,
    EyeIcon
  },
  computed: {
    pageType() {
      return this.current_page.type
    },
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
    createPageIcon() {
      if (this.pageType === 'Clubs') {
        return require(`@/assets/img/svg/create_club_page.svg`)
      } else if (this.pageType === 'Company') {
        return require(`@/assets/img/svg/create_company_page.svg`)
      } else {
        return require(`@/assets/img/user-1.png`)
      }
    },
    ratePerHour: {
      set(newValue) {
        this.$v.setAvailabilityAndRate.rate_per_hour.$model = newValue
      },
      get() {
        return (this.current_page) ? this.current_page.rate_per_hour : null
      },
    },
    currentStatus: {
      set(newValue) {
        this.$v.setAvailabilityAndRate.availability_status.$model = newValue
      },
      get() {
        return (this.current_page) ? this.current_page.availability_status : null
      },
    },
    currentAvailabilityTime: {
      set(newValue) {
        this.$v.setAvailabilityAndRate.availability_time.$model = newValue
      },
      get() {
        return (this.current_page) ? this.current_page.availability_time : null
      },
    },
    youGet() {
      if (this.setAvailabilityAndRate.rate_per_hour) {
        return this.setAvailabilityAndRate.rate_per_hour - (Number(this.setAvailabilityAndRate.rate_per_hour) / 10)
      }
      return null
    }
  },
  validations: {
    setAvailabilityAndRate: {
      availability_status: {

      },
      availability_time: {

      },
      rate_per_hour: {
        required,
      }
    }
  },
  watch: {
    current_page(val){
      if(val)
      this.getPageDataFromStore()
    }
  },
  methods: {
    validateState(name) {
      const {$dirty, $error} = this.$v.setAvailabilityAndRate[name];
      return $dirty ? !$error : null;
    },
    async updateAvailabilityAndRate() {
      this.$v.setAvailabilityAndRate.$touch();
      if (this.$v.setAvailabilityAndRate.$anyError) {
        return;
      } else {
        try {
          const formData = new FormData();
          for (const field in this.setAvailabilityAndRate) {
            if (!!(this.setAvailabilityAndRate[field])) {
              formData.append(field, this.setAvailabilityAndRate[field]);
            }
          }
          await this.$store.dispatch('loading', true)
          const {data} = await setAvailabilityAndRatePage(this.current_page.id, formData);
          const {success, message} = data || {}
          await this.$store.dispatch('loading', false)
          if (success === 1) {
            const {information} = data || {};
            this.$store.commit('SET_PAGE_DATA', information)
            await this.notificationToast(true, "Page!", message, 5000, 'success')
          } else {
            await this.notificationToast(true, "Page!",message?message:'Try Again', 5000, 'error')
          }
        } catch (e) {
          await this.$store.dispatch('loading', false)
          console.error('error',e)
          const {data} = e
          if(data)
          {
          await this.notificationToast(true, "Page!", data.message, 5000, 'error')
            return
          }
          await this.notificationToast(true, "Page!", e.message, 5000, 'error')
        }

      }
    },
    getPageDataFromStore(){
      if(this.current_page){
        this.setAvailabilityAndRate.availability_status =this.current_page.availability_status
        this.setAvailabilityAndRate.availability_time =this.current_page.availability_time
        this.setAvailabilityAndRate.rate_per_hour =this.current_page.rate_per_hour
      }
    },
  },
  mounted() {
    this.getPageDataFromStore()
  }
}
</script>
<style scoped>
.artist-setting-radio.expert-radio [type="radio"]:checked+label, .artist-setting-radio.custom-radio [type="radio"]:not(:checked)+label {
  font-weight: 400;
  padding: 6px 6px 6px 26px;
}

.artist-setting-radio.expert-radio [type="radio"]:checked+label::before, .artist-setting-radio.custom-radio [type="radio"]:not(:checked)+label::before {
  content: "";
  position: absolute;
  left: 10px;
  top: 11px;
  width: 10px;
  height: 10px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

.artist-setting-radio.expert-radio [type="radio"]:checked+label::after, .artist-setting-radio.custom-radio [type="radio"]:not(:checked)+label::after {
  content: "";
  width: 6px;
  height: 6px;
  background: #D1433A;
  position: absolute;
  left: 12px;
  top: 13px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.category-select::after {
  background-image: url(../../../../assets/img/artist-select.png);
}

.container-checkbox .week-checkmark::after {
  background-image: url(../../../../assets/img/setting-checkbox.png);
}
</style>

<style scoped lang="scss">
.ag-container {
  width: 100%;
  padding: 0;
}
.panel_wrapper {
  margin-top: 59px;
  margin-top: 130px;
}
</style>
