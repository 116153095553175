import { userEvents } from "../../apis/APIs";

const INITIAL_STATE = {
  allEvents: {
    past: [],
    upcoming: [],
  },
  totalEvents: {
    past: 0,
    upcoming: 0,
  },
  eventOffset: {
    past: 0,
    upcoming: 0,
  },
};

export const state = { ...INITIAL_STATE };

export const actions = {
  async getUserEvents({ state, commit, dispatch }, { eventType = 'past', limit = 8, showMore = false, returnKey = 'events' }) {


    const eventLimit = limit;

    try {

      const offset = state.eventOffset[eventType] || 0;
      const currentEventList = state.allEvents[eventType] || [];

      const newOffset = showMore ? offset + eventLimit : 0;

      const { data } = await userEvents(eventType, newOffset, eventLimit);

      const newEventData = data.information.data;
      const totalEvents = data.information.total;

      if (newEventData.length > 0) {

        commit(`SET_${eventType.toUpperCase()}_EVENTS`, showMore ? [...currentEventList, ...newEventData] : newEventData);
        commit(`SET_TOTAL_${eventType.toUpperCase()}_EVENTS`, totalEvents);
        commit(`SET_${eventType.toUpperCase()}_OFFSET`, newOffset);
      }

      return { [returnKey]: data };

    } catch (error) {
      dispatch('loading', false);
      throw error;
    }
  },
};

export const mutations = {
  SET_UPCOMING_EVENTS(state, payload) {
    state.allEvents.upcoming = payload;
  },
  SET_PAST_EVENTS(state, payload) {
    state.allEvents.past = payload;
  },
  SET_TOTAL_PAST_EVENTS(state, payload) {
    state.totalEvents.past = payload;
  },
  SET_TOTAL_UPCOMING_EVENTS(state, payload) {
    state.totalEvents.upcoming = payload;
  },
  SET_PAST_OFFSET(state, payload) {
    state.eventOffset.past = payload;
  },
  SET_UPCOMING_OFFSET(state, payload) {
    state.eventOffset.upcoming = payload;
  },
};

export const getters = {
  getPastEvents(state) {
    return state.allEvents.past;
  },
  getUpcomingEvents(state) {
    return state.allEvents.upcoming;
  },
  getUpcomingTotal(state) {
    return state.totalEvents.upcoming;
  },
  getPastTotal(state) {
    return state.totalEvents.past;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
