<template>
  <div>
    <div v-if="artistFollowingsList.length > 0">
    <div class="row mt_10">
      <div class="col-md-6" v-for="(artistFollowing,index) in artistFollowingsList" :key="index">
        <div class="frind_list_box">
          <div class="friend_profile_image">
            <div class="friend-image-wrapper cursor-pointer"
                 @click="redirectWidgetsToProfilePage($event, artistFollowing.username ? artistFollowing.username : '')"
            >
              <img :src="artistFollowing.artwork_url" alt="user_image" @error="onProfileImageError($event, getFirstAlphabetOfUserName(artistFollowing))">
            </div>
          </div>
          <div class="friend_profile_content">
            <h4 class="friend_profile_name cursor-pointer"
                @click="redirectWidgetsToProfilePage($event, artistFollowing.username ? artistFollowing.username : '')"
            >{{ artistFollowing.name }}</h4>
            <p class="friend_profile_location">
              <span>{{ artistFollowing.mututal_friends }} mutual friends</span>
            </p>
          </div>
          <div class="friends_friend_icon" @click="changeFollowStatus(artistFollowing.id, artistFollowingUserType, 1, artistFollowing)" v-if="artistFollowingUserType === 'user' && artistFollowing.follow_status === 0">
            <svg width="18" height="15" viewBox="0 0 18 15" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.0345 14.1553V12.776C12.0345 12.0443 11.7439 11.3427 11.2265 10.8253C10.7092 10.308 10.0075 10.0173 9.27588 10.0173H3.75863C3.02699 10.0173 2.32533 10.308 1.80798 10.8253C1.29064 11.3427 1 12.0443 1 12.776V14.1553"
                stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                class="friends_icon_path"></path>
              <path
                d="M6.51644 7.25846C8.03998 7.25846 9.27506 6.02338 9.27506 4.49984C9.27506 2.97629 8.03998 1.74121 6.51644 1.74121C4.99289 1.74121 3.75781 2.97629 3.75781 4.49984C3.75781 6.02338 4.99289 7.25846 6.51644 7.25846Z"
                stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                class="friends_icon_path"></path>
              <path d="M14.3906 4.47363V8.91254" stroke="#D1433A" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round" class="friends_icon_path"></path>
              <path d="M12.2461 6.69336H16.685" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" class="friends_icon_path"></path>
            </svg>
          </div>
          <div class="friends_friend_icon" @click="changeFollowStatus(artistFollowing.id, artistFollowingUserType, 1, artistFollowing)" v-if="artistFollowingUserType === 'admin' && artistFollowing.page_follow_status === 0">
            <svg width="18" height="15" viewBox="0 0 18 15" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.0345 14.1553V12.776C12.0345 12.0443 11.7439 11.3427 11.2265 10.8253C10.7092 10.308 10.0075 10.0173 9.27588 10.0173H3.75863C3.02699 10.0173 2.32533 10.308 1.80798 10.8253C1.29064 11.3427 1 12.0443 1 12.776V14.1553"
                stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                class="friends_icon_path"></path>
              <path
                d="M6.51644 7.25846C8.03998 7.25846 9.27506 6.02338 9.27506 4.49984C9.27506 2.97629 8.03998 1.74121 6.51644 1.74121C4.99289 1.74121 3.75781 2.97629 3.75781 4.49984C3.75781 6.02338 4.99289 7.25846 6.51644 7.25846Z"
                stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                class="friends_icon_path"></path>
              <path d="M14.3906 4.47363V8.91254" stroke="#D1433A" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round" class="friends_icon_path"></path>
              <path d="M12.2461 6.69336H16.685" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" class="friends_icon_path"></path>
            </svg>
          </div>
          <div class="friends_friend_icon frd-following-icon" @click="changeFollowStatus(artistFollowing.id, artistFollowingUserType, 0, artistFollowing)"  v-if="artistFollowingUserType === 'user' && artistFollowing.follow_status === 1">
            <svg width="18" height="15" viewBox="0 0 18 15" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path class="friends_icon_path"
                    d="M12.0345 14.1553V12.776C12.0345 12.0443 11.7439 11.3427 11.2265 10.8253C10.7092 10.308 10.0075 10.0173 9.27588 10.0173H3.75863C3.02699 10.0173 2.32533 10.308 1.80798 10.8253C1.29064 11.3427 1 12.0443 1 12.776V14.1553"
                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="friends_icon_path"
                    d="M6.51644 7.25846C8.03998 7.25846 9.27506 6.02338 9.27506 4.49984C9.27506 2.97629 8.03998 1.74121 6.51644 1.74121C4.99289 1.74121 3.75781 2.97629 3.75781 4.49984C3.75781 6.02338 4.99289 7.25846 6.51644 7.25846Z"
                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="friends_icon_path" d="M14.3906 4.47363V8.91254" stroke="#D1433A"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="friends_icon_path" d="M12.2461 6.69336H16.685" stroke="#D1433A"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="friends_friend_icon frd-following-icon" @click="changeFollowStatus(artistFollowing.id, artistFollowingUserType, 0, artistFollowing)"  v-if="artistFollowingUserType === 'admin' && artistFollowing.page_follow_status === 1">
            <svg width="18" height="15" viewBox="0 0 18 15" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path class="friends_icon_path"
                    d="M12.0345 14.1553V12.776C12.0345 12.0443 11.7439 11.3427 11.2265 10.8253C10.7092 10.308 10.0075 10.0173 9.27588 10.0173H3.75863C3.02699 10.0173 2.32533 10.308 1.80798 10.8253C1.29064 11.3427 1 12.0443 1 12.776V14.1553"
                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="friends_icon_path"
                    d="M6.51644 7.25846C8.03998 7.25846 9.27506 6.02338 9.27506 4.49984C9.27506 2.97629 8.03998 1.74121 6.51644 1.74121C4.99289 1.74121 3.75781 2.97629 3.75781 4.49984C3.75781 6.02338 4.99289 7.25846 6.51644 7.25846Z"
                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="friends_icon_path" d="M14.3906 4.47363V8.91254" stroke="#D1433A"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="friends_icon_path" d="M12.2461 6.69336H16.685" stroke="#D1433A"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="show_more_btn" v-if="artistFollowingsTotal > artistFollowingsList.length">
      <div class="show-more musicbar_more">
        <a href="javascript:;" @click="showMoreFollowings">
        <span class="show_more_bg">
          <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.24219 0.689941V7.01994" stroke="#071526" stroke-width="1.3" stroke-linecap="round"
                  stroke-linejoin="round">
            </path>
            <path d="M1 3.85498H7.48702" stroke="#071526" stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round">
            </path>
          </svg>
          SHOW  MORE USERS
        </span>
        </a>
      </div>
    </div>
  </div>

  <no-data v-else cardTitle="You don't have any following yet."
  cardSubTitle="Browse the playyground to find following." cardIconType="FriendsIcon"/>
  </div>
</template>
<script type="application/javascript">
export default {
  props:['currentActiveFollowerTab','friendsSearchKey'],
  date(){
    return{

    }
  },
  components: {
    "no-data": require("@/components/Home/People/NoData").default,
  },
  computed:{
    artistFollowingsList(){
      return this.$store.getters['getArtistFollowingsList']
    },
    artistFollowingsTotal(){
      return this.$store.getters['getArtistFollowingsTotal']
    },
    artistFollowingUserType(){
      return this.$store.getters['getArtistFollowingUserType']
    }
  },
  methods:{
    showMoreFollowings(){
      let payload = {
        showMore : true,
        page_id: this.$route.params.id,
        name : this.friendsSearchKey,
        search_type: this.currentActiveFollowerTab,
      }
      this.$store.dispatch('getArtistPageFollowings', payload);
    },
    changeFollowStatus(user_id, object_type, action, artistFollowing){
      let payload = {
        user_id : user_id,
        page_id : this.$route.params.id,
        object_type : object_type,
        action : action,
        user : artistFollowing
      }
      this.$store.dispatch('pageChangeUserFollowStatus', payload);
    }
  }
}
</script>
