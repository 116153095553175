<template>
  <div class="Signup_pop_overlay">
    <div class="wr_report_modal">
      <BlockModalHeader @closeModal="closeModal"/>
      <div class="text-container">
        <div class="text">
          Are you sure you want to
          <b>{{ type === "Unblock" ? "Unblock" : "Block" }} this?</b>
        </div>
      </div>
      <div class="wr_btn">
        <div @click="closeModal()" class="cancel">CANCEL</div>
        <div class="confirm" @click="confirmBlockUser">CONFIRM</div>
      </div>
    </div>

  </div>
</template>

<script>

import BlockModalHeader from "./BlockModalHeader.vue";
import { blockUser, reportPosts, unblockUser } from "../../../apis/APIs";

export default {
  name: "ConfirmBlockModal",
  components: {
    BlockModalHeader,
  },
  props: {
    blockableId: {
      type: [String, Number],
      default: null
    },
    blockableType:{
      type: String,
      default: "user"
    },
    type:{
      type: String,
      default: "block"
    }
  },
  data() {
  },
  computed: {
  },
  methods: {
    async confirmBlockUser() {
      const payload = {
        user_id: this.blockableId,
      };
      try {
        if (this.type === "Unblock") {
          await unblockUser(this.blockableId, {type: this.blockableType});
          this.$emit("closeBlockModal");
          await this.$store.dispatch("fetchBlockUsersData", "")
        }
        else {
          await this.$store.dispatch("blockUserFunction", payload);
          this.$emit("closeBlockModal");
        }
        await this.notificationToast(true, `${this.type === "Unblock" ? "Unblock" : "Block" } User`, `User ${this.type === "Unblock" ? "Unblocked" : "Blocked" } Successfully!`, 5000, 'success')
      }
      catch (error) {
        this.$emit("closeBlockModal")
        await this.notificationToast(true, 'Block User', `${error.data.message}`, 5000, 'error')
      }
    },

    closeModal() {
      this.$emit("closeBlockModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.Signup_pop_overlay {
  font-family: HelveticaNeueNormal;
  .wr_report_modal {
    background: #ffffff;
    width: 570px;
    height: 249px;
    padding: 22px 20px 29px;
    border-radius: 4px;
    .text-container{
      border-bottom: 1px solid #e6eaf0;
      margin-bottom: 20px;
      .text {
        text-align: center;
        font-weight: 400;
        margin: 35px 0;
        font-size: 15px;
        line-height: 20px;
        color: #47505B;
        b {
          font-family: HelveticaNeueBold;
        }
      }
    }
    .wr_btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .confirm,
      .cancel {
        width: 160px;
        height: 40px;
        font-weight: 700;
        font-size: 13px;
        color: #ffffff;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .cancel {
        color: #8b949f;
        border: 1.3px solid #8b949f;
      }
      .confirm {
        background: #D1433A;
      }
    }
  }
}
</style>
