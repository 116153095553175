<template>
  <div class="container-events">
    <div class="container-events-close" data-dismiss="modal" aria-label="Close" @click="close">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5 4.5L4.5 13.5" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
        <path d="M4.5 4.5L13.5 13.5" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </div>

    <div class="container-events-title">
      <span>New Event Post</span>
    </div>
    <!-- <perfect-scrollbar> -->
    <div class="for_height">
      <div class="container-events-tabs">
        <div class="tabs-item" :class="{
          'active-tab': item.id === activeTab,
          'prev-tab': activeTab > item.id,
        }" v-for="item in nav" :key="item.id">
          <div class="tabs-item-img" :style="{
            'mask-image':
              `url('` + require(`@/assets/img/events/${item.img}`) + `')`,
          }"></div>
          <p>{{ item.title }}</p>
        </div>
      </div>
      <div class="container-events-content">
        <information v-show="activeTab === 2" ref="infoRef" @setNewDataEvent="setNewDataEvent" />
        <artwork v-show="activeTab === 3" ref="artWorkRef" @setNewDataEvent="setNewDataEvent" />
        <tickets v-show="activeTab === 4" ref="ticketsRef" @setNewDataEvent="setNewDataEvent" />
        <visibility v-show="activeTab === 5" ref="visibilityRef" @setNewDataEvent="setNewDataEvent" />
      </div>
      <div class="line" :class="{ borderColor: activeTab === 4 }" v-if="activeTab === 4"></div>

      <div style="margin-top: 20px" v-if="activeTab === 2 || activeTab === 3"></div>
      <div style="margin-top: 30px" v-if="activeTab === 5"></div>
    </div>
    <!-- </perfect-scrollbar> -->
    <!-- BUttons  -->
    <div class="left-actions">
      <button class="cancel" @click="openCancelBoxDialog()">
        <span class="mt-1">cancel</span>
      </button>
      <div class="flex">
        <button class="saveDraft for_hover_empty_border_blue btn_empty_border_blue" v-if="activeTab === 2">
          <span class="mt-1">Save to Draft</span>
        </button>
        <button class="saveDraft for_hover_empty_border_blue btn_empty_border_blue" @click="backStep"
          v-if="activeTab !== 2">
          <span class="mt-1">Back</span>
        </button>
        <button v-if="activeTab != 5" class="create for_hover_blue_btn blue_btn" @click="nextStep">
          <span style="margin-top: 0.1rem;">Next Step</span>
          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.25 9L5.25 5L1.25 1" stroke="white" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </button>
        <button v-if="activeTab === 5" class="create for_hover_blue_btn blue_btn" @click="newEvent">
          POST EVENT
          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.25 9L5.25 5L1.25 1" stroke="white" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </button>
      </div>
    </div>
    <!-- Buttons -->
    <div v-if="cancelEventPopup" class="cancel-popup-wrapper">
      <cancel-popup @cancelEvent="cancelEvent" @closeEvent="close" @closeCancelEvent="closeCancelEventDialog" />
    </div>
    <!-- <form ref="asd"></form> -->
  </div>
</template>

<script>
import moment from "moment";
import CancelPopup from "./CancelPopup.vue";
import Information from "./Information.vue";
import Artwork from "./Artwork.vue";
import Tickets from "./Tickets.vue";
import Visibility from "./Visibility.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "EventPostContainer",

  components: {
    Information,
    CancelPopup,
    Artwork,
    Visibility,
    Tickets,
  },

  data() {
    return {
      nav: [
        {
          id: 2,
          title: "Information",
          img: "info.svg",
        },
        {
          id: 3,
          title: "Artwork",
          img: "art.svg",
        },
        {
          id: 4,
          title: "Tickets",
          img: "ticket.svg",
        },
        {
          id: 5,
          title: "Visibility",
          img: "visi.svg",
        },
      ],
      activeTab: 2,
      cancelEventPopup: false,
      newDataEvent: {},
    };
  },

  methods: {
    ...mapActions(["createEvent", "closeEventModal"]),
    cancelEvent() {
      this.cancelEventPopup = false
      this.$store.commit('SET_EVENT_MODAL', false)
      this.$store.commit("SET_CREATE_EVENT_MODAL_CHECK", false);
      this.$emit("closeEventModal");
    },
    closeCancelEventDialog() {
      this.cancelEventPopup = !this.cancelEventPopup
    },
    setNewDataEvent(data) {
      this.newDataEvent = { ...this.newDataEvent, ...data };
    },
    close() {
      this.cancelEventPopup = false
      this.$store.commit('SET_EVENT_MODAL', false)
      this.$store.commit("SET_CREATE_EVENT_MODAL_CHECK", false);
      this.$emit("closeEventModal");
    },
    notClose() {
      this.$emit("dontCloseModal");
    },
    isMoment(date) {
      return moment(date).format("Do-MMM-YYYY");
    },
    nextStep() {
      let next = true;
      switch (this.activeTab) {
        case 2:
          this.$refs.infoRef.handleInformation();
          break;
        case 3:
          this.$refs.artWorkRef.handleArtWork();
          break;
        case 4:
          this.$refs.ticketsRef.handleTickets();
          break;
        default:
      }
      let infoValid = Object.entries(this.newDataEvent);
      infoValid.forEach((el) => {
        if (
          (
            el[0] != "selectedInvites" &&
            (el[1] === "" || el[1] === null || el[1].length < 1)) &&
          el[0] != "editorData"
        ) {
          next = false;
        }
      });
      if (next) {
        this.activeTab += 1;
      }
    },
    backStep() {
      if (this.activeTab > 2) this.activeTab -= 1;
    },
    async newEvent() {
      await this.$refs.visibilityRef.handleVisibility();
      let formData = new FormData();
      formData.append("artwork", this.newDataEvent.imgCover);
      let genresList = this.newDataEvent.selectedGenres.map((elem) => elem.id);
      formData.append("name", this.newDataEvent.eventName);
      formData.append("start_date", this.isMoment(this.newDataEvent.startDate));
      formData.append("start_time", this.newDataEvent.startTime);
      formData.append("end_date", this.isMoment(this.newDataEvent.endDate));
      formData.append("end_time", this.newDataEvent.endTime);
      genresList.forEach((el, i) => {
        formData.append(`genre[${i}]`, String(el));
      });
      formData.append(
        "venue_type",
        this.newDataEvent.selectedVenue
          ? this.newDataEvent.selectedVenue.id
          : ""
      );
      formData.append(
        "about_event",
        this.newDataEvent.editorData ? this.newDataEvent.editorData.slice(3, this.newDataEvent.editorData.length - 5) : ""
      );
      formData.append("privacy", this.newDataEvent.privacy);
      for (let i = 0; i < this.newDataEvent.tickets.length; i++) {
        for (let key of Object.keys(this.newDataEvent.tickets[i])) {
          formData.append(
            `tickets[${i}][${key}]`,
            String(this.newDataEvent.tickets[i][key])
          );
        }
      }
      formData.append("capacity", this.newDataEvent.capacity);
      this.newDataEvent.selectedInvites.forEach((el, i) => {
        formData.append(`inviteIds[${i}]`, String(el));
      });
      formData.append("page_id", this.newDataEvent.page_id);
      formData.append("longitude", this.newDataEvent.gMapData.longitude);
      formData.append("latitude", this.newDataEvent.gMapData.latitude);
      formData.append("location", this.newDataEvent.gMapData.location);
      formData.append("country", this.newDataEvent.gMapData.country);
      formData.append("city", this.newDataEvent.gMapData.city);
      formData.append(
        "event_type",
        this.newDataEvent.selectedEvent
          ? this.newDataEvent.selectedEvent.id
          : ""
      );
      console.log("formData", formData);
      try {
        await this.$store.dispatch("loading", true);
        await this.createEvent(formData);
        await this.$store.dispatch("loading", true);
        this.close();
        await this.$parent.explorEvents(this.GET_EVENTS_SEARCH_FOR);
        await this.$store.dispatch("loading", false);
      } catch (error) {
        console.error(error);
        await this.$store.dispatch("loading", false);
        this.notClose();
      }
    },
    openCancelBoxDialog () {
      this.$refs.infoRef.closeDateAndTimePicker()
      this.cancelEventPopup = true
    }
  },

  computed: {
    ...mapGetters(["GET_EVENTS_SEARCH_FOR"]),
  },
};
</script>

<style lang="scss" scoped>

.container-events-title {

   span {
     font-family: HelveticaNeue,sans-serif;
     font-size: 20px;
     font-weight: 700;
     line-height: 24.42px;
     text-align: left;
     text-underline-position: from-font;
     text-decoration-skip-ink: none;
     color: rgba(7, 21, 38, 1);
   }
}



// .for_height {
//   max-height: 715px;
// }
.container-events-close {
  border-radius: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    background: #e6eaf0;
  }
}

.prev-tab {
  background: #D1433A1A !important;

  .tabs-item-img {
    background: #D1433A !important;
  }

  p {
    color: #D1433A !important;
  }
}

.active-tab {
  background: #D1433A !important;
  border: 1px solid #D1433A1A;

  .tabs-item-img {
    background: #ffffff !important;
  }

  p {
    color: #ffffff !important;
  }
}

.cancel-popup-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
}

.container-events {
  width: 770px;
  max-width: 100%;
  border-radius: 4px;
  border: 1px solid #e6eaf0;
  background: #ffffff;
  margin: 30px auto 50px;
  padding: 20px 30px;
  position: relative;

  &-close {
    position: absolute;
    // top: 26px;
    top: 18px;
    right: 26px;
    cursor: pointer;
  }

  &-title {
    border-bottom: 1px solid #e6eaf0;
    padding-bottom: 17px;
    text-align: center;

    h2 {
      color: #071526;
      font-size: 20px;
      line-height: 24px;
      font-family: HelveticaNeueBold;
    }
  }

  &-tabs {
    margin-top: 19px;
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .tabs-item {
      background: #f9fafb;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 170px;
      max-width: 100%;
      height: 50px;

      &-img {
        width: 16px;
        height: 16px;
        background: #47505b;
        margin-right: 14px;
        mask-position: center;
        mask-size: contain;
        mask-repeat: no-repeat;
      }

      p {
        margin-bottom: 0;
        font-family: HelveticaNeue, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.09px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: rgba(71, 80, 91, 1);
      }
    }
  }
}
</style>

<style lang="scss">
.container-events {

  // date picker
  .mx-calendar-header-label {
    color: #D1433A;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    font-family: HelveticaNeueBold;
  }

  .mx-btn-icon-left,
  .mx-btn-icon-right {
    border-radius: 4px !important;
  }

  .mx-table-date td,
  .mx-table-date th {
    border-radius: 50%;
  }

  .vs__actions {
    padding: 4px 0 0 3px !important;
  }

  .mx-datepicker {
    opacity: 0;
  }

  // tags
  .ti-input {
    border-radius: 4px;
    border: 1px solid #e6eaf0 !important;
    min-height: 30px;
    padding: 0 !important;
    padding-left: 10px !important;
  }

  .ti-tags {
    align-items: center;
  }

  .ti-tag {
    background: #e6eaf0 !important;
    border-radius: 2px !important;
    color: rgba(7, 21, 38, 0.6) !important;
    font-size: 13px !important;
    line-height: 16px !important;
    font-family: HelveticaNeueMedium !important;
    height: 20px;
    padding: 0 10px !important;
  }

  .ti-new-tag-input-wrapper {
    color: rgba(7, 21, 38, 0.6) !important;
    font-size: 13px !important;
    line-height: 16px !important;
    font-family: HelveticaNeueMedium !important;
    padding: 0 !important;
  }
}

// gmap
.left-title {
  .pac-target-input {
    width: 340px;
    max-width: 100%;
    border: 1px solid #e6eaf0;
    border-radius: 4px;
    height: 30px;
    font-size: 13px;
    line-height: 16px;
    font-family: HelveticaNeueBold;
    padding-left: 10px;

    &::placeholder {
      color: rgba(7, 21, 38, 0.6);
      font-size: 13px;
      line-height: 16px;
      font-family: HelveticaNeueMedium;
    }
  }
}

.left-actions {
  // margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  button {
    height: 40px;
    font-size: 12px;
    line-height: 16px;
    font-family: HelveticaNeueBold;
    border-radius: 4px;
    text-transform: uppercase;
  }

  .flex {
    display: flex;
  }

  .create {
    width: 130px;
    margin-left: 10px;
    margin-right: -2px;

    &:hover {
      background: #DA6961 !important;
    }

    svg {
      margin-left: 8px;
    }
  }

  .saveDraft {
    width: 130px;
  }

  .cancel {
    border: none;
    outline: none;
    width: 130px;
    max-width: 100%;
    background: #e6eaf0;
    color: #47505b;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: #d1d9e3;
    }
  }
}

.line {
  margin: 18px 0 19px 0;
  width: 100%;
  border: 1px solid #fff;
}

.borderColor {
  border-color: #e6eaf0;
}
</style>
