<template>
  <div>
    <div v-if="filter==='chooseFilters'" class="filters-friends width370">
      <div
        class="
          filters-head
          d-flex
          flex-row
          align-items-center
          justify-content-between">
        <div class="filters-title">Filters</div>
        <div class="clear-btn" @click="clearHistory"  style="color: #8B949F !important; border:1.3px solid #C4CCD6">Clear all history</div>
      </div>
      <div class="widget">
        <div class="d-flex flex-column">
          <div class="search-input">
            <div class="svg-icon2" style="left: 20px !important; ">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" style="display: inline-block !important; color: #47505B !important;">
                <path class="c-opacity-1 c-line-1" opacity="0.498"
                      d="M12.1 6.54294C12.1 3.48162 9.61518 0.999937 6.55 0.999937C3.48482 0.999937 1 3.48162 1 6.54294C1 9.60425 3.48482 12.0859 6.55 12.0859C9.61518 12.0859 12.1 9.60425 12.1 6.54294Z"
                      stroke="#8B949F" stroke-linecap="round" stroke-linejoin="round"></path>
                <path class="c-opacity-1 c-line-1" opacity="0.498" d="M12.5 12.501L14.5 14.501" stroke="#8B949F"
                      stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
            <b-form-input
              list="my-search-key"
              type="text"
              placeholder="Search by name"
              style="line-height: 40px !important; height: 40px !important; padding-left: 50px !important; color: #8B949F !important;"
              v-model="searchKey"
              autocomplete="off"
              debounce="300"
              class="custom-input"
            >
            </b-form-input>
            <datalist id="my-search-key">
              <option v-for="(searchFriend) in searchFriendsList" v-bind:key="searchFriend.id"> {{ searchFriend.name }} </option>
            </datalist>
          </div>
        </div>
      </div>
      <div class="widget">
        <div class="d-flex flex-column">
          <div class="filters-title mb_20">City</div>
          <div class="d-flex align-items-center">
            <input type="radio" class="radio"  id="featured_city" name="city" v-model="selected_city"  value="anywhere"  checked>
            <label class="label-text" for="anywhere">Anywhere </label>
          </div>
          <div v-for="(featured_city,index) in featuredCitiesList" :key="index">
            <div class="d-flex align-items-center">
              <input type="radio" class="radio"  id="featured_city"  v-model="selected_city" name="city" :value="featured_city" >
              <label class="label-text" for="featured_city">{{ featured_city }}</label>
            </div>
          </div>
          <div class="search-input">
            <b-form-input list="my-city-id" placeholder="Choose a City" v-model="computedCity"></b-form-input>
            <datalist id="my-city-id" >
              <option v-for="(city,index) in getCitiesList" :key="index"> {{ city }} </option>
            </datalist>
            <div class="svg-icon">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="c-opacity-1 c-line-1" opacity="0.498"
                      d="M12.1 6.54294C12.1 3.48162 9.61518 0.999937 6.55 0.999937C3.48482 0.999937 1 3.48162 1 6.54294C1 9.60425 3.48482 12.0859 6.55 12.0859C9.61518 12.0859 12.1 9.60425 12.1 6.54294Z"
                      stroke="#8B949F" stroke-linecap="round" stroke-linejoin="round"></path>
                <path class="c-opacity-1 c-line-1" opacity="0.498" d="M12.5 12.501L14.5 14.501" stroke="#8B949F"
                      stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="widget">
        <div class="d-flex flex-column">
          <div class="filters-title mb_20">Education</div>
          <div class="d-flex align-items-center">
            <input type="radio" class="radio" id="customRadio4" name="education" v-model="selected_school" value="anyschool" checked>
            <label class="label-text" for="customRadio4">Any school </label>
          </div>
          <div class="d-flex align-items-center" v-for="(featured_school,index) in featuredSchoolList" :key="index">
            <input type="radio" class="radio" id="featured_school" name="education" v-model="selected_school" :value="featured_school">
            <label class="label-text" for="featured_school">{{ featured_school }}</label>
          </div>

          <div class="search-input">
            <b-form-input list="my-school-id" placeholder="Choose a School" v-model="computedSchool"></b-form-input>
            <datalist id="my-school-id">
              <option v-for="(school,index) in getSchoolsList" :key="index"> {{ school }} </option>
            </datalist>
            <div class="svg-icon">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="c-opacity-1 c-line-1" opacity="0.498"
                      d="M12.1 6.54294C12.1 3.48162 9.61518 0.999937 6.55 0.999937C3.48482 0.999937 1 3.48162 1 6.54294C1 9.60425 3.48482 12.0859 6.55 12.0859C9.61518 12.0859 12.1 9.60425 12.1 6.54294Z"
                      stroke="#8B949F" stroke-linecap="round" stroke-linejoin="round"></path>
                <path class="c-opacity-1 c-line-1" opacity="0.498" d="M12.5 12.501L14.5 14.501" stroke="#8B949F"
                      stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="widget d-flex flex-column">
        <div class="filters-title mb_20">Work</div>
        <div class="d-flex align-items-center">
          <input type="radio" class="radio" id="company" name="work" v-model="selected_work" value="anycompany" checked>
          <label class="label-text" for="company">Any company </label>
        </div>
        <div class="d-flex align-items-center" v-for="(featured_work,index) in featuredWorkSpaceList" :key="index">
          <input type="radio" class="radio" id="featured_work" name="work" v-model="selected_work" :value="featured_work">
          <label class="label-text" for="featured_work">{{ featured_work }} </label>
        </div>
        <div class="search-input">
          <b-form-input list="my-company-id" placeholder="Choose a Company" v-model="computedWork"></b-form-input>
          <datalist id="my-company-id">
            <option v-for="(work,index) in getWorksList" :key="index"> {{ work }} </option>
          </datalist>
          <div class="svg-icon">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="c-opacity-1 c-line-1" opacity="0.498"
                    d="M12.1 6.54294C12.1 3.48162 9.61518 0.999937 6.55 0.999937C3.48482 0.999937 1 3.48162 1 6.54294C1 9.60425 3.48482 12.0859 6.55 12.0859C9.61518 12.0859 12.1 9.60425 12.1 6.54294Z"
                    stroke="#8B949F" stroke-linecap="round" stroke-linejoin="round"></path>
              <path class="c-opacity-1 c-line-1" opacity="0.498" d="M12.5 12.501L14.5 14.501" stroke="#8B949F"
                    stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="col-md-12 px-2 text-center" v-if="buttonShow">
        <button class="apply-filter-btn" type="button" @click="applyFilters">
          <span>APPLY</span>
        </button>
      </div>
    </div>
    <div class="m-b-50 friendRightSec">
      <div v-if="filter==='calenderFilters'" class="col-12 card px-0 calenderMain" style="position:relative;">
        <div class="col-12 px-4 card-body">
          <div class="col-12 px-0 calender-heading calenderFont">Birthday Calendar</div>
          <!-- <div class="col-12 px-0 mt-4" style="position:absolute; border:1px solid #E6EAF0; top:36px; left:0;"></div> -->
          <div class="row" style="text-align: left !important;padding-top: 11px;">
            <vc-calendar :attributes="attributes" mode="multiple" ref="calendar" @update:from-page="daymouseenter">
              <div
                slot="day-popover"
                slot-scope="{ day, dayTitle, attributes }" style="margin:4px;">
                <div style="padding-top:8px !important; padding-bottom:8px !important; padding-left: 0rem">
                  {{moment(day.date).format('DD MMMM YYYY')}}
                </div>
                <div style="margin-top: 4px;">
                  <ul style="list-style-type: none; padding-left: 0rem">
                    <li
                      v-for="{key, customData} in attributes"
                      :key="key"
                      style="border-top: 1px solid #E6EAF0 !important; padding-top:8px !important; padding-bottom:8px !important; padding-left: 0rem">
                      <b-avatar :src="customData.artwork_url" class="ml-0 mr-2" size="sm"/>
                      <span class=" mr-2">
                        {{customData.name }}
                        <span style="color:#8B949F !important;">
                          ({{moment().format('YYYY') - moment(customData.birth).format('YYYY')}} years)
                        </span>
                      </span>
                      <span style="float: right; margin-right: 2px;">
                        <img src="../../../assets/img/svg/msg-icon.svg">
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </vc-calendar>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import moment from 'moment';
  export default {
    props:['filter','buttonShow'],
    data(){
      return{
        range:'',
        current_date:'',
        current_month:'',
        current_year:'',
        date:'',
        selected_school:'anyschool',
        selected_work:'anycompany',
        selected_city:'anywhere',
        searchKey: '',
        keyOfDataList: 0,
        clearlist:'yes',
        birthday_dates :[],
        currentPage: null,
        myFromPage: null,
      }
    },
    mounted() {
      this.$store.dispatch('getCities');
      this.$store.dispatch('getWorkPlace');
      this.$store.dispatch('getSchools');
      //this.current_date = moment().format('DD-MM-YYYY')
      this.current_month = moment().format('MM');
      this.current_year = moment().format('YYYY')

    },
    created: function () {
      this.moment = moment;
    },
    computed: {
      attributes() {
        return [
          ...this.$store.state.friends.monthlyBirthdaysList.map(birthday_date => ({
            dates: this.current_year + birthday_date.birth.slice(4,10),
            highlight:{
              class:'date-circle',
              contentClass: 'date-text',
            },
            popover: {
              visibility: 'click',
              positionFixed: 'bottom-end',
              label: birthday_date.name +birthday_date.dates
            },
            customData: birthday_date,
            event: MouseEvent,
          })),
        ];
      },
      computedCity: {
        get() {
          return this.selected_city === 'anywhere' ? '' : this.selected_city;
        },
        set(value) {
          this.selected_city = value;
        },
      },
      computedWork: {
        get() {
          return this.selected_work === 'anycompany' ? '' : this.selected_work;
        },
        set(value) {
          this.selected_work = value;
        },
      },
      computedSchool: {
        get() {
          return this.selected_school === 'anyschool' ? '' : this.selected_school;
        },
        set(value) {
          this.selected_school = value;
        },
      },
      searchFriendsList() {
        return this.$store.getters['getSearchedFriendsList']
      },
      getCitiesList(){
        return this.$store.getters['getCitiesList']
      },
      featuredCitiesList(){
        return this.$store.getters['featuredCitiesList']
      },
      getSchoolsList(){
        return this.$store.getters['getSchoolsList']
      },
      featuredSchoolList(){
        return this.$store.getters['featuredSchoolList']
      },
      getWorksList(){
        return this.$store.getters['getWorksList']
      },
      featuredWorkSpaceList(){
        return this.$store.getters['featuredWorkSpaceList']
      },
    },
    methods:{
      daymouseenter(page){
        this.current_year = page.year;
        this.current_date = "01-"+page.month+"-"+page.year;
        this.$store.dispatch('getMonthlyBirthdays', this.current_date)

      },
      async searchFriends(searchQuery){
        await this.$store.dispatch('searchFriends',searchQuery);
      },
      async applyFilters(){
        let payload = {
          search : this.searchKey,
          city : this.selected_city === 'anywhere' ? '' : this.selected_city,
          work : this.selected_work === 'anycompany' ? '' : this.selected_work,
          school : this.selected_school === 'anyschool' ? '' : this.selected_school,
        }
        await this.$store.dispatch('getfilteredUsers', payload);
      },
      clearHistory(){
        this.searchKey = null;
        this.selected_city = null;
        this.selected_work = null;
        this.selected_school = null;
        this.$store.dispatch('getFriendsSuggestion',this.clearlist);
      },

    },
    watch: {
      searchKey(value) {
        this.searchFriends(value)
      },
      selected_city(newValue) {
        if (newValue === 'anywhere') {
          this.selected_city = '';
        }
      },
      selected_school(newValue) {
        if (newValue === 'anyschool') {
          this.selected_school = '';
        }
      },
      selected_work(newValue) {
        if (newValue === 'anycompany') {
          this.selected_work = '';
        }
      },
    }
  }
</script>
<style lang="scss">
  .date-circle {
    background: #D1433A !important;
    color:#FFFFFF !important;
  }
  .date-text {
    color: #FFFFFF !important;
    background: #D1433A !important;
  }
.apply-filter-btn{
  color: #ffffff;
  background: #D1433A;
  padding: 8px 0px 8px 0px;
  border-radius: 4px!important;
  border: none;
  font-weight: 500;
  line-height: 15px;
  font-family: 'HelveticaNeuemedium';
  font-size: 12px;
  width:93% !important;
}
input::-webkit-calendar-picker-indicator {
  opacity: 0;
}
.pages-tbs .active{
  border-bottom: 3px solid #D1433A !important;
}
.vc-container{
  border: none !important;
}
.vc-title{
  color: #D1433A !important;
  font-size: 16px !important;
  font-weight: bold !important;
}
.vc-day-content{
  font-size: 14px !important;
  color: #8B949F ;
}
.vc-weeks{
  margin-top:16px !important;
}
.vc-weekday{
  color:#47505B !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  text-align: center !important;
}
.vc-svg-icon{
  border: 1px solid #E6EAF0 !important;
  border-radius: 4px !important;
  width:30px !important;
  height: 30px !important;
  padding: 5px;
}
.vc-day-popover-container{
  color: #071526 !important;
  background-color: #FFFFFF !important;
  border: 1px solid #E7E7E7 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}
.vc-popover-content-wrapper:not(.is-interactive) {
  //pointer-events: none;
  //width: 275px;
  //height: 210px;
  border-radius: 4px;
  left: 100px !important
}
.vc-popover-caret{
  left: 10px !important;
}
.svg-icon2{
  cursor: pointer;
  position: absolute;
  top: 8px;
  color: #47505B !important;
}
.vc-container{
  width: 100% !important;
}
</style>
<style lang="scss" scoped>
.custom-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.custom-input:focus {
  border-color: #D1433A;
  box-shadow: 0 0 5px rgba(209, 67, 58, 0.5);
}

.label-text {
  color: var(--dark-grey-new, #47505B);
  font-family: HelveticaNeueNormal, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.radio  {
  -webkit-appearance: none; 
  appearance: none;
}
.radio {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid rgba(209, 67, 58, 0.5);
  background-color: white;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease, background-image 0.3s ease;
  margin-right: 14px;
  margin-top: -9px;
}

.radio:checked {
  background-color: #D1433A;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='2' fill='%23D1433A'/%3E%3Cpath d='M4.32538 11.7566C3.37089 10.7894 4.72764 9.97541 5.68213 10.9426L7.68213 12.9426L11.6821 9.44212L14.6821 6.44262C15.7321 5.4754 16.8292 6.2435 15.8747 7.30744L8.62059 14.5616C8.2388 14.9485 7.57065 15.0452 7.18886 14.5616L4.32538 11.7566Z' fill='white'/%3E%3C/svg%3E%0A");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.radio:not(:checked) {
  background-color: #FFF8F8;
  background-image: none;
}

.widget .search-input input:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 67, 58, 0.1)!important;
}
</style>
