import Vue from "vue";
import VueRouter from "vue-router";

// const { isNavigationFailure, NavigationFailureType } = VueRouter
import Home from "../views/Landing.vue";
import Register from "../views/Register.vue";

import AllPage from "../views/AllPage.vue";
import AllListElements from "../views/AllListElements.vue";
import AllFilters from "../components/Common/UiList/AllFilters";
import Elements from "../views/Elements.vue";

import PageNotFound from "../views/PageNotFound.vue";
import HomeContainer from "../views/Home/HomeContainer.vue";
import HomeSubContainer from "../views/Home/HomeSubContainer.vue";
import NewsFeed from "@/components/Home/NewsFeed.vue";
import MusicFeed from "@/components/Music/MusicFeed.vue";
import NewsFeedClone from "@/components/Home/NewsFeedClone.vue";
import NewsFeedImage from "@/components/Home/NewsFeedImage.vue";
import NewsFeedVideo from "@/components/Home/NewsFeedVideo.vue";
import Profile from "@/components/Home/Profile/Profile.vue";
import Favorites from "@/components/Home/Favorites.vue";
import * as SMStorage from "../apis/SMStorage";
import store from "../store";
import {
  SYSTEM_DATA_IS_AUTHENTICATED,
  SYSTEM_DATA_IS_WELCOME_PAGE,
  USER_DATA,
} from "../store/types";

// User Home ./Home/Settings
import Settings from "@/components/Home/Settings";
import People from "@/components/Home/People/People.vue";
import Page from "@/components/Home/Pages/Page.vue";
import Article from "@/components/Home/Article/Article.vue";
import CreateNewArticle from "@/components/Home/Article/CreateNewArticle.vue";
import ExplorePages from "@/components/Home/ExplorePages/ExplorePages.vue";

// Group Section
import Group from "@/components/Group/Pages/Group.vue";
import GroupMainContainer from "@/components/Group/MainContainer.vue";
import GroupSubStructure from "@/components/Group/SubContainer.vue";
import GroupAdmins from "@/components/Group/Pages/Admins.vue";
import GroupEvents from "@/components/Group/Pages/Events.vue";
import MemberRequest from "@/components/Group/Pages/MemberRequest.vue";
import GroupGeneralSettings from "@/components/Group/Pages/GeneralSettings.vue";
import GroupInformation from "@/components/Group/Pages/GroupInformation.vue";

// Formum Section
import Forum from "@/components/Home/Forum/Forum.vue";

// PageManager Module Start
import PMMainContainer from "@/components/PageManager/PMMainContainer";
import CreatePage from "@/components/PageManager/Pages/CreatePage";
import PMSubContainer from "@/components/PageManager/PMSubContainer";
import ArtistProfile from "@/components/PageManager/Pages/ArtistProfile";
import NewPage from "@/components/PageManager/Pages/NewPage";
import NewPageSpecification from "@/components/PageManager/Pages/NewPageSpecification";
import FirstUpload from "@/components/PageManager/Pages/FirstUploadElement";
import GeneralSettings from "@/components/PageManager/Pages/GeneralSettings";
import WorkSettings from "@/components/PageManager/Pages/WorkSettings";
import PaymentSettings from "@/components/PageManager/Pages/PaymentSettings";
import ArtistNotifications from "@/components/PageManager/Pages/ArtistNotifications";
import Music from "@/components/PageManager/Pages/Music/index.vue";
import AddMedia from "@/components/PageManager/Pages/Music/uploadMedia.vue";
import BasicInformation from "@/components/PageManager/Pages/EditPages/BasicInformation";
import AboutAndSkills from "@/components/PageManager/Pages/EditPages/AboutAndSkills";
import WorkAndEducation from "@/components/PageManager/Pages/EditPages/WorkAndEducation";
import Projects from "@/components/PageManager/Pages/EditPages/Projects";
import Availability from "@/components/PageManager/Pages/EditPages/Availibility";
import ContactInformation from "@/components/PageManager/Pages/EditPages/ContactInformation";
import Location from "@/components/PageManager/Pages/EditPages/Location";
import oldPageInfo from "@/components/PageManager/Pages/EditPages/old-page-info";
import Sponsorship from "@/components/PageManager/Pages/Sponsorship";
import Events from "@/components/PageManager/Pages/Events";
import Booking from "@/components/PageManager/Pages/Booking";
import BookingDetail from "@/components/PageManager/Pages/BookingDetail";
import BookingDetail1 from "@/components/PageManager/Pages/BookingDetail1";

import Jobs from "@/components/PageManager/Pages/Jobs";
import Verification from "@/components/PageManager/Pages/Verification";
import Admins from "@/components/PageManager/Pages/Admins";
import Engagement from "@/components/PageManager/Pages/Engagement";
import BookArtist from "@/components/PageManager/Pages/BookArtist";
import Company from "@/components/PageManager/Pages/Company/Company";
import InSights from "@/components/PageManager/Pages/InSights";
import SponsorshipMember from "@/components/PageManager/Pages/SponsorshipMember";
import Partner from "@/components/PageManager/Pages/Partners";

// ClubManager Module Start
import ClubCenterContainer from "@/components/ClubManager/Club/ClubCenterContainer";

//Market
import AddProductSongAlbum from "@/components/Market/Product/AddProductSongAlbum";
import AddProductSample from "@/components/Market/Product/AddProductSample";
//Market
// Music Details
// import TrackList from '@/components/PageManager/Pages/MusicDetailPage/TrackList'
// import RelatedAlbumList from '@/components/PageManager/Pages/MusicDetailPage/RelatedAlbumList'
// import AlbumDetailPage from '@/components/PageManager/Pages/MusicDetailPage/AlbumDetailPage'
// import TrackDetailPage from './components/user/Music/Pages/MusicDetailPage/TrackDetailPage'
// Music Module Start
/// Dynamic channels
import SongAndAlbumChannelWithGridAndList from "@/components/Music/Pages/ChannelsPages/SongAndAlbumChannelWithGridAndList.vue";
import ArtistOrPageChannelWithCards from "@/components/Music/Pages/ChannelsPages/ArtistOrPageChannelWithCards.vue";
import AllCountryList from "@/components/Music/Pages/ChannelsPages/AllCountryList.vue";
/// Dynamic channels
// Group Section
import Overview from "@/components/Music/Pages/Overview.vue";
import MusicMainContainer from "@/components/Music/MainContainer.vue";
import MusicSubStructure from "@/components/Music/SubContainer.vue";
import AlbumDetailListing from "@/components/Music/Pages/AlbumDetailListing.vue";
import IndividualFullViewPage from "../components/Music/Pages/IndividualFullViewPage";
import PopularArtistListing from "@/components/Music/Pages/PopularArtistListing.vue";
import PopularLabelListing from "@/components/Music/Pages/PopularLabelListing.vue";
import Top100Selling from "@/components/Music/Pages/Top100Sellings.vue";
import GenersMoods from "@/components/Music/Pages/GenersMoods.vue";
import RecentReviewsDetailShow from "@/components/Music/Pages/RecentReviewsDetailShow.vue";
import NewReleases from "@/components/Music/Pages/NewReleases.vue";
import Charts from "@/components/Music/Pages/Charts.vue";
import ChartSeeAll from "@/components/Common/Elements/ChartSeeAll.vue";
import Reviews from "@/components/Music/Pages/Reviews.vue";
import Podcasts from "@/components/Music/Pages/Podcasts.vue";
import FeaturedPodCastSeeAll from "@/components/Music/Pages/FeaturedPodCastSeeAll.vue";
import Favourite from "@/components/Music/Pages/Favourite.vue";
import Wishlist from "@/components/Music/Pages/Wishlist.vue";
import Collection from "@/components/Music/Pages/Collection.vue";

// Events
import eventsMainContainer from "@/components/Events/MainContainer";
import eventsSubStructure from "@/components/Events/SubContainer";
import eventsOverview from "@/components/Events/Pages/Overview";
import eventsFavorites from "@/components/Events/Pages/Favorites.vue";
import eventsInvites from "@/components/Events/Pages/Invites.vue";
import eventsMyTickets from "@/components/Events/Pages/MyTickets.vue";
import mySingleTicketDetail from "@/components/Events/Pages/MySingleTicketDetail.vue";
import findEvents from "@/components/Events/Pages/FindEvents";
import eventsDetail from "@/components/Events/Pages/Detail";
import newEvents from "@/components/Events/Pages/NewEvents";

//Events Manager
import EMMainContainer from "@/components/Events/EventManager/EMMainContainer.vue";
import EMSubContainer from "@/components/Events/EventManager/EMSubContainer.vue";
import EventManager_Dashboard from "@/components/Events/EventManager/EventManagerPages/EM_Dashboard.vue";
import EventManager_Events from "@/components/Events/EventManager/EventManagerPages/EM_Events.vue";
import EventManager_Tickets from "@/components/Events/EventManager/EventManagerPages/EM_Tickets.vue";
import EventManager_Orders from "@/components/Events/EventManager/EventManagerPages/EM_Orders.vue";
import EventManager_Settings from "@/components/Events/EventManager/EventManagerPages/EM_Settings.vue";
import EventManager_Insights from "@/components/Events/EventManager/EventManagerPages/EM_Insights.vue";
import EventManager_Ads from "@/components/Events/EventManager/EventManagerPages/EM_Ads.vue";
import EM_EventDetail from "@/components/Events/EventManager/EventManagerPages/EM_EventDetail.vue";
import EM_AdsDetail from "@/components/Events/EventManager/EventManagerPages/EM_AdsDetail.vue";

//Jobs Manager
import JMMainContainer from "@/components/Jobs/JobsManager/JMMainContainer.vue";
import JMSubContainer from "@/components/Jobs/JobsManager/JMSubContainer.vue";

//Market Manager
import MMMainContainer from "@/components/Market/MarketManager/MMMainContainer.vue";
import MMSubContainer from "@/components/Market/MarketManager/MMSubContainer.vue";
import MMDashboard from "@/components/Market/MarketManager/Pages/Index.vue";

// MUSIC ALL components
import AllComponentsUsed from "@/components/Music/UI-components/AllComponentsUsed.vue";
// MUSIC ALL components
// Design Guide
import DesignGuideContainer from "@/components/DesignGuide/MainContainer.vue";
import MusicUIGridElements from "@/components/DesignGuide/Music/MusicUIGridElements.vue";
// Design Guide
//test
import Test from "@/components/test.vue";
//Test Page
import TestPage from "@/components/TestPage.vue";
//Embed
import ImageAndWaveOnShareModal from "@/components/Common/ShareModal/ImageAndWaveOnShareModal";
import TestChatMusic from "../components/TestChatMusic";
import ChatSubContainer from "../components/FullViewChat/ChatSubContainer";
import MusicDiscussion from "../components/FullViewChat/MusicDiscussion";
import BuyTicket from "../components/Events/components/Detail/buyTickets/BuyTickets.vue";
import ShopingCart from "../components/Events/components/Detail/buyTickets/ShopingCart.vue";
import MyOrders from "../components/Events/components/Detail/buyTickets/MyOrders.vue";
import ClubInformation from "../components/PageManager/Pages/ClubInformation";
import MemberRequests from "../components/PageManager/Pages/MemberRequests";
import ClubGeneralSettings from "../components/PageManager/Pages/ClubGeneralSettings";
import LinkPrivewPage from "../components/LinkPrivewPage";
import PageNotifications from "../components/PageNotification/PageNotifications.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "landing",
    component: Register,
    meta: {
      requiresUnAuth: true,
    },
  },
  {
    path: '/auth/:provider/callback',
    component: Home,
    meta: {
      requiresUnAuth: false,
      requiresAuth: false,
    }
  },
  // {
  //   path: "/register",
  //   name: "landing",
  //   component: Register,
  //   meta: {
  //     requiresUnAuth: true,
  //   },
  // },
  {
    path: "/affiliate-signup/:key",
    name: "landing",
    component: Home,
    meta: {
      requiresUnAuth: true,
    },
  },
  {
    path: "/allpages",
    name: "allPages",
    component: AllPage,
    meta: {
      requiresUnAuth: true,
    },
  },
  {
    path: "/ui/all",
    name: "allPages",
    component: AllFilters,
    meta: {
      requiresUnAuth: true,
    },
  },
  {
    path: "/all-list-elements",
    name: "all-list-elements",
    component: AllListElements,
    meta: {
      requiresUnAuth: true,
    },
  },
  {
    path: "/elements",
    name: "elements",
    component: Elements,
    meta: {
      requiresUnAuth: true,
    },
  },
  {
    path: "/design",
    name: "design.container",
    component: DesignGuideContainer,
    meta: {
      requiresUnAuth: false,
    },
    children: [
      {
        path: "music-ui-grid",
        name: "design.music.ui-grid",
        component: MusicUIGridElements,
        meta: {
          requiresUnAuth: false,
        },
      },
    ],
  },
  {
    path: "/home",
    name: "home.dashboard",
    component: HomeContainer,
    meta: {
      requiresAuth: true,
      auth: {
        roles: 2,
        redirect: { name: "login" },
        forbiddenRedirect: "/403",
      },
    },
    redirect: { name: "page.default" },
    children: [
      {
        path: "page",
        name: "page.default",
        component: HomeSubContainer,
        redirect: { name: "home.newsfeed" },
        children: [
          {
            path: "favorites",
            name: "home.favorites",
            component: Favorites,
            props: { activeHeader: "home" },
          },
          {
            path: "news-feed",
            name: "home.newsfeed",
            component: NewsFeed,
            props: { activeHeader: "home" },
          },
          {
            path: "post-detail/:id",
            name: "home.newsfeed-detail",
            component: NewsFeedClone,
            props: { activeHeader: "home" },
          },
          {
            path: ":name/post-detail/:id",
            name: "home.other-detail",
            component: NewsFeedClone,
            props: { activeHeader: "home" },
          },
          {
            path: ":name/post-detail/:id/:page_id",
            name: "home.newsfeed-detail",
            component: NewsFeedClone,
            props: { activeHeader: "home" },
          },
          {
            path: "feed-image/:id",
            name: "home.newsfeed-image",
            component: NewsFeedImage,
            props: { activeHeader: "home" },
            meta: {
              showLeftNav: false,
            },
          },
          {
            path: ":name/feed-image/:id",
            name: "home.newsfeed-image",
            component: NewsFeedImage,
            props: { activeHeader: "home" },
          },
          {
            path: "feed-video/:id",
            name: "home.newsfeed-video",
            component: NewsFeedVideo,
            props: { activeHeader: "home", showFooter: false },
          },
          {
            path: "profile",
            name: "profile",
            component: Profile,
            props: { activeHeader: "home", activePage: "posts" },
          },
          {
            path: "user-profile/:username",
            name: "home.profile",
            component: Profile,
            props: { activeHeader: "home", activePage: "posts", custom: true },
            // meta: {
            //   showLeftNav: false,
            // },
          },
          {
            path: "people",
            name: "people",
            component: People,
            props: { activeHeader: "home" },
          },
          {
            path: "explore-pages",
            name: "explore-pages",
            component: Page,
            props: { activeHeader: "home" },
          },
          {
            path: "forum",
            name: "forum",
            component: Forum,
            props: { activeHeader: "home" },
          },
          {
            path: "forum/:name/:id",
            name: "forum",
            component: Forum,
            props: { activeHeader: "home" },
          },

          // {
          //   path: "events",
          //   name: "events",
          //   component: Events,
          //   props: { activeHeader: "home" }
          // } ,
          {
            path: "article",
            name: "article",
            component: Article,
            props: { activeHeader: "home" },
            // meta: {
            //   showLeftNav: false,
            // },
          },
          {
            path: "create-new-article",
            name: "create-new-article",
            component: CreateNewArticle,
            props: { activeHeader: "home" },
          },
          {
            path: "create-new-article/:type/:subtype/:id",
            name: "create-new-article",
            component: CreateNewArticle,
            props: { activeHeader: "home" },
          },
          {
            path: "my-page",
            name: "my-page",
            component: ExplorePages,
            props: { activeHeader: "home" },
          },
          {
            path: ":type",
            name: "pages",
            component: ExplorePages,
            props: { activeHeader: "home" },
          },
          {
            path: "wishlist",
            name: "wishlist",
            component: Profile,
            props: { activeHeader: "home", activePage: "wishlist" },
          },
          {
            path: "collection",
            name: "collection",
            component: Profile,
            props: { activeHeader: "home", activePage: "collection" },
          },
          {
            path: "events",
            name: "events",
            component: Profile,
            props: { activeHeader: "home", activePage: "events" },
          },
        ],
      },
      { path: "news-feed", name: "home.newsfeed", component: NewsFeed },
      { path: "/home/settings", component: Settings },
    ],
  },
  {
    path: "/group",
    name: "group.home",
    component: GroupMainContainer,
    redirect: { name: "group.default" },
    children: [
      {
        path: "page",
        name: "group.default",
        component: GroupSubStructure,
        redirect: { name: "group.discussion" },
        children: [
          {
            path: "discussion",
            name: "group.discussion",
            component: Group,
            props: { activeHeader: "home" },
          },
          {
            path: "group-admins",
            name: "group.admin",
            component: GroupAdmins,
            props: { activeHeader: "home" },
          },
          {
            path: "group-events",
            name: "group.events",
            component: GroupEvents,
            props: { activeHeader: "home" },
          },
          {
            path: "member-request",
            name: "group.member",
            component: MemberRequest,
            props: { activeHeader: "home" },
          },
          {
            path: "member-general-settings",
            name: "group.member",
            component: GroupGeneralSettings,
            props: { activeHeader: "home" },
          },
          {
            path: "group-information",
            name: "group.information",
            component: GroupInformation,
            props: { activeHeader: "home" },
          },
        ],
      },
    ],
    meta: {
      auth: {
        roles: 2,
        redirect: { name: "login" },
        forbiddenRedirect: "/403",
      },
    },
  },
  {
    path: "/events",
    name: "Events",
    component: eventsMainContainer,
    redirect: { name: "events.default" },
    children: [
      {
        path: "page",
        name: "events.default",
        component: eventsSubStructure,
        redirect: { name: "events.overview" },
        children: [
          {
            path: "overview",
            name: "events.overview",
            component: eventsOverview,
            props: { activeHeader: "home", ShowMusicFooter: true },
            meta: { event_Type_id: "" },
          },
          {
            path: "gigs",
            name: "events.gigs",
            component: eventsOverview,
            props: { activeHeader: "home", ShowMusicFooter: true },
            meta: { event_Type_id: 1 },
          },
          {
            path: "clubs",
            name: "events.clubs",
            component: eventsOverview,
            props: { activeHeader: "home", ShowMusicFooter: true },
            meta: { event_Type_id: 2 },
          },
          {
            path: "festivals",
            name: "events.festivals",
            component: eventsOverview,
            props: { activeHeader: "home", ShowMusicFooter: true },
            meta: { event_Type_id: 3 },
          },
          {
            path: "livestreaming",
            name: "events.livestreaming",
            component: eventsOverview,
            props: { activeHeader: "home", ShowMusicFooter: true },
            meta: { event_Type_id: 4 },
          },
          {
            path: "favorites",
            name: "events.Favorites",
            component: eventsFavorites,
            props: { activeHeader: "home", ShowMusicFooter: true },
          },
          {
            path: "invites",
            name: "events.Invites",
            component: eventsInvites,
            props: { activeHeader: "home", ShowMusicFooter: true },
          },
          {
            path: "my_Tickets",
            name: "events.MyTickets",
            component: eventsMyTickets,
            props: { activeHeader: "home", ShowMusicFooter: true },
          },
          {
            path: "ticket_detail/:order_id/:ticket_id",
            name: "mySingle.Ticket",
            component: mySingleTicketDetail,
            props: { activeHeader: "home", ShowMusicFooter: true },
          },
          {
            path: "events/find/:eventsName",
            name: "events.findEvents",
            component: findEvents,
            props: { ShowMusicFooter: false },
          },
          {
            path: "events/detail/:id",
            name: "events.Detail",
            component: eventsDetail,
            props: { ShowMusicFooter: true },
          },
          {
            path: "buy/ticket/:id",
            name: "buy.Ticket",
            component: BuyTicket,
            props: { ShowMusicFooter: false },
          },
          {
            path: "shoping/cart",
            name: "shopingCart",
            component: ShopingCart,
            props: { ShowMusicFooter: false },
          },
          {
            path: "my/orders",
            name: "MyOrders",
            component: MyOrders,
            props: { ShowMusicFooter: false },
          },
          {
            path: "events/new-events",
            name: "events.new-events",
            component: newEvents,
          },
        ],
      },
    ],
  },
  {
    path: "/music",
    name: "music",
    component: HomeContainer,
    redirect: { name: "music.default" },
    children: [
      {
        path: "page",
        name: "music.default",
        component: MusicSubStructure,
        redirect: { name: "music.overview" },
        children: [
          {
            path: "news-feed",
            name: "music.newsfeed",
            component: MusicFeed,
            props: { activeHeader: "music" },
          },
          {
            path: "all-components",
            name: "music.components",
            component: AllComponentsUsed,
            props: { activeHeader: "home", ShowMusicFooter: true },
          },
          {
            path: "overview",
            name: "music.overview",
            component: Overview,
            props: { activeHeader: "home", ShowMusicFooter: true },
          },
          {
            path: "see-all",
            name: "music.see-all",
            component: AlbumDetailListing,
            props: true,
          },
          {
            path: "song-album-channel/:channelName",
            name: "music.song-album-channel",
            component: SongAndAlbumChannelWithGridAndList,
          },
          {
            path: "artist-channel/:channelName",
            name: "music.artist-channel",
            component: ArtistOrPageChannelWithCards,
          },
          {
            path: "charts/all-countries",
            name: "music.Charts ",
            component: AllCountryList,
          },
          {
            path: "continue-listening-to",
            name: "music.continue-listening-to",
            component: AlbumDetailListing,
            props: {
              pageFrom: "Overview",
              pageTitle: "continue listening to",
              pageFilter: "recent_played_songs",
              redirectUrl: "music.overview",
              componentType: "buy",
              thirdTxt: "",
              activeHeader: "home",
              header: true,
            },
          },
          {
            path: "top-albums-of-the-week",
            name: "music.top-albums-of-the-week",
            component: AlbumDetailListing,
            props: {
              pageFrom: "Overview",
              pageTitle: "Top Albums Of The Week",
              redirectUrl: "music.overview",
              thirdTxt: "UPDATED DAILY",
              pageFilter: "top-albums-of-the-week",
              activeHeader: "home",
              header: true,
            },
          },
          {
            path: "hot-new-songs",
            name: "music.hot-new-songs",
            component: AlbumDetailListing,
            props: {
              pageFrom: "Overview",
              pageTitle: "Hot New Songs",
              redirectUrl: "music.overview",
              thirdTxt: "UPDATED DAILY",
              pageFilter: "18_songs_per_week_per_geners",
              activeHeader: "home",
              header: true,
            },
          },
          {
            path: "featured",
            name: "music.featured",
            component: AlbumDetailListing,
            props: {
              pageFrom: "Genres",
              pageTitle: "Feature Cold Songs",
              redirectUrl: "genres-moods",
              pageFilter: "featured_songs",
              thirdTxt: "UPDATED DAILY",
              activeHeader: "home",
              header: false,
            },
          },
          {
            path: "treanding",
            name: "music.trending-now",
            component: AlbumDetailListing,
            props: {
              pageFrom: "Genres",
              pageTitle: "Treanding",
              redirectUrl: "genres-moods",
              pageFilter:
                "most_popular_track_albums_tat_realeased_in_last_month",
              thirdTxt: "UPDATED DAILY",
              activeHeader: "home",
              header: false,
            },
          },

          {
            path: "trending-artist-this-week",
            name: "music.trending-artist-this-week",
            component: PopularArtistListing,
            props: {
              pageFrom: "Overview",
              pageTitle: "Trending Artist",
              redirectUrl: "music.overview",
              pageFilter: "trending-artist-this-week",
              thirdTxt: "UPDATED DAILY",
              activeHeader: "home",
              header: true,
            },
          },
          // {
          //   path: "popular-label-listing",
          //   name: "music.popular-label-listing",
          //   component: PopularLabelListing,
          //   props: { activeHeader: "home" },
          // },
          {
            path: "top-selling",
            name: "music.top-selling",
            component: Top100Selling,
            props: {
              pageFrom: "Overview",
              pageTitle: "TOP 100 UK ALBUMS",
              redirectUrl: "music.overview",
              thirdTxt: "UPDATED DAILY",
              activeHeader: "home",
              header: false,
            },
          },
          {
            path: "genres-moods",
            name: "music.genres-moods",
            component: GenersMoods,
            props: { activeHeader: "home" },
          },
          {
            path: "recent-views-see-all",
            name: "music.recent-views-see-all",
            component: RecentReviewsDetailShow,
            props: {
              pageFrom: "Genres",
              pageTitle: "Recent Reviews",
              redirectUrl: "genres-moods",
              pageFilter: "most_recent_reviews_overall_as_default",
              thirdTxt: "",
              activeHeader: "home",
              header: false,
            },
          },
          {
            path: "who-to-follow",
            name: "music.who-to-follow",
            component: PopularArtistListing,

            props: {
              pageFrom: "Genres",
              pageTitle: "POPULAR ARTIST",
              redirectUrl: "genres-moods",
              pageFilter: "artists_received_most_plays_in_that_week",
              thirdTxt: "",
              activeHeader: "home",
              header: false,
            },
          },

          {
            path: "new-releases",
            name: "music.new-releases",
            component: NewReleases,
            props: { activeHeader: "home" },
          },
          {
            path: "new-music",
            name: "music.new-music",
            component: AlbumDetailListing,
            props: {
              pageFrom: "New Releases",
              pageTitle: "New Music",
              redirectUrl: "new-releases",
              thirdTxt: "",
              activeHeader: "home",
              header: false,
            },
          },
          {
            path: "top-new-singles",
            name: "music.top-new-singles",
            component: AlbumDetailListing,
            props: {
              pageFrom: "New Releases",
              pageTitle: "Top New Singles",
              redirectUrl: "new-releases",
              thirdTxt: "",
              activeHeader: "home",
              header: false,
            },
          },
          {
            path: "top-new-albums",
            name: "music.top-new-albums",
            component: AlbumDetailListing,
            props: {
              pageFrom: "New Releases",
              pageTitle: "Top New Albums",
              redirectUrl: "new-releases",
              thirdTxt: "",
              activeHeader: "home",
              header: false,
            },
          },
          {
            path: "pre-order-future-releases",
            name: "music.pre-order-future-releases",
            component: AlbumDetailListing,
            props: {
              pageFrom: "New Releases",
              pageTitle: "Pre-Order Future Release",
              redirectUrl: "new-releases",
              thirdTxt: "",
              activeHeader: "home",
              header: false,
            },
          },
          {
            path: "recent-releases",
            name: "music.recent-releases",
            component: AlbumDetailListing,
            props: {
              pageFrom: "New Releases",
              pageTitle: "Recent Release",
              redirectUrl: "new-releases",
              thirdTxt: "",
              activeHeader: "home",
              header: false,
            },
          },
          {
            path: "popular-artist-this-week",
            name: "music.popular-artist-this-week",
            component: PopularArtistListing,
            props: {
              pageFrom: "New Releases",
              pageTitle: "Popular Artist This Week",
              redirectUrl: "new-releases",
              activeHeader: "home",
            },
          },
          {
            path: "charts",
            name: "music.charts",
            component: Charts,
            props: { activeHeader: "home" },
          },
          {
            path: "chart-see-all",
            name: "chart-see-all",
            component: ChartSeeAll,
            props: {
              pageFrom: "Charts",
              pageTitle: "Charts",
              redirectUrl: "charts",
              thirdTxt: "",
              activeHeader: "home",
              header: false,
            },
          },
          {
            path: "chart-top-selling/:countryId",
            name: "music.chart-top-selling",
            component: Top100Selling,
            props: {
              pageFrom: "Charts",
              pageTitle: "TOP 100 UK Singles",
              redirectUrl: "charts",
              thirdTxt: "UPDATED DAILY",
              activeHeader: "home",
              header: false,
            },
          },
          {
            path: "reviews",
            name: "music.reviews",
            component: Reviews,
            props: { activeHeader: "home" },
          },
          {
            path: "featured-albums",
            name: "music.featured-albums",
            component: RecentReviewsDetailShow,
            props: {
              pageFrom: "Reviews",
              pageTitle: "Featured Album Reviews",
              redirectUrl: "reviews",
              thirdTxt: "",
              activeHeader: "home",
              header: false,
            },
          },
          {
            path: "featured-single-reviews",
            name: "music.featured-single-reviews",
            component: RecentReviewsDetailShow,
            props: {
              pageFrom: "Reviews",
              pageTitle: "Featured Single Reviews",
              redirectUrl: "reviews",
              pageFilter: "most_recent_reviews_overall_as_default",
              thirdTxt: "",
              activeHeader: "home",
              header: false,
            },
          },
          {
            path: "featured-podcast-reviews",
            name: "music.featured-podcast-reviews",
            component: RecentReviewsDetailShow,
            props: {
              pageFrom: "Reviews",
              pageTitle: "Featured Podcast Reviews",
              redirectUrl: "reviews",
              thirdTxt: "",
              activeHeader: "home",
              header: false,
            },
          },
          {
            path: "explore-review-treanding",
            name: "music.explore-review-treanding",
            component: PopularArtistListing,
            props: {
              pageFrom: "Reviews",
              pageTitle: "Treanding Artist Reviews",
              redirectUrl: "reviews",
              thirdTxt: "UPDATED DAILY",
              activeHeader: "home",
              header: false,
            },
          },
          {
            path: "podcasts",
            name: "music.podcasts",
            component: Podcasts,
            props: { activeHeader: "home" },
          },
          {
            path: "featured-podcast-see-all",
            name: "music.featured-podcast-see-all",
            component: FeaturedPodCastSeeAll,
            props: {
              pageFrom: "Podcasts",
              pageTitle: "TRENDING podcasts",
              redirectUrl: "podcasts",
              thirdTxt: "UPDATED DAILY",
              activeHeader: "home",
              header: false,
            },
          },
          {
            path: "podcast-recent-reviews",
            name: "music.podcast-recent-reviews",
            component: RecentReviewsDetailShow,
            props: {
              pageFrom: "Podcast",
              pageTitle: "Recent Reviews",
              redirectUrl: "podcasts",
              thirdTxt: "",
              activeHeader: "home",
              header: false,
            },
          },
          {
            path: "treanding-artist-reviews",
            name: "music.treanding-artist-reviews",
            component: PopularArtistListing,
            props: {
              pageFrom: "Podcasts",
              pageTitle: "TRENDING PODCASTERS",
              redirectUrl: "podcasts",
              thirdTxt: "UPDATED DAILY",
              activeHeader: "home",
              header: false,
            },
          },

          {
            path: "favorites",
            name: "music.favorite",
            component: Favourite,
            props: { activeHeader: "home" },
          },
          {
            path: "music-collection",
            name: "music.music-collection",
            component: Collection,
            props: { activeHeader: "home" },
          },
          {
            path: "music-wishlist",
            name: "music.music-wishlist",
            component: Wishlist,
            props: { activeHeader: "home" },
          },
          {
            path: ":type/:id",
            name: "song.individual-view",
            component: IndividualFullViewPage,
            props: {
              activeHeader: "home",
              header: false,
            },
          },
        ],
      },
    ],
    meta: {
      auth: {
        roles: 2,
        redirect: { name: "login" },
        forbiddenRedirect: "/403",
      },
    },
  },
  {
    path: "/page-manager",
    name: "page-manager.home",
    component: HomeContainer,
    redirect: { name: "page-manager.default" },
    children: [
      {
        path: "page",
        name: "page-manager.default",
        component: PMSubContainer,
        redirect: { name: "page-manager.artist-profile" },
        children: [
          {
            path: "create-page",
            name: "page-manager.create-page",
            showLeftNav: false,
            component: CreatePage,
            meta: {
              showLeftNav: false,
            },
          },
          {
            path: "artist-profile",
            name: "page-manager.artist-profile",
            showLeftNav: true,
            component: ArtistProfile,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "new-page",
            name: "home.new-profile",
            showLeftNav: true,
            component: NewPage,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "create-new-page",
            name: "page.create-new-page",
            showLeftNav: false,
            component: NewPageSpecification,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: false,
            },
          },
          {
            path: "artist/:id",
            name: "home.page.view",
            showLeftNav: true,
            component: FirstUpload,
            props: {
              showLeftNav: true,
              ShowMusicFooter: false,
              activeHeader: "music",
            },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "company/:id",
            name: "home.page.view",
            showLeftNav: true,
            component: FirstUpload,
            props: {
              showLeftNav: true,
              ShowMusicFooter: false,
              activeHeader: "music",
            },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "club/:id",
            name: "home.club.view",
            showLeftNav: true,
            component: ClubCenterContainer,
            props: {
              showLeftNav: true,
              ShowMusicFooter: false,
              activeHeader: "posts",
            },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "party/:id",
            name: "home.party.view",
            showLeftNav: true,
            component: ClubCenterContainer,
            props: {
              showLeftNav: true,
              ShowMusicFooter: false,
              activeHeader: "posts",
            },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "/page-notifications",
            name: "PageNotification",
            component: PageNotifications,
          },
          {
            path: "notification/:id/:role",
            name: "notification",
            showLeftNav: true,
            component: FirstUpload,
            props: {
              showLeftNav: false,
              ShowMusicFooter: false,
              activeHeader: "music",
            },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: false,
            },
          },
          {
            path: "page-information/:id",
            name: "page-information",
            showLeftNav: true,
            component: BasicInformation,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "club/page-information/:id",
            name: "club-page-information",
            showLeftNav: true,
            component: ClubInformation,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "party/page-information/:id",
            name: "party-page-information",
            showLeftNav: true,
            component: ClubInformation,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "about-and-skills/:id",
            name: "page-about-and-skill",
            showLeftNav: true,
            component: AboutAndSkills,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "work-and-education/:id",
            name: "page-work-and-education",
            showLeftNav: true,
            component: WorkAndEducation,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "projects/:id",
            name: "page-projects",
            showLeftNav: true,
            component: Projects,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "availability/:id",
            name: "page-Availability",
            showLeftNav: true,
            component: Availability,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "contact-information/:id",
            name: "page-ContactInformation",
            showLeftNav: true,
            component: ContactInformation,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "location/:id",
            name: "page-Location",
            showLeftNav: true,
            component: Location,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "general-settings/:id",
            name: "general-settings",
            showLeftNav: true,
            component: GeneralSettings,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "club/general-settings/:id",
            name: "club-general-settings",
            showLeftNav: true,
            component: ClubGeneralSettings,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "party/general-settings/:id",
            name: "party-general-settings",
            showLeftNav: true,
            component: ClubGeneralSettings,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "work-settings/:id",
            name: "work-settings",
            showLeftNav: true,
            component: WorkSettings,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "payment-settings/:id",
            name: "payment-settings",
            showLeftNav: true,
            component: PaymentSettings,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "music/:id",
            name: "page-music",
            showLeftNav: true,
            component: Music,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "add-product/:id",
            name: "Add Product",
            showLeftNav: true,
            component: AddMedia,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "old-page-info/:id",
            name: "old-page-settings",
            showLeftNav: true,
            component: oldPageInfo,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "artist-notifications/:id",
            name: "artist-notifications",
            showLeftNav: true,
            component: ArtistNotifications,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "sponsorship/:id",
            name: "sponsorship",
            showLeftNav: true,
            component: Sponsorship,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "events/:id",
            name: "events",
            showLeftNav: true,
            component: Events,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "booking/:id",
            name: "events",
            showLeftNav: true,
            component: Booking,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "booking-details/:id",
            name: "events",
            showLeftNav: true,
            component: BookingDetail,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "booking-details1/:id",
            name: "events",
            showLeftNav: true,
            component: BookingDetail1,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "jobs/:id",
            name: "home.jobs",
            showLeftNav: true,
            component: Jobs,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "verification/:id",
            name: "home.verification",
            showLeftNav: true,
            component: Verification,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "admins/:id",
            name: "admins",
            showLeftNav: true,
            component: Admins,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "club/member-requests/:id",
            name: "member-requests",
            showLeftNav: true,
            component: MemberRequests,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "party/member-requests/:id",
            name: "member-requests",
            showLeftNav: true,
            component: MemberRequests,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "engagement/:id",
            name: "engagement",
            showLeftNav: true,
            component: Engagement,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "book-artist/:id",
            name: "book-artist",
            showLeftNav: false,
            component: BookArtist,
            props: { ShowMusicFooter: false, activeHeader: "music" },
          },
          {
            path: "company/:id",
            name: "home.company",
            showLeftNav: true,
            component: Company,
            props: { ShowMusicFooter: false, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          // { path:'track-list', name:'home.track-list',props: true,showLeftNav: true, component:TrackList, props: { ShowMusicFooter: false , activeHeader: 'music'}},
          // { path:'related-album-list', name:'related-album-list',props: true,showLeftNav: false, component:RelatedAlbumList, props: { ShowMusicFooter: false , activeHeader: 'music'}},
          // { path:'album-detail-page', name:'album-detail-page',props: true,showLeftNav: true, component:AlbumDetailPage, props: { ShowMusicFooter: true , activeHeader: 'music'}},
          // { path:'track-detail-page', name:'track-detail-page',props: true,showLeftNav: true, component:TrackDetailPage, props: { ShowMusicFooter: true , activeHeader: 'music'}},
          {
            path: "in-sights/:id",
            name: "in-sights",
            showLeftNav: true,
            component: InSights,
            props: { ShowMusicFooter: true, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "sponsorship-member/:id",
            name: "sponsorship-member",
            showLeftNav: true,
            component: SponsorshipMember,
            props: { ShowMusicFooter: true, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "partner/:id",
            name: "partner",
            showLeftNav: true,
            component: Partner,
            props: { ShowMusicFooter: true, activeHeader: "music" },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
        ],
      },
    ],
    meta: {
      auth: {
        roles: 2,
        redirect: { name: "login" },
        forbiddenRedirect: "/403",
      },
    },
  },
  {
    path: "/chat-manager",
    name: "chat-manager.home",
    component: HomeContainer,
    redirect: { name: "chat-manager.default" },
    children: [
      {
        path: "chat",
        name: "chat-manager.default",
        component: ChatSubContainer,
        props: { ShowMusicFooter: false },
        meta: {
          ShowMusicFooter: false,
        },
      },
      // {
      //   path: "test",
      //   name: "chat-manager.default",
      //   component: MusicDiscussion,
      //   props: { ShowMusicFooter: false },
      //   meta: {
      //     ShowMusicFooter: false,
      //   },
      // },
    ],
    meta: {
      auth: {
        roles: 2,
        redirect: { name: "login" },
        forbiddenRedirect: "/403",
      },
    },
  },
  {
    path: "/event-manager",
    name: "event-manager.home",
    component: EMMainContainer,
    redirect: { name: "event-manager.default" },
    children: [
      {
        path: "page",
        name: "event-manager.default",
        component: EMSubContainer,
        children: [
          {
            path: "dashboard",
            name: "dashboard",
            component: EventManager_Dashboard,
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "events",
            name: "events",
            component: EventManager_Events,
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "events/detail/:id",
            name: "event.detail",
            component: EM_EventDetail,
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "tickets",
            name: "tickets",
            component: EventManager_Tickets,
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "orders",
            name: "orders",
            component: EventManager_Orders,
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "orders/detail",
            name: "event.detail",
            component: EventManager_Orders,
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "Insights",
            name: "insights",
            component: EventManager_Insights,
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "ads",
            name: "ads",
            component: EventManager_Ads,
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "ads/detail/:id",
            name: "ads.detail",
            component: EM_AdsDetail,
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "settings",
            name: "settings",
            component: EventManager_Settings,
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
        ],
      },
    ],
    meta: {
      auth: {
        roles: 2,
        redirect: { name: "login" },
        forbiddenRedirect: "/403",
      },
    },
  },
  {
    path: "/jobs-manager",
    name: "jobs-manager.home",
    component: JMMainContainer,
    redirect: { name: "jobs-manager.default" },
    children: [
      {
        path: "page/:id",
        name: "jobs-manager.default",
        showLeftNav: true,
        component: JMSubContainer,
        meta: {
          ShowMusicFooter: true,
          showLeftNav: true,
        },
      },
    ],
    meta: {
      auth: {
        roles: 2,
        redirect: { name: "login" },
        forbiddenRedirect: "/403",
      },
    },
  },
  {
    path: "/market-manager",
    name: "market-manager.home",
    component: MMMainContainer,
    redirect: { name: "market-manager.default" },
    children: [
      {
        path: "page",
        name: "market-manager.default",
        showLeftNav: true,
        component: MMSubContainer,
        children: [
          {
            path: ":id",
            name: "page-manager.create-page",
            showLeftNav: false,
            component: MMDashboard,
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "add-product/:id",
            name: "Add Product",
            showLeftNav: true,
            component: AddMedia,
            props: {
              ShowMusicFooter: false,
              activeHeader: "music",
            },
            meta: {
              ShowMusicFooter: true,
              showLeftNav: true,
            },
          },
          {
            path: "create-product-song-album/:id",
            name: "product.create-song-album",
            showLeftNav: false,
            component: AddProductSongAlbum,
            meta: {
              showLeftNav: true,
              type: "createSongAlbum",
            },
          },
          {
            path: "create-product-sample/:id",
            name: "product.create-sample",
            showLeftNav: false,
            component: AddProductSample,
            meta: {
              showLeftNav: true,
              type: "createSample",
            },
          },
          {
            path: "create-product-podcast/:id",
            name: "product.create-podcast",
            showLeftNav: false,
            component: AddProductSongAlbum,
            meta: {
              showLeftNav: true,
              type: "createPodcast",
            },
          },
        ],
      },
    ],
    meta: {
      auth: {
        roles: 2,
        redirect: { name: "login" },
        forbiddenRedirect: "/403",
      },
    },
  },
  {
    path: "/Market",
    name: "market.home",
    component: PMMainContainer,
    redirect: { name: "page-manager.default" },
    children: [
      {
        path: "product",
        name: "product.default",
        component: PMSubContainer,
        redirect: { name: "page-manager.artist-profile" },
        children: [
          {
            path: "create-product-song-album",
            name: "product.create-song-album",
            showLeftNav: false,
            component: AddProductSongAlbum,
            meta: {
              showLeftNav: true,
              type: "createSongAlbum",
            },
          },
          {
            path: "create-product-podcast",
            name: "product.create-podcast",
            showLeftNav: false,
            component: AddProductSongAlbum,
            meta: {
              showLeftNav: true,
              type: "createPodcast",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/test",
    name: "text-component",
    component: Test,
    meta: {
      requiresUnAuth: true,
    },
  },
  {
    path: "/TestPage",
    name: "TestPage",
    component: TestPage,
    meta: {
      requiresUnAuth: true,
    },
  },
  {
    path: "/link-preview",
    name: "LinkPrivewPage",
    component: LinkPrivewPage,
    meta: {
      requiresUnAuth: true,
    },
  },
  {
    path: "/TestChatMusic",
    name: "TestChatMusic",
    component: TestChatMusic,
    meta: {
      requiresUnAuth: true,
    },
  },
  {
    path: "/embed/:type/:id",
    name: "EmbedComponent",
    component: ImageAndWaveOnShareModal,
    meta: {
      requiresUnAuth: false,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: PageNotFound,
    meta: {
      requiresUnAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active",
  base: process.env.BASE_URL,
  routes,
});

let is_authenticated = false;
router.beforeEach(async (to, from, next) => {
  const access_token =
    store.getters && store.getters.getAccessToken
      ? store.getters.getAccessToken
      : null;
  let userData = await SMStorage.getSMLoginData();
  userData = userData && userData.response ? userData.response : userData;
  if (access_token && userData.email_verified === 1) {
    try {
      store.commit("ADD_ROUTE_IN_STACK", from);
      is_authenticated = true;
      const { complete_profile } = userData;
      store.commit("updateSystemDataUIConstraints", {
        [SYSTEM_DATA_IS_AUTHENTICATED]: true,
        [SYSTEM_DATA_IS_WELCOME_PAGE]: complete_profile === 0 ? true : false,
      });
      store.commit("updateUserUIConstraints", {
        [USER_DATA]: userData,
      });
    } catch (error) {
      console.error("in before each function of route", error);
    }
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (is_authenticated) {
      next();
    } else {
      await store.dispatch("logout");
      next({
        path: "/",
      });
    }
  } else {
    next();
  }
});

// router.push(fullPath).catch(error => {
//   if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
//     throw Error(error)
//   }
// })

export default router;
