<template>
    <div>
      <div class="row">
        <div v-if="uploadingCheck" class="col-12 progress-bar-div">
          <div class="upload-icon">
            <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.85638 14.9463C4.85193 14.9604 3.86569 14.6776 3.02138 14.1333C2.37433 13.7313 1.84604 13.1643 1.49083 12.4904C1.13563 11.8166 0.96634 11.0603 1.00038 10.2993C1.00038 6.27928 5.02038 5.35028 5.02038 5.35028C5.02038 5.35028 6.18138 1.23828 10.3634 1.23828C14.5454 1.23828 15.7774 5.35028 15.7774 5.35028C15.7774 5.35028 20.1914 5.74528 20.1914 9.99728C20.228 10.7935 20.0591 11.5858 19.701 12.2979C19.3428 13.01 18.8075 13.618 18.1464 14.0633C17.2766 14.6534 16.2463 14.9617 15.1954 14.9463" stroke="white" stroke-width="1.3"/>
              <path d="M10.6553 16.7598V7.85877" stroke="white" stroke-width="1.3"/>
              <path d="M7.32129 11.1934L10.6553 7.85936L13.9903 11.1934" stroke="white" stroke-width="1.3"/>
            </svg>
          </div>
          <div class="uploading-content-name">
            <span>Uploading</span>
            <span class="content">{{fileName}}...</span>
          </div>
          <div class="progress-wrapper ml-auto">
            <span>{{ uploadPercentage }}%</span>
            <div class="progress-outer">
              <div class="progress-inner" :style="`width: ${uploadPercentage}%`"></div>
            </div>
          </div>
        </div>
        <div v-if="Object.keys(responseSongData).length !== 0" class="col-12 replace-file-div">
          <span>Provide FLAC, WAV, ALAC or AIFF for the best audio quality.</span>
          <a>Learn more about uploading requirements.</a>
          <button class="ml-auto">
            <span>Replace File</span>
          </button>
        </div>
      </div>
      <div class="row" style="background-color: white">
        <div class="col-9" style="max-width: 80.45% !important; flex: 80.45% !important;">
          <div class="row pt-4 px-3">
            <div class="col-sm-12 px-0">
              <h5 class="song-heading">Basic Information</h5>
              <div class="invalid-feedback d-block mb-2" v-if="!pageId">
              <span>
                Un-known page! You cannot proceed
              </span>
              </div>
            </div>
            <div class="col-sm-5 ">
              <div v-if="!(previewImage)">
                <div class="row basic-information-h py-3" style="width:250px" @click="$refs.file2.click()">
                  <div class="col-sm-3 ">
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="25" cy="25" r="25" fill="#D1433A" fill-opacity="0.1" />
                      <path
                        d="M32.5 27.5V30.8333C32.5 31.2754 32.3244 31.6993 32.0118 32.0118C31.6993 32.3244 31.2754 32.5 30.8333 32.5H19.1667C18.7246 32.5 18.3007 32.3244 17.9882 32.0118C17.6756 31.6993 17.5 31.2754 17.5 30.8333V27.5"
                        stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M29.1663 21.6667L24.9997 17.5L20.833 21.6667" stroke="#D1433A" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M25 17.5V27.5" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                  </div>
                  <div class="col-sm-9 px-0 pt-3">
                    <h3>Upload Your Artwork</h3>
                  </div>
                  <input type="file" ref="file2" @change="photoAdded($event)" style="display:none;">
                </div>
                <div class="row upload-your-work-card-border" style="width:250px">
                  <div class="col-sm-12 mt-3 pt-2 pl-3 ml-1">
                    <p class="upload-work-p">Your image must be:</p>
                  </div>
                  <div class="col-sm-12 basic-info-ul pl-3" style="margin-bottom: -18px;">
                    <ul class="pl-3 ml-1 pb-2">
                      <li>PNG or JPG format</li>
                      <li>Square</li>
                      <li>Minimum size: 500 x 500 pixels</li>
                      <li>Maximum Size: 10mb</li>
                      <li>RGB Format</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div v-else style="width:250px; height:250px; margin-left: -15px">
                <img class="image-preview" :src="previewImage" alt="Preview Image">
                <span @click="removePhoto" class="cross-icon cursor-pointer">
                    <svg width="35" height="35" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5 3.5L3.5 10.5" stroke="red" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M3.5 3.5L10.5 10.5" stroke="red" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </span>
              </div>
              <p class="invalid-feedback d-block" v-if="isImgUploaded === false">
                This is a required field. File must be of jpg or png type
              </p>
            </div>
            <div class="col-sm-7 pl-0 basic-information-p ">
              <div class="row setting-form basic-info-setting-form px-1">
                <div class="col-sm-12 pr-0">
                  <div class="form-group">
                    <label>Sample Title</label>
                    <b-form-input id="name-your-page" v-model="$v.basicInformation[TITLE].$model"
                                  :state="validateState([TITLE])" :placeholder="title">
                    </b-form-input>
                    <b-form-invalid-feedback id="name-your-page">This is a required field.
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="col-sm-12 pr-0">
                  <div class="form-group">
                    <label>Edit Sample URL</label>
                    <b-input-group size="sm" class="mb-2 d-flex align-items-center edit-song-url">
                      <template #append>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right: 9px;">
                          <path d="M11.2131 3.93398L8.6131 1.33398L1.81313 8.13399L1.34641 10.734C1.33051 10.796 1.32874 10.8607 1.34121 10.9235C1.35368 10.9863 1.38012 11.0454 1.41852 11.0966C1.45691 11.1478 1.50629 11.1897 1.56305 11.2193C1.61981 11.2488 1.68244 11.2652 1.7464 11.2673H1.87978L4.34641 10.6673L11.2131 3.93398Z" stroke="#D1433A" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M9.54675 5.40052L7.08008 2.86719" stroke="#D1433A" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </template>
                      <b-form-input id="page-address" v-model="$v.basicInformation[URL].$model"
                                    :state="validateState([URL])" :placeholder="prependUrl" class="input-color">
                      </b-form-input>
                      <b-form-invalid-feedback id="category">This is a required field.
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </div>
                </div>
                <div class="col-sm-12 pr-0" v-if="actionType === 'createPodcast'">
                  <div class="form-group">
                    <label>Podcast Type</label>
                    <select name="podcast_type" v-model="$v.basicInformation[PODCAST_TYPE].$model" class="form-control"
                            :state="validateState(PODCAST_TYPE)">
                      <option value="Music">Music</option>
                      <option value="Talk">Talk</option>
                    </select>
                    <div class="invalid-feedback d-block" v-if="errorsList[PODCAST_TYPE]">
                      This is a required field.
                    </div>
                  </div>
                </div>
                <div
                  :class="['col-sm-12 pr-0', ($v.basicInformation[PODCAST_TYPE].$model === 'Music') ? 'show_theme_input': '']">
                  <div class="form-group">
                    <label>Podcast Theme</label>
                    <select name="podcast_type" v-model="$v.basicInformation[PODCAST_CATEGORY_ID].$model"
                            class="form-control" :state="validateState(PODCAST_CATEGORY_ID)">
                      <option value=null hidden selected>Choose a Theme</option>
                      <option v-for="(theme,index) in podcastThemes" :value="theme.id" :key="index">
                        {{ theme.name }}
                      </option>
                    </select>
                    <div class="invalid-feedback d-block" v-if="errorsList[PODCAST_CATEGORY_ID]">
                      This is a required field.
                    </div>
                  </div>
                </div>
                <div
                  :class="['col-sm-6 pr-0', ($v.basicInformation[PODCAST_TYPE].$model === 'Talk') ? 'show_theme_input': '']">
                  <div class="form-group">
                    <label>Genre</label>
                    <Select2 v-model="$v.basicInformation[GENRE].$model" id="genre" placeholder="Choose"
                             :key="'specification_id' + errorsList['genre']" :class="{'is-invalid':errorsList[GENRE]}"
                             class="dropdown-scrollar-main"
                             :state="validateState('genre')" :options="genres" :settings="{ multiple: true, tags: true }" />
                    <div class="invalid-feedback d-block" v-if="errorsList['genre']">
                      This is a required field.
                    </div>
                  </div>
                </div>
                <div
                  :class="['col-sm-6 pr-0', ($v.basicInformation[PODCAST_TYPE].$model === 'Talk') ? 'show_theme_input': '']">
                  <div class="form-group">
                    <label>Subgenre</label>
                    <Select2 v-model="$v.basicInformation[SUB_GENRE].$model" id="sub-genre" placeholder="Choose"
                             :key="'specification_id' + errorsList[SUB_GENRE]" :class="{'is-invalid':errorsList[SUB_GENRE]}"
                             :state="validateState(SUB_GENRE)" :options="selectedGenresSubGenres"
                             :settings="{ multiple: true, tags: true }" />
                    <div class="invalid-feedback d-block" v-if="errorsList[SUB_GENRE]">
                      This is a required field.
                    </div>
                  </div>
                </div>
                <div
                  :class="['col-sm-12 pr-0', ($v.basicInformation[PODCAST_TYPE].$model === 'Talk') ? 'show_theme_input': '']"
                  v-if="actionType === 'createSample'">
                  <div class="form-group">
                    <label>Mood</label>
                    <Select2 v-model="$v.basicInformation[MOOD].$model" id="moods" placeholder="Choose "
                             :key="'specification_id' + errorsList[MOOD]" :class="{'is-invalid':errorsList[MOOD]}"
                             :state="validateState(MOOD)" :options="moods" :settings="{ multiple: true, tags: true }" />
                    <div class="invalid-feedback d-block" v-if="errorsList[MOOD]">
                      This is a required field.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row setting-form basic-info-setting-form-1">
            
            <div v-if="currentType==='sample'" class="instrument-character-container pr-0 pl-0">
            <div
                  :class="['instrument-container pr-0', ($v.basicInformation[PODCAST_TYPE].$model === 'Talk') ? 'show_theme_input': '']"
                  v-if="actionType === 'createSample'">
                  <div class="form-group">
                    <label>Instrument</label>
                    <Select2 v-model="$v.basicInformation[INSTRUMENT].$model" id="instrument" placeholder="Choose an Instrument"
                             :key="'specification_id' + errorsList[INSTRUMENT]" :class="{'is-invalid':errorsList[INSTRUMENT]}"
                             :state="validateState(INSTRUMENT)" :options="instrument" :settings="{ multiple: true, tags: true }" />
                    <div class="invalid-feedback d-block" v-if="errorsList[INSTRUMENT]">
                      This is a required field.
                    </div>
                  </div>
                </div>
                <div
                  :class="['character-container pr-0', ($v.basicInformation[PODCAST_TYPE].$model === 'Talk') ? 'show_theme_input': '']"
                  v-if="actionType === 'createSample'">
                  <div class="form-group">
                    <label>Character</label>
                    <Select2 v-model="$v.basicInformation[CHARACTER].$model" id="character" placeholder="Choose character"
                             :key="'specification_id' + errorsList[CHARACTER]" :class="{'is-invalid':errorsList[CHARACTER]}"
                             :state="validateState(CHARACTER)" :options="character" :settings="{ multiple: true, tags: true }" />
                    <div class="invalid-feedback d-block" v-if="errorsList[CHARACTER]">
                      This is a required field.
                    </div>
                  </div>
                </div>
          </div>
          <div v-if="currentType==='sample'" class="instrument-character-container pr-0 pl-0">
            <div
                  :class="['instrument-container pr-0', ($v.basicInformation[PODCAST_TYPE].$model === 'Talk') ? 'show_theme_input': '']"
                  v-if="actionType === 'createSample'">
                  <div class="form-group">
                    <label>Key</label>
                             <input 
        type="text" 
        v-model="$v.basicInformation[KEY].$model" 
        id="key" 
        placeholder="Key" 
        :class="{'form-control': true, 'is-invalid': errorsList[KEY]}" 
        :state="validateState(KEY)" 
    />
                  </div>
                </div>
                <div
                  :class="['character-container pr-0', ($v.basicInformation[PODCAST_TYPE].$model === 'Talk') ? 'show_theme_input': '']"
                  v-if="actionType === 'createSample'">
                  <div class="form-group">
                    <label>BPM (Beats per minute)</label>
                             <input 
        type="text" 
        v-model="$v.basicInformation[BPM].$model" 
        id="bpm" 
        placeholder="BPM" 
        :class="{'form-control': true, 'is-invalid': errorsList[BPM]}" 
        :state="validateState(BPM)" 
    />  
                  </div>
                </div>
          </div>

             <div v-if="currentType==='sample_pack'"  class="col-sm-12">
              <div class="form-group">
                <label for="text">#Tags</label>
                <Select2 v-model="$v.basicInformation[TAGS].$model" id="tags" placeholder="Add tags"
                         :key="'specification_id' + errorsList[TAGS]" :class="{'is-invalid':errorsList[TAGS]}"
                         :state="validateState(TAGS)" :options="tagsList" :settings="{ multiple: true, tags: true }" />
              </div>
            </div>            

            <div class="col-sm-12">
              <div class="form-group">
                <label for="textarea">Description
                </label>
                <b-form-textarea id="about" size="sm" v-model="$v.basicInformation[DESCRIPTION].$model"
                                 :state="validateState([DESCRIPTION])">
                </b-form-textarea>
                <div class="invalid-feedback d-block" v-if="errorsList[DESCRIPTION]">
                  This is a required field.
                </div>
              </div>
            </div>

            <!-- Sample #1 -->

            <div v-if="currentType==='sample_pack'" class="instrument-character-container pr-0 pl-0" >

            <div class="form-group pr-0 instrument-container">
                    <label>Sample #1</label>
                    <b-input-group size="sm" class="mb-2 d-flex align-items-center edit-song-url">
                      <template #append>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 4L4 12" stroke="#EE3F3F" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4 4L12 12" stroke="#EE3F3F" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                      </template>
                      <b-form-input id="page-address" v-model="sample_1_url"
                           :placeholder="prependUrl" class="input-color">
                      </b-form-input>
                      <b-form-invalid-feedback id="category">This is a required field.
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </div>

                  <div class="form-group pr-0 character-container">
                    <label>Name</label>
                    <b-form-input id="name-your-page" v-model="basicInformation.sample_details[0].title"
                       placeholder="Sample 1">
                    </b-form-input>
                    <b-form-invalid-feedback id="name-your-page">This is a required field.
                    </b-form-invalid-feedback>
                  </div>

                </div>

          <div v-if="currentType==='sample_pack'" class="instrument-character-container pr-0 pl-0">

          <div class="pr-0 instrument-container">
            <div class="form-group">
              <label>Sample Type</label>
            </div>
            <div style="margin-top: -24px;">
              <button
                type="button"
                  :class="{'active':(basicInformation.sample_details[0].sample_type==='one_shot')}"
                  @click="handleSampleType(0, 'one_shot')"                
                  class="song-btn"
              >
                <span
                  class="song-btn-icon-display">
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.8">
<path d="M11 1C5.48607 1 1 5.48607 1 11C1 16.5141 5.48607 21 11 21C16.5141 21 21 16.5139 21 11C21 5.48585 16.5141 1 11 1ZM11 20.2364C5.90694 20.2364 1.76364 16.0928 1.76364 11C1.76364 5.90716 5.90694 1.76364 11 1.76364C16.0928 1.76364 20.2364 5.90716 20.2364 11C20.2364 16.0928 16.0928 20.2364 11 20.2364Z" fill="white" stroke="white" stroke-width="0.41"/>
<path d="M13.7047 12.8224L14.7565 10.9675C14.822 10.8454 14.9711 10.7895 15.1125 10.7895H17.7083C17.8073 10.7895 17.901 10.8165 17.9726 10.8665C18.0445 10.9166 18.1 10.9952 18.1 11.093C18.1 11.1909 18.0445 11.2694 17.9726 11.3195C17.901 11.3695 17.8073 11.3965 17.7083 11.3965H15.369L13.9686 13.861L13.9684 13.8614C13.896 13.9877 13.7345 14.0424 13.5868 14.0377C13.434 14.0329 13.2806 13.9544 13.233 13.8159C13.233 13.8158 13.2329 13.8156 13.2329 13.8154L11.6704 9.35906L9.7176 14.8747L9.71701 14.8763L9.717 14.8763C9.66217 15.0229 9.49621 15.1 9.3375 15.1H9.33167C9.17236 15.1 9.00318 15.0178 8.95619 14.867L8.95581 14.8657L8.95581 14.8657L7.72904 10.7398L7.1593 11.9323C7.12923 12.0004 7.0703 12.0491 7.00877 12.0799C6.94539 12.1115 6.87019 12.1291 6.79417 12.1291H4.29167C4.19267 12.1291 4.09905 12.1021 4.02737 12.0521C3.95554 12.002 3.9 11.9234 3.9 11.8256C3.9 11.7277 3.95554 11.6492 4.02737 11.5991C4.09905 11.5491 4.19267 11.5221 4.29167 11.5221H6.52123L7.45644 9.57259C7.45644 9.57257 7.45645 9.57255 7.45646 9.57254L7.4565 9.57245L7.54667 9.6157C7.5875 9.53023 7.70417 9.47326 7.83833 9.47733L13.7047 12.8224ZM13.7047 12.8224L12.0513 8.12646C12.0029 7.98032 11.8337 7.9 11.6708 7.9C11.5127 7.9 11.3473 7.97653 11.2919 8.11824L11.2918 8.11822L11.2907 8.12137L9.37475 13.5493L13.7047 12.8224Z" fill="white" stroke="white" stroke-width="0.2"/>
</g>
</svg>
                </span>
                <span class="song-btn-margin">One Shoot</span>
              </button>
              <button
                type="button"
                :class="{'active':(basicInformation.sample_details[0].sample_type==='loop')}"
                @click="handleSampleType(0, 'loop')"                
                class="album-btn"
              >
                <span
                  class="song-btn-icon-display">
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.8">
<path d="M11 1C5.48607 1 1 5.48607 1 11C1 16.5141 5.48607 21 11 21C16.5141 21 21 16.5139 21 11C21 5.48585 16.5141 1 11 1ZM11 20.2364C5.90694 20.2364 1.76364 16.0928 1.76364 11C1.76364 5.90716 5.90694 1.76364 11 1.76364C16.0928 1.76364 20.2364 5.90716 20.2364 11C20.2364 16.0928 16.0928 20.2364 11 20.2364Z" fill="#47505B" stroke="#47505B" stroke-width="0.41"/>
<path d="M13.3104 8.62038L12.6788 9.23871C12.5239 9.38184 12.5143 9.64083 12.6572 9.79574C12.7325 9.87734 12.835 9.9273 12.9378 9.9273C13.0303 9.9273 13.1232 9.89806 13.1967 9.83043L14.5377 8.5942C14.616 8.52198 14.6605 8.4214 14.6605 8.31471C14.6605 8.20802 14.616 8.107 14.5377 8.03478L13.1967 6.79659C13.0418 6.65346 12.8001 6.66328 12.6572 6.81819C12.514 6.9731 12.5236 7.23426 12.6788 7.37739L13.2947 7.96583H8.09103C6.79764 7.96583 5.76367 8.97886 5.76367 10.2722V11.7269C5.76367 11.9379 5.88018 12.1087 6.09095 12.1087C6.30171 12.1087 6.41822 11.9379 6.41822 11.7269V10.2722C6.41822 9.39995 7.21874 8.62038 8.09103 8.62038H13.3104Z" fill="#47505B" stroke="#47505B" stroke-width="0.41"/>
<path d="M15.4801 10C15.2692 10 15.0438 10.1711 15.0438 10.3818V11.8364C15.0438 12.7085 14.4614 13.3118 13.5891 13.3118H8.35579L8.98176 12.7869C9.13667 12.6439 9.14627 12.4288 9.00314 12.2739C8.86023 12.1192 8.6187 12.1225 8.46357 12.2658L7.12262 13.5108C7.04451 13.583 7 13.688 7 13.7944C7 13.9009 7.04451 14.0043 7.12262 14.0765L8.46357 15.3158C8.5371 15.3839 8.62983 15.4177 8.72256 15.4177C8.82532 15.4177 8.92787 15.3767 9.00314 15.2951C9.14627 15.1402 9.13667 14.9 8.98176 14.7571L8.35994 14.1845H13.5894C14.8825 14.1845 15.9165 13.1296 15.9165 11.8362V10.3816C15.9165 10.1708 15.6911 10 15.4801 10Z" fill="#47505B" stroke="#47505B" stroke-width="0.41"/>
</g>
</svg>
                </span>
                <span class="song-btn-margin">Loop</span>
              </button>
            </div>
            </div>

            <div  class="form-group pr-0 character-container">
              <div style="display: flex; gap: 40px;">

              <div class="pl-0 pr-0" style="width: 47%;">
                    <label>Instrument</label>
                    <Select2 v-model="basicInformation.sample_details[0].instrument.$model" id="sample_details[0].instrument" placeholder="Choose an Instrument"
                             :options="instrument" :settings="{ tags: true }" />
                  </div>

                  <div class=" pl-0 pr-0" style="width: 47%;">
                    <label>Character</label>
                    <Select2 v-model="basicInformation.sample_details[0].character.$model" id="sample_details[0].character" placeholder="Choose character"
                             :options="character" :settings="{ tags: true }" />
                  </div>

                </div>

              </div>

          </div>

          <div v-if="currentType==='sample_pack'" class="instrument-character-container pr-0 pl-0" style="border-bottom: 1px solid #E6EAF0;">

<div class="pr-0 instrument-container">

  </div>

  <div class="form-group pr-0 character-container">
    <div style="display: flex; gap: 40px;">

    <div class="pl-0 pr-0" style="width: 47%;">
          <label>Key</label>
          <input 
        type="text" 
        v-model="basicInformation.sample_details[0].key.$model" 
        id="sample_details[0].key" 
        placeholder="Key" 
        :class="{'form-control': true, 'is-invalid': errorsList[KEY]}" 
:state="validateState(KEY)" 
    />
        </div>

        <div class=" pl-0 pr-0" style="width: 47%;">
          <label>BPM</label>
    <input 
        type="text" 
        v-model="basicInformation.sample_details[0].bpm.$model" 
        id="sample_details[0].bpm" 
        placeholder="BPM" 
        :class="{'form-control': true, 'is-invalid': errorsList[BPM]}" 
        :state="validateState(BPM)" 
    />  
        </div>

      </div>

    </div>

          </div>

          <!-- Sample #2 -->

          <div v-if="currentType==='sample_pack'" class="instrument-character-container pr-0 pl-0 mt-3">

<div class="form-group pr-0 instrument-container">
        <label>Sample #2</label>
        <b-input-group size="sm" class="mb-2 d-flex align-items-center edit-song-url">
          <template #append>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 4L4 12" stroke="#EE3F3F" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4 4L12 12" stroke="#EE3F3F" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

          </template>
          <b-form-input id="page-address" v-model="basicInformation.sample_details[1].temp_song_id.$model"
                     :placeholder="prependUrl" class="input-color">
          </b-form-input>
          <b-form-invalid-feedback id="category">This is a required field.
          </b-form-invalid-feedback>
        </b-input-group>
      </div>

      <div class="form-group pr-0 character-container">
        <label>Name</label>
        <b-form-input id="name-your-page" v-model="basicInformation.sample_details[1].title.$model"
           placeholder="Sample 2">
        </b-form-input>
        <b-form-invalid-feedback id="name-your-page">This is a required field.
        </b-form-invalid-feedback>
      </div>

    </div>

<div v-if="currentType==='sample_pack'" class="instrument-character-container pr-0 pl-0">

<div class="pr-0 instrument-container">
<div class="form-group">
  <label>Sample Type</label>
</div>
<div style="margin-top: -24px;">
  <button
    type="button"
      :class="{'active':(basicInformation.sample_details[1].sample_type==='one_shot')}"
      @click="handleSampleType(1, 'one_shot')"                
      class="song-btn"
  >
    <span
      class="song-btn-icon-display">
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.8">
<path d="M11 1C5.48607 1 1 5.48607 1 11C1 16.5141 5.48607 21 11 21C16.5141 21 21 16.5139 21 11C21 5.48585 16.5141 1 11 1ZM11 20.2364C5.90694 20.2364 1.76364 16.0928 1.76364 11C1.76364 5.90716 5.90694 1.76364 11 1.76364C16.0928 1.76364 20.2364 5.90716 20.2364 11C20.2364 16.0928 16.0928 20.2364 11 20.2364Z" fill="white" stroke="white" stroke-width="0.41"/>
<path d="M13.7047 12.8224L14.7565 10.9675C14.822 10.8454 14.9711 10.7895 15.1125 10.7895H17.7083C17.8073 10.7895 17.901 10.8165 17.9726 10.8665C18.0445 10.9166 18.1 10.9952 18.1 11.093C18.1 11.1909 18.0445 11.2694 17.9726 11.3195C17.901 11.3695 17.8073 11.3965 17.7083 11.3965H15.369L13.9686 13.861L13.9684 13.8614C13.896 13.9877 13.7345 14.0424 13.5868 14.0377C13.434 14.0329 13.2806 13.9544 13.233 13.8159C13.233 13.8158 13.2329 13.8156 13.2329 13.8154L11.6704 9.35906L9.7176 14.8747L9.71701 14.8763L9.717 14.8763C9.66217 15.0229 9.49621 15.1 9.3375 15.1H9.33167C9.17236 15.1 9.00318 15.0178 8.95619 14.867L8.95581 14.8657L8.95581 14.8657L7.72904 10.7398L7.1593 11.9323C7.12923 12.0004 7.0703 12.0491 7.00877 12.0799C6.94539 12.1115 6.87019 12.1291 6.79417 12.1291H4.29167C4.19267 12.1291 4.09905 12.1021 4.02737 12.0521C3.95554 12.002 3.9 11.9234 3.9 11.8256C3.9 11.7277 3.95554 11.6492 4.02737 11.5991C4.09905 11.5491 4.19267 11.5221 4.29167 11.5221H6.52123L7.45644 9.57259C7.45644 9.57257 7.45645 9.57255 7.45646 9.57254L7.4565 9.57245L7.54667 9.6157C7.5875 9.53023 7.70417 9.47326 7.83833 9.47733L13.7047 12.8224ZM13.7047 12.8224L12.0513 8.12646C12.0029 7.98032 11.8337 7.9 11.6708 7.9C11.5127 7.9 11.3473 7.97653 11.2919 8.11824L11.2918 8.11822L11.2907 8.12137L9.37475 13.5493L13.7047 12.8224Z" fill="white" stroke="white" stroke-width="0.2"/>
</g>
</svg>
    </span>
    <span class="song-btn-margin">One Shoot</span>
  </button>
  <button
    type="button"
    :class="{'active':(basicInformation.sample_details[1].sample_type==='loop')}"
    @click="handleSampleType(1, 'loop')"                
    class="album-btn"
  >
    <span
      class="song-btn-icon-display">
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.8">
<path d="M11 1C5.48607 1 1 5.48607 1 11C1 16.5141 5.48607 21 11 21C16.5141 21 21 16.5139 21 11C21 5.48585 16.5141 1 11 1ZM11 20.2364C5.90694 20.2364 1.76364 16.0928 1.76364 11C1.76364 5.90716 5.90694 1.76364 11 1.76364C16.0928 1.76364 20.2364 5.90716 20.2364 11C20.2364 16.0928 16.0928 20.2364 11 20.2364Z" fill="#47505B" stroke="#47505B" stroke-width="0.41"/>
<path d="M13.3104 8.62038L12.6788 9.23871C12.5239 9.38184 12.5143 9.64083 12.6572 9.79574C12.7325 9.87734 12.835 9.9273 12.9378 9.9273C13.0303 9.9273 13.1232 9.89806 13.1967 9.83043L14.5377 8.5942C14.616 8.52198 14.6605 8.4214 14.6605 8.31471C14.6605 8.20802 14.616 8.107 14.5377 8.03478L13.1967 6.79659C13.0418 6.65346 12.8001 6.66328 12.6572 6.81819C12.514 6.9731 12.5236 7.23426 12.6788 7.37739L13.2947 7.96583H8.09103C6.79764 7.96583 5.76367 8.97886 5.76367 10.2722V11.7269C5.76367 11.9379 5.88018 12.1087 6.09095 12.1087C6.30171 12.1087 6.41822 11.9379 6.41822 11.7269V10.2722C6.41822 9.39995 7.21874 8.62038 8.09103 8.62038H13.3104Z" fill="#47505B" stroke="#47505B" stroke-width="0.41"/>
<path d="M15.4801 10C15.2692 10 15.0438 10.1711 15.0438 10.3818V11.8364C15.0438 12.7085 14.4614 13.3118 13.5891 13.3118H8.35579L8.98176 12.7869C9.13667 12.6439 9.14627 12.4288 9.00314 12.2739C8.86023 12.1192 8.6187 12.1225 8.46357 12.2658L7.12262 13.5108C7.04451 13.583 7 13.688 7 13.7944C7 13.9009 7.04451 14.0043 7.12262 14.0765L8.46357 15.3158C8.5371 15.3839 8.62983 15.4177 8.72256 15.4177C8.82532 15.4177 8.92787 15.3767 9.00314 15.2951C9.14627 15.1402 9.13667 14.9 8.98176 14.7571L8.35994 14.1845H13.5894C14.8825 14.1845 15.9165 13.1296 15.9165 11.8362V10.3816C15.9165 10.1708 15.6911 10 15.4801 10Z" fill="#47505B" stroke="#47505B" stroke-width="0.41"/>
</g>
</svg>
    </span>
    <span class="song-btn-margin">Loop</span>
  </button>
</div>
</div>

<div class="form-group pr-0 character-container">
  <div style="display: flex; gap: 40px;">

  <div class="pl-0 pr-0" style="width: 47%;">
        <label>Instrument</label>
        <Select2 v-model="basicInformation.sample_details[1].instrument.$model" id="sample_details[1].instrument" placeholder="Choose an Instrument"
                 :options="instrument" :settings="{ tags: true }" />
      </div>

      <div class=" pl-0 pr-0" style="width: 47%;">
        <label>Character</label>
        <Select2 v-model="basicInformation.sample_details[1].character.$model" id="sample_details[1].character" placeholder="Choose character"
                 :options="character" :settings="{ tags: true }" />
      </div>

    </div>

  </div>

</div>

<div v-if="currentType==='sample_pack'" class="instrument-character-container pr-0 pl-0" style="border-bottom: 1px solid #E6EAF0;">

<div class="pr-0 instrument-container">

</div>

<div class="form-group pr-0 character-container">
<div style="display: flex; gap: 40px;">

<div class="pl-0 pr-0" style="width: 47%;">
<label>Key</label>
<input 
type="text" 
v-model="basicInformation.sample_details[1].key.$model" 
id="sample_details[1].key" 
placeholder="Key" 
:class="{'form-control': true, 'is-invalid': errorsList[KEY]}" 
:state="validateState(KEY)" 
/>
</div>

<div class=" pl-0 pr-0" style="width: 47%;">
<label>BPM</label>
<input 
type="text" 
v-model="basicInformation.sample_details[1].bpm.$model" 
id="sample_details[1]" 
placeholder="BPM" 
:class="{'form-control': true, 'is-invalid': errorsList[BPM]}" 
:state="validateState(BPM)" 
/>  
</div>

</div>

</div>

</div>

          <!-- Sample #3 -->

          <div v-if="currentType==='sample_pack'" class="instrument-character-container pr-0 pl-0 mt-3">

<div class="form-group pr-0 instrument-container">
        <label>Sample #3</label>
        <b-input-group size="sm" class="mb-2 d-flex align-items-center edit-song-url">
          <template #append>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 4L4 12" stroke="#EE3F3F" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4 4L12 12" stroke="#EE3F3F" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

          </template>
          <b-form-input id="page-address" v-model="basicInformation.sample_details[2].temp_song_id.$model"
                :placeholder="prependUrl" class="input-color">
          </b-form-input>
          <b-form-invalid-feedback id="category">This is a required field.
          </b-form-invalid-feedback>
        </b-input-group>
      </div>

      <div class="form-group pr-0 character-container">
        <label>Name</label>
        <b-form-input id="name-your-page" v-model="basicInformation.sample_details[2].title.$model"
           placeholder="Sample 3">
        </b-form-input>
        <b-form-invalid-feedback id="name-your-page">This is a required field.
        </b-form-invalid-feedback>
      </div>

    </div>

<div v-if="currentType==='sample_pack'" class="instrument-character-container pr-0 pl-0">

<div class="pr-0 instrument-container">
<div class="form-group">
  <label>Sample Type</label>
</div>
<div style="margin-top: -24px;">
  <button
    type="button"
      :class="{'active':(basicInformation.sample_details[2].sample_type==='one_shot')}"
      @click="handleSampleType(2, 'one_shot')"                
      class="song-btn"
  >
    <span
      class="song-btn-icon-display">
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.8">
<path d="M11 1C5.48607 1 1 5.48607 1 11C1 16.5141 5.48607 21 11 21C16.5141 21 21 16.5139 21 11C21 5.48585 16.5141 1 11 1ZM11 20.2364C5.90694 20.2364 1.76364 16.0928 1.76364 11C1.76364 5.90716 5.90694 1.76364 11 1.76364C16.0928 1.76364 20.2364 5.90716 20.2364 11C20.2364 16.0928 16.0928 20.2364 11 20.2364Z" fill="white" stroke="white" stroke-width="0.41"/>
<path d="M13.7047 12.8224L14.7565 10.9675C14.822 10.8454 14.9711 10.7895 15.1125 10.7895H17.7083C17.8073 10.7895 17.901 10.8165 17.9726 10.8665C18.0445 10.9166 18.1 10.9952 18.1 11.093C18.1 11.1909 18.0445 11.2694 17.9726 11.3195C17.901 11.3695 17.8073 11.3965 17.7083 11.3965H15.369L13.9686 13.861L13.9684 13.8614C13.896 13.9877 13.7345 14.0424 13.5868 14.0377C13.434 14.0329 13.2806 13.9544 13.233 13.8159C13.233 13.8158 13.2329 13.8156 13.2329 13.8154L11.6704 9.35906L9.7176 14.8747L9.71701 14.8763L9.717 14.8763C9.66217 15.0229 9.49621 15.1 9.3375 15.1H9.33167C9.17236 15.1 9.00318 15.0178 8.95619 14.867L8.95581 14.8657L8.95581 14.8657L7.72904 10.7398L7.1593 11.9323C7.12923 12.0004 7.0703 12.0491 7.00877 12.0799C6.94539 12.1115 6.87019 12.1291 6.79417 12.1291H4.29167C4.19267 12.1291 4.09905 12.1021 4.02737 12.0521C3.95554 12.002 3.9 11.9234 3.9 11.8256C3.9 11.7277 3.95554 11.6492 4.02737 11.5991C4.09905 11.5491 4.19267 11.5221 4.29167 11.5221H6.52123L7.45644 9.57259C7.45644 9.57257 7.45645 9.57255 7.45646 9.57254L7.4565 9.57245L7.54667 9.6157C7.5875 9.53023 7.70417 9.47326 7.83833 9.47733L13.7047 12.8224ZM13.7047 12.8224L12.0513 8.12646C12.0029 7.98032 11.8337 7.9 11.6708 7.9C11.5127 7.9 11.3473 7.97653 11.2919 8.11824L11.2918 8.11822L11.2907 8.12137L9.37475 13.5493L13.7047 12.8224Z" fill="white" stroke="white" stroke-width="0.2"/>
</g>
</svg>
    </span>
    <span class="song-btn-margin">One Shoot</span>
  </button>
  <button
    type="button"
    :class="{'active':(basicInformation.sample_details[2].sample_type==='loop')}"
    @click="handleSampleType(2, 'loop')"                
    class="album-btn"
  >
    <span
      class="song-btn-icon-display">
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.8">
<path d="M11 1C5.48607 1 1 5.48607 1 11C1 16.5141 5.48607 21 11 21C16.5141 21 21 16.5139 21 11C21 5.48585 16.5141 1 11 1ZM11 20.2364C5.90694 20.2364 1.76364 16.0928 1.76364 11C1.76364 5.90716 5.90694 1.76364 11 1.76364C16.0928 1.76364 20.2364 5.90716 20.2364 11C20.2364 16.0928 16.0928 20.2364 11 20.2364Z" fill="#47505B" stroke="#47505B" stroke-width="0.41"/>
<path d="M13.3104 8.62038L12.6788 9.23871C12.5239 9.38184 12.5143 9.64083 12.6572 9.79574C12.7325 9.87734 12.835 9.9273 12.9378 9.9273C13.0303 9.9273 13.1232 9.89806 13.1967 9.83043L14.5377 8.5942C14.616 8.52198 14.6605 8.4214 14.6605 8.31471C14.6605 8.20802 14.616 8.107 14.5377 8.03478L13.1967 6.79659C13.0418 6.65346 12.8001 6.66328 12.6572 6.81819C12.514 6.9731 12.5236 7.23426 12.6788 7.37739L13.2947 7.96583H8.09103C6.79764 7.96583 5.76367 8.97886 5.76367 10.2722V11.7269C5.76367 11.9379 5.88018 12.1087 6.09095 12.1087C6.30171 12.1087 6.41822 11.9379 6.41822 11.7269V10.2722C6.41822 9.39995 7.21874 8.62038 8.09103 8.62038H13.3104Z" fill="#47505B" stroke="#47505B" stroke-width="0.41"/>
<path d="M15.4801 10C15.2692 10 15.0438 10.1711 15.0438 10.3818V11.8364C15.0438 12.7085 14.4614 13.3118 13.5891 13.3118H8.35579L8.98176 12.7869C9.13667 12.6439 9.14627 12.4288 9.00314 12.2739C8.86023 12.1192 8.6187 12.1225 8.46357 12.2658L7.12262 13.5108C7.04451 13.583 7 13.688 7 13.7944C7 13.9009 7.04451 14.0043 7.12262 14.0765L8.46357 15.3158C8.5371 15.3839 8.62983 15.4177 8.72256 15.4177C8.82532 15.4177 8.92787 15.3767 9.00314 15.2951C9.14627 15.1402 9.13667 14.9 8.98176 14.7571L8.35994 14.1845H13.5894C14.8825 14.1845 15.9165 13.1296 15.9165 11.8362V10.3816C15.9165 10.1708 15.6911 10 15.4801 10Z" fill="#47505B" stroke="#47505B" stroke-width="0.41"/>
</g>
</svg>
    </span>
    <span class="song-btn-margin">Loop</span>
  </button>
</div>
</div>

<div class="form-group pr-0 character-container">
  <div style="display: flex; gap: 40px;">

  <div class="pl-0 pr-0" style="width: 47%;">
        <label>Instrument</label>
        <Select2 v-model="basicInformation.sample_details[2].instrument.$model" id="sample_details[2].instrument" placeholder="Choose an Instrument"
                 :options="instrument" :settings="{ tags: true }" />
      </div>

      <div class=" pl-0 pr-0" style="width: 47%;">
        <label>Character</label>
        <Select2 v-model="basicInformation.sample_details[2].character.$model" id="sample_details[2].character" placeholder="Choose character"
                :options="character" :settings="{ tags: true }" />
      </div>

    </div>

  </div>

</div>

<div v-if="currentType==='sample_pack'" class="instrument-character-container pr-0 pl-0" style="border-bottom: 1px solid #E6EAF0;">

<div class="pr-0 instrument-container">

</div>

<div class="form-group pr-0 character-container">
<div style="display: flex; gap: 40px;">

<div class="pl-0 pr-0" style="width: 47%;">
<label>Key</label>
<input 
type="text" 
v-model="basicInformation.sample_details[2].key.$model" 
id="sample_details[2].key" 
placeholder="Key" 
:class="{'form-control': true, 'is-invalid': errorsList[KEY]}" 
:state="validateState(KEY)" 
/>
</div>

<div class=" pl-0 pr-0" style="width: 47%;">
<label>BPM</label>
<input 
type="text" 
v-model="basicInformation.sample_details[2].bpm.$model" 
id="sample_details[2]" 
placeholder="BPM" 
:class="{'form-control': true, 'is-invalid': errorsList[BPM]}" 
:state="validateState(BPM)" 
/>  
</div>

</div>

</div>

</div>


            <div class="px-3 ">
              <hr class="mt-0 upload-border">
            </div>
            <div class="col-sm-12" v-if="type==='album'">
              <div class="row" v-for="(song,index) in songListInAlbums" :key="index">
                <div class="col-sm-1 album-h-position">
                  <h1 class="album-h"># {{ Number(index) + 1 }}</h1>
                  <span>
                  <svg class="m-auto" width="18" height="14" viewBox="0 0 18 14" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1H17" stroke="#47505B" stroke-width="1.3" stroke-linecap="round"
                          stroke-linejoin="round" />
                    <path d="M1 7H17" stroke="#47505B" stroke-width="1.3" stroke-linecap="round"
                          stroke-linejoin="round" />
                    <path d="M1 13H17" stroke="#47505B" stroke-width="1.3" stroke-linecap="round"
                          stroke-linejoin="round" />
                  </svg>
                </span>
                </div>
                <div class="col-sm-11">
                  <div class="row">
                    <div class="col-sm-5 pl-0 pr-1">
                      <div class="form-group mb-2">
                        <label for="text">Track Title</label>
                        <b-form-input :id="'song-title'+song.id" v-model="$v.songs.$each[index][TITLE].$model"
                                      :state="validateStateSongs(index,[TITLE])" class="form-control"
                                      placeholder="SuperArtist-MyBestSongEver.mp3">
  
                        </b-form-input>
                        <b-form-invalid-feedback :id="'song-title'+song.id">This is a required field.
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group mb-2">
                        <label for="text">Artist</label>
                        <b-form-input :id="'song-artist'+song.id" v-model="$v.songs.$each[index][PRIMARY_ARTIST].$model"
                                      :state="validateStateSongs(index,[PRIMARY_ARTIST])" class="form-control" placeholder="">
                        </b-form-input>
                        <b-form-invalid-feedback :id="'song-artist'+song.id">This is a required field.
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                    <div class="col-sm-3 pl-1">
                      <div class="form-group mb-2">
                        <label for="text">Composer</label>
                        <b-form-input :id="'song-composer'+song.id" v-model="$v.songs.$each[index][COMPOSER].$model"
                                      :state="validateStateSongs(index,[COMPOSER])" class="form-control" placeholder="">
                        </b-form-input>
                        <b-form-invalid-feedback :id="'song-composer'+song.id">This is a required field.
                        </b-form-invalid-feedback>
                      </div>
                    </div>
  
                    <div class="col-sm-5 pl-0 pr-1">
                      <div class="form-group">
                        <label for="text">Label</label>
                        <b-form-input :id="'song-Label'+song.id" v-model="$v.songs.$each[index][RECORD_LABEL].$model"
                                      :state="validateStateSongs(index,[RECORD_LABEL])" class="form-control" placeholder="">
                        </b-form-input>
                        <b-form-invalid-feedback :id="'song-Label'+song.id">This is a required field.
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="text">Publisher</label>
                        <b-form-input :id="'song-Publisher'+song.id" v-model="$v.songs.$each[index][PUBLISHER].$model"
                                      :state="validateStateSongs(index,[PUBLISHER])" class="form-control" placeholder="">
                        </b-form-input>
                        <b-form-invalid-feedback :id="'song-Publisher'+song.id">This is a required field.
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                    <div class="col-sm-3 pl-1">
                      <div class="form-group">
                        <label for="text">ISRC</label>
                        <b-form-input :id="'song-ISRC'+song.id" v-model="$v.songs.$each[index][ISRC].$model"
                                      :state="validateStateSongs(index,[ISRC])" class="form-control" placeholder="">
                        </b-form-input>
                        <b-form-invalid-feedback :id="'song-ISRC'+song.id">This is a required field.
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pb-4" style="padding-left: 15px;">
              <div class=" cancel-btn-display">
                <button @click="previousStep" class="upload-file-cancel-btn"> CANCEL</button>
              </div>
              <div style="float: right;">
                <div class=" save-btn-display">
                  <button class="upload-file-save-btn"> SAVE TO DRAFT</button>
                </div>
                <div class="upload-btn-display">
                  <button :disabled="!pageId" class="upload-file-upload-btn" @click="saveDataInStore">
                    <b-spinner v-if="spinner" small></b-spinner>
                    <span v-if="!spinner" class="">Next Step</span>
                    <svg v-if="!spinner" class="basic-info-upload-btn" width="8" height="14" viewBox="0 0 8 14" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.773189 13.5C0.848551 13.5 0.923913 13.4589 0.961595 13.4178L6.91522 7.21067C7.02826 7.08735 7.02826 6.92292 6.91522 6.7996L0.961596 0.59249C0.848552 0.469169 0.697828 0.469169 0.584784 0.59249C0.47174 0.71581 0.47174 0.880237 0.584784 1.00356L6.31232 7.00514L0.584783 13.0067C0.471739 13.1301 0.471739 13.2945 0.584783 13.4178C0.622464 13.5 0.697826 13.5 0.773189 13.5Z"
                        fill="white" stroke="white" stroke-width="0.5" />
                    </svg>
  
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-3 upload-file-p-padding" style="max-width: 19.55%">
          <div class="row " style="padding-top: 28px;">
            <div class="col-sm-12">
              <h5 class="song-heading-1">Basic Information</h5>
            </div>
            <div class="col-sm-12">
              <div>
                <p class="song-p">
                  Add basic information about your files here and upload artwork for display.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {
    COMPOSER,
    COPYRIGHT,
    DESCRIPTION,
    EXPLICIT_CONTENT,
    FEATURE_ARTIST,
    GENRE,
    ISRC,
    METADATA_LANGUAGE,
    MOOD,
    INSTRUMENT,
    CHARACTER,
    KEY,
    BPM,
    PAGE_ID,
    PICTURES, PODCAST_TYPE,
    PRIMARY_ARTIST,
    PRODUCER,
    PUBLISHER,
    RE_MIXER,
    RECORD_LABEL,
    RELEASED_AT,
    SUB_GENRE,
    TAGS,
    TITLE,
    URL,
    PODCAST_THEME, PODCAST_CATEGORY_ID, PICTURES_ID, SAMPLE_DETAILS, TEMP_SAMPLE_ID, SAMPLE_TYPE
  } from "../../../../store/types";
  import {required, requiredIf} from "vuelidate/lib/validators";
  import Select2 from 'v-select2-component';
  import Vue from "vue";
  import { get_geners_list, get_podcast_categories, getMoods, uploadTemporaryArtworkUrl } from "../../../../apis/APIs";
  import createFormData from "../../../../mixins/common";
  import axios from "axios";
  import AppConfig from "../../../../configs/AppConfig";
  import * as Utils from "../../../utils/Utils";
  import '/public/css/scroller-dropdown.css'
  
  export default {
    name: "AddBasicInformationSampleAlbum",
    data() {
      return {
        errorsList: [],
        isImgUploaded: true,
        genres: [],
        moods: [],
        instrument: [
              { id: 'BASS', text: 'Bass' },
              { id: 'BRASS_WOODWINDS', text: 'Brass & Woodwinds' },
              { id: 'DRUMS', text: 'Drums' },
        ],
        character: [
              { id: 'ACOUSTIC', text: 'Acoustic' },
              { id: 'ANALOG', text: 'Analog' },
              { id: 'DIGITAL', text: 'Digital' },
        ],
        previewImage: null,
        podcastThemes:[],
        DESCRIPTION,
        GENRE,
        MOOD,
        INSTRUMENT,
        CHARACTER,
        KEY,
        BPM,
        PAGE_ID,
        PICTURES,
        SUB_GENRE,
        TAGS,
        TITLE,
        URL,
        PRIMARY_ARTIST,
        COMPOSER,
        RECORD_LABEL,
        PUBLISHER,
        ISRC,
        METADATA_LANGUAGE,
        FEATURE_ARTIST,
        PRODUCER,
        RE_MIXER,
        RELEASED_AT,
        COPYRIGHT,
        EXPLICIT_CONTENT,
        PODCAST_TYPE,
        PODCAST_CATEGORY_ID,
        basicInformation: {
          [PICTURES]: null,
          [PICTURES_ID]: null,
          [PAGE_ID]: null,
          [TITLE]: null,
          [PODCAST_TYPE]: 'Music',
          [PODCAST_CATEGORY_ID] : null,
          [URL]: null,
          [DESCRIPTION]: null,
          [GENRE]: [],
          [SUB_GENRE]: [],
          [MOOD]: [],
          [INSTRUMENT]: [],
          [CHARACTER]: [],
          [TAGS]: [],
          [KEY]: "",
          [BPM]: "",
          sample_1_url: '',
          sample_2_url: '',
          sample_3_url: '',
          sample_details: [
            {
              temp_song_id: "",
              sample_type: 'one_shot',
              title: "",
              instrument: "",
              character: "",
              key: "",
              bpm: "",
            },
            {
              temp_song_id: "",
              sample_type: 'one_shot',
              title: "",
              instrument: "",
              character: "",
              key: "",
              bpm: "",
            },
            {
              temp_song_id: "",
              sample_type: 'one_shot',
              title: "",
              instrument: "",
              character: "",
              key: "",
              bpm: "",
            },
          ],
        },
        songs: [],
        uploadPercentage: 0,
        uploadingCheck: false,
        fileName: '',
        responseSongData: {},
        spinner: false,
      }
    },
    props: {
      type: {
        type: String,
        default: 'song'
      },
      actionType: {
        type: String,
        default: 'createSongAlbum'
      },
      currentType: {
        type: String,
        default: null
      },
    },
    components: {
      Select2
    },
    validations: {
      basicInformation: {
        [PICTURES]: {
          required,
          extension: value => {
            if (value) {
              const extension = value.name.split('.').pop().toLowerCase();
              return extension === 'jpg' || extension === 'png' || extension === 'jpeg';
            }
            return true; // If no file is selected, skip the extension check
          }
        },
        [PAGE_ID]: {},
        [TITLE]: {
          required
        },
        [URL]: {
          required,
        },
        [DESCRIPTION]: {
          required,
        },
        [GENRE]: {
          required:requiredIf(function () {
            return (this.actionType === 'createSongAlbum' || this.basicInformation[PODCAST_TYPE] === 'Music')
          })
        },
        [SUB_GENRE]: {},
        [MOOD]: {},
        [INSTRUMENT]: {},
        [CHARACTER]: {},
        [KEY]: {},
        [BPM]: {},
        [TAGS]: {},
        [PODCAST_TYPE]:{
          required:requiredIf(function () {
            return this.actionType === 'createPodcast'
          })
        },
        [PODCAST_CATEGORY_ID]:{
          required:requiredIf(function () {
            return this.basicInformation[PODCAST_TYPE] === 'Talk'
          })
        },
      },
      songs: {
        $each: {
          [TITLE]: {
            required:requiredIf(function () {
              return this.type==='album'
            })
          },
          [PRIMARY_ARTIST]: {
            required:requiredIf(function () {
              return this.type==='album'
            })
          },
          [COMPOSER]: {
            required:requiredIf(function () {
              return this.type==='album'
            })
          },
          [RECORD_LABEL]: {
            required:requiredIf(function () {
              return this.type==='album'
            })
          },
          [PUBLISHER]: {
            required:requiredIf(function () {
              return this.type==='album'
            })
          },
          [ISRC]: {
            required:requiredIf(function () {
              return this.type==='album'
            })
          }
        }
      }
    },
    watch: {
      pageId(val) {
        if (val) {
          this.getPageID()
        }
      },
      songListInAlbums(val) {
        if (val) {
          this.setValueOfSongs()
        }
      }
    },
    mounted() {
      this.getGenreList()
      this.getAllMoods()
      this.getPageID()
      this.setValueOfSongs()
      this.getTalks();
    },
    computed: {
      // eslint-disable-next-line vue/return-in-computed-property
      title(){
        if(this.actionType === 'createSongAlbum'){
          if(this.type === 'song'){
            return 'Song Title'
          }else if(this.type === 'album'){
            return 'Album Title'
          }
        }else if(this.actionType === 'createPodcast'){
          return 'Podcast Title'
        }
      },
      // eslint-disable-next-line vue/return-in-computed-property
      url(){
        if(this.actionType === 'createSongAlbum'){
          return  'Song';
        }else if(this.actionType === 'createPodcast'){
          return 'Podcast'
        }
      },
      // eslint-disable-next-line vue/return-in-computed-property
      prependUrl(){
        if(this.actionType === 'createSongAlbum'){
          if(this.type === 'song'){
            return  'https://www.audioground.net/';
          }else{
            return  'https://play.ground.fm/';
          }
  
        }else if(this.actionType === 'createPodcast'){
          return 'https://www.audioground.net'
        }
      },
      tagsList() {
        return this.$store.getters.getPostTagsList.map(obj => {
          return obj.tag
        })
      },
      selectedGenresSubGenres() {
        if (this.basicInformation[GENRE].length > 0) {
          let subGenres = []
          for (const genreId of this.basicInformation[GENRE]) {
            const index = this.genres.findIndex(e => e.id === Number(genreId))
            if (index !== -1) {
              subGenres = [...subGenres, ...this.genres[index].subgenre]
            }
          }
          return subGenres.map(genre => {
            return {
              id: genre.id,
              text: genre.name
            }
          })
        }
        return []
      },
      pageId() {
        if(this.$route.params.id){
          return this.$route.params.id
        }
        return (this.$route.query && this.$route.query.page_id) ? this.$route.query.page_id : null
      },
      songListInAlbums() {
        return this.$store.getters.getAlbumSongsUploadedIn
      }
    },
    methods: {
      validateState(name) {
        const {$dirty, $error} = this.$v.basicInformation[name];
        Vue.set(this.errorsList, name, $error);
        return $dirty ? !$error : null;
      },
      validateStateSongs(index,name) {
        const {$dirty, $error} =  this.$v.songs.$each[index][name];
        return $dirty ? !$error : null;
      },
      handleSampleType(index, type = null) {
        if (this.basicInformation.sample_details[index]) {
            if (type) {
                this.basicInformation.sample_details[index].sample_type = type;
            } 
            else {
                this.basicInformation.sample_details[index].sample_type =
                    this.basicInformation.sample_details[index].sample_type === 'one_shot' ? 'loop' : 'one_shot';
            }
        }
    },
      async getGenreList() {
        try {
  
          const res = await get_geners_list();
          const {success, information} = res.data || {};
          if (success === 1) {
            if (information && information.length) {
              this.genres = information.map(ele => ({id: ele.id, text: ele.name, subgenre: ele.subgenre}));
            }
          }
  
        } catch (error) {
  
        }
      },
      async getTalks() {
        try {
          const {data} = await get_podcast_categories();
          this.podcastThemes = data.data;
        } catch (error) {
          console.error(error)
        }
      },
      async getAllMoods() {
        try {
          let {data} = await getMoods();
          this.moods = data.data.map(mood => {
            return {
              id: mood.id,
              text: mood.name
            }
          });
        } catch (error) {
          console.error(error)
        }
      },
      photoAdded: function (event) {
        let input = event.target;
        if (input.files) {
          let reader = new FileReader();
          reader.onload = (e) => {
            this.previewImage = e.target.result;
          }
          this.isImgUploaded = true;
          if(input.files[0].name.split('.').pop().toLowerCase() === 'png' || input.files[0].name.split('.').pop().toLowerCase() === 'jpg' || input.files[0].name.split('.').pop().toLowerCase() === 'jpeg') {
            this.basicInformation[PICTURES] = input.files[0];
            reader.readAsDataURL(input.files[0]);
          } else {
            this.isImgUploaded = false;
          }
        }
      },
      removePhoto() {
        this.previewImage = null
        this.basicInformation[PICTURES] = null
      },
      nextStep() {
        console.log('Checking Next Step Call');
        this.$emit('nextStep')
      },
      previousStep() {
        this.$emit('previousStep')
      },
      setValueOfSongs() {
        this.songs = this.songListInAlbums
      },
      async saveDataInStore() {
        this.$v.basicInformation.$touch();
        this.$v.songs.$touch();
        if (this.$v.basicInformation.$anyError ||this.$v.songs.$anyError) {
          this.isImgUploaded = false;
          return;
        } else {
          if(this.basicInformation[PICTURES] && this.type && this.basicInformation[PICTURES_ID] === null) {
            try {
              this.spinner = true
              const payload = {
                artwork: this.basicInformation[PICTURES],
                type: this.type === 'sample_pack' ? 'sample' : this.type
              }
              const formData = createFormData(payload)
              const { data } = await uploadTemporaryArtworkUrl(formData)
              const {information, success, message} = data || {}
              this.spinner = false
              if (success === 1) {
                this.basicInformation[PICTURES_ID] = information.id
                this.notificationToast(true, 'Artwork', message, 5000, 'success')
              }
            } catch (e) {
              this.spinner = false
              console.error(e);
              this.notificationToast(true, 'Artwork', 'Enable to upload artwork', 5000, 'error')
            }
          }
          if (this.type === 'song' || this.type === 'podcast') {
            this.$store.commit('SET_SONG_STEP_2_DATA', this.basicInformation)
          } else if (this.type === 'sample' || this.type === 'sample_pack') {
            this.$store.commit('SET_SAMPLE_STEP_2_DATA', this.basicInformation)
          } else {
            this.$store.commit('SET_ALBUM_STEP_2_DATA', {
              data: this.basicInformation,
              songs: this.songs
            })
          }
          this.nextStep()
        }
  
      },
      getPageID() {
        this.$v.basicInformation[PAGE_ID].$model = this.pageId
      },
      async uploadSongOrAlbum (tempSongUpload) {
        if (tempSongUpload.type === 'sample_pack') {
          tempSongUpload.type = 'sample';
        }
        try {
          this.uploadingCheck = true
          this.fileName = tempSongUpload.names[0]
          const formData = createFormData(tempSongUpload);
          const {data} = await axios.post( `${AppConfig.base_url}/page/music/upload-temp-song`, formData, {
            headers: {
                Authorization: localStorage.getItem("token") !== null ? localStorage.getItem("token") : "",
                'Content-Type': 'multipart/form-data'
              },
              onUploadProgress: function( progressEvent ) {
                this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
              }.bind(this)
            }
          ).then((response) => response)
            .catch((err) => {
              this.previousStep();
              Utils.handleError
            });
          const {success, message, information} = data || {}
          if (success === 1) {
            if(this.type === 'album') {
              this.$store.commit('ADD_SONGS_OBJECTS_IN_ALBUM', information)
            }
            this.responseSongData = information
            if(this.currentType === 'sample'){
              const samplePayload = {
              sample_id: information.id
            }
            this.$store.commit('SET_SAMPLE_STEP_1_DATA', samplePayload)
            } else if(this.currentType === 'sample_pack') {
              this.basicInformation.sample_details[0].temp_song_id = information.id;
              this.basicInformation.sample_details[0].title = information.filename
              this.sample_1_url = information.audio_url
              console.log("this.sample_1_filename",  this.basicInformation)
            }
            const payload = {
              song_id: information.id
            }
            this.$store.commit('SET_SONG_STEP_1_DATA', payload)
            this.notificationToast(true, 'File Upload', message, 5000, 'success')
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .show_theme_input{
    display: none;
  }
  .song-heading {
    font-family: "HelveticaNeueMedium";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.03em;
    margin-bottom: 16px;
  }
  
  .upload-file-p-padding {
    padding: 0 19px 0 21px;
    background-color: #F9FAFB;
  }
  
  .song-p {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
    color: #47505B;
  }
  
  .upload-file-p-position {
    position: absolute;
    bottom: 0;
  }
  
  .Choose-File-Format-bottom-p {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: #8B949F;
  }
  
  .basic-information-h {
    background-color: #ECF0FF;
    border: 1px solid #E6EAF0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  
  .basic-information-h h3 {
    color: #D1433A;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    padding-left: 10px;
  }
  
  .basic-info-ul ul li {
    padding: 0 0 7px 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #47505B;
  }
  
  .basic-info-ul ul li::marker {
    color: #A0B5FF;
  }
  
  .upload-your-work-card-border {
    border: 1px solid #E6EAF0;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  
  .upload-work-p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #47505B;
  }
  
  .basic-info-setting-form .form-group {
    margin-bottom: 15px !important;
  }
  
  .basic-info-setting-form label {
    margin-bottom: 6px !important;
  }
  
  .basic-info-setting-form div:nth-child(2) .form-group .form-control {
    background-color: #D1433A1A;
    border: 1px solid #E6EAF0;
    /*background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAYAAADgKtSgAAAAAXNSR0IArs4c6QAAAXBJREFUSInN1M1RwkAYxvH/G/CeDqQD6cBABZSANwaZESsgVCAHcbwBFagNSDoQO6CE3ME8HiSBhPAR4ODeMvvu7919ZrPwn4fXlet15OXNOWfBLVVswZfBtN5RLztvZ8ElphiVDcz/fLZ+/H3SzjPwTOIRQOBvnqAwnoLFXGVqwdAGirjLNigUS14UEs1gaBMAr62mOYxWsH80vhUFfBj09jU4KpYtuEwteDZf0AcwYxxfR8e4XS0LD+48Fx5YmMx35K9OEBoEggYQKqK2Fz8E5zQghoMXm+2M5VgYwBHXWRh23JYicP1eIxnNLJyLHx1FV66z4ElGEzHXD7Xg1eabNSm8CGxLpkB1F5zCLw3D5j0v0bgknMIduAGQmFwCTuFR/F4Ys2zRKXAKN6gCUE7jp8J/FOC1VQVcxDyOxGupQomGLXhIntcCcILjrJ/QekdvAg9wk6oT4DVeJmAJGBWRNAplvBPxzRXjYJj/h+4bv8a9EvhDa03qAAAAAElFTkSuQmCC');*/
  }
  
  .basic-info-upload-btn {
    display: inline-block;
    margin-left: 12px;
    position: relative;
    top: -1px;
  }
  
  .select-component {
    .select2-container--default {
      width: 100%;
    }
  }
  
  .album-h {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
  }
  
  .album-h-position {
    position: relative;
    top: 26px;
  }
  
  .upload-border {
    border: none;
    opacity: 1;
    background: #E6EAF0;
  }
  
  .album-h {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
  }
  
  .album-h-position {
    position: relative;
    top: 26px;
  }
  
  .upload-border-bottom {
    border: none;
    opacity: 1;
    background: #E6EAF0;
  }
  .upload-file-save-btn {
    align-items: center;
    justify-content: center;
    font-weight: 700;
    transition: 0.3s all;
    border: 1px solid #D1433A !important;
    background-color: #ffffff;
    color: #D1433A;
    padding: 8px 12px;
    font-size: 13px;
    border: none;
    font-family: "HelveticaNeueMedium";
    width: 100%;
    border-radius: 4px;
    letter-spacing: 0.05em;
  }
  .upload-file-upload-btn {
    align-items: center;
    justify-content: center;
    font-weight: 700;
    transition: 0.3s all;
    background-color: #D1433A;
    color: #fff;
    padding: 10px;
    font-size: 13px;
    border: none;
    font-family: "HelveticaNeueMedium";
    width: 100%;
    border-radius: 4px;
    letter-spacing: 0.05em;
  }
  .cancel-btn-display {
    display: inline-block;
    width: 130px;
  }
  .save-btn-display {
    display: inline-block;
    width: 150px;
    padding-right: 10px;
  }
  .upload-btn-display {
    display: inline-block;
    width: 130px;
  }
  
  .pg-song-artist-checkbox-card{
    border: none !important;
  }
  
  
  .pg-song-artist-checkbox-card .custom-control {
    width: 100px;
    margin-left: 21px;
    display: inline-block;
  }
  
  .pg-song-artist-checkbox-card .widget-item{
    display: inline-block !important;
    padding: 0;
  }
  .pg-song-artist-checkbox-card .pg-item-title{
    display: inline-block;
    position: relative;
    top: -3px;
    width: auto;
    color: #071526;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
  
  }
  .upload-btn-margin{
    margin-left: 16px;
  }
  .upload-file-p-padding{
    padding: 0 19px 0 21px;
    background-color: #F9FAFB;
  }
  
  .upload-file-p-position{
    position: absolute;
    bottom: 0;
  }
  .Choose-File-Format-bottom-p{
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: #8B949F;
  }
  .upload-file-cancel-btn {
    align-items: center;
    justify-content: center;
    font-weight: 700;
    transition: 0.3s all;
    background: #e6eaf0;
    color: #47505b;
    padding: 10px;
    font-size: 13px;
    border: none;
    font-family: "HelveticaNeueMedium";
    width: 100%;
    border-radius: 4px;
    letter-spacing: 0.05em;
  }
  .basic-information-p{
    padding-right: 25px;
  }
  .basic-info-setting-form-1{
    padding-right: 15px;
  }
  .song-heading-1{
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    color: #071526;
    opacity: 0.9;
  }
  
  // fill image
  .fill-image{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .fill-image img{
    flex-shrink: 0;
    max-width: 277px;
    max-height: 273px;
  }
  
  .progress-bar-div {
    height: 50px;
    display: flex;
    align-items: center;
    background: #D1433A;
    border-radius: 4px 4px 0 0;
    margin-bottom: 10px;
  
    .uploading-content-name {
      margin-left: 18px;
      color: #FFFFFF;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
  
      .content {
        font-weight: 700;
        margin-left: 2px;
        text-transform: uppercase;
      }
    }
    .progress-wrapper {
      flex-direction: row;
      align-items: center;
      font-style: normal;
      text-align: right;
      font-weight: 600;
      line-height: 20px;
      color: #071526;
      font-size: 13px;
      display: flex;
  
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #FFFFFF;
        margin-right: 8px;
      }
  
      .progress-outer {
        position: relative;
        top: -1px;
        background-color: #7A98FF;
        border-radius: 10px;
        width: 370px;
        overflow: hidden;
        height: 8px;
  
        .progress-inner {
          width: 80%;
          height: 100%;
          background-color: #FFFFFF;
        }
      }
    }
  }
  .replace-file-div {
    height: 50px;
    display: flex;
    align-items: center;
    background: #F9FAFB;
    border: 1px solid #E6EAF0;
    border-radius: 2px;
    margin-bottom: 10px;
  
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #47505B;
    }
    a {
      margin-left: 2px;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #D1433A!important;
    }
  
    button {
      width: 120px;
      height: 31px;
      left: 1390px;
      top: 290px;
      background: #FFFFFF;
      border: 1px solid #E6EAF0;
      border-radius: 4px;
  
      span {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #47505B;
      }
    }
  }
  
  .edit-song-url {
    background: #D1433A1A;
    border: 1px solid #E6EAF0;
    border-radius: 4px;
    .input-color {
       border: none!important;
       background: #ecf0ff!important;
     }
  }
  
  .image-preview {
    width: inherit;
    height:inherit;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .cross-icon {
    right: 28px;
    svg {
      path {
        stroke: #FFFFFF;
      }
    }
  }

  .song-btn {
    align-items: center;
    justify-content: center;
    font-weight: 500;
    transition: 0.3s all;
    width: 50%;
    border-radius: 2px 0px 0px 2px;
    background-color: #f5f7f9;
    color: #47505b;
    padding: 1px 10px;
    font-size: 13px;
    border: none;
    font-family: "HelveticaNeueMedium";
    &.active{
      background-color: #D1433A;
      color: #fff;
      svg{
        path{
          stroke: #fff;
        }
      }
    }
    svg{
      path{
        stroke: black;
      }
    }
  }

  .song-btn-icon-display {
    &.upload{
      left: 2px;
      top: -1px!important;
      margin: 0 5px 0;
    }
    display: inline-block;
    position: relative;
    top: 4px;
    font-size: 20px;
  }
  
  .song-btn-margin {
    margin-left: 9px;
    position: relative;
    top: -3px;
  }

  .song-btn.active span:before {
    color: #fff;
  }

  .album-btn {
    align-items: center;
    justify-content: center;
    background-color: #f5f7f9;
    color: #47505b;
    font-weight: 500;
    border-radius: 2px 0px 0px 2px;
    transition: 0.3s all;
    border: none;
    padding: 1px 10px;
    width: 50%;
    font-size: 13px;
    font-family: "HelveticaNeueMedium";
    &.active{
      background-color: #D1433A;
      color: #fff;
      svg{
        path{
          stroke: #fff;
        }
      }
    }
  }

  .album-btn.active span:before{
    color: #fff;
  }

  </style>
  
  <style lang="css" scoped>
  .instrument-character-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .instrument-container {
    width: 24%;
    margin-left: 16px;
  }
  .character-container {
    width: 55.5%;
    margin-right: 16px;
  }
</style>